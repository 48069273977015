import { useResetAtom } from 'jotai/utils';
import { useCallback } from 'react';
import type { UseFormReset } from 'react-hook-form';
import type { MarketingAutomationAPIFormValues } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/components/RegisterMarketingAutomationDialogContent';
import { isNationalitySelectBoxOpenedState } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingBaseSetting/states/is-nationality-select-box-opened';
import {
  checkedLayersState,
  checkedOrphanSubLayersState,
  checkedSubLayersState,
} from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingBaseSetting/states/selected-layers';
import {
  isEventSendingCountChangedState,
  isEventStartDateChangedState,
} from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingEventStartDateSetting/states/is-event-start-date-changed';
import { isBaseDataSettingCompletedState } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/components/AutomatedMessageLists/states/is-base-data-setting-completed';
import { algorithmModeState } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/states/algorithm-mode';
import {
  selectedContentTabItemState,
  selectedMessageState,
} from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/states/message-form';
import { messageWarningStatesState } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/states/message-warning-states';
import { originMessagesState } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/states/origin-message';
import { selectedMarketingAutomationTabState } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/states/selected-marketing-automation-tab-state';

interface UseMarketingAutomationResetAllProps {
  reset: UseFormReset<MarketingAutomationAPIFormValues>;
  defaultValues?: MarketingAutomationAPIFormValues;
}

export const useMarketingAutomationResetAll = ({
  reset,
  defaultValues,
}: UseMarketingAutomationResetAllProps) => {
  const resetMessageWarningStates = useResetAtom(messageWarningStatesState);
  const resetOriginMessages = useResetAtom(originMessagesState);
  const resetCheckedLayers = useResetAtom(checkedLayersState);
  const resetCheckedSubLayers = useResetAtom(checkedSubLayersState);
  const resetCheckedOrphanSubLayers = useResetAtom(checkedOrphanSubLayersState);
  const resetContentTabIndex = useResetAtom(selectedContentTabItemState);
  const resetSelectedMarketingAutomationTab = useResetAtom(selectedMarketingAutomationTabState);
  const resetSelectedMessage = useResetAtom(selectedMessageState);
  const resetMessageMode = useResetAtom(algorithmModeState);
  const resetIsNationalitySelectBoxOpened = useResetAtom(isNationalitySelectBoxOpenedState);
  const resetIsBaseDataSettingCompleted = useResetAtom(isBaseDataSettingCompletedState);
  const resetIsEventStartDateChanged = useResetAtom(isEventStartDateChangedState);
  const resetIsEventSendingCountChanged = useResetAtom(isEventSendingCountChangedState);

  const resetAll = useCallback(() => {
    resetCheckedLayers();
    resetCheckedSubLayers();
    resetCheckedOrphanSubLayers();
    resetContentTabIndex();
    resetSelectedMarketingAutomationTab();
    resetSelectedMessage();
    resetMessageMode();
    resetIsNationalitySelectBoxOpened();
    resetOriginMessages();
    resetMessageWarningStates();
    resetIsBaseDataSettingCompleted();
    resetIsEventSendingCountChanged();
    resetIsEventStartDateChanged();
    reset(defaultValues);
  }, [
    reset,
    resetCheckedLayers,
    resetCheckedSubLayers,
    resetCheckedOrphanSubLayers,
    resetContentTabIndex,
    resetSelectedMarketingAutomationTab,
    resetSelectedMessage,
    resetMessageMode,
    resetIsNationalitySelectBoxOpened,
    resetOriginMessages,
    resetMessageWarningStates,
    resetIsBaseDataSettingCompleted,
    resetIsEventSendingCountChanged,
    resetIsEventStartDateChanged,
    defaultValues,
  ]);

  return { resetAll };
};
