import ContainedButton from '@afterdoc-design-system/components/Atoms/Button/ContainedButton';
import Scrollbar from '@afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import Logo from '@afterdoc-design-system/components/Foundations/Logo/Logo';

interface ErrorPageProps {
  errorInfo?: string | null;
  errorType?: 'NetworkError' | 'TypeError' | 'UnknownError' | null;
}

export default function ErrorPage({ errorInfo, errorType }: ErrorPageProps) {
  return (
    <div className='flex h-screen w-full flex-col items-center justify-center text-center'>
      <div className='mb-16 text-Header16 text-black700'>Error도 After Care가 필요할 땐?</div>
      <Logo name='LogoColor' size={232} />
      <div className='text-Header14 text-red400'>
        애프터닥을 완전히 종료 후<br />
        다시 실행해 보세요.
      </div>
      <div className='mt-12 text-Body13 text-black500'>
        이 페이지가 지속적으로 나타나면, 아래 연락처로 문의해주세요!
      </div>
      <div className='mt-8 text-Body13 text-black200'>(메디팔 070-5180-4070)</div>

      {process.env.NODE_ENV === 'development' && errorInfo && errorType && (
        <ErrorInDevelopment errorInfo={errorInfo} errorType={errorType} />
      )}
    </div>
  );
}

const ErrorInDevelopment = ({ errorInfo, errorType }: ErrorPageProps) => {
  const handleCopy = () => {
    if (errorInfo) {
      const textToCopy = `Error Type: ${errorType}\n\nError Details:\n${errorInfo}`;

      if (navigator.clipboard?.writeText) {
        navigator.clipboard
          .writeText(textToCopy)
          .then(() => alert('복사 완료!'))
          .catch(() => alert('복사 실패'));
      } else {
        const textArea = document.createElement('textarea');
        textArea.value = textToCopy;
        textArea.style.position = 'fixed';
        textArea.style.opacity = '0';
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
          document.execCommand('copy');
          alert('복사 완료!');
        } catch {
          alert('복사 실패');
        }

        document.body.removeChild(textArea);
      }
    }
  };

  return (
    <>
      <div className='mt-40 h-[500px] w-[950px] text-red500'>
        <Scrollbar>
          <div className='flex-col-center p-30'>
            <h2 className='text-Header16'>Error Type: {errorType}</h2>
            <h2 className='mt-30 text-Header16'>Error Details:</h2>
            <div className='break-all text-Body14'>{errorInfo}</div>
          </div>
        </Scrollbar>
      </div>
      <ContainedButton onClick={handleCopy}>에러 정보 복사</ContainedButton>
    </>
  );
};
