import LabelText from '@afterdoc-design-system/components/Atoms/LabelText/LabelText';
import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';
import NoData from 'afterdoc-design-system/components/Molecules/NoData/NoData';

export const CustomerReservationChatRoomNull = () => {
  return (
    <>
      <NoData
        iconProps={{
          name: 'warning',
          size: 48,
          color: 'white600',
        }}
        className='flex h-full w-full flex-col items-center justify-center'
        title='고객을 선택해 주세요.'
        subTitle='고객의 예약 정보와 메모를 확인할 수 있습니다.'
      />
    </>
  );
};
