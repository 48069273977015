import { SHARED_UTILS } from '@shared-utils/utils';
import { useQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { apiClient } from 'web/apis/instances/api-client';
import type { ApiLanguageElFindDefaultParams } from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import { chatRoomIDSelector } from 'web/templates/CustomerChat/states/selected-chat-room';

const getLanguageList = async (params: ApiLanguageElFindDefaultParams) => {
  const { data } = await apiClient.v3.apiLanguageElFindDefault(params);
  return SHARED_UTILS.api.checkApiResponse(data);
};

export const useLanguageList = () => {
  const chatRoomID = useAtomValue(chatRoomIDSelector);
  const { hospitalID } = useSelectedHospitalInfo();
  const params = {
    hospitalID,
  };
  const { data, refetch, isLoading } = useQuery({
    queryKey: ['chat-translate-language', hospitalID],
    queryFn: () => getLanguageList(params as ApiLanguageElFindDefaultParams),
    enabled: !!chatRoomID,
  });

  return {
    data: data ?? [
      {
        _id: '0',
        englishLanguageName: 'English',
        koreanLanguageName: '영어',
        languageCodeISOAlpha2: 'en',
      },
    ],
    refetch,
    isLoading,
  };
};
