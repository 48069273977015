import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { DataResponse } from './use-conditional-chat-data';

interface UseInvalidateConsultationGuideProps {
  lastMessage: DataResponse;
}

export const useInvalidateConsultationGuide = ({
  lastMessage,
}: UseInvalidateConsultationGuideProps) => {
  const queryClient = useQueryClient();
  const lastMessageID = lastMessage?._id ?? 'mocking';
  const lastMessageType = lastMessage?.type ?? -1;

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (11000 <= lastMessageType && lastMessageType <= 14000) {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.chatRoomsLatestConsultationGuideHandler],
      });
    }
  }, [lastMessageID]);
};
