import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import { useUserInfo } from '@shared/hooks/use-user-info';
import { useQueryClient } from '@tanstack/react-query';
import type { TempChatRoomHServiceLastMessageUsers } from '@templates/CustomerChat/components/ChattingList/ChattingList';
import { postChatroomCounselorsAdd } from '@templates/CustomerChat/components/ChattingList/api/add-counselor';
import ChattingListItem from '@templates/CustomerChat/components/ChattingList/components/ChattingListBody/components/ChattingListItem/ChattingListItem';
import type { TempChatRoomHServiceLastMessageUser } from '@templates/CustomerChat/components/ChattingList/hooks/use-chatting-list';
import type { ChatInfiniteQueryControls } from '@templates/CustomerChat/components/ChattingList/hooks/use-chatting-list-container';
import type { ActiveDropdown } from '@templates/CustomerChat/components/ChattingList/types/active-dropdown';
import { useChatRoomRouting } from '@templates/CustomerChat/components/ChattingList/use-chat-room-routing';
import { selectedChattingListTabState } from '@templates/CustomerChat/states/selected-chatting-list-tab';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import { useIntersectionObserver } from 'hooks/use-intersection-observer';
import { useSetAtom } from 'jotai';
import { useAtomValue } from 'jotai/index';
import { useCallback, useEffect, useRef, useState } from 'react';
import { SHARED_UTILS } from 'utils/utils';
import { selectedChatRoomState } from 'web/templates/CustomerChat/states/selected-chat-room';

interface ChattingListAdvanceProps {
  chatRooms: TempChatRoomHServiceLastMessageUser[];
  infiniteQueryControls: ChatInfiniteQueryControls;
}

export type HandleSelectedChatRoomParmas = {
  chatRoomID: string;
  chatRoomParentHServiceID: string;
  patientIDInChattingListUserID: string;
  chatRoomHserviceID: string;
};

const refetchQueryKeys = [
  QUERY_KEY.apiChatroomElUnreadcount,
  QUERY_KEY.apiChatroomElCount,
  QUERY_KEY.apiChatroomElFind,
  QUERY_KEY.apiChatroomElFindOne,
];

export const postChatroomUpdateRead = async (chatRoomID: string) => {
  const body = {
    chatRoomID,
  };
  const { data } = await apiClient.v3.apiChatroomElUpdateRead(body);
  return SHARED_UTILS.api.checkApiResponse(data) as TempChatRoomHServiceLastMessageUsers;
};

export default function ChattingListBody({
  chatRooms,
  infiniteQueryControls,
}: ChattingListAdvanceProps) {
  const queryClient = useQueryClient();
  const { userId } = useUserInfo();

  const selectedTab = useAtomValue(selectedChattingListTabState);

  const setSelectedChatRoom = useSetAtom(selectedChatRoomState);

  const [activeDropdown, setActiveDropdown] = useState<ActiveDropdown | null>(null);

  const loadMoreRef = useRef<HTMLDivElement | null>(null);
  const scrollbarRef = useRef<{
    scrollTo: (index: number, behavior?: ScrollBehavior) => void;
  }>(null);

  const { fetchNextPage, hasNextPage, isFetchingNextPage } = infiniteQueryControls;

  const handleIntersection = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;
      if (entry.isIntersecting && hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
    [fetchNextPage, hasNextPage, isFetchingNextPage],
  );

  useIntersectionObserver(handleIntersection, { root: null, rootMargin: '20px', threshold: 0.5 }, [
    loadMoreRef.current,
  ]);

  const handleDropdown = useCallback((activeDropdown: ActiveDropdown, isActive: boolean) => {
    const { key, chatRoomID } = activeDropdown;
    setActiveDropdown(isActive ? { key, chatRoomID } : null);
  }, []);

  const handleSelectChatRoom = useCallback(
    async ({
      chatRoomID,
      chatRoomParentHServiceID,
      patientIDInChattingListUserID,
      chatRoomHserviceID,
    }: HandleSelectedChatRoomParmas) => {
      try {
        // 1. 먼저 채팅방 정보를 가져옴
        const { roomType, ikakaoInfo, counselors } = await postChatroomUpdateRead(chatRoomID);

        // 2. 채팅방에 담당자가 없을 경우 본인을 담당자로 추가
        if (counselors?.length === 0) {
          await postChatroomCounselorsAdd({
            chatRoomID,
            onlyAddIDs: [userId],
          });
        }

        // 3. 모든 데이터가 준비된 후에 상태 업데이트
        setSelectedChatRoom({
          chatRoomID,
          chatRoomHserviceID,
          patientIDInChattingListUserID,
          chatRoomParentHServiceID,
          roomType,
          roomInfo: ikakaoInfo,
        });

        // 4. 채팅방이 변함에 따라 연관된 api들의 쿼리 무효화를 Promise.all로 처리
        Promise.all(
          refetchQueryKeys.map((key) => queryClient.invalidateQueries({ queryKey: [key] })),
        );
      } catch (error) {
        console.error('채팅방 선택 중 오류 발생:', error);
      }
    },
    [userId],
  );

  useEffect(() => {
    if (scrollbarRef.current) {
      scrollbarRef.current.scrollTo(0, 'auto');
    }
  }, [selectedTab]);

  useChatRoomRouting({ handleSelectChatRoom });

  return (
    <>
      <Scrollbar disabledX ref={scrollbarRef}>
        {chatRooms?.map((chatRoom) => {
          return (
            <ChattingListItem
              key={chatRoom._id}
              chatRoom={chatRoom}
              activeDropdown={activeDropdown}
              onHandleDropdown={handleDropdown}
              onHandleSelectChatRoom={handleSelectChatRoom}
            />
          );
        })}
        <div ref={loadMoreRef} style={{ height: 1 }} />
      </Scrollbar>
    </>
  );
}
