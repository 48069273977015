import { noticeCreateOrUpdateParamState } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Notice/states/notice-create-or-update-param-state';
import { selectedNoticeEditTargetErrorAtom } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Notice/states/notice-edit-error-state';
import PopupNoticeTargetDropdown from '@templates/Content/containers/ContentManager/containers/NoticePopup/shared/components/PopupNoticeTargetDropdown';
import type { PushNotificationOption } from '@templates/Content/containers/ContentManager/containers/NoticePopup/types/push-notification-option';
import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import LabelText from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import Radio from 'afterdoc-design-system/components/Atoms/Radio/Radio';
import {
  RadioGroup,
  type ValueType,
} from 'afterdoc-design-system/components/Atoms/Radio/RadioGroup';
import { useAtom, useSetAtom } from 'jotai/index';
import { memo, useCallback, useState } from 'react';

const pushOptionsMap = {
  none: {
    isSendMessage: false,
    isSendToHospital: false,
  },
  allCustomers: {
    isSendMessage: true,
    isSendToHospital: true,
  },
  specificCustomers: {
    isSendMessage: true,
    isSendToHospital: false,
  },
};

function NoticePushTarget() {
  const setNoticeCreateOrUpdateParams = useSetAtom(noticeCreateOrUpdateParamState);

  const [noticeTargetError, setNoticeTargetError] = useAtom(selectedNoticeEditTargetErrorAtom);

  const [pushNotificationOption, setPushNotificationOption] =
    useState<PushNotificationOption>('none');

  const clearError = useCallback(() => setNoticeTargetError(null), [setNoticeTargetError]);

  const handleChange = useCallback((value: ValueType) => {
    const selectedOption = value as PushNotificationOption;
    setPushNotificationOption(selectedOption);
    setNoticeCreateOrUpdateParams((prev) => ({
      ...prev,
      ...pushOptionsMap[selectedOption],
    }));
  }, []);

  const handleSelectType = useCallback(() => {
    clearError();
  }, []);

  const handleSelectTags = useCallback((tags: string[]) => {
    clearError();
    setNoticeCreateOrUpdateParams((prev) => ({ ...prev, treatmentCategories: tags }));
  }, []);

  const handleSelectVisitType = useCallback((visitType: boolean[] | undefined) => {
    clearError();
    setNoticeCreateOrUpdateParams((prev) => ({ ...prev, isFirstVisit: visitType }));
  }, []);

  return (
    <div className='mt-10 flex w-full flex-col gap-10'>
      <div className='flex min-h-100 gap-28 py-4'>
        <LabelText width={72} className='flex h-32 items-center'>
          푸시알림
        </LabelText>
        <div className='flex flex-col gap-4'>
          <RadioGroup name='pushTarget' value={pushNotificationOption} onChange={handleChange}>
            <Radio id='noPushNotification' label='푸시알림 보내지 않기' value='none' />
            <Radio id='all' label='모든 고객에게 푸시알림 보내기' value='allCustomers' />
            <Radio id='specific' label='특정 고객에게 푸시알림 보내기' value='specificCustomers' />
          </RadioGroup>
          {pushNotificationOption === 'specificCustomers' && (
            <PopupNoticeTargetDropdown
              errorMessage={noticeTargetError}
              onHandleSelectType={handleSelectType}
              onHandleSelectTags={handleSelectTags}
              onHandleSelectVisitType={handleSelectVisitType}
            />
          )}
        </div>
      </div>

      <Divider type='line' />
    </div>
  );
}
export default memo(NoticePushTarget);
