import * as React from "react";
import type { SVGProps } from "react";
const SvgItalic = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#7F7F7F"
      d="M8.333 3.333v2.5h1.842L7.325 12.5H5V15h6.667v-2.5H9.825l2.85-6.667H15v-2.5z"
    />
  </svg>
);
export default SvgItalic;
