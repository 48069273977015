import type { apiClient } from '@apis/instances/api-client';
import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';

export type CreateRequestPushParams = NonNullable<
  Parameters<typeof apiClient.v3.apiPushRequestElCreate>[0]
>;

export const eventPushRequestCreateParamState = atomWithReset<CreateRequestPushParams>({
  type: 'HospitalEvent',
  hospitalID: '',
  hospitalEventID: '',
  isSendToHospital: true,
});

export const eventPushSendToHospitalAtom = atom(
  (get) => get(eventPushRequestCreateParamState).isSendToHospital,
  (get, set, flag: boolean) => {
    const currentState = get(eventPushRequestCreateParamState);
    set(eventPushRequestCreateParamState, {
      ...currentState,
      isSendToHospital: flag,
    });
  },
);

export const eventPushTreatmentCategoriesAtom = atom(
  (get) => get(eventPushRequestCreateParamState).treatmentCategories,
  (get, set, ids: string[] | undefined) => {
    const currentState = get(eventPushRequestCreateParamState);
    set(eventPushRequestCreateParamState, {
      ...currentState,
      treatmentCategories: ids,
    });
  },
);

export const eventPushVisitTypeAtom = atom(
  (get) => get(eventPushRequestCreateParamState).isFirstVisit,
  (get, set, flags: boolean[] | undefined) => {
    const currentState = get(eventPushRequestCreateParamState);
    set(eventPushRequestCreateParamState, {
      ...currentState,
      isFirstVisit: flags,
    });
  },
);

const updateDateField = (field: 'startDate' | 'endDate') =>
  atom(
    (get) => get(eventPushRequestCreateParamState)[field],
    (get, set, date: string | null) => {
      const currentState = get(eventPushRequestCreateParamState);
      set(eventPushRequestCreateParamState, {
        ...currentState,
        [field]: date,
      });
    },
  );

export const eventPushStartDateAtom = updateDateField('startDate');
export const eventPushEndDateAtom = updateDateField('endDate');
