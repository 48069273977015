import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import TextInput from 'afterdoc-design-system/components/Atoms/Input/TextInput';
import LabelText from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import { memo } from 'react';

interface EventHomeCareTitleInputProps {
  description: string;
  onHandleValue: (value: string) => void;
}

const IntroSummaryInput = memo(({ description, onHandleValue }: EventHomeCareTitleInputProps) => {
  return (
    <div className='mt-10 flex w-full flex-col gap-10'>
      <div className='h-40 flex-center gap-28'>
        <LabelText width={72} className='flex h-32 items-center'>
          한줄 소개글
        </LabelText>
        <TextInput
          className='flex flex-grow items-center'
          onChange={(e) => onHandleValue(e.target.value)}
          maxLength={200}
          placeholder={'설명을 입력해주세요.'}
          value={description}
        />
      </div>
      <Divider type='line' />
    </div>
  );
});

export default IntroSummaryInput;
