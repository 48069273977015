import TextArea from '@afterdoc-design-system/components/Atoms/Input/TextArea/TextArea';
import { useAtom, useAtomValue } from 'jotai';
import { forwardRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import type { MarketingAutomationAPIFormValues } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/components/RegisterMarketingAutomationDialogContent';
import { MAX_LENGTH } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/containers/MessageContentForm/components/SendContentForm/constants/replaceable-texts';
import { useTextAreaWithMaxLength } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/containers/MessageContentForm/components/SendContentForm/hooks/use-text-area-with-max-length';
import { actualTextLengthState } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/containers/MessageContentForm/components/SendContentForm/states/actual-text-length';
import { selectedMessageIndexState } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/states/message-form';

const TextContentForm = forwardRef<HTMLTextAreaElement>((_, ref) => {
  const { getValues } = useFormContext<MarketingAutomationAPIFormValues>();

  const [actualTextLength, setActualTextLength] = useAtom(actualTextLengthState);
  const selectedMessageIndex = useAtomValue(selectedMessageIndexState);

  const messageContentText = getValues(`messages.${selectedMessageIndex}.content.text`);
  const { handleTextAreaChange } = useTextAreaWithMaxLength({ setActualTextLength });

  return (
    <Controller
      key={selectedMessageIndex}
      name={`messages.${selectedMessageIndex}.content.text`}
      render={({ field }) => (
        <TextArea
          {...field}
          ref={ref}
          onChange={(e) => {
            handleTextAreaChange(e);

            if (
              actualTextLength >= MAX_LENGTH &&
              e.target.value.length > (messageContentText?.length ?? 0)
            )
              return;

            field.onChange(e);
          }}
          height={150}
          isShowBottom={false}
          placeholder='내용을 입력해 주세요.'
        />
      )}
    />
  );
});

export default TextContentForm;
