import EventListContainer from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/components/EventList/EventListContainer';
import EventManageDialogContainer from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/components/EventManageDialog/EventManageDialogContainer';
import { contentEditState } from '@templates/Content/containers/ContentManager/states/content-edit-state';
import { useAtomValue } from 'jotai';
import React from 'react';

export default function EventContainer() {
  const contentEdit = useAtomValue(contentEditState);

  return (
    <>
      <EventListContainer />
      {contentEdit.isEditing && <EventManageDialogContainer />}
    </>
  );
}
