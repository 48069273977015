import * as React from "react";
import type { SVGProps } from "react";
const SvgH1 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#7F7F7F"
      d="M2.5 3.333h1.667v5H7.5v-5h1.667V15H7.5v-5H4.167v5H2.5zM11.667 15v-1.667h1.666V5.258l-2.083 1.2V4.533l2.083-1.2H15v10h1.667V15z"
    />
  </svg>
);
export default SvgH1;
