import type { CustomerManagementTemporaryAPIFormValues } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/types/table';

export const createInitialRows = (
  rowsLength: number,
  lastOrder: number,
  hospitalID: string,
): CustomerManagementTemporaryAPIFormValues => {
  return Array.from({ length: rowsLength }, (_, index) => ({
    order: lastOrder + index + 1,
    hospitalID,
    name: null,
    patientId: undefined,
    chartNumber: null,
    isFirstVisit: null,
    phoneNumber: null,
    birthdate: null,
    gender: null,
    treatmentTagIds: [],
    nationalityId: null,
  }));
};
