import Divider from '@afterdoc-design-system/components/Atoms/Divider/Divider';
import { dialogService } from '@afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import { Suspense } from 'react';
import type { AlgorithmMessage, AlgorithmWithMessagesInput } from 'web/apis/swaggers/swagger-docs';
import SurveyDetailContentDialog, {
  SURVEY_DETAIL_CONTENT_DIALOG_ID,
} from 'web/templates/Automation/containers/Viewer/AutomationZoomInOutViewer/components/components/MessageCardItem/components/SurveyContent/components/SurveyDetailContentDialog';

interface SurveyContentProps {
  messageId: NonNullable<AlgorithmMessage['id']>;
  messageName: NonNullable<AlgorithmMessage['name']>;
  survey: NonNullable<NonNullable<AlgorithmMessage['content']>['survey']>;
  algorithmName: NonNullable<AlgorithmWithMessagesInput['name']>;
}

export default function SurveyContent({
  messageId,
  messageName,
  survey,
  algorithmName,
}: SurveyContentProps) {
  const onClickSurvey = () => {
    if (!messageId) return;

    dialogService.push(
      <Suspense>
        <SurveyDetailContentDialog
          algorithmName={algorithmName}
          messageId={messageId}
          messageName={messageName}
          survey={survey}
        />
      </Suspense>,
      {
        id: SURVEY_DETAIL_CONTENT_DIALOG_ID,
        wrapperClassName: 'h-fit',
        width: 400,
      },
    );
  };

  return (
    <div
      className='cursor-pointer rounded-r10 bg-white50 px-16 py-10 shadow-modal'
      onClick={onClickSurvey}>
      <div className='text-Body14 text-black700'>{survey.question}</div>
      <Divider type='line' className='my-16' />
      <div className='flex flex-col gap-y-4'>
        {survey.answers?.map((answer, index) => (
          <div className='py-4 text-Body13 text-black500' key={index}>
            {`${index + 1}. ${answer}`}
          </div>
        ))}
      </div>
    </div>
  );
}
