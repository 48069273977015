import { useAtomValue } from 'jotai';
import ProgressItem from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/components/FileDownloadProgress/ProgressItem';
import { Z_INDEXS } from 'web/templates/CustomerChat/constants/layout';
import { chatFileProgressAtom } from '../ChattingContainer/components/ChattingContent/states/chatFileProgress';

export default function ProgressContent() {
  const chatFileProgress = useAtomValue(chatFileProgressAtom);
  const keysIsFileNames = Array.from(chatFileProgress.keys()); // IterableIterator를 배열로 변환
  const keysIsFileNamesReverse = keysIsFileNames.reverse(); // 배열을 역순으로 변환

  return (
    <div
      className='absolute bottom-0 w-full px-10 pb-8'
      style={{ zIndex: Z_INDEXS.ChattingTooltip }}>
      {keysIsFileNamesReverse.map((id: string) => {
        const { fileName, progress, filePath, error } = chatFileProgress.get(id) ?? { progress: 0 };

        return (
          <div key={id}>
            <ProgressItem
              key={id}
              id={id}
              progress={progress}
              fileName={fileName ?? ''}
              filePath={filePath}
              error={error ?? null}
            />
          </div>
        );
      })}
    </div>
  );
}
