import type { HospitalNoticePopupFilterFiles } from '@apis/swaggers/swagger-docs';
import type { CreateUpdatePopupParamType } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/types/create-update-popup-param-type';
import dayjs from 'dayjs';

export const convertHospitalPopupToParams = (
  data: HospitalNoticePopupFilterFiles,
): CreateUpdatePopupParamType => {
  const {
    _id = '',
    hospitalID = '',
    hospitalNoticeID = '',
    popupType = 'text',
    content,
    name = '',
    title,
    filter = [],
    startDate = '',
    endDate = '',
  } = data;

  const extractedFilter =
    Array.isArray(filter) && filter.length > 0
      ? {
          treatmentCategoryID_in: (filter[0]?.treatmentCategoryID_in ?? []).map((item) => item._id),
          isFirstVisit_in: filter[0]?.isFirstVisit_in ?? [],
        }
      : {
          treatmentCategoryID_in: [],
          isFirstVisit_in: [],
        };

  return {
    _id,
    hospitalID,
    hospitalNoticeID,
    popupType,
    content,
    name,
    title,
    filter: extractedFilter,
    startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD'),
    endDate: endDate
      ? dayjs(endDate).format('YYYY-MM-DD')
      : dayjs().add(7, 'day').format('YYYY-MM-DD'),
  };
};
