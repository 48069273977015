import type { ManualFolderList } from 'web/apis/swaggers/swagger-docs';

export const settingNoFolderOpen = ({
  data,
  firstTreatmentTagID,
}: { data: ManualFolderList; firstTreatmentTagID: string }) => {
  const unassignedTags = data.unassignedTags || [];
  return unassignedTags.map(({ _id, categoryName, color }) => ({
    id: _id,
    type: 'tag',
    color,
    title: categoryName,
    isOpen: firstTreatmentTagID === _id,
    items: [],
  }));
};
