import LabelText from '@afterdoc-design-system/components/Atoms/LabelText/LabelText';

export default function AlgorithmApplyTargetSetting() {
  return (
    <div className='flex w-full items-center justify-between'>
      <LabelText width='fit-content' isRequired>
        대상자
      </LabelText>
      <span className='text-Body10 text-black200'>
        선택한 치료태그, 국적이 입력된 고객에게 적용됩니다.
      </span>
    </div>
  );
}
