import { SHARED_UTILS } from '@shared-utils/utils';
import type { CSSProperties, ReactNode } from 'react';

type Alignment = 'start' | 'center' | 'end' | 'stretch';
type Direction = 'vertical' | 'horizontal';
type Justify = 'start' | 'center' | 'end' | 'space-between' | 'space-around' | 'space-evenly';

interface LayoutBoxProps {
  align?: Alignment;
  direction?: Direction;
  justify?: Justify;
  wrap?: boolean;
  size?: number;
  children: ReactNode;
  style?: CSSProperties;
  noStyle?: boolean;
  width?: number | string;
  height?: number | string;
  className?: string;
}

export default function LayoutBox({
  align = 'stretch',
  direction = 'horizontal',
  justify = 'start',
  wrap = false,
  size = 0,
  children,
  style,
  noStyle = false,
  width,
  height,
  className,
}: LayoutBoxProps) {
  const baseStyle: CSSProperties = {
    display: 'flex',
    flexDirection: direction === 'horizontal' ? 'row' : 'column',
    alignItems: align,
    justifyContent: justify,
    gap: size,
    flexWrap: wrap ? 'wrap' : 'nowrap',
    width: SHARED_UTILS.css.getCssSizeValue(width ?? 'fit-content'),
    height: SHARED_UTILS.css.getCssSizeValue(height ?? 'fit-content'),
    ...style,
  };

  if (noStyle) {
    return <>{children}</>;
  }

  return (
    <div className={className} style={baseStyle}>
      {children}
    </div>
  );
}
