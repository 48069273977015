import ContainedButton from '@afterdoc-design-system/components/Atoms/Button/ContainedButton';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import SmartDoctorHistoryDatePicker from '@templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/SmartDoctor/components/SmartDoctorReservationHistory/components/SmartDoctorHistoryDatePicker/SmartDoctorHistoryDatePicker';
import { useState } from 'react';

const PERIOD_OPTIONS = ['월간', '주간', '일간'];

export default function TitleAndSubText() {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  return (
    <div className='flex w-full flex-row items-center justify-between'>
      <div className='flex flex-col gap-8'>
        <div className='text-Header14 text-black500'>스마트닥터 예약내역</div>
      </div>
      <div className='flex flex-row items-center gap-20'>
        <div className='flex flex-row p-6'>
          {PERIOD_OPTIONS.map((option, index) => (
            <button
              key={option}
              type='button'
              className={customTwMerge(
                'h-fit w-fit select-none rounded-r6 px-14 py-4 text-Body11',
                selectedTabIndex === index ? 'bg-blue50 text-blue500' : 'bg-white50 text-white800',
              )}
              onClick={() => setSelectedTabIndex(index)}>
              {option}
            </button>
          ))}
        </div>
        <SmartDoctorHistoryDatePicker selectedTabIndex={selectedTabIndex} />
        <ContainedButton
          onClick={() => {
            window.electron?.ipcRenderer.send('Application.showReservationWindow');
          }}
          buttonSize='small'>
          예약 등록
        </ContainedButton>
      </div>
    </div>
  );
}
