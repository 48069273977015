import { useCallback, useEffect } from 'react';

type UseESCEventProps = {
  setIsDatePickerOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const useESCEvent = ({ setIsDatePickerOpen }: UseESCEventProps) => {
  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      switch (e.key) {
        case 'Escape':
          setIsDatePickerOpen(false);
          break;
        default:
          break;
      }
    },
    [setIsDatePickerOpen],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);
};
