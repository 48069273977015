import OutlinedButton from '@afterdoc-design-system/components/Atoms/Button/OutlinedButton';
import { dialogService } from '@afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import { useQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import TranslateTextMessage from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/components/ChattingMessage/Message/TextContentMessage/TranslateTextMessage';
import { fetchMetadata } from 'web/templates/CustomerChat/functions/fetchMetadata';
import { isActivateChatTranslateAtom } from '../../../../../../../../states/isChatTranslate';
import { useChatMessageContext } from '../../ChatMessageContext';
import { MESSAGE_TYPE_CODE, urlRegex } from '../../constants/message';
import ContentText from '../components/ContentText';
import { MessageDialogContent } from '../components/MessageDialogContent';
import IsTranslatingMessage from './IsTranslatingMessage';

export default function TextContentMessage() {
  const { isMine, chattingWidth, contentParts, isTranslated, isLeft, type, isOverLength } =
    useChatMessageContext();
  const isActivateChatTranslate = useAtomValue(isActivateChatTranslateAtom);
  const messageWidthStyle = {
    maxWidth: `${chattingWidth / 2}px`,
    width: 'fit-content',
  };

  const firstUrl = contentParts.find((part) => urlRegex.test(part));

  const { data: metadata } = useQuery({
    queryKey: ['chat-metadata', firstUrl] as const,
    queryFn: ({ queryKey }) => fetchMetadata(queryKey[1]),
    enabled: !!firstUrl,
    staleTime: 1000 * 60 * 60,
    retry: 1,
  });

  //TODO - Timeout 처리 및 에러 처리 필요
  if (isActivateChatTranslate && isTranslated === undefined) {
    if (isLeft) return <IsTranslatingMessage />;

    if (type === MESSAGE_TYPE_CODE.TEXT_MOCKING) return <IsTranslatingMessage />;
  }

  const handleModalOpen = () => {
    dialogService.push(
      <MessageDialogContent>
        <ContentText parts={contentParts} isTruncate={false} />
      </MessageDialogContent>,
      {
        hasCloseButton: true,
        titleProps: {
          title: '전체보기',
        },
      },
    );
  };

  return (
    <div className={`flex flex-col items-${isLeft ? 'start' : 'end'} gap-10`}>
      {isTranslated ? (
        <>
          <TranslateTextMessage />
        </>
      ) : (
        <div
          className={`rounded-r10 px-16 py-10 shadow-modal ${isMine ? 'bg-[rgba(255,245,208,1)]' : 'bg-white50'}`}
          style={messageWidthStyle}>
          <ContentText parts={contentParts} />
          {isOverLength && (
            <OutlinedButton
              className='w-full border-black200 px-20 py-6 text-black200'
              btnColor='secondary'
              onClick={handleModalOpen}>
              전체보기
            </OutlinedButton>
          )}
        </div>
      )}
      {metadata && (
        <div
          className={`h-[108px] overflow-hidden rounded-r10 shadow-modal ${isMine ? 'bg-[rgba(255,245,208,1)]' : 'bg-white50'}`}
          style={messageWidthStyle}>
          <div className='flex h-full'>
            {metadata?.image && (
              <img
                width={108}
                height={108}
                src={metadata.image}
                alt='Preview'
                className='object-cover'
              />
            )}
            <div className='m-10 flex flex-center flex-col-center flex-grow items-start gap-5'>
              <p className='line-clamp-1 overflow-hidden break-all text-Body13 text-black700'>
                {metadata?.title}
              </p>
              <p className='line-clamp-2 overflow-hidden break-all text-Body12 text-black500'>
                {metadata?.description}
              </p>
              <p className='line-clamp-1 overflow-hidden break-all text-Body12 text-black500'>
                {firstUrl}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
