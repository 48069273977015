import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useFormContext } from 'react-hook-form';
import type { MarketingAutomationAPIFormValues } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/components/RegisterMarketingAutomationDialogContent';
import { actualTextLengthState } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/containers/MessageContentForm/components/SendContentForm/states/actual-text-length';
import {
  MARKETING_MESSAGE_CONTENT_RESET_MAPPING,
  MARKETING_MESSAGE_SETTING_TAB_ITEMS,
} from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/containers/MessageContentForm/constants/tab';
import {
  selectedContentTabItemState,
  selectedMessageIdState,
  selectedMessageIndexState,
} from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/states/message-form';

export default function SendContentFormTab() {
  const { setValue } = useFormContext<MarketingAutomationAPIFormValues>();

  const [selectedContentTabItem, setSelectedContentTabItem] = useAtom(selectedContentTabItemState);
  const setActualTextLength = useSetAtom(actualTextLengthState);
  const selectedMessageId = useAtomValue(selectedMessageIdState);
  const selectedMessageIndex = useAtomValue(selectedMessageIndexState);

  const handleContentTabChange = (item: (typeof MARKETING_MESSAGE_SETTING_TAB_ITEMS)[number]) => {
    const prevItem = selectedContentTabItem?.[selectedMessageId];

    if (prevItem === item) {
      return;
    }

    const fieldsToReset =
      MARKETING_MESSAGE_CONTENT_RESET_MAPPING[
        prevItem as keyof typeof MARKETING_MESSAGE_CONTENT_RESET_MAPPING
      ] ?? [];
    for (const field of fieldsToReset) {
      setValue(`messages.${selectedMessageIndex}.content.${field}`, undefined);
    }

    // 텍스트 초기화는 항상 수행
    setActualTextLength(0);
    setValue(`messages.${selectedMessageIndex}.content.text`, undefined);

    // 새로운 탭 아이템 설정
    setSelectedContentTabItem((prev) => ({
      ...(prev || {}),
      [selectedMessageId]: item,
    }));
  };

  return (
    <div className='mt-10 flex'>
      {MARKETING_MESSAGE_SETTING_TAB_ITEMS.map((item) => (
        <div key={item}>
          <button
            type='button'
            onClick={() => {
              handleContentTabChange(item);
            }}
            className={customTwMerge(
              'py-8 pr-16 pl-20 text-Header12',
              selectedContentTabItem?.[selectedMessageId] === item
                ? 'text-black700'
                : 'text-black200',
            )}>
            {item}
          </button>
        </div>
      ))}
    </div>
  );
}
