import BaseLoading from 'afterdoc-design-system/components/Atoms/Loading/BaseLoading';
import { useAtomValue } from 'jotai';
import { useRef, useState } from 'react';
import ChattingContainer from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/ChattingContainer';
import ChattingParticipants from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingParticipants/ChattingParticipants';
import ProgressContent from 'web/templates/CustomerChat/components/ChattingRoom/components/ProgressContent/ProgressContent';
import { chatRoomIDSelector } from '../../states/selected-chat-room';
import ChatRoomIDNull from './components/ChatRoomIDNull/ChatRoomIDNull';
import ChattingSending from './components/ChattingSending/ChattingSending';
import CustomerInfoTop from './components/CustomerInfoTop/CustomerInfoTop';
import ParticipantsRequest from './components/ParticipantsRequest/ParticipantsRequest';
import useChatRoomResizeObserver from './hooks/use-chat-room-resize-observer';

export default function ChattingRoom({
  minWidth,
  isManualOpen,
}: { minWidth: number; isManualOpen: boolean }) {
  const divRef = useRef<HTMLDivElement>(null);

  const chatRoomID = useAtomValue(chatRoomIDSelector);

  const [isLoading, setIsLoading] = useState(false);

  useChatRoomResizeObserver(divRef, isManualOpen);

  if (chatRoomID === null) return <ChatRoomIDNull minWidth={minWidth} />;

  return (
    <div
      className='relative flex h-full w-full flex-col'
      ref={divRef}
      style={{ minWidth: `${minWidth}px` }}>
      <CustomerInfoTop />

      <div
        className='relative flex h-full w-full flex-col space-y-6 overflow-y-auto border-white400 border-y bg-blueLight'
        style={{ minWidth: `${minWidth}px`, position: 'relative' }}>
        <ParticipantsRequest />

        <ChattingContainer chatRoomID={chatRoomID} onLoading={setIsLoading} />
        <ChattingParticipants />

        <ProgressContent />
      </div>

      <ChattingSending />
      {isLoading && (
        <div className='absolute inset-0 flex-center bg-white50 opacity-40'>
          <BaseLoading />
        </div>
      )}
    </div>
  );
}
