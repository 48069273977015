import * as React from "react";
import type { SVGProps } from "react";
const SvgRectangle = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" {...props}>
    <g data-name="Layer_1">
      <path
        d="m11 18.6 8.1-8.1v-.9L2.9 9.5v1z"
        className="rectangle_svg__cls-1"
      />
      <path
        d="M19.1 10.5 11 18.6l-8.1-8.1H0l11 10.9 10.9-10.9z"
        style={{
          fill: "#d1d1d1",
        }}
      />
    </g>
  </svg>
);
export default SvgRectangle;
