import Title from 'afterdoc-design-system/components/Molecules/Title/Title';

const TITLE = '입력정보 관리';
const SUB_TITLE = '고객관리 > 고객정보 입력에서 일괄 입력할 항목을 켜고 끌 수 있습니다.';

export default function CustomerInfoTitle() {
  return (
    <>
      <Title title={TITLE} subTitle={SUB_TITLE} />
    </>
  );
}
