import ChattingList from '@templates/CustomerChat/components/ChattingList/ChattingList';
import { useAtomValue } from 'jotai';
import ChattingRoom from './components/ChattingRoom/ChattingRoom';
import { CustomerMemo } from './components/CustomerMemo/CustomerMemo';
import { CustomerReservation } from './components/CustomerReservation/CustomerReservation';
import { CustomerReservationChatRoomNull } from './components/CustomerReservation/CustomerReservationChatRoomNull';
import Manual from './components/Manual/Manual';
import ManualToggleButton from './components/ManualToggleButton/ManualToggleButton';
import { SupportBot } from './components/SupportBot/SupportBot';
import { MIN_LAYOUT_WIDTH } from './constants/layout';
import useIsManualOpen from './hooks/use-is-manual-open';
import { chatRoomIDSelector } from './states/selected-chat-room';

export default function CustomerChatContainer() {
  const chatRoomID = useAtomValue(chatRoomIDSelector);

  const { isManualOpen, toggleManual } = useIsManualOpen();

  return (
    <div className='h-full w-full flex-row-center'>
      <div className='h-full w-[400px] min-w-[400px]'>
        <ChattingList />
      </div>
      {isManualOpen && (
        <div className='h-full w-[400px] min-w-[400px] border-l border-l-white400'>
          <Manual />
        </div>
      )}
      <div className='relative flex h-full w-full border border-white400'>
        <ChattingRoom minWidth={MIN_LAYOUT_WIDTH} isManualOpen={isManualOpen} />
        <ManualToggleButton isManualOpen={isManualOpen} onClick={toggleManual} />
      </div>
      <div className='flex h-full w-[400px] min-w-[400px] flex-col'>
        {chatRoomID === null ? (
          <CustomerReservationChatRoomNull />
        ) : (
          <>
            <CustomerMemo />
            <CustomerReservation />
          </>
        )}
        <SupportBot />
      </div>
    </div>
  );
}
