import { useUpdateUserInfo } from '@templates/UserInfoSetting/containers/AccountSetting/hooks/use-update-user-info';
import { useUserPhoneVerify } from '@templates/UserInfoSetting/containers/AccountSetting/hooks/use-user-phone-verify';
import type { StatusMessage } from '@templates/UserInfoSetting/containers/AccountSetting/types/status-message';
import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';
import OutlinedButton from 'afterdoc-design-system/components/Atoms/Button/OutlinedButton';
import TextInput from 'afterdoc-design-system/components/Atoms/Input/TextInput';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { dialogService } from 'afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import { useState } from 'react';
import { SHARED_UTILS } from 'utils/utils';

export default function UserInfoUpdatePhone() {
  const [phone, setPhone] = useState('');
  const [code, setCode] = useState('');

  const [phoneID, setPhoneID] = useState('');

  const [requestCodeStatus, setRequestCodeStatus] = useState<StatusMessage>({ status: 'none' });
  const [verifyPhoneCodeStatus, setVerifyPhoneCodeStatus] = useState<StatusMessage>({
    status: 'none',
  });

  const { requestPhoneAuthNum, requestVerifyPhoneCode } = useUserPhoneVerify();
  const { updateUserInfo } = useUpdateUserInfo();

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(e.target.value.replace(/\D/g, ''));
    if (requestCodeStatus.status !== 'none') {
      setRequestCodeStatus({ status: 'none' });
    }
  };

  const handleCodeNumChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVerifyPhoneCodeStatus({ status: 'none' });
    setCode(e.target.value.replace(/\D/g, ''));
  };

  const handleAuthButtonClick = () => {
    setCode('');
    setRequestCodeStatus({ status: 'none' });
    setVerifyPhoneCodeStatus({ status: 'none' });

    requestPhoneAuthNum({
      param: { phone: phone, isRequestForChangingPhone: true },
      onSuccessCallback: handleRequestCodeSuccess,
      onFailureCallback: handleRequestCodeFailure,
    });
  };

  const handleRequestCodeSuccess = (phoneID: string, isSendingSuccess: boolean) => {
    if (isSendingSuccess) {
      setRequestCodeStatus({ status: 'success', message: '인증번호가 발송되었습니다.' });
      setPhoneID(phoneID);
    }
  };

  const handleRequestCodeFailure = (error: Error) => {
    const message = SHARED_UTILS.api.isApiErrorWithCode(error, 1504)
      ? error.message
      : '인증번호 발송에 실패했습니다. 다시 시도해 주세요.';
    setRequestCodeStatus({
      status: 'failure',
      message: message,
    });
  };

  const handleConfirmButtonClick = () => {
    setVerifyPhoneCodeStatus({ status: 'none' });

    requestVerifyPhoneCode({
      param: { phoneID, code },
      onSuccessCallback: handleVerificationSuccess,
      onFailureCallback: handleVerificationFailure,
    });
  };

  const handleVerificationSuccess = () => {
    updateUserInfo({
      param: { phone: phone },
      onSuccessCallback: () => {
        closeCurrentDialog();
        toastService.successMsg({ text: '휴대폰번호가 변경되었습니다.' });
      },
      onFailureCallback: () => {
        toastService.errorMsg({ text: '휴대폰번호 변경에 실패했습니다. 다시 시도해 주세요.' });
      },
    });
  };

  const handleVerificationFailure = (error: Error) => {
    const message = SHARED_UTILS.api.isApiErrorWithCode(error, 1515)
      ? '인증번호가 일치하지 않습니다. 다시 입력해 주세요.'
      : '일시적인 오류가 발생했습니다. 다시 시도해 주세요.';

    setVerifyPhoneCodeStatus({
      status: 'failure',
      message: message,
    });
  };

  const closeCurrentDialog = () => {
    dialogService.popById('user-info-update-dialog');
  };

  return (
    <>
      <div className='flex h-full flex-grow flex-col gap-16'>
        <div className='flex flex-col gap-4'>
          <div className='flex items-center gap-10'>
            <TextInput
              label={{ width: 80, children: '새 휴대폰번호', position: 'horizontal' }}
              className='flex-grow'
              value={phone}
              placeholder={'예) 01012345678'}
              onChange={handlePhoneChange}
            />
            <OutlinedButton className='h-30 w-88 px-0' onClick={handleAuthButtonClick}>
              인증요청
            </OutlinedButton>
          </div>
          {requestCodeStatus.status !== 'none' && (
            <span
              className={`ml-90 text-Body10 ${requestCodeStatus.status === 'success' ? 'text-blue500' : 'text-red500'}`}>
              {requestCodeStatus.message}
            </span>
          )}
        </div>
        <div className='flex flex-col gap-4'>
          <TextInput
            maxLength={6}
            label={{ width: 80, children: '인증번호', position: 'horizontal' }}
            placeholder='인증번호 6자리'
            value={code}
            onChange={handleCodeNumChange}
          />
          {verifyPhoneCodeStatus.status !== 'none' && (
            <span
              className={`ml-90 text-Body10 ${verifyPhoneCodeStatus.status === 'success' ? 'text-blue500' : 'text-red500'}`}>
              {verifyPhoneCodeStatus.message}
            </span>
          )}
        </div>
        <div className='mx-auto mt-auto flex gap-10'>
          <ContainedButton btnColor={'secondary'} onClick={closeCurrentDialog}>
            취소
          </ContainedButton>
          <ContainedButton
            disabled={phone.length < 10 || code.length !== 6}
            onClick={handleConfirmButtonClick}>
            확인
          </ContainedButton>
        </div>
      </div>
    </>
  );
}
