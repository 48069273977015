import type { VideoContentCategoryParentInfos } from '@apis/swaggers/swagger-docs';

export interface ContentLayer {
  title: string;
  isOpen: boolean;
  items: ContentSubLayer[];
}

export interface ContentSubLayer {
  key: string | defaultKey;
  text: string;
  data?: VideoContentCategoryParentInfos;
}

export type defaultKey = 'event' | 'homeCare' | 'notice' | 'popup';

export const validKeys: readonly defaultKey[] = ['event', 'homeCare', 'notice', 'popup'];

export const CONTENT_PANEL_ITEMS: ContentLayer[] = [
  {
    title: '이벤트',
    isOpen: true,
    items: [
      { text: '이벤트', key: 'event' },
      { text: '홈케어 상품', key: 'homeCare' },
    ],
  },
  {
    title: '공지사항',
    isOpen: true,
    items: [
      { text: '공지사항', key: 'notice' },
      { text: '팝업', key: 'popup' },
    ],
  },
];
