import { useAtomValue, useSetAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { useEffect, useRef, useState } from 'react';
import type { Socket } from 'socket.io-client';
import { useUserInfo } from 'web/shared/hooks/use-user-info';
import { selectedMessageIDState } from 'web/templates/CustomerChat/components/SupportBot/states/selected-message-id';
import { chatRoomIDSelector } from 'web/templates/CustomerChat/states/selected-chat-room';
import type { ChattingResponse, TextChattingResponse } from 'web/templates/CustomerChat/types';
import { isActivateChatTranslateAtom } from '../../../../../states/isChatTranslate';
import { REQUEST_ROOM_TYPE_MAP } from '../../../../ChattingSending/constants/types-codes';
import { chattingRequestState } from '../../../states/chatting-request';
import { isSendingMessagesState } from '../../../states/isSendingMessages';
import type {
  MessageType,
  PayloadTypeByMessageType,
} from '../components/ChattingMessage/types/message';
import { createSocketConnection } from '../functions/createSocketConnection';
import { withLogging } from '../functions/withLogging';
import { isSendingTranslateState } from '../states/is-sending-translate';
import { useInfiniteChatData } from './use-infinite-chat-data';

type WsParams = {
  enableListening?: boolean;
};

export const useHandleChatSocket = ({ enableListening = true }: WsParams = {}) => {
  const { userId: userID } = useUserInfo();
  const chatRoomID = useAtomValue(chatRoomIDSelector);
  const resetSelectedMessageID = useResetAtom(selectedMessageIDState);
  const [isProcessing, setIsProcessing] = useState(false);
  const [resQueue, setResQueue] = useState<Array<ChattingResponse | TextChattingResponse>>([]);

  const { refetch } = useInfiniteChatData();

  const socketRef = useRef<Socket | null>(null);
  const req = useAtomValue(chattingRequestState);
  const [res, setRes] = useState<ChattingResponse | TextChattingResponse | null>(null);
  const resetReq = useResetAtom(chattingRequestState);

  const isActivateChatTranslate = useAtomValue(isActivateChatTranslateAtom);
  const setIsSendingTranslate = useSetAtom(isSendingTranslateState);
  const setIsSendingMessages = useSetAtom(isSendingMessagesState);

  const isDisabled = userID === '' || chatRoomID === null;

  //이 부분은 비동기로 res가 한번 데이터가 바뀌기만 하면 되는 부분
  useEffect(() => {
    if (res) {
      setRes(null);
      resetReq(); //이걸 넣으면 로딩이 안사라짐.
    }
  }, [res, refetch]);

  // 큐를 useEffect로 반복문을 인위적으로 만들어 처리
  useEffect(() => {
    if (resQueue.length === 0 || isProcessing) return;

    const processNextMessage = async () => {
      setIsProcessing(true);
      const nextRes = resQueue[0]; // 큐의 첫 번째 메시지를 가져옴
      setRes(nextRes); // 현재 처리 중인 메시지를 상태로 저장
      setResQueue((prevQueue) => prevQueue.slice(1)); // 첫 번째 메시지를 큐에서 제거

      // 처리 로직
      const isIsTranslatedKey = nextRes.isTranslated ?? false;
      const isSent = nextRes.isSent ?? false;
      // const isLoginUser = nextRes.userID === userID;

      if (isIsTranslatedKey) {
        setIsSendingTranslate(false);
      } else if (isSent) {
        setIsSendingMessages(false);
      }

      // 완료 후 처리 상태 초기화
      setIsProcessing(false);
    };

    processNextMessage();
  }, [resQueue, isProcessing]);

  useEffect(() => {
    if (isDisabled) return;

    socketRef.current = createSocketConnection({ userID, chatRoomID });

    if (!enableListening) return;

    const handleReceivedMessage = async (event: ChattingResponse) => {
      const isSent = event.isSent ?? false;

      //서버에서 res로 보낼 목적이 분명한 res를 받았을 때만 처리
      if (isSent) {
        setResQueue((prev) => [...prev, event]); // 큐에 메시지를 추가
        return;
      }
    };

    const socket = socketRef.current;

    if (socket) {
      // 특정 이벤트(msg_saas)에 대한 핸들러 // 백엔드에서 이전 버전과의 공존을 위해 msg, msg_saas 둘다 보내고 있어 불가피 하게 클라이언트에서 분기 처리해 활용
      // socket.on('msg_saas', handleReceivedMessage);

      // 모든 이벤트를 가로채는 핸들러 추가
      socket.onAny((eventName, ...args) => {
        // 필요에 따라 특정 이벤트 이름별 추가 로직 작성 가능
        if (eventName === 'msg_saas') {
          handleReceivedMessage(args[0] as ChattingResponse);
        }
      });
    }

    return () => {
      if (socket) {
        // socket.off('msg_saas', handleReceivedMessage);
        socket.offAny();
        socket.disconnect();
        socket.close();
        setIsSendingMessages(false);
        socketRef.current = null;
      }
    };
  }, [chatRoomID, userID, enableListening, isDisabled]);

  const sendMessage = <T extends MessageType>(typeAndMessage: PayloadTypeByMessageType<T>) => {
    console.info('sendMessage', typeAndMessage);
    if (isDisabled) return;

    setIsSendingMessages(true);
    resetSelectedMessageID();

    const { payload } = typeAndMessage;

    switch (typeAndMessage.type) {
      case REQUEST_ROOM_TYPE_MAP.AFTERDOC.TEXT_TYPE: {
        // setReq(typeAndMessage);
        isActivateChatTranslate && setIsSendingTranslate(true);
        break;
      }
    }

    if (socketRef.current) {
      socketRef.current.emit('msg_saas', payload);
      console.info('send msg', payload);
    } else {
      withLogging({
        msg: 'WebSocket 연결이 되어있지 않습니다.',
        type: 'error',
      });
    }
  };

  return { sendMessage, req, res };
};
