interface EventTitleProps {
  title: string;
}

export default function EventHomeCareTitle({ title }: EventTitleProps) {
  return (
    <div className='flex h-full w-full max-w-xs items-center overflow-hidden text-ellipsis whitespace-nowrap'>
      <p className='truncate text-Header12 text-black500'>{title}</p>
    </div>
  );
}
