import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import type {
  SingleHospitalPushSettingHandlerParams,
  SingleHospitalPushSettingHandlerResponse,
} from '@apis/swaggers/swagger-docs';
import { useNavigationBlocker } from '@shared/hooks/use-navigation-blocker';
import { useSelectedHospitalInfo } from '@shared/hooks/use-selected-hospital-info';
import { useUserInfo } from '@shared/hooks/use-user-info';
import { useSuspenseQuery } from '@tanstack/react-query';
import AdminChatNotification from '@templates/UserInfoSetting/containers/NotificationSetting/components/AdminChatNotification/AdminChatNotification';
import CustomerSupportNotification from '@templates/UserInfoSetting/containers/NotificationSetting/components/CustomerSupportNotification/CustomerSupportNotification';
import ReservationManagementNotification from '@templates/UserInfoSetting/containers/NotificationSetting/components/ReservationManagementNotification/ReservationManagementNotification';
import { areSettingsEqual } from '@templates/UserInfoSetting/containers/NotificationSetting/functions/are-settings-equal';
import { convertNotificationSettingData } from '@templates/UserInfoSetting/containers/NotificationSetting/functions/convert-notification-setting-data';
import { isNotificationSettingModifiedState } from '@templates/UserInfoSetting/containers/NotificationSetting/states/is-notification-setting-modified-state';
import { notificationSettingState } from '@templates/UserInfoSetting/containers/NotificationSetting/states/notification-setting-state';
import { useAtom } from 'jotai';
import { useAtomValue } from 'jotai/index';
import { useResetAtom } from 'jotai/utils';
import { useEffect, useRef, useState } from 'react';
import { SHARED_UTILS } from 'utils/utils';

type ResType = SingleHospitalPushSettingHandlerResponse['data'];

const fetchHospitalPushSetting = async (param: SingleHospitalPushSettingHandlerParams) => {
  const { data } = await apiClient.v3.singleHospitalPushSettingHandler(param);
  return SHARED_UTILS.api.checkApiResponse<ResType>(data);
};

export default function NotificationSettingContainer() {
  const { userId: userID } = useUserInfo();
  const { hospitalID } = useSelectedHospitalInfo();

  const resetNotificationSetting = useResetAtom(notificationSettingState);

  const [notificationSetting, setNotificationSetting] = useAtom(notificationSettingState);

  const [isModified, setIsModified] = useAtom(isNotificationSettingModifiedState);

  const initialNotificationSetting = useRef<ResType | null>(null);

  const { data } = useSuspenseQuery({
    queryKey: [QUERY_KEY.singleHospitalPushSettingHandler, { hospitalID, userID }] as const,
    queryFn: ({ queryKey }) => fetchHospitalPushSetting(queryKey[1]),
  });

  useEffect(() => {
    if (data) {
      const initialData = convertNotificationSettingData(data);
      setNotificationSetting(initialData);
      initialNotificationSetting.current = initialData;
    }
  }, [data]);

  useEffect(() => {
    if (initialNotificationSetting.current && notificationSetting) {
      const isEquals = areSettingsEqual(initialNotificationSetting.current, notificationSetting);
      setIsModified(!isEquals);
    }
  }, [notificationSetting]);

  useEffect(() => {
    return () => {
      resetNotificationSetting();
    };
  }, []);

  useNavigationBlocker({
    shouldBlock: isModified,
  });

  return (
    <div className='flex flex-grow flex-col gap-40 bg-white50 px-80 py-40'>
      <CustomerSupportNotification />
      <ReservationManagementNotification />
      <AdminChatNotification />
    </div>
  );
}
