import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import type { ApiTreatmentTagsElParams } from '@apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from '@shared/hooks/use-selected-hospital-info';
import { useSuspenseQuery } from '@tanstack/react-query';
import {
  initialTreatmentTagsState,
  treatmentTagsState,
} from '@templates/HospitalSetting/containers/ServiceSetting/containers/TreatmentTag/states/hospital-treatment-tags';
import { useAtom, useSetAtom } from 'jotai/index';
import { useEffect } from 'react';
import { SHARED_UTILS } from 'utils/utils';
import TreatmentTagListItem from 'web/templates/HospitalSetting/containers/ServiceSetting/containers/TreatmentTag/components/TreatmentTagList/TreatmentTagListItem/TreatmentTagListItem';

const fetchTreatmentTagsList = async (params: ApiTreatmentTagsElParams) => {
  const response = await apiClient.v3.apiTreatmentTagsEl(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const TEMP_TAG = {
  tagId: 'temp1',
  name: '',
  color: '#007AFF',
};

export default function TreatmentTagList() {
  const { hospitalID } = useSelectedHospitalInfo();

  const [treatmentTags, setTreatmentTags] = useAtom(treatmentTagsState);
  const setInitialTreatmentTags = useSetAtom(initialTreatmentTagsState);

  const { data } = useSuspenseQuery({
    queryKey: [QUERY_KEY.apiTreatmentTagsEl, { hospitalID }] as const,
    queryFn: ({ queryKey }) => fetchTreatmentTagsList(queryKey[1]),
    select: (data) => {
      return [TEMP_TAG, ...(data.treatmentTags ?? [])];
    },
  });

  useEffect(() => {
    if (data) {
      setInitialTreatmentTags(data.slice(1));
      setTreatmentTags(data);
    }
  }, [data, setInitialTreatmentTags, setTreatmentTags]);

  return (
    <>
      <div className='flex flex-col gap-10 px-20 pt-10 pb-20'>
        {treatmentTags.map((tag, index) => (
          <TreatmentTagListItem key={tag.tagId} index={index} treatmentTag={tag} />
        ))}
      </div>
    </>
  );
}
