import { useEffect, useState } from 'react';

export const useToggleSelect = (initialSelectedIDs: string[]) => {
  const [selectedUserIDs, setSelectedUsers] = useState<string[]>([]);

  const isInitialSelected = (id: string) => initialSelectedIDs.includes(id);

  const isSelected = (id: string) => selectedUserIDs.includes(id);

  const toggleSelect = (e: React.MouseEvent<HTMLLIElement, MouseEvent>, id: string) => {
    e.stopPropagation();
    if (isInitialSelected(id)) return;
    setSelectedUsers((prev) => {
      const index = prev.findIndex((prevID) => prevID === id);

      if (index >= 0) {
        return prev.filter((prevID) => prevID !== id);
      }
      return [...prev, id];
    });
  };

  useEffect(() => {
    if (!initialSelectedIDs && selectedUserIDs.length === 0) {
      setSelectedUsers(initialSelectedIDs);
      return;
    }
    const missingIDs = initialSelectedIDs.filter((id) => !selectedUserIDs.includes(id));
    if (missingIDs.length > 0) {
      setSelectedUsers([...selectedUserIDs, ...missingIDs]);
    }
  }, [selectedUserIDs, initialSelectedIDs]);

  return { selectedUserIDs, toggleSelect, isInitialSelected, isSelected };
};
