import * as React from "react";
import type { SVGProps } from "react";
const SvgLinked = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 19 19"
    {...props}
  >
    <rect width={18} height={18} x={0.5} y={0.5} fill="#F7F7F7" rx={5.5} />
    <rect width={18} height={18} x={0.5} y={0.5} stroke="#fff" rx={5.5} />
    <path
      fill="#A3A3A3"
      d="M8.795 10.205a.487.487 0 0 1 0 .71.504.504 0 0 1-.71 0 2.5 2.5 0 0 1 0-3.535l1.77-1.77a2.5 2.5 0 0 1 3.535 0 2.5 2.5 0 0 1 0 3.535l-.745.745c.005-.41-.06-.82-.2-1.21l.235-.24a1.49 1.49 0 0 0 0-2.12 1.49 1.49 0 0 0-2.12 0L8.795 8.085a1.49 1.49 0 0 0 0 2.12m1.41-2.12a.504.504 0 0 1 .71 0 2.5 2.5 0 0 1 0 3.535l-1.77 1.77a2.5 2.5 0 0 1-3.535 0 2.5 2.5 0 0 1 0-3.535l.745-.745c-.005.41.06.82.2 1.215l-.235.235a1.49 1.49 0 0 0 0 2.12 1.49 1.49 0 0 0 2.12 0l1.765-1.765a1.49 1.49 0 0 0 0-2.12.485.485 0 0 1 0-.71"
    />
  </svg>
);
export default SvgLinked;
