import { useChatMessageContext } from '../ChatMessageContext';
import ContentText from './components/ContentText';
import MessageTitle from './components/MessageTitle';

export default function ReservationMessage() {
  const { isMine, messageWidthStyle, contentParts } = useChatMessageContext();
  return (
    <>
      <div
        className={`mt-10 rounded-r10 bg-white50 px-16 py-10 shadow-modal ${isMine ? 'bg-[rgba(255,245,208,1)]' : 'bg-white50'}`}
        style={messageWidthStyle}>
        <MessageTitle title='예약 상담' />
        <ContentText parts={contentParts} />
      </div>
    </>
  );
}
