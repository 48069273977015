import Scrollbar from '@afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import DOMPurify from 'dompurify';
import NoSupportBotContents from '../NoSuportBotContents/NoSupportBotContents';

interface AlgorithmGuideProps {
  consultationGuide: string;
}

export default function AlgorithmGuide({ consultationGuide }: AlgorithmGuideProps) {
  const sanitizedConsultationGuide = DOMPurify.sanitize(consultationGuide ?? '');

  const isConsultationGuideEmpty = consultationGuide === undefined || consultationGuide === '';

  if (isConsultationGuideEmpty) return <NoSupportBotContents />;

  return (
    <Scrollbar disabledX>
      <div className='editor-viewer p-10'>
        <div
          className='content break-words text-black700'
          // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
          dangerouslySetInnerHTML={{ __html: sanitizedConsultationGuide }}
        />
      </div>
    </Scrollbar>
  );
}
