import NotificationSettingItem, {
  type ItemType,
} from '@templates/UserInfoSetting/containers/NotificationSetting/components/NotificationSettingItem';
import Title from 'afterdoc-design-system/components/Molecules/Title/Title';

const notificationSettings: ItemType[] = [
  { key: 'onReservationNew', label: '신규 예약이 등록됐을 때 알림을 받습니다.' },
  { key: 'onReservationChange', label: '예약이 변경됐을 때 알림을 받습니다.' },
  { key: 'onReservationCancel', label: '예약이 취소됐을 때 알림을 받습니다.' },
];

export default function ReservationNotificationSettings() {
  return (
    <div>
      <Title
        title={'예약관리'}
        subTitle={'알림받을 계정으로 설정되어 있는 경우, 아래 설정에 따라 알림을 받을 수 있습니다.'}
      />
      <div className='mx-20 mt-10 flex flex-col gap-10'>
        {notificationSettings.map((setting) => (
          <NotificationSettingItem key={setting.key} fieldKey={setting.key} label={setting.label} />
        ))}
      </div>
    </div>
  );
}
