import { SHARED_UTILS } from '@shared-utils/utils';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useSetAtom } from 'jotai';
import { useEffect, useRef } from 'react';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type {
  ApiServiceSettingsElCountriesParams,
  ApiServiceSettingsElToBeDisplayedPatientsFieldsParams,
  ApiTreatmentTagsElParams,
} from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import { COUNTRY_LIMIT } from 'web/templates/CustomerManagement/constants/country-limit';
import CustomerManagementEditableTableHeader from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementEditableTableHeader/CustomerManagementEditableTableHeader';
import CustomerManagementSaveFooter from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementSaveFooter/CustomerManagementSaveFooter';
import {
  focusedCellState,
  selectedCellState,
} from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/states/table';
import CustomerManagementEditableTableBodyContainer from './containers/CustomerManagementEditableTableBody.container';

const fetchTreatmentTagsList = async (params: ApiTreatmentTagsElParams) => {
  const response = await apiClient.v3.apiTreatmentTagsEl(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const fetchCountriesList = async (params: ApiServiceSettingsElCountriesParams) => {
  const response = await apiClient.v3.apiServiceSettingsElCountries(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const fetchDisplayedPatientsFields = async (
  params: ApiServiceSettingsElToBeDisplayedPatientsFieldsParams,
) => {
  const response = await apiClient.v3.apiServiceSettingsElToBeDisplayedPatientsFields(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};
export default function CustomerManagementEditableTableContainer() {
  const { hospitalID } = useSelectedHospitalInfo();

  const { data: usingColumns } = useSuspenseQuery({
    queryKey: [QUERY_KEY.apiServiceSettingsElToBeDisplayedPatientsFields, { hospitalID }] as const,
    queryFn: ({ queryKey }) => fetchDisplayedPatientsFields(queryKey[1]),
  });

  const {
    data: { treatmentTags },
  } = useSuspenseQuery({
    queryKey: [QUERY_KEY.apiTreatmentTagsEl, { hospitalID }] as const,
    queryFn: ({ queryKey }) => fetchTreatmentTagsList(queryKey[1]),
  });

  const { data: countriesList } = useSuspenseQuery({
    queryKey: [
      QUERY_KEY.apiServiceSettingsElCountries,
      { hospitalID, limit: COUNTRY_LIMIT },
    ] as const,
    queryFn: ({ queryKey }) => fetchCountriesList(queryKey[1]),
  });

  const tableRef = useRef<HTMLDivElement>(null);

  const setSelectedCell = useSetAtom(selectedCellState);
  const setFocusedCell = useSetAtom(focusedCellState);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const targetElement = event.target as HTMLElement;

      if (tableRef.current && !tableRef.current.contains(targetElement)) {
        setSelectedCell({
          row: -1,
          col: -1,
        });
        setFocusedCell({
          row: null,
          col: null,
        });
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [tableRef]);

  return (
    <>
      <div
        className={customTwMerge(
          'flex h-[calc(100vh-155px)] w-full justify-center border-line-table',
        )}>
        <div className='flex flex-col'>
          <table className='w-full table-fixed'>
            <CustomerManagementEditableTableHeader {...usingColumns} />
          </table>
          <CustomerManagementEditableTableBodyContainer
            countriesList={countriesList}
            treatmentTags={treatmentTags}
            {...usingColumns}
          />
        </div>
      </div>
      <CustomerManagementSaveFooter />
    </>
  );
}
