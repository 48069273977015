import Divider from '@afterdoc-design-system/components/Atoms/Divider/Divider';
import NoData from '@afterdoc-design-system/components/Molecules/NoData/NoData';
import { SHARED_UTILS } from '@shared-utils/utils';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { FormProvider, useForm } from 'react-hook-form';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { ApiManualsElSingleParams } from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import { useUserInfo } from 'web/shared/hooks/use-user-info';
import ManualCautionImages from 'web/templates/HospitalManual/components/HospitalManualContent/components/ManualCautionImages';
import ManualContentEditor from 'web/templates/HospitalManual/components/HospitalManualContent/components/ManualContentEditor';
import ManualTreatmentTagContent from 'web/templates/HospitalManual/components/HospitalManualContent/components/ManualTreatmentTagContent';
import SaveTreatmentTagContentFooter from 'web/templates/HospitalManual/components/HospitalManualContent/components/SaveTreatmentTagContentFooter';
import { selectedTagIdState } from 'web/templates/HospitalManual/components/HospitalManualPanel/states/selected-tag-id';

export type UpdateSingleManualAPIFormValues = Parameters<
  typeof apiClient.v3.apiManualsElUpsertSingle
>[0];

const fetchManualsSingle = async (params: ApiManualsElSingleParams) => {
  const response = await apiClient.v3.apiManualsElSingle(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export default function HospitalManualContent() {
  const { authorizationTypeID } = useUserInfo();

  const canUserCreateManual = authorizationTypeID?.canCreateManual;

  const methods = useForm<UpdateSingleManualAPIFormValues>({
    mode: 'onChange',
  });

  const { hospitalID } = useSelectedHospitalInfo();
  const selectedTagId = useAtomValue(selectedTagIdState);

  const { data } = useSuspenseQuery({
    queryKey: [
      QUERY_KEY.apiManualsElSingle,
      {
        hospitalID,
        treatmentTagId: selectedTagId,
      },
    ] as const,
    queryFn: ({ queryKey }) => fetchManualsSingle(queryKey[1]),
  });

  const { tagName, tagColor, cautionImages, fileID, manualContent } = data;

  if (!selectedTagId) {
    return (
      <div className='mx-[100px] flex h-full items-center justify-center bg-white50'>
        <NoData
          title='표시할 내용이 없습니다.'
          iconProps={{
            name: 'warning',
            size: 48,
            color: 'white600',
          }}
        />
      </div>
    );
  }

  return (
    <FormProvider {...methods}>
      <div className='relative'>
        <div
          className={customTwMerge(
            'mx-auto w-[1000px] bg-white50 px-[100px]',
            canUserCreateManual ? 'h-[calc(100%-70px)]' : 'h-full',
          )}>
          <ManualTreatmentTagContent tagName={tagName} tagColor={tagColor} />
          <Divider type='line' margin={-100} className='bg-white400' />
          <ManualContentEditor key={selectedTagId} defaultManualContent={manualContent} />
          <ManualCautionImages fileID={fileID} originalCautionImages={cautionImages} />
        </div>
        {canUserCreateManual && <SaveTreatmentTagContentFooter originalImages={cautionImages} />}
      </div>
    </FormProvider>
  );
}
