import { useLayoutEffect, useState } from 'react';

interface UseRenderingPositionParams {
  renderingRef: React.RefObject<HTMLDivElement>;
  renderingHeight: number;
  offSet?: number;
  isOpen?: boolean;
}

export const useRenderingPosition = ({
  renderingRef,
  renderingHeight,
  offSet = 190,
}: UseRenderingPositionParams) => {
  const [position, setPosition] = useState<'top' | 'bottom'>('bottom');

  useLayoutEffect(() => {
    if (!renderingRef.current) return;

    const rect = renderingRef.current.getBoundingClientRect();
    const viewportHeight = window.innerHeight;
    const top = rect.y + renderingHeight + offSet;

    if (top > viewportHeight) {
      setPosition('top');
    } else {
      setPosition('bottom');
    }
  }, [renderingRef.current, renderingHeight]);

  return { position };
};
