import type { CreateUpdateNoticeParamType } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Notice/types/create-update-notice-param-type';

export const DEFAULT_NOTICE_PARAMS: CreateUpdateNoticeParamType = {
  _id: '',
  hospitalID: '',
  title: '',
  content: '',
  isSendMessage: false,
  isSendToHospital: false,
};
