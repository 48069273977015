import type { PanelListProps } from '@afterdoc-design-system/components/Atoms/PanelList/PanelList';
import PanelList from '@afterdoc-design-system/components/Atoms/PanelList/PanelList';
import { SHARED_UTILS } from '@shared-utils/utils';
import { useSuspenseQuery } from '@tanstack/react-query';
import { TABLE_TAB_ITEMS } from '@templates/CustomerManagement/containers/BoardPanel/containers/shared/containers/SearchFilter/constants/tab-items';
import {
  type TableTabValue,
  tabsState,
} from '@templates/CustomerManagement/containers/BoardPanel/containers/shared/containers/SearchFilter/states/tabs';
import { selectedCardInfoState } from '@templates/CustomerManagement/containers/ControlPanel/containers/OngoingConsultingAutomation/state/selected-card-info';
import { useAtom } from 'jotai';
import { useSetAtom } from 'jotai/index';
import { useResetAtom } from 'jotai/utils';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { ApiPatientsElUntaggedCountParams } from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import { selectedSearchCriteriaState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/containers/SearchFilter/states/selected-search-criteria';
import { useResetTable } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/hooks/use-reset-table';
import { clickedPanelIndexState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/states/panel';
import { DEFAULT_PANEL_ITEMS } from '../constants/panel-items';

const fetchUnTaggedPatientsCount = async (params: ApiPatientsElUntaggedCountParams) => {
  const response = await apiClient.v3.apiPatientsElUntaggedCount(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

type PanelItem = PanelListProps['layers'][number];

function CustomerManagementPanel() {
  const { hospitalID } = useSelectedHospitalInfo();
  const [searchParams, setSearchParams] = useSearchParams();

  const resetSelectedSearchCriteria = useResetAtom(selectedSearchCriteriaState);
  const resetSelectedCardInfo = useResetAtom(selectedCardInfoState);

  const resetClickedPanelIndex = useResetAtom(clickedPanelIndexState);
  const resetSelectedTab = useResetAtom(tabsState);

  const [clickedPanelIndex, setClickedPanelIndex] = useAtom(clickedPanelIndexState);

  const setSelectedTab = useSetAtom(tabsState);

  const [panelItems, setPanelItems] = useState<PanelItem[]>(DEFAULT_PANEL_ITEMS);

  const isFromSpecificRoute = useRef(false);

  const parsedParams = useMemo(
    () => ({
      tabTitle: searchParams.get('tabTitle'),
      tableTab: searchParams.get('tableTab'),
    }),
    [searchParams],
  );

  const { data } = useSuspenseQuery({
    queryKey: [QUERY_KEY.apiPatientsElUntaggedCount, { hospitalID }] as const,
    queryFn: ({ queryKey }) => fetchUnTaggedPatientsCount(queryKey[1]),
    staleTime: 0,
    gcTime: 0,
  });

  const { openLeaveWithoutSavingModal } = useResetTable();

  const shouldProceedWithClick = async (index: number) => {
    return new Promise<boolean>((resolve) => {
      const specificRoute = isFromSpecificRoute.current;
      isFromSpecificRoute.current = false;

      if (!specificRoute) {
        if (clickedPanelIndex === index) {
          resolve(false);
          return;
        }

        if (clickedPanelIndex === 1 && index === 0) {
          resetSelectedSearchCriteria();
          resetSelectedCardInfo();
          setClickedPanelIndex(index);
          resolve(true);
          return;
        }
      }

      openLeaveWithoutSavingModal({
        cb: () => {
          resetSelectedSearchCriteria();
          resetSelectedCardInfo();
          setClickedPanelIndex(index);
          resolve(true);
        },
        cancelCb: () => {
          resolve(false);
        },
      });
    });
  };

  useEffect(() => {
    const updatedPanelItems = DEFAULT_PANEL_ITEMS.map((item, index) =>
      index === 1 ? { ...item, rightContent: data?.count ?? 0 } : item,
    ).map((item, index) => ({
      ...item,
      state: index === clickedPanelIndex ? ('focus' as const) : ('default' as const),
    }));
    setPanelItems(updatedPanelItems);
  }, [data, clickedPanelIndex]);

  useEffect(() => {
    setClickedPanelIndex(0);
    return () => {
      resetClickedPanelIndex();
      resetSelectedTab();
    };
  }, []);

  useEffect(() => {
    const processTabTitle = async () => {
      const { tabTitle, tableTab } = parsedParams;
      if (tabTitle) {
        const matchedIndex = DEFAULT_PANEL_ITEMS.findIndex(
          (item) => item.title === tabTitle.replace(/'/g, ''),
        );
        if (matchedIndex >= 0) {
          isFromSpecificRoute.current = true;
          const canProceed = await shouldProceedWithClick(matchedIndex);
          if (canProceed && tableTab) {
            if (TABLE_TAB_ITEMS.some((item) => item.value === tableTab.replace(/'/g, ''))) {
              setSelectedTab(tableTab as TableTabValue);
            }
          }
        }
        setSearchParams({}, { replace: true });
      }
    };
    processTabTitle();
  }, [parsedParams]);

  return <PanelList layers={panelItems} shouldProceedWithClick={shouldProceedWithClick} />;
}

export default CustomerManagementPanel;
