import NoData from '@afterdoc-design-system/components/Molecules/NoData/NoData';
import { useAtomValue } from 'jotai';
import { type ReactNode, useCallback, useEffect, useMemo, useRef } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import type {
  ApiServiceSettingsElCountriesData,
  ApiServiceSettingsElToBeDisplayedPatientsFieldsData,
  ApiTreatmentTagsElData,
} from 'web/apis/swaggers/swagger-docs';
import {
  GENDER_DISPLAY,
  GENDER_OPTIONS,
  VISIT_DISPLAY,
  VISIT_OPTIONS,
} from 'web/shared/constants/options';
import { customerTableDisplayModeState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/states/customer-management-display-mode';
import { editableHeaderFilterState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/states/editable-header-filter';
import type { CustomerManagementTemporaryAPIFormValues } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/types/table';
import MultipleTagsSelectBoxTagDropdownForTable from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/components/MultipleTagsSelectBoxTagDropdownForTable/MultipleTagsSelectBoxTagDropdownForTable';
import SingleTagSelectBoxTagDropdownForTable from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/components/SingleTagSelectBoxTagDropdownForTable/SingleTagSelectBoxTagDropdownForTable';
import TableInput from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/components/TableInput';
import { searchTextState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/containers/SearchFilter/states/search';
import useTableCellInteraction, {
  type TableCellRefs,
} from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/hooks/use-table-cell-interaction';
import './CustomerManagementEditableTableBody.scss';

type SelectedCell = {
  row: number;
  col: number;
};

type FocusedCell = {
  row: number | null;
  col: number | null;
};

const renderIsSelected = (rowIndex: number, colIndex: number, selectedCell: SelectedCell) => {
  return selectedCell.row === rowIndex && selectedCell.col === colIndex;
};

const renderIsFocused = (rowIndex: number, colIndex: number, focusedCell: FocusedCell) => {
  return focusedCell.row === rowIndex && focusedCell.col === colIndex;
};

const getTableCellProps = (
  rowIndex: number,
  colIndex: number,
  selectedCell: SelectedCell,
  onClick: () => void,
  isViewerMode: boolean,
  className?: string,
) => ({
  className: `group border-line-table border-y border-r text-Body12 
    ${isViewerMode ? 'pointer-events-none' : 'hover:bg-blueLight'} 
    ${
      renderIsSelected(rowIndex, colIndex, selectedCell) && !isViewerMode
        ? '-outline-offset-1 bg-blue50 outline outline-1 outline-blue500'
        : ''
    } ${className}`,
  onClick: isViewerMode ? undefined : onClick,
});

type CustomerManagementEditableTableBodyProps = {
  countriesList: ApiServiceSettingsElCountriesData['data'];
  treatmentTagsList: ApiTreatmentTagsElData['data']['treatmentTags'];
  visibleRows: CustomerManagementTemporaryAPIFormValues;
} & ApiServiceSettingsElToBeDisplayedPatientsFieldsData['data'];

export default function CustomerManagementEditableTableBody({
  countriesList,
  treatmentTagsList,
  visibleRows,
  ...usingColumns
}: CustomerManagementEditableTableBodyProps) {
  const {
    usingChartNumber,
    usingFirstVisitStartDate,
    // usingCountryCode,
    usingBirthDay,
    usingGender,
    usingTreatmentTag,
    usingNationality,
  } = usingColumns;

  const { control, setValue } = useFormContext<{
    rows: CustomerManagementTemporaryAPIFormValues;
  }>();

  const inputRefs = useRef<TableCellRefs>([]);
  const setInputRef = useCallback(
    (rowIndex: number, colIndex: number, element: HTMLInputElement | HTMLDivElement | null) => {
      if (!inputRefs.current[rowIndex]) {
        inputRefs.current[rowIndex] = [];
      }
      inputRefs.current[rowIndex][colIndex] = element;
    },
    [],
  );

  const rows = useWatch({
    name: 'rows',
    control: control,
  });

  const editableHeaderFilter = useAtomValue(editableHeaderFilterState);
  const searchText = useAtomValue(searchTextState);
  const customerTableDisplayMode = useAtomValue(customerTableDisplayModeState);
  const isViewerMode = customerTableDisplayMode === 'VIEWER';

  const viewerModeClassName = isViewerMode ? 'pointer-events-none select-none' : '';
  const {
    selectedCell,
    focusedCell,
    handleCellClick,
    resetFocusedCell,
    handleChangeFocusedCell,
    handleChangeSelectedCell,
  } = useTableCellInteraction({
    maxRows: rows.length,
    isViewerMode,
    ...usingColumns,
    inputRefs,
  });

  // const countriesListWithDialingCodes = useMemo(() => {
  //   return countriesList.map((item) => ({
  //     name: `${SHARED_UTILS.text.truncateText(item.koreanCountryName ?? '', 10)}(+${item.internationalDialingCodes})`,
  //     id: item.countryId,
  //   }));
  // }, [countriesList]);

  const columnsToRender = useMemo(
    () => [
      { visible: true }, // 고객명
      { visible: usingChartNumber }, // 차트번호
      { visible: usingFirstVisitStartDate }, // 초/재진
      // { visible: usingCountryCode }, // 국가번호
      { visible: true }, // 휴대폰번호
      { visible: usingBirthDay }, // 생년월일
      { visible: usingGender }, // 성별
      { visible: usingTreatmentTag }, // 치료태그
      { visible: usingNationality }, // 국적
    ],
    [
      usingChartNumber,
      usingFirstVisitStartDate,
      // usingCountryCode,
      usingBirthDay,
      usingGender,
      usingTreatmentTag,
      usingNationality,
    ],
  );

  const activeColumns = useMemo(
    () =>
      columnsToRender
        .map((col, index) => (col.visible ? index : null))
        .filter((index) => index !== null),
    [columnsToRender],
  );

  useEffect(() => {
    if (selectedCell.row === null || selectedCell.col === null) return;

    const inputElement = inputRefs.current[selectedCell.row]?.[selectedCell.col];
    if (!inputElement) return;

    if (focusedCell.row === selectedCell.row && focusedCell.col === selectedCell.col) {
      inputElement.classList.remove('hidden-caret');
      inputElement.focus();
    } else {
      inputElement.classList.add('hidden-caret');
      inputElement.blur();
    }
  }, [selectedCell, focusedCell]);

  if (
    !visibleRows.length &&
    (!!searchText?.length || Object.values(editableHeaderFilter).some((v) => v !== undefined))
  ) {
    return (
      <tr>
        <td>
          <div className='h-[calc(100vh-500px)] flex-w-full-center'>
            <NoData
              iconProps={{
                name: 'warning',
                size: 48,
                color: 'white600',
              }}
              title='검색 결과가 없습니다.'
            />
          </div>
        </td>
      </tr>
    );
  }

  return (
    <tbody>
      {visibleRows.map((row) => {
        const { order, isFirstVisit, gender, defaultNationality, defaultTreatmentTags } = row;

        return (
          <tr key={order ?? uuidv4()}>
            <td
              key={order}
              className='w-60 border-line-table border-y border-r px-12 py-10 text-Body12'>
              {order}
            </td>
            {activeColumns.map((colIndex, index) => {
              const rowOrder = order ?? 0;
              switch (colIndex) {
                case 0:
                  return (
                    <TableCell
                      key={`name-${rowOrder - 1}`}
                      handleCellClick={() => handleCellClick(rowOrder - 1, index)}
                      selectedCell={selectedCell}
                      rowIndex={rowOrder - 1}
                      colIndex={index}
                      className='w-[260px]'>
                      <TableInput
                        value={row.name ?? ''}
                        onChange={(e) => {
                          setValue(`rows.${rowOrder - 1}.name`, e.target.value, {
                            shouldDirty: true,
                          });
                        }}
                        isViewerMode={isViewerMode}
                        ref={(el) => setInputRef(rowOrder - 1, index, el)}
                        isSelected={renderIsSelected(rowOrder - 1, index, selectedCell)}
                        isFocused={renderIsFocused(rowOrder - 1, index, focusedCell)}
                        type='text'
                        placeholder='예) 홍길동'
                        className={viewerModeClassName}
                      />
                    </TableCell>
                  );

                case 1:
                  return (
                    <TableCell
                      key={`chartNumber-${rowOrder - 1}`}
                      handleCellClick={() => handleCellClick(rowOrder - 1, index)}
                      rowIndex={rowOrder - 1}
                      colIndex={index}
                      selectedCell={selectedCell}
                      className='w-[140px]'>
                      <TableInput
                        value={row.chartNumber ?? ''}
                        onChange={(e) => {
                          setValue(`rows.${rowOrder - 1}.chartNumber`, e.target.value, {
                            shouldDirty: true,
                          });
                        }}
                        onDelete={() => {
                          setValue(`rows.${rowOrder - 1}.chartNumber`, '', { shouldDirty: true });
                        }}
                        isViewerMode={isViewerMode}
                        ref={(el) => setInputRef(rowOrder - 1, index, el)}
                        isFocused={renderIsFocused(rowOrder - 1, index, focusedCell)}
                        isSelected={renderIsSelected(rowOrder - 1, index, selectedCell)}
                        type='text'
                        placeholder='예) 00000'
                        className={viewerModeClassName}
                      />
                    </TableCell>
                  );

                case 2:
                  return (
                    <TableCell
                      key={`isFirstVisit-${rowOrder - 1}`}
                      handleCellClick={() => handleCellClick(rowOrder - 1, index)}
                      rowIndex={rowOrder - 1}
                      selectedCell={selectedCell}
                      colIndex={index}
                      className='w-[120px]'>
                      <SingleTagSelectBoxTagDropdownForTable
                        key={`isFirstVisit-${rowOrder - 1}`}
                        value={
                          isFirstVisit !== undefined && isFirstVisit !== null
                            ? {
                                name: isFirstVisit
                                  ? VISIT_DISPLAY.FIRST_VISIT
                                  : VISIT_DISPLAY.REVISIT,
                                id: isFirstVisit
                                  ? VISIT_DISPLAY.FIRST_VISIT
                                  : VISIT_DISPLAY.REVISIT,
                              }
                            : undefined
                        }
                        width={120}
                        onRemoveTag={() => {
                          handleChangeFocusedCell(rowOrder - 1, index);
                          handleChangeSelectedCell(rowOrder - 1, index);
                        }}
                        ref={(el) => setInputRef(rowOrder - 1, index, el)}
                        placeholder='선택'
                        defaultSelectedTag={
                          isFirstVisit !== undefined && isFirstVisit !== null
                            ? {
                                name: isFirstVisit
                                  ? VISIT_DISPLAY.FIRST_VISIT
                                  : VISIT_DISPLAY.REVISIT,
                              }
                            : undefined
                        }
                        isFocused={renderIsFocused(rowOrder - 1, index, focusedCell)}
                        isSelected={renderIsSelected(rowOrder - 1, index, selectedCell)}
                        tagOptions={VISIT_OPTIONS.map((option) => ({
                          name: option,
                          id: option,
                        }))}
                        onSelectedTagChange={(tag) => {
                          if (!tag) {
                            setValue(`rows.${rowOrder - 1}.isFirstVisit`, null, {
                              shouldDirty: true,
                            });
                            return;
                          }
                          setValue(
                            `rows.${rowOrder - 1}.isFirstVisit`,
                            tag.name === VISIT_DISPLAY.FIRST_VISIT,
                            { shouldDirty: true },
                          );
                        }}
                        handleClickOutside={resetFocusedCell}
                        className={viewerModeClassName}
                      />
                    </TableCell>
                  );

                // case 3:
                //   return (
                //     <TableCell
                //       key={`countryCode-${rowOrder - 1}`}
                //       handleCellClick={() => handleCellClick(rowOrder - 1, index)}
                //       rowIndex={rowOrder - 1}
                //       colIndex={index}
                //       selectedCell={selectedCell}
                //       className='w-[113px]'>
                //       <SingleTagSelectBoxTagDropdownForTable
                //         value={
                //           countryCode
                //             ? {
                //                 name: `${countryCode.koreanCountryName}(+${countryCode.internationalDialingCodes})`,
                //                 id: countryCode.id,
                //               }
                //             : undefined
                //         }
                //         width={166}
                //         ref={(el) => setInputRef(rowOrder - 1, index, el)}
                //         placeholder='선택'
                //         isChangeOnly
                //         isFocused={renderIsFocused(rowOrder - 1, index, focusedCell)}
                //         isSelected={renderIsSelected(rowOrder - 1, index, selectedCell)}
                //         maxTextLength='full'
                //         defaultSelectedTag={
                //           countryCode
                //             ? {
                //                 name: `${countryCode.koreanCountryName}(+${countryCode.internationalDialingCodes})`,
                //                 id: countryCode.id,
                //               }
                //             : {
                //                 name: '한국(+82)',
                //                 id: KOREA_COUNTRY_CODE_ID,
                //               }
                //         }
                //         tagOptions={countriesListWithDialingCodes}
                //         onSelectedTagChange={(tag) => {
                //           if (!tag) {
                //             setValue(`rows.${rowOrder - 1}.countryCode`, undefined, {
                //               shouldDirty: true,
                //             });
                //             return;
                //           }
                //           setValue(
                //             `rows.${rowOrder - 1}.countryCode`,
                //             {
                //               koreanCountryName: tag.name,
                //               internationalDialingCodes: tag.id ?? '',
                //               id: tag.id ?? '',
                //             },
                //             { shouldDirty: true },
                //           );
                //         }}
                //         handleClickOutside={resetFocusedCell}
                //         className={viewerModeClassName}
                //       />
                //     </TableCell>
                //   );

                case 3:
                  return (
                    <TableCell
                      key={`phoneNumber-${rowOrder - 1}`}
                      handleCellClick={() => handleCellClick(rowOrder - 1, index)}
                      rowIndex={rowOrder - 1}
                      selectedCell={selectedCell}
                      colIndex={index}
                      className='w-[130px]'>
                      <TableInput
                        value={row.phoneNumber ?? ''}
                        onChange={(e) => {
                          setValue(`rows.${rowOrder - 1}.phoneNumber`, e.target.value, {
                            shouldDirty: true,
                          });
                        }}
                        onDelete={() => {
                          setValue(`rows.${rowOrder - 1}.phoneNumber`, '', { shouldDirty: true });
                        }}
                        isViewerMode={isViewerMode}
                        ref={(el) => setInputRef(rowOrder - 1, index, el)}
                        isFocused={renderIsFocused(rowOrder - 1, index, focusedCell)}
                        isSelected={renderIsSelected(rowOrder - 1, index, selectedCell)}
                        type='number'
                        placeholder='예) 01012345678'
                        className={viewerModeClassName}
                      />
                    </TableCell>
                  );

                case 4:
                  return (
                    <TableCell
                      key={`birthdate-${rowOrder - 1}`}
                      handleCellClick={() => handleCellClick(rowOrder - 1, index)}
                      rowIndex={rowOrder - 1}
                      selectedCell={selectedCell}
                      colIndex={index}
                      className='w-[130px]'>
                      <TableInput
                        value={row.birthdate ?? ''}
                        onChange={(e) => {
                          let inputValue = e.target.value.replace(/[^0-9]/g, '');
                          if (inputValue.length >= 4) {
                            inputValue = `${inputValue.slice(0, 4)}-${inputValue.slice(4)}`;
                          }
                          if (inputValue.length >= 7) {
                            inputValue = `${inputValue.slice(0, 7)}-${inputValue.slice(7, 10)}`;
                          }
                          setValue(`rows.${rowOrder - 1}.birthdate`, inputValue, {
                            shouldDirty: true,
                          });
                        }}
                        onKeyDown={(e) => {
                          const value = e.currentTarget.value;
                          if (e.key === 'Delete') {
                            setValue(`rows.${rowOrder - 1}.birthdate`, '', { shouldDirty: true });
                          }
                          if (e.key === 'Backspace' && value.endsWith('-')) {
                            e.preventDefault();
                            setValue(`rows.${rowOrder - 1}.birthdate`, value.slice(0, -1), {
                              shouldDirty: true,
                            });
                          }
                        }}
                        isViewerMode={isViewerMode}
                        ref={(el) => setInputRef(rowOrder - 1, index, el)}
                        maxLength={10}
                        isFocused={renderIsFocused(rowOrder - 1, index, focusedCell)}
                        isSelected={renderIsSelected(rowOrder - 1, index, selectedCell)}
                        type='text'
                        placeholder='예) YYYY-MM-DD'
                        className={viewerModeClassName}
                      />
                    </TableCell>
                  );

                case 5:
                  return (
                    <TableCell
                      key={`gender-${rowOrder - 1}`}
                      handleCellClick={() => handleCellClick(rowOrder - 1, index)}
                      rowIndex={rowOrder - 1}
                      selectedCell={selectedCell}
                      colIndex={index}
                      className='w-[90px]'>
                      <SingleTagSelectBoxTagDropdownForTable
                        key={`gender-${rowOrder - 1}`}
                        value={gender ? { name: GENDER_DISPLAY[gender], id: gender } : undefined}
                        width={120}
                        onRemoveTag={() => {
                          handleChangeFocusedCell(rowOrder - 1, index);
                          handleChangeSelectedCell(rowOrder - 1, index);
                        }}
                        ref={(el) => setInputRef(rowOrder - 1, index, el)}
                        placeholder='선택'
                        isFocused={renderIsFocused(rowOrder - 1, index, focusedCell)}
                        isSelected={renderIsSelected(rowOrder - 1, index, selectedCell)}
                        defaultSelectedTag={
                          gender !== undefined && gender !== null
                            ? {
                                name: GENDER_DISPLAY[gender],
                                id: gender,
                              }
                            : undefined
                        }
                        tagOptions={GENDER_OPTIONS.map((option) => ({
                          name: GENDER_DISPLAY[option],
                          id: option,
                        }))}
                        onSelectedTagChange={(tag) => {
                          if (!tag) {
                            setValue(`rows.${rowOrder - 1}.gender`, null, {
                              shouldDirty: true,
                            });
                            return;
                          }
                          setValue(
                            `rows.${rowOrder - 1}.gender`,
                            tag.name === GENDER_DISPLAY.MALE
                              ? GENDER_OPTIONS[0]
                              : GENDER_OPTIONS[1],
                            { shouldDirty: true },
                          );
                        }}
                        handleClickOutside={resetFocusedCell}
                        className={viewerModeClassName}
                      />
                    </TableCell>
                  );

                case 6:
                  return (
                    <TableCell
                      key={`treatmentTagIds-${rowOrder - 1}`}
                      handleCellClick={() => handleCellClick(rowOrder - 1, index)}
                      rowIndex={rowOrder - 1}
                      selectedCell={selectedCell}
                      colIndex={index}
                      className='min-w-[150px]'>
                      <MultipleTagsSelectBoxTagDropdownForTable
                        key={`treatmentTagIds-${rowOrder - 1}`}
                        ref={(el) => setInputRef(rowOrder - 1, index, el)}
                        isFocused={renderIsFocused(rowOrder - 1, index, focusedCell)}
                        isSelected={renderIsSelected(rowOrder - 1, index, selectedCell)}
                        placeholder={'예) 도수치료, 리프팅'}
                        onRemoveTag={() => {
                          handleChangeFocusedCell(rowOrder - 1, index);
                          handleChangeSelectedCell(rowOrder - 1, index);
                        }}
                        tagOptions={
                          treatmentTagsList?.map((tag) => ({
                            name: tag.name,
                            id: tag.tagId,
                            color: tag.color,
                          })) ?? []
                        }
                        defaultSelectedTags={
                          defaultTreatmentTags
                            ? defaultTreatmentTags.map((tag) => ({
                                name: tag.name,
                                id: tag.tagId,
                                color: tag.color,
                              }))
                            : []
                        }
                        onSelectedTagsChange={(tags) => {
                          setValue(
                            `rows.${rowOrder - 1}.treatmentTagIds`,
                            tags.map((tag) => tag.id),
                            { shouldDirty: true },
                          );
                        }}
                        handleClickOutside={resetFocusedCell}
                        className={viewerModeClassName}
                      />
                    </TableCell>
                  );

                case 7:
                  return (
                    <TableCell
                      key={`nationalityId-${rowOrder - 1}`}
                      handleCellClick={() => handleCellClick(rowOrder - 1, index)}
                      rowIndex={rowOrder - 1}
                      selectedCell={selectedCell}
                      colIndex={index}
                      className='w-[160px]'>
                      <SingleTagSelectBoxTagDropdownForTable
                        key={`nationalityId-${rowOrder - 1}`}
                        width={140}
                        onRemoveTag={() => {
                          handleChangeFocusedCell(rowOrder - 1, index);
                          handleChangeSelectedCell(rowOrder - 1, index);
                        }}
                        ref={(el) => setInputRef(rowOrder - 1, index, el)}
                        placeholder='선택'
                        isFocused={renderIsFocused(rowOrder - 1, index, focusedCell)}
                        isSelected={renderIsSelected(rowOrder - 1, index, selectedCell)}
                        defaultSelectedTag={
                          defaultNationality
                            ? {
                                name: defaultNationality.koreanCountryName,
                                id: defaultNationality.id,
                              }
                            : undefined
                        }
                        tagOptions={countriesList.map((item) => ({
                          name: item.koreanCountryName ?? '',
                          id: item.countryId,
                        }))}
                        onSelectedTagChange={(tag) => {
                          if (tag?.id) {
                            setValue(`rows.${rowOrder - 1}.nationalityId`, tag.id, {
                              shouldDirty: true,
                            });
                          } else {
                            setValue(`rows.${rowOrder - 1}.nationalityId`, null, {
                              shouldDirty: true,
                            });
                          }
                        }}
                        handleClickOutside={resetFocusedCell}
                        className={viewerModeClassName}
                      />
                    </TableCell>
                  );

                default:
                  return null;
              }
            })}
          </tr>
        );
      })}
    </tbody>
  );
}

interface TableCellProps {
  rowIndex: number;
  colIndex: number;
  selectedCell: SelectedCell;
  handleCellClick: (rowIndex: number, colIndex: number) => void;
  children: ReactNode;
  className?: string;
}

const TableCell = ({
  rowIndex,
  colIndex,
  selectedCell,
  handleCellClick,
  children,
  className,
}: TableCellProps) => {
  const customerTableDisplayMode = useAtomValue(customerTableDisplayModeState);
  const isViewerMode = customerTableDisplayMode === 'VIEWER';

  return (
    <td
      {...getTableCellProps(
        rowIndex,
        colIndex,
        selectedCell,
        () => handleCellClick(rowIndex, colIndex),
        isViewerMode,
        className,
      )}>
      {children}
    </td>
  );
};
