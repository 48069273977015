import { popupCreateOrUpdateParamState } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/state/popup-create-or-update-param-state';
import { selectedPopupEditTargetErrorAtom } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/state/popup-edit-error-state';
import { popupTargetTypeState } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/state/popup-target-type-state';
import type { CreateUpdatePopupParamType } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/types/create-update-popup-param-type';
import type { PopupTargetType } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/types/popup-target-type';
import PopupNoticeTargetDropdown from '@templates/Content/containers/ContentManager/containers/NoticePopup/shared/components/PopupNoticeTargetDropdown';
import LabelText from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import Radio from 'afterdoc-design-system/components/Atoms/Radio/Radio';
import {
  RadioGroup,
  type ValueType,
} from 'afterdoc-design-system/components/Atoms/Radio/RadioGroup';
import { useSetAtom } from 'jotai';
import { useAtom } from 'jotai/index';
import { memo, useCallback, useEffect, useState } from 'react';

type FilterType = CreateUpdatePopupParamType['filter'];

interface PopupTargetSelectorProps {
  filter: FilterType;
  isDisabled: boolean;
}

function PopupTargetSelector({ filter, isDisabled }: PopupTargetSelectorProps) {
  const [popupTargetType, setPopupTargetType] = useAtom(popupTargetTypeState);

  const setPopupCreateOrUpdateParam = useSetAtom(popupCreateOrUpdateParamState);

  const [popupTargetError, setPopupTargetError] = useAtom(selectedPopupEditTargetErrorAtom);

  const [isInitialRender, setIsInitialRender] = useState(true);

  const clearError = useCallback(() => setPopupTargetError(null), [setPopupTargetError]);

  const updateFilter = useCallback(
    (newFilter: Partial<FilterType>) => {
      setPopupCreateOrUpdateParam((prev) => ({
        ...prev,
        filter: {
          ...prev.filter,
          ...newFilter,
        },
      }));
    },
    [setPopupCreateOrUpdateParam],
  );

  const handleRadioChange = useCallback((value: ValueType) => {
    setPopupTargetType(value as PopupTargetType);
  }, []);

  const handleSelectType = useCallback(() => {
    clearError();
    updateFilter({
      treatmentCategoryID_in: [],
      isFirstVisit_in: [],
    });
  }, []);

  const handleSelectTags = useCallback((tags: string[]) => {
    clearError();
    updateFilter({ treatmentCategoryID_in: tags });
  }, []);

  const handleSelectVisitType = useCallback((visitType: boolean[] | undefined) => {
    clearError();
    updateFilter({ isFirstVisit_in: visitType ?? [] });
  }, []);

  useEffect(() => {
    clearError();
    if (filter.isFirstVisit_in.length === 0 && filter.treatmentCategoryID_in.length === 0) {
      setPopupTargetType('all');
    } else {
      setPopupTargetType('specific');
    }
  }, []);

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      return;
    }

    if (popupTargetType === 'all') {
      updateFilter({
        treatmentCategoryID_in: [],
        isFirstVisit_in: [],
      });
    }
  }, [popupTargetType, isInitialRender]);

  return (
    <div className='my-10 flex w-full flex-col'>
      <div className='flex min-h-[78px] gap-28 py-4'>
        <LabelText width={72} className='flex h-32 items-center'>
          푸시알림
        </LabelText>
        <div className='flex flex-grow flex-col gap-4'>
          <RadioGroup name='popupTargetType' value={popupTargetType} onChange={handleRadioChange}>
            <Radio id='all' label='모든 고객에게 노출' value={'all'} disabled={isDisabled} />
            <Radio
              id='specific'
              label='특정 고객에게 노출'
              value={'specific'}
              disabled={isDisabled}
            />
            {popupTargetType === 'specific' && (
              <PopupNoticeTargetDropdown
                errorMessage={popupTargetError}
                isDisabled={isDisabled}
                defaultSelectedTagsIDs={filter.treatmentCategoryID_in}
                defaultSelectedVisitTypes={filter.isFirstVisit_in}
                onHandleSelectType={handleSelectType}
                onHandleSelectTags={handleSelectTags}
                onHandleSelectVisitType={handleSelectVisitType}
              />
            )}
          </RadioGroup>
        </div>
      </div>
    </div>
  );
}

export default memo(PopupTargetSelector);
