import * as React from "react";
import type { SVGProps } from "react";
const SvgArrowLine = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 41 40"
    {...props}
  >
    <path
      fill="#888"
      d="M40.707 20.707a1 1 0 0 0 0-1.414l-6.364-6.364a1 1 0 0 0-1.414 1.414L38.586 20l-5.657 5.657a1 1 0 0 0 1.414 1.414zM0 21h40v-2H0z"
    />
  </svg>
);
export default SvgArrowLine;
