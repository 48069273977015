import DayPicker from '@afterdoc-design-system/components/Atoms/Calendar/DayPicker';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { useRef } from 'react';
import { useRenderingPosition } from 'web/templates/CustomerChat/components/ParentDropDown/components/ChildDropdown/hooks/use-rendering-position';

type DayPickerWrapperProps = {
  handleDayPickerSelect: (date?: Date) => void;
  handleIsDatePickerOpen: () => void;
};

const DAYPICKER_HEIGHT = 288;
const POSITION_OFFSET = 400;

export default function DayPickerWrapper({
  handleDayPickerSelect,
  handleIsDatePickerOpen,
}: DayPickerWrapperProps) {
  const dayPickerRef = useRef<HTMLDivElement>(null);

  const { position } = useRenderingPosition({
    renderingRef: dayPickerRef,
    renderingHeight: DAYPICKER_HEIGHT,
    offSet: POSITION_OFFSET,
  });

  return (
    <div
      ref={dayPickerRef}
      className={customTwMerge(
        '-translate-x-1/2 absolute left-1/2 transform',
        position === 'top' ? 'bottom-15 mb-2' : 'top-15 mt-2',
      )}>
      <DayPicker
        onChangeDate={handleDayPickerSelect}
        handleToggle={handleIsDatePickerOpen}
        disableAfterDate={new Date()}
      />
    </div>
  );
}
