import type { NonNullableCareSchedule } from '@templates/HospitalSetting/containers/HospitalProfile/components/OperatingHours/OperatingHours';
import OperatingTimePicker from '@templates/HospitalSetting/containers/HospitalProfile/components/OperatingHours/components/OperatingTimePicker';
import { DAYS_OF_WEEK } from '@templates/HospitalSetting/containers/HospitalProfile/components/OperatingHours/constans/days-of-week';
import { hospitalInfoState } from '@templates/HospitalSetting/containers/HospitalProfile/states/hospital-info-state';
import Checkbox from 'afterdoc-design-system/components/Atoms/Checkbox/Checkbox';
import { useSetAtom } from 'jotai/index';
import { memo } from 'react';

export interface WeeklyOperatingHourProps {
  data: NonNullableCareSchedule;
}

function WeeklyOperatingHour({
  data: { isActive = false, dayIndex, startedAt, endedAt },
}: WeeklyOperatingHourProps) {
  const setHospitalInfo = useSetAtom(hospitalInfoState);

  const updateSchedule = (field: 'isActive' | 'startedAt' | 'endedAt', value: boolean | string) => {
    setHospitalInfo((prev) => ({
      ...prev,
      careSchedule: prev.careSchedule?.map((schedule) =>
        schedule.dayIndex === dayIndex
          ? {
              ...schedule,
              [field]: value,
            }
          : schedule,
      ),
    }));
  };

  const renderTimePicker = (date: string, field: 'startedAt' | 'endedAt') => (
    <OperatingTimePicker
      isActive={isActive}
      date={date}
      onSelect={(time) => updateSchedule(field, time)}
    />
  );

  return (
    <>
      <div className='mt-10 flex h-32 items-center gap-10'>
        <Checkbox
          checked={isActive ?? false}
          label={DAYS_OF_WEEK[dayIndex]}
          labelProps={{ className: 'text-Header12 text-black500' }}
          onChange={() => updateSchedule('isActive', !isActive)}
        />
        {renderTimePicker(startedAt, 'startedAt')}
        <span className='text-Body12 text-black500'>~</span>
        {renderTimePicker(endedAt, 'endedAt')}
      </div>
    </>
  );
}

export default memo(WeeklyOperatingHour);
