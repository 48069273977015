import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBookmarkempty = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    width={24}
    height={24}
    {...props}>
    <path fill='#888' d='m17 18-5-2.18L7 18V5h10m0-2H7a2 2 0 0 0-2 2v16l7-3 7 3V5a2 2 0 0 0-2-2' />
  </svg>
);
export default SvgBookmarkempty;
