import BaseLoading from '@afterdoc-design-system/components/Atoms/Loading/BaseLoading';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { useQuery } from '@tanstack/react-query';
import { useImageCompression } from 'web/shared/hooks/files/images/use-image-compression';
import type { ImageType } from 'web/shared/hooks/files/images/use-upload-multiple-images';
import { isLocalImage } from 'web/shared/hooks/files/images/use-upload-multiple-images';

interface OptimizedImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  image: ImageType;
  onLoad?: () => void;
  loadingComponent?: React.ReactNode;
}

export default function OptimizedImage({
  image,
  className,
  onLoad,
  loadingComponent = <BaseLoading />,
  ...props
}: OptimizedImageProps) {
  const { compressImage } = useImageCompression();

  const { data: optimizedUrl, isLoading } = useQuery({
    queryKey: ['optimized-image', isLocalImage(image) ? image.blobUrl : image.url],
    queryFn: async () => {
      try {
        const sourceUrl = isLocalImage(image) ? image.blobUrl : image.url;
        const response = await fetch(sourceUrl);
        const blob = await response.blob();
        const { compressedDataUrl } = await compressImage(
          blob,
          isLocalImage(image) ? image.name : `image_${image.id}`,
        );
        return compressedDataUrl;
      } catch (error) {
        console.error('이미지 최적화 실패:', error);
        return isLocalImage(image) ? image.blobUrl : image.url;
      }
    },
    staleTime: Number.POSITIVE_INFINITY, // 캐시를 영구적으로 유지
    gcTime: 5 * 60 * 1000, // 5분 동안 사용하지 않으면 가비지 컬렉션
    retry: false, // 실패시 재시도 하지 않음
  });

  if (isLoading) {
    return <div className={customTwMerge('flex-center', className)}>{loadingComponent}</div>;
  }

  return (
    <img
      {...props}
      src={optimizedUrl}
      className={className}
      onLoad={() => onLoad?.()}
      alt={props.alt || `optimized-image-${image.id}`}
    />
  );
}
