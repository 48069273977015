import mitt from 'mitt';

export type DataNotificationPayload = {
  data: { code: string; [key: string]: unknown };
  notification: { title: string; body: string };
};

type GlobalEvents = {
  dataPushNotification: DataNotificationPayload;
};

const eventBus = mitt<GlobalEvents>();
export default eventBus;
