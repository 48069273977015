import { useUpdateUserInfo } from '@templates/UserInfoSetting/containers/AccountSetting/hooks/use-update-user-info';
import type { StatusMessage } from '@templates/UserInfoSetting/containers/AccountSetting/types/status-message';
import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';
import TextInput from 'afterdoc-design-system/components/Atoms/Input/TextInput';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { dialogService } from 'afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import { useMemo, useState } from 'react';

export default function UserInfoUpdatePassword() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const { updateUserInfo } = useUpdateUserInfo();

  const [passwordStatus, setPasswordStatus] = useState<StatusMessage>({
    status: 'none',
  });

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordStatus({ status: 'none' });
    setPassword(e.target.value.replace(/[^a-zA-Z0-9]/g, ''));
  };

  const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordStatus({ status: 'none' });
    setConfirmPassword(e.target.value.replace(/[^a-zA-Z0-9]/g, ''));
  };

  const handleConfirmButtonClick = () => {
    if (password !== confirmPassword) {
      setPasswordStatus({
        status: 'failure',
        message: '비밀번호가 일치하지 않습니다. 다시 입력해 주세요.',
      });
      return;
    }
    updateUserInfo({
      param: { password: password },
      onSuccessCallback: () => {
        closeCurrentDialog();
        toastService.successMsg({ text: '비밀번호가 변경되었습니다.' });
      },
      onFailureCallback: () => {
        toastService.errorMsg({ text: '비밀번호 변경에 실패했습니다. 다시 시도해 주세요.' });
      },
    });
  };

  const closeCurrentDialog = () => {
    dialogService.popById('user-info-update-dialog');
  };

  const isConfirmButtonDisabled = useMemo(() => {
    const isValidLength = (value: string) => value.length >= 3 && value.length <= 25;
    const isPasswordValid = isValidLength(password) && isValidLength(confirmPassword);

    return !isPasswordValid;
  }, [password, confirmPassword]);

  return (
    <>
      <div className='flex h-full flex-grow flex-col'>
        <div className='flex flex-col gap-16'>
          <TextInput
            label={{ width: 80, children: '새 비밀번호', position: 'horizontal' }}
            className='flex-grow'
            maxLength={25}
            value={password}
            type={'password'}
            onChange={handlePasswordChange}
            placeholder={'영문, 숫자 3~25자'}
          />
          <div className='flex flex-col gap-4'>
            <TextInput
              label={{ width: 80, children: '재입력', position: 'horizontal' }}
              className='flex-grow'
              maxLength={25}
              value={confirmPassword}
              type={'password'}
              onChange={handleConfirmPasswordChange}
              placeholder={'비밀번호 재입력'}
            />
            {passwordStatus.status !== 'none' && (
              <span
                className={`ml-90 text-Body10 ${passwordStatus.status === 'success' ? 'text-blue500' : 'text-red500'}`}>
                {passwordStatus.message}
              </span>
            )}
          </div>
        </div>
        <div className='mx-auto mt-auto flex gap-10'>
          <ContainedButton btnColor={'secondary'} onClick={closeCurrentDialog}>
            취소
          </ContainedButton>
          <ContainedButton disabled={isConfirmButtonDisabled} onClick={handleConfirmButtonClick}>
            확인
          </ContainedButton>
        </div>
      </div>
    </>
  );
}
