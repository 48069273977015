import { eventPushSendToHospitalAtom } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/components/EventList/components/EventListBody/components/EventPushAlarmDialog/EventPushSelector/states/event-push-request-create-param-state';
import { isUseDateRangeFilterState } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/components/EventList/components/EventListBody/components/EventPushAlarmDialog/EventPushSelector/states/is-use-date-range-filter-state';
import EventTargetDropdown from '@templates/Content/containers/ContentManager/containers/EventHomeCare/shared/components/Manage/PushTargetSelector/components/EventTargetDropdown';
import SignupRangeFilter from '@templates/Content/containers/ContentManager/containers/EventHomeCare/shared/components/Manage/PushTargetSelector/components/SignupRangeFilter/SignupRangeFilter';
import { useAtomValue } from 'jotai/index';
import { useResetAtom } from 'jotai/utils';
import { useEffect } from 'react';

export default function EventTargetDetailFilter() {
  const isSendToHospital = useAtomValue(eventPushSendToHospitalAtom);
  const resetIsUseDateRangeFilterState = useResetAtom(isUseDateRangeFilterState);

  useEffect(() => {
    resetIsUseDateRangeFilterState();
  }, [isSendToHospital]);

  return (
    <>
      <div className='flex h-full w-full flex-grow flex-col'>
        {!isSendToHospital && (
          <>
            <SignupRangeFilter />
            <EventTargetDropdown />
          </>
        )}
      </div>
    </>
  );
}
