export const VISIT_OPTIONS = ['초진', '재진'];
export const VISIT_DISPLAY = {
  FIRST_VISIT: '초진',
  REVISIT: '재진',
};

export const PATIENT_OPTIONS = ['전체', '미가입자', '가입자'];

export const GENDER_OPTIONS = ['MALE', 'FEMALE'] as const;
export const GENDER_DISPLAY = {
  MALE: '남성',
  FEMALE: '여성',
};
