import type { EventListQueryKeyAtomType } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/states/event-list-query-key-state';
import type { HomeCareListQueryKeyAtomType } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/HomeCare/states/home-care-list-query-key-state';
import type { EventHomeCareColumnType } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/constants/event-home-care-list-columns';
import BaseLoading from 'afterdoc-design-system/components/Atoms/Loading/BaseLoading';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import Pagination from 'afterdoc-design-system/components/Molecules/Pagination/Pagination';
import { useAtom, useAtomValue } from 'jotai/index';
import type { PrimitiveAtom } from 'jotai/vanilla/atom';
import { Suspense, useEffect, useRef } from 'react';

interface EventHomeCareListSection {
  children: React.ReactNode;
  columns: EventHomeCareColumnType[];
  queryKeyState: HomeCareListQueryKeyAtomType | EventListQueryKeyAtomType;
  totalCountState: PrimitiveAtom<number>;
}

const DEFAULT_LIMIT = 50;

export default function EventHomeCareListSection({
  columns,
  children,
  queryKeyState,
  totalCountState,
}: EventHomeCareListSection) {
  const [queryKey, setQueryKey] = useAtom(queryKeyState);

  const totalCount = useAtomValue(totalCountState);

  const totalPages = Math.max(1, Math.ceil(totalCount / DEFAULT_LIMIT));
  const currentPage = queryKey.skip === 0 ? 1 : Math.floor(queryKey.skip / DEFAULT_LIMIT) + 1;

  const handlePageChange = (page: number) => {
    const newSkip = (page - 1) * DEFAULT_LIMIT;
    setQueryKey((prev) => ({
      ...prev,
      skip: newSkip,
    }));
  };

  const scrollbarRef = useRef<{
    scrollTo: (index: number, behavior?: ScrollBehavior) => void;
  }>(null);

  useEffect(() => {
    if (scrollbarRef.current) {
      scrollbarRef.current.scrollTo(0, 'auto');
    }
  }, [queryKey]);

  return (
    <>
      <div className='flex h-42 items-center border-white600 border-y bg-white100 p-10 px-24'>
        {columns.map((column) => (
          <div
            key={column.key}
            className={`text-Header14 text-black700 ${column.className}`}
            style={{ width: `${column.width}px` }}>
            {column.title}
          </div>
        ))}
      </div>
      <div className='flex h-full flex-grow flex-col'>
        <Suspense
          fallback={
            <div className='h-full w-full flex-center'>
              <BaseLoading />
            </div>
          }>
          <Scrollbar ref={scrollbarRef} className='h-full flex-grow'>
            {children}
          </Scrollbar>
        </Suspense>
      </div>
      <Pagination
        className='h-auto'
        totalPages={totalPages}
        selectedPage={currentPage}
        onPageChange={handlePageChange}
      />
    </>
  );
}
