import { useSelectedHospitalInfo } from '@shared/hooks/use-selected-hospital-info';
import { useUserInfo } from '@shared/hooks/use-user-info';
import AccountFormActionButtons from '@templates/HospitalSetting/containers/AllAccountSetting/containers/AddAccount/components/AddAccountRequestDialog/components/AccountFormActionButtons';
import AccountRequestFormField, {
  type AccountRequestFormFieldProps,
} from '@templates/HospitalSetting/containers/AllAccountSetting/containers/AddAccount/components/AddAccountRequestDialog/components/AccountRequestFormField';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

const formFields: AccountRequestFormFieldProps[] = [
  {
    fieldName: 'requesterName',
    label: '신청자명',
    placeholder: '신청자명 입력',
    errorMessage: '신청자명을 입력해 주세요.',
  },
  {
    fieldName: 'requesterContact',
    label: '연락처',
    placeholder: '연락받으실 휴대폰번호',
    errorMessage: '연락처를 입력해 주세요.',
  },
  {
    fieldName: 'requestedAccountCount',
    label: '추가 계정 수',
    placeholder: '추가하실 계정 수',
    errorMessage: '추가하실 계정 수는 1 이상 입력해 주세요.',
  },
];

export default function AddAccountRequestDialog() {
  const { hospitalID } = useSelectedHospitalInfo();
  const { realName } = useUserInfo();

  const methods = useForm({
    defaultValues: {
      hospitalID,
      requesterName: realName,
      requestedAccountCount: 1,
    },
  });

  return (
    <FormProvider {...methods}>
      <form autoComplete='off'>
        <div className='flex h-full w-[458px] flex-col justify-between'>
          <div className='flex w-full flex-1 flex-col gap-10 px-20 py-5'>
            {formFields.map((field) => (
              <AccountRequestFormField key={field.fieldName} {...field} />
            ))}
          </div>
          <AccountFormActionButtons />
        </div>
      </form>
    </FormProvider>
  );
}
