import type { SmartdoctorCustomer } from '@shared/components/ALIntegrationEdit/hooks/type';
import SmartDoctorTableItem from '@templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/SmartDoctor/components/SmartDoctorReservationHistory/components/SmartDoctorReservationHistoryTable/components/SmartDoctorTableItem';
import { useSmartDoctorReservationByDate } from '@templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/SmartDoctor/components/SmartDoctorReservationHistory/components/SmartDoctorReservationHistoryTable/hooks/use-smart-doctor-reservation-by-date';
import { TABLE_HEADERS } from '@templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/SmartDoctor/components/SmartDoctorReservationHistory/constants/headers';
import NoData from 'afterdoc-design-system/components/Molecules/NoData/NoData';

interface SmartDoctorTableBodyProps {
  customer: SmartdoctorCustomer | undefined;
}

export default function SmartDoctorTableBody({ customer }: SmartDoctorTableBodyProps) {
  const { reservations, isError } = useSmartDoctorReservationByDate(customer);

  return (
    <tbody className='truncate'>
      {reservations.length > 0 ? (
        reservations.map((reservation, index) => (
          <SmartDoctorTableItem key={index} reservation={reservation} />
        ))
      ) : (
        <TableNoData isError={isError} />
      )}
    </tbody>
  );
}

const TableNoData = ({ isError }: { isError: boolean }) => {
  const title = isError ? '일시적인 오류로 예약을 불러올 수 없습니다.' : '표시할 내용이 없습니다.';
  return (
    <>
      <tr>
        <td colSpan={TABLE_HEADERS.length}>
          <div className='flex h-[300px] w-full items-center justify-center'>
            <NoData
              iconProps={{
                name: 'calendar-blank-outline',
                size: 48,
                color: 'white400',
              }}
              title={title}
            />
          </div>
        </td>
      </tr>
    </>
  );
};
