import TagDropdown from '@afterdoc-design-system/components/Molecules/TextSelect/TagDropdown';
import type { TagProperty } from '@afterdoc-design-system/components/Molecules/TextSelect/types/tag-dropdown';
import { SHARED_UTILS } from '@shared-utils/utils';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import FilledTag from 'afterdoc-design-system/components/Atoms/Tag/FilledTag';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import './MultipleTagsSelectBoxTagDropdownForTable.scss';

export interface MultipleTagsSelectBoxTagDropdownForTableProps {
  id?: string;
  width?: number | string;
  placeholder?: string;
  tagOptions: TagProperty[];
  defaultSelectedTags?: TagProperty[];
  onSelect?: (index: number) => void;
  onSelectedTagsChange?: (tags: TagProperty[]) => void;
  onRemoveTag?: (tagIndex: number) => void;
  isFocused?: boolean;
  isSelected?: boolean;
  disabled?: boolean;
  customFocusScrollHandler?: (focusedIndex: number) => number;
  customSelectedScrollHandler?: (selectedIndex: number) => number;
  handleClickOutside?: () => void;
  className?: string;
}

const MultipleTagsSelectBoxTagDropdownForTable = forwardRef<
  HTMLDivElement,
  MultipleTagsSelectBoxTagDropdownForTableProps
>(
  (
    {
      id,
      width,
      onSelect,
      tagOptions,
      defaultSelectedTags = [],
      onSelectedTagsChange,
      onRemoveTag,
      disabled,
      placeholder,
      isFocused,
      isSelected,
      className,
    },
    ref,
  ) => {
    const inputRef = useRef<HTMLDivElement>(null);
    const ignoreRef = useRef(null);

    const [isToggle, setIsToggle] = useState(false);
    const [selectedTags, setSelectedTags] = useState<TagProperty[]>(defaultSelectedTags);
    const [inputValue, setInputValue] = useState('');
    const [immediateInputValue, setImmediateInputValue] = useState('');
    const [filteredTags, setFilteredTags] = useState(tagOptions);
    const [isComposing, setIsComposing] = useState(false);

    useImperativeHandle(ref, () => inputRef.current as HTMLDivElement);

    const handleSelect = (index: number) => {
      if (disabled) return;

      const selectedTag = filteredTags[index];
      if (!selectedTags.some((tag) => tag.name === selectedTag.name)) {
        if (inputRef.current) {
          inputRef.current.textContent = '';
          setInputValue('');
          setImmediateInputValue('');
          const range = document.createRange();
          const sel = window.getSelection();
          range.selectNodeContents(inputRef.current);
          range.collapse(false);
          sel?.removeAllRanges();
          sel?.addRange(range);
          inputRef.current.focus();
        }

        setTimeout(() => {
          const newSelectedTags = [...selectedTags, selectedTag];
          setSelectedTags(newSelectedTags);
          onSelectedTagsChange?.(newSelectedTags);
          onSelect?.(index);
        }, 0);
      }
    };

    const handleToggle = (isToggle?: boolean) => {
      if (isToggle !== undefined) {
        setIsToggle(isToggle);
      } else {
        setIsToggle((prev) => !prev);
      }
    };

    const clearInput = () => {
      if (inputRef.current) {
        inputRef.current.textContent = '';
        setImmediateInputValue('');
      }
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
      if (disabled) return;

      if (e.key === 'Enter') {
        e.preventDefault();
        if (!isToggle || !filteredTags.length) {
          setIsToggle(true);
        }
      } else if (e.key === 'Tab') {
        e.preventDefault();
        const nextFocusable = inputRef.current?.nextElementSibling as HTMLElement;
        if (nextFocusable) {
          nextFocusable.focus();
        }
      } else if (e.key === 'Backspace') {
        if (window.getSelection()?.toString() || e.ctrlKey || e.metaKey) {
          e.preventDefault();
          setSelectedTags([]);
          onSelectedTagsChange?.([]);
          setInputValue('');
          setImmediateInputValue('');
          if (inputRef.current) {
            inputRef.current.textContent = '';
          }
        } else if (inputValue === '' && selectedTags.length > 0) {
          e.preventDefault();
          const newSelectedTags = selectedTags.slice(0, -1);
          setSelectedTags(newSelectedTags);
          onSelectedTagsChange?.(newSelectedTags);
          setIsToggle(true);
          inputRef.current?.focus();
        }
      } else if (e.key === 'Escape') {
        e.preventDefault();
        setIsToggle(false);
      } else if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
        setTimeout(() => {
          inputRef.current?.focus();
        }, 0);
      }
    };

    const handleRemoveTag = (tagIndex: number) => {
      if (disabled) return;

      const newSelectedTags = selectedTags.filter((_, index) => index !== tagIndex);
      setSelectedTags(newSelectedTags);
      onSelectedTagsChange?.(newSelectedTags);
      onRemoveTag?.(tagIndex);
      setIsToggle(true);
      inputRef.current?.focus();
    };

    const handleInput = (e: React.FormEvent<HTMLDivElement>) => {
      if (disabled) return;

      if (!isComposing) {
        const text = e.currentTarget?.textContent || '';
        setInputValue(text);
        setImmediateInputValue(text);
      }
    };

    const handleCompositionStart = () => {
      setIsComposing(true);
    };

    const handleCompositionEnd = (e: React.CompositionEvent<HTMLDivElement>) => {
      setIsComposing(false);
      const text = e.currentTarget?.textContent || '';
      setInputValue(text);
      setImmediateInputValue(text);
    };

    useEffect(() => {
      const observer = new MutationObserver(() => {
        const text = inputRef.current?.textContent || '';
        setImmediateInputValue(text);
      });

      if (inputRef.current) {
        observer.observe(inputRef.current, {
          childList: true,
          subtree: true,
          characterData: true,
        });
      }

      return () => {
        observer.disconnect();
      };
    }, [inputRef]);

    useEffect(() => {
      if (inputRef.current) {
        const range = document.createRange();
        const sel = window.getSelection();
        range.selectNodeContents(inputRef.current);
        range.collapse(false);
        sel?.removeAllRanges();
        sel?.addRange(range);
      }
    }, [inputValue]);

    useEffect(() => {
      setFilteredTags(
        tagOptions.filter(
          (tag) =>
            tag.name.toLowerCase().includes(immediateInputValue.toLowerCase()) &&
            !selectedTags.some((selectedTag) => selectedTag.name === tag.name),
        ),
      );
    }, [immediateInputValue, tagOptions, selectedTags]);

    useEffect(() => {
      if (inputRef.current) {
        if (isFocused && !disabled) {
          setIsToggle(true);
          inputRef.current.focus();
          // placeholder 제거
          inputRef.current.setAttribute('data-placeholder', '');
          inputRef.current.classList.remove('hidden-caret');

          // 빈 텍스트 노드를 추가하여 Caret이 첫 번째 줄에 위치하도록 설정
          if (inputRef.current.innerText.trim() === '') {
            inputRef.current.innerHTML = ''; // 기존의 줄바꿈을 제거
            const textNode = document.createTextNode('');
            inputRef.current.appendChild(textNode);

            // Caret을 첫 번째 줄에 위치시키기
            const range = document.createRange();
            const selection = window.getSelection();
            range.setStart(textNode, 0);
            range.collapse(true);

            if (selection) {
              selection.removeAllRanges();
              selection.addRange(range);
            }
          }
        } else {
          setIsToggle(false);
          inputRef.current.blur();
          inputRef.current.classList.add('hidden-caret');
        }
      }
    }, [isFocused, disabled]);

    useEffect(() => {
      if (inputRef.current) {
        if (!selectedTags.length && !isFocused) {
          inputRef.current.setAttribute('data-placeholder', placeholder ?? '');
        } else {
          inputRef.current.removeAttribute('data-placeholder');
        }
      }
    }, [selectedTags, placeholder, isFocused]);

    useEffect(() => {
      if (!isFocused && inputRef.current) {
        if (inputRef.current.textContent && inputRef.current.textContent.trim() !== '') {
          clearInput();
        }
      }
    }, [isFocused]);

    // useEffect(() => {
    //   if (defaultSelectedTags.length === 0 && selectedTags.length > 0) {
    //     setSelectedTags([]);
    //     if (inputRef.current) {
    //       inputRef.current.textContent = '';
    //       setInputValue('');
    //       setImmediateInputValue('');
    //     }
    //   }
    // }, [defaultSelectedTags]);

    return (
      <>
        <div
          ref={ignoreRef}
          className={customTwMerge(
            'mt-1 flex flex-wrap items-center overflow-auto px-12 outline-none hover:bg-blueLight',
            selectedTags.length > 0 ? 'py-[9.5px]' : 'py-11',
            disabled ? 'cursor-not-allowed border-white600 bg-white200' : 'border-white600',
            (isFocused || isSelected) && 'bg-blue50',
            className,
          )}
          style={{
            width: SHARED_UTILS.css.getCssSizeValue(width),
          }}>
          {selectedTags.map((tag, tagIndex) => (
            <FilledTag
              key={uuidv4()}
              tagSize='small'
              className={customTwMerge('m-1', disabled ? 'cursor-not-allowed' : 'cursor-pointer')}
              iconProps={{
                name: 'close',
                size: 12,
              }}
              bgColor={tag.color}
              onClick={(e) => {
                if (disabled) return;
                e?.stopPropagation();
                handleRemoveTag(tagIndex);
              }}>
              {tag.name}
            </FilledTag>
          ))}
          <div
            ref={inputRef}
            className='flex-1 overflow-hidden bg-transparent text-Body12 text-black700 outline-none'
            contentEditable={!disabled}
            onInput={handleInput}
            onKeyDown={handleKeyDown}
            onCompositionStart={handleCompositionStart}
            onCompositionEnd={handleCompositionEnd}
            suppressContentEditableWarning={true}
            onClick={(e) => {
              if (disabled) {
                e.preventDefault();
                inputRef.current?.blur();
              }
            }}
          />
        </div>
        {!disabled && isToggle && (
          <TagDropdown
            id={id}
            width={width}
            tags={filteredTags}
            ignoreRefs={[ignoreRef]}
            isToggle={isToggle}
            handleToggle={handleToggle}
            isDefaultFocus={!!immediateInputValue}
            onSelect={handleSelect}
          />
        )}
      </>
    );
  },
);

MultipleTagsSelectBoxTagDropdownForTable.displayName = 'MultipleTagsSelectBoxTagDropdownForTable';

export default MultipleTagsSelectBoxTagDropdownForTable;
