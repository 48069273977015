import Divider from '@afterdoc-design-system/components/Atoms/Divider/Divider';
import { SHARED_UTILS } from '@shared-utils/utils';
import dayjs from 'dayjs';
import { useAtomValue, useSetAtom } from 'jotai';
import { useState } from 'react';
import { apiClient } from 'web/apis/instances/api-client';
import type { ApiChatElFindDateParams } from 'web/apis/swaggers/swagger-docs';
import DayPickerWrapper from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/components/DateDivider/DayPickerWrapper/DayPickerWrapper';
import { useESCEvent } from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/components/DateDivider/hooks/use-ESC-event';
import { selectedMessageIDState } from 'web/templates/CustomerChat/components/SupportBot/states/selected-message-id';
import { chatRoomIDSelector } from 'web/templates/CustomerChat/states/selected-chat-room';

type DateDividerProps = {
  dividerLineDate: string;
};

const getMessageID = async (query: ApiChatElFindDateParams) => {
  const { data } = await apiClient.v3.apiChatElFindDate(query);
  return SHARED_UTILS.api.checkApiResponse(data);
};

export default function DateDivider({ dividerLineDate }: DateDividerProps) {
  const chatRoomID = useAtomValue(chatRoomIDSelector);
  const setSelectedMessageId = useSetAtom(selectedMessageIDState);

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const handleIsDatePickerOpen = () => {
    setIsDatePickerOpen((prev) => !prev);
  };

  const handleDayPickerSelect = async (date?: Date) => {
    if (!chatRoomID || !date) return;

    const query = {
      chatRoomID,
      date: dayjs(date).format('YYYY-MM-DD'),
    };

    const { _id: messageID } = await getMessageID(query);

    setSelectedMessageId(messageID);
    setIsDatePickerOpen(false);
  };

  useESCEvent({ setIsDatePickerOpen });

  return (
    <div className='relative mx-20 flex items-center justify-center'>
      <Divider direction='horizontal' type='line' />
      <div
        className='mx-10 cursor-pointer whitespace-nowrap text-Body11 text-black200'
        onClick={handleIsDatePickerOpen}>
        {dividerLineDate}
      </div>
      <Divider direction='horizontal' type='line' />
      {isDatePickerOpen && (
        <DayPickerWrapper
          handleDayPickerSelect={handleDayPickerSelect}
          handleIsDatePickerOpen={handleIsDatePickerOpen}
        />
      )}
    </div>
  );
}
