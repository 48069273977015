import { apiClient } from '@apis/instances/api-client';
import type { ServiceNotice } from '@apis/swaggers/swagger-docs';
import { useMutation } from '@tanstack/react-query';
import { formatCardCreatedDate } from '@templates/ServiceNoticeDialog/functions/format-card-created-date';
import { useServiceListReadStatus } from '@templates/ServiceNoticeDialog/hooks/use-service-list-read-status';
import { isUpdatingState } from '@templates/ServiceNoticeDialog/states/is-updating-state';
import Badge from 'afterdoc-design-system/components/Atoms/Badge/Badge';
import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { useSetAtom } from 'jotai';
import { SHARED_UTILS } from 'utils/utils';

interface ChatRoomJoinRequestCardProps {
  data: ServiceNotice;
}

type ApiApprovalChatroomElUpdateParam = Parameters<
  typeof apiClient.v3.apiApprovalChatroomElUpdate
>[0];

export const updateApprovalChatRoom = async (param: ApiApprovalChatroomElUpdateParam) => {
  const { data } = await apiClient.v3.apiApprovalChatroomElUpdate(param);
  return SHARED_UTILS.api.checkApiResponse(data);
};

export default function ChatRoomJoinRequestCard({ data }: ChatRoomJoinRequestCardProps) {
  const { updateMutationAction } = useServiceListReadStatus();

  const setIsUpdating = useSetAtom(isUpdatingState);

  const updateMutation = useMutation({
    mutationFn: updateApprovalChatRoom,
    onMutate: () => setIsUpdating(true),
    onSettled: () => setIsUpdating(false),
    onSuccess: () => {
      data.isResponded = true;
    },
    onError: (error) => handleFailureListener(error),
  });

  const markAsRead = async (): Promise<void> => {
    await new Promise<void>((resolve) => {
      updateMutationAction(data._id, {
        onSettledCallback: (isSuccess) => {
          if (isSuccess) data.isRead = true;
          resolve();
        },
      });
    });
  };

  const approveRequest = async () => {
    await markAsRead();
    const approvalChatRoomID = data.data?.approvalChatRoomID;
    if (approvalChatRoomID) {
      updateMutation.mutate({ approvalChatRoomID, isApproved: true });
    }
  };

  const handleFailureListener = (error: Error) => {
    console.error(`Failed to update approval chatroom API: ${error.message}`);
    const isAlreadyProcessed = SHARED_UTILS.api.isApiErrorWithCode(error, 9999);

    if (isAlreadyProcessed) {
      data.isResponded = true;
    }
    toastService.errorMsg({
      text: isAlreadyProcessed
        ? '이미 처리가 완료된 요청 건입니다.'
        : '일시적인 오류가 발생했습니다. 다시 시도해 주세요.',
    });
  };

  return (
    <>
      <div className='flex cursor-pointer flex-col gap-10 p-20'>
        <Badge isBadge={!data.isRead} wrapperClassName={'flex-grow'}>
          <span className='flex min-h-[24px] w-full items-center text-Header14 text-black700'>
            {data.title}
          </span>
        </Badge>
        <span className={'break-all text-Body12 text-black500'}>{data.body}</span>
        <div className='ml-auto'>
          <ContainedButton
            buttonSize={'small'}
            disabled={data.isResponded}
            onClick={(e) => {
              e.stopPropagation();
              approveRequest();
            }}>
            승인
          </ContainedButton>
        </div>
        <span className='flex justify-end text-Body10 text-black200'>
          {formatCardCreatedDate(data.createdAt)}
        </span>
      </div>
    </>
  );
}
