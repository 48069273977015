import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/react-editor';
import { debounce } from 'lodash-es';
import { useCallback, useEffect, useRef } from 'react';
import CustomToolbar from 'web/shared/components/TextEditor/CustomToolbar';
import { useImageCompression } from 'web/shared/hooks/files/images/use-image-compression';
import './TextEditor.scss';

interface TextEditorProps {
  value?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
  height?: number;
  className?: string;
}

export default function TextEditor({
  value = '',
  disabled,
  onChange,
  height = 316,
  className,
}: TextEditorProps) {
  const { compressImage } = useImageCompression();

  const editorRef = useRef<Editor>(null);
  const historyRef = useRef<string[]>([]);
  const currentIndexRef = useRef<number>(-1);
  const isUndoRedoRef = useRef<boolean>(false);

  const updateHistory = useCallback(
    debounce((newContent: string) => {
      if (!isUndoRedoRef.current && newContent !== historyRef.current[currentIndexRef.current]) {
        historyRef.current = historyRef.current.slice(0, currentIndexRef.current + 1);
        historyRef.current.push(newContent);
        currentIndexRef.current++;
      }
    }, 150),
    [],
  );

  const handleChange = () => {
    const instance = editorRef.current?.getInstance();
    if (!instance) return;

    const currentContent = instance.getHTML();
    const isDefaultTag = currentContent === '<p><br></p>';
    const newContent = isDefaultTag ? '' : currentContent;

    // 일반적인 onChange 호출
    onChange?.(newContent);

    // debounced history 업데이트
    if (!isUndoRedoRef.current) {
      updateHistory(newContent);
    }

    isUndoRedoRef.current = false;
  };

  const handleUndo = useCallback(() => {
    if (currentIndexRef.current > 0) {
      isUndoRedoRef.current = true;
      currentIndexRef.current--;
      const previousContent = historyRef.current[currentIndexRef.current];
      editorRef.current?.getInstance().setHTML(previousContent);
    }
  }, []);

  const handleRedo = useCallback(() => {
    if (currentIndexRef.current < historyRef.current.length - 1) {
      isUndoRedoRef.current = true;
      currentIndexRef.current++;
      const nextContent = historyRef.current[currentIndexRef.current];
      editorRef.current?.getInstance().setHTML(nextContent);
    }
  }, []);

  useEffect(() => {
    if (value && historyRef.current.length === 0) {
      historyRef.current = [value];
      currentIndexRef.current = 0;
    }
  }, [value]);

  useEffect(() => {
    const instance = editorRef.current?.getInstance();
    if (!instance) return;

    // 이미지 붙여넣기 핸들러
    const handlePaste = async (e: ClipboardEvent) => {
      if (disabled) return;

      const items = e.clipboardData?.items;
      if (!items) return;

      for (const item of items) {
        if (item.type.indexOf('image') === 0) {
          e.preventDefault(); // 기본 붙여넣기 동작 방지
          const file = item.getAsFile();
          if (!file) continue;

          try {
            const { compressedDataUrl } = await compressImage(file);
            // 압축된 이미지만 삽입
            instance.exec('addImage', {
              imageUrl: compressedDataUrl,
              altText: 'compressed-image',
            });
          } catch (error) {
            console.error('이미지 처리 실패:', error);
          }
          break; // 첫 번째 이미지만 처리
        }
      }
    };

    // 붙여넣기 이벤트를 캡처 단계에서 처리
    const { mdEditor, wwEditor } = instance.getEditorElements();
    const editorEl = wwEditor || mdEditor;

    if (editorEl) {
      editorEl.addEventListener('paste', handlePaste, { capture: true });

      return () => {
        editorEl.removeEventListener('paste', handlePaste, { capture: true });
      };
    }
  }, [disabled, compressImage]);

  useEffect(() => {
    const instance = editorRef.current?.getInstance();
    if (!instance) return;

    const currentContent = instance.getHTML();
    if (currentContent !== value) {
      instance.setHTML(value);
    }
  }, [value]);

  useEffect(() => {
    const instance = editorRef.current?.getInstance();
    if (!instance) return;

    const disableSpellcheck = () => {
      const editableElements = document.querySelectorAll('[contenteditable="true"]');
      for (const el of editableElements) {
        el.setAttribute('spellcheck', 'false');
        el.setAttribute('data-gramm', 'false');
        el.setAttribute('data-gramm_editor', 'false');
        el.setAttribute('data-enable-grammarly', 'false');
      }
    };

    disableSpellcheck();

    const observerTarget = document.querySelector('.toast-editor');
    if (!observerTarget) return;

    const observer = new MutationObserver(disableSpellcheck);

    observer.observe(observerTarget, {
      childList: true,
      subtree: true,
    });

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const instance = editorRef.current?.getInstance();
    if (!instance) return;

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'h' && e.ctrlKey) {
        e.preventDefault();
      }
    };

    const { mdEditor, wwEditor } = instance.getEditorElements();
    const editorEl = wwEditor || mdEditor;

    if (editorEl) {
      editorEl.addEventListener('keydown', handleKeyDown);

      return () => {
        editorEl.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, []);

  // cleanup
  useEffect(() => {
    return () => {
      updateHistory.cancel();
    };
  }, [updateHistory]);

  return (
    <div className={customTwMerge('toast-editor', className)}>
      <CustomToolbar
        disabled={disabled}
        editorRef={editorRef}
        onUndo={handleUndo}
        onRedo={handleRedo}
      />
      <Editor
        ref={editorRef}
        initialValue={value}
        height={`${height}px`}
        initialEditType='wysiwyg'
        hideModeSwitch
        toolbarItems={[]}
        useCommandShortcut={true}
        onChange={handleChange}
        readOnly={disabled}
        placeholder='내용을 입력해 주세요.'
        // options={{
        //   hooks: {
        //     addImageBlobHook: async (blob: Blob, callback: (url: string) => void) => {
        //       console.info('addImageBlobHook', blob);
        //       const optimizedBase64 = await compressImage(blob);
        //       console.info('optimizedBase64', optimizedBase64);
        //       callback(optimizedBase64.compressedDataUrl);
        //     },
        //   },
        // }}
      />
    </div>
  );
}
