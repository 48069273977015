import type { SmartdoctorCustomer } from '@shared/components/ALIntegrationEdit/hooks/type';
import SmartDoctorReservationHistoryTable from './components/SmartDoctorReservationHistoryTable/SmartDoctorReservationHistoryTable';
import SmartDoctorReservationHistoryTitleAndSubText from './components/SmartDoctorReservationHistoryTitleAndSubText';

interface SmartDoctorReservationHistoryContainerProps {
  customer: SmartdoctorCustomer | undefined;
}
export default function SmartDoctorReservationHistoryContainer({
  customer,
}: SmartDoctorReservationHistoryContainerProps) {
  return (
    <>
      <SmartDoctorReservationHistoryTitleAndSubText />
      <SmartDoctorReservationHistoryTable customer={customer} />
    </>
  );
}
