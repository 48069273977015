import { useCallback } from 'react';
import {
  COUNSEL_REPLACEABLE_TEXTS,
  MAX_LENGTH,
} from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/constants/replaceable-texts';
import {
  calculateReplacedLength,
  removeSpecificReplaceableText,
  truncateTextBeforeMaxLength,
} from 'web/templates/Automation/containers/shared/functions/calculate-text';

interface UseTextAreaWithMaxLengthProps {
  setActualTextLength: (value: number) => void;
}

export function useTextAreaWithMaxLength({ setActualTextLength }: UseTextAreaWithMaxLengthProps) {
  const calculateActualLength = useCallback((text: string) => {
    return (
      removeSpecificReplaceableText(text, COUNSEL_REPLACEABLE_TEXTS).length +
      calculateReplacedLength(text, COUNSEL_REPLACEABLE_TEXTS)
    );
  }, []);

  const handleTextAreaChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const newValue = e.target.value;
      const newActualLength = calculateActualLength(newValue);

      if (newActualLength <= MAX_LENGTH) {
        setActualTextLength(newActualLength);
      } else {
        const truncatedValue = truncateTextBeforeMaxLength(
          newValue,
          COUNSEL_REPLACEABLE_TEXTS,
          MAX_LENGTH,
        );
        const truncatedActualLength = calculateActualLength(truncatedValue);

        setActualTextLength(truncatedActualLength);
      }
    },

    [calculateActualLength, setActualTextLength],
  );

  return {
    handleTextAreaChange,
    calculateActualLength,
  };
}
