import TextFieldSelectBoxDropdown from 'afterdoc-design-system/components/Molecules/TextSelect/TextFieldSelectBoxDropdown';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

interface OperatingTimePickerProps {
  isActive: boolean;
  date: string | undefined;
  onSelect: (time: string) => void;
}

const hourOptions = Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, '0'));
const minuteOptions = Array.from({ length: 12 }, (_, i) => (i * 5).toString().padStart(2, '0'));

export default function ReceiveTimePicker({ isActive, date, onSelect }: OperatingTimePickerProps) {
  const [selectedHourIndex, setSelectedHourIndex] = useState<number | undefined>();
  const [selectedMinuteIndex, setSelectedMinuteIndex] = useState<number | undefined>();

  const handleSelect = (index: number, type: 'hour' | 'minute') => {
    const updatedHour = type === 'hour' ? hourOptions[index] : hourOptions[selectedHourIndex ?? 0];
    const updatedMinute =
      type === 'minute' ? minuteOptions[index] : minuteOptions[selectedMinuteIndex ?? 0];

    if (type === 'hour') {
      setSelectedHourIndex(index);
    } else {
      setSelectedMinuteIndex(index);
    }

    const now = dayjs().format('YYYY-MM-DD');
    const formattedTime = `${now}T${updatedHour}:${updatedMinute}:00.000+0900`;

    onSelect(formattedTime);
  };

  useEffect(() => {
    const hourIndex = hourOptions.findIndex((hour) => hour === dayjs(date).format('HH'));
    const minuteIndex = minuteOptions.findIndex((minute) => minute === dayjs(date).format('mm'));

    setSelectedHourIndex(hourIndex !== -1 ? hourIndex : 0);
    setSelectedMinuteIndex(minuteIndex !== -1 ? minuteIndex : 0);
  }, [date]);

  return (
    <div className='h-full flex-center gap-4'>
      <TextFieldSelectBoxDropdown
        options={hourOptions}
        onSelect={(index) => handleSelect(index, 'hour')}
        width={62}
        disabled={!isActive}
        selectedIndex={selectedHourIndex}
      />

      <span className='text-Body12 text-black500'>:</span>

      <TextFieldSelectBoxDropdown
        options={minuteOptions}
        onSelect={(index) => handleSelect(index, 'minute')}
        width={62}
        disabled={!isActive}
        selectedIndex={selectedMinuteIndex}
      />
    </div>
  );
}
