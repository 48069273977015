import { atom } from 'jotai/index';
import { atomWithReset } from 'jotai/utils';

type NoticeListQueryType = {
  keyword?: string;
  skip: number;
  limit: number;
  isRemoved: boolean;
  key: string;
  way: number;
};

export const noticeListQueryKeyState = atomWithReset<NoticeListQueryType>({
  keyword: undefined,
  skip: 0,
  limit: 50,
  isRemoved: false,
  key: 'index',
  way: -1,
});

export const noticeListKeywordAtom = atom(
  (get) => get(noticeListQueryKeyState).keyword,
  (get, set, newKeyword?: string) => {
    const currentState = get(noticeListQueryKeyState);
    set(noticeListQueryKeyState, {
      ...currentState,
      keyword: newKeyword || undefined,
      skip: 0,
    });
  },
);

export type NoticeListQueryKeyAtomType = typeof noticeListQueryKeyState;
