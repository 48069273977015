import TextInput from 'afterdoc-design-system/components/Atoms/Input/TextInput';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

export default function AccountId() {
  const {
    register,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const inputValue = watch('id') ?? '';

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearErrors('id');

    let { value } = e.target;
    value = value.replace(/[^a-zA-Z0-9]/g, '');
    setValue('id', value);
  };

  const errorText = useMemo(() => {
    return typeof errors.id?.message === 'string'
      ? errors.id.message
      : '영문, 숫자 3~25자로 설정해 주세요.';
  }, [errors.id]);

  return (
    <div className='w-[164px]'>
      <TextInput
        id='id'
        placeholder={'영문, 숫자 포함 3자-25자'}
        maxLength={25}
        label={{
          children: '아이디',
          isRequired: true,
          width: '100%',
          position: 'vertical',
        }}
        hasError={!!errors.id}
        value={inputValue}
        errorText={errorText}
        {...register('id', { onChange: handleChange })}
      />
    </div>
  );
}
