import type { TagProperty } from '@afterdoc-design-system/components/Molecules/TextSelect/types/tag-dropdown';
import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import type { ApiTreatmentTagsElParams } from '@apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from '@shared/hooks/use-selected-hospital-info';
import { useQuery } from '@tanstack/react-query';
import {
  eventPushTreatmentCategoriesAtom,
  eventPushVisitTypeAtom,
} from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/components/EventList/components/EventListBody/components/EventPushAlarmDialog/EventPushSelector/states/event-push-request-create-param-state';
import { isTargetDropdownErrorState } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/components/EventList/components/EventListBody/components/EventPushAlarmDialog/EventPushSelector/states/is-target-dropdown-error-state';
import { categoryTypeState } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/states/category-type-state';
import TextFieldSelectBoxDropdown from 'afterdoc-design-system/components/Molecules/TextSelect/TextFieldSelectBoxDropdown';
import MultipleTextFieldSelectBoxTagDropdown from 'afterdoc-design-system/components/Molecules/TextSelect/TextFieldSelectBoxTagDropdown/MultipleTextFieldSelectBoxTagDropdown';
import { useAtom, useSetAtom } from 'jotai';
import { memo, useEffect, useMemo, useRef } from 'react';
import { SHARED_UTILS } from 'utils/utils';

const fetchTreatmentTags = async (params: ApiTreatmentTagsElParams) => {
  const { data } = await apiClient.v3.apiTreatmentTagsEl(params);
  return SHARED_UTILS.api.checkApiResponse(data);
};

const visitTypeTags: TagProperty[] = [
  { name: '초진', id: 'firstVisit', color: '#EBEBEB' },
  { name: '재진', id: 'followUpVisit', color: '#EBEBEB' },
];

function EventTargetDropdown() {
  const { hospitalID } = useSelectedHospitalInfo();
  const [categoryType, setCategoryType] = useAtom(categoryTypeState);
  const [targetDropdownError, setTargetDropdownError] = useAtom(isTargetDropdownErrorState);

  const containerRef = useRef<HTMLDivElement>(null);

  const setEventPushTreatmentCategories = useSetAtom(eventPushTreatmentCategoriesAtom);
  const setEventPushVisitType = useSetAtom(eventPushVisitTypeAtom);

  const { data } = useQuery({
    queryKey: [QUERY_KEY.apiTreatmentTagsEl, { hospitalID }] as const,
    queryFn: ({ queryKey }) => fetchTreatmentTags(queryKey[1]),
  });

  const treatmentTags = useMemo(() => {
    if (!data) return [];

    return (
      data.treatmentTags?.map((tag) => ({
        name: tag.name,
        id: tag.tagId,
        color: tag.color,
      })) ?? []
    );
  }, [data]);

  const handleSelect = (index: number) => {
    const type = index === 0 ? 'treatmentCategories' : 'isFirstVisit';
    setCategoryType(type);
  };

  const handleSelectedTreatmentTag = (tags: TagProperty[]) => {
    setTargetDropdownError(false);
    setEventPushTreatmentCategories(tags.map((tag) => tag.id));
  };

  const handleSelectedVisitType = (tags: TagProperty[]) => {
    setTargetDropdownError(false);

    const visitStatus = tags.reduce(
      (acc, tag) => {
        if (tag.id === 'firstVisit') acc[0] = true;
        if (tag.id === 'followUpVisit') acc[1] = true;
        return acc;
      },
      [false, false],
    );

    setEventPushVisitType(visitStatus.some(Boolean) ? visitStatus : undefined);
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.focus();
    }
  }, []);

  useEffect(() => {
    setTargetDropdownError(false);
    if (categoryType === 'treatmentCategories') {
      setEventPushVisitType(undefined);
    }
    if (categoryType === 'isFirstVisit') {
      setEventPushTreatmentCategories(undefined);
    }
  }, [categoryType]);

  return (
    <div className='mt-10 flex gap-10' ref={containerRef}>
      <TextFieldSelectBoxDropdown
        selectedIndex={0}
        options={['치료태그', '초/재진']}
        onSelect={handleSelect}
        wrapperClassName={'ml-35 w-95'}
      />

      <div>
        {categoryType === 'treatmentCategories' && (
          <MultipleTextFieldSelectBoxTagDropdown
            placeholder={'예) 도수치료, 리프팅'}
            tagOptions={treatmentTags}
            onSelectedTagsChange={handleSelectedTreatmentTag}
            defaultSelectedTags={[]}
            className='w-[285px]'
            fieldWrapperClassName='max-h-[180px]'
          />
        )}
        {categoryType === 'isFirstVisit' && (
          <MultipleTextFieldSelectBoxTagDropdown
            placeholder={'선택'}
            tagOptions={visitTypeTags}
            onSelectedTagsChange={handleSelectedVisitType}
            defaultSelectedTags={[]}
            className='w-[285px]'
            fieldWrapperClassName='max-h-[180px]'
          />
        )}
        {targetDropdownError && (
          <p className='mt-4 text-Body10 text-red500'>상세조건을 선택해주세요.</p>
        )}
      </div>
    </div>
  );
}
export default memo(EventTargetDropdown);
