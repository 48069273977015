import { eventPushSendToHospitalAtom } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/components/EventList/components/EventListBody/components/EventPushAlarmDialog/EventPushSelector/states/event-push-request-create-param-state';
import EventTargetDropdown from '@templates/Content/containers/ContentManager/containers/EventHomeCare/shared/components/Manage/PushTargetSelector/components/EventTargetDropdown';
import SignupRangeFilter from '@templates/Content/containers/ContentManager/containers/EventHomeCare/shared/components/Manage/PushTargetSelector/components/SignupRangeFilter/SignupRangeFilter';
import { pushNotificationOptionState } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/states/push-notification-option-state';
import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import LabelText from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import Radio from 'afterdoc-design-system/components/Atoms/Radio/Radio';
import {
  RadioGroup,
  type ValueType,
} from 'afterdoc-design-system/components/Atoms/Radio/RadioGroup';
import { useAtom, useSetAtom } from 'jotai';
import { useEffect } from 'react';

type PushNotificationOption = 'none' | 'allCustomers' | 'specificCustomers';

export default function PushTargetSelector() {
  const [pushNotificationOption, setPushNotificationOption] = useAtom(pushNotificationOptionState);
  const setIsSendToHospital = useSetAtom(eventPushSendToHospitalAtom);

  const handleChange = (value: ValueType) => {
    setPushNotificationOption(value as PushNotificationOption);
    setIsSendToHospital(value === 'allCustomers');
  };

  useEffect(() => {
    return () => {
      setPushNotificationOption('none');
    };
  }, []);

  return (
    <div className='mt-10 flex w-full flex-col gap-10'>
      <div className='flex min-h-100 gap-28 py-4'>
        <LabelText width={72} className='flex h-32 items-center'>
          푸시알림
        </LabelText>
        <div className='flex flex-col gap-4'>
          <RadioGroup name='pushTarget' value={pushNotificationOption} onChange={handleChange}>
            <Radio id='noPushNotification' label='푸시알림 보내지 않기' value='none' />
            <Radio id='all' label='모든 고객에게 푸시알림 보내기' value='allCustomers' />
            <Radio id='specific' label='특정 고객에게 푸시알림 보내기' value='specificCustomers' />
          </RadioGroup>
          {pushNotificationOption === 'specificCustomers' && (
            <div>
              <SignupRangeFilter />
              <EventTargetDropdown />
            </div>
          )}
        </div>
      </div>

      <Divider type='line' />
    </div>
  );
}
