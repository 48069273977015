import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';
import NoData from '@afterdoc-design-system/components/Molecules/NoData/NoData';
import { useAtomValue } from 'jotai';
import { useMemo, useState } from 'react';
import type { AlgorithmSummary } from 'web/apis/swaggers/swagger-docs';
import { automationSearchTextState } from 'web/templates/Automation/containers/Main/AutomationSearchFilter/states/automation-search';
import MarketingAutomationTitle from 'web/templates/Automation/containers/Main/MarketingAutomationContent/components/MarketingAutomationTitle';
import MarketingCardItem from 'web/templates/Automation/containers/Main/MarketingAutomationContent/components/MarketingCardItem';

interface MarketingAutomationContentProps {
  marketingAlgorithms?: AlgorithmSummary[];
}

export default function MarketingAutomationContent({
  marketingAlgorithms,
}: MarketingAutomationContentProps) {
  const automationSearchText = useAtomValue(automationSearchTextState);
  const [viewAll, setViewAll] = useState(false);

  const onClickViewAllCards = () => {
    setViewAll(true);
  };

  const onClickViewUnder9Cards = () => {
    setViewAll(false);
  };

  const slicedMarketingAlgorithms = useMemo(() => {
    if (!marketingAlgorithms) return [];
    return marketingAlgorithms.slice(0, 9);
  }, [marketingAlgorithms]);

  const restMarketingAlgorithms = useMemo(() => {
    if (!marketingAlgorithms) {
      return [];
    }
    return marketingAlgorithms.length > 9 ? marketingAlgorithms.slice(9) : [];
  }, [marketingAlgorithms]);

  if (!marketingAlgorithms?.length && automationSearchText?.length) {
    return (
      <>
        <MarketingAutomationTitle />
        <div className='col-span-3 h-[200px] flex-w-full-center rounded-r16 bg-[#E5EEF8]'>
          <NoData
            iconProps={{
              name: 'warning',
              size: 48,
              color: 'white600',
            }}
            title='검색 결과가 없습니다.'
            subTitle='단어의 철자가 정확한지 확인해 주세요.'
          />
        </div>
      </>
    );
  }

  if (!marketingAlgorithms || !marketingAlgorithms.length) {
    return (
      <>
        <MarketingAutomationTitle />
        <div className='col-span-3 h-[200px] flex-w-full-center rounded-r16 bg-[#E5EEF8]'>
          <NoData
            iconProps={{
              name: 'warning',
              size: 48,
              color: 'white600',
            }}
            title='표시할 내용이 없습니다.'
            subTitle='등록된 자동화가 여기에 표시됩니다.'
          />
        </div>
      </>
    );
  }

  return (
    <>
      <MarketingAutomationTitle />
      <div>
        <div className='grid w-full grid-cols-1 gap-x-20 gap-y-16 rounded-r16 bg-[#E5EEF8] p-10 sm:grid-cols-2 lg:grid-cols-3'>
          {slicedMarketingAlgorithms.map((marketingAlgorithm) => (
            <MarketingCardItem
              key={marketingAlgorithm._id}
              marketingAlgorithm={marketingAlgorithm}
            />
          ))}
          {viewAll &&
            restMarketingAlgorithms.length > 0 &&
            restMarketingAlgorithms.map((marketingAlgorithm) => (
              <MarketingCardItem
                key={marketingAlgorithm._id}
                marketingAlgorithm={marketingAlgorithm}
              />
            ))}
        </div>
        {marketingAlgorithms.length > 9 && (
          <button
            type='button'
            className='mx-auto flex w-fit items-center gap-2 rounded-b-r16 border-white600 border-x border-b bg-white50 pt-2 pr-8 pb-6 pl-20'
            onClick={viewAll ? onClickViewUnder9Cards : onClickViewAllCards}>
            <div className='text-Header14'>{viewAll ? '숨기기' : '더보기'}</div>
            <Icon name={viewAll ? 'chevron-up' : 'chevron-down'} size={28} color='black500' />
          </button>
        )}
      </div>
    </>
  );
}
