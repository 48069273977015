import { toastService } from '@afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { useAtom } from 'jotai';
import { useEffect, useRef } from 'react';
import { useChatRoomInfoOne } from '../../../hooks/use-chatroom-info-one';
import { isActivateChatTranslateAtom } from '../../../states/isChatTranslate';

export function useChatTranslationToggle({ chatRoomID }: { chatRoomID: string }) {
  // const previousChatRoomID = useRef<string | null>(null);
  const { isUsingTranslation } = useChatRoomInfoOne({ chatRoomID });
  const [isActivateChatTranslate, setIsActivateChatTranslate] = useAtom(
    isActivateChatTranslateAtom,
  );

  useEffect(() => {
    if (isUsingTranslation !== undefined) {
      setIsActivateChatTranslate(isUsingTranslation);
    }
  }, [isUsingTranslation, setIsActivateChatTranslate]);

  //chatting room id 가 변경되지 않고 isUsingTranslation 값이 변경될 때만 toast 를 띄우는 로직
  const previousIsUsingTranslation = useRef<boolean | undefined>(undefined);
  const previousChatRoomID = useRef<string | null>(null);

  useEffect(() => {
    // Check if chatRoomID has changed
    const hasChatRoomChanged = previousChatRoomID.current !== chatRoomID;

    if (hasChatRoomChanged) {
      previousChatRoomID.current = chatRoomID;
      // Reset the previous value when chatRoomID changes
      previousIsUsingTranslation.current = undefined;
    } else {
      // Check if isUsingTranslation has changed
      if (
        previousIsUsingTranslation.current !== undefined &&
        previousIsUsingTranslation.current !== isUsingTranslation
      ) {
        toastService.successMsg({
          text: `번역을 ${isUsingTranslation ? '시작' : '종료'}합니다`,
        });
      }
    }

    // Update the current value
    previousIsUsingTranslation.current = isUsingTranslation;
  }, [isUsingTranslation, chatRoomID]);

  // useEffect(() => {
  //   if (previousChatRoomID.current !== chatRoomID) {
  //     previousChatRoomID.current = chatRoomID;
  //   } else {
  //     toastService.successMsg({ text: `번역을 ${isUsingTranslation ? '시작' : '종료'}합니다` });
  //   }

  //   return () => {
  //     previousChatRoomID.current = chatRoomID
  //   }
  // }, [isUsingTranslation]);
  return { isActivateChatTranslate, setIsActivateChatTranslate };
}
