import * as React from "react";
import type { SVGProps } from "react";
const SvgImagePlus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#7F7F7F"
      d="M4.167 2.5A1.667 1.667 0 0 0 2.5 4.167v11.666A1.666 1.666 0 0 0 4.167 17.5h7.575a4.6 4.6 0 0 1-.075-.833c0-.567.1-1.134.291-1.667H4.167l2.916-3.75 2.084 2.5L12.083 10l1.859 2.475a5 5 0 0 1 2.725-.808c.283 0 .558.025.833.075V4.167A1.667 1.667 0 0 0 15.833 2.5zm11.666 10.833v2.5h-2.5V17.5h2.5V20H17.5v-2.5H20v-1.667h-2.5v-2.5z"
    />
  </svg>
);
export default SvgImagePlus;
