import { toastService } from '@afterdoc-design-system/components/Atoms/Toast/Toast.service';
import Toggle from '@afterdoc-design-system/components/Atoms/Toggle/Toggle';
import HoverTooltip from '@afterdoc-design-system/components/Atoms/Tooltip/HoverTooltip';
import { SHARED_UTILS } from '@shared-utils/utils';
import { useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { useEffect, useMemo, useRef } from 'react';
import { apiClient } from 'web/apis/instances/api-client';
import { useDataEventBusHandler } from 'web/apis/push/data-push/hooks/use-data-event-bus-handler';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { DataNotificationPayload } from 'web/shared/utils/event-bus';
import { useChatTopPatient } from 'web/templates/CustomerChat/hooks/use-chat-top-patient';
import {
  type ChatRoomID,
  chatRoomIDSelector,
} from 'web/templates/CustomerChat/states/selected-chat-room';
import { MIN_LAYOUT_WIDTH } from '../../../../constants/layout';
import { isSendingTranslateState } from '../ChattingContainer/components/ChattingContent/states/is-sending-translate';
import { chattingRequestState } from '../ChattingContainer/states/chatting-request';
import PatientDetail from './PatientDetail';
import PatientTitle from './PatientTitle/PatientTitle';
import TreatmentTags from './TreatmentTags';
import { useChatTranslationToggle } from './hooks/use-chat-translation-toggle';

type TranslateParams = Parameters<typeof apiClient.v3.apiChatroomElUpdateTranslateToggle>[0];

const postTranstranslate = async ({
  chatRoomID: chatRoomId,
  usingTranslation,
}: TranslateParams) => {
  const response = await apiClient.v3.apiChatroomElUpdateTranslateToggle({
    chatRoomID: chatRoomId,
    usingTranslation,
  });
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export default function CustomerInfoTop() {
  const queryClient = useQueryClient();
  const chatRoomID = useAtomValue(chatRoomIDSelector);
  const handleNotificationRef = useRef<((payload: DataNotificationPayload) => void) | null>(null);

  const { isKakaoIcon, isColorAfterDocIcon, isGrayAfterDocIcon } = useChatTopPatient();

  if (!chatRoomID) return null;
  const resetIsSendingTranslate = useResetAtom(isSendingTranslateState);
  const resetReq = useResetAtom(chattingRequestState);
  const { isActivateChatTranslate, setIsActivateChatTranslate } = useChatTranslationToggle({
    chatRoomID,
  });

  const notificationConfigs = useMemo(
    () => [
      {
        codes: ['d_HServiceUpdateLink_el'],
        handler: (payload: DataNotificationPayload) => handleNotificationRef.current?.(payload),
      },
    ],
    [],
  );

  useDataEventBusHandler(notificationConfigs);

  useEffect(() => {
    handleNotificationRef.current = (payload: DataNotificationPayload) => {
      const { chatRoomID: updateLinkChatRoomID } = payload.data;
      if (updateLinkChatRoomID === chatRoomID) {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.chatRoomElFindServiceInformationHandler, { chatRoomID }],
        });
      }
    };
  }, [chatRoomID]);

  const isShowDetail = !(
    isKakaoIcon === true &&
    isGrayAfterDocIcon === false &&
    isColorAfterDocIcon === false
  );

  const handleToggle = async (chatRoomId: ChatRoomID) => {
    if (!chatRoomId) return;
    resetIsSendingTranslate();
    resetReq();
    const newIsChatTranslate = !isActivateChatTranslate;
    try {
      setIsActivateChatTranslate(newIsChatTranslate);
      await postTranstranslate({ chatRoomID: chatRoomId, usingTranslation: !!newIsChatTranslate });

      // 이 부분은 비즈니스 로직에 따라 useChatTranslationToggle 안으로 이동했습니다.
      // toastService.successMsg({ text: `번역을 ${newIsChatTranslate ? '시작' : '종료'}합니다` });
    } catch (err) {
      console.error('trans err', err);
      toastService.errorMsg({ text: '번역기능을 사용할 수 없습니다.' });
    }
  };

  return (
    <div
      className='flex h-[114px] flex-shrink-0 flex-col justify-between border-white border-white400 bg-white50 px-20 py-10 opacity-0"'
      style={{ minWidth: `${MIN_LAYOUT_WIDTH}` }}>
      <PatientTitle />
      {isShowDetail && <PatientDetail />}
      <div className='ml-12 flex flex-center justify-between'>
        {isShowDetail ? (
          <TreatmentTags />
        ) : (
          <div className='flex w-[500px] flex-col-center items-start text-Body12'>
            <span>카카오톡으로 문의한 고객입니다.</span>
            <span>
              [고객등록] 버튼을 클릭하여 고객으로 등록하면, 예약 및 고객정보를 기록할 수 있습니다.
            </span>
          </div>
        )}

        <div className='relative flex items-center'>
          <HoverTooltip
            show={true}
            text='자동번역 기능은 베타 버전으로 일부 번역 결과에 오류가 있을 수 있습니다.'
            position='bottomRight'
            className='w-[193px]'
            wrapperProps={{
              className: 'cursor-default',
            }}>
            <div
              className='absolute left-[-12px] h-12 w-21 text-purple500 italic '
              style={{
                transform: 'translateY(-120%)',
                fontSize: '12px',
                fontFamily: 'Gogh, sans-serif',
                fontWeight: 800,
              }}>
              Beta
            </div>
            <div className='w-50 flex-shrink-0 justify-center text-Body10Bold text-black500'>
              자동번역
            </div>
          </HoverTooltip>
          <Toggle
            onChange={() => handleToggle(chatRoomID)}
            checked={isActivateChatTranslate}
            defaultChecked={isActivateChatTranslate}
          />
        </div>
      </div>
    </div>
  );
}
