import type { ApiPatientsElListOrSearchParams } from 'web/apis/swaggers/swagger-docs';

export const korFilterNameKeyWithEngValue: Record<
  string,
  ApiPatientsElListOrSearchParams['target']
> = {
  전체: 'ALL',
  가입자: 'JOINED',
  미가입자: 'NOT-REGISTERED',
};
