import Checkbox from '@afterdoc-design-system/components/Atoms/Checkbox/Checkbox';
import LabelText from '@afterdoc-design-system/components/Atoms/LabelText/LabelText';
import Scrollbar from '@afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import DashedTag from '@afterdoc-design-system/components/Atoms/Tag/DashedTag';
import FilledTag from '@afterdoc-design-system/components/Atoms/Tag/FilledTag';
import { SHARED_UTILS } from '@shared-utils/utils';
import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { useAtom, useAtomValue } from 'jotai';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form'; // Controller 추가
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { ApiManualsElFoldersParams } from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import type { MarketingAutomationAPIFormValues } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/components/RegisterMarketingAutomationDialogContent';
import {
  checkedLayersState,
  checkedOrphanSubLayersState,
  checkedSubLayersState,
} from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingBaseSetting/states/selected-layers';
import { algorithmModeState } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/states/algorithm-mode';

const textClassName = 'select-none text-Caption9 text-black800';

const getManualFolders = async (params: ApiManualsElFoldersParams) => {
  const response = await apiClient.v3.apiManualsElFolders(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export default function TreatmentTagSetting() {
  const isFirstRender = useRef(true);
  const tagContainerRef = useRef<HTMLDivElement>(null);
  const queryClient = useQueryClient();

  const { setValue, watch } = useFormContext<MarketingAutomationAPIFormValues>();

  const { hospitalID } = useSelectedHospitalInfo();

  const {
    data: { foldersHasTags, unassignedTags },
  } = useSuspenseQuery({
    queryKey: [QUERY_KEY.apiManualsElFolders, { hospitalID }] as const,
    queryFn: ({ queryKey }) => getManualFolders(queryKey[1]),
  });

  const algorithmMode = useAtomValue(algorithmModeState);
  const [checkedLayers, setCheckedLayers] = useAtom(checkedLayersState);
  const [checkedSubLayers, setCheckedSubLayers] = useAtom(checkedSubLayersState);
  const [checkedOrphanSubLayers, setCheckedOrphanSubLayers] = useAtom(checkedOrphanSubLayersState);
  const [tagMaxLengths, setTagMaxLengths] = useState<Record<string, number>>({});

  const targetTreatmentTags = watch('targetTreatmentTags');
  const isForNoTreatmentTags = watch('isForNoTreatmentTags');
  const disabled = algorithmMode.mode === 'EDIT';

  const layers = useMemo(
    () =>
      foldersHasTags
        ?.filter((folder) => folder.treatmentTags?.length)
        ?.map((folder) => ({
          id: folder._id,
          title: folder.name,
          items: folder.treatmentTags?.map((tag) => ({
            id: tag._id,
            text: tag.categoryName,
            color: tag.color,
          })),
        })),
    [foldersHasTags],
  );

  const orphanSubLayers = useMemo(
    () =>
      unassignedTags?.map((tag) => ({
        id: tag._id,
        text: tag.categoryName,
        color: tag.color,
      })),
    [unassignedTags],
  );

  useEffect(() => {
    if (!tagContainerRef.current) return;

    const measureDiv = document.createElement('div');
    measureDiv.style.position = 'absolute';
    measureDiv.style.visibility = 'hidden';
    measureDiv.style.whiteSpace = 'nowrap';
    measureDiv.style.fontSize = '12px';
    measureDiv.style.fontFamily = 'inherit';
    measureDiv.style.padding = '2px 8px';
    measureDiv.style.boxSizing = 'border-box';
    document.body.appendChild(measureDiv);

    const containerWidth = tagContainerRef.current.clientWidth;
    const maxTagWidth = containerWidth - 120;

    const calculateMaxLengths: Record<string, number> = {};

    const processTag = (tag: { id: string; text: string }) => {
      measureDiv.innerText = tag.text;
      let maxTextLength = tag.text.length;
      let tagWidth = measureDiv.getBoundingClientRect().width;

      const adjustedMaxWidth = maxTagWidth * 1.5;

      if (tagWidth > adjustedMaxWidth) {
        while (tagWidth > adjustedMaxWidth && maxTextLength > 3) {
          maxTextLength--;
          measureDiv.innerText = `${tag.text.slice(0, maxTextLength)}...`;
          tagWidth = measureDiv.getBoundingClientRect().width;
        }
      }
      calculateMaxLengths[tag.id] = maxTextLength;
    };

    for (const layer of layers ?? []) {
      for (const item of layer.items ?? []) {
        processTag(item);
      }
    }

    for (const orphanSubLayer of orphanSubLayers ?? []) {
      processTag(orphanSubLayer);
    }

    document.body.removeChild(measureDiv);
    setTagMaxLengths(calculateMaxLengths);
  }, [layers, orphanSubLayers]);

  const handleLayerChange = (layerId: string) => {
    setCheckedLayers((prev) => {
      const isChecked = !prev[layerId];
      const updated = { ...prev, [layerId]: isChecked };

      const layer = layers?.find((layer) => layer.id === layerId);
      const newCheckedSubLayers = { ...checkedSubLayers };

      if (layer?.items) {
        for (const item of layer.items) {
          newCheckedSubLayers[item.id] = isChecked;
        }
        setCheckedSubLayers(newCheckedSubLayers);
      }

      const newTargetTreatmentTags = Object.keys({
        ...newCheckedSubLayers,
        ...checkedOrphanSubLayers,
      }).filter((key) => newCheckedSubLayers[key] || checkedOrphanSubLayers[key]);
      handleUpdateTargetTreatmentTags(newTargetTreatmentTags);

      return updated;
    });
  };

  const handleSubLayerChange = (layerId: string, subLayerId: string) => {
    setCheckedSubLayers((prev) => {
      const isChecked = !prev[subLayerId];
      const updated = { ...prev, [subLayerId]: isChecked };

      const layer = layers?.find((layer) => layer.id === layerId);
      if (layer?.items) {
        const allChecked = layer.items.every((item) => updated[item.id]);
        setCheckedLayers((prevLayers) => ({ ...prevLayers, [layerId]: allChecked }));
      }

      const newTargetTreatmentTags = Object.keys({ ...updated, ...checkedOrphanSubLayers }).filter(
        (key) => updated[key] || checkedOrphanSubLayers[key],
      );
      handleUpdateTargetTreatmentTags(newTargetTreatmentTags);

      return updated;
    });
  };

  const handleOrphanSubLayerChange = (orphanSubLayerId: string) => {
    setCheckedOrphanSubLayers((prev) => {
      const updated = { ...prev, [orphanSubLayerId]: !prev[orphanSubLayerId] };

      const newTargetTreatmentTags = Object.keys({ ...checkedSubLayers, ...updated }).filter(
        (key) => checkedSubLayers[key] || updated[key],
      );
      handleUpdateTargetTreatmentTags(newTargetTreatmentTags);

      return updated;
    });
  };

  const handleUpdateTargetTreatmentTags = (newTargetTreatmentTags: string[]) => {
    setValue('targetTreatmentTags', newTargetTreatmentTags, {
      shouldDirty: true,
      shouldTouch: true,
    });
    queryClient.refetchQueries({
      queryKey: [
        QUERY_KEY.targetableNationalitiesHandler,
        {
          hospitalID,
          treatmentTagId: newTargetTreatmentTags.join(','),
        },
      ],
    });
  };

  const handleUpdateIsForNoTreatmentTags = (value: boolean) => {
    setValue('isForNoTreatmentTags', value, {
      shouldDirty: true,
      shouldTouch: true,
    });
    queryClient.refetchQueries({
      queryKey: [
        QUERY_KEY.targetableNationalitiesHandler,
        {
          hospitalID,
          treatmentTagId: targetTreatmentTags ? targetTreatmentTags?.join(',') : undefined,
        },
      ],
    });
  };

  useEffect(() => {
    if (algorithmMode.mode === 'CREATE' || !targetTreatmentTags?.length) return;

    if (isFirstRender.current) {
      isFirstRender.current = false;
      setCheckedLayers((prev) => {
        const updated = { ...prev };
        for (const layer of layers ?? []) {
          updated[layer.id] =
            layer.items?.every((item) => targetTreatmentTags.includes(item.id)) ?? false;
        }
        return updated;
      });

      setCheckedSubLayers((prev) => {
        const updated = { ...prev };
        for (const layer of layers ?? []) {
          for (const item of layer.items ?? []) {
            updated[item.id] = targetTreatmentTags.includes(item.id);
          }
        }
        return updated;
      });

      setCheckedOrphanSubLayers((prev) => {
        const updated = { ...prev };
        for (const item of orphanSubLayers ?? []) {
          updated[item.id] = targetTreatmentTags.includes(item.id);
        }
        return updated;
      });
    }
  }, [algorithmMode.mode, targetTreatmentTags]);

  return (
    <div className='mt-10 grid w-full'>
      <div className='flex w-full flex-col gap-4'>
        <LabelText isRequired textClassName='text-Body10Bold'>
          치료태그
        </LabelText>
        <div className='h-[250px] rounded-r10 border border-black200 bg-white50 py-4 pr-4 pl-16'>
          <Scrollbar disabledX>
            <div ref={tagContainerRef} className='flex flex-col gap-10 py-12'>
              <Checkbox
                disabled={disabled}
                size={20}
                label={<DashedTag tagSize='small'>치료태그 미입력</DashedTag>}
                checked={isForNoTreatmentTags}
                onChange={(e) => {
                  handleUpdateIsForNoTreatmentTags(e.target.checked);
                }}
              />
              {layers?.map((layer) => {
                if (!layer || !layer.items) return null;

                return (
                  <div key={layer.id} className='flex flex-col gap-10'>
                    <div className='flex items-center gap-7'>
                      <Checkbox
                        disabled={disabled}
                        size={20}
                        checked={checkedLayers[layer.id] || false}
                        onChange={() => {
                          handleLayerChange(layer.id);
                        }}
                        label={<div className={textClassName}>{layer.title}</div>}
                      />
                    </div>
                    {layer.items.length > 0 && (
                      <div className='flex flex-col gap-10'>
                        {layer.items.map((subLayer) => (
                          <div className='ml-28 flex items-center gap-10' key={subLayer.id}>
                            <Checkbox
                              disabled={disabled}
                              size={20}
                              checked={checkedSubLayers[subLayer.id] || false}
                              onChange={() => {
                                handleSubLayerChange(layer.id, subLayer.id);
                              }}
                              label={
                                <FilledTag
                                  maxTextLength={tagMaxLengths[subLayer.id]}
                                  className='cursor-pointer'
                                  tagSize='small'
                                  bgColor={subLayer.color}>
                                  {subLayer.text}
                                </FilledTag>
                              }
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                );
              })}
              {orphanSubLayers?.map((orphanSubLayer) => {
                if (!orphanSubLayer) return null;

                return (
                  <div className='flex items-center gap-10' key={orphanSubLayer.id}>
                    <Checkbox
                      disabled={disabled}
                      size={20}
                      checked={checkedOrphanSubLayers[orphanSubLayer.id] || false}
                      onChange={() => {
                        handleOrphanSubLayerChange(orphanSubLayer.id);
                      }}
                      label={
                        <FilledTag
                          maxTextLength={tagMaxLengths[orphanSubLayer.id]}
                          tagSize='small'
                          clickable={!disabled}
                          className={disabled ? 'cursor-not-allowed' : 'cursor-pointer'}
                          bgColor={orphanSubLayer.color}>
                          {orphanSubLayer.text}
                        </FilledTag>
                      }
                    />
                  </div>
                );
              })}
            </div>
          </Scrollbar>
        </div>
      </div>
    </div>
  );
}
