import ReceiveTimePicker from '@templates/UserInfoSetting/containers/NotificationSetting/components/NotificationTimeRangeControl/ReceiveTimeSetting/components/ReceiveTimePicker';
import { useNotificationSetting } from '@templates/UserInfoSetting/containers/NotificationSetting/hooks/use-notification-setting';
import type { StringSettingKey } from '@templates/UserInfoSetting/containers/NotificationSetting/types/res-notification-setting-type';
import dayjs from 'dayjs';

interface ReceiveTimeSettingProps {
  isActive: boolean;
  startTimeKey: StringSettingKey;
  endTimeKey: StringSettingKey;
  onHandleChange: () => void;
}

const getFormattedTime = (timeValue: string | null | undefined, type: 'start' | 'end'): string => {
  const parsedTime = dayjs(timeValue);
  if (timeValue && parsedTime.isValid()) {
    return timeValue;
  }

  const defaultHour = type === 'start' ? 9 : 20;
  return dayjs().hour(defaultHour).minute(0).second(0).millisecond(0).format();
};

export default function ReceiveTimeSetting({
  isActive,
  startTimeKey,
  endTimeKey,
  onHandleChange,
}: ReceiveTimeSettingProps) {
  const { getValue, setValue } = useNotificationSetting();

  const startTime = getFormattedTime(getValue(startTimeKey), 'start');
  const endTIme = getFormattedTime(getValue(endTimeKey), 'end');

  const updateTime = (key: StringSettingKey, time: string) => {
    onHandleChange();
    setValue(key, time);
  };

  return (
    <div className='flex-center gap-10'>
      <ReceiveTimePicker
        isActive={isActive}
        date={startTime}
        onSelect={(time) => updateTime(startTimeKey, time)}
      />
      <span className='select-none text-Body12 text-black500'>~</span>
      <ReceiveTimePicker
        isActive={isActive}
        date={endTIme}
        onSelect={(time) => updateTime(endTimeKey, time)}
      />
    </div>
  );
}
