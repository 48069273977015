import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';

interface ManualTreatmentTagContentProps {
  tagName: string;
  tagColor: string;
}

export default function ManualTreatmentTagContent({
  tagName,
  tagColor,
}: ManualTreatmentTagContentProps) {
  return (
    <div className='flex w-full gap-6 py-28'>
      <Icon name='tag' size={24} customColor={tagColor} className='flex-shrink-0' />
      <div className='flex-grow overflow-hidden break-words text-Header16'>{tagName}</div>
    </div>
  );
}
