import type { HospitalEventFileWriter } from '@apis/swaggers/swagger-docs';
import type { CreateUpdateEventParamType } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/types/create-update-event-param-type';
import dayjs from 'dayjs';

export const convertHospitalEventToParams = (
  data: HospitalEventFileWriter,
): CreateUpdateEventParamType => {
  const {
    _id = '',
    hospitalID = '',
    title = '',
    description = '',
    price = 0,
    salePrice = 0,
    saleRate = 0,
    saleType = 'price',
    isLimitedTime = false,
    startDate = '',
    endDate = '',
    isActive = true,
    isPinned = false,
    representImage,
    type = 'image',
    content = '',
  } = data;

  return {
    _id,
    title,
    hospitalID,
    description,
    price,
    salePrice,
    saleRate,
    saleType,
    isLimitedTime,
    startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD'),
    endDate: endDate
      ? dayjs(endDate).format('YYYY-MM-DD')
      : dayjs().add(7, 'day').format('YYYY-MM-DD'),
    isActive,
    isPinned,
    representImage: representImage?._id ?? '',
    type,
    content,
  };
};
