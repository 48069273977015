export const MAX_LENGTH = 1000;

export const MARKETING_REPLACEABLE_TEXTS = [
  {
    text: '{{고객명}}',
    description: '해당 메시지를 수신 받을 고객명이 표시됩니다. 예) 홍길동',
    length: 15,
  },
  {
    text: '{{병원명}}',
    description: '병원명이 표시됩니다. 예) 애프터닥의원',
    length: 30,
  },
  {
    text: '{{치료태그명}}',
    description: '고객에게 첫번째로 등록된 치료태그명이 표시됩니다. 예) 도수치료',
    length: 50,
  },
];
