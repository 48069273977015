import FilledTag from '@afterdoc-design-system/components/Atoms/Tag/FilledTag';
import HoverTooltip from '@afterdoc-design-system/components/Atoms/Tooltip/HoverTooltip';
import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';
import { Color } from '@tailwind-base/styles/color';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import type { CustomerCountData } from 'web/apis/swaggers/swagger-docs';
import { CHART_OPTIONS } from 'web/templates/Automation/containers/Main/DashBoardContent/components/constants/chart-options';
import CardItemLayout from 'web/templates/Automation/containers/Main/shared/components/CardItemLayout';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface MonthlyRespondentsCardItemProps {
  monthlyRespondents?: CustomerCountData;
}

export default function MonthlyRespondentsCardItem({
  monthlyRespondents,
}: MonthlyRespondentsCardItemProps) {
  if (!monthlyRespondents) {
    return <CardItemLayout />;
  }

  const { total, changeFromLastMonth, monthlyCounts } = monthlyRespondents;

  const chartData = {
    labels: monthlyCounts?.map((item) => `${item.month}월`) || [],
    datasets: [
      {
        label: '고객 수',
        data: monthlyCounts?.map((item) => item.count) || [],
        borderColor: '#64B5F6',
        backgroundColor: 'rgba(100, 181, 246, 0.1)',
        fill: true,
        tension: 0,
        pointRadius: 0,
        datalabels: {
          display: false,
        },
      },
    ],
  };

  return (
    <CardItemLayout className='flex flex-col gap-24'>
      <TopInfo number={total} changeFromLastMonth={changeFromLastMonth} />
      <div className='mt-4 h-90'>
        <Line data={chartData} options={CHART_OPTIONS} height={90} />
      </div>
    </CardItemLayout>
  );
}

interface TopInfoProps {
  number?: number;
  changeFromLastMonth?: number;
}

function TopInfo({ number, changeFromLastMonth }: TopInfoProps) {
  return (
    <div className='flex items-start justify-between'>
      <div>
        <div className='text-Header12 text-black500'>월별 상담자동화 대상자 중 응답자 수</div>
        <div className='mt-8 flex items-center gap-10'>
          <div className='text-Header16 text-black700'>
            {number ? number.toLocaleString() : '-'}명
          </div>
          <FilledTag
            tagSize='small'
            textColor={
              changeFromLastMonth && changeFromLastMonth > 0 ? Color.green500 : Color.red500
            }
            maxTextLength='full'
            bgColor={changeFromLastMonth && changeFromLastMonth > 0 ? Color.green100 : Color.red50}>
            {`전달대비 ${changeFromLastMonth ?? 0}%
            ${changeFromLastMonth && changeFromLastMonth > 0 ? '증가' : '감소'}`}
          </FilledTag>
        </div>
      </div>
      <HoverTooltip
        text={'상담자동화가 적용된 고객 중 해당 월에\n응답을 한 고객의 수를 의미합니다.'}>
        <div className='p-6'>
          <Icon name='info-circle-outline' color='black200' size={20} />
        </div>
      </HoverTooltip>
    </div>
  );
}
