import { FormProvider, useForm } from 'react-hook-form';
import BoardPanelContainer from 'web/templates/CustomerManagement/containers/BoardPanel/BoardPanel.container';
import { DestroyBlockerProvider } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/context/component-destory-blocker.context';
import ControlPanelContainer from 'web/templates/CustomerManagement/containers/ControlPanel/ControlPanel.container';

export default function CustomerManagementContainer() {
  const methods = useForm({
    defaultValues: {
      rows: [],
    },
  });

  return (
    <DestroyBlockerProvider>
      <FormProvider {...methods}>
        <main className='grid h-full grid-cols-[360px,1fr]'>
          {/* 좌측 고객 관리 컨트롤 패널 */}
          <ControlPanelContainer />
          {/* 우측 고객 관리 보드 패널 */}
          <BoardPanelContainer />
        </main>
      </FormProvider>
    </DestroyBlockerProvider>
  );
}
