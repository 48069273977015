import IconButton from '@afterdoc-design-system/components/Atoms/Button/IconButton';
import DayPicker from '@afterdoc-design-system/components/Atoms/Calendar/DayPicker';
import TextInput from '@afterdoc-design-system/components/Atoms/Input/TextInput';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import dayjs from 'dayjs';
import { debounce } from 'lodash-es';
import { useCallback, useEffect, useRef, useState } from 'react';

const YEAR_RANGE = [1900, 2500] as [number, number];

const formatDateWithHyphens = (dateStr: string) => {
  const cleaned = dateStr.replace(/[^0-9]/g, '');
  if (cleaned.length <= 4) return cleaned;
  if (cleaned.length <= 6) return `${cleaned.slice(0, 4)}-${cleaned.slice(4)}`;
  return `${cleaned.slice(0, 4)}-${cleaned.slice(4, 6)}-${cleaned.slice(6)}`;
};

interface AutomationDaySelectorProps {
  className?: string;
  width?: string | number;
  selectedDate?: Date;
  onSelect: (date: Date) => void;
  handleInput?: (value: string) => void;
  hasDayChangeButton?: boolean;
  disableBeforeDate?: Date;
  disableAfterDate?: Date;
  yearRange?: [number, number];
}

export default function AutomationDaySelector({
  selectedDate,
  onSelect,
  handleInput,
  className,
  width,
  hasDayChangeButton = true,
  disableBeforeDate,
  disableAfterDate,
  yearRange,
}: AutomationDaySelectorProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  const [isValid, setIsValid] = useState(true);
  const [date, setDate] = useState(new Date());
  const [isToggle, setIsToggle] = useState(false);
  const [inputValue, setInputValue] = useState(dayjs(date).format('YYYY-MM-DD'));

  const isDateDisabled = (day: Date) => {
    const dayjsDate = dayjs(day);
    if (disableBeforeDate && dayjsDate.isBefore(dayjs(disableBeforeDate), 'day')) {
      return true;
    }
    if (disableAfterDate && dayjsDate.isAfter(dayjs(disableAfterDate), 'day')) {
      return true;
    }
    return false;
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === 'Escape') {
      setIsToggle(!isToggle);
      inputRef.current?.blur();
    }
  };

  const handleInputDebounced = useCallback(
    debounce((formattedInput: string) => {
      handleInput?.(formattedInput);
    }, 200),
    [handleInput],
  );

  const updateDateAndState = useCallback(
    (newDate: Date) => {
      setDate(newDate);
      setInputValue(dayjs(newDate).format('YYYY-MM-DD'));
      onSelect(newDate);
    },
    [onSelect],
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isToggle) {
      setIsToggle(true);
    }

    const input = e.target.value;
    if (/[^0-9-]/.test(input)) return;

    const formattedInput = formatDateWithHyphens(input);
    setInputValue(formattedInput);

    if (formattedInput.length === 10) {
      const [year, month, day] = formattedInput.split('-').map(Number);
      setIsValid(true);
      const newDate = new Date(year, month - 1, day);
      updateDateAndState(newDate);
    }

    handleInputDebounced(formattedInput);
  };

  const handleDateChange = (direction: 'prev' | 'next') => {
    const newDate =
      direction === 'prev'
        ? dayjs(date).subtract(1, 'day').toDate()
        : dayjs(date).add(1, 'day').toDate();

    if (!isDateDisabled(newDate)) {
      updateDateAndState(newDate);
    }
  };

  useEffect(() => {
    if (!selectedDate) return;

    setDate(selectedDate);
    setInputValue(dayjs(selectedDate).format('YYYY-MM-DD'));
  }, [selectedDate]);

  return (
    <div className='flex items-center gap-10'>
      {hasDayChangeButton && (
        <IconButton
          icon='chevron-left'
          size={16}
          color='black500'
          className='h-24 w-24 rounded-r6 border border-white400 p-3'
          onClick={() => handleDateChange('prev')}
        />
      )}
      <div className='relative'>
        <TextInput
          ref={inputRef}
          onKeyDown={handleKeyDown}
          value={inputValue}
          onChange={handleInputChange}
          onClick={() => {
            setIsToggle(!isToggle);
          }}
          maxLength={10}
          className={isValid ? '' : 'input-error'}
          width={width}
        />
        {isToggle && (
          <DayPicker
            hasError={!isValid}
            className={customTwMerge('absolute z-10', className)}
            onChangeDate={(selectedDate) => {
              if (!selectedDate) return;
              updateDateAndState(selectedDate);
              setIsToggle(false);
            }}
            selectedDate={date}
            handleToggle={() => setIsToggle(false)}
            yearRange={yearRange ?? YEAR_RANGE}
            disableBeforeDate={disableBeforeDate}
            disableAfterDate={disableAfterDate}
          />
        )}
      </div>
      {hasDayChangeButton && (
        <IconButton
          icon='chevron-right'
          size={16}
          color='black500'
          className='h-24 w-24 rounded-r6 border border-white400 p-3'
          onClick={() => handleDateChange('next')}
        />
      )}
    </div>
  );
}
