import ColorItem from '@templates/HospitalSetting/containers/ServiceSetting/containers/TreatmentTag/components/TreatmentTagList/TreatmentTagListItem/components/ColorItem';
import CustomColorPicker from '@templates/HospitalSetting/containers/ServiceSetting/containers/TreatmentTag/components/TreatmentTagList/TreatmentTagListItem/components/CustomColorPicker/CustomColorPicker';
import { serviceSettingState } from '@templates/HospitalSetting/containers/ServiceSetting/containers/TreatmentTag/states/service-setting-state';
import { showColorPickerIndexState } from '@templates/HospitalSetting/containers/ServiceSetting/containers/TreatmentTag/states/show-color-picker-index';
import { TagColor } from 'afterdoc-design-system/assets/images';
import { useClickOutside } from 'hooks/use-click-outside';
import { useAtomValue, useSetAtom } from 'jotai';
import { selectAtom } from 'jotai/utils';
import React, { useRef, useState } from 'react';
import { v4 } from 'uuid';

interface TagColorSelectorProps {
  id: string;
  color: string;
}

const basicPaletteAtom = selectAtom(
  serviceSettingState,
  (setting) => setting?.colorPalette?.basicPalette,
);

export default function TagColorSelector({ color, id }: TagColorSelectorProps) {
  const basicPalette = useAtomValue(basicPaletteAtom);

  const setShowColorPickerIndex = useSetAtom(showColorPickerIndexState);

  const pickerRef = useRef<HTMLDivElement>(null);
  const customPickerRef = useRef<HTMLDivElement>(null);

  const [visibleCustomPicker, setVisibleCustomPicker] = useState(false);

  const handleClick = () => {
    setVisibleCustomPicker(!visibleCustomPicker);
  };

  useClickOutside({
    id: v4(),
    ref: pickerRef,
    contentRef: pickerRef,
    ignoreRefs: [customPickerRef],
    onClose: () => {
      setShowColorPickerIndex(null);
    },
  });

  return (
    <>
      <div className='absolute top-9 left-14 z-10 '>
        <div className='flex gap-12'>
          <div
            ref={pickerRef}
            className='h-fit w-[172px] flex-col-center rounded-r10 bg-white50 p-10 shadow-black100'
            style={{
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
            }}>
            <div className='grid w-full grid-cols-5 gap-8 '>
              {basicPalette?.map((value) => (
                <ColorItem key={value} value={value} savedColor={color} id={id} />
              ))}
            </div>
            <div
              className='mt-10 h-28 w-full flex-center cursor-pointer gap-5 rounded-r6 bg-white100'
              onClick={handleClick}>
              <div className='flex h-24 w-24 items-center'>
                {basicPalette?.includes(color) ? (
                  <TagColor />
                ) : (
                  <div className='h-18 w-18 rounded-r16' style={{ backgroundColor: color }} />
                )}
              </div>
              <span className='text-Body12 text-black500'>다른 색상 선택</span>
            </div>
          </div>
          {visibleCustomPicker && (
            <CustomColorPicker
              defaultColor={color}
              id={id}
              ref={customPickerRef}
              onHandleClose={() => setShowColorPickerIndex(null)}
            />
          )}
        </div>
      </div>
    </>
  );
}
