import { selectedContentEditPriceErrorAtom } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/states/content-edit-errors-state';
import TextInput from 'afterdoc-design-system/components/Atoms/Input/TextInput';
import { useAtom } from 'jotai';
import { type ChangeEvent, useEffect, useState } from 'react';

interface PriceInputProps {
  price?: number;
  onHandlePriceValue: (value?: number) => void;
}

export default function PriceInput({ price, onHandlePriceValue }: PriceInputProps) {
  const [priceInputError, setPriceInputError] = useAtom(selectedContentEditPriceErrorAtom);

  const [inputPriceValue, setInputPriceValue] = useState('');

  const handlePriceChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPriceInputError(undefined);

    const inputValue = e.target.value.replace(/[^0-9]/g, '');
    const parsedValue = Number.parseInt(inputValue, 10);

    if (Number.isNaN(parsedValue)) {
      setInputPriceValue('');
      onHandlePriceValue(undefined);
      return;
    }

    const finalPrice = Math.min(parsedValue, 1_000_000_000);
    setInputPriceValue(finalPrice.toLocaleString());
    onHandlePriceValue(finalPrice);
  };

  useEffect(() => {
    if (inputPriceValue === '') {
      setInputPriceValue(!price ? '' : price.toLocaleString());
    }
  }, [price]);

  return (
    <div className='flex flex-col'>
      <TextInput
        className='h-full'
        placeholder='0'
        inputClassName='w-[120px]'
        value={inputPriceValue}
        onChange={handlePriceChange}
        label={{
          children: '기본가격',
          position: 'horizontal',
          width: 45,
          textClassName: 'text-Body12 text-black200 font-regular',
        }}
      />
      {priceInputError && <span className='pt-4 text-Body10 text-red500'>{priceInputError}</span>}
    </div>
  );
}
