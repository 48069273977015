import Scrollbar from '@afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import { useAtom } from 'jotai';
import { Suspense, useEffect } from 'react';
import LayoutBox from 'web/shared/components/LayoutBox/LayoutBox';
import { usePatientDetailInfo } from 'web/shared/hooks/use-get-patient-detail-info';
import { patientIdState } from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/state/show-customer-detail-info';
import CustomerInfoInputFormContainer from './containers/CareAndCustomerInfoInputForm/CareAndCustomerInfoInputForm.container';
import CustomerInfoInputBoardContainer from './containers/CustomerInfoInputBoard/CustomerInfoInputBoard.container';
import CustomerInfoTitleContainer from './containers/CustomerInfoTitle/CustomerInfoTitle.container';
import ReservationManagementContainer from './containers/ReservationManagement/ReservationManagement.container';

interface CustomerDetailInfoContainerProps {
  patientId: string;
}

export default function CustomerDetailInfoContainer({
  patientId,
}: CustomerDetailInfoContainerProps) {
  const [globalPatientId, setGlobalPatientId] = useAtom(patientIdState);

  useEffect(() => {
    if (patientId) {
      setGlobalPatientId(patientId);
    }

    return () => setGlobalPatientId(null);
  }, [patientId, setGlobalPatientId]);

  if (!globalPatientId) return null;

  return <CustomerDetailInfoContent />;
}

function CustomerDetailInfoContent() {
  const patientInfo = usePatientDetailInfo();

  if (!patientInfo) return null;

  return (
    <Scrollbar>
      <div className='w-full px-40'>
        <Suspense>
          <CustomerInfoTitleContainer />
        </Suspense>
        <div className='grid w-full grid-cols-[1fr,374px] gap-40'>
          <div className='mt-18 grid grid-rows-[auto,auto] gap-34'>
            {/* 적용 중인 상담자동화, 고객메모, 관리방향 */}
            <LayoutBox noStyle>
              <CustomerInfoInputBoardContainer />
            </LayoutBox>

            {/* 예약관리 */}
            <Suspense>
              <ReservationManagementContainer />
            </Suspense>
          </div>
          {/* 우측 고객정보 */}
          <Suspense>
            <CustomerInfoInputFormContainer />
          </Suspense>
        </div>
      </div>
    </Scrollbar>
  );
}
