import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import { useSelectedHospitalInfo } from '@shared/hooks/use-selected-hospital-info';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';
import OutlinedButton from 'afterdoc-design-system/components/Atoms/Button/OutlinedButton';
import { fullDimmedLoadingService } from 'afterdoc-design-system/components/Atoms/Loading/FullDimmedLoading/FullDimmedLoading.service';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { dialogService } from 'afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import React from 'react';
import { type SubmitHandler, useFormContext } from 'react-hook-form';
import { SHARED_UTILS } from 'utils/utils';

export type HospitalsAccountsCountTuningFormValues = Parameters<
  typeof apiClient.v3.hospitalsAccountsCountTuningHandler
>[0];

const requestHospitalsAccountsIncrease = async (params: HospitalsAccountsCountTuningFormValues) => {
  const { data } = await apiClient.v3.hospitalsAccountsCountTuningHandler(params);
  return SHARED_UTILS.api.checkApiResponse(data);
};

export default function AccountFormActionButtons() {
  const { handleSubmit, setError } = useFormContext<HospitalsAccountsCountTuningFormValues>();
  const { hospitalID } = useSelectedHospitalInfo();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (params: HospitalsAccountsCountTuningFormValues) =>
      requestHospitalsAccountsIncrease(params),
    onMutate: () => fullDimmedLoadingService.on(),
    onSettled: () => fullDimmedLoadingService.off(),
    onSuccess: () => handleMutationSuccess(),
    onError: (error) => handleMutationError(error),
  });

  const handleMutationSuccess = () => {
    queryClient.invalidateQueries({ queryKey: [QUERY_KEY.apiServiceSettingsElFindOne] });
    dialogService.popById('add-account-request-dialog');
  };

  const handleMutationError = (error: Error) => {
    console.error('Error while creating hospital account request :', error);
    toastService.errorMsg({ text: '요청을 실패했습니다. 다시 시도해 주세요.' });
  };

  const handleCancel = () => {
    dialogService.popById('add-account-request-dialog');
  };

  const validateFields = (data: HospitalsAccountsCountTuningFormValues) => {
    const { requesterName, requesterContact, requestedAccountCount } = data;
    const errors: { field: keyof HospitalsAccountsCountTuningFormValues }[] = [];

    if (!requesterName) {
      errors.push({ field: 'requesterName' });
    }
    if (!requesterContact) {
      errors.push({ field: 'requesterContact' });
    }
    const count = Number(requestedAccountCount);
    if (!requestedAccountCount || Number.isNaN(count)) {
      errors.push({ field: 'requestedAccountCount' });
    }

    if (errors.length > 0) {
      for (const error of errors) {
        setError(error.field, { type: 'manual' });
      }
      return false;
    }
    return true;
  };

  const onSubmit: SubmitHandler<HospitalsAccountsCountTuningFormValues> = (data) => {
    if (!validateFields(data)) {
      return;
    }
    const transformedData = {
      ...data,
      hospitalID,
      requestedAccountCount: Number(data.requestedAccountCount),
    };
    mutation.mutate(transformedData);
  };

  return (
    <div className='flex w-full gap-8 px-20 py-20'>
      <OutlinedButton btnColor='blue' className='flex-grow' onClick={handleCancel}>
        취소
      </OutlinedButton>
      <ContainedButton btnColor='blue' className='flex-grow' onClick={handleSubmit(onSubmit)}>
        확인
      </ContainedButton>
    </div>
  );
}
