import TextButton from '@afterdoc-design-system/components/Atoms/Button/TextButton';
import { useSetAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import DownloadManualPDFButton from 'web/templates/HospitalManual/components/HospitalManualPanel/components/AddFolderAndDownloadManualBox/components/DownloadManualPDFButton/DownloadManualPDFButton';
import {
  isAddingNewFolderState,
  newFolderNameState,
} from 'web/templates/HospitalManual/components/HospitalManualPanel/states/is-adding-new-folder';

export default function AddFolderAndDownloadManualBox() {
  const setIsAddingNewFolder = useSetAtom(isAddingNewFolderState);
  const resetNewFolderName = useResetAtom(newFolderNameState);

  return (
    <div className='flex h-[40px] w-full items-center justify-between bg-white100 px-20'>
      <TextButton
        iconProps={{
          name: 'add-circle',
          color: 'blue500',
          size: 16,
        }}
        textColor='blue500'
        onClick={() => {
          resetNewFolderName();
          setIsAddingNewFolder(true);
        }}>
        폴더 추가하기
      </TextButton>
      <DownloadManualPDFButton />
    </div>
  );
}
