import { useState } from 'react';
import ImageViewer from 'web/shared/components/ImageViewer/ImageViewer';
import { useChatMessageContext } from '../ChatMessageContext';
import TextContentMessage from './TextContentMessage/TextContentMessage';

export default function AutomationMarketingMessage() {
  const {
    fileIDs,
    isMine,
    messageWidthStyle,
    content,
    userRealName,
    userName,
    jobType,
    createdAt,
  } = useChatMessageContext();
  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);

  const images = fileIDs?.map(({ _id, aws }) => {
    return {
      id: _id,
      url: aws?.[0].Location,
      fileName: aws?.[0].fileName,
      sentAt: createdAt,
      name: (userRealName || userName) ?? '',
      jobType,
    };
  });

  if (fileIDs === undefined) return null;
  return (
    <div className='flex flex-col gap-8'>
      {content && <TextContentMessage />}
      <div
        className={`rounded-r10 bg-white50 px-16 py-10 shadow-modal ${isMine ? 'bg-[rgba(255,245,208,1)]' : 'bg-white50'}`}
        style={messageWidthStyle}>
        {fileIDs?.map(
          ({ paths, createdAt }) =>
            paths && (
              <img
                key={createdAt}
                src={paths[0]}
                alt='marketing'
                className='h-full w-full'
                onClick={() => {
                  setIsImageViewerOpen(true);
                }}
              />
            ),
        )}
      </div>
      {isImageViewerOpen && (
        <ImageViewer
          selectedIndex={0}
          imageObjects={images}
          imageUrls={fileIDs.map(({ paths }) => paths[0])}
          onClose={() => setIsImageViewerOpen(false)}
        />
      )}
    </div>
  );
}
