import type { Juso } from '@apis/swaggers/swagger-docs';

interface AddressCardItemProps {
  juso: Juso;
  onSelect: (juso: Juso) => void;
}

export default function AddressCardItem({ juso, onSelect }: AddressCardItemProps) {
  const { zipNo, roadAddr, jibunAddr } = juso;
  return (
    <>
      <div
        className='flex w-full cursor-pointer flex-col border-white400 border-t px-20 py-12 hover:bg-white100'
        onClick={() => {
          onSelect(juso);
        }}>
        <span className='text-Header12 text-blue500'>{zipNo}</span>
        <span className='mt-10 text-Header12 text-black700'>{roadAddr}</span>
        <span className='text-Body11 text-black200'>{jibunAddr}</span>
      </div>
    </>
  );
}
