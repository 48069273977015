import NoticeListContainer from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Notice/components/NoticeList/NoticeListContainer';
import NoticeManageDialogContainer from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Notice/components/NoticeManageDialog/NoticeManageDialogContainer';
import { contentEditState } from '@templates/Content/containers/ContentManager/states/content-edit-state';
import { useAtomValue } from 'jotai/index';

export default function NoticeContainer() {
  const contentEdit = useAtomValue(contentEditState);

  return (
    <>
      <NoticeListContainer />
      {contentEdit.isEditing && <NoticeManageDialogContainer />}
    </>
  );
}
