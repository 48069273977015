import TextButton from '@afterdoc-design-system/components/Atoms/Button/TextButton';
import { fullDimmedLoadingService } from '@afterdoc-design-system/components/Atoms/Loading/FullDimmedLoading/FullDimmedLoading.service';
import { toastService } from '@afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { modalService } from '@afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { SHARED_UTILS } from '@shared-utils/utils';
import { useMutation, useSuspenseQuery } from '@tanstack/react-query';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { ApiPatientsElDetailParams } from 'web/apis/swaggers/swagger-docs';
import { usePatientDetailInfo } from 'web/shared/hooks/use-get-patient-detail-info';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';

type PostReInvitationSMSParams = Parameters<
  typeof apiClient.v3.apiPatientsRegistrationsElReinvitationWithSms
>[0];

const getPatientInfo = async (params: ApiPatientsElDetailParams) => {
  const response = await apiClient.v3.apiPatientsElDetail(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const postReInvitationSMS = async (params: PostReInvitationSMSParams) => {
  const response = await apiClient.v3.apiPatientsRegistrationsElReinvitationWithSms(params);
  return response;
};

export default function ReInvitationButton() {
  const { patientId } = usePatientDetailInfo();
  const { hospitalID } = useSelectedHospitalInfo();

  const mutationPostReInvitationSMS = useMutation({
    mutationFn: (params: PostReInvitationSMSParams) => postReInvitationSMS(params),
    onSuccess: (data) => {
      fullDimmedLoadingService.off();

      // 성공한 경우
      if (data.data.code === 0) {
        return toastService.successMsg({
          text: '애프터닥 앱 가입 문자를 발송했습니다.',
        });
      }

      // 실패한 경우
      return modalService.defaultWarning({
        id: 're-invitation-sms-fail',
        title: '문자 발송 실패',
        contents: '문자 발송을 실패했습니다. 다시 시도하시겠습니까?',
        buttonText: ['확인', '취소'],
        onConfirm: () => {
          mutationPostReInvitationSMS.mutate({
            hospitalID,
            hserviceID: patientId,
          });
          modalService.popById('re-invitation-sms-fail');
        },
      });
    },
    onError: () => {
      fullDimmedLoadingService.off();
    },
  });

  const onReInvitationClick = () => {
    fullDimmedLoadingService.on();
    mutationPostReInvitationSMS.mutate({
      hospitalID,
      hserviceID: patientId,
    });
  };

  const {
    data: { hasAgreedPrivacyTerms, isClosed, hasSmartDoctorLinkage },
  } = useSuspenseQuery({
    queryKey: [QUERY_KEY.apiPatientsElDetail, { patientId }] as const,
    queryFn: ({ queryKey }) => getPatientInfo(queryKey[1]),
  });

  // 전능 이용자면서 개인정보 이용 동의를 하지 않은경우
  const isNotAgreedPrivacyTermsWhoIsSmartDoctorUser =
    hasSmartDoctorLinkage && !hasAgreedPrivacyTerms;

  // 전능 이용자가 아니면서 이용제외된 경우
  const isClosedWhoIsNotSmartDoctorUser = !hasSmartDoctorLinkage && isClosed;

  if (isNotAgreedPrivacyTermsWhoIsSmartDoctorUser || isClosedWhoIsNotSmartDoctorUser) return null;

  return (
    <TextButton disabled={isClosed} onClick={onReInvitationClick}>
      가입문자 재발송
    </TextButton>
  );
}
