import type { PopupEditError } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/types/popup-edit-error-type';
import { atom } from 'jotai/index';
import { atomWithReset } from 'jotai/utils';

export const popupEditErrorState = atomWithReset<PopupEditError>({
  name: null,
  date: null,
  target: null,
  noticeConnect: null,
  title: null,
  contentText: null,
  contentImage: null,
});

const createErrorAtom = (key: keyof PopupEditError) =>
  atom(
    (get) => get(popupEditErrorState)[key],
    (get, set, value: string | null) => {
      const currentState = get(popupEditErrorState);
      set(popupEditErrorState, {
        ...currentState,
        [key]: value || null,
      });
    },
  );

export const selectedPopupEditNameErrorAtom = createErrorAtom('name');
export const selectedPopupEditDateErrorAtom = createErrorAtom('date');
export const selectedPopupEditTargetErrorAtom = createErrorAtom('target');
export const selectedPopupEditNoticeConnectErrorAtom = createErrorAtom('noticeConnect');
export const selectedPopupEditTitleErrorAtom = createErrorAtom('title');
export const selectedPopupEditContentTextErrorAtom = createErrorAtom('contentText');
export const selectedPopupEditContentImageErrorAtom = createErrorAtom('contentImage');
