import { hospitalInfoState } from '@templates/HospitalSetting/containers/HospitalProfile/states/hospital-info-state';
import TextInput from 'afterdoc-design-system/components/Atoms/Input/TextInput';
import { useAtomValue } from 'jotai';
import { useSetAtom } from 'jotai/index';
import { selectAtom } from 'jotai/utils';

const hospitalContactInfoAtom = selectAtom(hospitalInfoState, (hospital) => ({
  phone: hospital.phone,
  homepageURL: hospital.homepageURL,
}));

export default function ContactInfoInput() {
  const contactInfo = useAtomValue(hospitalContactInfoAtom);
  const setHospitalInfo = useSetAtom(hospitalInfoState);

  const handlePhoneInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const regex = /^[0-9]*$/;
    if (regex.test(value)) {
      setHospitalInfo((prev) => ({
        ...prev,
        phone: value,
      }));
    }
  };

  const handleHomepageURLInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHospitalInfo((prev) => ({
      ...prev,
      homepageURL: e.target.value,
    }));
  };

  return (
    <>
      <div className='flex flex-1 flex-col justify-center gap-20'>
        <TextInput
          label={{
            children: '전화번호',
            width: 60,
            position: 'horizontal',
          }}
          className='w-full'
          maxLength={20}
          value={contactInfo.phone ?? ''}
          onChange={handlePhoneInputChange}
          placeholder='예) 0212345678'
        />
        <TextInput
          label={{
            children: '홈페이지',
            width: 60,
            position: 'horizontal',
          }}
          className='w-full'
          maxLength={1000}
          onChange={handleHomepageURLInputChange}
          value={contactInfo.homepageURL ?? ''}
          placeholder='예) www.afterdoc.co.kr'
        />
      </div>
    </>
  );
}
