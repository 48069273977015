import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import dayjs from 'dayjs';
import type { MemoWithFileID } from 'web/apis/swaggers/swagger-docs';

interface ManagementDirectionMemoCardProps {
  managementDirection: MemoWithFileID;
  onClick: () => void;
  selectedManagementDirectionId?: string;
}

export default function ManagementDirectionMemoCard({
  managementDirection,
  onClick,
  selectedManagementDirectionId,
}: ManagementDirectionMemoCardProps) {
  const {
    content,
    _id: managementDirectionId,
    updatedAt,
    writerName,
    modifierName,
  } = managementDirection;

  const bottomText =
    managementDirection && dayjs(updatedAt).isValid()
      ? `${dayjs(updatedAt).format('YYYY-MM-DD HH:mm')}/${modifierName ?? writerName}`
      : undefined;

  return (
    <div
      className={customTwMerge(
        'w-full cursor-pointer rounded-r10 border bg-white50 px-20 py-10',
        selectedManagementDirectionId === managementDirectionId
          ? 'border-blue500 bg-blue50'
          : 'border-white600',
      )}
      onClick={onClick}>
      <div
        className={customTwMerge(
          'flex h-[46px] items-start gap-4',
          managementDirection.fileIDs?.length ? 'flex-row' : 'w-full',
        )}>
        {!!managementDirection.fileIDs?.length && (
          <Icon name='image-filter' size={16} color='black200' />
        )}
        <div className='line-clamp-2 break-all text-Body12 text-black700'>{content}</div>
      </div>

      <div className='flex w-full items-center justify-between'>
        <div className='text-Body10 text-black200'>{bottomText}</div>
      </div>
    </div>
  );
}
