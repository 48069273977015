import { SHARED_UTILS } from '@shared-utils/utils';
import { useQuery } from '@tanstack/react-query';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { ManualAllContentsHandlerParams } from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';

const getAllManualContent = async (params: ManualAllContentsHandlerParams) => {
  const { data } = await apiClient.v3.manualAllContentsHandler(params);
  return SHARED_UTILS.api.checkApiResponse(data);
};

export const useManualData = ({ treatmentTagId }: { treatmentTagId: string }) => {
  const { hospitalID } = useSelectedHospitalInfo();

  const { data, error, isLoading } = useQuery({
    queryKey: [QUERY_KEY.manualAllContentsHandler, { hospitalID }] as const,
    queryFn: ({ queryKey }) => getAllManualContent(queryKey[1]),
  });

  const targetData = data?.find((content) => content.treatmentTagId === treatmentTagId);
  const manualContent = targetData?.manualContent ?? '';
  const treatmentCautionImages = targetData?.treatmentCautionImages ?? [];

  return { data: { manualContent, treatmentCautionImages }, error, isLoading };
};
