import dayjs from 'dayjs';

type shouldShowDateProps = {
  isLastSendMessage: boolean;
  previousMessageTime: string | undefined;
  currentMessageTime: string | undefined;
};

export const useDateDivider = () => {
  const shouldShowDate = ({
    isLastSendMessage,
    previousMessageTime,
    currentMessageTime,
  }: shouldShowDateProps): string | false => {
    const previousDate = dayjs(previousMessageTime);
    const currentDate = dayjs(currentMessageTime);

    if (isLastSendMessage && currentMessageTime) {
      return currentDate.format('YYYY년 M월 D일 dddd');
    }

    if (!previousMessageTime || !currentMessageTime) return false;

    if (!previousDate.isValid() || !currentDate.isValid()) return false;

    if (!previousDate.startOf('day').isSame(currentDate.startOf('day'))) {
      return currentDate.format('YYYY년 M월 D일 dddd');
    }

    return false;
  };

  return { shouldShowDate };
};
