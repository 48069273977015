import eventBus, { type DataNotificationPayload } from '@shared/utils/event-bus';
import { useEffect, useRef } from 'react';

export type HandlerConfig = {
  codes: string[];
  handler: (payload: DataNotificationPayload) => void;
};

export const useDataEventBusHandler = (configs: HandlerConfig[]) => {
  const stableConfigsRef = useRef<HandlerConfig[]>(configs);

  useEffect(() => {
    stableConfigsRef.current = configs;
  }, [configs]);

  // 이벤트 등록은 한번만 이루어진다. 의존성 배열 [] 빈값
  // 호출하는 핸들러는 useRef 로 관리하고있어 최신 핸들러만 호출할것이다.
  // 해당 훅의 인자로 사용되는 configs-handler 함수는 위 내용을 토대로 useCallback 을 사용해도 무관하지만
  // 예외케이스를 100% 장담하기 어려우므로 해당 configs 를 전달하는 handler 또한 useRef 관리한다. (ex: AdminChatMenu.tsx)
  useEffect(() => {
    const eventListener = (payload: DataNotificationPayload) => {
      const notificationCode = payload.data.code;
      const config = stableConfigsRef.current.find(({ codes }) => codes.includes(notificationCode));
      if (config) {
        config.handler(payload);
      }
    };

    eventBus.on('dataPushNotification', eventListener);
    return () => {
      eventBus.off('dataPushNotification', eventListener);
    };
  }, []);
};
