import { findALReservations, findALUsers } from '@apis/Almighty/api-client';
import type { ALReservation, ALUserIdAndNameDTO } from '@apis/Almighty/types';
import { FIND_USERS } from '@apis/Almighty/urls';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import type { SmartdoctorCustomer } from '@shared/components/ALIntegrationEdit/hooks/type';
import { useQuery } from '@tanstack/react-query';
import { reservationSelectedDateState } from '@templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/AfterDoc/ReservationHistory/states/date';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai/index';
import { useMemo } from 'react';

interface ReservationsRequest {
  name: string;
  cellPhone: string;
  reservationDateWithinStart?: string;
  reservationDateWithinEnd?: string;
}

interface ReservationsResponse {
  reservations: ALReservation[];
}

const transformReservations = (customerNumber: string, reservations?: ALReservation[]) => {
  if (reservations) {
    return reservations
      .filter((item) => item?.id?.customerNumber === customerNumber)
      .sort((l, r) => {
        const dateComparison = r.reservationDate.localeCompare(l.reservationDate);
        if (dateComparison !== 0) {
          return dateComparison;
        }
        return r.reservationStartTime.localeCompare(l.reservationStartTime);
      });
  }
  return [];
};

const mapConsultantsToReservations = (
  reservations: ALReservation[],
  consultantMap: Map<string, string>,
) => {
  return reservations.map((reservation) => ({
    ...reservation,
    consultantName: consultantMap.get(reservation.consultantId) || null,
  }));
};

export const useSmartDoctorReservationByDate = (customer: SmartdoctorCustomer | undefined) => {
  const reservationSelectedDate = useAtomValue(reservationSelectedDateState);

  const reservationDates = useMemo(() => {
    const formatDate = (date?: Date) => {
      if (date) {
        const formattedDate = dayjs(date);
        if (formattedDate.isValid()) {
          return formattedDate.format('YYYY-MM-DD');
        }
      }
      return undefined;
    };

    if (!reservationSelectedDate) return null;

    return {
      reservationDateWithinStart: formatDate(reservationSelectedDate?.startedAt),
      reservationDateWithinEnd: formatDate(reservationSelectedDate?.endedAt),
    };
  }, [reservationSelectedDate]);

  const fetchHistoryParamKey = useMemo(() => {
    if ((!customer?.name && !customer?.cellPhone) || !reservationDates) return null;
    return {
      name: customer?.name,
      cellPhone: customer?.cellPhone,
      ...reservationDates,
    };
  }, [customer, reservationDates]);

  const { data: consultants } = useQuery({
    queryKey: [FIND_USERS] as const,
    queryFn: () => findALUsers('CONSULTANT'),
    select: (response) => {
      if (Array.isArray(response.data.userIdAndNameDTOs)) {
        return response.data.userIdAndNameDTOs as ALUserIdAndNameDTO[];
      }
      return [];
    },
    enabled: !!customer?.customerNumber,
  });

  const consultantMap = useMemo(() => {
    if (!consultants) return new Map();
    return new Map(consultants.map((c) => [c.id, c.name]));
  }, [consultants]);

  const { data, isError } = useQuery({
    queryKey: [QUERY_KEY.smartDoctorReservationHistory, fetchHistoryParamKey] as const,
    queryFn: ({ queryKey }) => findALReservations(queryKey[1] as ReservationsRequest),
    select: (response) => {
      const filteredReservations = transformReservations(
        customer?.customerNumber as string,
        response.data?.reservations,
      );
      return { ...response.data, reservations: filteredReservations } as ReservationsResponse;
    },
    gcTime: 0,
    staleTime: 0,
    enabled: !!customer?.customerNumber && !!fetchHistoryParamKey,
  });

  const reservations = useMemo(() => {
    if (!data?.reservations || !consultantMap.size) return [];
    return mapConsultantsToReservations(data.reservations, consultantMap);
  }, [data, consultantMap]);

  return {
    reservations,
    isError,
  };
};
