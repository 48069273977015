export const extractFileName = (url: string): string | null => {
  try {
    const urlObj = new URL(url);
    const decodedPath = decodeURIComponent(urlObj.pathname);

    const fileName = decodedPath.split('/').pop();
    if (!fileName) return null;

    const nameWithoutExtension = fileName.split('.').slice(0, -1).join('.');
    return nameWithoutExtension;
  } catch (error) {
    console.error('Invalid URL:', error);
    return null;
  }
};

export const extractImageObjectFromS3Url = (url: string) => {
  const fileName = decodeURIComponent(url).split('/').pop() || '';
  return { url, fileName };
};
