import * as React from "react";
import type { SVGProps } from "react";
const SvgCheckboxMarkedDisabled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#D9D9D9"
      d="m10 17-5-5 1.41-1.42L10 14.17l7.59-7.59L19 8m0-5H5c-1.11 0-2 .89-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2"
    />
    <path fill="#fff" d="m5 12 5 5 9-9-1.41-1.42L10 14.17l-3.59-3.59z" />
  </svg>
);
export default SvgCheckboxMarkedDisabled;
