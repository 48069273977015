import type { PanelListProps } from 'afterdoc-design-system/components/Atoms/PanelList/PanelList';

export const HOSPITAL_SETTING_PANEL_ITEMS: PanelListProps['layers'] = [
  {
    title: '병원정보',
    state: 'focus',
    items: [
      { text: '병원프로필', state: 'default' },
      { text: '앱 가입문자 설정', state: 'default' },
    ],
  },
  {
    title: '서비스설정',
    state: 'default',
    items: [
      { text: '입력정보 관리', state: 'default' },
      { text: '채널연동', state: 'default' },
    ],
  },
  {
    title: '계정관리',
    state: 'default',
    items: [
      { text: '전체계정', state: 'default' },
      { text: '권한설정', state: 'default' },
    ],
  },
];
