interface GenerateTimeIntervalArray {
  startHour?: number;
  startMinute?: number;
  endHour?: number;
  endMinute?: number;
}

export const generateTimeIntervalArray = ({
  startHour = 8,
  startMinute = 30,
  endHour = 20,
  endMinute = 30,
}: GenerateTimeIntervalArray = {}): string[] => {
  const intervals: string[] = [];
  let currentHour = startHour;
  let currentMinute = startMinute;

  while (currentHour < endHour || (currentHour === endHour && currentMinute <= endMinute)) {
    const timeString = `${String(currentHour).padStart(2, '0')}:${String(currentMinute).padStart(2, '0')}`;
    intervals.push(timeString);

    currentMinute += 30;
    if (currentMinute >= 60) {
      currentMinute -= 60;
      currentHour += 1;
    }
  }

  return intervals;
};
