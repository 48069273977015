import { atom } from 'jotai/index';
import { atomWithReset } from 'jotai/utils';

export type HomeCareListQueryType = {
  keyword?: string;
  skip: number;
  limit: number;
  isRemoved: boolean;
};

export const homeCareListQueryKeyState = atomWithReset<HomeCareListQueryType>({
  keyword: undefined,
  skip: 0,
  limit: 50,
  isRemoved: false,
});

export const homeCareListKeywordAtom = atom(
  (get) => get(homeCareListQueryKeyState).keyword,
  (get, set, newKeyword?: string) => {
    const currentState = get(homeCareListQueryKeyState);
    set(homeCareListQueryKeyState, {
      ...currentState,
      keyword: newKeyword || undefined,
      skip: 0,
    });
  },
);

export type HomeCareListQueryKeyAtomType = typeof homeCareListQueryKeyState;
