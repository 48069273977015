export const extractFileExtention = (url: string): string | null => {
  try {
    const urlObj = new URL(url);
    const decodedPath = decodeURIComponent(urlObj.pathname);

    const fileName = decodedPath.split('/').pop();
    if (!fileName) return null;

    const extention = fileName.split('.').pop() ?? null;
    return extention;
  } catch (error) {
    console.error('Invalid URL:', error);
    return null;
  }
};
