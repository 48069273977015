import type { UserNameAndType } from '@apis/swaggers/swagger-docs';

const PARTICIPANTS_DISPLAY_LIMIT = 4;

export const displayParticipantsParticipantsChip = (
  onlyUsersNotMedipaler: UserNameAndType[] | undefined,
) => {
  if (!onlyUsersNotMedipaler || onlyUsersNotMedipaler.length === 0) return '담당자 없음';
  const length = onlyUsersNotMedipaler.length;

  if (length === 1) return onlyUsersNotMedipaler[0].realName;

  if (onlyUsersNotMedipaler[0].realName.length > PARTICIPANTS_DISPLAY_LIMIT) {
    return `${onlyUsersNotMedipaler[0].realName.slice(0, PARTICIPANTS_DISPLAY_LIMIT)}... 외 ${length - 1}명`;
  }
  return `${onlyUsersNotMedipaler[0].realName} 외 ${length - 1}명`;
};
