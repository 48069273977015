import type { KakaoConsultationTalkLinkageHandlerBodyRequest } from '@apis/swaggers/swagger-docs';
import { hasLinkageOrRequestHistoryState } from '@templates/HospitalSetting/containers/ChannelIntegration/states/has-linkage-or-request-history-state';
import { toBeContactedPhoneErrorAtom } from '@templates/HospitalSetting/containers/ChannelIntegration/states/kakao-consult-form-error-state';
import TextInput from 'afterdoc-design-system/components/Atoms/Input/TextInput';
import { useAtom } from 'jotai';
import { useAtomValue } from 'jotai/index';
import type React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

export default function PhoneInput() {
  const { register, setValue } = useFormContext<KakaoConsultationTalkLinkageHandlerBodyRequest>();

  const hasLinkageOrRequestHistory = useAtomValue(hasLinkageOrRequestHistoryState);

  const [toBeContactedPhoneError, setToBeContactedPhoneError] = useAtom(
    toBeContactedPhoneErrorAtom,
  );

  const inputValue = useWatch({ name: 'toBeContactedPhone' }) ?? '';

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setToBeContactedPhoneError(false);

    setValue('toBeContactedPhone', e.target.value.replace(/[^0-9]/g, ''));
  };

  return (
    <TextInput
      label={{
        isRequired: true,
        children: '연락처',
        width: 140,
        position: 'horizontal',
      }}
      maxLength={11}
      width={290}
      hasError={toBeContactedPhoneError}
      disabled={hasLinkageOrRequestHistory !== 'NotLinked'}
      errorTextPosition={'vertical'}
      value={inputValue}
      placeholder='연락받으실 휴대폰번호'
      errorText='연락받으실 분의 휴대폰번호를 입력해 주세요.'
      {...register('toBeContactedPhone', { onChange: handleChange })}
    />
  );
}
