import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { useChattingList } from 'web/templates/CustomerChat/components/ChattingList/hooks/use-chatting-list';
import {
  chatRoomParentHServiceIDSelector,
  patientIDInChattingListUserIDSelector,
} from 'web/templates/CustomerChat/states/selected-chat-room';

export const useChatRoomPatientUnreadCount = () => {
  const { chatRooms } = useChattingList();
  const chatRoomParentHServiceID = useAtomValue(chatRoomParentHServiceIDSelector);
  const selectedPatientIDInChattingList = useAtomValue(patientIDInChattingListUserIDSelector);

  const chatRoomPatientUnreadCount = useMemo(() => {
    const { info: infos } = chatRooms.find(
      ({ parentHServiceID }) => parentHServiceID?._id === chatRoomParentHServiceID,
    ) ?? { info: [] };

    const patientInfo = infos.find((info) => info.userID === selectedPatientIDInChattingList);

    return patientInfo?.count ?? 0;
  }, [chatRooms, selectedPatientIDInChattingList, chatRoomParentHServiceID]);

  return chatRoomPatientUnreadCount;
};
