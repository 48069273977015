import { toastService } from '@afterdoc-design-system/components/Atoms/Toast/Toast.service';

export const showToastMessage = (isSuccess: boolean) => {
  if (isSuccess) {
    toastService.successMsg({ text: '담당자 추가를 성공했습니다.' });
  } else {
    toastService.errorMsg({ text: '담당자 추가를 실패했습니다.' });
  }
};

export const handleError = (error: unknown) => {
  console.error('Error:', error);
  toastService.errorMsg({ text: '담당자 추가를 실패했습니다.' });
};
