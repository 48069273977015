import TextButton from '@afterdoc-design-system/components/Atoms/Button/TextButton';
import { dialogService } from '@afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import EditorViewer from 'web/shared/components/EditorViewer/EditorViewer';
import { useChatMessageContext } from '../ChatMessageContext';
import { makeChattingInfoStringDisplay } from '../functions/makeChattingInfoStringDisplay';
import { MessageDialogContent } from './components/MessageDialogContent';
import MessageTitle from './components/MessageTitle';

export default function NoticeMessage() {
  const { isMine, messageWidthStyle, hnoticeID } = useChatMessageContext();
  if (!hnoticeID) return null;
  const { title: noticeTitle, content: noticeContent, createdAt } = hnoticeID;

  const displayCreated = makeChattingInfoStringDisplay({
    type: 'notiCreated',
    payload: { timestamp: createdAt },
  });

  const handleModalOpen = () => {
    dialogService.push(
      <MessageDialogContent displayCreated={displayCreated} title={noticeTitle ?? ''}>
        <div className='w-full'>
          <EditorViewer htmlContent={noticeContent ?? ''} />
        </div>
      </MessageDialogContent>,
      {
        hasCloseButton: true,
        titleProps: {
          title: '공지사항 미리보기',
        },
      },
    );
  };

  return (
    <div
      className={`rounded-r10 px-16 py-10 shadow-modal ${isMine ? 'bg-[rgba(255,245,208,1)]' : 'bg-white50'}`}
      style={messageWidthStyle}>
      <MessageTitle title='공지사항' />
      <div className='mb-6 text-Body13 text-black500'>{noticeTitle}</div>
      <div className='mb-10 text-Body12 text-black200'>{displayCreated}</div>
      <TextButton
        className='w-full flex-center rounded-r10 border border-black200 bg-white50'
        onClick={handleModalOpen}>
        <div className=' text-Body12 text-black200'>자세히보기</div>
      </TextButton>
    </div>
  );
}
