import { atom } from 'jotai/index';
import { atomWithReset } from 'jotai/utils';

type PopupListQueryType = {
  keyword?: string;
  skip: number;
  limit: number;
  isRemoved: boolean;
  key: string;
  way: number;
};

export const popupListQueryKeyState = atomWithReset<PopupListQueryType>({
  keyword: undefined,
  skip: 0,
  limit: 50,
  isRemoved: false,
  key: 'index',
  way: -1,
});

export const popupListKeywordAtom = atom(
  (get) => get(popupListQueryKeyState).keyword,
  (get, set, newKeyword?: string) => {
    const currentState = get(popupListQueryKeyState);
    set(popupListQueryKeyState, {
      ...currentState,
      keyword: newKeyword || undefined,
      skip: 0,
    });
  },
);

export type PopupListQueryKeyAtomType = typeof popupListQueryKeyState;
