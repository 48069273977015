import type { Hospital } from '@apis/swaggers/swagger-docs';
import ConsultationScheduleNote from '@templates/HospitalSetting/containers/HospitalProfile/components/OperatingHours/components/ConsultationScheduleNote';
import WeeklyOperatingHour from '@templates/HospitalSetting/containers/HospitalProfile/components/OperatingHours/components/WeeklyOperatingHour';
import { hospitalInfoState } from '@templates/HospitalSetting/containers/HospitalProfile/states/hospital-info-state';
import { createErrorMessageAtom } from '@templates/HospitalSetting/states/hospital-info-input-error-messages-state';
import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import LabelText from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import Title from 'afterdoc-design-system/components/Molecules/Title/Title';
import { useAtom, useAtomValue } from 'jotai';
import { selectAtom } from 'jotai/utils';
import { useEffect } from 'react';

const TITLE = '병원운영시간';
const SUB_TITLE = '고객이 내원할 때 참고할 수 있도록 진료시간을 설정해 주세요.';

type CareSchedule = Exclude<Hospital['careSchedule'], undefined>[number];

export type NonNullableCareSchedule = Required<CareSchedule>;

const isNonNullableCareSchedule = (obj: unknown): obj is NonNullableCareSchedule => {
  if (typeof obj !== 'object' || obj === null) {
    return false;
  }

  const potentialCareSchedule = obj as Partial<NonNullableCareSchedule>;
  return (
    typeof potentialCareSchedule.dayIndex === 'number' &&
    typeof potentialCareSchedule.startedAt === 'string' &&
    typeof potentialCareSchedule.endedAt === 'string' &&
    typeof potentialCareSchedule.isActive === 'boolean'
  );
};

// 변환 함수
const convertToCareSchedule = (data: unknown[]): NonNullableCareSchedule[] => {
  const convertedData = data.filter(isNonNullableCareSchedule);

  convertedData.sort((l, r) => {
    if (l.dayIndex === 0) return 1;
    if (r.dayIndex === 0) return -1;
    return l.dayIndex - r.dayIndex;
  });

  return convertedData;
};

const hospitalCareScheduleAtom = selectAtom(hospitalInfoState, (hospital) => hospital.careSchedule);

const operatingHoursErrorAtom = createErrorMessageAtom('operatingHours');

export default function OperatingHours() {
  const [operatingHoursError, setOperatingHoursError] = useAtom(operatingHoursErrorAtom);

  const hospitalCareSchedule = useAtomValue(hospitalCareScheduleAtom);

  const careSchedule = convertToCareSchedule(hospitalCareSchedule || []);

  useEffect(() => {
    if (operatingHoursError !== null) {
      setOperatingHoursError(null);
    }
  }, [hospitalCareSchedule]);

  return (
    <>
      <div className='px-80 pt-20 pb-40'>
        <Title title={TITLE} subTitle={SUB_TITLE} />
        <div className='flex flex-col gap-10 px-20'>
          <div className='mt-10 flex-auto pt-10 pb-10'>
            <div className='flex w-full justify-between gap-40'>
              <div>
                <LabelText isRequired={true}>운영시간 설정</LabelText>
                {careSchedule.map((schedule) => (
                  <WeeklyOperatingHour key={schedule.dayIndex} data={schedule} />
                ))}
              </div>
              <ConsultationScheduleNote />
            </div>
          </div>
          {operatingHoursError && (
            <span className='text-Body10 text-red500'>{operatingHoursError}</span>
          )}
        </div>
      </div>
      <Divider type='plane' height={10} />
    </>
  );
}
