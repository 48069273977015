import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';
import type { Editor } from '@toast-ui/react-editor';
import type { RefObject } from 'react';
import { useImageCompression } from 'web/shared/hooks/files/images/use-image-compression';

interface CustomToolbarProps {
  editorRef: RefObject<Editor>;
  disabled?: boolean;
  onUndo: () => void;
  onRedo: () => void;
}

export default function CustomToolbar({ editorRef, disabled, onUndo, onRedo }: CustomToolbarProps) {
  const { compressImage } = useImageCompression();

  const handleToolbarClick = async (command: string, value?: string) => {
    const instance = editorRef.current?.getInstance();
    if (!instance || disabled) return;

    if (command === 'undo') {
      onUndo();
      return;
    }

    if (command === 'redo') {
      onRedo();
      return;
    }

    if (command === 'heading') {
      instance.exec('heading', { level: Number(value) });
      return;
    }

    if (command === 'addImageBlobHook') {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*';
      input.onchange = async (e) => {
        const file = (e.target as HTMLInputElement).files?.[0];
        if (file) {
          try {
            const { compressedDataUrl } = await compressImage(file);

            instance.exec('addImage', {
              imageUrl: compressedDataUrl,
              altText: file.name,
            });
          } catch (error) {
            console.error('이미지 처리 실패:', error);
          }
        }
      };
      input.click();
      return;
    }

    instance.exec(command);
  };

  return (
    <div className='editor-toolbar'>
      <button
        type='button'
        onClick={() => handleToolbarClick('undo')}
        disabled={disabled}
        className='toolbar-button'>
        <Icon name='undo' size={20} color='white800' />
      </button>
      <button
        type='button'
        onClick={() => handleToolbarClick('redo')}
        disabled={disabled}
        className='toolbar-button'>
        <Icon name='redo' size={20} color='white800' />
      </button>
      <button
        type='button'
        onClick={() => handleToolbarClick('bold')}
        disabled={disabled}
        className='toolbar-button'>
        <Icon name='bold' size={20} color='white800' />
      </button>
      <button
        type='button'
        onClick={() => handleToolbarClick('italic')}
        disabled={disabled}
        className='toolbar-button'>
        <Icon name='italic' size={20} color='white800' />
      </button>
      <button
        type='button'
        onClick={() => handleToolbarClick('strike')}
        disabled={disabled}
        className='toolbar-button'>
        <Icon name='strikethrough' size={20} color='white800' />
      </button>
      <button
        type='button'
        onClick={() => handleToolbarClick('bulletList')}
        disabled={disabled}
        className='toolbar-button'>
        <Icon name='list-bulleted' size={20} color='white800' />
      </button>
      <button
        type='button'
        onClick={() => handleToolbarClick('orderedList')}
        disabled={disabled}
        className='toolbar-button'>
        <Icon name='list-numbers' size={20} color='white800' />
      </button>
      <button
        type='button'
        onClick={() => handleToolbarClick('heading', '1')}
        disabled={disabled}
        className='toolbar-button'>
        <Icon name='h1' size={20} color='white800' />
      </button>
      <button
        type='button'
        onClick={() => handleToolbarClick('heading', '2')}
        disabled={disabled}
        className='toolbar-button'>
        <Icon name='h2' size={20} color='white800' />
      </button>
      <button
        type='button'
        onClick={() => handleToolbarClick('heading', '3')}
        disabled={disabled}
        className='toolbar-button'>
        <Icon name='h3' size={20} color='white800' />
      </button>
      <button
        type='button'
        onClick={() => handleToolbarClick('addImageBlobHook')}
        disabled={disabled}
        className='toolbar-button'>
        <Icon name='image-plus' size={20} color='white800' />
      </button>
      <button
        type='button'
        onClick={() => handleToolbarClick('blockQuote')}
        disabled={disabled}
        className='toolbar-button'>
        <Icon name='quote' size={20} color='white800' />
      </button>
      <button
        type='button'
        onClick={() => handleToolbarClick('hr')}
        disabled={disabled}
        className='toolbar-button'>
        <Icon name='hr' size={20} color='white800' />
      </button>
    </div>
  );
}
