import { selectedContentEditTitleErrorAtom } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/states/content-edit-errors-state';
import { contentEditState } from '@templates/Content/containers/ContentManager/states/content-edit-state';
import TextInput from 'afterdoc-design-system/components/Atoms/Input/TextInput';
import LabelText from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import { useAtom, useAtomValue } from 'jotai';
import { type ChangeEvent, memo } from 'react';

interface EventHomeCareTitleInputProps {
  title: string;
  onHandleValue: (value: string) => void;
}

interface LabelTextConfig {
  placeHolder: string;
}

const labelTextConfig: Record<string, LabelTextConfig> = {
  event: {
    placeHolder: '제목을 입력해 주세요.',
  },
  homeCare: {
    placeHolder: '제목을 입력해 주세요.',
  },
};

const EventHomeCareTitleInput = memo(({ title, onHandleValue }: EventHomeCareTitleInputProps) => {
  const [titleInputError, setTitleInputError] = useAtom(selectedContentEditTitleErrorAtom);
  const {
    data: { editType = undefined } = {},
  } = useAtomValue(contentEditState);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onHandleValue(e.target.value);
    setTitleInputError(undefined);
  };

  const placeHolder = (editType && labelTextConfig[editType].placeHolder) || '';

  return (
    <div className='flex items-center gap-28'>
      <LabelText width={72} isRequired={true} className='flex h-32 items-center'>
        제목
      </LabelText>
      <TextInput
        className='h-full flex-grow'
        onChange={handleChange}
        maxLength={100}
        placeholder={placeHolder}
        value={title}
        errorText={titleInputError}
        hasError={titleInputError !== null}
      />
    </div>
  );
});

export default EventHomeCareTitleInput;
