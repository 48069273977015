import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import type {
  ApiEventHospitalAftercareElFindOneParams,
  HospitalAftercareFindSimple,
} from '@apis/swaggers/swagger-docs';
import { useUserInfo } from '@shared/hooks/use-user-info';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import HomeCareActionPanel from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/HomeCare/components/HomeCareList/components/HomeCareListBody/components/HomeCareActionPanel';
import { HOME_CARE_LIST_COLUMNS } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/constants/event-home-care-list-columns';
import EventHomeCareColumns from '@templates/Content/containers/ContentManager/containers/EventHomeCare/shared/components/EventHomeCareList/EventHomeCareColumns';
import { contentEditState } from '@templates/Content/containers/ContentManager/states/content-edit-state';
import IconButton from 'afterdoc-design-system/components/Atoms/Button/IconButton';
import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import { fullDimmedLoadingService } from 'afterdoc-design-system/components/Atoms/Loading/FullDimmedLoading/FullDimmedLoading.service';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { useSetAtom } from 'jotai/index';
import React, { useEffect } from 'react';
import { SHARED_UTILS } from 'utils/utils';

interface EventListItemProps {
  data: HospitalAftercareFindSimple;
}

type UpdateHomeCareApiParams = Parameters<typeof apiClient.v3.apiEventHospitalAftercareElUpdate>[0];

const fetchHospitalHomeCareDetail = async (params: ApiEventHospitalAftercareElFindOneParams) => {
  const response = await apiClient.v3.apiEventHospitalAftercareElFindOne(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const updateHospitalHomeCare = async (params: UpdateHomeCareApiParams) => {
  const response = await apiClient.v3.apiEventHospitalAftercareElUpdate(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const generateMessage = (
  keys: UpdateHomeCareApiParams,
  key: keyof HospitalAftercareFindSimple,
  success: boolean,
) => {
  if (key === 'isActive') {
    return keys.isActive
      ? success
        ? '활성화 했습니다'
        : '활성화를 실패했습니다'
      : success
        ? '비활성화 했습니다'
        : '비활성화를 실패했습니다';
  }
  if (key === 'isPinned') {
    return keys.isPinned
      ? success
        ? '상단고정 했습니다.'
        : '상단고정을 실패했습니다.'
      : success
        ? '상단고정 해제되었습니다.'
        : '상단고정 해제를 실패했습니다.';
  }
  return '';
};

export default function HomeCareListItem({ data }: EventListItemProps) {
  const queryClient = useQueryClient();
  const setContentEdit = useSetAtom(contentEditState);

  const { authorizationTypeID } = useUserInfo();

  const {
    _id: homeCareID = '',
    isPinned = false,
    isActive = false,
    representImage,
    title = '',
    price = 0,
    salePrice = 0,
    saleRate = 0,
    saleType = 'price',
    isLimitedTime = false,
    startDate = '',
    endDate = '',
    viewCount = 0,
    purchaseChatCount = 0,
  } = data;

  const handleSuccess = (keys: UpdateHomeCareApiParams, key: keyof HospitalAftercareFindSimple) => {
    queryClient.invalidateQueries({ queryKey: [QUERY_KEY.apiEventHospitalAftercareElCount] });
    queryClient.invalidateQueries({ queryKey: [QUERY_KEY.apiEventHospitalAftercareElFindSimple] });
    const message = generateMessage(keys, key, true);
    toastService.successMsg({ text: message });
  };

  const handleFailure = (
    error: Error,
    keys: UpdateHomeCareApiParams,
    key: keyof HospitalAftercareFindSimple,
  ) => {
    console.error('Error updating hospital event infos', error);
    const message = generateMessage(keys, key, false);
    toastService.errorMsg({ text: message });
  };

  const updateHomeCareMutation = useMutation({
    mutationFn: updateHospitalHomeCare,
    onMutate: () => fullDimmedLoadingService.on(),
    onSettled: () => fullDimmedLoadingService.off(),
  });

  const handleChange = <K extends keyof HospitalAftercareFindSimple>(
    key: K,
    value: HospitalAftercareFindSimple[K],
  ) => {
    const updateValue = {
      ...(key === 'isActive' && value === false ? { isPinned: false } : {}),
      ...(key === 'isPinned' && value === true ? { isActive: true } : {}),
    };
    updateHomeCareMutation.mutate(
      { _id: homeCareID, [key]: value, ...updateValue },
      {
        onSuccess: (_, variables) => handleSuccess(variables, key),
        onError: (error, variables) => handleFailure(error, variables, key),
      },
    );
  };

  const handleItemClick = async () => {
    if (homeCareID) {
      fullDimmedLoadingService.on();
      try {
        const data = await fetchHospitalHomeCareDetail({ _id: homeCareID });
        setContentEdit({
          isEditing: true,
          id: homeCareID,
          data: { ...data, editType: 'homeCare' },
        });
      } catch (error) {
        console.error('Error fetching hospital homeCare details', error);
      } finally {
        fullDimmedLoadingService.off();
      }
    }
  };

  const componentMap = EventHomeCareColumns<HospitalAftercareFindSimple>({
    isPinned,
    isActive,
    representImage: representImage?.paths?.[0] ?? '',
    title,
    price,
    salePrice,
    saleRate,
    saleType,
    isLimitedTime,
    startDate,
    endDate,
    viewCount,
    chatCount: purchaseChatCount,
    handleChange,
  });

  useEffect(() => {
    return () => fullDimmedLoadingService.off();
  }, []);

  return (
    <>
      <div
        className={`flex w-full flex-col px-12 ${authorizationTypeID?.canUpsertContent ? 'cursor-pointer ' : ''}${isActive ? 'bg-white50' : 'bg-white100'}`}>
        <div className='flex h-60 px-12 pt-10'>
          {HOME_CARE_LIST_COLUMNS.map(({ title, width, key, className }) => (
            <div
              key={title}
              style={{ width: `${width}px` }}
              className={`flex-shrink-0 ${className}`}
              onClick={() => {
                if (authorizationTypeID?.canUpsertContent) {
                  handleItemClick();
                }
              }}>
              {componentMap[key]}
            </div>
          ))}
          <div className='flex flex-grow items-center justify-end'>
            <HomeCareActionPanel homeCareID={homeCareID} />
            {!!authorizationTypeID?.canUpsertContent && (
              <IconButton
                icon='chevron-right'
                size={24}
                color={'black200'}
                className='ml-4'
                onClick={handleItemClick}
              />
            )}
          </div>
        </div>
        <Divider type={'line'} className='mt-9' />
      </div>
    </>
  );
}
