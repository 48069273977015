import TextInput from '@afterdoc-design-system/components/Atoms/Input/TextInput';
import LabelText from '@afterdoc-design-system/components/Atoms/LabelText/LabelText';

interface AlgorithmNameSettingProps {
  value: string;
  onChange: (value: string) => void;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
}

export default function AlgorithmNameSetting({
  value,
  onChange,
  inputProps,
}: AlgorithmNameSettingProps) {
  return (
    <>
      <div className='flex w-full items-center justify-between'>
        <LabelText width='fit-content' isRequired>
          자동화명
        </LabelText>
        <span className='text-Body10 text-black200'>
          관리를 위해 구분하기 쉬운 명칭으로 설정해 주세요.
        </span>
      </div>
      <TextInput
        id='name'
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder='예) 다이어트'
        className='mt-10'
        maxLength={50}
        {...inputProps}
      />
    </>
  );
}
