import { atom } from 'jotai';
import { atomWithReset, selectAtom } from 'jotai/utils';
import type { MessageInput } from 'web/apis/swaggers/swagger-docs';
import type { COUNSEL_MESSAGE_SETTING_TAB_ITEMS } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/constants/tab';

export const selectedMessageState = atomWithReset<
  | (MessageInput & {
      id: string;
      index: number;
      hasApprovedAlimtalkTemplate?: boolean;
      rejectedReasonForAlimtalkTemplate?: string;
    })
  | undefined
>(undefined);

export const selectedMessageIdState = selectAtom(selectedMessageState, (state) => state?.id ?? '');

export const selectedMessageIndexState = selectAtom(
  selectedMessageState,
  (state) => state?.index ?? -1,
);
export const selectedContentTabItemState = atomWithReset<
  Record<string, (typeof COUNSEL_MESSAGE_SETTING_TAB_ITEMS)[number]>
>({});
export const sendTypeState = atom<MessageInput['sendingType']>('BASIC');
