import type { HospitalNoticePopupSimple } from '@apis/swaggers/swagger-docs';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import PopupActionPanel from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/components/PopupList/PopupListBody/components/PopupActionPanel';
import { useHospitalPopup } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/hooks/use-hospital-popup';
import { POPUP_LIST_COLUMNS } from '@templates/Content/containers/ContentManager/containers/NoticePopup/constants/notice-popup-list-columns';
import { contentEditState } from '@templates/Content/containers/ContentManager/states/content-edit-state';
import IconButton from 'afterdoc-design-system/components/Atoms/Button/IconButton';
import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import dayjs from 'dayjs';
import { useSetAtom } from 'jotai/index';
import { useMemo, useState } from 'react';

interface PopupListItemProps {
  data: HospitalNoticePopupSimple;
}

export default function PopupListItem({ data }: PopupListItemProps) {
  const { detailPopupAction } = useHospitalPopup();

  const setContentEdit = useSetAtom(contentEditState);

  const [bgColor, setBgColor] = useState('white');

  const { _id: popupID, index, name, startDate, endDate, viewCount } = data;

  const handleAction = (isFocus: boolean) => {
    setBgColor(isFocus ? 'bg-blue50' : 'white');
  };

  const handleItemClick = () => {
    if (!popupID) return;

    detailPopupAction(popupID, (data) => {
      setContentEdit({ isEditing: true, id: popupID, data: { ...data, editType: 'popup' } });
    });
  };

  const formattedDate = useMemo(() => {
    if (startDate && dayjs(startDate).isValid() && endDate && dayjs(endDate).isValid()) {
      const start = dayjs(startDate).format('YYYY-MM-DD');
      const end = dayjs(endDate).format('YYYY-MM-DD');
      return `${start} ~ ${end}`;
    }
    return '-';
  }, [startDate, endDate]);

  const isCurrentlyActive = useMemo(() => {
    const today = dayjs();
    const start = dayjs(startDate);
    const end = dayjs(endDate);
    return (
      start.isValid() &&
      end.isValid() &&
      (today.isAfter(start, 'day') || today.isSame(start, 'day')) &&
      (today.isBefore(end, 'day') || today.isSame(end, 'day'))
    );
  }, [startDate, endDate]);

  return (
    <>
      <div className={`flex w-full cursor-pointer flex-col hover:bg-blueLight ${bgColor}`}>
        <div className='flex h-50 items-center px-20'>
          {POPUP_LIST_COLUMNS.map(({ key, className: columnClassName, width }) => (
            <div
              key={key}
              className={customTwMerge(
                'flex h-50 items-center px-12 text-Body14 text-black600',
                columnClassName,
              )}
              onClick={handleItemClick}
              style={{ width: `${width}px` }}>
              {key === 'index' && <span>{index?.toLocaleString() ?? 0}</span>}
              {key === 'title' && (
                <div className='flex gap-12'>
                  {isCurrentlyActive && (
                    <span className='h-18 w-50 flex-center rounded-r6 bg-blue300 px-8 text-Body12 text-white50'>
                      사용중
                    </span>
                  )}
                  <span className='flex-grow truncate'>{name}</span>
                </div>
              )}
              {key === 'date' && <span>{formattedDate}</span>}
              {key === 'count' && <span>{viewCount?.toLocaleString() ?? 0}</span>}
            </div>
          ))}
          <div className='flex flex-grow items-center justify-end'>
            <PopupActionPanel id={popupID ?? ''} handleAction={handleAction} />
            <IconButton
              icon='chevron-right'
              size={24}
              className='ml-4'
              color={'black200'}
              onClick={handleItemClick}
            />
          </div>
        </div>
        <Divider type={'line'} />
      </div>
    </>
  );
}
