import * as React from "react";
import type { SVGProps } from "react";
const SvgRedo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#7F7F7F"
      d="M15.333 8.833c-1.542-1.333-3.542-2.167-5.75-2.167-3.875 0-7.15 2.525-8.3 6.017l1.967.65A6.67 6.67 0 0 1 9.583 8.75c1.625 0 3.108.6 4.267 1.566l-3.017 3.017h7.5v-7.5z"
    />
  </svg>
);
export default SvgRedo;
