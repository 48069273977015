import { treatmentTagsState } from '@templates/HospitalSetting/containers/ServiceSetting/containers/TreatmentTag/states/hospital-treatment-tags';
import { showColorPickerIndexState } from '@templates/HospitalSetting/containers/ServiceSetting/containers/TreatmentTag/states/show-color-picker-index';
import { useSetAtom } from 'jotai/index';

interface ColorItemProps {
  value: string;
  savedColor: string;
  id: string;
}

export default function ColorItem({ value, savedColor, id }: ColorItemProps) {
  const setTreatmentTags = useSetAtom(treatmentTagsState);
  const setShowColorPickerIndex = useSetAtom(showColorPickerIndexState);

  const handleSelectColor = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    setShowColorPickerIndex(null);
    setTreatmentTags((prev) =>
      prev.map((tag) => (tag.tagId === id ? { ...tag, color: value } : tag)),
    );
  };

  return (
    <div className='h-24 w-24 flex-center'>
      <div
        className={`h-18 w-18 cursor-pointer rounded-r16 ${value === savedColor ? 'border-2 border-blue300' : ''}`}
        style={{ backgroundColor: value }}
        onClick={handleSelectColor}
      />
    </div>
  );
}
