import Icon, { type IconType } from 'afterdoc-design-system/components/Foundations/Icon/Icon';

interface AuthorizationListTitle {
  icon: IconType;
  name?: string;
}

export default function AuthorizationListTitle({ icon, name }: AuthorizationListTitle) {
  return (
    <div className='flex h-[30px] w-[140px] items-center'>
      <div className='flex items-center gap-8'>
        <Icon name={icon} size={24} color={'black100'} />
        <span className='text-Header14 text-black500'>{name ?? ''}</span>
      </div>
    </div>
  );
}
