import { funcSaveButtonState } from '@templates/HospitalSetting/states/save-button-disabled-state';
import DownloadFooter from 'afterdoc-design-system/components/Molecules/DownloadFooter/DownloadFooter';
import { useAtomValue } from 'jotai';
import React from 'react';

export default function HospitalSettingSaveFooter() {
  const saveFunction = useAtomValue(funcSaveButtonState);

  return (
    <>
      <DownloadFooter
        saveButton='저장'
        className='sticky right-0 bottom-0'
        onSave={saveFunction.invoke}
      />
    </>
  );
}
