import type { Hospital } from '@apis/swaggers/swagger-docs';
import MessageInput from '@templates/HospitalSetting/containers/CustomerMessageSend/containers/components/MessageInput';
import MessagePreview from '@templates/HospitalSetting/containers/CustomerMessageSend/containers/components/MessagePreview';
import { hospitalInfoState } from '@templates/HospitalSetting/containers/HospitalProfile/states/hospital-info-state';
import { createErrorMessageAtom } from '@templates/HospitalSetting/states/hospital-info-input-error-messages-state';
import type { HospitalInfoErrorType } from '@templates/HospitalSetting/types/hospital-info-error-type';
import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import HoverTooltip from 'afterdoc-design-system/components/Atoms/Tooltip/HoverTooltip';
import Title from 'afterdoc-design-system/components/Molecules/Title/Title';
import { useAtomValue, useSetAtom } from 'jotai';
import { useAtom } from 'jotai/index';
import { selectAtom } from 'jotai/utils';
import { memo, useMemo } from 'react';

export interface RegistrationPromptProps {
  type: 'basic' | 'reservation' | 'resend';
  title: string;
  description: string;
}

const messageKeys: { [key in RegistrationPromptProps['type']]: keyof Hospital } = {
  basic: 'smsBasicMessage',
  reservation: 'smsReservation',
  resend: 'smsResendMessage',
};

const messageErrorKeys: { [key in RegistrationPromptProps['type']]: keyof HospitalInfoErrorType } =
  {
    basic: 'basicMessage',
    reservation: 'reservationMessage',
    resend: 'resendMessage',
  };

const getMessageByType =
  (type: RegistrationPromptProps['type']) =>
  (state: Hospital): string => {
    const message = state[messageKeys[type]];

    if (typeof message === 'string') {
      return message;
    }

    return '';
  };

const hoverTextItem = [
  {
    key: 'customerName',
    text: '{{고객명}}',
    tooltip: '고객명이 표시됩니다. 예) 홍길동',
  },
  {
    key: 'hospitalName',
    text: '{{병원명}}',
    tooltip: '병원명이 표시됩니다. 예) 애프터닥의원',
  },
  {
    key: 'downloadLink',
    text: '{{링크}}',
    tooltip: '앱 다운로드 화면으로 이동할 링크가 표시됩니다.  예) https://www.afterdoc.co.kr/',
  },
];

function RegistrationPrompt({ type, title, description }: RegistrationPromptProps) {
  const selectedMessageAtom = useMemo(
    () => selectAtom(hospitalInfoState, getMessageByType(type)),
    [type],
  );

  const selectedErrorAtom = useMemo(() => createErrorMessageAtom(messageErrorKeys[type]), [type]);

  const [selectedError, setSelectedError] = useAtom(selectedErrorAtom);

  const selectedMessage = useAtomValue(selectedMessageAtom);

  const setHospitalInfo = useSetAtom(hospitalInfoState);

  const handleInputChange = (message: string) => {
    setSelectedError(null);
    setHospitalInfo((prev) => ({
      ...prev,
      [messageKeys[type]]: message,
    }));
  };

  return (
    <>
      <div className='flex flex-col gap-10 px-80 pt-20 pb-40'>
        <div className='flex flex-col gap-20'>
          <Title title={title} subTitle={description} wrapperClassName='w-full' />
          <div className='h-[300px] w-full flex-center gap-20 px-20'>
            <MessagePreview message={selectedMessage || '내용을 입력해 주세요.'} />
            <MessageInput message={selectedMessage} onChange={handleInputChange} />
          </div>
          <div className='flex items-center gap-10 px-20 '>
            <span className='text-Header12 text-black500'>치환 문자</span>
            {hoverTextItem.map((item) => (
              <HoverTooltip key={item.key} position='topRight' text={item.tooltip}>
                <span
                  className={`h-24 flex-center rounded-r6 px-6 text-Body12 ${item.key !== 'downloadLink' ? 'bg-white200 text-black500' : 'bg-red50 text-red500'}`}>
                  {item.text}
                </span>
              </HoverTooltip>
            ))}
            <span className='text-Body11 text-black200'>{'*{{링크}}의 경우, 필수값입니다.'}</span>
          </div>
        </div>
        {selectedError && <span className='px-20 text-Body10 text-red500'>{selectedError}</span>}
      </div>
      {type !== 'resend' && <Divider type='plane' height={10} />}
    </>
  );
}
export default memo(RegistrationPrompt);
