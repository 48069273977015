import dayjs from 'dayjs';

export const formatDate = (date: string | undefined) => {
  const parsedDate = dayjs(date);

  if (!parsedDate.isValid()) {
    return '-';
  }

  return `~${parsedDate.format('YYYY-MM-DD')}`;
};
