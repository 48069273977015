import OutlinedButton from '@afterdoc-design-system/components/Atoms/Button/OutlinedButton';
import { dialogService } from '@afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import { useChatMessageContext } from '../../ChatMessageContext';
import { MessageDialogContent } from '../components/MessageDialogContent';
import TranslatedMessageBundle from './TranslatedMessageBundle';

export default function TranslateTextMessage() {
  const {
    isOverLength,
    isMine,
    chattingWidth,
    contentParts,
    translatedContent,
    retranslatedContent,
    isLeft,
  } = useChatMessageContext();
  const messageWidthStyle = {
    maxWidth: `${chattingWidth / 2}px`,
    width: 'fit-content',
  };
  const first = isLeft ? contentParts : [translatedContent ?? '번역'];
  const second = isLeft ? [translatedContent ?? '번역'] : [retranslatedContent ?? '재번역'];

  const handleModalOpen = () => {
    dialogService.push(
      <MessageDialogContent>
        <TranslatedMessageBundle first={first} second={second} isTruncate={false} />
      </MessageDialogContent>,
      {
        hasCloseButton: true,
        titleProps: {
          title: '전체보기',
        },
      },
    );
  };
  return (
    <div
      className={`flex flex-col-center gap-10 rounded-r10 px-16 py-10 shadow-modal ${isMine ? 'bg-[rgba(255,245,208,1)]' : 'bg-white50'}`}
      style={messageWidthStyle}>
      <TranslatedMessageBundle first={first} second={second} />
      {isOverLength && (
        <OutlinedButton
          className='w-full border-black200 px-20 py-6 text-black200'
          btnColor='secondary'
          onClick={handleModalOpen}>
          전체보기
        </OutlinedButton>
      )}
    </div>
  );
}
