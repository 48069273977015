import { useFormContext } from 'react-hook-form';
import type { CounselAutomationAPIFormValues } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/components/RegisterCounselAutomationDialogContent';
import AlgorithmNameSetting from 'web/templates/Automation/containers/shared/components/Settings/AlgorithmNameSetting';

export default function CounselAlgorithmNameSetting() {
  const { setValue, register, watch } = useFormContext<CounselAutomationAPIFormValues>();

  const name = watch('name');

  return (
    <AlgorithmNameSetting
      value={name}
      onChange={(value) => setValue('name', value)}
      inputProps={{
        ...register('name', { required: true }),
      }}
    />
  );
}
