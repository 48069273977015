import type { PanelListProps } from 'afterdoc-design-system/components/Atoms/PanelList/PanelList';

export const PANEL_ITEMS: PanelListProps['layers'] = [
  {
    title: '계정 설정',
    state: 'focus',
    items: [],
  },
  {
    title: '알림 설정',
    state: 'default',
    items: [],
  },
];
