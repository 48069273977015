import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import type {
  KakaoLatestLinkageHistoryHandlerData,
  KakaoLatestLinkageHistoryHandlerParams,
} from '@apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from '@shared/hooks/use-selected-hospital-info';
import { useSuspenseQuery } from '@tanstack/react-query';
import type { ServiceLinkageStatus } from '@templates/HospitalSetting/containers/ChannelIntegration/ChannelIntegration.container';
import KakaoAlimTalkForm from '@templates/HospitalSetting/containers/ChannelIntegration/components/IntegrationFormWrapper/KakaoAlimTalkForm/KakaoAlimTalkForm';
import KakaoConsultTalkForm from '@templates/HospitalSetting/containers/ChannelIntegration/components/IntegrationFormWrapper/KakaoConsultTalkForm/KakaoConsultTalkForm';
import { hasLinkageOrRequestHistoryState } from '@templates/HospitalSetting/containers/ChannelIntegration/states/has-linkage-or-request-history-state';
import type { LinkageType } from '@templates/HospitalSetting/containers/ChannelIntegration/types/linkage-type';
import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';
import dayjs from 'dayjs';
import { useSetAtom } from 'jotai/index';
import React, { useEffect, useMemo, useState } from 'react';
import { SHARED_UTILS } from 'utils/utils';

interface ChannelIntegrationInputFormProps {
  linkageType: LinkageType;
  linkageStatus: ServiceLinkageStatus;
}

export type KakaoLatestLinkageHistoryResType = KakaoLatestLinkageHistoryHandlerData['data'];

const fetchKakaoLatestLinkageHistory = async (params: KakaoLatestLinkageHistoryHandlerParams) => {
  try {
    const { data } = await apiClient.v3.kakaoLatestLinkageHistoryHandler(params);
    return SHARED_UTILS.api.checkApiResponse<KakaoLatestLinkageHistoryResType>(data);
  } catch (error) {
    if (SHARED_UTILS.api.isApiErrorWithCode(error, 404)) {
      return null;
    }
    throw error;
  }
};

const renderPage = (data: KakaoLatestLinkageHistoryResType | null, linkageType: LinkageType) => {
  switch (linkageType) {
    case 'ALIM_TALK':
      return <KakaoAlimTalkForm data={data} />;
    case 'CONSULTATION_TALK': {
      return <KakaoConsultTalkForm data={data} />;
    }
    default:
      return null;
  }
};

export default function IntegrationFormWrapper({
  linkageType,
  linkageStatus,
}: ChannelIntegrationInputFormProps) {
  const { hospitalID } = useSelectedHospitalInfo();

  const [isShowOverlay, setIsShowOverlay] = useState(false);

  const setHasLinkageOrRequestHistory = useSetAtom(hasLinkageOrRequestHistoryState);

  const [RenderFormPage, setRenderFormPage] = useState<JSX.Element | null>(null);

  const { data } = useSuspenseQuery({
    queryKey: [QUERY_KEY.kakaoLatestLinkageHistoryHandler, { hospitalID, linkageType }] as const,
    queryFn: ({ queryKey }) => fetchKakaoLatestLinkageHistory(queryKey[1]),
  });

  const hasLinkageOrRequestHistory = useMemo(() => {
    const { hasLinkage, unlinkedAt } = linkageStatus;

    if (hasLinkage) {
      return 'Linked';
    }

    if (data) {
      if (!unlinkedAt) {
        return 'LinkageRequested';
      }

      const createdDate = dayjs(data.createdAt);
      const unlinkedDate = dayjs(unlinkedAt);
      if (createdDate.isAfter(unlinkedDate)) {
        return 'LinkageRequested';
      }
    }

    return 'NotLinked';
  }, [data, linkageStatus]);

  useEffect(() => {
    setHasLinkageOrRequestHistory(hasLinkageOrRequestHistory);
    setIsShowOverlay(hasLinkageOrRequestHistory === 'NotLinked');
    setRenderFormPage(renderPage(data, linkageType));
  }, [hasLinkageOrRequestHistory, setHasLinkageOrRequestHistory, linkageType]);

  return (
    <>
      <div className='relative flex min-h-full flex-grow flex-col pl-20'>
        {RenderFormPage}
        {isShowOverlay && (
          <KakaoLinkageOverlay linkageType={linkageType} onClick={() => setIsShowOverlay(false)} />
        )}
      </div>
    </>
  );
}

const KakaoLinkageOverlay = ({
  linkageType,
  onClick,
}: { linkageType: LinkageType; onClick: () => void }) => {
  const channel = linkageType === 'CONSULTATION_TALK' ? '상담톡' : '알림톡';

  return (
    <div className='absolute top-0 right-[-80px] bottom-0 left-[-9px] z-10 flex flex-col items-center justify-center rounded-r16 bg-white/80 backdrop-blur-[10px]'>
      <>
        <span className='text-Header14 text-black500'>{`카카오 ${channel} (유료)`}</span>
        <span className='mt-12 whitespace-pre-wrap text-center text-Body12 text-black500'>
          {channel} 연동은 카카오{' '}
          <span className='text-red500'>비즈니스 채널 승인 완료 후 신청</span>할 수 있습니다.
          <br />
          신청을 원하시면 아래 버튼을 클릭해 주세요.
        </span>
        <ContainedButton btnColor='blue' className='mt-20' onClick={onClick}>
          연동신청
        </ContainedButton>
      </>
    </div>
  );
};
