import type { AuthorizationTypeForV3 } from '@apis/swaggers/swagger-docs';
import { selectAuthorizationTypeIndex } from '@templates/HospitalSetting/containers/AuthorizationSetting/jotai/select-authorization-type-index';
import { atom } from 'jotai/index';

export const hospitalAuthorizationTypesState = atom<AuthorizationTypeForV3[]>([]);

export const hospitalInitialAuthorizationTypesState = atom<AuthorizationTypeForV3[]>([]);

export const selectedAuthorizationTypeState = atom(
  (get) => {
    const data = get(hospitalAuthorizationTypesState);
    const selectedIndex = get(selectAuthorizationTypeIndex);
    return data[selectedIndex];
  },
  (get, set, update: AuthorizationTypeForV3) => {
    const data = get(hospitalAuthorizationTypesState);
    const selectedIndex = get(selectAuthorizationTypeIndex);
    const newData = [...data];
    newData[selectedIndex] = update;
    set(hospitalAuthorizationTypesState, newData);
  },
);

export const selectedInitialAuthorizationTypeState = atom(
  (get) => {
    const data = get(hospitalInitialAuthorizationTypesState);
    const selectedIndex = get(selectAuthorizationTypeIndex);
    return data[selectedIndex];
  },
  (get, set, update: AuthorizationTypeForV3) => {
    const data = get(hospitalInitialAuthorizationTypesState);
    const selectedIndex = get(selectAuthorizationTypeIndex);
    const newData = [...data];
    newData[selectedIndex] = update;
    set(hospitalInitialAuthorizationTypesState, newData);
  },
);
