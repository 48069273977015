import { useChatMessageContext } from '../ChatMessageContext';
import TextContentMessage from './TextContentMessage/TextContentMessage';
import ListCard from './components/EventListCard';
import MessageTitle from './components/MessageTitle';

export default function EventMessage() {
  const { content, heventID, isMine, messageWidthStyle } = useChatMessageContext();
  if (heventID === null) return null; //TODO - 상혁님의 방어로직 필요
  if (heventID === undefined) return null;
  const representImage = heventID?.representImage;
  const paths = representImage?.paths;
  const url = paths ? paths[0] : '';

  const { title, price, saleRate, salePrice } = heventID;
  if (
    title === undefined ||
    price === undefined ||
    saleRate === undefined ||
    salePrice === undefined
  )
    return null;
  const priceAfterSale = price - salePrice;
  return (
    <div className='flex flex-col items-end gap-10'>
      {content && <TextContentMessage />}
      <div
        className={`rounded-r10 px-16 py-10 shadow-modal ${isMine ? 'bg-[rgba(255,245,208,1)]' : 'bg-white50'}`}
        style={messageWidthStyle}>
        <MessageTitle title='이벤트' />
        <ListCard
          url={url}
          description={title}
          originalPrice={price}
          eventPrice={priceAfterSale}
          percentage={saleRate}
          isEvent={true}
        />
      </div>
    </div>
  );
}
