import * as React from "react";
import type { SVGProps } from "react";
const SvgAfterdoc = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 19 19"
    {...props}
  >
    <rect width={18} height={18} x={0.5} y={0.5} fill="#E6F2FF" rx={5.5} />
    <rect width={18} height={18} x={0.5} y={0.5} stroke="#fff" rx={5.5} />
    <path
      fill="#007AFF"
      d="M13.863 3H5.137a.65.65 0 0 0-.637.657v9.12c0 .364.286.658.637.658h8.726a.65.65 0 0 0 .637-.657v-9.12A.65.65 0 0 0 13.863 3m-2.061 6.003h-1.54v1.588H8.738V9.003h-1.54V7.432h1.54V5.844h1.524v1.588h1.54z"
    />
    <path
      fill="#96E6FD"
      d="M13.863 3.26 7.836 5.522c-.352.132-.638.533-.638.897v1.013h1.54V5.844h1.524v1.588h1.54v1.571h-1.54v1.588H8.738V9.003h-1.54v6.537c0 .362.286.55.638.417l6.027-2.262c.351-.131.637-.533.637-.897v-9.12c0-.362-.286-.55-.637-.417z"
    />
    <path
      fill="#0AF"
      d="M13.863 3.26 7.836 5.522c-.352.132-.638.533-.638.897v7.016h6.665a.65.65 0 0 0 .637-.657v-9.1c0-.362-.286-.55-.637-.417zm-2.061 5.743h-1.54v1.588H8.738V9.003h-1.54V7.432h1.54V5.844h1.524v1.588h1.54z"
    />
  </svg>
);
export default SvgAfterdoc;
