import AddAccountRequestDialog from '@templates/HospitalSetting/containers/AllAccountSetting/containers/AddAccount/components/AddAccountRequestDialog/AddAccountRequestDialog';
import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';
import { dialogService } from 'afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';

interface AccountUpgradePromptProps {
  accountCountTuningRequestedAt?: string;
  finalAccountCountTunedAt?: string;
}
export default function AccountUpgradePrompt({
  accountCountTuningRequestedAt,
  finalAccountCountTunedAt,
}: AccountUpgradePromptProps) {
  const handleClick = () => {
    dialogService.push(<AddAccountRequestDialog />, {
      id: 'add-account-request-dialog',
      titleProps: { title: '계정추가 요청' },
      wrapperClassName: 'h-fit mt-0 mb-0',
      onClose: () => dialogService.popById('add-account-request-dialog'),
    });
  };

  const shouldShowAddAccountScreen = useMemo(() => {
    if (!accountCountTuningRequestedAt) {
      return true;
    }
    if (!finalAccountCountTunedAt) {
      return false;
    }

    const finalTunedAt = dayjs(finalAccountCountTunedAt);
    const requestTunedAt = dayjs(accountCountTuningRequestedAt);

    return finalTunedAt.isAfter(requestTunedAt);
  }, [accountCountTuningRequestedAt, finalAccountCountTunedAt]);

  return (
    <div className='absolute inset-0 z-10 flex flex-col items-center justify-center rounded-r16 bg-white/80 backdrop-blur-[10px]'>
      {shouldShowAddAccountScreen ? (
        <AddAccountRequestScreen onClick={handleClick} />
      ) : (
        <AccountRequestCompletedScreen />
      )}
    </div>
  );
}

const AddAccountRequestScreen = ({ onClick }: { onClick: () => void }) => {
  return (
    <>
      <span className='text-Header14 text-black500'>최대 계정 수에 도달하였습니다.</span>
      <span className='mt-12 whitespace-pre-wrap text-Body12 text-black500'>
        사용하지 않는 계정을 삭제 후 다시 시도하시거나, 추가 결제가 필요합니다.
        <br />
        추가 결제를 원하시면 아래 버튼을 클릭해 주세요. 확인 후 계정 추가를 도와드리겠습니다.
      </span>
      <ContainedButton btnColor='blue' className='mt-20' onClick={onClick}>
        계정추가 요청
      </ContainedButton>
    </>
  );
};

const AccountRequestCompletedScreen = () => {
  return (
    <>
      <span className='text-Header14 text-black500'>계정추가 요청 완료</span>
      <span className='mt-12 text-Body12 text-black500'>
        계정추가 요청이 완료되었습니다. 담당자 확인 후 연락드리겠습니다.
      </span>
    </>
  );
};
