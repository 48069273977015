import type {
  HospitalAftercareFindSimple,
  HospitalEventFindSimple,
} from '@apis/swaggers/swagger-docs';
import { useUserInfo } from '@shared/hooks/use-user-info';
import Toggle from 'afterdoc-design-system/components/Atoms/Toggle/Toggle';

interface EventActivationStatusProps<
  T extends HospitalEventFindSimple | HospitalAftercareFindSimple,
> {
  isActive: boolean;
  handleChange: <K extends keyof T>(key: K, value: T[K]) => void;
}

export default function ActivationStatus<
  T extends HospitalEventFindSimple | HospitalAftercareFindSimple,
>({ isActive, handleChange }: EventActivationStatusProps<T>) {
  const { authorizationTypeID } = useUserInfo();
  return (
    <div className='flex-center' onClick={(e) => e.stopPropagation()}>
      <Toggle
        toggleSize='big'
        checked={isActive}
        onChange={() => handleChange('isActive', !isActive)}
        disabled={!authorizationTypeID?.canUpsertContent}
      />
    </div>
  );
}
