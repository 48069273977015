import ProgressBar from '@afterdoc-design-system/components/Molecules/ProgressBar/ProgressBar';
import { useSetAtom } from 'jotai';
import type { ErrorMessage } from 'react-use-downloader/dist/types';
import { deleteFileProgressAtom, setFileProgressAtom } from '../../states/chatFileProgress';
import { progressStatusDisplay } from './progressStatusDisplay';

interface ProgressItemProps {
  id: string;
  fileName: string;
  filePath?: string | null;
  progress: number;
  error: ErrorMessage | null;
}

//TODO - 다운로드 기록 및 재 다운로드 로직 추가
export default function ProgressItem({
  id,
  fileName,
  filePath,
  progress,
  error,
}: ProgressItemProps) {
  const deleteFileProgress = useSetAtom(deleteFileProgressAtom);
  const reloadFileProgress = useSetAtom(setFileProgressAtom);
  const cancelFileProgress = useSetAtom(setFileProgressAtom);
  const status = progressStatusDisplay({ progress, error });
  const deleteClickCallback = () => {
    if (status === 'complete' || status === 'error') {
      deleteFileProgress(id);
    } else {
      cancelFileProgress({ id, fileName, triggerCancel: true });
    }
  };
  const cancelClickCallback = () => {
    cancelFileProgress({ id, fileName, triggerCancel: true });
  };
  const reloadClickCallback = () => {
    reloadFileProgress({ id, fileName, triggerReload: true });
  };
  const openFileFolder = () => {
    if (!filePath) return;

    window.electron?.ipcRenderer.send('Application.openFileLocatedFolder', filePath);
  };

  return (
    <div className='mt-10'>
      <ProgressBar
        fileName={fileName}
        status={status}
        percentage={progress}
        onDeleteClickCallback={deleteClickCallback}
        onCancelClickCallback={cancelClickCallback}
        onReloadClickCallback={reloadClickCallback}
        shouldAutoHide={true}
        onFileOpenClickCallback={(e) => {
          e.preventDefault();
          e.stopPropagation();

          openFileFolder();

          deleteFileProgress(id);
        }}
        isDisabledTimeout={true}
      />
    </div>
  );
}
