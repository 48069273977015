import Divider from '@afterdoc-design-system/components/Atoms/Divider/Divider';
import Scrollbar from '@afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import NoData from '@afterdoc-design-system/components/Molecules/NoData/NoData';
import { apiClient } from '@apis/instances/api-client';
import type {
  ApiVideocontenthospitalElFindParams,
  VideoContentHospitalCategoryThumbnailPath,
} from '@apis/swaggers/swagger-docs';
import { useIntersectionObserver } from '@shared-hooks/use-intersection-observer';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { useCallback, useRef } from 'react';
import { SHARED_UTILS } from 'utils/utils';
import { v4 as uuidv4 } from 'uuid';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import type { WorkoutVideoContentSelectDialogProps } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/components/Forms/WorkoutVideoContentForm/components/WorkoutVideoContentSelectDialog/WorkoutVideoContentSelectDialog';
import { focusedContentSubLayerState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/components/Forms/WorkoutVideoContentForm/components/WorkoutVideoContentSelectDialog/components/WorkoutVideoContentPanel/states/focused-content-sub-layer-state';

const ITEMS_LIMIT = 10;

const fetchVideoContents = async (params: ApiVideocontenthospitalElFindParams) => {
  const response = await apiClient.v3.apiVideocontenthospitalElFind(params);
  return SHARED_UTILS.api.checkApiResponse<VideoContentHospitalCategoryThumbnailPath[]>(
    response.data,
  );
};

interface WorkoutVideoContentListProps extends WorkoutVideoContentSelectDialogProps {}

export default function WorkoutVideoContentList({
  handleClickWorkoutCard,
}: WorkoutVideoContentListProps) {
  const { hospitalID } = useSelectedHospitalInfo();

  const loadMoreRef = useRef<HTMLDivElement | null>(null);

  const focusedContentSubLayer = useAtomValue(focusedContentSubLayerState);

  const { data, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage } = useInfiniteQuery({
    queryKey: [
      'videoContentList',
      {
        hospitalID,
        limit: ITEMS_LIMIT,
        categoryID: focusedContentSubLayer?.categoryID,
      },
    ] as const,
    queryFn: ({ queryKey: [, params], pageParam = 0 }) =>
      fetchVideoContents({
        ...params,
        skip: pageParam,
      }),
    enabled: !!focusedContentSubLayer && !!focusedContentSubLayer.categoryID,
    getNextPageParam: (lastPage, allPages) => {
      const loadedItemsCount = allPages.flat().length;
      return lastPage.length === ITEMS_LIMIT ? loadedItemsCount : undefined;
    },
    initialPageParam: 0,
  });

  const contents = data?.pages.flat() ?? [];

  const handleIntersection = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;
      if (entry.isIntersecting && hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
    [fetchNextPage, hasNextPage, isFetchingNextPage],
  );

  useIntersectionObserver(handleIntersection, { root: null, rootMargin: '20px', threshold: 0 }, [
    loadMoreRef.current,
  ]);

  if (!contents.length && !isLoading) {
    return (
      <div className='flex h-[calc(100vh-220px)] w-full items-center justify-center'>
        <NoData
          iconProps={{
            name: 'warning',
            size: 48,
            color: 'white600',
          }}
          title='등록된 콘텐츠가 없습니다.'
        />
      </div>
    );
  }
  return (
    <div className='h-[calc(100vh-220px)] w-full'>
      <Scrollbar disabledX>
        <div className='flex flex-col'>
          {contents.map((content) => {
            return (
              <ContentsCard
                key={uuidv4()}
                handleClickWorkoutCard={handleClickWorkoutCard}
                {...content}
              />
            );
          })}
        </div>
        <div ref={loadMoreRef} style={{ height: 1 }} />
      </Scrollbar>
    </div>
  );
}

const ContentsCard = (
  props: VideoContentHospitalCategoryThumbnailPath & WorkoutVideoContentListProps,
) => {
  const { title, thumbnailFileID, subtitle, vcID, handleClickWorkoutCard } = props;

  return (
    <>
      <div
        className='flex w-full cursor-pointer items-center gap-16 px-20 py-10 hover:bg-blue50'
        onClick={() => {
          handleClickWorkoutCard({
            _id: vcID,
            title,
            thumbnailUrl: thumbnailFileID?.paths?.[0],
            subtitle,
          });
        }}>
        {!!thumbnailFileID?.paths?.[0] && (
          <img
            src={thumbnailFileID.paths[0]}
            alt={title}
            className='h-[80px] w-[80px] rounded-r10 object-cover shadow-modal'
          />
        )}
        <div className='flex flex-col gap-4'>
          <div className='text-Body10 text-black500'>{subtitle}</div>
          <div className='text-Header12 text-black500'>{title}</div>
        </div>
      </div>
      <Divider type='line' className='bg-white400' />
    </>
  );
};
