import * as React from "react";
import type { SVGProps } from "react";
const SvgWomen = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <circle cx={10} cy={8} r={3.25} stroke="#FF006B" strokeWidth={1.5} />
    <path stroke="#FF006B" strokeWidth={1.5} d="M10 11.5v5M14 14H6" />
  </svg>
);
export default SvgWomen;
