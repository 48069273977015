import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import type { ApiEventHospitalAftercareElFindSimpleParams } from '@apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from '@shared/hooks/use-selected-hospital-info';
import { useSuspenseQuery } from '@tanstack/react-query';
import HomeCareListItem from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/HomeCare/components/HomeCareList/components/HomeCareListBody/components/HomeCareListItem';
import { homeCareListQueryKeyState } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/HomeCare/states/home-care-list-query-key-state';
import ContentNoData from '@templates/Content/containers/ContentManager/shared/components/ContentNoData/ContentNoData';
import { useAtomValue } from 'jotai/index';
import React, { useMemo } from 'react';
import { SHARED_UTILS } from 'utils/utils';

const fetchHospitalHomeCareList = async (params: ApiEventHospitalAftercareElFindSimpleParams) => {
  const response = await apiClient.v3.apiEventHospitalAftercareElFindSimple(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export default function HomeCareListBody() {
  const { hospitalID } = useSelectedHospitalInfo();
  const homeCareListQueryKey = useAtomValue(homeCareListQueryKeyState);

  const { data: homeCareList } = useSuspenseQuery({
    queryKey: [
      QUERY_KEY.apiEventHospitalAftercareElFindSimple,
      { hospitalID, ...homeCareListQueryKey },
    ] as const,
    queryFn: ({ queryKey }) => fetchHospitalHomeCareList(queryKey[1]),
  });

  const isSearchQueryEntered = useMemo(() => {
    if (homeCareListQueryKey.keyword) {
      return homeCareListQueryKey.keyword.length > 0;
    }
    return false;
  }, [homeCareListQueryKey.keyword]);

  if (!homeCareList.length) {
    return <ContentNoData isSearchQueryEntered={isSearchQueryEntered} type={'homeCare'} />;
  }

  return (
    <div className='flex flex-col'>
      {homeCareList.map((item) => (
        <HomeCareListItem key={item._id} data={item} />
      ))}
    </div>
  );
}
