import NoData from 'afterdoc-design-system/components/Molecules/NoData/NoData';
import React from 'react';

interface AddressResultNoDataProps {
  keyword?: string;
}

export default function AddressResultNoData({ keyword }: AddressResultNoDataProps) {
  return (
    <NoData
      iconProps={{
        name: 'warning',
        size: 48,
        color: 'white600',
      }}
      className='my-auto whitespace-pre-wrap'
      title={
        !keyword
          ? '검색할 주소를 입력해 주세요.'
          : '검색 결과가 없습니다.\n입력한 주소를 확인해 주세요.'
      }
    />
  );
}
