// hooks/usePatientDetailInfo.ts
import { SHARED_UTILS } from '@shared-utils/utils';
import { useQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type {
  ApiPatientsElDetailData,
  ApiPatientsElDetailParams,
} from 'web/apis/swaggers/swagger-docs';
import { patientIdState } from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/state/show-customer-detail-info';

const getPatientDetailInfo = async (params: ApiPatientsElDetailParams) => {
  const response = await apiClient.v3.apiPatientsElDetail(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export const usePatientDetailInfo = () => {
  const patientId = useAtomValue(patientIdState);

  const { data } = useQuery({
    queryKey: [QUERY_KEY.apiPatientsElDetail, { patientId: patientId || '' }] as const,
    queryFn: ({ queryKey }) => getPatientDetailInfo(queryKey[1]),
    enabled: !!patientId,
  });

  return data as ApiPatientsElDetailData['data'];
};
