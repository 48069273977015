import * as React from "react";
import type { SVGProps } from "react";
const SvgChatVideo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 35 35"
    {...props}
  >
    <g clipPath="url(#chat-video_svg__a)">
      <path
        stroke="#222"
        d="M17.5 34.5c9.389 0 17-7.611 17-17s-7.611-17-17-17-17 7.611-17 17 7.611 17 17 17Z"
      />
      <path
        fill="#222"
        d="M20 19.51v-4c0-.83-.67-1.5-1.5-1.5h-7c-.83 0-1.5.67-1.5 1.5v5c0 .83.67 1.5 1.5 1.5h7c.83 0 1.5-.67 1.5-1.5zm1-4.07 2.23-1.86A2.3 2.3 0 0 1 27 15.35v5.31a2.3 2.3 0 0 1-3.77 1.77L21 20.57A2.507 2.507 0 0 1 18.5 23h-7A2.5 2.5 0 0 1 9 20.5v-5a2.5 2.5 0 0 1 2.5-2.5h7c1.35 0 2.46 1.08 2.5 2.43zm0 1.3v2.53l2.87 2.39a1.3 1.3 0 0 0 2.13-1v-5.31a1.297 1.297 0 0 0-2.13-1z"
      />
    </g>
    <defs>
      <clipPath id="chat-video_svg__a">
        <path fill="#fff" d="M0 0h35v35H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgChatVideo;
