import { atomWithReset, selectAtom } from 'jotai/utils';
import { CUSTOMER_SEARCH_FIELD_OPTIONS } from '../constants/dropdown-items';

export const selectedSearchCriteriaState = atomWithReset<
  (typeof CUSTOMER_SEARCH_FIELD_OPTIONS)[number]['key']
>(CUSTOMER_SEARCH_FIELD_OPTIONS[0].key);

export const selectedSearchCriteriaIndexState = selectAtom(selectedSearchCriteriaState, (state) =>
  CUSTOMER_SEARCH_FIELD_OPTIONS.findIndex((option) => option.key === state),
);
