import type { TimeSettingControlKeys } from '@templates/UserInfoSetting/containers/NotificationSetting/types/res-notification-setting-type';
import { atomWithReset } from 'jotai/utils';

export const notificationSettingErrorState = atomWithReset<
  Record<keyof TimeSettingControlKeys, boolean>
>({
  hasTimeSettingForInChargeChat: false,
  onChatsDependsTime: false,
  onManagerChatsDependsTime: false,
});
