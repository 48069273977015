import { toastService } from '@afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { SHARED_UTILS } from '@shared-utils/utils';
import { useAtomValue, useSetAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useUserInfo } from 'web/shared/hooks/use-user-info';
import { ROOM_TYPE_MAP } from 'web/templates/CustomerChat/components/ChattingList/constants/room-type';
import { selectedMessageIDState } from 'web/templates/CustomerChat/components/SupportBot/states/selected-message-id';
import { isEmptyWithTrim } from 'web/templates/CustomerChat/functions/isEmptyWithTrim';
import {
  chatRoomIDSelector,
  roomInfoSelector,
  roomTypeSelector,
} from 'web/templates/CustomerChat/states/selected-chat-room';
import { translateLanguageState } from 'web/templates/CustomerChat/states/translate-language';
import type { ChattingResponse } from 'web/templates/CustomerChat/types';
import { isActivateChatTranslateAtom } from '../../../states/isChatTranslate';
import { TEXT_TYPE_GROUP } from '../../ChattingContainer/components/ChattingContent/components/ChattingMessage/Message/Message';
import { MESSAGE_TYPE_CODE } from '../../ChattingContainer/components/ChattingContent/components/ChattingMessage/constants/message';
import { useHandleChatSocket } from '../../ChattingContainer/components/ChattingContent/hooks/use-handle-chat-socket';
import { isSendingTranslateState } from '../../ChattingContainer/components/ChattingContent/states/is-sending-translate';
import { chattingRequestState } from '../../ChattingContainer/states/chatting-request';
import { isSendingMessagesState } from '../../ChattingContainer/states/isSendingMessages';
import { kakaoIntegrationAxiosInstance } from '../api/kakaoIntegrationAxiosInstance';
import { KAKAO_TEXT_TYPE_FOR_API, REQUEST_ROOM_TYPE_MAP } from '../constants/types-codes';
import type { TempSendFile } from '../types/send-file';

export const useChatInput = () => {
  const [text, setText] = useState<string>('');
  const chatRoomID = useAtomValue(chatRoomIDSelector);
  const translateLanguage = useAtomValue(translateLanguageState);
  const isActivateChatTranslate = useAtomValue(isActivateChatTranslateAtom);
  const setIsSendingTranslate = useSetAtom(isSendingTranslateState);
  const selectedMessageID = useAtomValue(selectedMessageIDState);
  const resetSelectedMessageID = useResetAtom(selectedMessageIDState);
  const setReq = useSetAtom(chattingRequestState);
  const setIsSendingMessages = useSetAtom(isSendingMessagesState);

  const { userId, userType, realName, jobType, photo, name } = useUserInfo();
  const roomType = useAtomValue(roomTypeSelector);
  const roomInfo = useAtomValue(roomInfoSelector);

  const { sendMessage } = useHandleChatSocket({ enableListening: false });

  const sendText = async (messageText: string) => {
    if (chatRoomID === null) return;

    const userName = name ?? '';
    const userRealName = realName ?? '';
    const userJobType = jobType ?? '';
    const filePath = photo as unknown as TempSendFile;
    const photoPath = filePath?.paths[0];

    setIsSendingMessages(true);
    selectedMessageID && resetSelectedMessageID();

    isActivateChatTranslate && setIsSendingTranslate(true);

    switch (roomType) {
      case ROOM_TYPE_MAP.kakao: {
        const { pf_id, user_key } = roomInfo;

        const payload = {
          pf_id,
          user_key,
          jobType: userJobType,
          message_type: KAKAO_TEXT_TYPE_FOR_API,
          message: messageText,
          userID: userId,
          language: translateLanguage,
        };

        const { data } = await kakaoIntegrationAxiosInstance.post('/biz/chat/write', payload);
        //카카오 채팅 api 실패시 에러 코드
        if (data?.code === '5850') {
          toastService.errorMsg({
            text: data?.message,
          });
        }
        const chatData = SHARED_UTILS.api.checkApiResponse(data) as ChattingResponse;

        setReq({
          type: REQUEST_ROOM_TYPE_MAP.KAKAO.TEXT_TYPE,
          payload: { ...chatData, type: MESSAGE_TYPE_CODE.TEXT_MOCKING },
        });

        break;
      }
      case ROOM_TYPE_MAP.afterdoc: {
        const payload = {
          _id: uuidv4(),
          chatRoomID,
          content: messageText,
          jobType: userJobType,
          type: TEXT_TYPE_GROUP[0],
          userRealName,
          userName,
          userID: userId,
          userType,
          photoPath,
          language: translateLanguage,
        };
        setReq({
          type: REQUEST_ROOM_TYPE_MAP.AFTERDOC.TEXT_TYPE,
          payload,
        });

        const { _id, ...restPayload } = payload;

        sendMessage({
          type: REQUEST_ROOM_TYPE_MAP.AFTERDOC.TEXT_TYPE,
          payload: restPayload,
        });
        break;
      }
      default: {
        console.error('Unsupported room type');
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value);
  };

  const handleSend = () => {
    if (isEmptyWithTrim(text)) return;

    sendText(text);
    setText('');
  };

  return {
    text,
    setText,
    handleChange,
    handleSend,
  };
};
