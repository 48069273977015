import { hospitalInfoState } from '@templates/HospitalSetting/containers/HospitalProfile/states/hospital-info-state';
import { createErrorMessageAtom } from '@templates/HospitalSetting/states/hospital-info-input-error-messages-state';
import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import Title from 'afterdoc-design-system/components/Molecules/Title/Title';
import { useAtom, useAtomValue } from 'jotai';
import { selectAtom } from 'jotai/utils';
import { useEffect } from 'react';
import HospitalIntroductionInput from './components/HospitalIntroductionInput';
import HospitalNameInput from './components/HospitalNameInput';
import HospitalProfileImage from './components/HospitalProfileImage';

const TITLE = '병원프로필';
const SUB_TITLE =
  '병원 정보는 고객이나 외부에 공유될 페이지에 사용됩니다. 병원 브랜드를 홍보할 수 있는 정보로 설정해 주세요.';

const hospitalShortNameAtom = selectAtom(hospitalInfoState, (hospital) => hospital.shortName);

const shortNameErrorAtom = createErrorMessageAtom('shortName');

export default function HospitalProfile() {
  const shortName = useAtomValue(hospitalShortNameAtom);

  const [nameError, setNameError] = useAtom(shortNameErrorAtom);

  useEffect(() => {
    if (nameError !== null) {
      setNameError(null);
    }
  }, [shortName]);

  return (
    <>
      <div className='px-80 py-40'>
        <Title title={TITLE} subTitle={SUB_TITLE} />
        <div className='flex flex-col gap-10 px-20'>
          <div className='mt-10 flex py-10'>
            <HospitalProfileImage />
            <div className='ml-40 flex flex-1 flex-col'>
              <HospitalNameInput />
              <HospitalIntroductionInput />
            </div>
          </div>
          {nameError && <span className='text-Body10 text-red500'>{nameError}</span>}
        </div>
      </div>
      <Divider type='plane' height={10} />
    </>
  );
}
