import type { HospitalNoticeSimple } from '@apis/swaggers/swagger-docs';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import NoticeActionPanel from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Notice/components/NoticeList/components/NoticeListBody/components/NoticeActionPanel';
import { useHospitalNotice } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Notice/hooks/use-hospital-notice';
import { NOTICE_LIST_COLUMNS } from '@templates/Content/containers/ContentManager/containers/NoticePopup/constants/notice-popup-list-columns';
import { contentEditState } from '@templates/Content/containers/ContentManager/states/content-edit-state';
import IconButton from 'afterdoc-design-system/components/Atoms/Button/IconButton';
import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import dayjs from 'dayjs';
import { useSetAtom } from 'jotai/index';
import { useMemo, useState } from 'react';

interface NoticeListItemProps {
  data: HospitalNoticeSimple;
}

export default function NoticeListItem({ data }: NoticeListItemProps) {
  const { detailNoticeAction } = useHospitalNotice();

  const setContentEdit = useSetAtom(contentEditState);

  const { _id: noticeID, index: noticeIndex, title, createdAt, viewCount } = data;

  const [bgColor, setBgColor] = useState('white');

  const handleAction = (isFocus: boolean) => {
    setBgColor(isFocus ? 'bg-blue50' : 'white');
  };

  const handleItemClick = () => {
    if (!noticeID) return;

    detailNoticeAction(noticeID, (data) => {
      setContentEdit({ isEditing: true, id: noticeID, data: { ...data, editType: 'notice' } });
    });
  };

  const formattedCreatedAt = useMemo(() => {
    if (createdAt && dayjs(createdAt).isValid()) {
      return dayjs(createdAt).format('YYYY-MM-DD');
    }
    return '-';
  }, [createdAt]);

  return (
    <>
      <div className={`flex w-full cursor-pointer flex-col hover:bg-blueLight ${bgColor}`}>
        <div className='flex h-50 items-center px-20'>
          {NOTICE_LIST_COLUMNS.map(({ key, className: columnClassName, width }) => (
            <div
              key={key}
              className={customTwMerge(
                'flex h-50 items-center px-12 text-Body14 text-black600',
                columnClassName,
              )}
              onClick={handleItemClick}
              style={{ width: `${width}px` }}>
              {key === 'index' && <span>{noticeIndex?.toLocaleString() ?? 0}</span>}
              {key === 'title' && <span className='w-full truncate'>{title}</span>}
              {key === 'date' && <span>{formattedCreatedAt}</span>}
              {key === 'count' && <span>{viewCount?.toLocaleString() ?? 0}</span>}
            </div>
          ))}
          <div className='flex flex-grow items-center justify-end'>
            <NoticeActionPanel id={noticeID ?? ''} handleAction={handleAction} />
            <IconButton
              icon='chevron-right'
              size={24}
              color={'black200'}
              className='ml-4'
              onClick={handleItemClick}
            />
          </div>
        </div>
        <Divider type={'line'} />
      </div>
    </>
  );
}
