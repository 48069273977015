import { kakaoIntegrationAxiosInstance } from "../../../api/kakaoIntegrationAxiosInstance";

type KakaoUploadBase = {
  pf_id: string;
  user_key: string;
  userType: number;
  userName: string;
  userRealName: string;
  userID: string;
  jobType: string;
  type: number;
  photoPath: string;
};
type KakaoUploadImage = KakaoUploadBase & {images: File[]};
type KakaoUploadFile = KakaoUploadBase & {files: File[]};

export const kakaoUploadImage = async (payload:KakaoUploadImage) => {
  const {
    data: { data: imagesData },
  } = await kakaoIntegrationAxiosInstance.post('/biz/file/image_upload', payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return imagesData;
}

export const kakaoUploadFile = async (payload:KakaoUploadFile) => {
  const {
    data: { data: filesData },
  } = await kakaoIntegrationAxiosInstance.post('/biz/file/file_upload', payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return filesData;
}