import * as React from "react";
import type { SVGProps } from "react";
const SvgUnderline = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#7F7F7F"
      d="M4.167 17.5h11.666v-1.667H4.166zM10 14.167a5 5 0 0 0 5-5V2.5h-2.084v6.667a2.917 2.917 0 0 1-5.833 0V2.5H5v6.667a5 5 0 0 0 5 5"
    />
  </svg>
);
export default SvgUnderline;
