import DOMPurify from 'dompurify';
import { useMemo } from 'react';

interface UseGetPriceInfoProps {
  price: number;
  salePrice: number;
  saleRate: number;
  saleType?: string;
  content?: string;
}

export const useGetPriceInfo = ({
  content,
  price,
  salePrice,
  saleRate,
  saleType,
}: UseGetPriceInfoProps) => {
  const formattedPriceDisplay = useMemo(() => {
    const isPriceValid = saleType === 'price' && salePrice !== 0;
    const isRateValid = saleType === 'rate' && saleRate !== 0;

    return isPriceValid || isRateValid ? price.toLocaleString() : '';
  }, [saleType, salePrice, saleRate, price]);

  const calculatedPrice = useMemo(() => {
    if (saleType === 'price') {
      return (price - salePrice).toLocaleString();
    }

    if (saleType === 'rate') {
      const discount = (price * saleRate) / 100;
      return (price - discount).toLocaleString();
    }

    return '0';
  }, [saleType, salePrice, saleRate, price]);

  const calculatedRate = useMemo(() => {
    if (saleType === 'rate') return saleRate ? `${saleRate}%` : '';
    if (salePrice === 0) return '';

    const rate = Math.round((salePrice / price) * 100);
    return `${rate}%`;
  }, [saleType, salePrice, saleRate, price]);

  const sanitizedContent = DOMPurify.sanitize(content ?? '');

  return {
    formattedPriceDisplay,
    calculatedPrice,
    calculatedRate,
    sanitizedContent,
  };
};
