import { createContext, useCallback, useRef } from 'react';

interface DestroyBlockerContextType {
  registerBlocker: (id: string, priority: number) => void;
  unregisterBlocker: (id: string) => void;
  getHighestPriorityBlocker: () => string | null;
}

export const DestroyBlockerContext = createContext<DestroyBlockerContextType | null>(null);

export function DestroyBlockerProvider({ children }: { children: React.ReactNode }) {
  // Map을 사용하여 blocker ID와 우선순위를 저장
  const blockers = useRef(new Map<string, number>());

  const registerBlocker = useCallback((id: string, priority: number) => {
    blockers.current.set(id, priority);
  }, []);

  const unregisterBlocker = useCallback((id: string) => {
    blockers.current.delete(id);
  }, []);

  const getHighestPriorityBlocker = useCallback(() => {
    if (blockers.current.size === 0) return null;

    let highestPriority = -1;
    let highestPriorityId: string | null = null;

    blockers.current.forEach((priority, id) => {
      if (priority > highestPriority) {
        highestPriority = priority;
        highestPriorityId = id;
      }
    });

    return highestPriorityId;
  }, []);

  return (
    <DestroyBlockerContext.Provider
      value={{ registerBlocker, unregisterBlocker, getHighestPriorityBlocker }}>
      {children}
    </DestroyBlockerContext.Provider>
  );
}
