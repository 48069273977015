import ParentDropdown from '@templates/CustomerChat/components/ParentDropDown/ParentDropdown';
import { useDropdown } from '@templates/CustomerChat/components/ParentDropDown/hooks/use-dropdown';
import { useAtomValue } from 'jotai';
import BookmarkToggle from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/components/ChattingMessage/components/BookmarkToggle/BookmarkToggle';
import { useChatTopPatient } from 'web/templates/CustomerChat/hooks/use-chat-top-patient';
import { usePatient } from 'web/templates/CustomerChat/hooks/use-patient';
import { roomTypeSelector } from 'web/templates/CustomerChat/states/selected-chat-room';
import { useChatMessageContext } from '../../ChatMessageContext';
import Message from '../../Message/Message';
import MessageTimestamp from '../../MessageTimestamp';

export default function LeftMessage() {
  const { sentAt, userName } = useChatMessageContext();
  const roomType = useAtomValue(roomTypeSelector);

  const { name } = usePatient();

  const { iconSet } = useChatTopPatient();

  const { isDropdownOpen, handleDropdown } = useDropdown();

  return (
    <div className='group relative ml-20 flex flex-col gap-8'>
      <div>
        <div
          className='inline-block max-w-full cursor-pointer text-left text-Header12 text-black200'
          onClick={handleDropdown}>
          {roomType === 'ikakao' ? name : userName}
        </div>
        {isDropdownOpen && (
          <ParentDropdown dropdownDataParams={iconSet} handleToggle={handleDropdown} />
        )}
      </div>
      <div className='flex flex-row items-end gap-8'>
        <Message />
        <div className='flex flex-col items-start justify-end'>
          <BookmarkToggle />
          {<MessageTimestamp sentAt={sentAt} />}
        </div>
      </div>
    </div>
  );
}
