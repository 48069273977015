import EventContainer from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/EventContainer';
import HomeCareContainer from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/HomeCare/HomeCareContainer';
import NoticeContainer from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Notice/NoticeContainer';
import PopupContainer from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/PopupContainer';
import VideoContentContainer from '@templates/Content/containers/ContentManager/containers/VideoContent/VideoContentContainer';
import {
  type ContentSubLayer,
  type defaultKey,
  validKeys,
} from '@templates/Content/containers/ContentPanel/constants/tab-items';
import { focusedContentSubLayerState } from '@templates/Content/containers/ContentPanel/states/focused-content-sub-layer-state';
import { useAtomValue } from 'jotai';

const RenderPage = (contentLayer: ContentSubLayer | null) => {
  if (contentLayer) {
    if (validKeys.includes(contentLayer.key as defaultKey)) {
      switch (contentLayer.key) {
        case 'event':
          return EventContainer;
        case 'homeCare':
          return HomeCareContainer;
        case 'notice':
          return NoticeContainer;
        case 'popup':
          return PopupContainer;
      }
    }
    return VideoContentContainer;
  }
  return null;
};

export default function ContentManagerContainer() {
  const focusedContentSubLayer = useAtomValue(focusedContentSubLayerState);
  const ComponentToRender = RenderPage(focusedContentSubLayer);

  return (
    <div className='flex-col-center flex-grow bg-blueLight'>
      {ComponentToRender && <ComponentToRender />}
    </div>
  );
}
