type CreateTopPositionParams = {
  top: number;
  dropdownHeight: number;
  position: string;
  offset?: number;
};

export const createTopPosition = ({
  top,
  dropdownHeight,
  position,
  offset = 0,
}: CreateTopPositionParams) => {
  if (position === 'top') {
    return -(dropdownHeight - top + offset);
  }
  if (position === 'bottom') {
    return top;
  }
};
