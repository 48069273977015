import { hasUnreadMessageBadgeState } from '@shared/states/has-unread-message-badge-state';
import type { InfiniteData, UseInfiniteQueryResult } from '@tanstack/react-query';
import type { TempChatRoomHServiceLastMessageUser } from '@templates/CustomerChat/components/ChattingList/hooks/use-in-progress-chatting-list';
import { useAtomValue } from 'jotai/index';
import { useEffect, useMemo, useState } from 'react';
import { CHATTING_LIST_TABS } from 'web/templates/CustomerChat/constants/tab';
import { useChatRoomLength } from './use-chatroom-length';
import { useChattingList } from './use-chatting-list';

export type ChatInfiniteQueryControls = Pick<
  UseInfiniteQueryResult<InfiniteData<TempChatRoomHServiceLastMessageUser[], unknown>, Error>,
  'fetchNextPage' | 'isFetchingNextPage' | 'hasNextPage' | 'isLoading'
>;

export const useChattingListContainer = () => {
  const [tabItemsObject, setTabItemsObject] = useState({
    [CHATTING_LIST_TABS.IN_PROGRESS]: {
      isBadge: false,
      badgeCount: 0,
    },
    [CHATTING_LIST_TABS.ALL]: {
      isBadge: false,
      badgeCount: 0,
    },
  });

  const { chatRooms, fetchNextPage, isFetchingNextPage, hasNextPage, isLoading } =
    useChattingList();

  const { ingLength, allLength } = useChatRoomLength();

  const hasUnreadMessageBadge = useAtomValue(hasUnreadMessageBadgeState);

  useEffect(() => {
    setTabItemsObject((prev) => ({
      ...prev,
      [CHATTING_LIST_TABS.IN_PROGRESS]: {
        isBadge: hasUnreadMessageBadge,
        badgeCount: ingLength,
      },
    }));
  }, [ingLength, hasUnreadMessageBadge]);

  useEffect(() => {
    setTabItemsObject((prev) => ({
      ...prev,
      [CHATTING_LIST_TABS.ALL]: {
        isBadge: false,
        badgeCount: allLength,
      },
    }));
  }, [allLength]);

  const infiniteQueryControls: ChatInfiniteQueryControls = useMemo(
    () => ({
      fetchNextPage,
      isFetchingNextPage,
      hasNextPage,
      isLoading,
    }),
    [fetchNextPage, isFetchingNextPage, hasNextPage, isLoading],
  );

  return {
    chatRooms,
    tabItemsObject,
    infiniteQueryControls,
  };
};
