import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';

interface EditOverlayProps {
  id?: string;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  className?: string;
}

export default function EditOverlay({ id, onClick, className }: EditOverlayProps) {
  return (
    <div
      key={id}
      className={customTwMerge(
        'absolute top-0 left-0 z-10 h-full w-full bg-transparent',
        className,
      )}
      onClick={onClick}
    />
  );
}
