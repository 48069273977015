import { useChatMessageContext } from '../ChatMessageContext';
import MessageTitle from './components/MessageTitle';
import VideoListCard from './components/VideoListCard';

export default function VideoMessage() {
  const { videoContentIDs, isMine, messageWidthStyle } = useChatMessageContext();

  return (
    <div className='flex flex-col gap-10'>
      <div
        className={`rounded-r10 px-16 py-10 shadow-modal ${isMine ? 'bg-[rgba(255,245,208,1)]' : 'bg-white50'}`}
        style={messageWidthStyle}>
        <MessageTitle title='운동 영상' />
        <div className='flex flex-col gap-10'>
          {videoContentIDs?.map((video) => {
            //TODO vchID 추가하기
            const { thumbnailFileID, title, vchID } = video;

            if (!thumbnailFileID?.paths?.[0] || !title) return null;
            return (
              <VideoListCard
                vchID={vchID ?? ''}
                key={thumbnailFileID.paths[0]}
                url={thumbnailFileID.paths[0]}
                title={title}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
