import * as React from "react";
import type { SVGProps } from "react";
const SvgH2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#7F7F7F"
      d="M2.5 3.333h1.667v5H7.5v-5h1.667V15H7.5v-5H4.167v5H2.5zM17.5 15h-5a1.666 1.666 0 0 1-1.667-1.667c0-.442.167-.833.45-1.133l4.059-4.359c.308-.3.491-.716.491-1.175a1.667 1.667 0 0 0-3.333 0h-1.667a3.333 3.333 0 1 1 5.692 2.359L12.5 13.333h5z"
    />
  </svg>
);
export default SvgH2;
