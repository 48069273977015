import * as React from "react";
import type { SVGProps } from "react";
const SvgStrikethrough = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#7F7F7F"
      d="M2.5 11.666h15V10h-15m1.667-6.667v2.5h4.166v2.5h3.334v-2.5h4.166v-2.5m-7.5 12.5h3.334v-2.5H8.333z"
    />
  </svg>
);
export default SvgStrikethrough;
