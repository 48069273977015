import dayjs from 'dayjs';

export const hasIncompleteDates = (startDate?: string, endDate?: string) => {
  const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;

  const dateValidate = (date: string | undefined) => {
    return date && dayjs(date).isValid() && dateFormatRegex.test(date);
  };

  return !dateValidate(startDate) || !dateValidate(endDate);
};
