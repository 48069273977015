import type { AlgorithmSummary } from 'web/apis/swaggers/swagger-docs';
import CounselCardItem from 'web/templates/Automation/containers/Main/CounselAutomationContent/components/CounselCardItem';

interface ConsultationAlgorithmListProps {
  slicedConsultationAlgorithms: AlgorithmSummary[];
  restConsultationAlgorithms: AlgorithmSummary[];
  viewAll: boolean;
}

export default function ConsultationAlgorithmList({
  slicedConsultationAlgorithms,
  restConsultationAlgorithms,
  viewAll,
}: ConsultationAlgorithmListProps) {
  return (
    <>
      {slicedConsultationAlgorithms.map((consultationAlgorithm) => (
        <CounselCardItem
          key={consultationAlgorithm._id}
          consultationAlgorithm={consultationAlgorithm}
        />
      ))}
      {viewAll &&
        restConsultationAlgorithms.length > 0 &&
        restConsultationAlgorithms.map((consultationAlgorithm) => (
          <CounselCardItem
            key={consultationAlgorithm._id}
            consultationAlgorithm={consultationAlgorithm}
          />
        ))}
    </>
  );
}
