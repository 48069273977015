import { eventCreateOrUpdateParamState } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/states/event-create-or-update-param-state';
import { homeCareCreateOrUpdateParamState } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/HomeCare/states/homecare-create-or-update-param-state';
import { contentEditErrorsState } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/states/content-edit-errors-state';
import { detailImageTempFileState } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/states/detail-image-temp-file-state';
import { thumbnailImageTempFileState } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/states/thumbnail-image-temp-file-state';
import { contentEditState } from '@templates/Content/containers/ContentManager/states/content-edit-state';
import { useResetAtom } from 'jotai/utils';

export const useResetAll = () => {
  const resetContentEdit = useResetAtom(contentEditState);
  const resetContentEditErrors = useResetAtom(contentEditErrorsState);
  const resetThumbnailImageTempFile = useResetAtom(thumbnailImageTempFileState);
  const resetDetailImageTempFile = useResetAtom(detailImageTempFileState);

  const resetEventCreateOrUpdateParam = useResetAtom(eventCreateOrUpdateParamState);
  const resetHomeCareCreateOrUpdateParam = useResetAtom(homeCareCreateOrUpdateParamState);

  const atomResetMap = {
    contentEditState: resetContentEdit,
    contentEditErrorsState: resetContentEditErrors,
    thumbnailImageTempFileState: resetThumbnailImageTempFile,
    detailImageTempFileState: resetDetailImageTempFile,
    eventCreateOrUpdateParamState: resetEventCreateOrUpdateParam,
    homeCareCreateOrUpdateParamState: resetHomeCareCreateOrUpdateParam,
  };

  const resetSpecific = (key: keyof typeof atomResetMap) => {
    atomResetMap[key]?.();
  };

  const resetAll = (key: 'eventCreateOrUpdateParamState' | 'homeCareCreateOrUpdateParamState') => {
    resetSpecific('contentEditErrorsState');
    resetSpecific('thumbnailImageTempFileState');
    resetSpecific('detailImageTempFileState');

    resetSpecific(key);
  };

  return {
    resetSpecific,
    resetAll,
  };
};
