import TextButton from '@afterdoc-design-system/components/Atoms/Button/TextButton';
import { toastService } from '@afterdoc-design-system/components/Atoms/Toast/Toast.service';
import Title from '@afterdoc-design-system/components/Molecules/Title/Title';
import { SHARED_UTILS } from '@shared-utils/utils';
import { useSuspenseQuery } from '@tanstack/react-query';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { ApiManualsElFoldersParams } from 'web/apis/swaggers/swagger-docs';
import { OVERLAY_PAGE_ID } from 'web/shared/constants/overlay-page-id';
import { useOpenAutomationDialog } from 'web/shared/hooks/overlayPage/use-open-automation-dialog';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import { useUserInfo } from 'web/shared/hooks/use-user-info';
import RegisterCounselAutomationDialogContainer from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/RegisterCounselAutomationDialog.container';

const getManualFolders = async (params: ApiManualsElFoldersParams) => {
  const response = await apiClient.v3.apiManualsElFolders(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export default function CounselAutomationTitle() {
  const { hospitalID } = useSelectedHospitalInfo();
  const {
    data: { foldersHasTags, unassignedTags },
  } = useSuspenseQuery({
    queryKey: [QUERY_KEY.apiManualsElFolders, { hospitalID }] as const,
    queryFn: ({ queryKey }) => getManualFolders(queryKey[1]),
  });

  const { authorizationTypeID } = useUserInfo();
  const canUpsertAutomation = !!authorizationTypeID?.canUpsertAutomation;

  const openAutomationDialog = useOpenAutomationDialog();

  const onClickOpenAutomationDialog = () => {
    if (!foldersHasTags?.length && !unassignedTags?.length) {
      return toastService.errorMsg({
        text: '치료태그를 먼저 등록해주세요.',
      });
    }
    return openAutomationDialog(
      <RegisterCounselAutomationDialogContainer />,
      OVERLAY_PAGE_ID['register-counsel-automation-dialog'],
    );
  };

  return (
    <Title
      title='상담자동화'
      wrapperClassName='items-center'
      subTitle='고객에게 입력된 치료태그에 따라 관리방법, 재내원 안내 등의 메시지를 자동화할 수 있습니다.'
      trailingChildren={
        canUpsertAutomation && (
          <TextButton
            onClick={onClickOpenAutomationDialog}
            textColor='blue500'
            className='text-Body14'>
            등록
          </TextButton>
        )
      }
    />
  );
}
