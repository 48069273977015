import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';

export type RoomInfo = {
  pf_id: string;
  user_key: string;
  isClosed: boolean;
};

export type RoomType = 'patient' | 'ikakao' | 'manager';

export type ChatRoomID = string | null;

export type SelectedChatRoom = {
  chatRoomID: ChatRoomID;
  chatRoomHserviceID: string | null;
  patientIDInChattingListUserID: string | null;
  chatRoomParentHServiceID: string | null;
  roomType: RoomType | null;
  roomInfo: RoomInfo | null;
};

// 전체 context를 관리하는 atom
export const selectedChatRoomState = atomWithReset<SelectedChatRoom>({
  chatRoomID: null,
  chatRoomHserviceID: null,
  patientIDInChattingListUserID: null,
  chatRoomParentHServiceID: null,
  roomType: null,
  roomInfo: null,
});

// 특정 프로퍼티의 변화를 감지하는 atom 셀렉터
export const chatRoomIDSelector = atom((get) => get(selectedChatRoomState).chatRoomID);

//카카오톡 연동일 경우 메뉴얼, 컨텐츠 보내기에 사용하는 hserviceID(patientID)
export const chatRoomHserviceIDSelector = atom(
  (get) => get(selectedChatRoomState).chatRoomHserviceID ?? '',
);
export const patientIDInChattingListUserIDSelector = atom(
  (get) => get(selectedChatRoomState).patientIDInChattingListUserID ?? '',
);

export const chatRoomParentHServiceIDSelector = atom(
  (get) => get(selectedChatRoomState).chatRoomParentHServiceID ?? '',
);

export const roomTypeSelector = atom((get) => get(selectedChatRoomState).roomType);

export const roomInfoSelector = atom(
  (get) => get(selectedChatRoomState).roomInfo ?? { pf_id: '', user_key: '', isClosed: false },
);
