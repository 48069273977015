import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';
import { dialogService } from 'afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import React, { useEffect, useRef, useState } from 'react';
import {
  type ArbitraryProps,
  Cropper,
  FixedCropper,
  type FixedCropperRef,
  ImageRestriction,
} from 'react-advanced-cropper';
import 'react-advanced-cropper/dist/style.css';

interface EventImageCropDialogProps {
  file: File;
  onCropComplete: (url: string, file: File) => void;
  useFixedCropper?: boolean;
  stencilProps?: ArbitraryProps;
}

const defaultStencil = {
  handlers: false,
  lines: false,
  movable: false,
  resizable: false,
  scalable: true,
  aspectRatio: 1,
};

export default function EventImageCropDialog({
  file,
  onCropComplete,
  useFixedCropper,
  stencilProps,
}: EventImageCropDialogProps) {
  const [imageSrc, setImageSrc] = useState<string | undefined>();
  const cropperRef = useRef<FixedCropperRef>(null);

  const handleCancel = () => {
    dialogService.popById('image-crop-dialog');
  };

  const handleConfirm = () => {
    if (cropperRef.current) {
      const canvas = cropperRef.current.getCanvas();
      if (canvas) {
        canvas.toBlob((blob) => {
          if (blob) {
            const croppedFile = new File([blob], file.name, {
              type: file.type,
              lastModified: Date.now(),
            });

            const croppedUrl = URL.createObjectURL(croppedFile);
            onCropComplete(croppedUrl, croppedFile);
          }
        }, file.type);
      }
      dialogService.popById('image-crop-dialog');
    }
  };

  useEffect(() => {
    if (file) {
      const objectUrl = URL.createObjectURL(file);
      setImageSrc(objectUrl);

      return () => {
        URL.revokeObjectURL(objectUrl);
      };
    }
  }, [file]);

  return (
    <div className='h-full flex-col-center'>
      <div className='my-30 h-[400px] w-[400px]'>
        {useFixedCropper && (
          <FixedCropper
            ref={cropperRef}
            src={imageSrc}
            className={'h-full w-full'}
            stencilSize={{ width: 300, height: 300 }}
            stencilProps={{
              ...defaultStencil,
              ...stencilProps,
            }}
            imageRestriction={ImageRestriction.stencil}
          />
        )}
        {!useFixedCropper && (
          <Cropper
            ref={cropperRef}
            src={imageSrc}
            className={'h-full w-full'}
            stencilProps={{ ...stencilProps, movable: false }}
            transformImage={{
              adjustStencil: false,
            }}
            imageRestriction={ImageRestriction.stencil}
          />
        )}
      </div>
      <div className='flex gap-10 pt-10 pb-20'>
        <ContainedButton btnColor='transparent' onClick={handleCancel}>
          취소하기
        </ContainedButton>
        <ContainedButton btnColor='blue' onClick={handleConfirm}>
          등록하기
        </ContainedButton>
      </div>
    </div>
  );
}
