import type {
  HospitalAftercareFindSimple,
  HospitalEventFindSimple,
} from '@apis/swaggers/swagger-docs';
import ActivationStatus from '@templates/Content/containers/ContentManager/containers/EventHomeCare/shared/components/EventHomeCareListItem/ActivationStatus';
import DisplayPeriod from '@templates/Content/containers/ContentManager/containers/EventHomeCare/shared/components/EventHomeCareListItem/DisplayPeriod';
import EventHomeCareImage from '@templates/Content/containers/ContentManager/containers/EventHomeCare/shared/components/EventHomeCareListItem/EventHomeCareImage';
import EventHomeCareTitle from '@templates/Content/containers/ContentManager/containers/EventHomeCare/shared/components/EventHomeCareListItem/EventHomeCareTitle';
import InquiryCount from '@templates/Content/containers/ContentManager/containers/EventHomeCare/shared/components/EventHomeCareListItem/InquiryCount';
import SellingPrice from '@templates/Content/containers/ContentManager/containers/EventHomeCare/shared/components/EventHomeCareListItem/SellingPrice';
import TopPinnedStatus from '@templates/Content/containers/ContentManager/containers/EventHomeCare/shared/components/EventHomeCareListItem/TopPinnedStatus';
import ViewCount from '@templates/Content/containers/ContentManager/containers/EventHomeCare/shared/components/EventHomeCareListItem/ViewCount';

interface EventHomeCareColumnsProps<
  T extends HospitalEventFindSimple | HospitalAftercareFindSimple,
> {
  isPinned: boolean;
  isActive: boolean;
  representImage: string;
  title: string;
  price: number;
  salePrice: number;
  saleRate: number;
  saleType: string;
  isLimitedTime: boolean;
  startDate: string;
  endDate: string;
  viewCount: number;
  chatCount: number;
  handleChange: <K extends keyof T>(key: K, value: T[K]) => void;
}

export default function EventHomeCareColumns<
  T extends HospitalEventFindSimple | HospitalAftercareFindSimple,
>({
  isPinned,
  isActive,
  representImage,
  title,
  price,
  salePrice,
  saleRate,
  saleType,
  isLimitedTime,
  startDate,
  endDate,
  viewCount,
  chatCount,
  handleChange,
}: EventHomeCareColumnsProps<T>) {
  return {
    topPinned: <TopPinnedStatus isPinned={isPinned} handleChange={handleChange} />,
    activation: <ActivationStatus isActive={isActive} handleChange={handleChange} />,
    image: <EventHomeCareImage imagePath={representImage} />,
    eventTitle: <EventHomeCareTitle title={title} />,
    sellingPrice: (
      <SellingPrice price={price} salePrice={salePrice} saleRate={saleRate} saleType={saleType} />
    ),
    displayPeriod: (
      <DisplayPeriod isLimitedTime={isLimitedTime} startDate={startDate} endDate={endDate} />
    ),
    viewCount: <ViewCount viewCount={viewCount} />,
    inquiryCount: <InquiryCount chatCount={chatCount} />,
  };
}
