import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import React from 'react';

export default function AccessDenied() {
  return (
    <div className='flex h-full flex-col items-center justify-center'>
      <Icon name='warning' size={48} color='white600' />
      <span className='size mt-10 font-bold text-[14px]'>접근 권한이 없습니다.</span>
    </div>
  );
}
