type ComponentMapType = {
  index: string;
  title: string;
  date: string;
  count: string;
};

export type NoticePopupColumnType = {
  key: keyof ComponentMapType;
  title: string;
  width: number;
  className?: string;
};

type ColumnTypeCategory = 'notice' | 'popup';

const COMMON_COLUMNS: Omit<NoticePopupColumnType, 'title' | 'width'>[] = [
  { key: 'index' },
  { key: 'title' },
  { key: 'date', className: 'flex-center' },
  { key: 'count', className: 'flex-center' },
];

const generateColumnsByCategory = (category: ColumnTypeCategory): NoticePopupColumnType[] => {
  const titlesMap: Record<ColumnTypeCategory, string[]> = {
    notice: ['번호', '제목', '작성일', '조회수'],
    popup: ['번호', '팝업명', '노출 기간', '노출수'],
  };

  const columnWidths: Record<
    ColumnTypeCategory,
    Partial<Record<keyof ComponentMapType, number>>
  > = {
    notice: {
      index: 80,
      title: 700,
      date: 130,
      count: 90,
    },
    popup: {
      index: 80,
      title: 610,
      date: 220,
      count: 90,
    },
  };

  const titles = titlesMap[category];

  return COMMON_COLUMNS.map((column, index) => ({
    ...column,
    title: titles[index],
    width: columnWidths[category][column.key] as number,
  }));
};

export const NOTICE_LIST_COLUMNS = generateColumnsByCategory('notice');

export const POPUP_LIST_COLUMNS = generateColumnsByCategory('popup');
