import EmojiPicker from 'emoji-picker-react';
import { useAtomValue } from 'jotai';
import { useEffect, useRef } from 'react';
import { isDisabledChat } from 'web/templates/CustomerChat/functions/is-disabled-Chat';
import { isEmptyWithTrim } from 'web/templates/CustomerChat/functions/isEmptyWithTrim';
import { useChatTopPatient } from 'web/templates/CustomerChat/hooks/use-chat-top-patient';
import {
  chatRoomIDSelector,
  roomTypeSelector,
} from 'web/templates/CustomerChat/states/selected-chat-room';
import { useChatRoomInfoOne } from '../../hooks/use-chatroom-info-one';
import ChatTextArea from './components/ChatTextArea/ChatTextArea';
import ChattingSendingBottom from './components/ChattingSendingBottom/ChattingSendingBottom';
import { useChatInput } from './hooks/use-chat-input';
import { useEmojiPicker } from './hooks/use-emoji-picker';
import { useReservationListener } from './hooks/use-reservation-listener';

export default function ChattingSending() {
  const chatRoomID = useAtomValue(chatRoomIDSelector);
  if (!chatRoomID) return null;
  const roomType = useAtomValue(roomTypeSelector);

  const { isCounselInProgress, languageID, ikakaoInfo } = useChatRoomInfoOne({
    chatRoomID,
  });

  const { iconSet } = useChatTopPatient();

  // const isClosedKakaoChat = roomType === ROOM_TYPE_MAP.kakao ? ikakaoInfo?.isClosed : false;

  const isDisabledSendingBottom = isDisabledChat({ roomType, ikakaoInfo, iconSet });

  const placeholder = () => {
    if (isDisabledSendingBottom) return '고객 정보를 알 수 없어 메시지를 보낼 수 없습니다.';

    if (isCounselInProgress === undefined || isCounselInProgress) {
      return '메시지를 입력해주세요';
    }
    return '상담 종료한 채팅방입니다.\n상담을 진행하시려면 메시지를 입력해주세요.';
  };

  const { text, setText, handleChange, handleSend } = useChatInput();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useReservationListener(setText);

  const { showEmojiPicker, emojiPickerRef, toggleEmojiPicker, onEmojiClick } = useEmojiPicker(
    setText,
    textAreaRef,
  );

  useEffect(() => {
    setText('');
  }, [chatRoomID]);

  //TODO - round 16 먹이기 이곳의 문제는 부모의 무엇인가에 영향을 받는다.
  return (
    <div
      className={`flex w-full flex-col ${isDisabledSendingBottom ? 'bg-white200' : 'bg-white50'}`}>
      <div className='relative'>
        <ChatTextArea
          textAreaRef={textAreaRef}
          placeholder={placeholder()}
          onChange={handleChange}
          isShowBottom={false}
          errorText='에러 메시지'
          value={text}
          noBorder={true}
          maxLength={1000}
          maxHeight={185}
          className={`overflow-hidden ${isDisabledSendingBottom ? 'text-black200' : 'text-black700'}`}
          disabled={isDisabledSendingBottom}
        />
        {showEmojiPicker && (
          <div
            ref={emojiPickerRef}
            style={{
              position: 'absolute',
              top: '-375px',
              left: 0,
              zIndex: 100,
            }}>
            <EmojiPicker onEmojiClick={onEmojiClick} width={340} height={370} />
          </div>
        )}
      </div>
      <ChattingSendingBottom
        disabled={isEmptyWithTrim(text)}
        toggleEmojiPicker={toggleEmojiPicker}
        onClickSend={handleSend}
        textAreaRef={textAreaRef}
        languageID={languageID}
        chatRoomID={chatRoomID}
      />
    </div>
  );
}
