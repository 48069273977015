import type { IconType } from 'afterdoc-design-system/components/Foundations/Icon/Icon';

export type AuthorizationGroupKey =
  | 'patientConsultation'
  | 'patientManagement'
  | 'reservationManagement'
  | 'automation'
  | 'content'
  | 'manualManagement'
  | 'hospitalSettings';

export interface AuthorizationItemType {
  icon: IconType;
  key: AuthorizationGroupKey;
}

type ItemTypes = {
  [key: string]: AuthorizationItemType;
};

export const AUTHORIZATION_TYPES: ItemTypes = {
  customerConsultation: {
    icon: 'chat-bubble-outline',
    key: 'patientConsultation',
  },
  customerManagement: {
    icon: 'account-view-outline',
    key: 'patientManagement',
  },
  // reservationManagement: {
  //   icon: 'calendar-blank-outline',
  //   key: 'reservationManagement',
  // },
  automation: {
    icon: 'auto-fix-high',
    key: 'automation',
  },
  content: {
    icon: 'human-male-board-poll',
    key: 'content',
  },
  manualManagement: {
    icon: 'book-cog-outline',
    key: 'manualManagement',
  },
  hospitalSettings: {
    icon: 'cog-outline',
    key: 'hospitalSettings',
  },
};
