import ContainedButton from '@afterdoc-design-system/components/Atoms/Button/ContainedButton';
import OutlinedButton from '@afterdoc-design-system/components/Atoms/Button/OutlinedButton';
import { modalService } from '@afterdoc-design-system/components/Molecules/Modal/Modal.service';

type BottomButtonsProps = {
  handleConfirmClick?: (() => void) | undefined;
};

export default function BottomButtons({ handleConfirmClick }: BottomButtonsProps) {
  return (
    <>
      <OutlinedButton
        btnColor='blue'
        className='w-full'
        onClick={() => {
          modalService.pop();
        }}>
        취소
      </OutlinedButton>
      <ContainedButton btnColor='blue' className='w-full' onClick={handleConfirmClick}>
        확인
      </ContainedButton>
    </>
  );
}
