interface VideoContentDetailTitleProps {
  title: string;
  subTitle: string;
}

export default function VideoContentDetailTitle({ title, subTitle }: VideoContentDetailTitleProps) {
  return (
    <div className='mt-30 flex w-full flex-col gap-6'>
      <span className='line-clamp-2 overflow-hidden break-all text-Body13 text-black500'>
        {subTitle}
      </span>
      <span className='line-clamp-2 overflow-hidden break-all text-Header16 text-black700'>
        {title}
      </span>
    </div>
  );
}
