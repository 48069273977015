import { SHARED_UTILS } from '@shared-utils/utils';
import { useQuery } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { useEffect, useMemo, useRef, useState } from 'react';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type {
  ApiServiceSettingsElFindOneParams,
  ApiVideocontentcategoryElFindParams,
  VideoContentCategoryParentInfos,
} from 'web/apis/swaggers/swagger-docs';
import FullLoading from 'web/shared/components/FullLoading/FullLoading';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import ContentPanelFolder from 'web/templates/Content/containers/ContentPanel/components/ContentPanelFolder';
import {
  CONTENT_PANEL_ITEMS,
  type ContentLayer,
  type ContentSubLayer,
} from 'web/templates/Content/containers/ContentPanel/constants/tab-items';
import { contentPanelLayerState } from 'web/templates/Content/containers/ContentPanel/states/content-panel-layer-state';
import '../../styles/ManualContainer.scss';
import ChatContentPanelFolderItem from './components/ContentsPanelFolderItem/components/ChatContentPanelFolderItem/ChatContentPanelFolderItem';

export interface ContentFolders {
  id: string;
  title: string;
  items: { key: string; text: string; type: string; isOpen: boolean }[];
}
[];

const fetchServiceSetting = async (params: ApiServiceSettingsElFindOneParams) => {
  const response = await apiClient.v3.apiServiceSettingsElFindOne(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const fetchVideoContentCategories = async (params: ApiVideocontentcategoryElFindParams) => {
  const response = await apiClient.v3.apiVideocontentcategoryElFind(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const createContentItem = (category: VideoContentCategoryParentInfos): ContentSubLayer => ({
  key: category._id ?? '',
  text: category.categoryName ?? '',
  data: category,
});

export default function ContentsList() {
  const [categories, setCategories] = useState<VideoContentCategoryParentInfos[]>([]);
  const [contentPanelLayer, setContentPanelLayer] = useAtom(contentPanelLayerState);
  const [contentsPanelOpenMap, setContentsPanelOpenMap] = useState(
    () => new Map<string, boolean>(),
  );

  const manualListContainerRef = useRef<HTMLDivElement>(null);

  const { hospitalID, usingHospitalEvent } = useSelectedHospitalInfo();

  const filteredContentPanelItems = useMemo(() => {
    return usingHospitalEvent
      ? CONTENT_PANEL_ITEMS
      : CONTENT_PANEL_ITEMS.filter((item) => item.title !== '이벤트');
  }, [usingHospitalEvent]);

  const { data: serviceSettings, isLoading } = useQuery({
    queryKey: [QUERY_KEY.apiServiceSettingsElFindOne, { hospitalID }] as const,
    queryFn: ({ queryKey }) => fetchServiceSetting(queryKey[1]),
  });

  const addCategoryToLayer = (
    layers: ContentLayer[],
    category: VideoContentCategoryParentInfos,
    parentCategoryName: string,
  ): ContentLayer[] => {
    const existingLayer = layers.find((layer) => layer.title === parentCategoryName);

    const contentItem = createContentItem(category);

    if (existingLayer) {
      existingLayer.items.push(contentItem);
    } else {
      layers.push({
        title: parentCategoryName,
        isOpen: true,
        items: [contentItem],
      });
    }

    return layers;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (serviceSettings?.usingAutoSendVideo) {
        const uniqueParentCategoryIDs = [
          ...new Set(
            serviceSettings.usingAutoSendVideo
              .map((video) => video._id)
              .filter((id): id is string => id !== undefined),
          ),
        ];

        if (uniqueParentCategoryIDs.length > 0) {
          const data = await fetchVideoContentCategories({
            isTop: false,
            isParent: true,
            'parentCategoryIDs[]': uniqueParentCategoryIDs,
          });
          setCategories(data);
          return;
        }
      }
      setContentPanelLayer(filteredContentPanelItems);
    };
    fetchData();
  }, [serviceSettings]);

  const buildContentPanelItems = (
    categories: VideoContentCategoryParentInfos[],
  ): ContentLayer[] => {
    const layers: ContentLayer[] = [];
    for (const category of categories) {
      for (const parent of category.parentCategoryIDs ?? []) {
        addCategoryToLayer(layers, category, parent.categoryName ?? '');
      }
    }
    return layers;
  };

  useEffect(() => {
    if (contentPanelLayer && contentPanelLayer.length > 0) {
      setContentsPanelOpenMap((prevManualPanelOpenMap) => {
        const newContentsPanelOpenMap = new Map(prevManualPanelOpenMap);
        const subList = contentPanelLayer.flatMap((layer) => layer.items);
        for (const { key } of subList) {
          // 기존 맵에 해당 id가 없을 때만 새로운 값 추가
          newContentsPanelOpenMap.set(key, false);
        }
        return newContentsPanelOpenMap;
      });
    }
    //setting을 넣으면 홈케어 데이터가 세팅 되기 전에 map을 생성해버림.
  }, [contentPanelLayer]);

  const handleClick = ({ title }: { title: string }) => {
    const newContentsPanelOpenMap = new Map<string, boolean>(contentsPanelOpenMap);
    //전체 map을 돌면서 id가 같은 것만 열고 나머지는 닫음
    for (const [key] of newContentsPanelOpenMap) {
      if (key === title) {
        newContentsPanelOpenMap.set(title, !contentsPanelOpenMap.get(title));
      } else {
        newContentsPanelOpenMap.set(key, false);
      }
    }

    setContentsPanelOpenMap(newContentsPanelOpenMap);
  };

  useEffect(() => {
    if (categories.length > 0) {
      const contentPanelItems = buildContentPanelItems(categories);

      setContentPanelLayer([...contentPanelItems, ...filteredContentPanelItems]);
    }
  }, [categories]);

  if (isLoading) return <FullLoading />;

  return (
    <div className='h-full'>
      <div id='manual-container' ref={manualListContainerRef} className='select-none'>
        {contentPanelLayer.map((parentItem, folderIndex) => (
          //주의: 해당 폴더 컴포넌트는 컨텐츠와 공유하고 있음
          <ContentPanelFolder key={parentItem.title} folderIndex={folderIndex}>
            {parentItem.items.map((childItem, itemIndex) => {
              const isOpen = contentsPanelOpenMap.get(childItem.key) ?? false;
              return (
                <ChatContentPanelFolderItem
                  key={childItem.key}
                  id={childItem.key}
                  categoryTitle={childItem.text}
                  folderIndex={folderIndex}
                  itemIndex={itemIndex}
                  isOpen={isOpen}
                  handleClick={handleClick}
                />
              );
            })}
          </ContentPanelFolder>
        ))}
      </div>
    </div>
  );
}
