import { useUpdateBookMark } from '@templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/hooks/use-update-book-mark';
import { useAtomValue } from 'jotai/index';
import { useChatMessageContext } from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/components/ChattingMessage/ChatMessageContext';
import { isBookmarkMapState } from 'web/templates/CustomerChat/states/is-bookmark-map';
import Bookmark from './Bookmark';
import Bookmarkempty from './Bookmarkempty';

export default function BookmarkToggle() {
  const { updateBookMarkAction } = useUpdateBookMark();

  const { _id: messageId, isBookmark: initialBookmark } = useChatMessageContext();

  const bookmarkState = useAtomValue(isBookmarkMapState);

  const isBookmark = bookmarkState[messageId] ?? initialBookmark;

  const handleBookmarkClick = () => {
    updateBookMarkAction(messageId, !isBookmark);
  };

  return isBookmark ? (
    <Bookmark className='cursor-pointer' onClick={handleBookmarkClick} />
  ) : (
    <Bookmarkempty
      className='cursor-pointer opacity-0 transition-opacity duration-200 group-hover:opacity-100'
      onClick={handleBookmarkClick}
    />
  );
}
