import TextInput from 'afterdoc-design-system/components/Atoms/Input/TextInput';
import { useFormContext } from 'react-hook-form';

export default function AccountName() {
  const {
    register,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  return (
    <div className='w-[164px]'>
      <TextInput
        id='name'
        placeholder={'최대 15자'}
        maxLength={15}
        label={{
          children: '이름',
          isRequired: true,
          width: '100%',
          position: 'vertical',
        }}
        hasError={!!errors.name}
        errorText={'이름을 입력해 주세요.'}
        {...register('name', { onChange: () => clearErrors('name') })}
      />
    </div>
  );
}
