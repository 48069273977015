import Toggle from '@afterdoc-design-system/components/Atoms/Toggle/Toggle';
import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';
import TextFieldSelectBoxDropdown from '@afterdoc-design-system/components/Molecules/TextSelect/TextFieldSelectBoxDropdown';
import { useEffect, useRef, useState } from 'react';
import type {
  ApplyingCommonAlgorithmHandlerBodyRequest,
  CommonAlgorithm,
} from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import { useApplyCommonAlgorithm } from 'web/templates/Automation/containers/Dialog/EditCommonAutomationDialog/components/EditCommonAutomationDialogContent/hooks/use-apply-common-algorithm';
import { generateDaysArray } from 'web/templates/Automation/containers/shared/functions/generate-days-array';

interface ConsultationAlgorithmItemProps {
  consultationAlgorithm: Exclude<CommonAlgorithm['consultationAlgorithms'], undefined>[0];
}

export default function ConsultationAlgorithmItem({
  consultationAlgorithm: {
    isCommonAlgorithmEnabled,
    sendingDaysAfterStart,
    _id: algorithmId,
    name,
  },
}: ConsultationAlgorithmItemProps) {
  const { hospitalID } = useSelectedHospitalInfo();
  const [isChecked, setIsChecked] = useState(true);
  const [isDropdownToggled, setIsDropdownToggled] = useState(false);

  const dropdownOptions = useRef<string[]>(
    generateDaysArray({
      endDay: 365,
      addUnit: '일차',
    }),
  );

  const applyCommonAlgorithmMutation = useApplyCommonAlgorithm(algorithmId);

  const createMutationParams = (
    algorithmEnabled: boolean,
    days: number,
  ): ApplyingCommonAlgorithmHandlerBodyRequest => ({
    consultationAlgorithms: [
      {
        _id: algorithmId,
        sendingDaysAfterStart: days,
        isCommonAlgorithmEnabled: algorithmEnabled,
      },
    ],
    hospitalID,
  });

  const customFocusScrollHandler = (focusedIndex: number) => focusedIndex - 2;
  const customSelectedScrollHandler = (selectedIndex: number) => selectedIndex - 2;

  useEffect(() => {
    setIsChecked(isCommonAlgorithmEnabled ?? false);
  }, [isCommonAlgorithmEnabled]);

  return (
    <div key={algorithmId} className='flex w-full justify-between'>
      <div>
        <div className='py-7 text-Body12 text-black200'>{name}</div>
        <div className='flex items-center gap-10'>
          <Icon name='arrow-right-bottom' size={20} color='black200' />
          <TextFieldSelectBoxDropdown
            width={100}
            options={dropdownOptions.current}
            onSelect={(dropdownIndex) => {
              setIsDropdownToggled(!isDropdownToggled);
              applyCommonAlgorithmMutation.mutate(
                createMutationParams(isChecked, dropdownIndex + 1),
              );
            }}
            disabled={!isChecked}
            selectedIndex={(sendingDaysAfterStart ?? 60) - 1}
            focusedIndex={(sendingDaysAfterStart ?? 60) - 1}
            customFocusScrollHandler={customFocusScrollHandler}
            customSelectedScrollHandler={customSelectedScrollHandler}
          />
          <div className='text-Body12 text-black200'>에 발송합니다.</div>
        </div>
      </div>
      <Toggle
        toggleSize='small'
        checked={isChecked}
        onChange={(e) => {
          setIsChecked(e.target.checked);
          applyCommonAlgorithmMutation.mutate(
            createMutationParams(e.target.checked, sendingDaysAfterStart ?? 60),
          );
        }}
      />
    </div>
  );
}
