import { findUserWithCustomerNumber } from '@apis/Almighty/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import type { SmartdoctorCustomer } from '@shared/components/ALIntegrationEdit/hooks/type';
import { usePatientDetailInfo } from '@shared/hooks/use-get-patient-detail-info';
import { useQuery } from '@tanstack/react-query';
import LayoutBox from 'web/shared/components/LayoutBox/LayoutBox';
import SmartDoctorCustomerMemoContainer from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/SmartDoctor/components/SmartDoctorCustomerMemo/SmartDoctorCustomerMemo.container';
import SmartDoctorReservationHistoryContainer from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/SmartDoctor/components/SmartDoctorReservationHistory/SmartDoctorReservationHistory.container';

export default function SmartDoctorReservationContainer() {
  const { ALCustomerNumber: customerNumber } = usePatientDetailInfo();

  const { data: customer, isLoading } = useQuery({
    queryKey: [QUERY_KEY.findUserWithCustomerNumber, customerNumber],
    queryFn: ({ queryKey }) => findUserWithCustomerNumber(queryKey[1] as string),
    select: (response) => {
      const data = response.data as { customer: SmartdoctorCustomer };
      if (!data.customer || typeof data.customer !== 'object') {
        throw new Error('Invalid customer structure in response');
      }
      return data.customer;
    },
    enabled: !!customerNumber,
  });

  return (
    <div className='flex h-full flex-col gap-16'>
      {/* 스마트닥터 고객메모 */}
      <LayoutBox noStyle>
        <SmartDoctorCustomerMemoContainer isLoading={isLoading} customerMemo={customer?.memo} />
      </LayoutBox>
      {/* 스마트닥터 예약내역 */}
      <LayoutBox noStyle>
        <SmartDoctorReservationHistoryContainer customer={customer} />
      </LayoutBox>
    </div>
  );
}
