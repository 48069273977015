import {
  findALReservationUsers,
  findALReservationsWithCustomerNumber,
  findALUsers,
} from '@apis/Almighty/api-client';
import type {
  ALDepartmentDTO,
  ALReservationDetail,
  ALUserIdAndNameDTO,
} from '@apis/Almighty/types';
import {
  FIND_RESERVATIONS_CUSTOMER_NUMBER,
  FIND_USERS,
  FIND_USERS_RESERVATION,
} from '@apis/Almighty/urls';
import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import type { ApiPatientsElDetailParams } from '@apis/swaggers/swagger-docs';
import { useQuery } from '@tanstack/react-query';
import { populateALReservationInfo } from '@templates/CustomerChat/components/CustomerReservation/functions/populate-al-reservation-Info';
import { useEffect, useState } from 'react';
import { SHARED_UTILS } from 'utils/utils';

interface useAlMightyReservationsProps {
  isALReservation: boolean | null;
  chatRoomParentHServiceID: string;
}

const fetchHServiceDetail = async (param: ApiPatientsElDetailParams) => {
  const { data } = await apiClient.v3.apiPatientsElDetail(param);
  return SHARED_UTILS.api.checkApiResponse(data);
};

export const useAlMightyReservations = ({
  isALReservation,
  chatRoomParentHServiceID,
}: useAlMightyReservationsProps) => {
  const [customerNumber, setCustomerNumber] = useState('');
  const [isALLoading, setIsALLoading] = useState(false);
  const [aLHospitalUserInfo, setALHospitalUserInfo] = useState<{
    departments: ALDepartmentDTO[];
    consultants: ALUserIdAndNameDTO[];
  }>();
  const [aLReservations, setALReservations] = useState<ALReservationDetail[]>([]);

  // HService 전능 고객연결 여부 조회
  const { data: patients, isLoading: fetchingHServiceInfo } = useQuery({
    queryKey: [QUERY_KEY.apiPatientsElDetail, { patientId: chatRoomParentHServiceID }] as const,
    queryFn: ({ queryKey }) => fetchHServiceDetail(queryKey[1]),
    enabled: !!chatRoomParentHServiceID && !!isALReservation,
  });

  // 담당자/ 상담사/ 부서 정보 조회
  const { data: ALHospitalUserInfo, isError } = useQuery({
    queryKey: [FIND_USERS_RESERVATION, FIND_USERS] as const,
    queryFn: async () => {
      const [resUser, resConsultant] = await Promise.all([
        findALReservationUsers(),
        findALUsers('CONSULTANT'),
      ]);
      return { resUser, resConsultant };
    },
    enabled: !!chatRoomParentHServiceID && !!isALReservation,
  });

  // 예약내역 조회
  const {
    data,
    isLoading: fetchingALReservations,
    isError: isALReservationError,
  } = useQuery({
    queryKey: [FIND_RESERVATIONS_CUSTOMER_NUMBER, customerNumber] as const,
    queryFn: ({ queryKey }) => findALReservationsWithCustomerNumber(queryKey[1]),
    select: (response) => {
      if (response && typeof response === 'object' && response?.status === 200 && response.data) {
        const { customerNumber: fetchedCustomerNumber, reservations } = response.data as {
          customerNumber: string;
          reservations: ALReservationDetail[];
        };
        if (fetchedCustomerNumber === customerNumber && aLHospitalUserInfo) {
          const { departments, consultants } = aLHospitalUserInfo;
          return populateALReservationInfo(reservations, departments, consultants);
        }
      }
      return [];
    },
    enabled: !!customerNumber && !!aLHospitalUserInfo,
    staleTime: 0,
    gcTime: 0,
  });

  useEffect(() => {
    setCustomerNumber(patients?.ALCustomerNumber ?? '');
  }, [patients]);

  useEffect(() => {
    if (isError) {
      setALHospitalUserInfo({ departments: [], consultants: [] });
    } else if (ALHospitalUserInfo) {
      const { resUser, resConsultant } = ALHospitalUserInfo;

      const departments =
        resUser?.status === 200 && Array.isArray(resUser.data.departmentDTOs)
          ? resUser.data.departmentDTOs
          : [];
      const consultants =
        resConsultant?.status === 200 && Array.isArray(resConsultant.data.userIdAndNameDTOs)
          ? resConsultant.data.userIdAndNameDTOs
          : [];
      setALHospitalUserInfo({ departments, consultants });
    }
  }, [ALHospitalUserInfo, isError]);

  useEffect(() => {
    setALReservations(data ?? []);
  }, [data]);

  useEffect(() => {
    setIsALLoading(fetchingHServiceInfo || fetchingALReservations);
  }, [fetchingHServiceInfo, fetchingALReservations]);

  return {
    isALLoading,
    isALReservationError,
    aLReservations,
  };
};
