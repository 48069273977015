import ButtonTab from '@afterdoc-design-system/components/Atoms/Tabs/components/ButtonTab';
import Tabs from '@afterdoc-design-system/components/Atoms/Tabs/components/Tabs';
import { selectedMessageIDState } from '@templates/CustomerChat/components/SupportBot/states/selected-message-id';
import BaseLoading from 'afterdoc-design-system/components/Atoms/Loading/BaseLoading';
import { useAtom, useAtomValue } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { useEffect, useState } from 'react';
import { SUPPORT_TABS } from '../../constants/tab';
import { chatRoomIDSelector } from '../../states/selected-chat-room';
import { selectedSupportTabState } from '../ChattingRoom/components/ChattingContainer/components/ChattingContent/states/selected-support-tab';
import AlgorithmGuide from './components/AlgorithmGuide/AlgorithmGuide';
import { useGetAlgorithmGuide } from './components/AlgorithmGuide/hooks/use-get-algorithm-guide';
import BookmarkList from './components/BookmarkList/BookmarkList';

export const SupportBot = () => {
  const { consultationGuide, isLoading } = useGetAlgorithmGuide();

  const resetSelectedMessageID = useResetAtom(selectedMessageIDState);

  const chatRoomID = useAtomValue(chatRoomIDSelector);

  const [selectedTab, setSelectedTab] = useAtom(selectedSupportTabState);

  const [isShowLoading, setIsShowLoading] = useState(false);

  const isConsultationGuideEmpty = consultationGuide === undefined || consultationGuide === '';

  useEffect(() => {
    setIsShowLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    setSelectedTab(!isConsultationGuideEmpty ? SUPPORT_TABS.GUIDE : SUPPORT_TABS.BOOKMARK);
  }, [chatRoomID, isConsultationGuideEmpty]);

  useEffect(() => {
    resetSelectedMessageID();
  }, [chatRoomID]);

  return (
    <div className='flex flex-col gap-10 pb-10'>
      <Tabs value={selectedTab} onChange={setSelectedTab}>
        {Object.values(SUPPORT_TABS).map((value) => (
          <ButtonTab className='border-b border-b-white400' value={value} label={value} key={value}>
            {value}
          </ButtonTab>
        ))}
      </Tabs>
      <div className='relative h-[calc(100vh-652px)]'>
        {selectedTab === SUPPORT_TABS.BOOKMARK && (
          <BookmarkList onChangeLoadingState={setIsShowLoading} />
        )}
        {selectedTab === SUPPORT_TABS.GUIDE && (
          <AlgorithmGuide consultationGuide={consultationGuide} />
        )}
        {isShowLoading && (
          <div className='absolute inset-0 flex-center bg-white50 opacity-40'>
            <BaseLoading />
          </div>
        )}
      </div>
    </div>
  );
};
