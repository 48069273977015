import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';
import { AnimatePresence, motion } from 'framer-motion';

import TagDetailPanel from './components/TagDetailPanel/TagDetailPanel';

interface ContentPanelFolderItemProps {
  id: string;
  childID: string;
  color: string;
  text: string;
  treatmentTagsIds: string[];
  focusedManualTagID: string | null;
  handleClick: ({ id }: { id: string }) => void;
  isItemOpen: boolean;
}

export default function ManualPanelFolderItem({
  childID,
  id,
  color,
  text,
  treatmentTagsIds,
  focusedManualTagID,
  handleClick,
  isItemOpen,
}: ContentPanelFolderItemProps) {
  const isInTreatmentTagsIds = treatmentTagsIds.includes(childID);

  const isInFocusedTag = focusedManualTagID === childID;

  return (
    <AnimatePresence initial={false}>
      {
        <motion.ul
          key={`motion-ul-${id}`}
          initial={isItemOpen ? { height: 0, opacity: 0 } : { height: 'auto', opacity: 1 }}
          animate={{ height: 'auto', opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}
          transition={{ duration: 0.4 }}
          className='overflow-hidden'>
          <li className='px-8 py-4'>
            <div
              onClick={() => {
                handleClick({ id: childID });
              }}
              className={`flex w-full cursor-pointer items-center justify-start gap-4 rounded-r6 py-11 pr-20 pl-56 font-regular text-Body12 text-black500 ${
                isInTreatmentTagsIds ? 'bg-purple100' : isInFocusedTag ? 'bg-blue50' : 'bg-white50'
              }`}>
              <Icon
                name={isItemOpen ? 'chevron-down' : 'chevron-right'}
                size={16}
                color='black200'
              />
              <Icon name='tag' size={20} customColor={color} />

              {text}
            </div>
          </li>
          {isItemOpen && <TagDetailPanel treatmentTagId={childID} />}
        </motion.ul>
      }
    </AnimatePresence>
  );
}
