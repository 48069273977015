interface generateDaysArrayProps {
  startDay?: number;
  endDay: number;
  addUnit?: string;
}

export const generateDaysArray = ({
  startDay = 1,
  endDay,
  addUnit = '일',
}: generateDaysArrayProps) => {
  const days = [];

  for (let i = startDay; i <= endDay; i++) {
    days.push(`${i}${addUnit}`);
  }

  return days;
};
