import Divider from '@afterdoc-design-system/components/Atoms/Divider/Divider';
import SearchInput from '@afterdoc-design-system/components/Atoms/Input/SearchInput';
import LoadingSpinner from '@afterdoc-design-system/components/Atoms/Loading/LoadingSpinner';
import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';
import { dialogService } from '@afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import { calculatePrice } from '@templates/CustomerChat/components/Manual/components/ContentsList/components/ContentsPanelFolderItem/components/ChatContentPanelFolderItem/components/HomecareVideoSelect/functions/calculate-price';
import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';
import { type MouseEvent, useState } from 'react';
import EditorViewer from 'web/shared/components/EditorViewer/EditorViewer';
import { useSearchInput } from 'web/templates/CustomerChat/components/ChattingList/hooks/use-search-input';
import { MessageDialogContent } from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/components/ChattingMessage/Message/components/MessageDialogContent';
import { useConditionalChatData } from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/hooks/use-conditional-chat-data';
import { useChatRoomInfoOne } from 'web/templates/CustomerChat/components/ChattingRoom/hooks/use-chatroom-info-one';
import { selectedMessageIDState } from 'web/templates/CustomerChat/components/SupportBot/states/selected-message-id';
import { isDisabledChat } from 'web/templates/CustomerChat/functions/is-disabled-Chat';
import { useChatTopPatient } from 'web/templates/CustomerChat/hooks/use-chat-top-patient';
import { usePatient } from 'web/templates/CustomerChat/hooks/use-patient';
import {
  chatRoomHserviceIDSelector,
  chatRoomIDSelector,
  roomTypeSelector,
} from 'web/templates/CustomerChat/states/selected-chat-room';
import { NoData } from '../../../NoData';
import { getDetailEvent } from './apis/get-detail-event';
import { getDetailHAftercare } from './apis/get-detail-haftercare';
import { getDetailNotice } from './apis/get-detail-notice';
import { postSendEvent } from './apis/post-send-event';
import { postSendHomecareProduct } from './apis/post-send-homecare-product';
import { postSendNotice } from './apis/post-send-notice';
import { postSendVideos } from './apis/post-send-videos';
import EventDevicePopup from './components/EventDevicePopup';
import { handleVideoDialogOpen } from './functions/handle-video-dialog-open';
import { useEventList } from './hooks/use-event-list';
import { useNoticeList } from './hooks/use-notice-list';
import { useVideoList } from './hooks/use-video-list';
import type { EventData, NoticeData, UseDataReturnType, VideoData } from './types';

const MAX_NUM = 3;
const NOTICE_MAX_NUM = 1;

export default function HomecareVideoSelect({
  categoryID,
  categoryTitle,
}: { categoryID: string; categoryTitle: string }) {
  const chatRoomID = useAtomValue(chatRoomIDSelector);
  const chatRoomHserivceID = useAtomValue(chatRoomHserviceIDSelector);
  const { patientId } = usePatient();
  const selectedMessageID = useAtomValue(selectedMessageIDState);
  const { ikakaoInfo } = useChatRoomInfoOne({
    chatRoomID: chatRoomID ?? '',
  });
  const roomType = useAtomValue(roomTypeSelector);
  const hserviceID = roomType === 'ikakao' ? chatRoomHserivceID : (patientId ?? '');

  const { iconSet } = useChatTopPatient();

  const { refreshAll } = useConditionalChatData(selectedMessageID, chatRoomID);

  const [selectedIDs, setSelectedIDs] = useState<string[]>([]);

  const { inputValue, keyword, handleChange, handleKeyDown, handleDelete } = useSearchInput();

  const isNoticeOrEvent =
    categoryTitle === '공지사항' || categoryTitle === '이벤트' || categoryTitle === '홈케어 상품';

  const useDataHook = (categoryTitle: string) => {
    switch (categoryTitle) {
      case '홈케어 상품':
      case '이벤트':
        return useEventList({ keyword, categoryID, categoryTitle }) as UseDataReturnType<EventData>;
      case '공지사항':
        return useNoticeList({ keyword }) as UseDataReturnType<NoticeData>;
      default:
        return useVideoList({ keyword, categoryID }) as UseDataReturnType<VideoData>;
    }
  };

  const { data, isLoading } = useDataHook(categoryTitle);

  const toggleSelectItem = (e: MouseEvent<HTMLDivElement>, id: string) => {
    e.preventDefault();

    setSelectedIDs((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id],
    );
  };

  const sendContentsToChat = async () => {
    if (!chatRoomID) return;
    if (!patientId) return;
    switch (categoryTitle) {
      //데이터 푸쉬로만 등장
      case '홈케어 상품': {
        const res = await postSendHomecareProduct({
          chatRoomID,
          hserviceID,
          haftercareID: selectedIDs[0],
        });
        res && refreshAll();
        res && setSelectedIDs([]);

        break;
      }
      case '이벤트': {
        const res = await postSendEvent({
          chatRoomID,
          hserviceID: patientId,
          heventID: selectedIDs?.[0],
        });

        res && refreshAll();
        res && setSelectedIDs([]);

        break;
      }
      case '공지사항': {
        const res = await postSendNotice({
          chatRoomID,
          hserviceID: patientId,
          hnoticeID: selectedIDs[0],
        });

        res && refreshAll();
        res && setSelectedIDs([]);

        break;
      }

      //통증 및 재활 영상(홈케어 영상)은 하위 카테고리(categoryTitle)가 목, 어깨 이런거라 default로 퉁쳐서 처리.
      default: {
        const res = await postSendVideos({
          chatRoomID,
          hserviceID,
          vchIDs: selectedIDs,
        });

        res && refreshAll();
        res && setSelectedIDs([]);
      }
    }
  };

  const isData = data.length > 0;

  const isChatRoomNotNull = chatRoomID;

  const isSendButtonDisabled = isDisabledChat({ roomType, ikakaoInfo, iconSet });

  return (
    <div className='flex flex-col'>
      <div className='p-10'>
        <SearchInput
          placeholder='키워드 혹은 제목으로 검색'
          value={inputValue}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onDelete={handleDelete}
        />
      </div>
      {isData && isChatRoomNotNull && !!selectedIDs.length && (
        <div className='flex h-40 flex-center flex-row justify-between bg-white100 px-20 py-9'>
          <span className='text-Body10 text-black500'>
            {`${selectedIDs.length}개 선택(최대 ${isNoticeOrEvent ? NOTICE_MAX_NUM : MAX_NUM}개)`}
          </span>
          <ContainedButton
            onClick={sendContentsToChat}
            buttonSize={'small'}
            disabled={isSendButtonDisabled}>
            전송
          </ContainedButton>
        </div>
      )}

      <div className='mx-20 flex flex-wrap justify-between'>
        {isLoading && (
          <div className='flex h-[222px] w-full flex-center'>
            <LoadingSpinner />
          </div>
        )}
        {!isData && !isLoading && <NoData />}
        {isData &&
          data.map((item) => {
            const isSelected = selectedIDs.some((id) => id === item.id);
            const isSelectable =
              selectedIDs.length < (isNoticeOrEvent ? NOTICE_MAX_NUM : MAX_NUM) || isSelected;
            const isAbleSelect = isChatRoomNotNull && isSelectable;

            switch (categoryTitle) {
              case '홈케어 상품':
              case '이벤트': {
                const eventData = item as EventData;
                const { id, url, title, price, salePrice, saleRate, saleType } = eventData;
                return (
                  <div key={id} className='my-10 flex w-[170px] flex-col'>
                    <div
                      key={url}
                      onClick={(e) => isAbleSelect && toggleSelectItem(e, id)}
                      className={`relative inline-block h-[170px] w-[170px] ${isAbleSelect && 'cursor-pointer'} overflow-hidden border ${
                        isSelected ? 'border-blue500' : 'border-white600'
                      } ${!isAbleSelect && 'opacity-50'}`}
                      style={{ borderRadius: '10px' }}>
                      {isAbleSelect && (
                        <div className='absolute top-2 left-2 h-24 w-24'>
                          {isSelected ? (
                            <Icon name='checkbox-marked' size={24} color='blue500' />
                          ) : (
                            <Icon name='checkbox-blank-outline' size={24} color='white600' />
                          )}
                        </div>
                      )}
                      <img
                        src={url ?? ''}
                        alt='이벤트 이미지'
                        className='h-[170px] w-[170px] rounded-r10 object-cover'
                      />
                      <div
                        className='absolute right-2 bottom-2 flex h-32 w-32 flex-center cursor-pointer bg-black200'
                        onClick={async (e) => {
                          e.stopPropagation();

                          const dynamicFunction =
                            categoryTitle === '이벤트' ? getDetailEvent : getDetailHAftercare;
                          const {
                            title,
                            content,
                            fileIDs,
                            description,
                            isLimitedTime,
                            endDate,
                            type,
                            saleType,
                            salePrice,
                            saleRate,
                            price,
                          } = await dynamicFunction(id);
                          const detailInfoImageSrc = fileIDs?.[0]?.paths?.[0];

                          dialogService.push(
                            <MessageDialogContent isDisabledScrollBar={true}>
                              <EventDevicePopup
                                title={title ?? ''}
                                description={description ?? ''}
                                content={content ?? ''}
                                isLimitedTime={isLimitedTime ?? false}
                                endDate={endDate ?? ''}
                                thumbnailSrc={url ?? ''}
                                type={type ?? ''}
                                detailInfoImageSrc={detailInfoImageSrc ?? ''}
                                saleType={saleType ?? ''}
                                salePrice={salePrice ?? 0}
                                saleRate={saleRate ?? 0}
                                price={price ?? 0}
                              />
                            </MessageDialogContent>,
                            {
                              hasCloseButton: true,
                              titleProps: {
                                title: '상세내용',
                              },
                            },
                          );
                        }}
                        style={{ borderRadius: '6px' }}>
                        <Icon name='magnify-scan' size={20} color='white50' />
                      </div>
                    </div>
                    <div className='my-10'>
                      <span className='line-clamp-1 h-36 text-Header12 text-black500'>{title}</span>
                      <span className='line-clamp-1 text-Body11 text-black200 line-through'>
                        {price.toLocaleString()}
                      </span>
                      <span className='line-clamp-1 text-Header12 text-blue500'>
                        {calculatePrice(price, salePrice, saleRate, saleType)}
                      </span>
                    </div>
                  </div>
                );
              }
              case '공지사항': {
                const { id, title, createdAt } = item as NoticeData;
                const activeColor = isSelectable ? 'black500' : 'disabled';
                const activeTextColor = `text-${activeColor}`;
                return (
                  <div key={id} className='mt-10 flex h-36 w-full flex-col-center'>
                    <li className='flex w-full flex-row justify-between gap-10'>
                      <div className='flex flex-center flex-grow gap-10'>
                        {isChatRoomNotNull && (
                          <div
                            className='cursor-pointer'
                            onClick={(e) => isSelectable && toggleSelectItem(e, id)}>
                            {isSelected ? (
                              <Icon name='checkbox-marked' size={24} color='blue500' />
                            ) : (
                              <Icon name='checkbox-blank-outline' size={24} color='white600' />
                            )}
                          </div>
                        )}
                        <div className={`line-clamp-1 flex-grow text-Header12 ${activeTextColor}`}>
                          {title}
                        </div>
                      </div>
                      <div
                        className='flex flex-center flex-none cursor-pointer'
                        onClick={async () => {
                          const { title, content, createdAt } = await getDetailNotice(id);
                          dialogService.push(
                            <MessageDialogContent
                              displayCreated={dayjs(createdAt).format('YYYY-MM-DD')}
                              title={title ?? ''}>
                              <div className='w-full'>
                                <EditorViewer htmlContent={content ?? ''} />
                              </div>
                            </MessageDialogContent>,
                            {
                              hasCloseButton: true,
                              titleProps: {
                                title: '공지사항 미리보기',
                              },
                            },
                          );
                        }}>
                        <span className={`text-Body10 ${activeTextColor}`}>
                          {dayjs(createdAt).format('YYYY-MM-DD')}
                        </span>
                        <Icon name='chevron-right' size={24} color={activeColor} />
                      </div>
                    </li>
                    <Divider type='line' className='mt-10' />
                  </div>
                );
              }
              //통증 및 재활 영상(홈케어 영상)은 하위 카테고리(categoryTitle)가 목, 어깨 이런거라 default로 퉁쳐서 처리.
              default: {
                const { id, title, subtitle, url } = item as VideoData;

                return (
                  <div key={id} className='my-10 flex w-[170px] flex-col'>
                    <div
                      key={url}
                      onClick={(e) => isAbleSelect && toggleSelectItem(e, id)}
                      className={`relative inline-block h-96 w-[170px] ${isAbleSelect && 'cursor-pointer'} overflow-hidden border ${
                        isSelected ? 'border-blue500' : 'border-white600'
                      } ${!isAbleSelect && 'opacity-50'}`}
                      style={{ borderRadius: '10px' }}>
                      {isAbleSelect && (
                        <div className='absolute top-2 left-2 h-24 w-24 '>
                          {isSelected ? (
                            <Icon name='checkbox-marked' size={24} color='blue500' />
                          ) : (
                            <Icon name='checkbox-blank-outline' size={24} color='white600' />
                          )}
                        </div>
                      )}
                      <img
                        src={url ?? ''}
                        alt='콘텐츠 동영상'
                        className='h-96 w-[170px]'
                        style={{ borderRadius: '10px' }}
                      />
                      <div
                        className='absolute right-2 bottom-2 flex h-32 w-32 flex-center cursor-pointer bg-black200'
                        onClick={(e) => handleVideoDialogOpen(id, title, e)}
                        style={{ borderRadius: '6px' }}>
                        <Icon name='magnify-scan' size={20} color='white50' />
                      </div>
                    </div>
                    <div className='my-10'>
                      <span className='line-clamp-1 text-Body11 text-black200'>{subtitle}</span>
                      <span className='line-clamp-2 h-36 text-Body13 text-black700'>{title}</span>
                    </div>
                  </div>
                );
              }
            }
          })}
      </div>
    </div>
  );
}
