import dayjs from 'dayjs';

type ViewerChildProps = {
  name?: string;
  jobType?: string;
  filename?: string;
  sentAt?: string;
};

export default function ViewerChild({ name, jobType, filename, sentAt }: ViewerChildProps) {
  return (
    <div className='flex flex-grow items-center gap-10 text-white50'>
      {name && <div className='text-Header16 text-white50'>{name}</div>}
      {jobType && <div className='text-Body13 text-white400'>{jobType}</div>}
      {filename && <div className='text-Body12 text-white600'>{filename}</div>}
      {sentAt && (
        <div className='text-Body12 text-white600'>{dayjs(sentAt).format('YYYY-MM-DD HH:mm')}</div>
      )}
    </div>
  );
}
