// Define the type for payload items
type Payload = { [key: string]: string | null | number | boolean | undefined };

const makeNullValuToHypen = (value: string | null | number | boolean | undefined) => {
  if (value === null) {
    return '-';
  }

  return value;
};

// Define the display functions
const displayIsFirstVisit = (payload: Payload) => {
  const { isFirstVisit } = payload;
  return isFirstVisit ? '초' : '재';
};

const displayName = (payload: Payload) => {
  const { name } = payload;
  if (typeof name !== 'string') return '';

  return name.length > 25 ? `${name.slice(0, 25)}...` : name;
};

const displayChartNumber = (payload: Payload) => {
  const { chartNumber } = payload;
  if (chartNumber) {
    const chartNumberString = chartNumber.toString();
    const chartNumberStringLimit =
      chartNumberString.length > 8 ? `${chartNumberString.slice(0, 5)}...` : chartNumberString;
    return `(${chartNumberStringLimit})`;
  }

  return '(-)';
};

const displayGenderAndBirthdate = (payload: Payload) => {
  const { gender, birthdate } = payload;
  if (gender && birthdate) {
    const genderText = makeNullValuToHypen(gender) === 'MALE' ? '남' : '여';
    return `${genderText}/${birthdate}`;
  }

  return '-/-';
};
const displayCountry = (payload: Payload) => {
  const { koreanCountryName, internationalDialingCodes } = payload;
  return `${koreanCountryName}(${internationalDialingCodes})`;
};

const displayNationalityKoreanCountryName = (payload: Payload) => {
  const { nationalityKoreanCountryName } = payload;
  if (!nationalityKoreanCountryName) return '-';

  return `${nationalityKoreanCountryName?.toString().slice(0, 5)}...`;
};

const displayPhoneNumber = (payload: Payload) => {
  const { phoneNumber, internationalDialingCodes } = payload;
  if (phoneNumber && internationalDialingCodes) {
    if (typeof phoneNumber !== 'string' || typeof internationalDialingCodes !== 'string')
      return '없음';
    if (phoneNumber.startsWith(internationalDialingCodes)) {
      const localNumber = phoneNumber.slice(internationalDialingCodes.length);
      const formattedNumber = `0${localNumber.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3')}`;
      return formattedNumber;
    }
    return phoneNumber;
  }
  return '-';
};

// Initialize the map with display functions
const infoDisplayMap = new Map<string, (payload: Payload) => string>([
  ['isFirstVisit', displayIsFirstVisit],
  ['chartNumber', displayChartNumber],
  ['name', displayName],
  ['genderAndBirthdate', displayGenderAndBirthdate],
  ['country', displayCountry],
  ['phoneNumber', displayPhoneNumber],
  ['nationalityKoreanCountryName', displayNationalityKoreanCountryName],
]);

// The makeInfoDisplay function
export const makePatientInfoStringDisplay = ({
  type,
  payload,
}: { type: string; payload: Payload }) => {
  const displayFunction = infoDisplayMap.get(type);

  if (displayFunction) {
    return displayFunction(payload);
  }
};
