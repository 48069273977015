import axios from 'axios';
import { API_URL } from 'web/config/url';
import swaggerDocs from '../../../../../swagger.json';

const getSwaggerPaths = (swaggerDocs: { paths: Record<string, unknown> }) => {
  return Object.keys(swaggerDocs.paths);
};

const swaggerPaths = getSwaggerPaths(swaggerDocs);

const isSwaggerPath = (url: string, swaggerPaths: string[]) => {
  return swaggerPaths.some((path) => url.includes(path));
};

const axiosConfig = {
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
};

const axiosInstance = axios.create(axiosConfig);

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');

    if (token) {
      config.headers.Authorization = JSON.parse(token);
    }

    // 현재 URL의 origin을 가져와서 baseURL로 설정
    const currentOrigin = window.location.origin;

    // Swagger 문서에 경로가 정의되어 있는지 검사
    if (isSwaggerPath(config.url ?? '', swaggerPaths)) {
      config.baseURL = API_URL;
    } else {
      config.baseURL = currentOrigin;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export { axiosInstance };
