import NoData from 'afterdoc-design-system/components/Molecules/NoData/NoData';

interface ChatRoomIDNullProps {
  minWidth: number;
}

export default function ChatRoomIDNull({ minWidth }: ChatRoomIDNullProps) {
  return (
    <div
      className='h-full w-full flex-center flex-col gap-16 bg-blue50'
      style={{ minWidth: `${minWidth}px` }}>
      <NoData
        iconProps={{
          name: 'warning',
          size: 48,
          color: 'blue100',
        }}
        title='상담 고객을 선택해주세요.'
        subTitle='고객을 선택하여 상담을 진행할 수 있습니다.'
      />
    </div>
  );
}
