import ContainedButton from '@afterdoc-design-system/components/Atoms/Button/ContainedButton';
import Divider from '@afterdoc-design-system/components/Atoms/Divider/Divider';
import Radio from '@afterdoc-design-system/components/Atoms/Radio/Radio';
import { replaceTemplateVariables } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/components/PhoneView/functions/replace-template-variables';

interface DisplaySurveyProps {
  messageSurvey:
    | {
        question?: string;
        answers?: string[];
      }
    | undefined;
}

export default function DisplaySurvey({ messageSurvey }: DisplaySurveyProps) {
  const noSurveyContent =
    !messageSurvey ||
    (messageSurvey.answers?.every((answer) => !answer.length) && !messageSurvey.question?.length);

  if (noSurveyContent) {
    return <NoSurveyContent />;
  }

  const { question, answers } = messageSurvey;

  return (
    <div className='rounded-[12px] bg-white50 shadow-modal'>
      <div className='rounded-tl-[8px] rounded-tr-[8px] bg-blue400 p-8 text-Header12 text-white50'>
        설문
      </div>
      <div className='flex flex-col break-all px-12 pb-8'>
        <span className='whitespace-pre-wrap pt-8 text-Body14 text-black800'>
          {replaceTemplateVariables(question)}
        </span>
        <Divider type='line' direction='horizontal' className='my-10' />
        {answers?.map((answer, index) => (
          <div className='flex items-center gap-4' key={index}>
            <Radio />
            <div className='whitespace-pre-wrap pb-6 text-Body14 text-black800'>
              {index + 1}.{answer ?? '내용을 입력해 주세요.'}
            </div>
          </div>
        ))}
        <ContainedButton disabled={true} btnColor='blue' className='mt-10 w-full'>
          응답 제출
        </ContainedButton>
      </div>
    </div>
  );
}

const NoSurveyContent = () => (
  <div className='rounded-[12px] bg-white50 shadow-modal'>
    <div className='rounded-tl-[8px] rounded-tr-[8px] bg-blue400 p-8 text-Header12 text-white50'>
      설문
    </div>
    <div className='flex flex-col px-12 pb-8 '>
      <span className='pt-8 text-Body14 text-black800'>내용을 입력해 주세요.</span>
      <Divider type='line' direction='horizontal' className='my-10' />
      {Array.from({ length: 2 }).map((_, index) => (
        <div className='flex items-center gap-4' key={index}>
          <Radio />
          <div className='pb-6 text-Body14 text-black800'>{index + 1}.내용을 입력해 주세요.</div>
        </div>
      ))}
      <ContainedButton disabled={true} btnColor='blue' className='mt-10 w-full'>
        응답 제출
      </ContainedButton>
    </div>
  </div>
);
