import { useUserInfo } from '@shared/hooks/use-user-info';
import { FileUploadController } from '@shared/utils/fileUpload/FileUploadController';
import type { FileUploadResponse } from '@shared/utils/fileUpload/types/types';
import { validatePopupParams } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/functions/validate-popup-params';
import { useHospitalPopup } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/hooks/use-hospital-popup';
import { popupContentTempFileState } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/state/popup-content-temp-file-state';
import { popupCreateOrUpdateParamState } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/state/popup-create-or-update-param-state';
import { popupEditErrorState } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/state/popup-edit-error-state';
import { popupTargetTypeState } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/state/popup-target-type-state';
import { contentEditState } from '@templates/Content/containers/ContentManager/states/content-edit-state';
import type { UploadImageFile } from '@templates/Content/containers/ContentManager/types/upload-image-file';
import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';
import { fullDimmedLoadingService } from 'afterdoc-design-system/components/Atoms/Loading/FullDimmedLoading/FullDimmedLoading.service';
import DownloadFooter from 'afterdoc-design-system/components/Molecules/DownloadFooter/DownloadFooter';
import { modalService } from 'afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { useAtom, useAtomValue, useSetAtom } from 'jotai/index';
import { useEffect, useState } from 'react';

interface PopupManagePageFooterProps {
  isModified: boolean;
}

const showDeleteConfirmation = (onConfirm: () => void) => {
  modalService.defaultWarning({
    title: '팝업을 삭제할까요?',
    contents: '삭제한 팝업은 복구할 수 없습니다.',
    onConfirm,
  });
};

const uploadImage = async (
  userId: string,
  tempFile: UploadImageFile | null,
  callback: (fileID: string) => void,
): Promise<void> => {
  if (!tempFile) return;

  return new Promise<void>((resolve, reject) => {
    const onComplete = (result: FileUploadResponse) => {
      if (result.code !== 0 || !result.data) {
        return reject(new Error('Image upload failed'));
      }

      const file = result.data[0];
      callback(file._id);
      resolve();
    };

    FileUploadController.uploadFiles({
      files: [tempFile.file],
      userID: userId,
      callback: onComplete,
    });
  });
};

export default function PopupManagePageFooter({ isModified }: PopupManagePageFooterProps) {
  const { userId } = useUserInfo();
  const { createPopupAction, updatePopupAction, removePopupAction } = useHospitalPopup();

  const { authorizationTypeID } = useUserInfo();

  const [contentEdit, setContentEdit] = useAtom(contentEditState);
  const [popupCreateOrUpdateParam, setPopupCreateOrUpdateParam] = useAtom(
    popupCreateOrUpdateParamState,
  );

  const popupTargetType = useAtomValue(popupTargetTypeState);
  const popupContentTempFile = useAtomValue(popupContentTempFileState);

  const setPopupEditError = useSetAtom(popupEditErrorState);

  const [isPrepared, setIsPrepared] = useState(false);

  const handleEditEnd = () => setContentEdit({ isEditing: false });

  const handleConfirm = async () => {
    try {
      const errors = validatePopupParams(
        popupCreateOrUpdateParam,
        popupTargetType,
        popupContentTempFile,
      );
      if (Object.keys(errors).length > 0) {
        setPopupEditError((prev) => ({ ...prev, ...errors }));
        return false;
      }

      fullDimmedLoadingService.on();

      if (popupCreateOrUpdateParam.popupType === 'image') {
        await uploadImage(userId, popupContentTempFile, (fileID) => {
          setPopupCreateOrUpdateParam((prev) => ({ ...prev, fileIDs: [fileID] }));
        });
      }

      setIsPrepared(true);
    } catch (error) {
      console.error('Error during upload:', error);
      fullDimmedLoadingService.off();
    }
  };

  const handleCancel = () => {
    if (isModified) {
      modalService.defaultWarning({
        onConfirm: () => {
          handleEditEnd();
          modalService.pop();
        },
      });
      return;
    }
    handleEditEnd();
  };

  const handleRemove = () => {
    if (!contentEdit.id) return;
    showDeleteConfirmation(() => {
      removePopupAction(contentEdit.id || '', handleEditEnd);
    });
  };

  const handleErrorCallback = () => {
    setIsPrepared(false);
  };

  useEffect(() => {
    if (isPrepared) {
      if (contentEdit.id) {
        updatePopupAction(popupCreateOrUpdateParam, handleEditEnd, handleErrorCallback);
      } else {
        createPopupAction(popupCreateOrUpdateParam, handleEditEnd, handleErrorCallback);
      }
    }
  }, [isPrepared]);

  const customLeftButton = contentEdit.id && authorizationTypeID?.canDeleteContent && (
    <ContainedButton btnColor='red' onClick={handleRemove}>
      삭제
    </ContainedButton>
  );

  const customRightButton = (
    <div className='flex gap-10'>
      <ContainedButton btnColor='transparent' onClick={handleCancel}>
        취소
      </ContainedButton>
      <ContainedButton
        btnColor='blue'
        onClick={handleConfirm}
        disabled={!authorizationTypeID?.canUpsertContent}>
        저장
      </ContainedButton>
    </div>
  );

  return (
    <DownloadFooter customLeftButton={customLeftButton} customRightButton={customRightButton} />
  );
}
