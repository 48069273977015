import Checkbox from '@afterdoc-design-system/components/Atoms/Checkbox/Checkbox';
import Scrollbar from '@afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import FilledTag from '@afterdoc-design-system/components/Atoms/Tag/FilledTag';
import { SHARED_UTILS } from '@shared-utils/utils';
import { useSuspenseQuery } from '@tanstack/react-query';
import { Fragment, forwardRef, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { ApiServiceSettingsElCountriesParams } from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import { COUNTRY_LIMIT } from 'web/templates/CustomerManagement/constants/country-limit';
import { filterWrapperClassName } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementEditableTableHeader/constants/class';
import type { EditableHeaderFilterState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/states/editable-header-filter';
import type { ReadOnlyHeaderFilterState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementReadOnlyTable/states/read-only-header-filter';
import type { UnTaggedHeaderFilterState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/UnTaggedCustomerManagement/containers/UnTaggedCustomerManagementEditableTable/states/editable-header-filter';

const fetchCountriesList = async (params: ApiServiceSettingsElCountriesParams) => {
  const response = await apiClient.v3.apiServiceSettingsElCountries(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};
const NationalityFilter = forwardRef<HTMLDivElement>((_, ref) => {
  const { hospitalID } = useSelectedHospitalInfo();

  const { data: countriesList } = useSuspenseQuery({
    queryKey: [
      QUERY_KEY.apiServiceSettingsElCountries,
      { hospitalID, limit: COUNTRY_LIMIT },
    ] as const,
    queryFn: ({ queryKey }) => fetchCountriesList(queryKey[1]),
  });

  const { setValue, watch } = useFormContext<
    EditableHeaderFilterState | ReadOnlyHeaderFilterState | UnTaggedHeaderFilterState
  >();
  const nationalityId = watch('nationality');

  const [checkState, setCheckState] = useState<{ [nationalityId: string]: boolean } | undefined>(
    () => {
      if (!nationalityId) return undefined;
      const initialState: { [nationalityId: string]: boolean } = {};
      for (const cur of nationalityId.split(',')) {
        initialState[cur] = true;
      }
      return initialState;
    },
  );

  const nationalities = useMemo(() => {
    return countriesList.map((country) => ({
      nationalityId: country.countryId,
      name: country.koreanCountryName,
    }));
  }, [countriesList]);

  const handleCheckboxChange = (nationalityId: string) => {
    const newState = { ...checkState, [nationalityId]: !checkState?.[nationalityId] };
    setCheckState(newState);

    const selectedNationalities = Object.keys(newState).filter((key) => newState[key]);

    if (
      selectedNationalities.length === 0 ||
      selectedNationalities.length === nationalities?.length
    ) {
      setValue('nationality', undefined);
    } else {
      setValue('nationality', selectedNationalities.join(','));
    }
  };

  return (
    <div className={`${filterWrapperClassName} h-[200px] w-[300px]`} ref={ref}>
      <Scrollbar disabledX>
        <div className='p-16'>
          <div className='mb-10 text-Caption9 text-black200'>
            *체크한 값에 해당하는 고객만 표시됩니다.
          </div>
          {nationalities?.map((nationality) => (
            <Fragment key={nationality.nationalityId}>
              {nationality.nationalityId && (
                <Checkbox
                  key={nationality.nationalityId}
                  label={
                    <FilledTag className='cursor-pointer' tagSize='small' maxTextLength={25}>
                      {nationality.name}
                    </FilledTag>
                  }
                  checked={!!checkState?.[nationality.nationalityId]}
                  onChange={() => {
                    if (!nationality.nationalityId) return;
                    handleCheckboxChange(nationality.nationalityId);
                  }}
                />
              )}
            </Fragment>
          ))}
        </div>
      </Scrollbar>
    </div>
  );
});

export default NationalityFilter;
