import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import type { ApiServiceSettingsElFindOneParams } from '@apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from '@shared/hooks/use-selected-hospital-info';
import { useSuspenseQuery } from '@tanstack/react-query';
import ChannelIntegrationItem from '@templates/HospitalSetting/containers/ChannelIntegration/components/ChannelIntegrationItem';
import ChannelIntegrationTitle from '@templates/HospitalSetting/containers/ChannelIntegration/components/ChannelIntegrationTitle';
import IntegrationFormWrapper from '@templates/HospitalSetting/containers/ChannelIntegration/components/IntegrationFormWrapper/IntegrationFormWrapper';
import { CHANNEL_INTEGRATION_LIST } from '@templates/HospitalSetting/containers/ChannelIntegration/constants/channel-intergration-list';
import { kakaoConsultFormErrorState } from '@templates/HospitalSetting/containers/ChannelIntegration/states/kakao-consult-form-error-state';
import type { IntegrationChannelItem } from '@templates/HospitalSetting/containers/ChannelIntegration/types/intergration-channel-item';
import { hospitalSettingsModifiedState } from '@templates/HospitalSetting/states/hospital-settings-modified-state';
import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import BaseLoading from 'afterdoc-design-system/components/Atoms/Loading/BaseLoading';
import { modalService } from 'afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { useAtomValue } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { Suspense, useCallback, useEffect, useState } from 'react';
import { SHARED_UTILS } from 'utils/utils';

const fetchServiceSetting = async (params: ApiServiceSettingsElFindOneParams) => {
  const { data } = await apiClient.v3.apiServiceSettingsElFindOne(params);
  return SHARED_UTILS.api.checkApiResponse(data);
};

export interface ServiceLinkageStatus {
  hasLinkage: boolean;
  unlinkedAt?: string;
}

export default function ChannelIntegrationContainer() {
  const { hospitalID } = useSelectedHospitalInfo();

  const resetKakaoConsultFormError = useResetAtom(kakaoConsultFormErrorState);
  const resetHospitalSettingsModified = useResetAtom(hospitalSettingsModifiedState);

  const hospitalSettingsModified = useAtomValue(hospitalSettingsModifiedState);

  const [selectedChannelItem, setSelectedChannelItem] = useState<IntegrationChannelItem>(
    CHANNEL_INTEGRATION_LIST[0],
  );

  const { data: serviceSettings } = useSuspenseQuery({
    queryKey: [QUERY_KEY.apiServiceSettingsElFindOne, { hospitalID }] as const,
    queryFn: ({ queryKey }) => fetchServiceSetting(queryKey[1]),
  });

  const getLinkageStatus = useCallback(
    (item: IntegrationChannelItem): ServiceLinkageStatus => {
      switch (item.linkageType) {
        case 'ALIM_TALK':
          return {
            hasLinkage: serviceSettings.hasKakaoAlimAndFriendTalkLinkage,
            unlinkedAt: serviceSettings.kakaoAlimAndFriendTalkUnlinkedAt,
          };
        case 'CONSULTATION_TALK':
          return {
            hasLinkage: serviceSettings.hasKakaoConsultationTalkLinkage,
            unlinkedAt: serviceSettings.kakaoConsultationTalkUnlinkedAt,
          };
        default:
          return {
            hasLinkage: false,
            unlinkedAt: undefined,
          };
      }
    },
    [serviceSettings],
  );

  const handleSelectChannel = useCallback(
    (item: IntegrationChannelItem) => {
      const confirmAndSelect = () => {
        resetHospitalSettingsModified();
        setSelectedChannelItem(item);
      };

      if (hospitalSettingsModified) {
        modalService.defaultWarning({
          onConfirm: () => {
            confirmAndSelect();
            modalService.pop();
          },
        });
        return;
      }

      confirmAndSelect();
    },
    [hospitalSettingsModified, resetHospitalSettingsModified, setSelectedChannelItem],
  );

  useEffect(() => {
    resetKakaoConsultFormError();
    return () => {
      resetKakaoConsultFormError();
    };
  }, [selectedChannelItem]);

  return (
    <div className='flex min-h-screen flex-col gap-20 bg-white50 px-80 py-40'>
      <ChannelIntegrationTitle />
      <div className='flex h-full flex-grow gap-10'>
        <div className='flex w-[360px] flex-col gap-16 px-20'>
          {CHANNEL_INTEGRATION_LIST.map((item) => {
            return (
              <ChannelIntegrationItem
                key={item.title}
                data={item}
                hasLinkage={getLinkageStatus(item).hasLinkage}
                isSelected={selectedChannelItem?.key === item.key}
                onSelect={() => handleSelectChannel(item)}
              />
            );
          })}
        </div>
        <div>
          <Divider width={1} type={'line'} direction={'vertical'} className='h-full' />
        </div>
        <Suspense
          fallback={
            <div className='flex-center flex-grow'>
              <BaseLoading />
            </div>
          }>
          <IntegrationFormWrapper
            linkageType={selectedChannelItem?.linkageType}
            linkageStatus={getLinkageStatus(selectedChannelItem)}
          />
        </Suspense>
      </div>
    </div>
  );
}
