import TextInput from 'afterdoc-design-system/components/Atoms/Input/TextInput';
import type React from 'react';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

export default function EditAccountId() {
  const {
    register,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const inputValue = watch('id') ?? '';

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearErrors('id');

    let { value } = e.target;
    value = value.replace(/[^a-zA-Z0-9]/g, '');
    setValue('id', value);
  };

  const errorText = useMemo(() => {
    return typeof errors.id?.message === 'string'
      ? errors.id.message
      : '영문, 숫자 3~25자로 설정해 주세요.';
  }, [errors.id]);

  return (
    <div className='flex flex-col gap-4'>
      <TextInput
        placeholder='영문, 숫자 포함 3~25자'
        maxLength={25}
        width={272}
        label={{
          isRequired: true,
          children: '아이디',
          width: 60,
          position: 'horizontal',
        }}
        value={inputValue}
        {...register('id', { onChange: handleChange })}
      />
      {!!errors.id && <span className='ml-70 text-Body10 text-red500'>{errorText}</span>}
    </div>
  );
}
