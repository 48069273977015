import { atom } from 'jotai/index';
import { atomWithReset } from 'jotai/utils';

export type KakaoConsultFormErrorType = {
  requesterName?: boolean;
  toBeContactedPhone?: boolean;
  toBeStartedAt?: boolean;
  searchableChannelId?: boolean;

  // 상담톡
  consultationInformationDay?: boolean;
  consultationInformationTime?: boolean;

  // 알림톡
  channelAdminPhone?: boolean;
  authenticationNumber?: boolean;
  categoryCode?: boolean;
};

export const kakaoConsultFormErrorState = atomWithReset<KakaoConsultFormErrorType>({
  requesterName: false,
  toBeContactedPhone: false,
  toBeStartedAt: false,
  searchableChannelId: false,
  consultationInformationDay: false,
  consultationInformationTime: false,
  channelAdminPhone: false,
  authenticationNumber: false,
  categoryCode: false,
});

const createErrorAtom = (key: keyof KakaoConsultFormErrorType) =>
  atom(
    (get) => get(kakaoConsultFormErrorState)[key] ?? false,
    (get, set, value: boolean) => {
      const currentState = get(kakaoConsultFormErrorState);
      set(kakaoConsultFormErrorState, {
        ...currentState,
        [key]: value || null,
      });
    },
  );

export const requesterNameErrorAtom = createErrorAtom('requesterName');
export const toBeContactedPhoneErrorAtom = createErrorAtom('toBeContactedPhone');
export const toBeStartedAtErrorAtom = createErrorAtom('toBeStartedAt');
export const searchableChannelIdErrorAtom = createErrorAtom('searchableChannelId');

export const consultationInformationDayErrorAtom = createErrorAtom('consultationInformationDay');
export const consultationInformationTimeErrorAtom = createErrorAtom('consultationInformationTime');

export const channelAdminPhoneErrorAtom = createErrorAtom('channelAdminPhone');
export const authenticationNumberErrorAtom = createErrorAtom('authenticationNumber');
export const categoryCodeErrorAtom = createErrorAtom('categoryCode');
