import * as React from "react";
import type { SVGProps } from "react";
const SvgCheckboxPartMarked = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      d="M10 6h28a4 4 0 0 1 4 4v28a4 4 0 0 1-4 4H10a4 4 0 0 1-4-4V10c0-2.22 1.78-4 4-4m23 17H15v3h18z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCheckboxPartMarked;
