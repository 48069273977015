import Scrollbar from '@afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import { Suspense } from 'react';
import LayoutBox from 'web/shared/components/LayoutBox/LayoutBox';
import AutomatedMessageLists from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/components/AutomatedMessageLists/AutomatedMessageLists';
import PhoneView from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/components/PhoneView/PhoneView';
import MessageContentFormContainer from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/containers/MessageContentForm/MessageContentForm.container';

export default function MarketingMessageSettingContainer() {
  return (
    <div className='h-[calc(100vh-100px)] w-full'>
      <Scrollbar>
        <div className='mt-40 mb-20 flex w-full justify-center gap-50'>
          <Suspense>
            <AutomatedMessageLists />
          </Suspense>

          <LayoutBox noStyle>
            <MessageContentFormContainer />
          </LayoutBox>

          <LayoutBox noStyle>
            <PhoneView />
          </LayoutBox>
        </div>
      </Scrollbar>
    </div>
  );
}
