import type { UserNameAndType } from '@apis/swaggers/swagger-docs';
import { contentEditState } from '@templates/Content/containers/ContentManager/states/content-edit-state';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai/index';
import { memo, useEffect, useState } from 'react';

const formatInfo = (id: UserNameAndType | undefined, dateField: string | undefined) => {
  const name = id?.realName || '-';
  let formattedDate = '-';

  const date = dayjs(dateField);
  if (date.isValid()) {
    formattedDate = date.format('YYYY-MM-DD HH:mm');
  }

  return `${formattedDate}/${name}`;
};

function EventHomeCareEditInfo() {
  const contentEdit = useAtomValue(contentEditState);

  const [writerInfo, setWriterInfo] = useState('-');
  const [modifierInfo, setModifierInfo] = useState('-');

  useEffect(() => {
    const { id, data } = contentEdit;
    if (id && (data?.editType === 'event' || data?.editType === 'homeCare')) {
      setWriterInfo(formatInfo(data?.writerID, data?.createdAt));
      setModifierInfo(formatInfo(data?.modifierID, data?.updatedAt));
    }
  }, [contentEdit]);

  return (
    <div className='w-full pb-40'>
      <div className='flex min-h-[18px] gap-4 rounded-r10 bg-white200 px-20 py-10'>
        <div className='flex h-full w-[50%] items-center gap-10 '>
          <span className='flex-shrink-0 text-Header12 text-black200'>최초 작성</span>
          <span className='flex-grow text-Body12 text-black200'>{writerInfo}</span>
        </div>
        <div className='flex h-full w-[50%] items-center gap-10 '>
          <span className='flex-shrink-0 text-Header12 text-black200'>마지막 수정</span>
          <span className='flex-grow text-Body12 text-black200'>{modifierInfo}</span>
        </div>
      </div>
    </div>
  );
}

export default memo(EventHomeCareEditInfo);
