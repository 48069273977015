import type { PatientsField } from '@templates/HospitalSetting/types/PatientsField';

type DataKeys = keyof PatientsField;

interface ToggleItem {
  title: string;
  key: DataKeys;
}

export const TOGGLE_ITEMS: ToggleItem[] = [
  { title: '차트번호', key: 'usingChartNumber' },
  { title: '초/재진', key: 'usingFirstVisitStartDate' },
  // { title: '국가번호', key: 'usingCountryCode' },
  { title: '생년월일', key: 'usingBirthDay' },
  { title: '성별', key: 'usingGender' },
  { title: '치료태그', key: 'usingTreatmentTag' },
  { title: '국적', key: 'usingNationality' },
];
