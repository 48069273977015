import ContainedButton from '@afterdoc-design-system/components/Atoms/Button/ContainedButton';
import Dropdown from '@afterdoc-design-system/components/Atoms/Dropdown/Dropdown';
import { toastService } from '@afterdoc-design-system/components/Atoms/Toast/Toast.service';
import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';
import dayjs from 'dayjs';
import { useAtomValue, useSetAtom } from 'jotai';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import type { MarketingAutomationAPIFormValues } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/components/RegisterMarketingAutomationDialogContent';
import { algorithmModeState } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/states/algorithm-mode';
import {
  selectedContentTabItemState,
  selectedMessageIndexState,
} from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/states/message-form';
import { originMessagesState } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/states/origin-message';

export default function AutomationMessageHeader() {
  const { setValue, getValues } = useFormContext<MarketingAutomationAPIFormValues>();

  const algorithmMode = useAtomValue(algorithmModeState);
  const originMessages = useAtomValue(originMessagesState);
  const selectedMessageIndex = useAtomValue(selectedMessageIndexState);
  const setSelectedContentTabItem = useSetAtom(selectedContentTabItemState);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const canBeSentToFriendTalk =
    originMessages[selectedMessageIndex]?.canBeSentToFriendTalk ?? false;

  const messageLists = getValues('messages');
  const messageName = getValues(`messages.${selectedMessageIndex}.name`);
  const messageSendingDateTime = getValues(`messages.${selectedMessageIndex}.sendingDateTime`);

  return (
    <div className='flex w-full justify-between py-20'>
      <div className='flex items-center gap-10'>
        {canBeSentToFriendTalk && algorithmMode.mode === 'EDIT' && (
          <Icon name='kakaotalk' color='black200' size={20} />
        )}
        <div className='rounded-r6 border border-white600 bg-white50 px-10 py-4 text-Header14'>
          {dayjs(messageSendingDateTime).format('YYYY-MM-DD')}
        </div>
        <div className='max-w-[330px] truncate text-Header16 text-black500'>{messageName}</div>
      </div>
      <div className='relative'>
        <ContainedButton
          onClick={() => {
            setIsDropdownOpen((prev) => !prev);
          }}
          iconProps={{
            name: 'chevron-down',
            size: 20,
            color: 'white800',
          }}
          className='w-[150px]'
          btnColor='secondary'>
          메시지 내용 복사
        </ContainedButton>
        {isDropdownOpen &&
          (messageLists.filter((_, index) => index !== selectedMessageIndex).length > 0 ? (
            <Dropdown
              handleToggle={() => {
                setIsDropdownOpen(false);
              }}
              onSelect={(dropdownIndex) => {
                const filteredMessages = messageLists.filter(
                  (_, index) => index !== selectedMessageIndex,
                );

                const selectedMessage = filteredMessages[dropdownIndex];
                const messageContent = selectedMessage?.content;

                if (messageContent) {
                  setValue(`messages.${selectedMessageIndex}.content`, messageContent);
                }

                if (messageContent?.image) {
                  setSelectedContentTabItem((prev) => ({
                    ...prev,
                    [messageLists[selectedMessageIndex].id]: '이미지',
                  }));
                } else {
                  setSelectedContentTabItem((prev) => ({
                    ...prev,
                    [messageLists[selectedMessageIndex].id]: '텍스트',
                  }));
                }

                toastService.successMsg({
                  text: '전송내용을 불러왔습니다.',
                });
                setIsDropdownOpen(false);
              }}
              width={160}
              options={messageLists
                .filter((_, index) => index !== selectedMessageIndex)
                .map((message) => message.name)}
            />
          ) : (
            <Dropdown
              handleToggle={() => {
                setIsDropdownOpen(false);
              }}
              width={160}
              options={['전송내용이 없습니다.']}
            />
          ))}
      </div>
    </div>
  );
}
