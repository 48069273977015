import { SHARED_UTILS } from '@shared-utils/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';

export const updateTreatmentTags = async (
  params: Parameters<typeof apiClient.v3.apiTreatmentTagsElUpdate>[0],
) => {
  const response = await apiClient.v3.apiTreatmentTagsElUpdate(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export const useMutateTreatmentTags = () => {
  const queryClient = useQueryClient();
  const { hospitalID } = useSelectedHospitalInfo();

  const updateTreatmentTagsMutation = useMutation({
    mutationFn: updateTreatmentTags,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.apiManualsElFolders, { hospitalID }],
      });
    },
  });

  return {
    updateTreatmentTagsMutation,
  };
};
