import { TABLE_HEADERS } from '@templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/SmartDoctor/components/SmartDoctorReservationHistory/constants/headers';

export default function SmartDoctorTableHeader() {
  return (
    <thead>
      <tr>
        {TABLE_HEADERS.map((header) => (
          <th
            key={header}
            className='h-fit whitespace-nowrap border-line-table border-b bg-table px-12 py-10 text-left text-Header14 text-black700'>
            {header}
          </th>
        ))}
      </tr>
    </thead>
  );
}
