import * as React from "react";
import type { SVGProps } from "react";
const SvgQuote = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#7F7F7F"
      d="M11.667 14.166h2.5l1.666-3.333v-5h-5v5h2.5M5 14.166h2.5l1.666-3.333v-5h-5v5h2.5z"
    />
  </svg>
);
export default SvgQuote;
