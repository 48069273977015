import NoData from 'afterdoc-design-system/components/Molecules/NoData/NoData';

type ContentType = 'event' | 'homeCare' | 'notice' | 'popup';

interface ListNoDataProps {
  isSearchQueryEntered: boolean;
  type: ContentType;
}

const commonTextResources = {
  searchResultTitle: '검색 결과가 없습니다.',
};

const textResources = {
  event: {
    ...commonTextResources,
    defaultTitle: '등록된 이벤트가 없습니다.',
  },
  homeCare: {
    ...commonTextResources,
    defaultTitle: '등록된 상품이 없습니다.',
  },
  notice: {
    ...commonTextResources,
    defaultTitle: '등록된 공지사항이 없습니다.',
  },
  popup: {
    ...commonTextResources,
    defaultTitle: '등록된 팝업이 없습니다.',
  },
};

const getTextResources = (type: ContentType, isSearchQueryEntered: boolean) => {
  const { searchResultTitle, defaultTitle } = textResources[type];

  return {
    title: isSearchQueryEntered ? searchResultTitle : defaultTitle,
  };
};

export default function ContentNoData({ isSearchQueryEntered, type }: ListNoDataProps) {
  const { title } = getTextResources(type, isSearchQueryEntered);

  return (
    <div className='h-full flex-center'>
      <NoData
        iconProps={{
          name: 'warning',
          size: 48,
          color: 'white600',
        }}
        title={title}
      />
    </div>
  );
}
