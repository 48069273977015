import type { RegistrationPromptProps } from '@templates/HospitalSetting/containers/CustomerMessageSend/containers/RegistrationPrompt';

export const prompts: RegistrationPromptProps[] = [
  {
    type: 'basic',
    title: '신규고객 등록 가입문자',
    description: '고객을 새로 등록했을 때 발송되는 가입문자의 텍스트를 설정합니다.',
  },
  {
    type: 'reservation',
    title: '예약 가입문자',
    description: '예약시 고객을 새로 등록했을 때 발송되는 가입문자의 텍스트를 설정합니다.',
  },
  {
    type: 'resend',
    title: '재발송 가입문자',
    description:
      '고객이 앱을 삭제하였거나 전화번호 변경으로 인해 가입문자를 재발송할 때 사용되는 문자 텍스트를 설정합니다.',
  },
];
