import UserInfoPanel from '@templates/UserInfoSetting/components/UserInfoPanel/UserInfoPanel';
import UserInfoSettingsPageWrapper from '@templates/UserInfoSetting/components/UserInfoSettingsPageWrapper/UserInfoSettingsPageWrapper';

export default function UserInfoSettingContainer() {
  return (
    <main className='grid h-full grid-cols-[300px,1fr] bg-blueLight'>
      <UserInfoPanel />
      <UserInfoSettingsPageWrapper />
    </main>
  );
}
