import NoData from 'afterdoc-design-system/components/Molecules/NoData/NoData';

interface ContentNoDataMessageProps {
  searchKeyword: string;
}
export default function ContentNoDataMessage({ searchKeyword }: ContentNoDataMessageProps) {
  return (
    <div className='flex-center flex-grow'>
      <NoData
        iconProps={{
          name: 'warning',
          size: 48,
          color: 'white600',
        }}
        title={searchKeyword.length > 0 ? '검색결과가 없습니다.' : '등록된 콘텐츠가 없습니다.'}
        subTitle={
          searchKeyword.length > 0 ? '영상별 검색키워드나 메인 타이틀로 검색할 수 있습니다.' : ''
        }
      />
    </div>
  );
}
