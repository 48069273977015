import type { VideoContentExerciseSetFilePaths } from '@apis/swaggers/swagger-docs';

interface ExerciseSetInfoProps {
  exerciseSets: VideoContentExerciseSetFilePaths[];
}

export default function ExerciseSetInfo({ exerciseSets }: ExerciseSetInfoProps) {
  return (
    <div className='mt-10 flex flex-col gap-10'>
      {exerciseSets.map(({ title, thumbnailFileID, description }) => (
        <div key={title} className='flex h-80 w-full gap-14'>
          <img
            src={thumbnailFileID?.paths?.[0]}
            alt=''
            className='h-full w-80 rounded-r16 object-cover'
          />
          <div className='flex h-full flex-grow flex-col py-10'>
            <span className='line-clamp-2 overflow-hidden break-all text-Header12 text-black500'>
              {title}
            </span>
            <span className='line-clamp-2 overflow-hidden break-all text-Body12 text-black200'>
              {description}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
}
