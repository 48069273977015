import dotsSpinnerAnimationData from 'afterdoc-design-system/assets/lotties/dots-spinner.json';
import messageLoadingAnimationData from 'afterdoc-design-system/assets/lotties/message-loading.json';
import type { LottiePlayer } from 'lottie-web';
import { useEffect, useRef, useState } from 'react';

const getLottiePath = ({ LottieName }: { LottieName: string }) => {
  try {
    switch (LottieName) {
      case 'dotsSpinner':
        return dotsSpinnerAnimationData;
      case 'messageLoading':
        return messageLoadingAnimationData;
      default:
        return null;
    }
  } catch (error) {
    console.error('Lottie file not found', error);
    return null;
  }
};

export const useLottieAnimation = ({ LottieName }: { LottieName: string }) => {
  const animationData = getLottiePath({ LottieName });
  const ref = useRef<HTMLDivElement>(null);
  const [lottie, setLottie] = useState<LottiePlayer | null>(null);

  useEffect(() => {
    import('lottie-web').then((Lottie) => setLottie(Lottie.default));
  }, []);

  useEffect(() => {
    if (lottie && ref.current && animationData) {
      const animation = lottie.loadAnimation({
        container: ref.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData,
      });

      return () => animation.destroy();
    }
  }, [lottie, animationData]);

  return ref;
};
