import EventPushListContainer from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/components/EventList/components/EventListBody/components/EventPushAlarmDialog/EventPushList/EventPushList.container';
import EventPushSelectorContainer from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/components/EventList/components/EventListBody/components/EventPushAlarmDialog/EventPushSelector/EventPushSelector.container';
import { dialogService } from 'afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import { useEffect } from 'react';

interface EventPushAlarmDialogProps {
  hospitalEventID: string;
}

export default function EventPushAlarmDialog({ hospitalEventID }: EventPushAlarmDialogProps) {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.code === 'Escape') {
        dialogService.popById('event-push-alarm-dialog');
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div className='flex h-full w-[1205px] gap-10 px-20'>
      <EventPushSelectorContainer hospitalEventID={hospitalEventID} />
      <EventPushListContainer hospitalEventID={hospitalEventID} />
    </div>
  );
}
