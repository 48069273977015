import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import type { ApiServiceSettingsElToBeDisplayedPatientsFieldsParams } from '@apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from '@shared/hooks/use-selected-hospital-info';
import { useSuspenseQuery } from '@tanstack/react-query';
import CustomerInfoTitle from '@templates/HospitalSetting/containers/ServiceSetting/containers/CustomerInfo/components/CustomerInfoTitle';
import CustomerInfoToggle from '@templates/HospitalSetting/containers/ServiceSetting/containers/CustomerInfo/components/CustomerInfoToggle';
import { TOGGLE_ITEMS } from '@templates/HospitalSetting/containers/ServiceSetting/containers/CustomerInfo/constants/ToggleItems';
import {
  displayedPatientsFieldsState,
  initialDisplayedPatientsFieldsState,
} from '@templates/HospitalSetting/containers/ServiceSetting/containers/CustomerInfo/jotai/displayed-patients-fields';
import type { PatientsField } from '@templates/HospitalSetting/types/PatientsField';
import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import { useAtom, useSetAtom } from 'jotai/index';
import { useResetAtom } from 'jotai/utils';
import { useEffect } from 'react';
import { SHARED_UTILS } from 'utils/utils';

const fetchPatientsFields = async (
  params: ApiServiceSettingsElToBeDisplayedPatientsFieldsParams,
) => {
  const response = await apiClient.v3.apiServiceSettingsElToBeDisplayedPatientsFields(params);
  return SHARED_UTILS.api.checkApiResponse<PatientsField>(response.data);
};

export default function CustomerInfoContainer() {
  const { hospitalID } = useSelectedHospitalInfo();

  const resetDisplayedPatientsFields = useResetAtom(displayedPatientsFieldsState);
  const resetInitialDisplayedPatientsFields = useResetAtom(initialDisplayedPatientsFieldsState);

  const [patientsFields, setPatientsFields] = useAtom(displayedPatientsFieldsState);
  const setInitialPatientsFields = useSetAtom(initialDisplayedPatientsFieldsState);

  const { data } = useSuspenseQuery({
    queryKey: [QUERY_KEY.apiServiceSettingsElToBeDisplayedPatientsFields, { hospitalID }] as const,
    queryFn: ({ queryKey }) => fetchPatientsFields(queryKey[1]),
  });

  useEffect(() => {
    if (data) {
      setPatientsFields(data);
      setInitialPatientsFields(data);
    }
  }, [data]);

  useEffect(() => {
    return () => {
      resetDisplayedPatientsFields();
      resetInitialDisplayedPatientsFields();
    };
  }, []);

  return (
    <div className='flex flex-col gap-40'>
      <div className='flex flex-col gap-20 px-80'>
        <CustomerInfoTitle />
        <div className='grid grid-cols-4 gap-16 px-20'>
          {TOGGLE_ITEMS.map((item) => (
            <CustomerInfoToggle
              key={item.key}
              id={item.key}
              title={item.title}
              checked={(patientsFields?.[item.key] as boolean) ?? false}
            />
          ))}
        </div>
      </div>
      <Divider type='line' height={10} direction='horizontal' />
    </div>
  );
}
