import CustomerManagementPanelListContainer from 'web/templates/CustomerManagement/containers/ControlPanel/containers/CustomerManagementPanelList/CustomerManagementPanelList.container';
import OngoingConsultingAutomationContainer from 'web/templates/CustomerManagement/containers/ControlPanel/containers/OngoingConsultingAutomation/OngoingConsultingAutomation.container';

export default function ControlPanelContainer() {
  return (
    <div className='h-full border-r border-r-white400 bg-white50'>
      {/* 고객 관리 패널 */}
      <CustomerManagementPanelListContainer />
      {/* 적용 중인 상담자동화 */}
      <OngoingConsultingAutomationContainer />
    </div>
  );
}
