import type { CreateUpdateNoticeParamType } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Notice/types/create-update-notice-param-type';
import type { NoticeEditError } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Notice/types/notice-edit-error-type';

export const validateNoticeParams = (params: CreateUpdateNoticeParamType) => {
  const errors: NoticeEditError = {
    title: null,
    content: null,
    pushTarget: null,
  };

  const { title, content, isSendMessage, isSendToHospital, isFirstVisit, treatmentCategories } =
    params;
  if (!title) {
    errors.title = '공지사항 제목을 입력해 주세요..';
  }
  if (!content) {
    errors.content = '내용을 입력해 주세요.';
  }

  if (isSendMessage && !isSendToHospital) {
    if ((isFirstVisit?.length ?? 0) === 0 && (treatmentCategories?.length ?? 0) === 0) {
      errors.pushTarget = '상세조건을 선택해 주세요.';
    }
  }
  return Object.keys(errors).reduce((acc, key) => {
    const typedKey = key as keyof NoticeEditError;
    if (errors[typedKey] !== null) {
      acc[typedKey] = errors[typedKey];
    }
    return acc;
  }, {} as NoticeEditError);
};
