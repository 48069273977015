import { Group } from '@afterdoc-design-system/assets/icons';
import { NavBar, Statusbar } from '@afterdoc-design-system/assets/images';
import Scrollbar from '@afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';
import { useFormContext } from 'react-hook-form';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import type { MarketingAutomationAPIFormValues } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/components/RegisterMarketingAutomationDialogContent';
import DisplayImages from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/components/PhoneView/components/DisplayImages';
import DisplayText from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/components/PhoneView/components/DisplayText';
import {
  selectedContentTabItemState,
  selectedMessageIdState,
  selectedMessageIndexState,
} from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/states/message-form';

export default function PhoneView() {
  const { name: hospitalName } = useSelectedHospitalInfo();

  const { watch, getValues } = useFormContext<MarketingAutomationAPIFormValues>();

  const selectedMessageIndex = useAtomValue(selectedMessageIndexState);
  const selectedMessageId = useAtomValue(selectedMessageIdState);
  const selectedContentTabItem = useAtomValue(selectedContentTabItemState);

  const messageLists = getValues('messages');
  const messageSendingDateTime = watch(`messages.${selectedMessageIndex}.sendingDateTime`);
  const messageContent = watch(`messages.${selectedMessageIndex}.content`);

  if (!messageLists || !messageContent) return null;

  const messageText = messageContent.text;
  const messageImage = messageContent.image;

  return (
    <div className='h-screen max-h-[722px] min-w-[150px] max-w-[370px] flex-col-center flex-grow overflow-hidden rounded-[24px] border-[5px] border-blueLight bg-blue50 shadow-modal'>
      <Statusbar className='w-full bg-white50' />
      <Scrollbar>
        <div className='h-full w-full'>
          <div className='flex items-start gap-8 px-16 py-24'>
            <div className='flex-shrink-0'>
              <Group width={38} height={38} />
            </div>
            <div className='flex flex-grow flex-col gap-8'>
              <span className='text-Body10Bold text-black500'>{hospitalName}</span>
              <div className='flex gap-8 rounded-[12px] bg-white50 px-12 py-8'>
                <DisplayText text={messageText} />
              </div>
              {selectedContentTabItem[selectedMessageId] === '이미지' && (
                <DisplayImages messageImage={messageImage} />
              )}
            </div>
            <span className='mt-auto w-fit flex-shrink-0 text-Body10 text-black200'>
              {dayjs(messageSendingDateTime).format('A hh:mm')}
            </span>
          </div>
        </div>
      </Scrollbar>
      <NavBar className='w-full bg-white50' />
    </div>
  );
}
