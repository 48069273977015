export interface ReplaceableText {
  text: string;
  description: string;
  length: number;
}

export const removeSpecificReplaceableText = (
  text: string,
  replaceableTexts: ReplaceableText[],
) => {
  const tagsToRemove = replaceableTexts.map((item) => item.text).join('|');
  const regex = new RegExp(`(${tagsToRemove})`, 'g');
  return text.replace(regex, '');
};

export const calculateReplacedLength = (text: string, replaceableTexts: ReplaceableText[]) => {
  let totalReplacedLength = 0;

  for (const { text: tagText, length } of replaceableTexts) {
    const count = (text.match(new RegExp(tagText, 'g')) || []).length;
    totalReplacedLength += count * length;
  }

  return totalReplacedLength;
};

export const truncateTextBeforeMaxLength = (
  text: string,
  replaceableTexts: ReplaceableText[],
  maxLength: number,
) => {
  let truncated = '';
  let currentLength = 0;

  for (let i = 0; i < text.length; i++) {
    const char = text[i];
    if (char === '{' && text[i + 1] === '{') {
      const endIndex = text.indexOf('}}', i);
      if (endIndex !== -1) {
        const tag = text.slice(i, endIndex + 2);
        const tagReplacedLength = calculateReplacedLength(tag, replaceableTexts);
        if (currentLength + tagReplacedLength <= maxLength) {
          truncated += tag;
          currentLength += tagReplacedLength;
          i = endIndex + 1;
        } else {
          break;
        }
      }
    } else {
      if (currentLength < maxLength) {
        truncated += char;
        currentLength++;
      } else {
        break;
      }
    }
  }

  return truncated;
};
