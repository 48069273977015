import SearchInput from '@afterdoc-design-system/components/Atoms/Input/SearchInput';
import { useAtomValue, useSetAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { type KeyboardEvent, useRef, useState } from 'react';
import { AUTOMATION_SEARCH_FIELD_OPTIONS } from 'web/templates/Automation/containers/Main/AutomationSearchFilter/constants/automation-search-field-options';
import { INPUT_ID } from 'web/templates/Automation/containers/Main/AutomationSearchFilter/constants/input';
import { automationSearchTextState } from 'web/templates/Automation/containers/Main/AutomationSearchFilter/states/automation-search';
import {
  selectedAutomationSearchCriteriaIndexState,
  selectedAutomationSearchCriteriaState,
} from 'web/templates/Automation/containers/Main/AutomationSearchFilter/states/selected-automation-search-criteria';

export default function AutomationSearchInput() {
  const inputRef = useRef<HTMLInputElement>(null);

  const resetAutomationSearchText = useResetAtom(automationSearchTextState);

  const setAutomationSearchText = useSetAtom(automationSearchTextState);
  const setSelectedAutomationSearchCriteria = useSetAtom(selectedAutomationSearchCriteriaState);
  const selectedAutomationSearchCriteriaIndex = useAtomValue(
    selectedAutomationSearchCriteriaIndexState,
  );

  const [localSearchText, setLocalSearchText] = useState('');

  const handleKeyDown = async (e: KeyboardEvent) => {
    e.stopPropagation();

    if (!inputRef.current) return;

    if (e.key === 'Enter' && localSearchText.length === 0) {
      resetAutomationSearchText();
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
      return;
    }

    if (e.key === 'Enter' && localSearchText.length > 0) {
      setAutomationSearchText(localSearchText);
      setSelectedAutomationSearchCriteria(
        AUTOMATION_SEARCH_FIELD_OPTIONS[selectedAutomationSearchCriteriaIndex].key,
      );
    }
  };

  const handleDelete = () => {
    setLocalSearchText('');
    resetAutomationSearchText();
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  return (
    <SearchInput
      id={INPUT_ID}
      placeholder={
        AUTOMATION_SEARCH_FIELD_OPTIONS[selectedAutomationSearchCriteriaIndex].placeholder
      }
      className='w-full'
      value={localSearchText}
      onChange={(e) => {
        setLocalSearchText(e.target.value);
      }}
      onKeyDown={handleKeyDown}
      onDelete={handleDelete}
      ref={inputRef}
    />
  );
}
