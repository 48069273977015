import type {
  HospitalAftercareFileWriter,
  HospitalEventFileWriter,
  HospitalNotice,
  HospitalNoticePopupFilterFiles,
} from '@apis/swaggers/swagger-docs';
import { atomWithReset } from 'jotai/utils';

type EditingState = {
  isEditing: boolean;
  id?: string;
  data?:
    | (HospitalEventFileWriter & { editType: 'event' })
    | (HospitalAftercareFileWriter & { editType: 'homeCare' })
    | (HospitalNotice & { editType: 'notice' })
    | (HospitalNoticePopupFilterFiles & { editType: 'popup' });
};

export const contentEditState = atomWithReset<EditingState>({
  isEditing: false,
});
