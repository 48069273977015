import * as React from "react";
import type { SVGProps } from "react";
const SvgSmartDoctor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 19 19"
    {...props}
  >
    <rect width={18} height={18} x={0.5} y={0.5} fill="#F7F7F7" rx={5.5} />
    <rect width={18} height={18} x={0.5} y={0.5} stroke="#fff" rx={5.5} />
    <path
      fill="#231F30"
      fillRule="evenodd"
      d="M10.286 6.2h-4.49V4h4.49C12.889 4 15 6.068 15 8.62v1.76c0 2.552-2.11 4.62-4.714 4.62h-4.49v-2.2h4.49c1.364 0 2.47-1.084 2.47-2.42V8.62c0-1.337-1.107-2.42-2.47-2.42"
      clipRule="evenodd"
    />
    <path
      fill="#713BEC"
      d="M5.796 10.38v1.76h1.796v-1.76h1.796V8.62H7.592V6.86H5.796v1.76H4v1.76z"
    />
  </svg>
);
export default SvgSmartDoctor;
