import { atom } from 'jotai';
import { ROUTES_PATH } from 'web/shared/components/NavigationBar/constants/menu-items';

type ROUTES_PATH = typeof ROUTES_PATH;
type RouteKey = keyof ROUTES_PATH;

type CustomerManagementType = {
  treatmentHistory: boolean;
  customerInfoCustomerMemo: boolean;
};

type HospitalSettingType = {
  hospitalSetting: boolean;
  serviceSetting: boolean;
  authorizationSetting: boolean;
};

type CustomerChatType = {
  customerChattingCustomerMemo: boolean;
};

type AutomationType = {
  counselorAutomation: boolean;
  commonAutomation: boolean;
  marketingAutomation: boolean;
};

type ContentType = {
  eventManage: boolean;
  homeCareManage: boolean;
  noticeManage: boolean;
  popupManage: boolean;
};

type RouteTypeMap = {
  CUSTOMER_MANAGEMENT: CustomerManagementType;
  HOSPITAL_SETTING: HospitalSettingType;
  CUSTOMER_CHAT: CustomerChatType;
  AUTOMATION: AutomationType;
  CONTENT: ContentType;
};

type RouteState = {
  [K in RouteKey]: K extends keyof RouteTypeMap ? RouteTypeMap[K] : null;
};

// 초기 상태 생성 함수
const createInitialState = (): RouteState => {
  const state = {} as RouteState;
  for (const key of Object.keys(ROUTES_PATH) as Array<RouteKey>) {
    if (key === 'CUSTOMER_MANAGEMENT') {
      state[key] = {
        treatmentHistory: false,
        customerInfoCustomerMemo: false,
      } as RouteState[typeof key];
    }
    if (key === 'HOSPITAL_SETTING') {
      state[key] = {
        hospitalSetting: false,
        serviceSetting: false,
        authorizationSetting: false,
      } as RouteState['HOSPITAL_SETTING'];
    }
    if (key === 'CUSTOMER_CHAT') {
      state[key] = {
        customerChattingCustomerMemo: false,
      } as RouteState['CUSTOMER_CHAT'];
    }
    if (key === 'AUTOMATION') {
      state[key] = {
        counselorAutomation: false,
        commonAutomation: false,
        marketingAutomation: false,
      } as RouteState['AUTOMATION'];
    }
    if (key === 'CONTENT') {
      state[key] = {
        eventManage: false,
        homeCareManage: false,
        noticeManage: false,
        popupManage: false,
      } as RouteState['CONTENT'];
    }
  }
  return state;
};

export const isThereSomeThingToSaveState = atom(createInitialState());

export const isUnBlockedState = atom(true);
