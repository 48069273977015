import { useAtomValue } from 'jotai';
import { Suspense } from 'react';
import type { AlgorithmWithMessagesInput, MessageInput } from 'web/apis/swaggers/swagger-docs';
import RegisterCounselAutomationDialogTabs from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/components/RegisterCounselAutomationDialogTabs';
import CounselBaseSettingContainer from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselBaseSetting/CounselBaseSetting.container';
import CounselCommonReVisitMessageSettingContainer from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselCommonReVisitMessageSetting/CounselCommonReVisitMessageSetting.container';
import CounselMessageSettingContainer from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/CounselMessageSetting.container';
import { selectedCounselAutomationTabState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/states/selected-counsel-automation-tab-state';

export type CounselAutomationAPIFormValues = Omit<AlgorithmWithMessagesInput, 'messages'> & {
  messages: (MessageInput & {
    id: string;
  })[];
};

export default function RegisterCounselAutomationDialogContent() {
  const selectedAutomationTab = useAtomValue(selectedCounselAutomationTabState);

  return (
    <div className='w-full flex-col-center pt-40'>
      <Suspense>
        <RegisterCounselAutomationDialogTabs />
      </Suspense>
      {/* 기본설정 */}
      {selectedAutomationTab === 0 && <CounselBaseSettingContainer />}
      {/* 공통 재내원 안내 */}
      {selectedAutomationTab === 1 && <CounselCommonReVisitMessageSettingContainer />}
      {/* 메시지 */}
      {selectedAutomationTab === 2 && <CounselMessageSettingContainer />}
    </div>
  );
}
