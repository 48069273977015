import Scrollbar from '@afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import NoData from '@afterdoc-design-system/components/Molecules/NoData/NoData';
import ALIntegrationEditSearchedCustomerItem from './ALIntegrationEditSearchedCustomerItem';
import type { ALIntegrationUserData } from './hooks/type';
interface ALIntegrationEditSearchedCustomerProps {
  value: ALIntegrationUserData[];
  hasSearch: boolean;
  setter: (index: number, user: ALIntegrationUserData) => void;
}

const IntroDescription = () => {
  return (
    <div className='flex-full-center'>
      <NoData
        iconProps={{
          name: 'account-view',
          size: 48,
          color: 'white600',
        }}
        className={'h-full flex-center'}
        title='스마트닥터에 등록된 고객을 검색합니다.'
      />
    </div>
  );
};
const NoResultDescription = () => {
  return (
    <div className='flex-full-center'>
      <NoData
        iconProps={{
          name: 'warning',
          size: 48,
          color: 'white600',
        }}
        className={'h-full flex-center'}
        title='검색 결과가 없습니다.'
        subTitle='단어와 철자가 정확한지 확인해 주세요.'
      />
    </div>
  );
};

export default function ALIntegrationEditSearchedCustomer({
  value,
  hasSearch,
  setter,
}: ALIntegrationEditSearchedCustomerProps) {
  return (
    <div className='flex h-full w-full flex-center flex-col'>
      <Scrollbar className='p-5'>
        {value.length > 0 &&
          value?.map((_, index) => (
            <ALIntegrationEditSearchedCustomerItem
              key={index}
              value={value}
              index={index}
              setter={setter}
            />
          ))}
        {value.length === 0 && hasSearch === false && IntroDescription()}
        {value.length === 0 && hasSearch === true && NoResultDescription()}
      </Scrollbar>
    </div>
  );
}
