import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';
import { useLottieAnimation } from '../hooks/use-lottie-animation';

export const RenderingDownloadIcon = ({
  isComplete,
  percentage,
}: { isComplete: boolean; percentage: number }) => {
  const lottieRef = useLottieAnimation({ LottieName: 'dotsSpinner' });
  if (isComplete) {
    return <Icon name='done' />;
  }
  if (percentage === 0) {
    return <Icon name='tray-download' />;
  }
  return <div ref={lottieRef} />;
};
