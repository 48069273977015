import DaySelector from '@templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/AfterDoc/ReservationHistory/containers/components/DaySelector';
import MonthSelector from '@templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/AfterDoc/ReservationHistory/containers/components/MonthSelector';
import WeekSelector from '@templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/AfterDoc/ReservationHistory/containers/components/WeekSelector';

interface SmartDoctorHistoryDatePickerProps {
  selectedTabIndex: number;
}

export default function SmartDoctorHistoryDatePicker({
  selectedTabIndex,
}: SmartDoctorHistoryDatePickerProps) {
  switch (selectedTabIndex) {
    case 0: {
      // 월간
      return <MonthSelector />;
    }
    case 1: {
      // 주간
      return <WeekSelector />;
    }
    case 2: {
      //일간
      return <DaySelector />;
    }
  }

  return null;
}
