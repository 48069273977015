import { popupCreateOrUpdateParamState } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/state/popup-create-or-update-param-state';
import LabelText from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import Radio from 'afterdoc-design-system/components/Atoms/Radio/Radio';
import {
  RadioGroup,
  type ValueType,
} from 'afterdoc-design-system/components/Atoms/Radio/RadioGroup';
import { useSetAtom } from 'jotai/index';
import { memo } from 'react';

interface PopupTypeRadio {
  type: string;
  isDisabled: boolean;
}

function PopupTypeRadio({ type, isDisabled }: PopupTypeRadio) {
  const setPopupCreateOrUpdateParam = useSetAtom(popupCreateOrUpdateParamState);

  const handleRadioChange = (value: ValueType) => {
    setPopupCreateOrUpdateParam((prev) => ({ ...prev, popupType: value as string }));
  };

  return (
    <div className='flex min-h-40 items-start gap-24 py-4'>
      <LabelText isRequired width={76} className='flex h-32 items-center'>
        타입
      </LabelText>
      <div className='flex flex-grow gap-20'>
        <RadioGroup name='popupContentType' value={type} onChange={handleRadioChange}>
          <Radio id='text' label='텍스트' value={'text'} disabled={isDisabled} />
          <Radio id='image' label='이미지' value={'image'} disabled={isDisabled} />
        </RadioGroup>
      </div>
    </div>
  );
}

export default memo(PopupTypeRadio);
