import FilePicker, { type FilePickerHandle } from '@shared/components/FilePicker/FilePicker';
import { FILE_UPLOAD_MAX_SIZE } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/constants/file-upload-max-size';
import EventImageCropDialog from '@templates/Content/containers/ContentManager/containers/EventHomeCare/shared/components/EventImageCropDialog/EventImageCropDialog';
import { selectedThumbnailImageErrorAtom } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/states/content-edit-errors-state';
import { thumbnailImageTempFileState } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/states/thumbnail-image-temp-file-state';
import BigImage from 'afterdoc-design-system/components/Atoms/BigImage/BigImage';
import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import LabelText from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { dialogService } from 'afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import { useSetAtom } from 'jotai';
import { useAtom } from 'jotai/index';
import { memo, useEffect, useRef, useState } from 'react';

interface ThumbnailImagePickerProps {
  imageUrl?: string;
}

const isFileTooLarge = (targetFile: File) => {
  if (targetFile.size >= FILE_UPLOAD_MAX_SIZE) {
    toastService.errorMsg({
      text: '30MB 이하의 이미지를 등록할 수 있습니다.',
    });
    return true;
  }
  return false;
};

const ThumbnailImagePicker = memo(({ imageUrl }: ThumbnailImagePickerProps) => {
  const filePickerRef = useRef<FilePickerHandle>(null);
  const [imageSrc, setImageSrc] = useState<string | undefined>();

  const [thumbnailError, setThumbnailError] = useAtom(selectedThumbnailImageErrorAtom);

  const setThumbnailImageTempFile = useSetAtom(thumbnailImageTempFileState);

  const handleClick = () => {
    filePickerRef.current?.clickSingle();
  };

  const handleCropComplete = (url: string, file: File) => {
    setImageSrc(url);
    setThumbnailImageTempFile({ file, url });
    setThumbnailError(undefined);
  };

  const handleFileChange = (files: File[]) => {
    if (files.length === 0) return;
    const targetFile = files[0];

    if (isFileTooLarge(targetFile)) return;

    dialogService.push(
      <EventImageCropDialog
        file={files[0]}
        onCropComplete={handleCropComplete}
        useFixedCropper={true}
      />,
      {
        id: 'image-crop-dialog',
        titleProps: {
          title: '이미지 사이즈 조정하기',
          subTitle: '사진을 드래그하여 위치를 변경하거나 마우스 휠로 확대/축소할 수 있습니다.',
        },
        hasCloseButton: false,
        wrapperClassName: 'h-[592px]',
        width: 500,
      },
    );
  };

  useEffect(() => {
    if (imageUrl) {
      setImageSrc(imageUrl);
    }
  }, [imageUrl]);

  return (
    <div className='mt-10 flex w-full flex-col gap-10'>
      <div className='flex gap-28'>
        <LabelText width={72} isRequired={true} className='flex h-32 items-center'>
          대표이미지
        </LabelText>

        <div className='flex flex-col gap-4'>
          <FilePicker ref={filePickerRef} onFileChange={handleFileChange} />
          <BigImage
            text='이미지 변경'
            width={170}
            height={170}
            onClickImage={handleClick}
            onClickNoImage={handleClick}>
            {!!imageSrc && (
              <img
                alt='eventThumbnail'
                className='h-full w-full rounded-xl object-cover'
                src={imageSrc}
              />
            )}
          </BigImage>
          {!!thumbnailError && <span className='text-Body10 text-red500'>{thumbnailError}</span>}
        </div>
      </div>

      <Divider type='line' />
    </div>
  );
});

export default ThumbnailImagePicker;
