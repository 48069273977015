import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';
import { handleVideoDialogOpen } from 'web/templates/CustomerChat/components/Manual/components/ContentsList/components/ContentsPanelFolderItem/components/ChatContentPanelFolderItem/components/HomecareVideoSelect/functions/handle-video-dialog-open';

type Props = {
  url: string;
  title: string;
  vchID?: string;
};

export default function VideoListCard({ url, title, vchID }: Props) {
  return (
    <div
      className='flex h-80 w-full cursor-pointer items-center gap-10 rounded-r16 border border-white600 bg-white50 p-10 text-Body13'
      onClick={() => vchID && handleVideoDialogOpen(vchID, title)}>
      <img className='size-60 flex-shrink-0 rounded-r16' src={url} alt='Event Card' />
      <div className='flex flex-grow flex-col'>
        <div className='line-clamp-2 text-Body13 text-black700'>{title}</div>
      </div>
      <Icon size={16} color={'black600'} name='chevron-right' className='flex-shrink-0' />
    </div>
  );
}
