import type { ALReservation } from '@apis/Almighty/types';
import FilledTag from 'afterdoc-design-system/components/Atoms/Tag/FilledTag';
import HoverTooltip from 'afterdoc-design-system/components/Atoms/Tooltip/HoverTooltip';
import dayjs from 'dayjs';

interface SmartDoctorTableItemProps {
  reservation: ALReservation;
}

export default function SmartDoctorTableItem({ reservation }: SmartDoctorTableItemProps) {
  const formattedDate = (reservation: ALReservation) => {
    const { reservationDate, reservationStartTime } = reservation;
    const date = dayjs(`${reservationDate}T${reservationStartTime}`);
    return date.isValid() ? date.format('YYYY-MM-DD HH:mm') : '-';
  };

  return (
    <>
      <tr>
        <td className='border-line-table border-b px-12 py-10'>
          {reservation.label.name ? (
            <HoverTooltip
              text={reservation.label.name}
              position='bottomRight'
              wrapperProps={{ className: 'truncate !block' }}>
              <FilledTag
                className='max-w-[76px]'
                textClassName='truncate'
                bgColor={reservation.label.color}>
                {reservation.label.name}
              </FilledTag>
            </HoverTooltip>
          ) : (
            '-'
          )}
        </td>
        <td className='truncate border-line-table border-b px-12 py-10'>
          {formattedDate(reservation) || '-'}
        </td>
        <TruncateTooltip text={reservation.departmentName} />
        <TruncateTooltip text={reservation.chargeDoctorName} />
        <TruncateTooltip text={reservation.reservationMemo} />
        <TruncateTooltip text={reservation.consultantName} />
      </tr>
    </>
  );
}

const TruncateTooltip = ({ text }: { text: string | null }) => {
  return (
    <td className={'truncate border-line-table border-b px-12 py-10'}>
      <HoverTooltip
        text={text}
        position='bottomRight'
        wrapperProps={{ className: 'truncate !block' }}>
        {text || '-'}
      </HoverTooltip>
    </td>
  );
};
