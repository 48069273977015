import * as React from "react";
import type { SVGProps } from "react";
const SvgXlsx = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <rect width={32} height={32} fill="#000" rx={4} />
    <path
      fill="#fff"
      d="m21.465 20.564 2.112-3.492-1.968-3.216h1.884l.684 1.176c.18.396.384.768.588 1.152h.06c.156-.384.324-.756.492-1.152l.552-1.176h1.824l-1.968 3.408 2.112 3.3h-1.896l-.744-1.236c-.216-.384-.432-.792-.66-1.164h-.06c-.192.372-.372.768-.564 1.164l-.612 1.236zM18.212 20.732c-.9 0-1.896-.384-2.556-.936l.792-1.116c.612.468 1.176.744 1.8.744.672 0 .984-.3.984-.72 0-.492-.708-.732-1.404-.996-.852-.336-1.8-.84-1.8-1.98 0-1.188.96-2.04 2.496-2.04.972 0 1.74.396 2.292.828l-.804 1.056c-.468-.336-.948-.576-1.44-.576-.612 0-.888.264-.888.66 0 .48.612.672 1.332.924.888.336 1.86.792 1.86 2.028 0 1.188-.924 2.124-2.664 2.124M14.163 20.732c-1.236 0-1.656-.804-1.656-2.052V11h1.764v7.752c0 .42.18.552.347.552.06 0 .108 0 .229-.024l.216 1.308a2.6 2.6 0 0 1-.9.144M5 20.564l2.112-3.492-1.968-3.216h1.884l.684 1.176c.18.396.384.768.588 1.152h.06c.156-.384.324-.756.492-1.152l.552-1.176h1.824L9.26 17.264l2.112 3.3H9.476l-.744-1.236c-.216-.384-.432-.792-.66-1.164h-.06c-.192.372-.372.768-.564 1.164l-.612 1.236z"
    />
  </svg>
);
export default SvgXlsx;
