import BigImage from '@afterdoc-design-system/components/Atoms/BigImage/BigImage';
import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';
import { useAtomValue } from 'jotai';
import { forwardRef, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import FilePicker from 'web/shared/components/FilePicker/FilePicker';
import LayoutBox from 'web/shared/components/LayoutBox/LayoutBox';
import OptimizedImage from 'web/shared/components/OptimizedImage/OptimizedImage';
import {
  type ImageType,
  useUploadMultipleImages,
} from 'web/shared/hooks/files/images/use-upload-multiple-images';
import type { CounselAutomationAPIFormValues } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/components/RegisterCounselAutomationDialogContent';
import { ContentTextArea } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/components/Forms/components/ContentTextArea';
import { selectedMessageIndexState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/message-form';
import { useGetIsKakaoAlimAndFriendTalkLinkage } from 'web/templates/Automation/containers/Dialog/shared/hooks/use-get-is-kakao-alim-and-friend-talk-linkage';

const MAX_FILE_SIZE_MB = 5;
const MAX_IMAGES_LENGTH = 20;

const ImageContentForm = forwardRef<HTMLTextAreaElement>((_, ref) => {
  const { setValue, getValues } = useFormContext<CounselAutomationAPIFormValues>();

  const selectedMessageIndex = useAtomValue(selectedMessageIndexState);
  const messageContentImage = getValues(`messages.${selectedMessageIndex}.content.image`);

  const {
    images,
    setImages,
    handleFileChange,
    handleRemoveImage,
    handleAttachMultipleImages,
    filePickerRef,
  } = useUploadMultipleImages({
    maxFileSizeMB: MAX_FILE_SIZE_MB,
    maxImages: MAX_IMAGES_LENGTH,
  });

  const { hasKakaoAlimAndFriendTalkLinkage } = useGetIsKakaoAlimAndFriendTalkLinkage();

  const handleAutomationRemoveImage = (index: number) => {
    handleRemoveImage(index);

    if (images.length === 1) {
      setValue(`messages.${selectedMessageIndex}.content.image`, undefined);
    }
  };

  useEffect(() => {
    if (images && images.length > 0) {
      const stringifiedImages = JSON.stringify(images);
      setValue(`messages.${selectedMessageIndex}.content.image`, stringifiedImages);
    }
  }, [images]);

  useEffect(() => {
    if (messageContentImage) {
      try {
        const imageContent: ImageType[] = JSON.parse(messageContentImage);
        setImages(imageContent);
      } catch (error) {
        console.error('Error parsing image content:', error);
        setImages([]);
      }
    } else {
      setImages([]);
    }
  }, [messageContentImage, setImages]);

  return (
    <>
      {hasKakaoAlimAndFriendTalkLinkage && (
        <div className='rounded-r10 bg-white200 px-16 py-8 text-Body10 text-black500'>
          텍스트를 제외한 다른 타입의 메시지는 알림톡을 발송할 수 없습니다.
        </div>
      )}
      <LayoutBox direction='vertical' size={20} width='100%'>
        <div className='mt-10 flex flex-wrap gap-10'>
          <FilePicker ref={filePickerRef} onFileChange={handleFileChange} />
          {images.map((image, index) => (
            <div className='relative' key={uuidv4()}>
              <OptimizedImage
                image={image}
                className='h-[96px] w-[170px] rounded-r10 border border-white600 object-cover'
              />
              <button
                onClick={() => handleAutomationRemoveImage(index)}
                className='absolute-center flex items-center gap-2 rounded-r16 bg-black800 bg-opacity-50 py-7 pr-10 pl-8'
                type='button'>
                <Icon name='trash-can-outline' color='white50' size={20} />
                <span className='whitespace-nowrap text-Body13 text-white50'>삭제하기</span>
              </button>
            </div>
          ))}
          {images.length < 20 && (
            <BigImage width={170} height={96} onClickNoImage={handleAttachMultipleImages} />
          )}
        </div>
        <ContentTextArea ref={ref} />
      </LayoutBox>
    </>
  );
});

export default ImageContentForm;
