import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import type { ApiPatientsMemosElFindOneParams } from '@apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from '@shared/hooks/use-selected-hospital-info';
import { useMutation, useQuery } from '@tanstack/react-query';
import { usePatient } from '@templates/CustomerChat/hooks/use-patient';
import { useState } from 'react';
import { SHARED_UTILS } from 'utils/utils';

type UpdateHserviceMemoParam = Parameters<typeof apiClient.v3.apiPatientsMemosElUpdate>[0];

const fetchHServiceMemo = async (params: ApiPatientsMemosElFindOneParams) => {
  const { data } = await apiClient.v3.apiPatientsMemosElFindOne(params);
  return SHARED_UTILS.api.checkApiResponse(data);
};

const updateHServiceMemo = async (query: UpdateHserviceMemoParam) => {
  const { data } = await apiClient.v3.apiPatientsMemosElUpdate(query);
  return SHARED_UTILS.api.checkApiResponse(data);
};

interface UpdateActionCallback {
  onSuccessCallback?: () => void;
  onFailureCallback?: (error: Error) => void;
}

export const useHServiceMemo = () => {
  const { hospitalID } = useSelectedHospitalInfo();
  const { userID, patientId: hserviceID } = usePatient();

  const [isUpdating, setIsUpdating] = useState(false);

  const queryResult = useQuery({
    queryKey: [QUERY_KEY.apiPatientsMemosElFindOne, { hospitalID, userID, hserviceID }] as const,
    queryFn: ({ queryKey }) => fetchHServiceMemo(queryKey[1]),
    enabled: Boolean(hospitalID && userID && hserviceID),
  });

  const updateHServiceMemoMutation = useMutation({
    mutationFn: (params: UpdateHserviceMemoParam) => updateHServiceMemo(params),
    onMutate: () => setIsUpdating(true),
    onSettled: () => setIsUpdating(false),
  });

  const updateHServiceMemoAction = (content: string, callback: UpdateActionCallback) => {
    updateHServiceMemoMutation.mutate(
      {
        hserviceID,
        userID,
        hospitalID,
        content,
      },
      {
        onSuccess: () => callback.onSuccessCallback?.(),
        onError: (error) => callback.onFailureCallback?.(error),
      },
    );
  };

  return {
    queryResult,
    isUpdating,
    updateHServiceMemoAction,
  };
};
