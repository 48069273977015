import { modalService } from '@afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { useAtom, useAtomValue } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import { useTemporaryPatientsLock } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementSaveFooter/utils/ use-temporary-patients-lock';
import { customerTableDisplayModeState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/states/customer-management-display-mode';
import type { CustomerManagementTemporaryAPIFormValues } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/types/table';
import { clickedPanelIndexState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/states/panel';
import {
  focusedCellState,
  selectedCellState,
} from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/states/table';

export const useResetTable = () => {
  const {
    reset,
    formState: { isDirty },
  } = useFormContext<{ rows: CustomerManagementTemporaryAPIFormValues }>();
  const [customerTableDisplayMode, setCustomerTableDisplayMode] = useAtom(
    customerTableDisplayModeState,
  );
  const { hospitalID } = useSelectedHospitalInfo();
  const clickedPanelIndex = useAtomValue(clickedPanelIndexState);

  const resetSelectedCell = useResetAtom(selectedCellState);
  const resetFocusedCell = useResetAtom(focusedCellState);

  const { getPatientsIsLocked, patchRequestLockMutation } = useTemporaryPatientsLock();

  const handleChangeDisplayMode = useCallback(
    (mode?: Parameters<typeof setCustomerTableDisplayMode>[0]) => {
      setCustomerTableDisplayMode(mode ?? 'VIEWER');
    },
    [setCustomerTableDisplayMode],
  );

  const resetTable = useCallback(
    (mode?: Parameters<typeof handleChangeDisplayMode>[0]) => {
      reset(
        { rows: [] },
        {
          keepDefaultValues: false,
          keepDirty: false,
          keepErrors: false,
          keepDirtyValues: false,
          keepTouched: false,
          keepSubmitCount: false,
        },
      );

      if (clickedPanelIndex === 1) {
        return;
      }

      // 테이블 모드 변경
      if (customerTableDisplayMode !== (mode ?? 'VIEWER')) {
        handleChangeDisplayMode(mode);
      }

      // 셀 선택 상태 초기화
      resetSelectedCell();
      resetFocusedCell();
    },
    [
      clickedPanelIndex,
      customerTableDisplayMode,
      resetSelectedCell,
      resetFocusedCell,
      handleChangeDisplayMode,
      reset,
    ],
  );

  const hasChange = useMemo(
    () => isDirty && (clickedPanelIndex === 0 ? customerTableDisplayMode === 'EDITOR' : true),
    [isDirty, customerTableDisplayMode, clickedPanelIndex],
  );

  const openLeaveWithoutSavingModal = useCallback(
    ({
      cb,
      cancelCb,
      showResetTable = true,
    }: {
      cb?: () => void;
      cancelCb?: () => void;
      showResetTable?: boolean;
    } = {}) => {
      const onRequestLock = async () => {
        const latestLockData = await getPatientsIsLocked({ hospitalID });

        if (!latestLockData) return;

        await patchRequestLockMutation.mutateAsync({
          hospitalID,
          isLock: false,
          version: latestLockData.version,
        });
      };

      if (clickedPanelIndex === 0 && customerTableDisplayMode === 'EDITOR') {
        modalService.defaultWarning({
          id: 'leave-without-saving',
          title: '저장하지 않고 나가시겠어요?',
          contents: '페이지 이탈 시 저장하지 않은 정보를 잃게 됩니다.',
          onConfirm: async () => {
            await onRequestLock();

            showResetTable && resetTable();
            modalService.popById('leave-without-saving');
            cb?.();
          },
          onCancel: () => {
            modalService.popById('leave-without-saving');
            cancelCb?.();
          },
        });
      } else {
        showResetTable && resetTable();
        cb?.();
      }
    },
    [
      clickedPanelIndex,
      customerTableDisplayMode,
      getPatientsIsLocked,
      patchRequestLockMutation,
      hospitalID,
      resetTable,
    ],
  );

  return { resetTable, hasChange, openLeaveWithoutSavingModal };
};
