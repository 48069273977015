import * as React from "react";
import type { SVGProps } from "react";
const SvgCheckboxDefault = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <g clipPath="url(#checkbox-default_svg__a)">
      <path
        fill="#A3A3A3"
        d="M10 1c-4.96 0-9 4.04-9 9s4.04 9 9 9 9-4.04 9-9-4.04-9-9-9m0-1c5.52 0 10 4.48 10 10s-4.48 10-10 10S0 15.52 0 10 4.48 0 10 0"
      />
      <path
        stroke="#A3A3A3"
        strokeWidth={1.5}
        d="m5.36 9.66 3.56 3.56 6.44-6.43"
      />
    </g>
    <defs>
      <clipPath id="checkbox-default_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCheckboxDefault;
