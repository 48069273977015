import { SHARED_UTILS } from '@shared-utils/utils';
import { useQuery } from '@tanstack/react-query';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { ApiApprovalChatroomElFindParams } from 'web/apis/swaggers/swagger-docs';

const getApprovalRequest = async (query: ApiApprovalChatroomElFindParams) => {
  const { data } = await apiClient.v3.apiApprovalChatroomElFind(query);
  return SHARED_UTILS.api.checkApiResponse(data);
};

export const useGetApprovalChatroom = ({ chatRoomID }: { chatRoomID: string }) => {
  const query = {
    chatRoomID,
    status: 'waiting',
    type: 'join',
  };
  const {
    data: approvalChatroom,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [QUERY_KEY.apiApprovalChatroomElFind, query] as const,
    queryFn: ({ queryKey }) => getApprovalRequest(queryKey[1]),
  });

  return {
    approvalChatroom: approvalChatroom ?? [],
    isLoading,
    refetch,
  };
};
