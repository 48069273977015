import TextArea from 'afterdoc-design-system/components/Atoms/Input/TextArea/TextArea';

interface MessageInputProps {
  message: string;
  onChange: (value: string) => void;
}

export default function MessageInput({ message, onChange }: MessageInputProps) {
  return (
    <>
      <div className='h-full w-full'>
        <TextArea
          height='100%'
          width='100%'
          value={message}
          isShowBottom={false}
          onChange={(e) => onChange(e.target.value)}
          maxLength={900}
          placeholder={'내용을 입력해 주세요.'}
        />
      </div>
    </>
  );
}
