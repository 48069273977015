import { SHARED_UTILS } from '@shared-utils/utils';
import { useQuery } from '@tanstack/react-query';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { ApiVideocontenthospitalElFindParams } from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';

const getVideoContentSingle = async (query: ApiVideocontenthospitalElFindParams) => {
  const { data } = await apiClient.v3.apiVideocontenthospitalElFind(query);
  return SHARED_UTILS.api.checkApiResponse(data);
};

export const useVideoList = ({
  keyword,
  categoryID,
}: { keyword?: string; categoryID?: string }) => {
  const { hospitalID } = useSelectedHospitalInfo();
  const query = {
    hospitalID,
    ...(keyword && { keyword }),
    ...(categoryID && { categoryID }),
  };
  const { data, error, isLoading } = useQuery({
    queryKey: [QUERY_KEY.apiVideocontenthospitalElFind, query] as const,
    queryFn: ({ queryKey }) => getVideoContentSingle(queryKey[1]),
  });

  const newData =
    data?.map(({ _id, subtitle, thumbnailFileID, title }) => {
      const { paths } = thumbnailFileID ?? {};
      return {
        id: _id ?? '',
        subtitle,
        url: paths?.[0] ?? null,
        title,
      };
    }) ?? [];

  return {
    data: newData ?? [],
    error,
    isLoading,
  };
};
