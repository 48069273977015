import * as React from "react";
import type { SVGProps } from "react";
const SvgCheckboxBlankDisabled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g fill="#D9D9D9" clipPath="url(#checkbox-blank-disabled_svg__a)">
      <path d="M5 5h14v14H5z" />
      <path d="M19 3H5c-1.11 0-2 .89-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2m0 2v14H5V5z" />
    </g>
    <defs>
      <clipPath id="checkbox-blank-disabled_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCheckboxBlankDisabled;
