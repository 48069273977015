import TextArea from '@afterdoc-design-system/components/Atoms/Input/TextArea/TextArea';
import LabelText from '@afterdoc-design-system/components/Atoms/LabelText/LabelText';
import { useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import type { CommonAlgorithmInput } from 'web/apis/swaggers/swagger-docs';
import { useTextAreaWithMaxLength } from 'web/templates/Automation/containers/Dialog/EditCommonAutomationDialog/components/EditCommonAutomationDialogContent/hooks/use-text-area-with-max-length';
import {
  COMMON_REPLACEABLE_TEXTS,
  MAX_LENGTH,
} from 'web/templates/Automation/containers/Dialog/EditCommonAutomationDialog/constants/replaceable-texts';
import ReplaceableTextLists from 'web/templates/Automation/containers/Dialog/shared/components/ReplaceableTextLists/ReplaceableTextLists';
import { calculateReplacedLength } from 'web/templates/Automation/containers/shared/functions/calculate-text';

export default function MessageContent() {
  const textRef = useRef<HTMLTextAreaElement>(null);
  const {
    control,
    setValue,
    clearErrors,
    watch,
    formState: { errors },
  } = useFormContext<CommonAlgorithmInput>();

  const messageContent = watch('content') ?? '';

  const [actualTextLength, setActualTextLength] = useState(
    calculateReplacedLength(messageContent, COMMON_REPLACEABLE_TEXTS) + messageContent.length,
  );

  const { handleTextAreaChange } = useTextAreaWithMaxLength({ setActualTextLength });

  const handleInsertReplaceText = (text: string, length: number) => {
    if (actualTextLength + length < MAX_LENGTH) {
      setValue('content', messageContent + text);
      setActualTextLength((prev) => prev + length);
      textRef.current?.focus();
    }
  };

  return (
    <div>
      <LabelText isRequired>내용</LabelText>
      <Controller
        name='content'
        control={control}
        render={({ field }) => (
          <TextArea
            {...field}
            onChange={(e) => {
              handleTextAreaChange(e);

              if (actualTextLength >= MAX_LENGTH && e.target.value.length > messageContent.length)
                return;

              field.onChange(e);
              clearErrors('content');
            }}
            value={messageContent}
            ref={textRef}
            className='mt-10'
            isShowBottom={false}
            height={460}
            placeholder='내용을 입력해 주세요.'
            hasError={!!errors.content}
            errorText={errors.content?.message}
          />
        )}
      />
      <ReplaceableTextLists
        replaceableTexts={COMMON_REPLACEABLE_TEXTS}
        handleInsertReplaceText={handleInsertReplaceText}
      />
    </div>
  );
}
