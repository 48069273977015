import { useMutation } from '@tanstack/react-query';
import { apiClient } from 'web/apis/instances/api-client';
import type { SingleFileContentsUpdateHandlerBodyRequest } from 'web/apis/swaggers/swagger-docs';
import {
  type LocalImage,
  type ServerImage,
  isLocalImage,
} from 'web/shared/hooks/files/images/use-upload-multiple-images';
import { useUserInfo } from 'web/shared/hooks/use-user-info';
import { FileService } from 'web/shared/utils/fileUpload/services/FileServices';
import { FileUploader } from 'web/shared/utils/fileUpload/services/FileUploader';

type ImageType = LocalImage | ServerImage;

const convertToFileArrObject = async (images: LocalImage[]): Promise<File[]> => {
  const files = await Promise.all(
    images.map(async (image) => {
      const response = await fetch(image.blobUrl);
      const blob = await response.blob();
      const file = new File([blob], image.name, {
        type: image.type,
        lastModified: image.lastModified,
      });
      return file;
    }),
  );

  return files;
};

const patchFile = async (params: SingleFileContentsUpdateHandlerBodyRequest) => {
  const response = await apiClient.v3.singleFileContentsUpdateHandler(params);
  return response.data;
};

type OriginalImage = {
  url: string;
  fileName?: string;
  fileSize?: number;
  id?: string;
};

export const useProcessImagesForUpload = () => {
  const { userId } = useUserInfo();

  const patchFileMutation = useMutation({
    mutationFn: patchFile,
  });

  const processImagesForUpload = async ({
    originalImages,
    newImages,
  }: {
    originalImages?: OriginalImage[];
    newImages: ImageType[];
  }) => {
    const serverImages = newImages.filter((image) => !isLocalImage(image)) as ServerImage[];
    const localImages = newImages.filter(isLocalImage);

    const toBeRemovedUrls: string[] = [];
    const toBeAddedAWSObjectArray: SingleFileContentsUpdateHandlerBodyRequest['toBeAddedAWSObjectArray'] =
      [];

    // 기존 이미지와 새 이미지 비교해서 삭제될 URL 찾기
    if (originalImages) {
      const originalImageUrls = originalImages.map((img) => img.url);
      const currentServerImageUrls = serverImages.map((img) => img.url);

      // 기존 서버 이미지 중에서 새 이미지에 포함되지 않은 이미지를 삭제 목록에 추가
      toBeRemovedUrls.push(
        ...originalImageUrls.filter((url) => !currentServerImageUrls.includes(url)),
      );
    }

    if (serverImages.length > 0 && localImages.length > 0) {
      const filesToUpload = await convertToFileArrObject(localImages);

      // 로컬 이미지 업로드
      const sizes = await Promise.all(filesToUpload.map(FileService.getImageFileWidthHeight));
      const result = await FileUploader.uploadFiles({
        files: filesToUpload,
        type: 300,
        userID: userId,
        sizes,
      });

      // 업로드된 파일 정보를 toBeAddedAWSObjectArray에 추가
      if (result.aws) {
        for (const file of result.aws) {
          toBeAddedAWSObjectArray.push({
            ETag: file.ETag,
            Location: file.Location,
            Key: file.Key,
            Bucket: file.Bucket,
            size: file.size,
            fileSize: file.fileSize,
            fileName: file.fileName,
          });
        }
      }

      const patchFileResult = await patchFileMutation.mutateAsync({
        fileID: serverImages[0].id,
        type: 300,
        toBeRemovedUrls: toBeRemovedUrls.length > 0 ? toBeRemovedUrls : undefined,
        toBeAddedAWSObjectArray: toBeAddedAWSObjectArray,
      });

      if (patchFileResult.data.isUpdatedSuccess) {
        return serverImages[0].id;
      }
      return;
    }

    // 서버 이미지만 남아있는 경우 기존 이미지의 fileID를 사용
    if (serverImages.length > 0 && !localImages.length) {
      if (toBeRemovedUrls.length > 0) {
        const patchFileResult = await patchFileMutation.mutateAsync({
          fileID: serverImages[0].id,
          type: 300,
          toBeRemovedUrls,
        });

        if (patchFileResult.data.isUpdatedSuccess) {
          return serverImages[0].id;
        }
        return;
      }
      return serverImages[0].id;
    }

    // 로컬 이미지만 있는 경우
    if (localImages.length > 0 && !serverImages.length) {
      // 로컬 이미지를 File 객체로 변환
      const filesToUpload = await convertToFileArrObject(localImages);

      // 로컬 이미지 업로드
      const sizes = await Promise.all(filesToUpload.map(FileService.getImageFileWidthHeight));
      const result = await FileUploader.uploadFiles({
        files: filesToUpload,
        type: 300,
        userID: userId,
        sizes,
      });

      return result._id;
    }

    // 이미지가 없는 경우
    if (!originalImages || !originalImages?.[0].id) return;

    const patchFileResult = await patchFileMutation.mutateAsync({
      fileID: originalImages?.[0].id,
      type: 300,
      toBeRemovedUrls,
    });

    if (patchFileResult.data.isUpdatedSuccess) {
      return originalImages?.[0].id;
    }
  };

  return {
    processImagesForUpload,
    patchFileMutation,
  };
};
