import { useState } from 'react';

export function useSearchInput() {
  const [inputValue, setInputValue] = useState('');
  const [keyword, setKeyword] = useState('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      setKeyword(inputValue);
    }
  };

  const handleDelete = () => {
    setInputValue('');
    setKeyword('');
  };

  return {
    inputValue,
    keyword,
    handleChange,
    handleKeyDown,
    handleDelete,
  };
}
