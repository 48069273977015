import ResultArea from '@templates/HospitalSetting/containers/HospitalProfile/components/HospitalAddress/components/AddressSearchDialog/components/ResultArea';
import SearchKeyword from '@templates/HospitalSetting/containers/HospitalProfile/components/HospitalAddress/components/AddressSearchDialog/components/SearchKeyword';
import { hospitalAddressQueryKeyState } from '@templates/HospitalSetting/containers/HospitalProfile/components/HospitalAddress/components/AddressSearchDialog/jotai/address-query-key';
import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';
import { dialogService } from 'afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import { useResetAtom } from 'jotai/utils';
import React, { useEffect } from 'react';

export default function AddressSearchDialog() {
  const resetHospitalAddressQueryKey = useResetAtom(hospitalAddressQueryKeyState);

  const handleClick = () => {
    dialogService.popById('address-search-dialog');
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.code === 'Escape') {
        dialogService.popById('address-search-dialog');
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);

      resetHospitalAddressQueryKey();
    };
  }, []);

  return (
    <div className='h-full w-[410px]'>
      <div className='flex h-full flex-col'>
        <SearchKeyword />
        <div className='h-full flex-col-center'>
          <div className='h-full w-full flex-col-center flex-grow'>
            <ResultArea />
          </div>
          <ContainedButton
            buttonSize='big'
            className='mx-auto mt-10 mb-20'
            btnColor='secondary'
            onClick={handleClick}>
            닫기
          </ContainedButton>
        </div>
      </div>
    </div>
  );
}
