import { useAtomValue } from 'jotai';
import type { UserNameAndType } from 'web/apis/swaggers/swagger-docs';
import { useUserInfo } from 'web/shared/hooks/use-user-info';
import { chatRoomIDSelector } from 'web/templates/CustomerChat/states/selected-chat-room';
import { useChatRoomInfoOne } from '../../ChattingRoom/hooks/use-chatroom-info-one';
import {
  OPTION_CHAT_EXIT,
  OPTION_CHAT_NOT_IN_PROGRESS,
  OPTION_DETAIL,
  OPTION_RESERVATION_ENROLL,
  OPTION_USER_ADD,
} from '../constants/dropdown';

export type DropdownDataParams = {
  id?: string;
  isColorAfterDocIcon: boolean;
  isGrayAfterDocIcon: boolean;
  isKakaoIcon: boolean;
};

const isInCounselors = (counselors: UserNameAndType[], userID: string) => {
  return counselors.some((counselor) => counselor._id === userID);
};

const isInParticipants = (participants: UserNameAndType[], userID: string) => {
  return participants.some((participant) => participant._id === userID);
};

const calculateRedTextIndices = (options: string[]) =>
  options
    .map((option, index) => (RED_TEXT_LINES.includes(option) ? index : -1))
    .filter((index) => index !== -1);

const onlyKakaoFilterOptions = (data: string[], isOnlyKakao: boolean): string[] => {
  return isOnlyKakao ? data.filter((item) => item !== '상세정보') : data;
};

export const RED_TEXT_LINES = [OPTION_CHAT_NOT_IN_PROGRESS, OPTION_CHAT_EXIT];

const OPTIONS = [OPTION_RESERVATION_ENROLL, OPTION_DETAIL, OPTION_USER_ADD, ...RED_TEXT_LINES];

export function useParentDropdownOptions({
  id,
  isColorAfterDocIcon,
  isGrayAfterDocIcon,
  isKakaoIcon,
}: DropdownDataParams) {
  const chatRoomID = useAtomValue(chatRoomIDSelector);
  const { authorizationTypeID, userId: userID } = useUserInfo();

  const targetChatRoomId = id ?? (chatRoomID || '');

  const { counselors, participants, isCounselInProgress } = useChatRoomInfoOne({
    chatRoomID: targetChatRoomId,
  });

  const isAuthoriztionControlPatientChatting = authorizationTypeID?.canControlPatientChatting;

  const isClosedChat = !isCounselInProgress;

  const isInCounselor = isInCounselors(counselors, userID);
  const isInParticipant = isInParticipants(participants, userID);

  const isOnlyKakao = (isKakaoIcon && !isColorAfterDocIcon && !isGrayAfterDocIcon) ?? false;

  // console.info(
  //   'check options state:',
  //   isAuthoriztionControlPatientChatting,
  //   isInParticipant,
  //   isInCounselor,
  //   isClosedChat,
  // );

  //채팅 초대 권한 없는 그룹
  if (!isAuthoriztionControlPatientChatting && isInParticipant && !isInCounselor) {
    const data = ['예약등록', '상세정보', '채팅방 나가기'];
    const newData = onlyKakaoFilterOptions(data, isOnlyKakao);

    return {
      data: newData,
      redTextIndices: calculateRedTextIndices(newData),
    };
  }

  if (!isAuthoriztionControlPatientChatting && isInParticipant && isInCounselor) {
    const data = isClosedChat
      ? ['예약등록', '상세정보', '담당자 추가', '채팅방 나가기']
      : ['예약등록', '상세정보', '담당자 추가', '상담 종료', '채팅방 나가기'];
    const newData = onlyKakaoFilterOptions(data, isOnlyKakao);

    return {
      data: newData,
      redTextIndices: calculateRedTextIndices(newData),
    };
  }

  //채팅 초대 권한 있는 그룹
  if (isAuthoriztionControlPatientChatting && !isInParticipant && !isInCounselor) {
    const data = isClosedChat
      ? ['예약등록', '상세정보', '담당자 추가']
      : ['예약등록', '상세정보', '담당자 추가', '상담 종료'];
    const newData = onlyKakaoFilterOptions(data, isOnlyKakao);

    return {
      data: newData,
      redTextIndices: calculateRedTextIndices(newData),
    };
  }

  if (isAuthoriztionControlPatientChatting && isInParticipant) {
    if (isClosedChat) {
      const data = ['예약등록', '상세정보', '담당자 추가', '채팅방 나가기'];
      const newData = onlyKakaoFilterOptions(data, isOnlyKakao);

      return {
        data: newData,
        redTextIndices: calculateRedTextIndices(newData),
      };
    }
  }

  const OPTIONS_WithoutDetail = onlyKakaoFilterOptions(OPTIONS, isOnlyKakao);

  return {
    data: OPTIONS_WithoutDetail,
    redTextIndices: calculateRedTextIndices(OPTIONS_WithoutDetail),
  };
}
