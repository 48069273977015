import { useChatMessageContext } from '../ChatMessageContext';
import FileMessage from './FileMessage';
import TextContentMessage from './TextContentMessage/TextContentMessage';

export default function AlgorithmOldVideoMessage() {
  const { content } = useChatMessageContext();

  return (
    <div className='flex flex-col gap-10'>
      {content && <TextContentMessage />}
      <FileMessage />
    </div>
  );
}
