import ParticipantsChip from '@templates/CustomerChat/components/ChattingList/components/ChattingListBody/components/ChattingListItem/components/ParticipantsChip';
import type { ActiveDropdown } from '@templates/CustomerChat/components/ChattingList/types/active-dropdown';
import { useParsedChatRoomData } from '@templates/CustomerChat/components/ChattingList/use-parsed-chat-room-data';
import Badge from 'afterdoc-design-system/components/Atoms/Badge/Badge';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai/index';
import { memo, useCallback, useMemo, useRef, useState } from 'react';
import { chatRoomIDSelector } from 'web/templates/CustomerChat/states/selected-chat-room';
import ParentDropdown from '../../../../../ParentDropDown/ParentDropdown';
import { displayTextContent } from '../../../../functions/displayTextContent';
import type { TempChatRoomHServiceLastMessageUser } from '../../../../hooks/use-chatting-list';
import type { HandleSelectedChatRoomParmas } from '../../ChattingListBody';

interface ChattingListItemAdvanceProps {
  chatRoom: TempChatRoomHServiceLastMessageUser;
  activeDropdown: ActiveDropdown | null;
  onHandleDropdown: (activeDropdown: ActiveDropdown, isActive: boolean) => void;
  onHandleSelectChatRoom: ({
    chatRoomID,
    chatRoomParentHServiceID,
    patientIDInChattingListUserID,
    chatRoomHserviceID,
  }: HandleSelectedChatRoomParmas) => void;
}

function ChattingListItem({
  chatRoom,
  activeDropdown,
  onHandleDropdown,
  onHandleSelectChatRoom,
}: ChattingListItemAdvanceProps) {
  const {
    id,
    realTitle,
    counselorID,
    type,
    counselors,
    chatRoomParentHServiceID,
    patientIDInChattingListUserID,
    lastMessageUpdatedAt,
    isBadge,
    isColorAfterDocIcon,
    isKakaoIcon,
    isGrayAfterDocIcon,
    isLeft,
    isTranslated,
    translatedContent,
    content,
    chatRoomHserviceID,
  } = useParsedChatRoomData(chatRoom);

  const chatRoomID = useAtomValue(chatRoomIDSelector);

  const [dropdownPosition, setDropdownPosition] = useState<{ top: number; left: number } | null>(
    null,
  );

  const chattingListItemRef = useRef<HTMLDivElement | null>(null);

  const isDropdownVisible = useCallback(
    (key: ActiveDropdown['key']) => {
      return activeDropdown?.key === key && activeDropdown.chatRoomID === id;
    },
    [activeDropdown, id],
  );

  const dropdwonDataParams = { id, isColorAfterDocIcon, isKakaoIcon, isGrayAfterDocIcon };

  const isParentDropdownVisible = isDropdownVisible('parent');
  const isChildDropdownVisible = isDropdownVisible('children');

  const handleRightClick = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();

      if (isParentDropdownVisible) {
        setDropdownPosition(null);
        onHandleDropdown({ key: 'parent', chatRoomID: id }, false);
        return;
      }
      const element = chattingListItemRef.current;
      if (!element) return;

      const boundingRect = element.getBoundingClientRect();
      const clickX = event.clientX - boundingRect.left;
      const clickY = event.clientY - boundingRect.top;

      const adjustedLeft = Math.min(clickX, 280);

      setDropdownPosition({
        top: clickY,
        left: adjustedLeft,
      });

      onHandleDropdown({ key: 'parent', chatRoomID: id }, true);
    },
    [isParentDropdownVisible, onHandleDropdown, id],
  );

  const closeDropdown = () => {
    setDropdownPosition(null);
    onHandleDropdown({ key: 'parent', chatRoomID: id }, false);
  };

  const previewMessage = useMemo(() => {
    const textContent = isLeft || !isTranslated ? content : translatedContent;

    const displayContent = displayTextContent(type);

    return displayContent || textContent || '';
  }, [isLeft, isTranslated, translatedContent, content, type]);

  const formatedUpdatedAt = useMemo(() => {
    const updatedAt = dayjs(lastMessageUpdatedAt);
    const isToday = updatedAt.isSame(dayjs(), 'day');
    return isToday ? updatedAt.format('HH:mm') : updatedAt.format('YYYY-MM-DD');
  }, [lastMessageUpdatedAt]);

  const currentItemIconSet = useMemo(() => {
    return {
      isColorAfterDocIcon,
      isGrayAfterDocIcon,
      isKakaoIcon,
      isPendingService: false,
    };
  }, [isColorAfterDocIcon, isGrayAfterDocIcon, isKakaoIcon]);

  return (
    <>
      {isParentDropdownVisible && dropdownPosition && (
        <ParentDropdown
          selectedName={realTitle}
          selectedIconSet={currentItemIconSet}
          triggerPosition={dropdownPosition}
          inChattingListchatRoomID={id}
          inChattingListpatientID={chatRoomParentHServiceID}
          inChattingCounselors={counselors}
          handleToggle={closeDropdown}
          isReservationDisabled={!isColorAfterDocIcon && !isGrayAfterDocIcon}
          dropdownDataParams={dropdwonDataParams}
        />
      )}
      <div
        key={id}
        className={`relative cursor-pointer ${chatRoomID === id ? 'bg-blue50' : ''}`}
        onClick={() =>
          onHandleSelectChatRoom({
            chatRoomID: id,
            chatRoomParentHServiceID,
            patientIDInChattingListUserID,
            chatRoomHserviceID,
          })
        }>
        <div
          className='flex h-86 flex-col gap-2 px-20 py-12'
          ref={chattingListItemRef}
          onContextMenu={handleRightClick}>
          <div className='flex h-24 flex-shrink-0 gap-10'>
            <div className='flex h-18 flex-grow items-center gap-8 overflow-hidden'>
              <span className='overflow-hidden text-ellipsis whitespace-nowrap text-Header14 text-black700'>
                {realTitle}
              </span>
              <div className='flex gap-4'>
                {isColorAfterDocIcon && <Icon name='afterdoc' />}
                {isGrayAfterDocIcon && <Icon name='afterdoc-non' />}
                {isKakaoIcon && <Icon name='kakaotalk' />}
              </div>
            </div>
            <div className='flex flex-shrink-0'>
              <Badge isBadge={chatRoomID !== id ? isBadge : false}>
                <ParticipantsChip
                  title={realTitle}
                  isNextDropdownOpen={isChildDropdownVisible}
                  counselorID={counselorID}
                  onHandleDropdown={onHandleDropdown}
                  eachChattingRoomID={id}
                  counselors={counselors}
                />
              </Badge>
            </div>
          </div>
          <div className='flex h-36 flex-row justify-between'>
            <span className='line-clamp-2 w-[280px] text-Body12 text-black200'>
              {previewMessage}
            </span>
            <span className='flex content-end items-end text-Caption9 text-black200'>
              {formatedUpdatedAt}
            </span>
          </div>
        </div>
        {chatRoomID === id && <div className='absolute top-0 bottom-0 left-0 w-2 bg-blue500' />}
      </div>
    </>
  );
}

export default memo(ChattingListItem);
