interface StimulateMusclesInfoProps {
  filePaths: string[];
}

export default function StimulateMusclesInfo({ filePaths }: StimulateMusclesInfoProps) {
  return (
    <div className='mt-10 flex flex-col gap-10 '>
      {filePaths?.map((path, index) => (
        <img
          key={path}
          src={path}
          alt={`적용/자극부위 ${index}번째 이미지`}
          className='h-full w-full rounded-r16'
        />
      ))}
    </div>
  );
}
