import { useChatMessageContext } from '../ChatMessageContext';

export default function SystemContentMessage() {
  const { content, messageWidthStyle } = useChatMessageContext();
  return (
    <div className='rounded-r10 bg-purple500 px-16 py-10 shadow-modal' style={messageWidthStyle}>
      <div className='whitespace-pre-wrap break-all bg-purple500 text-Body12 text-white50'>
        {content}
      </div>
    </div>
  );
}
