import { apiClient } from 'web/apis/instances/api-client';

export const postChatroomUserRemove = async ({
  chatRoomID,
  userIDs,
}: { chatRoomID: string; userIDs: string[] }): Promise<void> => {
  const params = {
    chatRoomID,
    userIDs,
  };
  try {
    await apiClient.v3.apiChatroomElUserRemove(params);
  } catch (error) {
    console.error(error);
  }
};
