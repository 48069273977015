import ContainedButton from '@afterdoc-design-system/components/Atoms/Button/ContainedButton';
import IconButton from '@afterdoc-design-system/components/Atoms/Button/IconButton';
import { toastService } from '@afterdoc-design-system/components/Atoms/Toast/Toast.service';
import TextFieldSelectBoxDropdown from '@afterdoc-design-system/components/Molecules/TextSelect/TextFieldSelectBoxDropdown';
import { SHARED_UTILS } from '@shared-utils/utils';
import { useAtomValue, useSetAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { apiClient } from 'web/apis/instances/api-client';
import type { LanguageNameAndAlpha2 } from 'web/apis/swaggers/swagger-docs';
import FilePicker, { type FilePickerHandle } from 'web/shared/components/FilePicker/FilePicker';
import { useUserInfo } from 'web/shared/hooks/use-user-info';
import { FileUploadController } from 'web/shared/utils/fileUpload/FileUploadController';
import { FileService } from 'web/shared/utils/fileUpload/services/FileServices';
import type { FileUploadResponse } from 'web/shared/utils/fileUpload/types/types';
import { ROOM_TYPE_MAP } from 'web/templates/CustomerChat/components/ChattingList/constants/room-type';
import { useLanguageList } from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingSending/components/ChattingSendingBottom/hooks/use-language-list';
import { REQUEST_ROOM_TYPE_MAP } from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingSending/constants/types-codes';
import { useLanguageCode } from 'web/templates/CustomerChat/components/ChattingRoom/hooks/use-language-code';
import { selectedMessageIDState } from 'web/templates/CustomerChat/components/SupportBot/states/selected-message-id';
import { usePatient } from 'web/templates/CustomerChat/hooks/use-patient';
import {
  roomInfoSelector,
  roomTypeSelector,
} from 'web/templates/CustomerChat/states/selected-chat-room';
import { translateLanguageState } from 'web/templates/CustomerChat/states/translate-language';
import type { ImageChattingResponse } from 'web/templates/CustomerChat/types';
import { isActivateChatTranslateAtom } from '../../../../states/isChatTranslate';
import { MESSAGE_TYPE_CODE } from '../../../ChattingContainer/components/ChattingContent/components/ChattingMessage/constants/message';
import { useHandleChatSocket } from '../../../ChattingContainer/components/ChattingContent/hooks/use-handle-chat-socket';
import { chattingRequestState } from '../../../ChattingContainer/states/chatting-request';
import { isSendingMessagesState } from '../../../ChattingContainer/states/isSendingMessages';
import type { TempSendFile } from '../../types/send-file';
import { kakaoUploadFile, kakaoUploadImage } from './apis/kakao-upload';
import { createMockingFileIDs } from './functions/create-mocking-file-ids';
interface ChattingSendingBottomProps {
  disabled: boolean;
  toggleEmojiPicker: (iconButtonRef: HTMLButtonElement | null) => void;
  onClickSend: () => void;
  textAreaRef: React.RefObject<HTMLTextAreaElement>;
  languageID?: LanguageNameAndAlpha2;
  chatRoomID: string;
}

export default function ChattingSendingBottom({
  disabled,
  toggleEmojiPicker,
  onClickSend,
  textAreaRef,
  languageID,
  chatRoomID,
}: ChattingSendingBottomProps) {
  const isActivateChatTranslate = useAtomValue(isActivateChatTranslateAtom);
  const { userId, userType, name, realName, jobType, photo } = useUserInfo();
  const roomInfo = useAtomValue(roomInfoSelector);
  const roomType = useAtomValue(roomTypeSelector);

  const { closeReason } = usePatient();

  const isClosedKakaoChat = roomType === ROOM_TYPE_MAP.kakao ? roomInfo.isClosed : false;
  const isGrayAfterDocIcon = closeReason?.length !== 0;

  const isDisabledSendingBottom = isClosedKakaoChat || isGrayAfterDocIcon;

  const resetSelectedMessageId = useResetAtom(selectedMessageIDState);
  const setReq = useSetAtom(chattingRequestState);
  const setIsSendingMessages = useSetAtom(isSendingMessagesState);
  const setTranslateLanguage = useSetAtom(translateLanguageState);

  const filePickerRef = useRef<FilePickerHandle>(null);
  const emojiButtonRef = useRef<HTMLButtonElement>(null);
  const isComposingRef = useRef(false);

  const { data } = useLanguageList();
  const options = data.map((item) => {
    const { koreanLanguageName } = item;
    return `${koreanLanguageName}로 변환`;
  });

  const { languageCodeISOAlpha2 } = useLanguageCode(languageID);
  const { sendMessage } = useHandleChatSocket({ enableListening: false });
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleCompositionStart = () => {
    isComposingRef.current = true;
  };

  const handleCompositionEnd = () => {
    isComposingRef.current = false;
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    event.stopPropagation();

    if (
      event.key === 'Enter' &&
      textAreaRef.current &&
      document.activeElement === textAreaRef.current &&
      !isComposingRef.current
    ) {
      if (event.shiftKey) return; // Shift + Enter인 경우 줄바꿈을 허용

      event.preventDefault();
      onClickSend();
    }
  };

  useEffect(() => {
    if (languageCodeISOAlpha2) {
      const index = data.findIndex((item) => item.languageCodeISOAlpha2 === languageCodeISOAlpha2);
      setSelectedIndex(index);
    }
  }, [languageCodeISOAlpha2]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const textarea = textAreaRef.current;

    if (textarea) {
      textarea.addEventListener('compositionstart', handleCompositionStart);
      textarea.addEventListener('compositionend', handleCompositionEnd);
      textarea.addEventListener('keydown', handleKeyDown);

      return () => {
        textarea.removeEventListener('compositionstart', handleCompositionStart);
        textarea.removeEventListener('compositionend', handleCompositionEnd);
        textarea.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [textAreaRef, onClickSend]); //맨 처음 실행되면 enter가 미동작그래서 onClinckSend를 넣어줌

  const handleClick = () => {
    filePickerRef.current?.clickMultipleFile();
  };

  const handleFileChange = async (files: File[]) => {
    const userName = name ?? '';
    const userRealName = realName ?? '';
    const userJobType = jobType ?? '';
    const filePath = photo as unknown as TempSendFile;
    const photoPath = filePath?.paths[0];

    const { pf_id, user_key } = roomInfo;

    const filteredFiles = files.filter((file) => {
      const isValidateFileSize = FileService.validateFileSize(file);
      const isNoZeroFileSize = FileService.isSizeNoZero(file);

      !isValidateFileSize && toastService.errorMsg({ text: '용량 초과로 전송을 실패했습니다.' });
      !isNoZeroFileSize && toastService.errorMsg({ text: '용량이 0인 파일은 전송할 수 없습니다.' });

      return isValidateFileSize && isNoZeroFileSize;
    });

    setIsSendingMessages(true);
    resetSelectedMessageId();

    switch (roomType) {
      case ROOM_TYPE_MAP.kakao: {
        const imageAndFileDataForMocking: ImageChattingResponse[] = [];

        const imageFiles = filteredFiles.filter(FileService.isImageFile);

        const mockingPayload = {
          chatRoomID,
          userType,
          userName,
          userRealName,
          userID: userId,
          jobType: userJobType,
          fileIDs: [],
          type: MESSAGE_TYPE_CODE.IMAGE_MOCKING,
          photoPath: '',
          createdAt: '',
          isSent: true,
          markers: [''],
          sentAt: '',
          updatedAt: '',
          videoContentIDs: [''],
          _id: '',
          _v: 0,
        };

        const imagesDataWithMockingType = imageFiles.map((file) => {
          return {
            ...mockingPayload,
            _id: uuidv4(),
            fileIDs: createMockingFileIDs([file]),
          };
        });

        imageAndFileDataForMocking.push(...imagesDataWithMockingType);

        if (imageFiles.length !== 0) {
          const payload = {
            pf_id,
            user_key,
            userType,
            userName,
            userRealName,
            userID: userId,
            jobType: userJobType,
            type: 300,
            photoPath,
            images: imageFiles,
          };

          kakaoUploadImage(payload);
        }
        const nonImageFiles = filteredFiles.filter((file) => !FileService.isImageFile(file));

        if (nonImageFiles.length !== 0) {
          const filePayload = {
            pf_id,
            user_key,
            userType,
            userName,
            userRealName,
            userID: userId,
            jobType: userJobType,
            type: 500,
            photoPath,
            files: nonImageFiles,
          };

          const filesDataWithMockingType = [];

          const mockingFileIDs = createMockingFileIDs(nonImageFiles);
          const filesData = await kakaoUploadFile(filePayload);

          //_id값 filesData에 존재
          for (const idx in filesData) {
            filesDataWithMockingType.push({
              ...filesData[idx],
              type: MESSAGE_TYPE_CODE.FILE_MOCKING,
              fileIDs: [mockingFileIDs[Number(idx)]],
            });
          }

          imageAndFileDataForMocking.push(...filesDataWithMockingType);
        }

        setReq({
          type: REQUEST_ROOM_TYPE_MAP.KAKAO.IMAGES_FILES_TYPE,
          payload: imageAndFileDataForMocking,
        });
        break;
      }
      case ROOM_TYPE_MAP.afterdoc: {
        const imageFiles = filteredFiles.filter(FileService.isImageFile);
        if (imageFiles.length !== 0) {
          const mockingPayload = {
            chatRoomID,
            userType,
            userName,
            userRealName,
            userID: userId,
            jobType: userJobType,
            fileIDs: imageFiles.map((file) => file.name),
            type: MESSAGE_TYPE_CODE.IMAGE_MOCKING,
            photoPath,
          };

          setReq({
            type: REQUEST_ROOM_TYPE_MAP.AFTERDOC.FILE_TYPE,
            payload: { ...mockingPayload, _id: uuidv4() },
          });

          const callback = async (result: FileUploadResponse) => {
            if (result.code !== 0 || !result.data) return;

            const { data } = result;

            for (const file of data) {
              if (chatRoomID === null) return;

              const { _id: fileIDsBundleID, type } = file;
              const payload = {
                ...mockingPayload,
                fileIDs: [fileIDsBundleID],
                type,
              };

              //payload 자체에 _id 값을 넣으면 문제 발생
              sendMessage({
                type: REQUEST_ROOM_TYPE_MAP.AFTERDOC.FILE_TYPE,
                payload,
              });
            }
          };
          FileUploadController.uploadFiles({
            files: imageFiles,
            userID: userId,
            callback,
          });
        }
        const nonImageFiles = filteredFiles.filter((file) => !FileService.isImageFile(file));
        if (nonImageFiles.length !== 0) {
          const callback = async (result: FileUploadResponse) => {
            if (result.code !== 0 || !result.data) return;

            const { data } = result;

            const mockingFileIDs = createMockingFileIDs(nonImageFiles);
            const mockingPayload = {
              chatRoomID,
              userType,
              userName,
              userRealName,
              userID: userId,
              jobType: userJobType,
              fileIDs: mockingFileIDs,
              type: MESSAGE_TYPE_CODE.FILE_MOCKING,
              photoPath,
            };

            setReq({
              type: REQUEST_ROOM_TYPE_MAP.AFTERDOC.FILE_TYPE,
              payload: { ...mockingPayload, _id: uuidv4() },
            });

            for (const file of data) {
              if (chatRoomID === null) return;

              const { _id: fileIDsBundleID, type } = file;
              const payload = {
                ...mockingPayload,
                fileIDs: [fileIDsBundleID],
                type,
              };
              sendMessage({
                type: REQUEST_ROOM_TYPE_MAP.AFTERDOC.FILE_TYPE,
                payload,
              });
            }
          };

          FileUploadController.uploadFiles({
            files: nonImageFiles,
            userID: userId,
            callback,
          });
        }

        break;
      }
      default:
        console.error('Unsupported room type');
        break;
    }
  };

  const handleSelectLanguage = async (index: number) => {
    const newLanguageCodeISOAlpha2 = data[index].languageCodeISOAlpha2;
    const postChatroomTranslateLanguage = async () => {
      const { data } = await apiClient.v3.apiChatroomElUpdate({
        _id: chatRoomID,
        language: newLanguageCodeISOAlpha2,
      });
      return SHARED_UTILS.api.checkApiResponse(data);
    };

    await postChatroomTranslateLanguage();

    setSelectedIndex(index);
    setTranslateLanguage(data[index].languageCodeISOAlpha2);
  };

  return (
    <div className='mr-10 mb-10 ml-15 flex items-center justify-between'>
      <FilePicker ref={filePickerRef} onFileChange={handleFileChange} />
      <div className={'flex items-center gap-8'}>
        <IconButton
          size={20}
          icon={'add-circle'}
          color={'white800'}
          disabled={isDisabledSendingBottom}
          onClick={handleClick}
        />
        <IconButton
          size={20}
          icon={'emoji-outline'}
          color={'white800'}
          onClick={() => toggleEmojiPicker(emojiButtonRef.current)}
          className='mr-12'
          disabled={isDisabledSendingBottom}
          ref={emojiButtonRef}
        />
        {isActivateChatTranslate && (
          <TextFieldSelectBoxDropdown
            width={116}
            inputClassName='h-20 text-Body10 !rounded-[5px] pl-8'
            options={options}
            dropdownWrapperClassName='absolute bottom-20 mb-2'
            manualInput={false}
            onSelect={handleSelectLanguage}
            selectedIndex={selectedIndex}
            focusedIndex={selectedIndex}
            disabled={isDisabledSendingBottom}
          />
        )}
      </div>
      <div className='flex items-center'>
        {!isGrayAfterDocIcon && (
          <ContainedButton
            className='flex items-center'
            buttonSize='big'
            disabled={disabled}
            onClick={onClickSend}
            iconProps={{ name: 'send', color: 'white50' }}>
            전송
          </ContainedButton>
        )}
      </div>
    </div>
  );
}
