import ContainedButton from '@afterdoc-design-system/components/Atoms/Button/ContainedButton';
import OutlinedButton from '@afterdoc-design-system/components/Atoms/Button/OutlinedButton';
import BaseLoading from '@afterdoc-design-system/components/Atoms/Loading/BaseLoading';
import { modalService } from '@afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { useClickOutside } from '@shared-hooks/use-click-outside';
import { SHARED_UTILS } from '@shared-utils/utils';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { useQueryClient } from '@tanstack/react-query';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import { useCallback, useId, useMemo, useRef } from 'react';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import { SUCCESS } from 'web/shared/constants/codes';
import { useUserInfo } from 'web/shared/hooks/use-user-info';
import { useChatRoomInfoOne } from 'web/templates/CustomerChat/components/ChattingRoom/hooks/use-chatroom-info-one';
import { usePatient } from 'web/templates/CustomerChat/hooks/use-patient';
import { USER_TYPE_CODE } from '../../../ChattingRoom/components/ChattingContainer/components/ChattingContent/components/ChattingMessage/constants/message';
import { WIDTH } from '../../constants/dropdown';
import { createTopPosition } from './functions/create-top-position';
import { displayAddNames } from './functions/display-add-names';
import { postChatroomCounselerAdd } from './functions/postChatroomCounselerAdd';
import { handleError, showToastMessage } from './functions/response-handle';
import { useGetHworkers } from './hooks/use-get-hworkers';
import { useRenderingPosition } from './hooks/use-rendering-position';
import { useToggleSelect } from './hooks/use-toggle-select';

type DropdownProps = {
  title?: string;
  handleToggle: () => void;
  triggerPosition: { top: number; left: number };
  isOnMouseLeave: boolean;
  eachChattingRoomID: string;
};

export default function ChildDropdown({
  title,
  handleToggle,
  triggerPosition,
  isOnMouseLeave,
  eachChattingRoomID,
}: DropdownProps) {
  if (!eachChattingRoomID) return null;
  const queryClient = useQueryClient();
  const { top, left } = triggerPosition;

  const id = useId();

  const { userId: userID } = useUserInfo();
  const { name } = usePatient();

  const dropdownRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  //TODO - 서비스 매니저들이 포함될 수 있는지 확인 필요
  const { counselors, isLoading } = useChatRoomInfoOne({
    chatRoomID: eachChattingRoomID,
  });

  //담당자 추가 하는 후보들은 병원 모든 직원들
  const { hworkers } = useGetHworkers();

  const initialSelectedIDs = useMemo(() => {
    return counselors.map((counselor) => counselor._id);
  }, [counselors]);

  const { selectedUserIDs, toggleSelect, isInitialSelected, isSelected } =
    useToggleSelect(initialSelectedIDs);

  const user = counselors.find(({ _id }) => _id === userID);

  const newHworkders = user === undefined ? hworkers : hworkers.filter(({ _id }) => _id !== userID);
  const childDropdownOptionsForDisplayUsers = newHworkders.filter(
    ({ type }) => Number(type) !== USER_TYPE_CODE.PATIENT,
  );

  const dropdownHeight = useMemo(() => {
    return childDropdownOptionsForDisplayUsers.length > 0
      ? Math.min(32 * childDropdownOptionsForDisplayUsers.length, 170)
      : 40;
  }, [childDropdownOptionsForDisplayUsers]);

  const { position } = useRenderingPosition({
    renderingRef: dropdownRef,
    renderingHeight: dropdownHeight,
  });

  useClickOutside({
    id,
    ref: dropdownRef,
    contentRef,
    onClose: handleToggle,
  });

  const onMouseLeaveOption = useCallback(() => {
    isOnMouseLeave && handleToggle();
  }, [isOnMouseLeave, handleToggle]);

  const handleConfirmClick = async (onlyAddIDs: string[]) => {
    try {
      const { code } = await postChatroomCounselerAdd({
        chatRoomID: eachChattingRoomID,
        onlyAddIDs,
      });
      const isSuccess = code === SUCCESS;

      showToastMessage(isSuccess);

      if (isSuccess) {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.apiChatroomElFind],
        });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.apiChatroomElFindOne],
        });

        handleToggle();

        modalService.pop();
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleModal = (e: React.MouseEvent) => {
    e.stopPropagation();

    const onlyAddIDs = selectedUserIDs.filter((id) => !initialSelectedIDs.includes(id));

    //TODO - 태웅님에게 모달 관련 질문 하면서 리펙토링
    modalService.push({
      id: 'add-user-confirm-modal',
      width: 384,
      titleProps: {
        title: '담당자를 추가할까요?',
      },
      contents: (
        <div className='w-full flex-col'>
          <span className='text-Body12 text-black500'>
            {title ?? name}님 채팅방에 {displayAddNames(onlyAddIDs, hworkers)}님을 담당자로
            추가합니다.
          </span>
          <div className='flex-center gap-8 pt-20 pb-15 '>
            <OutlinedButton
              className='flex-1'
              onClick={() => {
                modalService.pop();
              }}>
              취소
            </OutlinedButton>
            <ContainedButton
              className='flex-1'
              btnColor='blue'
              onClick={() => handleConfirmClick(onlyAddIDs)}>
              확인
            </ContainedButton>
          </div>
        </div>
      ),
    });
  };

  const isDisabled = useMemo(() => {
    return initialSelectedIDs.length === selectedUserIDs.length;
  }, [initialSelectedIDs, selectedUserIDs]);

  return (
    <div
      ref={dropdownRef}
      className='relative'
      style={{ zIndex: 200 }}
      onMouseLeave={onMouseLeaveOption}>
      {
        <div
          ref={contentRef}
          className={
            'absolute h-fit w-full select-none rounded-r6 bg-white50 py-4 pt-4 shadow-modal ring-1 ring-black ring-opacity-5'
          }
          data-ignore-click-outside={true}
          style={{
            top: createTopPosition({
              top,
              dropdownHeight: dropdownHeight,
              offset: 24 + (isLoading ? 0 : 26) + 10, // -(ParticipantsChip 높이 + 드롭다운 '추가'버튼 높이 + default 5+5)
              position,
            }),
            left,
            width: SHARED_UTILS.css.getCssSizeValue(WIDTH),
          }}>
          {isLoading ? (
            <div className='flex flex-center' style={{ height: dropdownHeight }}>
              <BaseLoading />
            </div>
          ) : (
            <div style={{ height: dropdownHeight }}>
              <Scrollbar disabledX>
                <ul aria-orientation='vertical' aria-labelledby='options-menu' className='relative'>
                  {user && (
                    <li
                      key={user._id}
                      className={customTwMerge(
                        'flex items-center justify-between px-10 py-7 text-Header12 text-disabled',
                      )}>
                      <span>{`${user.realName}(나)`}</span>
                      <Icon name='done' color='blue500' size={16} className={'flex-shrink-0 '} />
                    </li>
                  )}
                  {childDropdownOptionsForDisplayUsers.map(({ _id: id, realName }, index) => (
                    <li
                      key={id}
                      tabIndex={index}
                      onClick={(e) => toggleSelect(e, id)}
                      className={customTwMerge(
                        'flex h-30 cursor-pointer items-center justify-between px-10 py-7 text-Body12 hover:bg-blue50 hover:text-Header12 focus:bg-blue50 focus:outline-none',
                        isSelected(id) ? 'text-Header12' : 'text-Body12',
                        isInitialSelected(id) && 'text-disabled',
                      )}
                      item-index={index}
                      aria-selected={isSelected(id) ? 'true' : undefined}>
                      <span className='line-clamp-1'>{realName}</span>
                      {(isSelected(id) || isInitialSelected(id)) && (
                        <Icon name='done' color='blue500' size={16} className={'flex-shrink-0 '} />
                      )}
                    </li>
                  ))}
                </ul>
              </Scrollbar>
            </div>
          )}
          {!isLoading && (
            <button
              disabled={isDisabled}
              type='button'
              onClick={handleModal}
              className={`flex h-26 w-full items-center justify-center text-Body12 ${
                isDisabled
                  ? 'cursor-not-allowed bg-white50 text-disabled'
                  : 'bg-white50 text-blue500 hover:bg-blue50'
              }`}>
              추가
            </button>
          )}
        </div>
      }
    </div>
  );
}
