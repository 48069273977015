import ReceiveTimeSetting from '@templates/UserInfoSetting/containers/NotificationSetting/components/NotificationTimeRangeControl/ReceiveTimeSetting/ReceiveTimeSetting';
import { useNotificationSetting } from '@templates/UserInfoSetting/containers/NotificationSetting/hooks/use-notification-setting';
import { notificationSettingErrorState } from '@templates/UserInfoSetting/containers/NotificationSetting/states/notification-setting-error-state';
import type {
  BooleanSettingKey,
  StringSettingKey,
  TimeSettingControlKeys,
} from '@templates/UserInfoSetting/containers/NotificationSetting/types/res-notification-setting-type';
import Checkbox from 'afterdoc-design-system/components/Atoms/Checkbox/Checkbox';
import { useAtom } from 'jotai';
import { useCallback, useEffect } from 'react';

interface NotificationTimeRangeControlProps {
  fieldKey: BooleanSettingKey;
  startTimeKey: StringSettingKey;
  endTimeKey: StringSettingKey;
}

export default function NotificationTimeRangeControl({
  fieldKey,
  startTimeKey,
  endTimeKey,
}: NotificationTimeRangeControlProps) {
  const { getValue, setValue } = useNotificationSetting();

  const [notificationSettingError, setNotificationSettingError] = useAtom(
    notificationSettingErrorState,
  );

  const fieldValue = getValue(fieldKey) ?? false;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    resetErrorState();
    setValue(fieldKey, e.target.checked);
  };

  const resetErrorState = useCallback(() => {
    setNotificationSettingError((prev) => ({
      ...prev,
      [fieldKey as keyof TimeSettingControlKeys]: false,
    }));
  }, [fieldKey]);

  useEffect(() => {
    return () => resetErrorState();
  }, []);

  return (
    <div className='mt-6 flex flex-col gap-6'>
      <div className='flex items-center gap-16'>
        <Checkbox label={'특정 시간에만 알림 받기'} checked={fieldValue} onChange={handleChange} />
        <ReceiveTimeSetting
          isActive={fieldValue}
          startTimeKey={startTimeKey}
          endTimeKey={endTimeKey}
          onHandleChange={resetErrorState}
        />
      </div>
      {notificationSettingError[fieldKey as keyof TimeSettingControlKeys] && (
        <span className='text-Body10 text-red500'>종료시간이 시작시간보다 빠릅니다.</span>
      )}
    </div>
  );
}
