import type { ErrorMessage } from 'react-use-downloader/dist/types';

export const progressStatusDisplay = ({
  progress,
  error,
}: { progress: number; error: ErrorMessage | null }) => {
  if (error) {
    return 'error';
  }
  const isComplete = progress === 100;
  return isComplete ? 'complete' : 'downloading';
};
