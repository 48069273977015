import type { ApiPushRequestElFindParams } from '@apis/swaggers/swagger-docs';
import { atom } from 'jotai/index';
import { atomWithReset } from 'jotai/utils';

export const eventPushRequestListQueryKeyState = atomWithReset<ApiPushRequestElFindParams>({
  hospitalID: '',
  hospitalEventID: '',
  type: 'HospitalEvent',
  skip: 0,
  limit: 10,
  key: 'createdAt',
  way: -1,
});

export const eventPushRequestListSkipNumAtom = atom(
  (get) => get(eventPushRequestListQueryKeyState).skip ?? 0,
  (get, set, newSkip: number) => {
    const currentState = get(eventPushRequestListQueryKeyState);
    set(eventPushRequestListQueryKeyState, { ...currentState, skip: newSkip });
  },
);
