import Scrollbar from '@afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { useCallback, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import type {
  CreateMemosAPIFormValues,
  UpdateMemosAPIFormValues,
} from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/CustomerInfoInputBoard/ManagementDirection/ManagementDirectionDialog.container';

interface ManagementDirectionMemoContentEditorProps {
  selectedManagementDirectionId?: string;
  disabled?: boolean;
}

export default function ManagementDirectionMemoContentEditor({
  selectedManagementDirectionId,
  disabled,
}: ManagementDirectionMemoContentEditorProps) {
  const { watch, setValue } = useFormContext<CreateMemosAPIFormValues | UpdateMemosAPIFormValues>();

  const managementDirectionContent = watch('content');

  const handleTextareaInput = useCallback(
    (value: string) => {
      setValue('content', value);
    },
    [setValue],
  );

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (!selectedManagementDirectionId) {
      textareaRef.current?.focus();
    }
  }, [selectedManagementDirectionId]);

  return (
    <div
      className={customTwMerge(
        'relative h-full w-full rounded-r10 border border-white600 py-16',
        disabled ? 'bg-white200' : 'bg-white50',
      )}>
      <Scrollbar>
        <div className={customTwMerge('h-[522px] w-full pr-4 pl-16 text-Body12')}>
          <textarea
            ref={textareaRef}
            placeholder={
              disabled ? '좌측 관리방향 추가 버튼을 눌러주세요.' : '내용을 입력해 주세요.'
            }
            value={managementDirectionContent}
            onChange={(e) => handleTextareaInput(e.target.value)}
            disabled={disabled}
            className='h-[522px] w-full resize-none border-none bg-transparent pt-0 text-Body12 text-black700 placeholder-black200 outline-none placeholder:text-Body12 placeholder:text-black200 disabled:bg-white200'
            rows={10}
          />
        </div>
      </Scrollbar>
    </div>
  );
}
