import type { ApiPatientsElDetailData } from 'web/apis/swaggers/swagger-docs';
import type {
  ALIntegrationUserData,
  ALResponse,
  SmartdoctorCustomerResponse,
  SmartdoctorCustomersResponse,
  responseDataDuplicatedCustomerNumber,
} from './type';

const parsePhoneString = (phone: string) => {
  if (!phone) return '-';
  let result = '';
  const str = phone.replace(/[^0-9]/g, '');
  if (str.length === 0) {
    result = '-';
  } else if (str.length < 4) {
    result = str;
  } else if (str.length < 7) {
    result = str.replace(/(\d{3})(\d{1,4})/, '$1-$2');
  } else if (str.length < 11) {
    result = str.replace(/(\d{3})(\d{3})(\d{1,4})/, '$1-$2-$3');
  } else {
    result = str.replace(/(\d{3})(\d{4})(\d{1,4})/, '$1-$2-$3');
  }
  return result;
};

const parseGenderString = (gender: string) => {
  if (gender === 'FEMALE') {
    return '여';
  }
  if (gender === 'MALE') {
    return '남';
  }
  return '-';
};

export const isALIntegrationData = (value: unknown): value is ALIntegrationUserData => {
  return (
    typeof value === 'object' &&
    value !== null &&
    'name' in value &&
    'patientId' in value &&
    'chartNumber' in value &&
    'birthdate' in value &&
    'gender' in value &&
    'phoneNumber' in value &&
    typeof (value as ALIntegrationUserData).name === 'string' &&
    typeof (value as ALIntegrationUserData).patientId === 'string' &&
    typeof (value as ALIntegrationUserData).chartNumber === 'string' &&
    typeof (value as ALIntegrationUserData).birthdate === 'string' &&
    typeof (value as ALIntegrationUserData).gender === 'string' &&
    typeof (value as ALIntegrationUserData).phoneNumber === 'string'
  );
};

export const parseAfterDocData = ({ data }: ApiPatientsElDetailData) => {
  return {
    patientId: data?.patientId ?? '-',
    name: data.name ?? '-',
    chartNumber: data.chartNumber ?? '-',
    birthdate: data.birthdate ?? '-',
    gender: parseGenderString(data.gender ?? '') ?? '-',
    phoneNumber: parsePhoneString(data.phoneNumber),
    ALCustomerNumber: data.ALCustomerNumber,
    ALCustomerNumbers: data.ALCustomerNumbers,
  } as ALIntegrationUserData;
};

export const parseAfterDocDatas = (datas: responseDataDuplicatedCustomerNumber[]) => {
  const array: ALIntegrationUserData[] = [];
  for (const data of datas) {
    array.push({
      _id: data._id ?? '-',
      patientId: data?.patientId ?? '-',
      name: data.name ?? '-',
      chartNumber: data.chartNumber ?? '-',
      birthdate: data.birthdate ?? '-',
      gender: parseGenderString(data.gender ?? '') ?? '-',
      phoneNumber: parsePhoneString(data.phone),
      ALCustomerNumber: data.ALCustomerNumber,
      ALCustomerNumbers: data.ALCustomerNumbers,
    } as unknown as ALIntegrationUserData);
  }

  return array;
};

export const parseSmartDoctorDatas = (response: ALResponse<SmartdoctorCustomersResponse>) => {
  // MARK: - TODO | Error Handle
  if (response.status !== 200) return;
  const data = response.data.customers;
  const result: ALIntegrationUserData[] = [];
  for (const d of data) {
    result.push({
      _id: d._id ?? '',
      name: d.name ?? '-',
      patientId: '',
      chartNumber: d.customerNumber,
      birthdate: d.citizenNumber.slice(0, 6),
      gender: parseGenderString(d.sex ?? '-'),
      phoneNumber: parsePhoneString(d.cellPhone ?? '-'),
      ALCustomerNumber: d.customerNumber,
      ALCustomerNumbers: undefined,
      isRepresented: false,
      isSelected: false,
    });
  }
  return result;
};

export const parseSmartDoctorData = (response: ALResponse<SmartdoctorCustomerResponse>) => {
  // MARK: - TODO | Error Handle
  if (response.status !== 200) return;
  const d = response.data.customer;
  return {
    _id: d._id ?? '',
    name: d.name ?? '-',
    patientId: '',
    chartNumber: d.customerNumber,
    birthdate: d.citizenNumber,
    gender: parseGenderString(d.sex ?? '-'),
    phoneNumber: parsePhoneString(d.cellPhone ?? '-'),
    ALCustomerNumber: d.customerNumber,
    ALCustomerNumbers: undefined,
    isRepresented: false,
    isSelected: false,
  };
};
