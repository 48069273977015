import type { EmojiClickData } from 'emoji-picker-react';
import { useCallback, useEffect, useRef, useState } from 'react';

export const useEmojiPicker = (
  setText: React.Dispatch<React.SetStateAction<string>>,
  textAreaRef?: React.RefObject<HTMLTextAreaElement>,
) => {
  const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false);
  const emojiPickerRef = useRef<HTMLDivElement>(null);

  const toggleEmojiPicker = useCallback((iconButtonRef: HTMLButtonElement | null) => {
    if (!iconButtonRef) return;

    setShowEmojiPicker((prevState) => !prevState);
  }, []);

  const onEmojiClick = useCallback(
    (emojiData: EmojiClickData) => {
      setText((prevText) => prevText + emojiData.emoji);
      textAreaRef?.current?.focus();
      setShowEmojiPicker(false);
    },
    [setText],
  );

  const handleClickOutside = (event: MouseEvent) => {
    if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target as Node)) {
      setShowEmojiPicker(false);
    }
  };
  // Close emoji picker when clicking outside
  useEffect(() => {
    if (showEmojiPicker) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showEmojiPicker]);

  return {
    showEmojiPicker,
    emojiPickerRef,
    toggleEmojiPicker,
    onEmojiClick,
  };
};
