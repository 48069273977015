import type { ALIntegrationUserData } from './hooks/type';

export const ALIntegrationEditPatientInfo = (data: ALIntegrationUserData) => {
  return (
    <div className='rounded-r16 bg-white100 px-10 py-10'>
      <div className='pt-6 pb-10 text-Header14'>애프터닥 등록 정보</div>
      <div
        style={{ width: '279px' }}
        className='flex flex-col gap-10 rounded-r10 border border-white600 bg-white50 p-16'>
        <div>
          <span className='text-Header14'>{data.name}</span>{' '}
          <span className='text-Body11'>{data.chartNumber}</span>
        </div>
        <div className='flex gap-10'>
          <span className='rounded-r6 bg-white100 px-8 text-Body12'>
            {data.gender}/{data.birthdate}
          </span>
          <span className='rounded-r6 bg-white100 px-8 text-Body12'>{data.phoneNumber}</span>
        </div>
      </div>
    </div>
  );
};
