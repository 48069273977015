import { Group } from '@afterdoc-design-system/assets/icons';
import { NavBar, Statusbar } from '@afterdoc-design-system/assets/images';
import Scrollbar from '@afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';
import { useFormContext } from 'react-hook-form';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import type { CounselAutomationAPIFormValues } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/components/RegisterCounselAutomationDialogContent';
import DisplayEventOrHomecare from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/components/PhoneView/components/DisplayEventOrHomecare';
import DisplayImages from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/components/PhoneView/components/DisplayImages';
import DisplaySurvey from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/components/PhoneView/components/DisplaySurvey';
import DisplayText from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/components/PhoneView/components/DisplayText';
import DisplayVideo from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/components/PhoneView/components/DisplayVideo';
import DisplayWorkoutVideo from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/components/PhoneView/components/DisplayWorkoutVideo';
import {
  selectedContentTabItemState,
  selectedMessageIdState,
  selectedMessageIndexState,
} from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/message-form';

export default function PhoneView() {
  const { name: hospitalName } = useSelectedHospitalInfo();

  const { watch, getValues } = useFormContext<CounselAutomationAPIFormValues>();

  const selectedMessageIndex = useAtomValue(selectedMessageIndexState);
  const selectedMessageId = useAtomValue(selectedMessageIdState);
  const selectedContentTabItem = useAtomValue(selectedContentTabItemState);

  const messageLists = getValues('messages');
  const messageSendingType = watch(`messages.${selectedMessageIndex}.sendingType`);
  const messageContent = watch(`messages.${selectedMessageIndex}.content`);

  if (!messageLists || !messageContent) return null;

  const messageText = messageContent.text;
  const messageImage = messageContent.image;
  const messageVideo = messageContent.video;
  const messageWorkoutVideo = messageContent.workoutVideo;
  const messageEventOrHomecare = messageContent.event || messageContent.homecareContent;
  const messageSurvey = messageContent.survey;
  const messageTimeOfDay = messageLists[selectedMessageIndex].timeOfDay;

  const isSurvey = messageSendingType === 'SURVEY';

  return (
    <div className='h-screen max-h-[722px] min-w-[150px] max-w-[370px] flex-col-center flex-grow overflow-hidden rounded-[24px] border-[5px] border-blueLight bg-blue50 shadow-modal'>
      <Statusbar className='w-full bg-white50' />
      <Scrollbar>
        <div className='h-full w-full'>
          <div className='flex items-start gap-8 px-16 py-24'>
            <div className='flex-shrink-0'>
              <Group width={38} height={38} />
            </div>
            <div className='flex flex-grow flex-col gap-8'>
              <span className='text-Body10Bold text-black500'>{hospitalName}</span>
              {!isSurvey && <DisplayText text={messageText} />}
              {!isSurvey && selectedContentTabItem?.[selectedMessageId] === '이미지' && (
                <DisplayImages messageImage={messageImage} />
              )}
              {!isSurvey && selectedContentTabItem?.[selectedMessageId] === '동영상' && (
                <DisplayVideo messageVideo={messageVideo} />
              )}
              {!isSurvey && selectedContentTabItem?.[selectedMessageId] === '홈케어콘텐츠' && (
                <DisplayWorkoutVideo messageWorkoutVideo={messageWorkoutVideo} />
              )}
              {!isSurvey && selectedContentTabItem?.[selectedMessageId] === '이벤트' && (
                <DisplayEventOrHomecare messageEventOrHomecare={messageEventOrHomecare} />
              )}
              {isSurvey && <DisplaySurvey messageSurvey={messageSurvey} />}
            </div>
            <span className='mt-auto w-fit flex-shrink-0 text-Body10 text-black200'>
              {dayjs(messageTimeOfDay, 'HH:mm').format('A hh:mm')}
            </span>
          </div>
        </div>
      </Scrollbar>
      <NavBar className='w-full bg-white50' />
    </div>
  );
}
