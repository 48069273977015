import * as React from "react";
import type { SVGProps } from "react";
const SvgListNumbers = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#7F7F7F"
      d="M5.833 10.833V9.166H17.5v1.667zm0 5v-1.667H17.5v1.667zm0-10V4.166H17.5v1.667zM2.5 6.666v-2.5h-.833v-.833h1.666v3.333zm-.833 7.5v-.833h2.5v3.333h-2.5v-.833h1.666v-.417H2.5v-.833h.833v-.417zm1.875-5.833a.625.625 0 0 1 .45 1.058L2.6 10.833h1.567v.833h-2.5V10.9l1.666-1.734H1.667v-.833z"
    />
  </svg>
);
export default SvgListNumbers;
