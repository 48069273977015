import ContainedButton from '@afterdoc-design-system/components/Atoms/Button/ContainedButton';
import { useSetAtom } from 'jotai';
import { useFormContext } from 'react-hook-form';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import { useSaveTemporaryUser } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementSaveFooter/hooks/save/use-save-temporary-user';
import { useSaveUser } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementSaveFooter/hooks/save/use-save-user';
import { useTemporaryPatientsLock } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementSaveFooter/utils/ use-temporary-patients-lock';
import { useTemporaryPatientsModifier } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementSaveFooter/utils/ use-temporary-patients-modifier';
import { modifierInfoState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/states/modifier-info';
import type { CustomerManagementTemporaryAPIFormValues } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/types/table';

export default function FinalSaveButton() {
  const { getValues } = useFormContext<{ rows: CustomerManagementTemporaryAPIFormValues }>();
  const { hospitalID } = useSelectedHospitalInfo();
  const { getPatientsIsLocked, patchRequestLockMutation } = useTemporaryPatientsLock();
  const { patchTemporaryPatientsLastModifierMutation } = useTemporaryPatientsModifier();
  const { handleTemporarySave } = useSaveTemporaryUser();
  const { handleSaveFinalUserInfo } = useSaveUser();

  const rows = getValues('rows');

  const setModifierInfo = useSetAtom(modifierInfoState);

  const handleSaveFinalUserInfoClick = async () => {
    try {
      const latestLockData = await getPatientsIsLocked({ hospitalID });

      if (!latestLockData) return;

      // 1. 먼저 lock 해제
      await patchRequestLockMutation.mutateAsync({
        hospitalID,
        isLock: false,
        version: latestLockData.version,
      });

      // 2. 임시 저장 실행
      const temporarySaveResult = await handleTemporarySave(rows);

      // 3. 최종 저장 실행
      if (temporarySaveResult) {
        await handleSaveFinalUserInfo();
      }

      // 4. 마지막 수정자 정보 업데이트
      const result = await patchTemporaryPatientsLastModifierMutation.mutateAsync({
        hospitalID,
      });

      // 5. 수정자 정보 상태 업데이트
      setModifierInfo({
        createdAt: result.createdAt,
        userID: result.userID,
      });
    } catch (error) {
      console.error('Error during save process:', error);
    }
  };

  return (
    <div className='flex items-center gap-10'>
      <div className='flex gap-4' />
      <ContainedButton btnColor='blue' onClick={handleSaveFinalUserInfoClick}>
        저장
      </ContainedButton>
    </div>
  );
}
