import { eventCreateOrUpdateParamState } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/states/event-create-or-update-param-state';
import type { CreateUpdateEventParamType } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/types/create-update-event-param-type';
import { homeCareCreateOrUpdateParamState } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/HomeCare/states/homecare-create-or-update-param-state';
import type { CreateUpdateHomeCareParams } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/HomeCare/types/create-update-homecare-params';
import { useSetAtom } from 'jotai';
import { useCallback } from 'react';

export const useManageHandlers = <
  T extends CreateUpdateEventParamType | CreateUpdateHomeCareParams,
>(
  type: 'event' | 'homeCare',
) => {
  const setCreateOrUpdateParam = useSetAtom(
    type === 'event' ? eventCreateOrUpdateParamState : homeCareCreateOrUpdateParamState,
  );

  const handleParamChange = useCallback(
    <K extends keyof T>(key: K) => {
      return (value: T[K]) => {
        setCreateOrUpdateParam((prev) => {
          const updatedParams = {
            ...(prev as T),
            [key]: value,
          };

          if (key === 'isActive' && !value) {
            updatedParams.isPinned = false;
          }
          if (key === 'isPinned' && value) {
            updatedParams.isActive = true;
          }

          return updatedParams;
        });
      };
    },
    [setCreateOrUpdateParam],
  );

  const handleIsActiveChange = useCallback(
    (value: boolean) => handleParamChange('isActive')(value),
    [handleParamChange],
  );

  const handleIsPinnedChange = useCallback(
    (value: boolean) => handleParamChange('isPinned')(value),
    [handleParamChange],
  );

  const handleTitleChange = useCallback(
    (value: string) => handleParamChange('title')(value),
    [handleParamChange],
  );

  const handlePriceChange = useCallback(
    (value: number | undefined) => handleParamChange('price')(value),
    [handleParamChange],
  );

  const handleSalePriceChange = useCallback(
    (value: number) => handleParamChange('salePrice')(value),
    [handleParamChange],
  );

  const handleSaleRateChange = useCallback(
    (value: number) => handleParamChange('saleRate')(value),
    [handleParamChange],
  );

  const handleSaleTypeChange = useCallback(
    (value: string) => handleParamChange('saleType')(value),
    [handleParamChange],
  );

  const handleIsLimitedTimeChange = useCallback(
    (value: boolean) => handleParamChange('isLimitedTime')(value),
    [handleParamChange],
  );

  const handleStartDateChange = useCallback(
    (value: string) => handleParamChange('startDate')(value),
    [handleParamChange],
  );

  const handleEndDateChange = useCallback(
    (value: string) => handleParamChange('endDate')(value),
    [handleParamChange],
  );

  const handleDescriptionChange = useCallback(
    (value: string) => handleParamChange('description')(value),
    [handleParamChange],
  );

  const handleContentTypeChange = useCallback(
    (value: string) => handleParamChange('type')(value),
    [handleParamChange],
  );

  const handleContentInputChange = useCallback(
    (value: string) => handleParamChange('content')(value),
    [handleParamChange],
  );

  return {
    handleIsActiveChange,
    handleIsPinnedChange,
    handleTitleChange,
    handlePriceChange,
    handleSalePriceChange,
    handleSaleRateChange,
    handleSaleTypeChange,
    handleIsLimitedTimeChange,
    handleStartDateChange,
    handleEndDateChange,
    handleDescriptionChange,
    handleContentTypeChange,
    handleContentInputChange,
  };
};
