import type { PatientsField } from '@templates/HospitalSetting/types/PatientsField';
import { atomWithReset } from 'jotai/utils';

const initialPatientsField: PatientsField = {
  // usingCountryCode: false,
  usingBirthDay: false,
  usingGender: false,
  usingTreatmentTag: false,
  usingUserTag: false,
  usingFirstVisitStartDate: false,
  usingChartNumber: false,
  usingFieldNames: [],
  usingNationality: false,
};

export const displayedPatientsFieldsState = atomWithReset<PatientsField>(initialPatientsField);

export const initialDisplayedPatientsFieldsState =
  atomWithReset<PatientsField>(initialPatientsField);
