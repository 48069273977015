import type { Juso } from '@apis/swaggers/swagger-docs';
import AddressCardItem from '@templates/HospitalSetting/containers/HospitalProfile/components/HospitalAddress/components/AddressSearchDialog/components/AddressCardItem';
import { hospitalAddressQueryKeyState } from '@templates/HospitalSetting/containers/HospitalProfile/components/HospitalAddress/components/AddressSearchDialog/jotai/address-query-key';
import { hospitalInfoState } from '@templates/HospitalSetting/containers/HospitalProfile/states/hospital-info-state';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import { dialogService } from 'afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import { useSetAtom } from 'jotai/index';
import { useResetAtom } from 'jotai/utils';
import React from 'react';

interface AddressResultListProps {
  juso: Juso[] | undefined;
}

export default function AddressResultList({ juso }: AddressResultListProps) {
  const setHospitalInfo = useSetAtom(hospitalInfoState);
  const resetHospitalAddressQueryKey = useResetAtom(hospitalAddressQueryKeyState);

  const handleSelect = (juso: Juso) => {
    setHospitalInfo((prev) => ({
      ...prev,
      addressInfo: {
        ...prev.addressInfo,
        ...juso,
      },
    }));
    dialogService.pop();
    resetHospitalAddressQueryKey();
  };

  return (
    <>
      <Scrollbar disabledX={true} className='flex-grow'>
        {juso?.map((juso) => (
          <AddressCardItem
            key={`${juso.roadAddr}${juso.jibunAddr}`}
            juso={juso}
            onSelect={handleSelect}
          />
        ))}
      </Scrollbar>
    </>
  );
}
