import { S3Client } from '@aws-sdk/client-s3';

const BUCKET_NAME = 'afterdoc';
const REGION = 'ap-northeast-2';
const ACCESS_KEY_ID = import.meta.env.VITE_AWS_ACCESS_KEY_ID as string;
const SECRET_ACCESS_KEY = import.meta.env.VITE_AWS_SECRET_ACCESS_KEY as string;

const s3 = new S3Client({
  region: REGION,
  credentials: {
    accessKeyId: ACCESS_KEY_ID,
    secretAccessKey: SECRET_ACCESS_KEY,
  },
});

export { s3, REGION, BUCKET_NAME };
