import { SHARED_UTILS } from '@shared-utils/utils';
import { useQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { ChatRoomsLatestConsultationGuideHandlerParams } from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import { chatRoomIDSelector } from 'web/templates/CustomerChat/states/selected-chat-room';

export const getConsultationGuide = async (
  query: ChatRoomsLatestConsultationGuideHandlerParams,
) => {
  const { data } = await apiClient.v3.chatRoomsLatestConsultationGuideHandler(query);
  return SHARED_UTILS.api.checkApiResponse(data);
};

export const useGetAlgorithmGuide = () => {
  const chatRoomID = useAtomValue(chatRoomIDSelector);
  const { hospitalID } = useSelectedHospitalInfo();
  const query = {
    chatRoomID: chatRoomID ?? '',
    hospitalID,
  };

  const { data, error, isLoading } = useQuery({
    queryKey: [QUERY_KEY.chatRoomsLatestConsultationGuideHandler, query] as const,
    queryFn: ({ queryKey }) => getConsultationGuide(queryKey[1]),
    enabled: !!chatRoomID,
  });

  const consultationGuide = data?.consultationGuide ?? '';

  return { data, consultationGuide, error, isLoading };
};
