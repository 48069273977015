import * as React from "react";
import type { SVGProps } from "react";
const SvgLogoKakaotalk = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#logo-kakaotalk_svg__a)">
      <path fill="#F9E000" d="M24 0H0v24h24z" />
      <path
        fill="#371C1D"
        d="M11.976 4.401c-4.76 0-8.62 3.05-8.62 6.815 0 2.45 1.634 4.597 4.089 5.798-.18.672-.653 2.438-.747 2.816-.117.469.17.462.361.337l3.317-2.254q.78.116 1.6.117c4.761 0 8.62-3.05 8.62-6.814 0-3.763-3.859-6.815-8.62-6.815"
      />
      <path
        fill="#F9E000"
        d="M5.962 9.961h.887s.005 2.437 0 3.005c0 .415.923.42.921.007l.004-2.98s.298.003.882.003c.552 0 .552-.868 0-.868-.622 0-2.699-.018-2.699-.018-.52 0-.52.851 0 .851"
      />
      <mask
        id="logo-kakaotalk_svg__b"
        width={5}
        height={5}
        x={5}
        y={9}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: "luminance",
        }}
      >
        <path
          fill="#fff"
          d="M5.962 9.961h.887s.005 2.437 0 3.005c0 .415.923.42.921.007l.004-2.98s.298.003.882.003c.552 0 .552-.868 0-.868-.622 0-2.699-.018-2.699-.018-.52 0-.52.851 0 .851"
        />
      </mask>
      <g mask="url(#logo-kakaotalk_svg__b)">
        <path fill="#F9E000" d="M9.21 9.11H5.442v4.276h3.77z" />
      </g>
      <path
        fill="#F9E000"
        d="m10.209 10.118.493 1.546H9.673zm-.554-.725c-.218.48-1.007 2.751-1.268 3.353-.189.435.627.79.816.355l.177-.62h1.623s-.055.026.16.594c.174.46 1.017.142.843-.319-.25-.658-1.112-3.027-1.27-3.363-.072-.154-.332-.267-.584-.267-.209 0-.412.077-.498.267"
      />
      <mask
        id="logo-kakaotalk_svg__c"
        width={5}
        height={5}
        x={8}
        y={9}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: "luminance",
        }}
      >
        <path
          fill="#fff"
          d="m10.209 10.118.493 1.546H9.673zm-.554-.725c-.218.48-1.007 2.751-1.268 3.353-.189.435.627.79.816.355l.177-.62h1.623s-.055.026.16.594c.174.46 1.017.142.843-.319-.25-.658-1.112-3.027-1.27-3.363-.072-.154-.332-.267-.584-.267-.209 0-.412.077-.498.267"
        />
      </mask>
      <g mask="url(#logo-kakaotalk_svg__c)">
        <path fill="#F9E000" d="M12.181 9.128H8.197v4.41h3.984z" />
      </g>
      <path
        fill="#F9E000"
        d="M12.318 9.535c0 .771.005 3.258.005 3.258s-.072.495.353.495 1.364-.005 1.78-.005c.418 0 .416-.887 0-.887-.417 0-1.18-.005-1.18-.005s.004-2.3.002-2.86c0-.27-.24-.405-.48-.405-.239 0-.482.137-.48.407"
      />
      <mask
        id="logo-kakaotalk_svg__d"
        width={3}
        height={5}
        x={12}
        y={9}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: "luminance",
        }}
      >
        <path
          fill="#fff"
          d="M12.318 9.535c0 .771.005 3.258.005 3.258s-.072.495.353.495 1.364-.005 1.78-.005c.418 0 .416-.887 0-.887-.417 0-1.18-.005-1.18-.005s.004-2.3.002-2.86c0-.27-.24-.405-.48-.405-.239 0-.482.137-.48.407"
        />
      </mask>
      <g mask="url(#logo-kakaotalk_svg__d)">
        <path fill="#F9E000" d="M14.874 9.128H12.25v4.16h2.623z" />
      </g>
      <path
        fill="#F9E000"
        d="M14.984 9.413c.01.338-.011 3.398-.011 3.625 0 .358.911.356.911 0v-1.205l.267-.215 1.17 1.595c.237.313.98-.252.744-.563l-1.241-1.636s.843-.911 1.175-1.241c.193-.192-.407-.798-.6-.604-.188.185-1.51 1.509-1.51 1.509s.012-.83 0-1.29c-.005-.174-.218-.258-.438-.258-.234 0-.473.096-.468.283"
      />
      <mask
        id="logo-kakaotalk_svg__e"
        width={5}
        height={5}
        x={14}
        y={9}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: "luminance",
        }}
      >
        <path
          fill="#fff"
          d="M14.984 9.413c.01.338-.011 3.398-.011 3.625 0 .358.911.356.911 0v-1.205l.267-.215 1.17 1.595c.237.313.98-.252.744-.563l-1.241-1.636s.843-.911 1.175-1.241c.193-.192-.407-.798-.6-.604-.188.185-1.51 1.509-1.51 1.509s.012-.83 0-1.29c-.005-.174-.218-.258-.438-.258-.234 0-.473.096-.468.283"
        />
      </mask>
      <g mask="url(#logo-kakaotalk_svg__e)">
        <path fill="#F9E000" d="M18.302 8.977h-3.33v4.55h3.33z" />
      </g>
    </g>
    <defs>
      <clipPath id="logo-kakaotalk_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgLogoKakaotalk;
