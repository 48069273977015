import HomeCareListContainer from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/HomeCare/components/HomeCareList/HomeCareListContainer';
import HomeCareManageDialogContainer from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/HomeCare/components/HomeCareManageDialog/HomeCareManageDialogContainer';
import { contentEditState } from '@templates/Content/containers/ContentManager/states/content-edit-state';
import { useAtomValue } from 'jotai/index';
import React from 'react';

export default function HomeCareContainer() {
  const contentEdit = useAtomValue(contentEditState);

  return (
    <>
      <HomeCareListContainer />
      {contentEdit.isEditing && <HomeCareManageDialogContainer />}
    </>
  );
}
