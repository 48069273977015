import type { AuthorizationTypeForV3 } from '@apis/swaggers/swagger-docs';
import {
  hospitalAuthorizationTypesState,
  hospitalInitialAuthorizationTypesState,
} from '@templates/HospitalSetting/containers/AuthorizationSetting/jotai/hospital-authorization-types';
import { selectAuthorizationTypeIndex } from '@templates/HospitalSetting/containers/AuthorizationSetting/jotai/select-authorization-type-index';
import { modalService } from 'afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { useAtom } from 'jotai';
import { useAtomValue } from 'jotai/index';
import { isEqual } from 'lodash-es';

interface PermissionTypeCardProps {
  index: number;
  authorizationType: AuthorizationTypeForV3;
}

const selectedBackgroundStyle = 'bg-blue50 border-blue500';
const defaultBackgroundStyle = 'bg-white50 border-white600';

export default function AuthorizationTypeCard({
  index,
  authorizationType,
}: PermissionTypeCardProps) {
  const [selectedTypeIndex, setSelectedTypeIndex] = useAtom(selectAuthorizationTypeIndex);
  const [authorizationTypes, setAuthorizationTypes] = useAtom(hospitalAuthorizationTypesState);
  const initAuthorizationTypes = useAtomValue(hospitalInitialAuthorizationTypesState);
  const { name, description } = authorizationType;

  const handleTypeClick = () => {
    if (selectedTypeIndex === index) return;

    if (isEqual(authorizationTypes, initAuthorizationTypes)) {
      setSelectedTypeIndex(index);
    } else {
      modalService.defaultWarning({
        onConfirm: () => {
          setAuthorizationTypes([...initAuthorizationTypes]);
          modalService.pop();
          setSelectedTypeIndex(index);
        },
      });
    }
  };

  const cardBackgroundStyle =
    selectedTypeIndex === index ? selectedBackgroundStyle : defaultBackgroundStyle;

  return (
    <div
      className={`h-[105px] cursor-pointer rounded-r16 border px-20 py-16 ${cardBackgroundStyle}`}
      onClick={handleTypeClick}>
      <h1 className='text-Header14 text-black700'>{name ?? ''}</h1>
      <h2 className='mt-6 text-Body11 text-black500'>{description ?? ''}</h2>
    </div>
  );
}
