import EventPushButtonGroup from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/components/EventList/components/EventListBody/components/EventPushAlarmDialog/EventPushSelector/components/EventPushButtonGroup';
import EventTargetDetailFilter from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/components/EventList/components/EventListBody/components/EventPushAlarmDialog/EventPushSelector/components/EventTargetDetailFilter';
import EventTargetRadioGroup from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/components/EventList/components/EventListBody/components/EventPushAlarmDialog/EventPushSelector/components/EventTargetRadioGroup';
import EventTargetTitle from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/components/EventList/components/EventListBody/components/EventPushAlarmDialog/EventPushSelector/components/EventTargetTitle';
import { eventPushRequestCreateParamState } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/components/EventList/components/EventListBody/components/EventPushAlarmDialog/EventPushSelector/states/event-push-request-create-param-state';
import { isUseDateRangeFilterState } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/components/EventList/components/EventListBody/components/EventPushAlarmDialog/EventPushSelector/states/is-use-date-range-filter-state';
import { signupRangeErrorTypeState } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/components/EventList/components/EventListBody/components/EventPushAlarmDialog/EventPushSelector/states/signup-range-error-type-state';
import { useResetAtom } from 'jotai/utils';
import React, { useEffect } from 'react';

interface EventPushSelectorContainerProps {
  hospitalEventID: string;
}

export default function EventPushSelectorContainer({
  hospitalEventID,
}: EventPushSelectorContainerProps) {
  const resetEventPushRequestParam = useResetAtom(eventPushRequestCreateParamState);
  const resetErrorTypeState = useResetAtom(signupRangeErrorTypeState);
  const resetIsUseDateRangeFilter = useResetAtom(isUseDateRangeFilterState);

  useEffect(() => {
    return () => {
      resetEventPushRequestParam();
      resetErrorTypeState();
      resetIsUseDateRangeFilter();
    };
  }, []);

  return (
    <div className='flex h-full w-[425px] flex-col py-20'>
      <EventTargetTitle />
      <div className='flex flex-grow flex-col gap-10'>
        <EventTargetRadioGroup />
        <EventTargetDetailFilter />
        <EventPushButtonGroup hospitalEventID={hospitalEventID} />
      </div>
    </div>
  );
}
