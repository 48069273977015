import { EVENT_PUSH_LIST_HEADERS } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/components/EventList/components/EventListBody/components/EventPushAlarmDialog/EventPushList/constants/event-push-header-items';

export default function EventPushListHeader() {
  return (
    <div className='flex w-full'>
      {EVENT_PUSH_LIST_HEADERS.map(({ title, width }) => (
        <span
          key={title}
          style={{ width: `${width}px` }}
          className='h-40 whitespace-nowrap border-line-table border-b bg-table px-12 py-10 text-left text-Header14 text-black700'>
          {title}
        </span>
      ))}
    </div>
  );
}
