export const JOB_ITEMS = [
  '대표원장님',
  '원장님',
  '팀장',
  '실장',
  '직원',
  '상담실장',
  '치료사',
  '(병원)',
];
