import type { IntegrationChannelItem } from '@templates/HospitalSetting/containers/ChannelIntegration/types/intergration-channel-item';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';

interface ChannelIntegrationItemProps {
  data: IntegrationChannelItem;
  hasLinkage: boolean;
  isSelected: boolean;
  onSelect: () => void;
}
export default function ChannelIntegrationItem({
  data: { icon, title, description },
  hasLinkage,
  isSelected,
  onSelect,
}: ChannelIntegrationItemProps) {
  return (
    <div
      className={`flex min-h-[72px] w-[320px] cursor-pointer flex-col rounded-r16 border px-20 py-16 ${
        isSelected ? 'border-blue500 bg-blue50' : 'border-white600 bg-white50'
      }`}
      onClick={onSelect}>
      <div className='flex items-center gap-8'>
        <Icon name={icon} size={17} />
        <span className='text-Header14 text-black700'>{title}</span>
        <div
          className={`flex flex-center rounded-[6px] px-8 text-Body12 ${hasLinkage ? 'bg-blue50 text-blue500' : 'bg-white100 text-black500'}`}>
          {hasLinkage ? '연동' : '미연동'}
        </div>
      </div>
      <span className='mt-6 text-Body11 text-black500'>{description}</span>
    </div>
  );
}
