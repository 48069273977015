import { useEffect, useRef } from 'react';

/**
 * Intersection Observer API를 사용하여 주어진 요소들이 뷰포트에 진입하거나 나갈 때 콜백을 실행하는 훅입니다.
 *
 * @param {IntersectionObserverCallback} callback - 요소들이 교차할 때 실행되는 콜백 함수입니다.
 * @param {IntersectionObserverInit} options - Intersection Observer의 옵션입니다.
 * @param {(Element | null)[]} targets - 관찰할 요소들의 배열입니다.
 *
 * @example
 * useIntersectionObserver((entries) => {
 *   entries.forEach(entry => {
 *     console.info(entry.isIntersecting);
 *   });
 * }, { root: null, rootMargin: '0px', threshold: 1.0 }, [targetRef.current]);
 */
export const useIntersectionObserver = (
  callback: IntersectionObserverCallback,
  options: IntersectionObserverInit,
  targets: (Element | null)[],
) => {
  const observerRef = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    if (observerRef.current) {
      observerRef.current.disconnect();
    }

    observerRef.current = new IntersectionObserver(callback, options);

    for (const target of targets) {
      if (target) observerRef.current?.observe(target);
    }

    return () => observerRef.current?.disconnect();
  }, [callback, options, targets]);
};
