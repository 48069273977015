import { ROOM_TYPE_MAP } from '../components/ChattingList/constants/room-type';
import type { PatientIconInfoType } from '../hooks/use-chat-top-patient';
import type { RoomType } from '../states/selected-chat-room';

type IsDisabledChatParams = {
  roomType: RoomType | null;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  ikakaoInfo: Record<string, any> | undefined;
  iconSet: PatientIconInfoType | null;
};

export const isDisabledChat = ({ roomType, ikakaoInfo, iconSet }: IsDisabledChatParams) => {
  const isClosedKakaoChat = roomType === ROOM_TYPE_MAP.kakao ? ikakaoInfo?.isClosed : false;

  const isDisabledSendingBottom =
    isClosedKakaoChat || (iconSet?.isGrayAfterDocIcon && !iconSet?.isKakaoIcon);

  return isDisabledSendingBottom;
};
