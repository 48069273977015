import { popupCreateOrUpdateParamState } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/state/popup-create-or-update-param-state';
import DayPicker from 'afterdoc-design-system/components/Atoms/Calendar/DayPicker';
import TextInput from 'afterdoc-design-system/components/Atoms/Input/TextInput';
import dayjs from 'dayjs';
import { useSetAtom } from 'jotai';
import { type ChangeEvent, useEffect, useRef, useState } from 'react';

interface PopupDatePickerProps {
  type: 'start' | 'end';
  targetDate: string;
  isToggle: boolean;
  isDisabled: boolean;
  onToggle: (isToggle: boolean) => void;
}

const isValidDate = (year: number, month: number, day: number) => {
  if (year < 1900 || year > 2500) return false;
  if (month < 1 || month > 12) return false;
  const daysInMonth = new Date(year, month, 0).getDate();
  return !(day < 1 || day > daysInMonth);
};

const formatDateWithHyphens = (dateStr: string) => {
  const cleaned = dateStr.replace(/[^0-9]/g, '');

  const year = cleaned.slice(0, 4);
  const month = cleaned.slice(4, 6);
  const day = cleaned.slice(6, 8);

  if (cleaned.length <= 4) return year;
  if (cleaned.length <= 6) return `${year}-${month}`;

  return `${year}-${month}-${day}`;
};

export default function PopupDatePicker({
  type,
  targetDate,
  isToggle,
  isDisabled,
  onToggle,
}: PopupDatePickerProps) {
  const [inputValue, setInputValue] = useState(targetDate);
  const [isValid, setIsValid] = useState(true);

  const [selectedDate, setSelectedDate] = useState<Date>();

  const setPopupCreateOrUpdateParam = useSetAtom(popupCreateOrUpdateParamState);

  const isFirstRender = useRef(true);

  const inputRef = useRef<HTMLInputElement>(null);

  const setPopupDateParam = (date: string) => {
    setPopupCreateOrUpdateParam((prev) => ({
      ...prev,
      [type === 'start' ? 'startDate' : 'endDate']: date,
    }));
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!isToggle) {
      onToggle(true);
    }

    const input = e.target.value;
    if (/[^0-9-]/.test(input)) return;

    const formattedInput = formatDateWithHyphens(input);
    setInputValue(formattedInput);

    if (formattedInput.length === 10) {
      const [year, month, day] = formattedInput.split('-').map(Number);
      if (isValidDate(year, month, day)) {
        setIsValid(true);
        setSelectedDate(new Date(year, month - 1, day));
        return;
      }
    }
    setIsValid(false);
    setPopupCreateOrUpdateParam((prev) => ({ ...prev }));
    setPopupDateParam(formattedInput);
  };

  const handleDayPickerSelect = (date?: Date) => {
    if (!date) return;
    setIsValid(true);
    setSelectedDate(date);
    setInputValue(dayjs(date).format('YYYY-MM-DD'));
    onToggle(false);
  };

  useEffect(() => {
    if (targetDate && isFirstRender.current) {
      isFirstRender.current = false;
      const date = dayjs(targetDate);
      if (date.isValid()) {
        setSelectedDate(date.toDate());
        setInputValue(date.format('YYYY-MM-DD'));
      }
    }
  }, [targetDate]);

  useEffect(() => {
    if (selectedDate) {
      setPopupDateParam(dayjs(selectedDate).format('YYYY-MM-DD'));
    }
  }, [selectedDate]);

  useEffect(() => {
    if (!isToggle) {
      setTimeout(() => {
        inputRef.current?.blur();
      }, 0);
    }
  }, [isToggle]);

  return (
    <div className='relative'>
      <TextInput
        ref={inputRef}
        value={inputValue}
        onChange={handleInputChange}
        disabled={isDisabled}
        onClick={() => onToggle(!isToggle)}
        maxLength={10}
        placeholder='YYYY-MM-DD'
      />
      {isToggle && (
        <DayPicker
          hasError={!isValid}
          className='absolute z-[10000]'
          onChangeDate={handleDayPickerSelect}
          selectedDate={selectedDate}
          handleToggle={() => onToggle(false)}
          yearRange={[1900, 2500]}
          onEscKeyDown={() => onToggle(false)}
          styles={{
            table: {
              padding: 10,
            },
          }}
        />
      )}
    </div>
  );
}
