import { apiClient } from '@apis/instances/api-client';
import type { ApiHospitalsElFindAddressParams, Juso } from '@apis/swaggers/swagger-docs';
import { useQuery } from '@tanstack/react-query';
import AddressFetchLoading from '@templates/HospitalSetting/containers/HospitalProfile/components/HospitalAddress/components/AddressSearchDialog/components/AddressFetchLoading';
import AddressPagination from '@templates/HospitalSetting/containers/HospitalProfile/components/HospitalAddress/components/AddressSearchDialog/components/AddressPagination';
import AddressResultList from '@templates/HospitalSetting/containers/HospitalProfile/components/HospitalAddress/components/AddressSearchDialog/components/AddressResultList';
import AddressResultNoData from '@templates/HospitalSetting/containers/HospitalProfile/components/HospitalAddress/components/AddressSearchDialog/components/AddressResultNoData';
import { hospitalAddressQueryKeyState } from '@templates/HospitalSetting/containers/HospitalProfile/components/HospitalAddress/components/AddressSearchDialog/jotai/address-query-key';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import { SHARED_UTILS } from 'utils/utils';

const fetchHospitalAddress = async (params: ApiHospitalsElFindAddressParams) => {
  try {
    const { data } = await apiClient.v3.apiHospitalsElFindAddress(params);
    return SHARED_UTILS.api.checkApiResponse(data);
  } catch (error) {
    console.error('Error fetching hospital address', error);
    return undefined;
  }
};

export default function ResultArea() {
  const hospitalAddressQueryKey = useAtomValue(hospitalAddressQueryKeyState);
  const [totalCount, setTotalCount] = useState(0);
  const [juso, setJuso] = useState<Juso[] | undefined>();

  const { data, isLoading, error } = useQuery({
    queryKey: hospitalAddressQueryKey,
    queryFn: ({ queryKey }) => fetchHospitalAddress(queryKey[1]),
    enabled: !!hospitalAddressQueryKey[1].keyword,
  });

  const RenderResults = () => {
    if (isLoading) return <AddressFetchLoading />;

    if (juso && juso.length > 0) return <AddressResultList juso={juso} />;

    return <AddressResultNoData keyword={hospitalAddressQueryKey[1].keyword} />;
  };

  useEffect(() => {
    if (data) {
      setJuso(data.juso);
      setTotalCount(Number(data.common?.totalCount ?? '0'));
    } else if (error) {
      setJuso(undefined);
      setTotalCount(0);
    }
  }, [data, error]);

  return (
    <>
      <RenderResults />
      {totalCount > 0 && <AddressPagination totalCount={totalCount} />}
    </>
  );
}
