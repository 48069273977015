import Divider from '@afterdoc-design-system/components/Atoms/Divider/Divider';
import Scrollbar from '@afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import NoData from '@afterdoc-design-system/components/Molecules/NoData/NoData';
import { apiClient } from '@apis/instances/api-client';
import type {
  ApiEventHospitalAftercareElFindSimpleParams,
  ApiEventHospitalEventElFindSimpleParams,
  HospitalAftercareFindSimple,
  HospitalEventFindSimple,
} from '@apis/swaggers/swagger-docs';
import { useIntersectionObserver } from '@shared-hooks/use-intersection-observer';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { useCallback, useEffect, useRef } from 'react';
import { SHARED_UTILS } from 'utils/utils';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import type { EventOrHomecareContentSelectDialogProps } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/components/Forms/EventOrHomecareContentForm/components/EventOrHomecareContentSelectDialog/EventOrHomecareContentSelectDialog';
import { clickedPanelIndexState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/components/Forms/EventOrHomecareContentForm/components/EventOrHomecareContentSelectDialog/components/EventOrHomecareContentPanel/states/clicked-panel-index';
import { useGetCalculatedResult } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/components/Forms/EventOrHomecareContentForm/hooks/use-get-calculated-result';

const ITEMS_LIMIT = 10;

const fetchHospitalEventList = async (params: ApiEventHospitalEventElFindSimpleParams) => {
  const filteredParams = {
    ...params,
    ...(params.keyword === '' && { keyword: undefined }),
  };

  const response = await apiClient.v3.apiEventHospitalEventElFindSimple(filteredParams);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const fetchHospitalHomeCareList = async (params: ApiEventHospitalAftercareElFindSimpleParams) => {
  const filteredParams = {
    ...params,
    ...(params.keyword === '' && { keyword: undefined }),
  };

  const response = await apiClient.v3.apiEventHospitalAftercareElFindSimple(filteredParams);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

interface EventOrHomecareContentListProps extends EventOrHomecareContentSelectDialogProps {}

export default function EventOrHomecareContentList({
  handleClickWorkoutCard,
}: EventOrHomecareContentListProps) {
  const { hospitalID } = useSelectedHospitalInfo();

  const resetPanelIndex = useResetAtom(clickedPanelIndexState);

  const loadMoreRef = useRef<HTMLDivElement | null>(null);
  const clickedPanelIndex = useAtomValue(clickedPanelIndexState);

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteQuery({
    queryKey: [
      clickedPanelIndex === 0 ? 'eventList' : 'homeCareList',
      {
        hospitalID,
        limit: ITEMS_LIMIT,
      },
    ] as const,
    queryFn: ({ queryKey: [, params], pageParam }) =>
      clickedPanelIndex === 0
        ? fetchHospitalEventList({
            ...params,
            skip: pageParam,
            isActive: true,
            isRemoved: false,
          })
        : fetchHospitalHomeCareList({
            ...params,
            skip: pageParam,
            isActive: true,
            isRemoved: false,
          }),
    getNextPageParam: (lastPage, allPages) => {
      const loadedItemsCount = allPages.flat().length;
      return lastPage.length === ITEMS_LIMIT ? loadedItemsCount : undefined;
    },
    initialPageParam: 0,
  });

  const contents = data?.pages.flat() ?? [];

  const handleIntersection = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;
      if (entry.isIntersecting && hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
    [fetchNextPage, hasNextPage, isFetchingNextPage],
  );

  useIntersectionObserver(handleIntersection, { root: null, rootMargin: '20px', threshold: 0 }, [
    loadMoreRef.current,
  ]);

  useEffect(() => {
    return () => resetPanelIndex();
  }, [resetPanelIndex]);

  if (!contents.length) {
    return (
      <div className='flex h-[calc(100vh-220px)] w-full items-center justify-center'>
        <NoData
          iconProps={{
            name: 'warning',
            size: 48,
            color: 'white600',
          }}
          title={
            clickedPanelIndex === 0 ? '등록된 이벤트가 없습니다.' : '등록된 홈케어상품이 없습니다.'
          }
        />
      </div>
    );
  }

  return (
    <div className='h-[calc(100vh-220px)] w-full'>
      <Scrollbar disabledX>
        <div className='flex flex-col'>
          {contents.map((content) => {
            return (
              <ContentsCard
                key={content._id}
                handleClickWorkoutCard={handleClickWorkoutCard}
                {...content}
              />
            );
          })}
        </div>
        <div ref={loadMoreRef} style={{ height: 1 }} />
      </Scrollbar>
    </div>
  );
}

const ContentsCard = (
  props: (HospitalEventFindSimple | HospitalAftercareFindSimple) & EventOrHomecareContentListProps,
) => {
  const clickedPanelIndex = useAtomValue(clickedPanelIndexState);

  const {
    _id,
    title,
    representImage,
    saleRate,
    saleType,
    price = 0,
    salePrice,
    handleClickWorkoutCard,
  } = props;

  const { formattedPriceDisplay, calculatedPrice, calculatedRate } = useGetCalculatedResult({
    saleRate,
    saleType,
    price,
    salePrice,
  });

  return (
    <>
      <div
        className='flex w-full cursor-pointer items-center gap-16 px-20 py-10 hover:bg-blue50'
        onClick={() => {
          handleClickWorkoutCard({
            _id,
            title,
            thumbnailUrl: representImage?.paths?.[0],
            saleRate,
            saleType,
            price,
            salePrice,
            type: clickedPanelIndex === 0 ? 'event' : 'homecare',
          });
        }}>
        {!!representImage?.paths?.[0] && (
          <img
            src={representImage.paths[0]}
            alt={title}
            className='h-[80px] w-[80px] rounded-r10 object-cover shadow-modal'
          />
        )}
        <div className='flex flex-col gap-4'>
          <div className='text-Header12 text-black500'>{title}</div>
          <div className='flex flex-row gap-10'>
            <div className='text-Body12 text-black200 line-through'>{formattedPriceDisplay}</div>
            <div className='flex'>
              <div className='text-Header12 text-blue500'>{calculatedPrice}원</div>
              <div className='text-Header12 text-blue500'>&nbsp;({calculatedRate})</div>
            </div>
          </div>
        </div>
      </div>
      <Divider type='line' className='bg-white400' />
    </>
  );
};
