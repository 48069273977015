import Scrollbar from '@afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';

type DialogContentProps = {
  displayCreated?: string;
  description?: string;
  title?: string;
  isDisabledScrollBar?: boolean;
  children?: React.ReactNode;
};

export const MessageDialogContent = ({
  displayCreated,
  description,
  title,
  isDisabledScrollBar = false,
  children,
}: DialogContentProps) => {
  return (
    <div className='h-full w-[400px] px-15 py-10'>
      <Scrollbar disabledY={isDisabledScrollBar}>
        <div className='h-auto flex-col-center pb-10'>
          <div className='text-Header16 text-black500'> {title}</div>
          <div className='text-Body12 text-black500'>{displayCreated}</div>
          {children}
          <div className='mb-[200px] text-Body13 text-black500'>{description}</div>
        </div>
      </Scrollbar>
    </div>
  );
};
