import { useUpdateUserInfo } from '@templates/UserInfoSetting/containers/AccountSetting/hooks/use-update-user-info';
import OutlinedButton from 'afterdoc-design-system/components/Atoms/Button/OutlinedButton';
import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import TextInput from 'afterdoc-design-system/components/Atoms/Input/TextInput';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import Title from 'afterdoc-design-system/components/Molecules/Title/Title';
import { useEffect, useState } from 'react';

interface BasicInfoSectionProps {
  userName: string;
  isMasterOrAdmin: boolean;
  onUnsavedChange: (value: boolean) => void;
}

export default function BasicInfoSection({
  userName,
  isMasterOrAdmin,
  onUnsavedChange,
}: BasicInfoSectionProps) {
  const [realName, setRealName] = useState(userName);

  const { updateUserInfo } = useUpdateUserInfo();

  const handleSave = () => {
    updateUserInfo({
      param: { realName: realName },
      onSuccessCallback: () => {
        toastService.successMsg({ text: '프로필이 저장되었습니다.' });
      },
      onFailureCallback: () => {
        toastService.errorMsg({ text: '프로필 저장에 실패하였습니다. 다시 시도해 주세요.' });
      },
    });
  };

  useEffect(() => {
    onUnsavedChange(realName !== userName);
  }, [realName, userName, onUnsavedChange]);

  return (
    <>
      <div className='flex flex-col gap-10 px-80 py-20'>
        <Title
          title='기본 정보'
          subTitle='채팅에서 메시지 전송 시 보여지는 이름입니다. (고객에게는 병원명이 표시됩니다.)'
        />
        <div className='flex items-end gap-10 px-20 pt-10 pb-20'>
          <TextInput
            onChange={(e) => setRealName(e.target.value)}
            value={realName}
            maxLength={15}
            placeholder='예) 홍길동'
            label={{
              isRequired: true,
              position: 'vertical',
              children: '내 이름',
            }}
            className='flex-grow'
          />
          <OutlinedButton
            className='mb-1 h-30 w-88'
            disabled={realName.length === 0}
            onClick={handleSave}>
            저장
          </OutlinedButton>
        </div>
      </div>
      {isMasterOrAdmin && <Divider type='line' height={10} />}
    </>
  );
}
