import Scrollbar from '@afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import DashedTag from '@afterdoc-design-system/components/Atoms/Tag/DashedTag';
import FilledTag from '@afterdoc-design-system/components/Atoms/Tag/FilledTag';
import GradientTag from '@afterdoc-design-system/components/Atoms/Tag/GradientTag/GradientTag';
import HoverComponent from '@afterdoc-design-system/components/Atoms/Tooltip/HoverComponent';
import { useAtomValue } from 'jotai';
import { useEffect, useRef, useState } from 'react';
import type { TreatmentTag } from 'web/apis/swaggers/swagger-docs';
import { chattingWidthAtom } from 'web/templates/CustomerChat/states/chattingWidth';
import { usePatient } from '../../../../hooks/use-patient';
import TagList from './TagList';

const MARGIN_VALUE = 10;
const EXTRA_NUMBER_COMPONENT_WIDTH = 30;
const HOVER_CONTENT_WRAPPER = 'rounded-r16 bg-white50 p-10 shadow-modal';

const isCompleteTreatmentTag = (tag: Partial<TreatmentTag>): tag is TreatmentTag => {
  return tag?.tagId !== undefined && tag?.name !== undefined && tag?.color !== undefined;
};

export default function TreatmentTags() {
  const { treatmentTags, automationAppliedTreatmentTag } = usePatient();
  const treatmentDivRef = useRef<HTMLDivElement>(null);
  const hoverContentRef = useRef<HTMLDivElement>(null);
  const [visibleTags, setVisibleTags] = useState<TreatmentTag[]>([]);
  const [hiddenTags, setHiddenTags] = useState<TreatmentTag[]>([]);
  const [hoverContentSize, setHoverContentSize] = useState<{ width: number; height: number }>({
    width: 326,
    height: 92,
  });
  const [flag, setFlag] = useState(false);
  const chattingWidth = useAtomValue(chattingWidthAtom);
  const TAGS_CONTAINER_WIDTH = chattingWidth - 150;

  const calculateVisibleTags = () => {
    if (treatmentDivRef.current) {
      let currentWidth = EXTRA_NUMBER_COMPONENT_WIDTH;
      const visible: TreatmentTag[] = [];
      const hidden: TreatmentTag[] = [];
      const tags = treatmentDivRef.current.children;
      if (treatmentTags && tags) {
        for (let i = 0; i < tags.length; i++) {
          if (!isCompleteTreatmentTag(treatmentTags[i])) {
            continue;
          }
          const tagWidth = (tags[i] as HTMLDivElement).offsetWidth;
          if (currentWidth + tagWidth + MARGIN_VALUE > TAGS_CONTAINER_WIDTH) {
            hidden.push(treatmentTags[i] as TreatmentTag);
          } else {
            currentWidth += tagWidth + MARGIN_VALUE;
            visible.push(treatmentTags[i] as TreatmentTag);
          }
        }
      }
      setVisibleTags(visible);
      setHiddenTags(hidden);
    }
  };
  useEffect(() => {
    setVisibleTags([]);
    setHiddenTags([]);
    setFlag(false);
  }, [chattingWidth, treatmentTags]);

  useEffect(() => {
    if (!flag) {
      calculateVisibleTags();
    }
  }, [treatmentDivRef.current, flag]);

  useEffect(() => {
    setFlag(true);
  }, [visibleTags]);

  useEffect(() => {
    if (hoverContentRef.current) {
      const { offsetWidth, offsetHeight } = hoverContentRef.current as HTMLDivElement;
      setHoverContentSize({
        width: Math.min(offsetWidth + 20, 326),
        height: Math.min(offsetHeight, 92),
      });
    }
  }, [hiddenTags]);

  const tags = flag ? visibleTags : treatmentTags;

  return (
    <div className='flex w-full flex-row-center gap-3'>
      <div className='sub-text mr-3 w-37 text-Body10Bold text-black500'>치료태그</div>
      {!automationAppliedTreatmentTag && tags?.length === 0 && (
        <div className='sub-text text-black500'>
          <DashedTag tagSize='big'>{'없음'}</DashedTag>
        </div>
      )}
      <div ref={treatmentDivRef} className='fixed-width flex gap-6'>
        {automationAppliedTreatmentTag &&
          (automationAppliedTreatmentTag.automationApplied ? (
            <GradientTag
              key={automationAppliedTreatmentTag.tagId}
              bgColor={automationAppliedTreatmentTag.color}
              tagSize='big'
              style={{ whiteSpace: 'nowrap' }}>
              {automationAppliedTreatmentTag.name}
            </GradientTag>
          ) : (
            <FilledTag
              key={automationAppliedTreatmentTag.tagId}
              bgColor={automationAppliedTreatmentTag.color}
              tagSize='big'
              style={{ whiteSpace: 'nowrap' }}>
              {automationAppliedTreatmentTag.name}
            </FilledTag>
          ))}
        {tags?.map((treatmentTag) => (
          <FilledTag
            key={treatmentTag.tagId}
            bgColor={treatmentTag.color}
            tagSize='big'
            style={{ whiteSpace: 'nowrap' }}>
            {treatmentTag.name}
          </FilledTag>
        ))}

        {hiddenTags.length > 0 && (
          <HoverComponent
            wrapperProps={{
              style: { display: 'flex' },
            }}
            content={
              <div
                className={HOVER_CONTENT_WRAPPER}
                style={{
                  overflowY: 'auto',
                  width: `${hoverContentSize.width}px`,
                  height: `${hoverContentSize.height}px`,
                }}>
                <Scrollbar disabledX>
                  <TagList tags={hiddenTags} />
                </Scrollbar>
              </div>
            }
            position='bottomRight'>
            <DashedTag>{`${hiddenTags.length}+`}</DashedTag>
          </HoverComponent>
        )}
      </div>
      <div
        className={HOVER_CONTENT_WRAPPER}
        ref={hoverContentRef}
        style={{
          visibility: 'hidden',
          position: 'absolute',
          maxWidth: '326px',
          maxHeight: '92px',
        }}>
        <TagList tags={hiddenTags} />
      </div>
    </div>
  );
}
