import { SHARED_UTILS } from '@shared-utils/utils';
import { apiClient } from 'web/apis/instances/api-client';
import type { PostSendHAftercareParams } from '../types';

export const postSendHomecareProduct = async ({
  chatRoomID,
  hserviceID,
  haftercareID,
}: PostSendHAftercareParams) => {
  const body = {
    chatRoomID,
    hserviceID,
    haftercareID,
  };

  const { data } = await apiClient.v3.apiChatElSendHaftercare(body);
  return SHARED_UTILS.api.checkApiResponse(data);
};
