import type { CreateUpdateEventParamType } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/types/create-update-event-param-type';
import type { CreateUpdateHomeCareParams } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/HomeCare/types/create-update-homecare-params';
import type { ContentEditErrorsState } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/states/content-edit-errors-state';
import { hasIncompleteDates } from '@templates/Content/containers/ContentManager/functions/has-incomplete-dates';
import { isInvalidDateRange } from '@templates/Content/containers/ContentManager/functions/is-invalid-date-range';
import type { UploadImageFile } from '@templates/Content/containers/ContentManager/types/upload-image-file';

const defaultWarningText = '필수값입니다.';

export const validateEventOrHomeCareParams = (
  params: CreateUpdateEventParamType | CreateUpdateHomeCareParams,
  thumbnailImage: UploadImageFile | null,
  contentImage: UploadImageFile | null,
): ContentEditErrorsState => {
  const errors: ContentEditErrorsState = {};
  const {
    _id: id,
    title,
    price = 0,
    type,
    saleType,
    salePrice,
    startDate,
    endDate,
    isLimitedTime,
    content,
  } = params;

  if (!title) {
    errors.title = defaultWarningText;
  }

  if (price < 0 || Number.isNaN(price)) {
    errors.price = defaultWarningText;
  }

  if (saleType === 'price' && price < (salePrice ?? 0)) {
    errors.price = '할인금액은 기본가를 초과할 수 없습니다.';
  }

  if (isLimitedTime) {
    if (hasIncompleteDates(startDate, endDate)) {
      errors.date = '날짜를 다시 확인해주세요.';
    } else if (isInvalidDateRange(startDate, endDate)) {
      errors.date = '종료일이 시작일보다 빠릅니다.';
    }
  }

  if (!id && !thumbnailImage?.file) {
    errors.thumbnailImage = defaultWarningText;
  }

  if (type === 'image' && !id && !contentImage?.file) {
    errors.contentImage = defaultWarningText;
  }

  if (type === 'text' && !content) {
    errors.contentText = defaultWarningText;
  }

  return errors;
};
