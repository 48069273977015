import TextFieldSelectBoxDropdown from '@afterdoc-design-system/components/Molecules/TextSelect/TextFieldSelectBoxDropdown';
import { useAtom } from 'jotai';
import AutomationSearchInput from 'web/templates/Automation/containers/Main/AutomationSearchFilter/components/AutomationSearchInput';
import { AUTOMATION_SEARCH_FIELD_OPTIONS } from 'web/templates/Automation/containers/Main/AutomationSearchFilter/constants/automation-search-field-options';
import { INPUT_ID } from 'web/templates/Automation/containers/Main/AutomationSearchFilter/constants/input';
import { selectedAutomationSearchCriteriaIndexState } from 'web/templates/Automation/containers/Main/AutomationSearchFilter/states/selected-automation-search-criteria';

export default function AutomationSearchFilter() {
  const [selectedAutomationSearchCriteriaIndex, setSelectedAutomationSearchCriteriaIndex] = useAtom(
    selectedAutomationSearchCriteriaIndexState,
  );

  const handleDropdownSelect = () => {
    const searchInputElement = document.getElementById(INPUT_ID);
    if (searchInputElement) {
      searchInputElement.focus();
    }
  };

  return (
    <div className='sticky top-0 z-10 w-full border-b border-b-white400 bg-white50 px-[200px] pt-18 pb-22'>
      <div className='flex w-full items-center'>
        <TextFieldSelectBoxDropdown
          options={AUTOMATION_SEARCH_FIELD_OPTIONS.map((option) => option.value)}
          manualInput={false}
          onSelect={(index) => {
            setSelectedAutomationSearchCriteriaIndex(index);
            handleDropdownSelect();
          }}
          selectedIndex={selectedAutomationSearchCriteriaIndex}
          wrapperClassName='mr-20'
        />
        <AutomationSearchInput />
      </div>
    </div>
  );
}
