import * as React from "react";
import type { SVGProps } from "react";
const SvgKakaotalk = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 19 19"
    {...props}
  >
    <g clipPath="url(#kakaotalk_svg__a)">
      <rect width={17} height={17} x={1} y={1} fill="#E6F2FF" rx={5} />
      <g clipPath="url(#kakaotalk_svg__b)">
        <path fill="#F9E000" d="M18 1H1v17h17z" />
        <path
          fill="#371C1D"
          d="M9.483 4.118c-3.372 0-6.106 2.16-6.106 4.827 0 1.735 1.158 3.256 2.897 4.107-.128.476-.463 1.727-.53 1.994-.083.332.121.328.256.239.105-.07 1.673-1.137 2.35-1.597q.552.082 1.133.083c3.373 0 6.106-2.16 6.106-4.826s-2.733-4.827-6.106-4.827"
        />
        <path
          fill="#F9E000"
          d="M5.223 8.056h.628s.004 1.726 0 2.128c0 .294.654.298.653.005l.002-2.11.625.001c.391 0 .391-.614 0-.614-.44 0-1.911-.013-1.911-.013-.37 0-.368.603 0 .603"
        />
        <mask
          id="kakaotalk_svg__c"
          width={4}
          height={4}
          x={4}
          y={7}
          maskUnits="userSpaceOnUse"
          style={{
            maskType: "luminance",
          }}
        >
          <path
            fill="#fff"
            d="M5.223 8.056h.628s.004 1.726 0 2.128c0 .294.654.298.653.005l.002-2.11.625.001c.391 0 .391-.614 0-.614-.44 0-1.911-.013-1.911-.013-.37 0-.368.603 0 .603"
          />
        </mask>
        <g mask="url(#kakaotalk_svg__c)">
          <path fill="#F9E000" d="M7.524 7.453h-2.67v3.029h2.67z" />
        </g>
        <path
          fill="#F9E000"
          d="m8.231 8.167.35 1.095h-.73zm-.392-.513c-.155.34-.714 1.948-.898 2.375-.134.307.444.559.577.25l.126-.438h1.15s-.04.018.113.42c.123.327.72.1.597-.225-.177-.466-.788-2.145-.9-2.382-.05-.11-.235-.19-.413-.19-.148 0-.292.055-.353.19"
        />
        <mask
          id="kakaotalk_svg__d"
          width={4}
          height={4}
          x={6}
          y={7}
          maskUnits="userSpaceOnUse"
          style={{
            maskType: "luminance",
          }}
        >
          <path
            fill="#fff"
            d="m8.231 8.167.35 1.095h-.73zm-.392-.513c-.155.34-.714 1.948-.898 2.375-.134.307.444.559.577.25l.126-.438h1.15s-.04.018.113.42c.123.327.72.1.597-.225-.177-.466-.788-2.145-.9-2.382-.05-.11-.235-.19-.413-.19-.148 0-.292.055-.353.19"
          />
        </mask>
        <g mask="url(#kakaotalk_svg__d)">
          <path fill="#F9E000" d="M9.628 7.466H6.806v3.124h2.822z" />
        </g>
        <path
          fill="#F9E000"
          d="m9.725 7.754.004 2.308s-.05.35.25.35l1.261-.003c.296 0 .294-.628 0-.628-.296 0-.836-.004-.836-.004s.003-1.629.002-2.025c0-.192-.171-.288-.34-.288-.17 0-.342.097-.34.289"
        />
        <mask
          id="kakaotalk_svg__e"
          width={3}
          height={4}
          x={9}
          y={7}
          maskUnits="userSpaceOnUse"
          style={{
            maskType: "luminance",
          }}
        >
          <path
            fill="#fff"
            d="m9.725 7.754.004 2.308s-.05.35.25.35l1.261-.003c.296 0 .294-.628 0-.628-.296 0-.836-.004-.836-.004s.003-1.629.002-2.025c0-.192-.171-.288-.34-.288-.17 0-.342.097-.34.289"
          />
        </mask>
        <g mask="url(#kakaotalk_svg__e)">
          <path fill="#F9E000" d="M11.535 7.466H9.678v2.946h1.857z" />
        </g>
        <path
          fill="#F9E000"
          d="M11.614 7.667c.007.24-.008 2.408-.008 2.568 0 .254.645.253.645 0v-.853l.19-.152.828 1.13c.168.22.694-.18.527-.4l-.88-1.158s.598-.646.833-.88c.137-.136-.288-.565-.425-.428l-1.07 1.07s.009-.589 0-.914c-.003-.123-.154-.183-.31-.183-.165 0-.335.068-.331.2"
        />
        <mask
          id="kakaotalk_svg__f"
          width={3}
          height={4}
          x={11}
          y={7}
          maskUnits="userSpaceOnUse"
          style={{
            maskType: "luminance",
          }}
        >
          <path
            fill="#fff"
            d="M11.614 7.667c.007.24-.008 2.408-.008 2.568 0 .254.645.253.645 0v-.853l.19-.152.828 1.13c.168.22.694-.18.527-.4l-.88-1.158s.598-.646.833-.88c.137-.136-.288-.565-.425-.428l-1.07 1.07s.009-.589 0-.914c-.003-.123-.154-.183-.31-.183-.165 0-.335.068-.331.2"
          />
        </mask>
        <g mask="url(#kakaotalk_svg__f)">
          <path fill="#F9E000" d="M13.964 7.358h-2.358v3.224h2.358z" />
        </g>
      </g>
    </g>
    <rect width={18} height={18} x={0.5} y={0.5} stroke="#fff" rx={5.5} />
    <defs>
      <clipPath id="kakaotalk_svg__a">
        <rect width={17} height={17} x={1} y={1} fill="#fff" rx={5} />
      </clipPath>
      <clipPath id="kakaotalk_svg__b">
        <path fill="#fff" d="M1 1h17v17H1z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgKakaotalk;
