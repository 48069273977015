export const AUTOMATION_SEARCH_FIELD_OPTIONS = [
  {
    key: 'algorithmName',
    value: '자동화명',
    placeholder: '자동화명으로 검색',
  },
  {
    key: 'treatmentTagName',
    value: '치료태그',
    placeholder: '치료태그로 검색',
  },
  {
    key: 'nationalityName',
    value: '국적',
    placeholder: '국적으로 검색',
  },
] as const;
