import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import { useEffect, useState } from 'react';

interface MessagePreviewProps {
  message: string;
}

export default function MessagePreview({ message }: MessagePreviewProps) {
  const [formattedMessage, setFormattedMessage] = useState('');

  useEffect(() => {
    const formattedMsg = message
      .replaceAll('{{병원명}}', '애프터닥의원')
      .replaceAll('{{고객명}}', '홍길동')
      .replaceAll('{{링크}}', 'https://www.afterdoc.co.kr/');

    setFormattedMessage(formattedMsg);
  }, [message]);

  return (
    <>
      <div className='h-full w-full rounded-r16 bg-blue50 pt-10 pr-10 pl-20'>
        <Scrollbar disabledX={true} offsetBottom={10}>
          <div className='pt-10'>
            <span className='text-Header12 text-black200'>애프터닥</span>
            <div className='mt-8 mb-20 flex gap-8 pr-20'>
              <div className='flex flex-grow items-center rounded-r10 bg-white50 px-16 py-10'>
                <span className='whitespace-pre-wrap break-all text-Body12 text-black700'>
                  {formattedMessage}
                </span>
              </div>
              <span className='mt-auto w-fit flex-shrink-0 text-Body10 text-black200'>
                오후 1:40
              </span>
            </div>
          </div>
        </Scrollbar>
      </div>
    </>
  );
}
