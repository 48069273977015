import { atom } from 'jotai';

export const funcSaveButtonState = atom(
  (get) => get(fnAtom),
  (_get, set, newFn: () => void) => {
    set(fnAtom, { invoke: newFn });
  },
);

const fnAtom = atom({ invoke: () => {} });
