import FilledTag from '@afterdoc-design-system/components/Atoms/Tag/FilledTag';
import TagDropdown from '@afterdoc-design-system/components/Molecules/TextSelect/TagDropdown';
import { Color } from '@tailwind-base/styles/color';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import './SingleTagSelectBoxTagDropdownForTable.scss';

type BaseSingleTagProperty = {
  name: string;
  id?: string;
  color?: string;
};

export interface SingleTagSelectBoxTagDropdownForTableProps<T extends BaseSingleTagProperty> {
  id?: string;
  width?: number | string;
  placeholder?: string;
  value?: T;
  tagOptions: T[];
  defaultSelectedTag?: T;
  onSelect?: (index: number) => void;
  onSelectedTagChange?: (tag: T | null) => void;
  onRemoveTag?: () => void;
  isFocused?: boolean;
  isSelected?: boolean;
  maxTextLength?: number | 'full';
  className?: string;
  disabled?: boolean;
  isChangeOnly?: boolean;
  customFocusScrollHandler?: (focusedIndex: number) => number;
  customSelectedScrollHandler?: (selectedIndex: number) => number;
  handleClickOutside?: () => void;
}

const SingleTagSelectBoxTagDropdownForTable = forwardRef<
  HTMLDivElement,
  SingleTagSelectBoxTagDropdownForTableProps<BaseSingleTagProperty>
>(
  (
    {
      id,
      value,
      tagOptions,
      defaultSelectedTag,
      onSelectedTagChange,
      onRemoveTag,
      disabled,
      placeholder,
      isFocused,
      isSelected,
      className,
      isChangeOnly,
      customFocusScrollHandler,
      customSelectedScrollHandler,
      handleClickOutside,
      width,
      onSelect,
    },
    ref,
  ) => {
    const inputRef = useRef<HTMLDivElement>(null);
    const ignoreRef = useRef(null);

    const [isToggle, setIsToggle] = useState(false);
    const [selectedTag, setSelectedTag] = useState<BaseSingleTagProperty | null>(() => {
      if (value !== undefined) {
        const matchingTag = tagOptions.find(
          (tag) => tag.id === value.id || tag.name === value.name,
        );
        return matchingTag || null;
      }
      return defaultSelectedTag || null;
    });
    const [inputValue, setInputValue] = useState('');
    const [immediateInputValue, setImmediateInputValue] = useState('');
    const [filteredTags, setFilteredTags] = useState(tagOptions);
    const [isComposing, setIsComposing] = useState(false);

    useImperativeHandle(ref, () => inputRef.current as HTMLDivElement);

    const handleSelect = (index: number) => {
      if (disabled) return;

      const selectedTag = filteredTags[index];

      if (!selectedTag) return;
      if (inputRef.current) {
        inputRef.current.textContent = '';
        setInputValue('');
        setImmediateInputValue('');
        const range = document.createRange();
        const sel = window.getSelection();
        range.selectNodeContents(inputRef.current);
        range.collapse(false);
        sel?.removeAllRanges();
        sel?.addRange(range);
        inputRef.current.focus();
      }

      setTimeout(() => {
        setSelectedTag(selectedTag);
        onSelectedTagChange?.(selectedTag);
        onSelect?.(index);
        setIsToggle(false);
      }, 0);
    };

    const handleToggle = (isToggle?: boolean) => {
      setIsToggle(isToggle ?? !isToggle);
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
      if (disabled || isComposing) return;

      if (e.key === 'Enter') {
        e.preventDefault();
        if (!isToggle || !filteredTags.length) {
          setIsToggle(true);
        }
      } else if (e.key === 'Tab') {
        e.preventDefault();
        const nextFocusable = inputRef.current?.nextElementSibling as HTMLElement;
        if (nextFocusable) {
          nextFocusable.focus();
        }
      } else if (e.key === 'Backspace' && !isChangeOnly) {
        if (window.getSelection()?.toString() || e.ctrlKey || e.metaKey) {
          e.preventDefault();
          setSelectedTag(null);
          onSelectedTagChange?.(null);
          setInputValue('');
          setImmediateInputValue('');
          if (inputRef.current) {
            inputRef.current.textContent = '';
          }
        } else if (inputValue === '' && selectedTag) {
          e.preventDefault();
          setSelectedTag(null);
          onSelectedTagChange?.(null);
          setIsToggle(true);
          inputRef.current?.focus();
        }
      } else if (e.key === 'Escape') {
        e.preventDefault();
        setIsToggle(false);
      } else if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
        e.preventDefault();
        if (!isToggle) {
          setIsToggle(true);
        } else {
          setImmediateInputValue(immediateInputValue);
        }
      }
    };

    const handleRemoveTag = () => {
      if (disabled || isChangeOnly) return;

      setSelectedTag(null);
      onSelectedTagChange?.(null);
      setIsToggle(true);
      onRemoveTag?.();
      inputRef.current?.focus();
    };

    const handleInput = (e: React.FormEvent<HTMLDivElement>) => {
      if (disabled) return;

      if (!isComposing) {
        const text = e.currentTarget?.textContent || '';
        setInputValue(text);
        setImmediateInputValue(text);
      }
    };

    const handleBlur = () => {
      setInputValue('');
      setImmediateInputValue('');
      if (inputRef.current) {
        inputRef.current.textContent = '';
      }
    };

    const handleCompositionStart = () => {
      setIsComposing(true);
    };

    const handleCompositionEnd = (e: React.CompositionEvent<HTMLDivElement>) => {
      setIsComposing(false);
      const text = e.currentTarget?.textContent || '';
      setInputValue(text);
      setImmediateInputValue(text);
    };

    useEffect(() => {
      const observer = new MutationObserver(() => {
        const text = inputRef.current?.textContent || '';
        setImmediateInputValue(text);
      });

      if (inputRef.current) {
        observer.observe(inputRef.current, {
          childList: true,
          subtree: true,
          characterData: true,
        });
      }

      return () => {
        observer.disconnect();
      };
    }, [inputRef]);

    useEffect(() => {
      setFilteredTags(
        tagOptions.filter((tag) =>
          tag.name.toLowerCase().includes(immediateInputValue.toLowerCase()),
        ),
      );
    }, [immediateInputValue, tagOptions]);

    useEffect(() => {
      if (inputRef.current) {
        if (isFocused && !disabled) {
          setIsToggle(true);
          inputRef.current.focus();
          inputRef.current.setAttribute('data-placeholder', '');
          inputRef.current.classList.remove('hidden-caret');

          if (inputRef.current.innerText.trim() === '') {
            inputRef.current.innerHTML = '';
            const textNode = document.createTextNode('');
            inputRef.current.appendChild(textNode);

            const range = document.createRange();
            const selection = window.getSelection();
            range.setStart(textNode, 0);
            range.collapse(true);

            if (selection) {
              selection.removeAllRanges();
              selection.addRange(range);
            }
          }
        } else {
          setIsToggle(false);
          inputRef.current.blur();
          inputRef.current.classList.add('hidden-caret');
        }
      }
    }, [isFocused, disabled]);

    useEffect(() => {
      if (inputRef.current) {
        if (!selectedTag && !isFocused) {
          inputRef.current.setAttribute('data-placeholder', placeholder ?? '');
        } else {
          inputRef.current.removeAttribute('data-placeholder');
        }
      }
    }, [selectedTag, placeholder, isFocused]);

    useEffect(() => {
      if (value === undefined) {
        setSelectedTag(null);
      } else {
        const matchingTag = tagOptions.find(
          (tag) => tag.id === value.id || tag.name === value.name,
        );
        setSelectedTag(matchingTag || null);
      }
    }, [value, tagOptions]);

    return (
      <div className={customTwMerge('flex flex-col', className)}>
        <div
          ref={ignoreRef}
          className={customTwMerge(
            'flex items-center px-12 outline-none group-hover:bg-blueLight',
            selectedTag ? 'py-[9.5px]' : 'py-11',
            !disabled ? 'border-white600 bg-white50' : '',
            (isFocused || isSelected) && 'bg-blue50',
          )}>
          {selectedTag && (
            <FilledTag
              textClassName={disabled ? 'text-Body12' : 'text-Caption9'}
              key={selectedTag.name}
              tagSize='small'
              bgColor={disabled ? Color.disabled : selectedTag.color}
              className={customTwMerge(
                'm-1 truncate',
                disabled ? 'cursor-default' : 'cursor-pointer',
              )}
              iconProps={
                !isChangeOnly && !disabled
                  ? {
                      name: 'close',
                      size: 12,
                    }
                  : undefined
              }
              onClick={(e) => {
                if (isChangeOnly) {
                  e?.stopPropagation();
                  setIsToggle(true);
                } else {
                  handleRemoveTag();
                }
              }}>
              {selectedTag.name}
            </FilledTag>
          )}
          <div
            ref={inputRef}
            className='single-line flex-1 overflow-hidden border-none bg-transparent text-Body12 text-black700 outline-none'
            contentEditable={!disabled}
            onInput={handleInput}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            onCompositionStart={handleCompositionStart}
            onCompositionEnd={handleCompositionEnd}
            suppressContentEditableWarning={true}
          />
        </div>
        {!disabled && isToggle && (
          <TagDropdown
            id={id}
            width={width}
            selectedIndex={filteredTags.findIndex((tag) => tag.name === selectedTag?.name)}
            tags={filteredTags.map((tag) => ({
              name: tag.name,
              id: tag.id ?? uuidv4(),
              color: tag.color ?? '#F5F5F5',
            }))}
            ignoreRefs={[ignoreRef]}
            isToggle={isToggle}
            handleToggle={handleToggle}
            onSelect={handleSelect}
            searchText={immediateInputValue}
            tagsClassName='px-12'
            customFocusScrollHandler={customFocusScrollHandler}
            customSelectedScrollHandler={customSelectedScrollHandler}
            handleClickOutside={handleClickOutside}
          />
        )}
      </div>
    );
  },
);

export default SingleTagSelectBoxTagDropdownForTable;
