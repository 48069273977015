import * as React from "react";
import type { SVGProps } from "react";
const SvgUndo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#7F7F7F"
      d="M10.417 6.666c-2.209 0-4.209.834-5.75 2.167l-3-3v7.5h7.5L6.15 10.316C7.308 9.35 8.783 8.75 10.416 8.75a6.68 6.68 0 0 1 6.334 4.583l1.975-.65c-1.158-3.492-4.434-6.017-8.309-6.017"
    />
  </svg>
);
export default SvgUndo;
