import { hospitalInfoState } from '@templates/HospitalSetting/containers/HospitalProfile/states/hospital-info-state';
import TextInput from 'afterdoc-design-system/components/Atoms/Input/TextInput';
import { useAtom, useSetAtom } from 'jotai/index';
import { selectAtom } from 'jotai/utils';

const hospitalShortNameAtom = selectAtom(hospitalInfoState, (hospital) => hospital.shortName);

export default function HospitalNameInput() {
  const [hospitalShortName] = useAtom(hospitalShortNameAtom);
  const setHospitalInfo = useSetAtom(hospitalInfoState);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHospitalInfo((prev) => ({
      ...prev,
      shortName: event.target.value,
    }));
  };

  return (
    <div>
      <TextInput
        label={{ isRequired: true, children: '병원명' }}
        placeholder='병원명 입력(최대 30자)'
        inputWrapperClassName='mt-10'
        maxLength={30}
        onChange={handleInputChange}
        value={hospitalShortName ?? ''}
      />
    </div>
  );
}
