import LabelText from '@afterdoc-design-system/components/Atoms/LabelText/LabelText';
import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';

export const NoData = () => {
  return (
    <div className='flex h-[222px] w-full flex-col items-center justify-center'>
      <div className='mb-4'>
        <Icon name='warning' color='white600' size={48} />
      </div>
      <div className='flex flex-col-center'>
        <LabelText textClassName='text-Header14' width='fit-content'>
          표시할 내용이 없습니다.
        </LabelText>
      </div>
    </div>
  );
};
