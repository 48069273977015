import AccountListTableRow from '@templates/HospitalSetting/containers/AllAccountSetting/containers/AllAccountList/components/AccountListTable/components/AccountListTableRow';
import {
  type RemoveHospitalAccountParams,
  type UpdateHospitalAccountParams,
  useHospitalAccount,
} from '@templates/HospitalSetting/containers/AllAccountSetting/hooks/use-hospital-account';
import { useCallback, useEffect } from 'react';

export default function AccountListTableBody() {
  const { fetchAccountsQuery, updateAccountMutation, removeAccountMutation } = useHospitalAccount();

  const { data: accounts } = fetchAccountsQuery();

  const handleChangeAccount = useCallback((body: UpdateHospitalAccountParams) => {
    updateAccountMutation.mutate(body);
  }, []);

  const handleRemoveAccount = useCallback((body: RemoveHospitalAccountParams) => {
    removeAccountMutation.mutate(body);
  }, []);

  return (
    <>
      <tbody>
        {accounts?.map((account) => (
          <AccountListTableRow
            account={account}
            key={account.id}
            handleChangeAccount={handleChangeAccount}
            handleRemoveAccount={handleRemoveAccount}
          />
        ))}
      </tbody>
    </>
  );
}
