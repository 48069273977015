import type { KakaoConsultationTalkLinkageHandlerBodyRequest } from '@apis/swaggers/swagger-docs';
import DayCheckbox from '@templates/HospitalSetting/containers/ChannelIntegration/components/IntegrationFormWrapper/KakaoConsultTalkForm/components/ConsultTalkIntegrationWrapper/components/IntegrationConsultDatePicker/DayCheckbox';
import IntegrationStartTimePicker from '@templates/HospitalSetting/containers/ChannelIntegration/components/IntegrationFormWrapper/common/RequestInfoWrapper/BeStartDateTimeInput/IntegrationStartTimePicker';
import { hasLinkageOrRequestHistoryState } from '@templates/HospitalSetting/containers/ChannelIntegration/states/has-linkage-or-request-history-state';
import {
  consultationInformationDayErrorAtom,
  consultationInformationTimeErrorAtom,
} from '@templates/HospitalSetting/containers/ChannelIntegration/states/kakao-consult-form-error-state';
import LabelText from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import HoverTooltip from 'afterdoc-design-system/components/Atoms/Tooltip/HoverTooltip';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import { useAtomValue } from 'jotai/index';
import React, { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';

export type ConsultationInformation = Omit<
  KakaoConsultationTalkLinkageHandlerBodyRequest['consultationInformation'],
  'consultationStartTime' | 'consultationEndTime'
>;

interface ConsultationAvailability {
  day: string;
  key: keyof ConsultationInformation;
}

const weekDays: ConsultationAvailability[] = [
  { day: '월', key: 'mondayAvailable' },
  { day: '화', key: 'tuesdayAvailable' },
  { day: '수', key: 'wednesdayAvailable' },
  { day: '목', key: 'thursdayAvailable' },
  { day: '금', key: 'fridayAvailable' },
  { day: '토', key: 'saturdayAvailable' },
  { day: '일', key: 'sundayAvailable' },
];

export default function IntegrationConsultDatePicker() {
  const { setValue, getValues } = useFormContext<KakaoConsultationTalkLinkageHandlerBodyRequest>();

  const consultationInformationDayError = useAtomValue(consultationInformationDayErrorAtom);

  const [consultationInformationTimeError, setConsultationInformationTimeError] = useAtom(
    consultationInformationTimeErrorAtom,
  );

  const hasLinkageOrRequestHistory = useAtomValue(hasLinkageOrRequestHistoryState);

  const [startTime, setStartTime] = useState(
    () => getValues('consultationInformation').consultationStartTime,
  );
  const [endTime, setEndTime] = useState(
    () => getValues('consultationInformation').consultationEndTime,
  );

  const onHandleChangeTime = useCallback(
    (time: string, type: 'start' | 'end') => {
      const key = type === 'start' ? 'consultationStartTime' : 'consultationEndTime';
      const currentAvailability = getValues('consultationInformation');

      setValue('consultationInformation', {
        ...currentAvailability,
        [key]: time,
      });
      setConsultationInformationTimeError(false);

      type === 'start' ? setStartTime(time) : setEndTime(time);
    },
    [setValue, getValues, setConsultationInformationTimeError],
  );

  return (
    <div className='flex w-full flex-col'>
      <div className='flex min-h-[32px] items-center gap-6'>
        <LabelText isRequired width={'w-fit'} className='flex-center'>
          상담가능일시
        </LabelText>
        <HoverTooltip
          position='topRight'
          text={
            '카카오 채널에 표시되는 단순 안내용입니다. \n1주를 기준으로 입력해 주세요. (예) 월~금 09:00~18:00)'
          }>
          <Icon name={'info-circle-outline'} color={'black200'} size={16} />
        </HoverTooltip>
      </div>
      <div className='py-10'>
        <div className='flex flex-col gap-12'>
          <div>
            <div className='flex gap-12'>
              {weekDays.map((item) => (
                <DayCheckbox
                  key={item.key}
                  fieldKey={item.key}
                  label={item.day}
                  disabled={hasLinkageOrRequestHistory !== 'NotLinked'}
                />
              ))}
            </div>
            {consultationInformationDayError && (
              <span className='mt-4 text-Body10 text-red500'>요일을 1개 이상 선택해 주세요.</span>
            )}
          </div>
          <div>
            <div className='flex items-center gap-8'>
              <IntegrationStartTimePicker
                currentDate={startTime}
                onHandleChangeTime={(time) => onHandleChangeTime(time, 'start')}
              />
              <span className='text-Body12 text-black500'>~</span>
              <IntegrationStartTimePicker
                currentDate={endTime}
                onHandleChangeTime={(time) => onHandleChangeTime(time, 'end')}
              />
            </div>
            {consultationInformationTimeError && (
              <span className='mt-4 text-Body10 text-red500'>
                종료시각이 시작시각보다 빠릅니다.
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
