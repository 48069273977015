import * as React from "react";
import type { SVGProps } from "react";
const SvgAfterdocNon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 19 19"
    {...props}
  >
    <rect width={18} height={18} x={0.5} y={0.5} fill="#fff" rx={5.5} />
    <rect width={18} height={18} x={0.5} y={0.5} stroke="#EBEBEB" rx={5.5} />
    <path
      fill="#888"
      d="M13.863 3.263 7.836 5.525c-.352.13-.638.532-.638.896v1.013h1.54V5.847h1.524v1.587h1.54v1.571h-1.54v1.587H8.738V9.005h-1.54v6.535c0 .363.286.55.638.417l6.027-2.261c.351-.132.637-.533.637-.897V3.68c0-.362-.286-.55-.637-.417"
      opacity={0.5}
    />
    <path
      fill="#888"
      d="M13.863 13.434H7.198V6.42c0-.363.286-.764.638-.897l6.027-2.262c.351-.131.637.055.637.418v-.022A.65.65 0 0 0 13.863 3H5.137a.65.65 0 0 0-.637.657v9.119c0 .362.286.657.637.657h8.726z"
    />
    <path
      fill="#888"
      d="M14.422 3.436c0-.362-.286-.55-.638-.417L7.757 5.281c-.351.131-.637.533-.637.896v7.015h6.664a.63.63 0 0 0 .516-.273.67.67 0 0 0 .122-.384V3.436m-2.7 5.324h-1.54v1.587H8.658V8.76h-1.54V7.188h1.54V5.601h1.524v1.587h1.54z"
      opacity={0.5}
    />
  </svg>
);
export default SvgAfterdocNon;
