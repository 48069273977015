import * as React from "react";
import type { SVGProps } from "react";
const SvgHwp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <rect width={32} height={32} fill="#000" rx={4} />
    <path
      fill="#fff"
      d="M23.063 20.88V12h2.916c1.956 0 3.468.684 3.468 2.796 0 2.028-1.512 2.928-3.42 2.928h-1.189v3.156zm1.776-4.56h1.068c1.224 0 1.811-.516 1.811-1.524 0-1.044-.648-1.38-1.872-1.38h-1.008zM12.922 20.88 11.206 12h1.812l.696 4.332c.144.936.276 1.884.42 2.844h.048c.18-.96.372-1.908.564-2.844L15.766 12h1.524l1.032 4.332c.192.924.36 1.884.564 2.844h.06c.12-.96.264-1.908.384-2.844L20.05 12h1.68l-1.644 8.88h-2.22l-.984-4.392a32 32 0 0 1-.384-2.052h-.048c-.12.672-.252 1.368-.384 2.052l-.972 4.392zM3 20.88V12h1.776v3.504h3.36V12H9.9v8.88H8.136v-3.84h-3.36v3.84z"
    />
  </svg>
);
export default SvgHwp;
