import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import type { ApiPopupHospitalElCountParams } from '@apis/swaggers/swagger-docs';
import PopupListBody from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/components/PopupList/PopupListBody/PopupListBody';
import {
  popupListKeywordAtom,
  popupListQueryKeyState,
} from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/state/popup-list-query-key-state';
import { popupTotalCountState } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/state/popup-total-count-state';
import { POPUP_LIST_COLUMNS } from '@templates/Content/containers/ContentManager/containers/NoticePopup/constants/notice-popup-list-columns';
import NoticePopupListSection from '@templates/Content/containers/ContentManager/containers/NoticePopup/shared/components/NoticePopupListSection/NoticePopupListSection';
import ContentListCommonFooter from '@templates/Content/containers/ContentManager/shared/components/ContentListCommonFooter/ContentListCommonFooter';
import ContentSearchHeader from '@templates/Content/containers/ContentManager/shared/components/ContentSearchHeader/ContentSearchHeader';
import { useResetAtom } from 'jotai/utils';
import React, { useEffect } from 'react';
import { SHARED_UTILS } from 'utils/utils';

const fetchNoticePopupCount = async (params: ApiPopupHospitalElCountParams) => {
  const response = await apiClient.v3.apiPopupHospitalElCount(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export default function PopupListContainer() {
  const resetPopupListQueryKey = useResetAtom(popupListQueryKeyState);

  useEffect(() => resetPopupListQueryKey(), []);

  return (
    <div className='flex min-h-screen w-full flex-col'>
      <div className='mx-auto w-[1150px] flex-col-center flex-grow bg-white50'>
        <ContentSearchHeader
          queryKey={QUERY_KEY.apiPopupHospitalElCount}
          fetchCount={fetchNoticePopupCount}
          totalCountState={popupTotalCountState}
          keywordState={popupListKeywordAtom}
          inputPlaceholder='팝업명으로 검색해 주세요.'
        />
        <div className='flex h-full w-full flex-grow flex-col'>
          <NoticePopupListSection
            columns={POPUP_LIST_COLUMNS}
            queryKeyState={popupListQueryKeyState}
            totalCountState={popupTotalCountState}>
            <PopupListBody />
          </NoticePopupListSection>
        </div>
      </div>
      <ContentListCommonFooter type={'popup'} />
    </div>
  );
}
