import Divider from '@afterdoc-design-system/components/Atoms/Divider/Divider';
import LoadingSpinner from '@afterdoc-design-system/components/Atoms/Loading/LoadingSpinner';
import EditorViewer from 'web/shared/components/EditorViewer/EditorViewer';
import { useManualData } from '../../hooks/use-manual-data';
import CautionImagesSelect from './components/CautionImaagesSelect/CautionImagesSelect';
import { NoHTMLData } from './components/NoHTMLData';

export default function TagDetailPanel({ treatmentTagId }: { treatmentTagId: string }) {
  const {
    data: { manualContent },
    isLoading,
  } = useManualData({ treatmentTagId });

  return (
    <div className='flex flex-col gap-16'>
      <div className='mx-20'>
        {!manualContent && isLoading && (
          <div className='flex flex-center'>
            <LoadingSpinner />
          </div>
        )}
        {manualContent ? <EditorViewer htmlContent={manualContent} /> : <NoHTMLData />}
        <Divider type='line' className='my-16' />
        <span className='text-Header14 text-black500 '>주의사항 이미지</span>
      </div>
      <CautionImagesSelect treatmentTagId={treatmentTagId} />
    </div>
  );
}
