import { useState } from 'react';
import { WIDTH } from '../constants/dropdown';

export const useNextDropdown = () => {
  const [nextDropdownPosition, setNextDropdownPosition] = useState<{ top: number; left: number }>({
    top: 0,
    left: WIDTH,
  });
  const [isNextDropdownOpen, setIsNextDropdownOpen] = useState(false);

  const updateNextDropdownPosition = ({ top, left }: { top: number; left: number }) => {
    setNextDropdownPosition((prev) => ({ ...prev, top, left }));
  };

  const toggleNextDropdown = (isOpen: boolean) => {
    setIsNextDropdownOpen(isOpen);
  };

  const handleIsNextDropdownOpen = () => {
    toggleNextDropdown(!isNextDropdownOpen);
  };

  return {
    nextDropdownPosition,
    isNextDropdownOpen,
    updateNextDropdownPosition,
    toggleNextDropdown,
    handleIsNextDropdownOpen,
  };
};
