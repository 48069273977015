import VideoPlayer from '@shared/components/VideoPlayer/VideoPlayer';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import { dialogService } from 'afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import React from 'react';

interface VideoContentThumbnailProps {
  title: string;
  thumbnailURL: string;
  vchID: string;
}

export default function VideoContentDetailThumbnail({
  title,
  thumbnailURL,
  vchID,
}: VideoContentThumbnailProps) {
  const handleClick = () => {
    dialogService.push(<VideoPlayer vchID={vchID} />, {
      hasCloseButton: true,
      titleProps: {
        title: title,
      },
      wrapperClassName: 'h-[590px] !rounded-bl-none !rounded-br-none',
    });
  };

  return (
    <div className='relative w-full cursor-pointer rounded-3x ' onClick={handleClick}>
      <img
        className='h-[175px] w-full flex-shrink-0 rounded-r16'
        src={thumbnailURL}
        alt='videoContentThumbnail'
      />
      <div
        className='absolute-center h-40 w-40 flex-center rounded-full'
        style={{ backgroundColor: 'rgba(17, 17, 17, 0.6)' }}>
        <Icon name='play' size={24} color='white50' />
      </div>
    </div>
  );
}
