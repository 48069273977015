import AutoGreetingMessageInput from '@templates/HospitalSetting/containers/HospitalProfile/components/AutoGreetingMessage/components/AutoGreetingMessageInput';
import AutoGreetingMessagePreview from '@templates/HospitalSetting/containers/HospitalProfile/components/AutoGreetingMessage/components/AutoGreetingMessagePreview';
import AutoGreetingMessageTitle from '@templates/HospitalSetting/containers/HospitalProfile/components/AutoGreetingMessage/components/AutoGreetingMessageTitle';
import { hospitalInfoState } from '@templates/HospitalSetting/containers/HospitalProfile/states/hospital-info-state';
import { createErrorMessageAtom } from '@templates/HospitalSetting/states/hospital-info-input-error-messages-state';
import { useAtom, useAtomValue } from 'jotai/index';
import { selectAtom } from 'jotai/utils';
import { useEffect } from 'react';

const selectedAtom = selectAtom(hospitalInfoState, (hospital) => hospital.initialChats);

const autoGreetingErrorAtom = createErrorMessageAtom('autoGreeting');

export default function AutoGreetingMessageContainer() {
  const initialChat = useAtomValue(selectedAtom);
  const [autoGreetingError, setAutoGreetingError] = useAtom(autoGreetingErrorAtom);

  useEffect(() => {
    if (autoGreetingError !== null) {
      setAutoGreetingError(null);
    }
  }, [initialChat?.[0]?.content]);

  return (
    <>
      <div className='flex w-full flex-col gap-10 px-80 pt-20 pb-40'>
        <div className='flex flex-grow gap-10'>
          <div className='w-full flex-grow px-20'>
            <AutoGreetingMessageTitle />
            <AutoGreetingMessageInput />
          </div>
          <div className='w-full flex-grow'>
            <AutoGreetingMessagePreview />
          </div>
        </div>
        {autoGreetingError && (
          <span className='px-20 text-Body10 text-red500'>{autoGreetingError}</span>
        )}
      </div>
    </>
  );
}
