import type { CreateUpdatePopupParamType } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/types/create-update-popup-param-type';
import dayjs from 'dayjs';

export const DEFAULT_POPUP_PARAMS: CreateUpdatePopupParamType = {
  _id: '',
  hospitalID: '',
  hospitalNoticeID: '',
  name: '',
  startDate: dayjs().format('YYYY-MM-DD'),
  endDate: dayjs().add(7, 'day').format('YYYY-MM-DD'),
  filter: { treatmentCategoryID_in: [], isFirstVisit_in: [] },
  popupType: 'text',
  title: undefined,
  content: undefined,
};
