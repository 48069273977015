import { hospitalInfoState } from '@templates/HospitalSetting/containers/HospitalProfile/states/hospital-info-state';
import { Group } from 'afterdoc-design-system/assets/icons';
import { Statusbar } from 'afterdoc-design-system/assets/images';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import { useAtomValue } from 'jotai';
import { selectAtom } from 'jotai/utils';
import { useEffect, useState } from 'react';

const selectedAtom = selectAtom(hospitalInfoState, (hospital) => hospital.initialChats);

export default function AutoGreetingMessagePreview() {
  const message = useAtomValue(selectedAtom);

  const [formattedMessage, setFormattedMessage] = useState('');

  useEffect(() => {
    const msg = message?.[0]?.content || '';

    const formattedMsg = msg
      .replaceAll('{{병원명}}', '애프터닥의원')
      .replaceAll('{{고객명}}', '홍길동');

    setFormattedMessage(formattedMsg);
  }, [message]);

  return (
    <>
      <div className='flex h-full flex-col overflow-hidden rounded-[24px] border-[5px] border-blueLight'>
        <div className='w-full flex-center bg-white50'>
          <Statusbar />
        </div>
        <div className='flex flex-grow flex-col bg-blue50'>
          <Scrollbar disabledX={true} className='flex-grow'>
            <div className='flex items-start gap-8 px-16 py-24'>
              <div className='flex-shrink-0'>
                <Group width={38} height={38} />
              </div>
              <div className='flex flex-grow flex-col'>
                <span className='text-Body10Bold text-black500'>애프터닥의원</span>
                <div className='mt-8 flex items-center rounded-[12px] bg-white50 px-12 py-8'>
                  <span className='whitespace-pre-wrap break-all text-Body12 text-black700'>
                    {formattedMessage || '내용을 입력해 주세요.'}
                  </span>
                </div>
              </div>
              <span className='mt-auto w-fit flex-shrink-0 text-Body10 text-black200'>
                오후 1:40
              </span>
            </div>
          </Scrollbar>
        </div>
      </div>
    </>
  );
}
