import TextInput from 'afterdoc-design-system/components/Atoms/Input/TextInput';
import { useFormContext } from 'react-hook-form';

export default function EditName() {
  const { register, clearErrors } = useFormContext();

  return (
    <div className='flex w-full flex-col items-start gap-4'>
      <TextInput
        placeholder='예) 홍길동'
        maxLength={1000}
        className='w-full'
        label={{
          isRequired: true,
          children: '고객명',
          width: 70,
          position: 'horizontal',
        }}
        {...register('name', {
          required: true,
          onChange: () => clearErrors('name'),
        })}
      />
    </div>
  );
}
