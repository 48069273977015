import type { InfoKey } from '@templates/UserInfoSetting/containers/AccountSetting/components/AccountSettingsSection';
import UserInfoUpdateId from '@templates/UserInfoSetting/containers/AccountSetting/components/UserInfoUpdateDialog/components/UserInfoUpdateID';
import UserInfoUpdatePassword from '@templates/UserInfoSetting/containers/AccountSetting/components/UserInfoUpdateDialog/components/UserInfoUpdatePassword';
import UserInfoUpdatePhone from '@templates/UserInfoSetting/containers/AccountSetting/components/UserInfoUpdateDialog/components/UserInfoUpdatePhone';
import UserInfoUpdatePhoneVerification from '@templates/UserInfoSetting/containers/AccountSetting/components/UserInfoUpdateDialog/components/UserInfoUpdatePhoneVerification';
import { useState } from 'react';

interface UserInfoUpdateDialogProps {
  infoKey: InfoKey;
}

const renderMap = (
  infoKey: InfoKey,
  isAuthenticated: boolean,
  setIsAuthenticated: (value: boolean) => void,
) => {
  if (infoKey !== 'phone' && !isAuthenticated)
    return <UserInfoUpdatePhoneVerification onAuthenticate={() => setIsAuthenticated(true)} />;

  switch (infoKey) {
    case 'id':
      return <UserInfoUpdateId />;
    case 'password':
      return <UserInfoUpdatePassword />;
    default:
      return <UserInfoUpdatePhone />;
  }
};

export default function UserInfoUpdateDialog({ infoKey }: UserInfoUpdateDialogProps) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  return (
    <div className='h-[256px] px-20 pt-10 pb-20'>
      {renderMap(infoKey, isAuthenticated, setIsAuthenticated)}
    </div>
  );
}
