import type { ProgressBarProps } from '@afterdoc-design-system/components/Molecules/ProgressBar/ProgressBar';
import { useCallback, useEffect, useRef, useState } from 'react';

const CHUNK_SIZE = 1024 * 1024;

export default function useFileDownloadWithProgress() {
  const fileNameRef = useRef<string | null>(null);
  const fileBlobRef = useRef<Blob | null>(null);
  const fileUrlRef = useRef<string | null>(null);

  const [isCanceled, setIsCanceled] = useState(false);
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState<ProgressBarProps['status']>();
  const [filePath, setFilePath] = useState<string>();

  // ProgressBar 상태를 업데이트하는 함수
  const updateProgressBar = (status: ProgressBarProps['status'], percentage: number) => {
    const event = new CustomEvent('showProgressBar', {
      detail: {
        status,
        percentage,
        fileName: fileNameRef.current ?? '',
        onCancelClickCallback: (e: Event) => {
          e.preventDefault();
          cancelDownload();
        },
        onFileOpenClickCallback: (e: Event) => {
          e.preventDefault();
          openFileFolder();
        },
        shouldAutoHide: true,
        onReloadClickCallback: (e: Event) => {
          e.preventDefault();
          if (fileBlobRef.current || fileUrlRef.current) {
            startDownload(
              fileBlobRef.current || (fileUrlRef.current as Blob | string),
              fileNameRef.current as string,
              [{ name: fileNameRef.current as string, extensions: ['*'] }],
            );
          }
        },
      },
    });
    document.dispatchEvent(event);
  };

  const hideProgressBar = () => {
    const event = new Event('hideProgressBar');
    document.dispatchEvent(event);
  };

  const startDownload = async (
    source: Blob | string,
    fileName: string,
    filters: { name: string; extensions: string[] }[],
  ) => {
    setIsCanceled(false);
    setProgress(0);
    setStatus(undefined);
    setFilePath(undefined);

    const sanitizedFileName = fileName.replace(/[^a-zA-Z0-9가-힣\s.-]/g, '').substring(0, 100);

    if (typeof source === 'string') {
      fileUrlRef.current = source;
      fileBlobRef.current = null;
    } else {
      fileBlobRef.current = source;
      fileUrlRef.current = null;
    }

    fileNameRef.current = sanitizedFileName;

    setupListeners();

    window.electron?.ipcRenderer.send('Application.startFileDownload', {
      title: 'Download File',
      fileName,
      filters,
    });
  };

  const setupListeners = () => {
    removeAllListeners();

    window.electron?.ipcRenderer.on('Application.writeStreamCreated', writeStreamHandler);
    window.electron?.ipcRenderer.on('Application.fileSaveDialogResult', fileSaveDialogHandler);
    window.electron?.ipcRenderer.on('Application.openFileLocatedFolderResult', openFolderHandler);
    window.electron?.ipcRenderer.on('Application.cancelDownloadCompleted', cancelDownloadHandler);
  };

  const writeStreamHandler = useCallback(() => {
    downloadFileAfterSaveDialog();
  }, []);

  const fileSaveDialogHandler = useCallback((_: unknown, path: string) => {
    setStatus(path ? 'complete' : 'error');
    setFilePath(path);
  }, []);

  const openFolderHandler = useCallback((_: unknown, { success }: { success: boolean }) => {
    if (!success) {
      console.error('Error opening folder');
    }
  }, []);

  const cancelDownloadHandler = useCallback(() => {
    setIsCanceled(true);
    setStatus('error');
  }, []);

  const downloadFileAfterSaveDialog = async () => {
    if (!fileBlobRef.current && !fileUrlRef.current) return;
    setIsCanceled(false);
    setStatus('downloading');
    setProgress(0);

    let offset = 0;

    const sendChunk = async () => {
      if (isCanceled) return;

      if (!fileBlobRef.current && fileUrlRef.current) {
        const response = await fetch(fileUrlRef.current);
        fileBlobRef.current = await response.blob();
      }

      const blob = fileBlobRef.current;
      if (!blob) return;
      const totalSize = blob.size;

      if (offset >= totalSize) {
        window.electron?.ipcRenderer.send('Application.downloadFileEnd');
        return;
      }

      const chunk = blob.slice(offset, offset + CHUNK_SIZE);
      const reader = new FileReader();

      reader.onload = () => {
        if (isCanceled) return;

        const chunkData = reader.result;
        if (chunkData) {
          const progress = Math.floor((offset / totalSize) * 100);
          window.electron?.ipcRenderer.send('Application.downloadFileChunk', {
            chunk: chunkData,
            progress,
          });
          setProgress(progress);
          offset += CHUNK_SIZE;
          sendChunk();
        }
      };

      reader.onerror = () => {
        console.error('Error reading file chunk');
      };

      reader.readAsArrayBuffer(chunk);
    };

    sendChunk();
  };

  const cancelDownload = () => {
    setIsCanceled(true);
    setStatus('error');
    window.electron?.ipcRenderer.send('Application.cancelFileDownload');
  };

  const openFileFolder = () => {
    if (!filePath) return;
    window.electron?.ipcRenderer.send('Application.openFileLocatedFolder', filePath);
  };

  const removeAllListeners = () => {
    window.electron?.ipcRenderer.removeAllListeners('Application.writeStreamCreated');
    window.electron?.ipcRenderer.removeAllListeners('Application.fileSaveDialogResult');
    window.electron?.ipcRenderer.removeAllListeners('Application.openFileLocatedFolderResult');
    window.electron?.ipcRenderer.removeAllListeners('Application.cancelDownloadCompleted');
  };

  useEffect(() => {
    if (status) {
      updateProgressBar(status, progress);
    }
  }, [status]);

  useEffect(() => {
    setupListeners();

    return () => {
      removeAllListeners();
    };
  }, []);

  return {
    startDownload,
    hideProgressBar,
    cancelDownload,
    openFileFolder,
    progress,
    status,
    filePath,
  };
}
