import type { SmartdoctorCustomer } from '@shared/components/ALIntegrationEdit/hooks/type';
import SmartDoctorTableBody from '@templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/SmartDoctor/components/SmartDoctorReservationHistory/components/SmartDoctorReservationHistoryTable/components/SmartDoctorTableBody';
import SmartDoctorTableHeader from '@templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/SmartDoctor/components/SmartDoctorReservationHistory/components/SmartDoctorReservationHistoryTable/components/SmartDoctorTableHeader';

interface SmartDoctorReservationHistoryTableProps {
  customer: SmartdoctorCustomer | undefined;
}

export default function SmartDoctorReservationHistoryTable({
  customer,
}: SmartDoctorReservationHistoryTableProps) {
  return (
    <table className='h-full w-full table-fixed bg-white text-Body12'>
      <colgroup>
        <col className='w-[100px]' />
        <col className='w-[200px]' />
        <col className='w-[180px]' />
        <col className='w-[100px]' />
        <col />
        <col className='w-[100px]' />
      </colgroup>
      <SmartDoctorTableHeader />
      <SmartDoctorTableBody customer={customer} />
    </table>
  );
}
