import { hospitalSettingPageState } from '@templates/HospitalSetting/states/hospital-setting-page-state';
import { PANEL_ITEMS } from '@templates/UserInfoSetting/components/UserInfoPanel/constants/panel-items';
import { USER_INFO_SETTING_PAGE } from '@templates/UserInfoSetting/constants/page';
import { isNotificationSettingModifiedState } from '@templates/UserInfoSetting/containers/NotificationSetting/states/is-notification-setting-modified-state';
import { userInfoPageState } from '@templates/UserInfoSetting/states/user-info-page-state';
import type { UserInfoPageType } from '@templates/UserInfoSetting/types/user-info-page-type';
import PanelList from 'afterdoc-design-system/components/Atoms/PanelList/PanelList';
import { modalService } from 'afterdoc-design-system/components/Molecules/Modal/Modal.service';
import Title from 'afterdoc-design-system/components/Molecules/Title/Title';
import { useAtomValue, useSetAtom } from 'jotai';
import { useAtom } from 'jotai/index';
import { useResetAtom } from 'jotai/utils';

const getPanelState = (layerIndex: number, itemIndex: number): UserInfoPageType | undefined => {
  const stateMapping: { [key: string]: UserInfoPageType } = {
    '0,0': USER_INFO_SETTING_PAGE.USER_INFO_SETTING,
    '1,0': USER_INFO_SETTING_PAGE.NOTIFICATION_SETTING,
  };
  return stateMapping[`${layerIndex},${itemIndex}`];
};

export default function UserInfoPanel() {
  const [currentPage, setCurrentPage] = useAtom(userInfoPageState);

  const resetIsNotificationSettingModified = useResetAtom(isNotificationSettingModifiedState);
  const isNotificationSettingModified = useAtomValue(isNotificationSettingModifiedState);

  const showModal = () =>
    new Promise<boolean>((resolve) => {
      modalService.defaultWarning({
        onConfirm: () => {
          modalService.pop();
          resolve(true);
        },
        onCancel: () => {
          modalService.pop();
          resolve(false);
        },
      });
    });

  const shouldProceedWithClick = async (layerIndex: number, itemIndex?: number) => {
    const newPage = getPanelState(layerIndex, itemIndex ?? 0);
    if (newPage && isNotificationSettingModified && currentPage !== newPage) {
      return await showModal();
    }
    resetIsNotificationSettingModified();
    return true;
  };

  const handleLayerClick = (layerIndex: number) => {
    const items = PANEL_ITEMS[layerIndex].items;
    if (items.length === 0) {
      const page = getPanelState(layerIndex, 0);
      if (page) setCurrentPage(page);
    }
  };

  return (
    <div className='w-full border-r border-r-white400 bg-white50'>
      <Title title='내 정보 설정' />
      <div className='py-10'>
        <PanelList
          layers={PANEL_ITEMS}
          shouldProceedWithClick={shouldProceedWithClick}
          onLayerClick={handleLayerClick}
        />
      </div>
    </div>
  );
}
