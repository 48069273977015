import type { VideoContentHospitalCategoryThumbnailPath } from '@apis/swaggers/swagger-docs';
import VideoContentListItem from '@templates/Content/containers/ContentManager/containers/VideoContent/componants/VideoContentList/components/VideoContentListItem';
import { videoContentNextPageEnabledState } from '@templates/Content/containers/ContentManager/containers/VideoContent/states/video-content-next-page-enabled-state';
import { videoContentSkipState } from '@templates/Content/containers/ContentManager/containers/VideoContent/states/video-content-skip-state';
import { focusedContentSubLayerState } from '@templates/Content/containers/ContentPanel/states/focused-content-sub-layer-state';
import { useIntersectionObserver } from '@templates/CustomerManagement/containers/BoardPanel/containers/shared/hooks/use-intersection-observer';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import { useAtomValue } from 'jotai';
import { useSetAtom } from 'jotai/index';
import { useEffect, useRef } from 'react';

interface VideoContentListProps {
  contents: VideoContentHospitalCategoryThumbnailPath[];
  searchKeyword: string;
}

export default function VideoContentList({ contents, searchKeyword }: VideoContentListProps) {
  const loadMoreRef = useRef<HTMLDivElement | null>(null);

  const focusedContentSubLayer = useAtomValue(focusedContentSubLayerState);
  const nextPageEnabled = useAtomValue(videoContentNextPageEnabledState);

  const setVideoContentSkip = useSetAtom(videoContentSkipState);

  const scrollbarRef = useRef<{
    scrollTo: (index: number, behavior?: ScrollBehavior) => void;
  }>(null);

  useIntersectionObserver({
    target: loadMoreRef.current,
    onIntersect: () => setVideoContentSkip((prevSkip) => prevSkip + 20),
    enabled: nextPageEnabled,
  });

  useEffect(() => {
    if (scrollbarRef.current) {
      scrollbarRef.current.scrollTo(0, 'auto');
    }
  }, [focusedContentSubLayer, searchKeyword]);

  return (
    <Scrollbar disabledX={true} className='flex-grow' ref={scrollbarRef}>
      <div className='my-10 w-full flex-col-center gap-10'>
        {contents.map((content) => (
          <VideoContentListItem
            key={`${focusedContentSubLayer?.text}_${content._id}`}
            {...content}
          />
        ))}
      </div>
      <div ref={loadMoreRef} style={{ height: 1 }} />
    </Scrollbar>
  );
}
