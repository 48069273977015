import HospitalSettingPanel from '@templates/HospitalSetting/components/HospitalSettingPanel';
import WrapperPageContainer from '@templates/HospitalSetting/containers/WrapperPage.container';

export default function HospitalSettingContainer() {
  return (
    <main className='grid h-full grid-cols-[300px,1fr]'>
      <HospitalSettingPanel />
      <WrapperPageContainer />
    </main>
  );
}
