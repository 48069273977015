import ContainedButton from '@afterdoc-design-system/components/Atoms/Button/ContainedButton';
import OutlinedButton from '@afterdoc-design-system/components/Atoms/Button/OutlinedButton';
import DownloadFooter from '@afterdoc-design-system/components/Molecules/DownloadFooter/DownloadFooter';
import { modalService } from '@afterdoc-design-system/components/Molecules/Modal/Modal.service';
import ProgressBar from '@afterdoc-design-system/components/Molecules/ProgressBar/ProgressBar';
import Portal from '@afterdoc-design-system/shared/Portal/Portal';
import { SHARED_UTILS } from '@shared-utils/utils';
import { useMutation, useSuspenseQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { Suspense, useEffect, useRef } from 'react';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type {
  TemporaryPatientsLastModifierHandlerBodyRequest,
  TemporaryPatientsRequestLockHandlerBodyRequest,
} from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import EditorModeButtons from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementSaveFooter/components/EditorModeButtons/EditorModeButtons';
import { useSaveCustomerManagementExcelForm } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementSaveFooter/hooks/save/use-save-customer-management-excel-form';
import { useLoadReservationHistory } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementSaveFooter/hooks/upload/use-load-reservation-history';
import { useUploadExcelCustomerManagement } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementSaveFooter/hooks/upload/use-upload-customer-management-excel';
import { useTemporaryPatientsLock } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementSaveFooter/utils/ use-temporary-patients-lock';
import { customerTableDisplayModeState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/states/customer-management-display-mode';
import { modifierInfoState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/states/modifier-info';

const patchRequestLock = async (params: TemporaryPatientsRequestLockHandlerBodyRequest) => {
  const response = await apiClient.v3.temporaryPatientsRequestLockHandler(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

interface DownloadExcelFormButtonProps {
  handleDownload: () => void;
}

function DownloadExcelFormButton({ handleDownload }: DownloadExcelFormButtonProps) {
  return (
    <OutlinedButton
      iconProps={{
        name: 'tray-download',
        color: 'white700',
        size: 20,
        direction: 'left',
      }}
      btnColor='secondary'
      onClick={handleDownload}>
      엑셀 양식 다운로드
    </OutlinedButton>
  );
}

function UploadExcelFormButton() {
  const { onUpload, fileInputRef } = useUploadExcelCustomerManagement();

  const handleClickUploadExcelFormButton = () => {
    modalService.defaultSuccess({
      id: 'upload-excel-modal',
      title: '선택된 영역에 업로드 할까요?',
      buttonType: 'CUSTOM',
      contents: (
        <>
          <b>
            현재 선택된 셀을 기준으로 정보가 기입되며, 입력된 내용이 있는 경우{' '}
            <span className='text-red500'>업로드된 정보로 덮어쓰기</span> 됩니다.
          </b>
          <br />
          선택한 행을 포함하여 아래에 기입된 정보가 없는지 확인하신 후 업로드를 진행해 주세요.
        </>
      ),
      bottomButtons: (
        <div className='grid w-full grid-cols-2 items-center gap-8'>
          <OutlinedButton
            onClick={() => {
              modalService.popById('upload-excel-modal');
            }}
            className='w-full'
            btnColor='blue'>
            취소
          </OutlinedButton>
          <ContainedButton
            onClick={() => {
              fileInputRef.current?.click();
              modalService.popById('upload-excel-modal');
            }}
            className='w-full'
            btnColor='blue'>
            업로드
          </ContainedButton>
        </div>
      ),
    });
  };

  return (
    <>
      <input
        ref={fileInputRef}
        type='file'
        accept='.xlsx,.csv'
        className='hidden'
        onChange={onUpload}
      />
      <OutlinedButton
        iconProps={{
          name: 'tray-upload',
          color: 'white700',
          size: 20,
          direction: 'left',
        }}
        btnColor='secondary'
        onClick={handleClickUploadExcelFormButton}>
        엑셀 업로드
      </OutlinedButton>
    </>
  );
}

function TodayReservationCustomerButton() {
  const { onLoadReservationHistory } = useLoadReservationHistory();

  const handleClickTodayReservationCustomerButton = () => {
    modalService.defaultSuccess({
      id: 'load-today-reservation-customer-modal',
      title: '선택한 영역에 업로드 할까요?',
      buttonType: 'CUSTOM',
      contents: (
        <>
          <b>
            현재 선택된 셀을 기준으로 정보가 기입되며, 입력된 내용이 있는 경우&nbsp;
            <span className='text-red500'>불러온 정보로 덮어쓰기</span> 됩니다.
          </b>
          <br />
          선택한 행을 포함하여 아래에 기입된 정보가 없는지 확인하신 후 불러오기를 진행해 주세요.
        </>
      ),
      bottomButtons: (
        <div className='grid w-full grid-cols-2 items-center gap-8'>
          <OutlinedButton
            onClick={() => {
              modalService.popById('load-today-reservation-customer-modal');
            }}
            className='w-full'
            btnColor='blue'>
            취소
          </OutlinedButton>
          <ContainedButton
            onClick={() => {
              onLoadReservationHistory();
              modalService.popById('load-today-reservation-customer-modal');
            }}
            className='w-full'
            btnColor='blue'>
            불러오기
          </ContainedButton>
        </div>
      ),
    });
  };

  return (
    <OutlinedButton
      iconProps={{
        name: 'calendar-blank-outline',
        color: 'white700',
        size: 20,
        direction: 'left',
      }}
      btnColor='secondary'
      onClick={handleClickTodayReservationCustomerButton}>
      오늘 예약고객 불러오기
    </OutlinedButton>
  );
}

export default function CustomerManagementSaveFooter() {
  const customerTableDisplayMode = useAtomValue(customerTableDisplayModeState);

  // 엑셀 양식 다운로드
  const {
    handleDownloadCustomerManagementExcelForm,
    excelTitle,
    cancelDownload,
    openFileFolder,
    progress,
    status,
  } = useSaveCustomerManagementExcelForm();

  return (
    <>
      <DownloadFooter
        customLeftButton={
          <div className='flex flex-row items-center gap-10'>
            <DownloadExcelFormButton handleDownload={handleDownloadCustomerManagementExcelForm} />
            {customerTableDisplayMode === 'EDITOR' && (
              <>
                <UploadExcelFormButton />
                <TodayReservationCustomerButton />
              </>
            )}
          </div>
        }
        customRightButton={
          customerTableDisplayMode === 'VIEWER' ? (
            <Suspense fallback={<OutlinedButton btnColor='blue'>수정</OutlinedButton>}>
              <EditButton />
            </Suspense>
          ) : (
            <EditorModeButtons />
          )
        }
        className='sticky right-0 bottom-0'
      />
      {!!status && (
        <Portal
          style={{
            position: 'fixed',
            zIndex: 10000,
            right: '40px',
            bottom: '40px',
            width: '630px',
          }}>
          <ProgressBar
            fileName={excelTitle}
            status={status}
            percentage={progress}
            onCancelClickCallback={(e) => {
              e.preventDefault();
              e.stopPropagation();

              cancelDownload();
            }}
            onFileOpenClickCallback={(e) => {
              e.preventDefault();
              e.stopPropagation();

              openFileFolder();
            }}
            shouldAutoHide={true}
            onReloadClickCallback={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleDownloadCustomerManagementExcelForm();
            }}
          />
        </Portal>
      )}
    </>
  );
}

const patchTemporaryPatientsLastModifier = async (
  params: TemporaryPatientsLastModifierHandlerBodyRequest,
) => {
  const response = await apiClient.v3.temporaryPatientsLastModifierHandler(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

function EditButton() {
  const isFirstRender = useRef(true);

  const { getPatientsIsLocked } = useTemporaryPatientsLock();
  const { hospitalID } = useSelectedHospitalInfo();
  const [modifierInfo, setModifierInfo] = useAtom(modifierInfoState);
  const setCustomerTableDisplayMode = useSetAtom(customerTableDisplayModeState);

  const { data: lockData } = useSuspenseQuery({
    queryKey: [QUERY_KEY.temporaryPatientsIsLockHandler, { hospitalID }] as const,
    queryFn: ({ queryKey }) => getPatientsIsLocked(queryKey[1]),
    refetchInterval: 10000,
    staleTime: 0,
    gcTime: 0,
  });

  const patchTemporaryPatientsLastModifierMutation = useMutation({
    mutationFn: patchTemporaryPatientsLastModifier,
    onSuccess: (data) => {
      setModifierInfo({
        createdAt: data.createdAt,
        userID: data.userID,
      });
    },
  });

  const patchRequestLockMutation = useMutation({
    mutationFn: patchRequestLock,
  });

  const handleClickEditButton = async () => {
    if (patchRequestLockMutation.isPending) return;

    const latestLockData = await getPatientsIsLocked({ hospitalID });

    if (latestLockData.isLock) {
      setModifierInfo({
        createdAt: latestLockData.updatedAt,
        userID: latestLockData.requestUserID,
      });
      return;
    }

    setCustomerTableDisplayMode('EDITOR');
  };

  useEffect(() => {
    if (isFirstRender.current && !hospitalID) {
      isFirstRender.current = false;
      return;
    }

    patchTemporaryPatientsLastModifierMutation.mutate({
      hospitalID,
    });
  }, [hospitalID]);

  return (
    <div className='flex flex-row items-center gap-10'>
      <div className='text-Body11 text-black200'>
        업데이트: {modifierInfo ? dayjs(modifierInfo?.createdAt).format('YYYY-MM-DD HH:mm') : '-'} /{' '}
        {modifierInfo?.userID?.realName ?? '-'}
      </div>
      <div className='relative'>
        <OutlinedButton btnColor='blue' onClick={handleClickEditButton} disabled={lockData.isLock}>
          수정
        </OutlinedButton>
        {!!lockData.isLock && !patchTemporaryPatientsLastModifierMutation.isPending && (
          <div className='-top-[70px] absolute right-0'>
            <div className='relative w-[244px]'>
              <div className='relative rounded-lg bg-black700 bg-opacity-80 px-10 py-8'>
                <div className='text-Body12 text-white50'>
                  현재 {lockData.requestUserID?.realName ?? '-'}님이 작성중입니다. 다른 사용자의
                  작성이 완료된 후 수정/저장할 수 있습니다.
                </div>
                <div className='-bottom-[12px] absolute right-[30px] h-0 w-0 border-t-[12px] border-t-black700/80 border-l-[20px] border-l-transparent' />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
