import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import BookmarkToggle from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/components/ChattingMessage/components/BookmarkToggle/BookmarkToggle';
import { isActivateChatTranslateAtom } from 'web/templates/CustomerChat/components/ChattingRoom/states/isChatTranslate';
import { useChatMessageContext } from '../../ChatMessageContext';
import Message from '../../Message/Message';
import { useLottieAnimation } from '../../Message/hooks/use-lottie-animation';
import MessageTimestamp from '../../MessageTimestamp';
import { MESSAGE_TYPE_CODE } from '../../constants/message';
import { makeChattingInfoStringDisplay } from '../../functions/makeChattingInfoStringDisplay';
import BottomError from './components/BottomError';
import { TEXT_MESSAGE_LIMIT_TIME } from './constants/rightMessage';

export default function RightMessage() {
  const { userName, sentAt, type, userRealName, isUnread, status } = useChatMessageContext();

  const isActivateChatTranslate = useAtomValue(isActivateChatTranslateAtom);

  const isMockingMessageInitial =
    (!isActivateChatTranslate && type === MESSAGE_TYPE_CODE.TEXT_MOCKING) ||
    type === MESSAGE_TYPE_CODE.IMAGE_MOCKING ||
    type === MESSAGE_TYPE_CODE.FILE_MOCKING;
  const [isMockingMessage, setIsMockingMessage] = useState(isMockingMessageInitial);

  useEffect(() => {
    setIsMockingMessage(isMockingMessageInitial);
  }, [isMockingMessageInitial]);

  const isFailed = status === 'failed';
  const [isTimeError, setIsTimeError] = useState(false);
  const isSystem = type === MESSAGE_TYPE_CODE.SYSTEM;
  const displayRightUserName = makeChattingInfoStringDisplay({
    type: 'rightUserName',
    payload: { userName, userRealName },
  });

  //send한 메시지를 빠르게 보여줬을 경우(res 받기 전) 후의 res로 인해 timestamp, 읽지 않음 깜빡임을 방지하기 위한 로직
  //큰 이슈는 아닌 것 같아서 기록을 남겨놓고 추후 voc가 만약 나오면 개선.
  // const preventBlinkOnSend = !isSendingTranslate || !isLastMessage;
  const preventBlinkOnSend = true;
  const lottieRef = useLottieAnimation({ LottieName: 'messageLoading' });

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isMockingMessage) {
      // Set a timer to change isMockingMessage to false after 10 seconds
      timer = setTimeout(() => {
        setIsMockingMessage(false);
        setIsTimeError(true);
      }, TEXT_MESSAGE_LIMIT_TIME);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [isMockingMessage]);

  return (
    <div className='mr-20 flex justify-end'>
      <div className='group flex flex-col items-end gap-8'>
        <div className='text-right text-Header12 text-black200'>
          {isSystem ? '시스템 안내' : displayRightUserName}
        </div>
        <div className='flex flex-row items-end gap-8'>
          {isMockingMessage && <div ref={lottieRef} className='h-30 w-30 pt-10' />}

          {!isMockingMessage && isUnread && preventBlinkOnSend && (
            <span className='text-Body10Bold text-black500'>읽지 않음</span>
          )}

          {!isMockingMessage && (
            <div className='flex flex-col items-end justify-end'>
              <BookmarkToggle />
              {preventBlinkOnSend && <MessageTimestamp sentAt={sentAt} />}
            </div>
          )}

          <Message />
        </div>
        {(isTimeError || isFailed) && <BottomError isFailed={isFailed} />}
      </div>
    </div>
  );
}
