import { useAtomValue } from 'jotai';
import { useUserInfo } from 'web/shared/hooks/use-user-info';
import { chattingWidthAtom } from 'web/templates/CustomerChat/states/chattingWidth';
import { ChatMessageProvider } from './ChatMessageContext';
import LeftMessage from './components/LeftMessage/LeftMessage';
import RightMessage from './components/RightMessage/RightMessage';
import { MESSAGE_TEXT_MAX_LENGTH, urlRegex } from './constants/message';
import type { ChattingMessageProps } from './types/message';

/**
 * ChattingMessage 컴포넌트는 채팅 메시지를 렌더링하는 역할을 합니다.
 * ChatMessageProvider를 사용하여 메시지의 세부 정보를 전처리하여 자식 컴포넌트인 메시지 렌더링 컴포넌트인
 * LeftMessage 또는 RightMessage에 전달합니다.
 *
 * @param {string} props.retranslatedContent - 메시지의 재번역된 내용입니다.
 * @param {string} props.translatedContent - 메시지의 번역된 내용입니다.
 * @param {boolean} props.isLeft - 메시지가 환자여서 왼쪽 메시지인지 아닌지 체크하는 타입입니다.
 * @param {boolean} props.isUnread - 메시지가 읽음 상태인지 체크하는 타입입니다.
 * @param {boolean} props.isTranslated - 메시지가 번역되었는지 여부를 나타내는 플래그입니다.
 * @param {string} props.content - 메시지의 원본 내용입니다.
 * @param {string} props.createdAt - 메시지가 생성된 시간의 타임스탬프입니다.
 * @param {string} props.userID - 해당 채팅의 환자 ID와 정보가 있습니다.
 * @param {string} props.userName - 메시지를 보낸 사용자의 이름입니다.
 * @param {string} props.userRealName - 메시지를 보낸 사용자의 실제 이름입니다.
 * @param {number} props.type - 메시지의 유형(예: 텍스트, 이미지, 비디오 등)입니다.
 * @param {number} props.userType - 사용자의 유형(예: 환자는 1000)입니다.
 * @param {Array<string>} props.fileIDs - 메시지에 첨부된 파일 ID의 배열입니다.
 * @param {string} props.heventID - 메시지와 연결된 이벤트 ID입니다(선택적).
 * @param {Array<string>} props.videoContentIDs - 메시지와 연결된 비디오 콘텐츠 ID의 배열입니다.
 * @param {string} props.haftercareID - 메시지와 연결된 사후 관리 ID입니다(선택적).
 * @param {string} props.hnoticeID - 메시지와 연결된 공지 ID입니다(선택적).
 * @param {Function} props.sendMessage - 메시지를 보내기 위한 함수로, provider에 전달됩니다.
 *
 * @returns {JSX.Element} 렌더링된 채팅 메시지 컴포넌트를 반환합니다.
 */

export default function ChattingMessage({ ...chatMessage }: ChattingMessageProps) {
  const { isLeft, content = '', userID, type } = chatMessage;

  const { userId } = useUserInfo();
  const chattingWidth = useAtomValue(chattingWidthAtom);
  const messageWidthStyle = {
    width: `${chattingWidth / 2}px`,
  };
  const isMine = userID === userId;

  const isOverLength = content.length > MESSAGE_TEXT_MAX_LENGTH;

  const contentParts = content.split(urlRegex) ?? [];
  const isNull = (type === 100 || type === 20100 || type === 12100 || type === 11100) && !content; //텍스트 메시지에서 텍스트가 빈값일 경우 무조건적으로 안보여줌.

  return (
    <>
      <ChatMessageProvider
        value={{
          ...chatMessage,
          chattingWidth,
          isMine,
          messageWidthStyle,
          contentParts,
          isOverLength,
        }}>
        {!isNull && (isLeft ? <LeftMessage /> : <RightMessage />)}
      </ChatMessageProvider>
    </>
  );
}
