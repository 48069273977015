import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import type { HospitalNotice } from '@apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from '@shared/hooks/use-selected-hospital-info';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { CreateUpdateNoticeParamType } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Notice/types/create-update-notice-param-type';
import { fullDimmedLoadingService } from 'afterdoc-design-system/components/Atoms/Loading/FullDimmedLoading/FullDimmedLoading.service';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { modalService } from 'afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { SHARED_UTILS } from 'utils/utils';

type DetailNoticeParam = Parameters<typeof apiClient.v3.apiNoticeHospitalElFindDetail>[0];

type UpdateNoticeParam = Parameters<typeof apiClient.v3.apiNoticeHospitalElUpdate>[0];

type CreateNoticeParam = Parameters<typeof apiClient.v3.apiNoticeHospitalElCreate>[0];

type RemoveNoticeParam = Parameters<typeof apiClient.v3.apiNoticeHospitalElRemove>[0];

const fetchHospitalNoticeDetail = async (params: DetailNoticeParam) => {
  const { data } = await apiClient.v3.apiNoticeHospitalElFindDetail(params);
  return SHARED_UTILS.api.checkApiResponse(data);
};

const createHospitalNotice = async (params: CreateNoticeParam) => {
  const { data } = await apiClient.v3.apiNoticeHospitalElCreate(params);
  return SHARED_UTILS.api.checkApiResponse(data);
};

const updateHospitalNotice = async (params: UpdateNoticeParam) => {
  const { data } = await apiClient.v3.apiNoticeHospitalElUpdate(params);
  return SHARED_UTILS.api.checkApiResponse(data);
};

const removeHospitalNotice = async (params: RemoveNoticeParam) => {
  const { data } = await apiClient.v3.apiNoticeHospitalElRemove(params);
  return SHARED_UTILS.api.checkApiResponse(data);
};

export const useHospitalNotice = () => {
  const queryClient = useQueryClient();
  const { hospitalID } = useSelectedHospitalInfo();

  // 공자사항 상세
  const detailNoticeMutation = useMutation({
    mutationFn: (params: DetailNoticeParam) => fetchHospitalNoticeDetail(params),
    onMutate: () => fullDimmedLoadingService.on(),
    onSettled: () => fullDimmedLoadingService.off(),
    onError: (error: Error) => {
      console.error('Error fetching hospital notice details', error);
    },
  });

  const detailNoticeAction = (id: string, onSuccessCallback?: (data: HospitalNotice) => void) => {
    detailNoticeMutation.mutate({ _id: id }, { onSuccess: (data) => onSuccessCallback?.(data) });
  };

  // 공지사항 생성
  const createNoticeMutation = useMutation({
    mutationFn: (params: CreateUpdateNoticeParamType) => {
      const { _id, ...restParams } = params;
      return createHospitalNotice({ ...restParams, hospitalID });
    },
    onMutate: () => fullDimmedLoadingService.on(),
    onSettled: () => fullDimmedLoadingService.off(),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.apiNoticeHospitalElCount] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.apiNoticeHospitalElFindSimple] });
      toastService.successMsg({ text: '공지사항을 등록했습니다.' });
    },
    onError: (error: Error) => {
      console.error('Error creating hospital notice', error);
      toastService.errorMsg({ text: '공지사항 등록을 실패했습니다.' });
    },
  });

  const createNoticeAction = (
    params: CreateUpdateNoticeParamType,
    onSuccessCallback?: () => void,
  ) => {
    createNoticeMutation.mutate(params, {
      onSuccess: () => onSuccessCallback?.(),
    });
  };

  const updateNoticeMutation = useMutation({
    mutationFn: ({ _id, hospitalID, title, content }: CreateUpdateNoticeParamType) => {
      return updateHospitalNotice({ _id, hospitalID, title, content });
    },
    onMutate: () => fullDimmedLoadingService.on(),
    onSettled: () => fullDimmedLoadingService.off(),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.apiNoticeHospitalElFindSimple] });
      toastService.successMsg({ text: '공지사항을 수정했습니다.' });
    },
    onError: (error: Error) => {
      console.error('Error creating hospital notice', error);
      toastService.errorMsg({ text: '공지사항 수정을 실패했습니다.' });
    },
  });

  const updateNoticeAction = (
    params: CreateUpdateNoticeParamType,
    onSuccessCallback?: () => void,
  ) => {
    updateNoticeMutation.mutate(params, {
      onSuccess: () => onSuccessCallback?.(),
    });
  };

  // 공지사항 삭제
  const removeNoticeMutation = useMutation({
    mutationFn: (params: RemoveNoticeParam) => removeHospitalNotice(params),
    onMutate: () => fullDimmedLoadingService.on(),
    onSettled: () => fullDimmedLoadingService.off(),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.apiNoticeHospitalElFindSimple] });
      modalService.pop();
      toastService.successMsg({ text: '공지사항을 삭제했습니다.' });
    },
    onError: (error: Error) => {
      console.error('Error removing hospital notice', error);
      modalService.pop();
      toastService.errorMsg({ text: '공지사항 삭제를 실패했습니다.' });
    },
  });

  const removeNoticeAction = (id: string, onSuccessCallback?: () => void) => {
    removeNoticeMutation.mutate({ _id: id }, { onSuccess: () => onSuccessCallback?.() });
  };

  return { detailNoticeAction, createNoticeAction, updateNoticeAction, removeNoticeAction };
};
