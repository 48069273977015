import LabelText from '@afterdoc-design-system/components/Atoms/LabelText/LabelText';
import TextFieldSelectBoxDropdown from '@afterdoc-design-system/components/Molecules/TextSelect/TextFieldSelectBoxDropdown';
import { useAtomValue } from 'jotai';
import { useCallback, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import type { CounselAutomationAPIFormValues } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/components/RegisterCounselAutomationDialogContent';
import { selectedMessageState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/message-form';
import { generateDaysArray } from 'web/templates/Automation/containers/shared/functions/generate-days-array';
import { generateTimeIntervalArray } from 'web/templates/Automation/containers/shared/functions/generate-time-interval-array';

export default function SendDateForm() {
  const selectedMessage = useAtomValue(selectedMessageState);

  if (!selectedMessage) return null;

  return (
    <div>
      <div className='mt-20 flex w-full items-center justify-between'>
        <LabelText width='fit-content' isRequired>
          발송시점
        </LabelText>
        <span className='text-Body10 text-black200'>선택한 일시에 메시지가 발송됩니다.</span>
      </div>
      <div className='mt-4 flex items-center gap-8'>
        <div className='text-Body13 text-black500'>태그 입력일로부터</div>
        <DayAfterDropdown />
        <TimeOfDayDropdown />
        <div className='text-Body13 text-black500'>에 발송합니다.</div>
      </div>
    </div>
  );
}

interface DropdownProps {
  options: string[];
  fieldName: 'daysAfter' | 'timeOfDay';
  width: number;
}

const CustomDropdown = ({ options, fieldName, width }: DropdownProps) => {
  const { setValue, getValues } = useFormContext<CounselAutomationAPIFormValues>();
  const selectedMessage = useAtomValue(selectedMessageState);

  const messageLists = getValues('messages');

  const customScrollHandler = useCallback((index: number) => index - 2, []);

  const onSelect = useCallback(
    (index: number) => {
      if (messageLists && selectedMessage) {
        setValue(
          `messages.${selectedMessage.index}.${fieldName}`,
          fieldName === 'daysAfter' ? index + 1 : options[index],
        );
      }
    },
    [fieldName, messageLists, options, selectedMessage, setValue],
  );

  const getIndex = useCallback(() => {
    if (!selectedMessage) return 0;
    const value = messageLists[selectedMessage.index]?.[fieldName];
    return fieldName === 'daysAfter' ? (value as number) - 1 : options.indexOf(value as string);
  }, [fieldName, messageLists, options, selectedMessage]);

  if (!messageLists.length) return null;

  return (
    <TextFieldSelectBoxDropdown
      options={options}
      onSelect={onSelect}
      selectedIndex={getIndex()}
      focusedIndex={getIndex()}
      width={width}
      customFocusScrollHandler={customScrollHandler}
      customSelectedScrollHandler={customScrollHandler}
    />
  );
};

const DayAfterDropdown = () => {
  const options = useRef(generateDaysArray({ endDay: 365, addUnit: '일차' }));
  return <CustomDropdown options={options.current} fieldName='daysAfter' width={100} />;
};

const TimeOfDayDropdown = () => {
  const options = useRef(generateTimeIntervalArray());
  return <CustomDropdown options={options.current} fieldName='timeOfDay' width={100} />;
};
