import { SHARED_UTILS } from '@shared-utils/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { ApiManualsElFoldersData } from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';

export const createNewFolder = async (
  params: Parameters<typeof apiClient.v3.apiManualsElFoldersCreate>[0],
) => {
  const response = await apiClient.v3.apiManualsElFoldersCreate(params);
  return response.data;
};

export const updateFolders = async (
  params: Parameters<typeof apiClient.v3.apiManualsElFoldersUpdate>[0],
) => {
  const response = await apiClient.v3.apiManualsElFoldersUpdate(params);
  return response.data;
};

export const postFolderDelete = async (
  params: Parameters<typeof apiClient.v3.apiManualsElFoldersDelete>[0],
) => {
  const response = await apiClient.v3.apiManualsElFoldersDelete(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export const useMutateFolders = () => {
  const { hospitalID } = useSelectedHospitalInfo();
  const queryClient = useQueryClient();

  const createNewFolderMutation = useMutation({
    mutationFn: createNewFolder,
  });

  const updateFoldersMutation = useMutation({
    mutationFn: updateFolders,
    onSuccess: (response, variables) => {
      if (response.code === 0) {
        queryClient.setQueryData(
          [QUERY_KEY.apiManualsElFolders, { hospitalID }],
          (oldData: ApiManualsElFoldersData['data']) => {
            if (!oldData) return;

            const updatedFolders = oldData.foldersHasTags?.map((folder) => {
              if (folder._id === variables.folderId) {
                return { ...folder, ...variables };
              }
              return folder;
            });

            const sortedFolders = updatedFolders?.sort((a, b) => b.sortNum - a.sortNum);

            const updatedData = {
              ...oldData,
              foldersHasTags: sortedFolders,
            };

            return updatedData;
          },
        );
      }
    },
    onError: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.apiManualsElFolders, { hospitalID }],
      });
    },
  });

  return {
    createNewFolderMutation,
    updateFoldersMutation,
  };
};
