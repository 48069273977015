import { fullDimmedLoadingService } from '@afterdoc-design-system/components/Atoms/Loading/FullDimmedLoading/FullDimmedLoading.service';
import { toastService } from '@afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { SHARED_UTILS } from '@shared-utils/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useFormContext } from 'react-hook-form';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type {
  ApiPatientsElTemporaryListOrSearchParams,
  PatientSavingRequest,
} from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import type { CustomerManagementTemporaryAPIFormValues } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/types/table';
import { createInitialRows } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/utils/create-initial-rows';
import { useResetTable } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/hooks/use-reset-table';

const fetchPatientsTemporaryList = async ({
  pageParam = undefined,
  ...params
}: {
  pageParam?: string;
} & ApiPatientsElTemporaryListOrSearchParams) => {
  const response = await apiClient.v3.apiPatientsElTemporaryListOrSearch({
    cursor: pageParam,
    ...params,
  });
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const postPatientsInfo = async (params: PatientSavingRequest) => {
  const response = await apiClient.v3.apiPatientsEl(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const TOTAL_ROW_COUNT = 50;

export const useSaveUser = () => {
  const queryClient = useQueryClient();
  const { setValue } = useFormContext<{ rows: CustomerManagementTemporaryAPIFormValues }>();
  const { hospitalID } = useSelectedHospitalInfo();

  const { resetTable } = useResetTable();

  const postSaveFinalUserInfos = useMutation({
    mutationFn: postPatientsInfo,
    onMutate: () => {
      fullDimmedLoadingService.on();
    },
    onSuccess: async (data) => {
      // 테이블 초기화
      resetTable();
      // 쿼리 무효화
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.apiPatientsElTemporaryListOrSearch],
          refetchType: 'all',
        }),
        queryClient.invalidateQueries({ queryKey: [QUERY_KEY.apiPatientsElListOrSearch] }),
        queryClient.invalidateQueries({ queryKey: [QUERY_KEY.apiPatientsElCount, { hospitalID }] }),
        queryClient.invalidateQueries({ queryKey: [QUERY_KEY.apiPatientsElDetail] }),
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.apiPatientsElListOrSearchForUntagged],
        }),
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.apiPatientsElUntaggedCount, { hospitalID }],
        }),
      ]);

      // 새로운 데이터 fetch
      const newData = await queryClient.fetchQuery({
        queryKey: [QUERY_KEY.apiPatientsElTemporaryListOrSearch, { hospitalID }],
        queryFn: () => fetchPatientsTemporaryList({ hospitalID }),
      });

      // 새로운 rows 설정
      if (!newData?.patients || newData.patients.length === 0) {
        setValue('rows', createInitialRows(TOTAL_ROW_COUNT, 0, hospitalID), {
          shouldDirty: false,
          shouldTouch: false,
        });
      } else {
        const allPatients = newData.patients?.map((patient) => ({
          ...patient,
          treatmentTagIds: patient.treatmentTags?.map((tag) => tag.tagId),
          nationalityId: patient.nationality?.id,
          hospitalID,
        })) as CustomerManagementTemporaryAPIFormValues;

        const totalCount = newData.totalCount;

        if (totalCount > 0) {
          const rowsToFill =
            Math.ceil(totalCount / TOTAL_ROW_COUNT) * TOTAL_ROW_COUNT - allPatients.length;

          setValue(
            'rows',
            [
              ...allPatients,
              ...createInitialRows(
                rowsToFill,
                allPatients[allPatients.length - 1]?.order ?? 0,
                hospitalID,
              ),
            ],
            {
              shouldDirty: false,
              shouldTouch: false,
            },
          );
        } else {
          setValue('rows', createInitialRows(TOTAL_ROW_COUNT, 0, hospitalID), {
            shouldDirty: false,
            shouldTouch: false,
          });
        }
      }

      if (!data.failCount && data.successCount > 0) {
        toastService.successMsg({ text: '고객 정보를 업데이트 했습니다.' });
      }

      if (data.failCount > 0) {
        toastService.errorMsg({
          text: `${data.failCount}명의 고객정보 업데이트를 실패했습니다. 입력된 정보를 다시 확인해주세요`,
        });
      }
    },
    onError: (error) => {
      toastService.errorMsg({
        text: '일시적인 오류가 발생하여 업데이트에 실패하였습니다. 다시 시도해 주세요.',
      });
      console.error('error', error);
    },
    onSettled: () => {
      fullDimmedLoadingService.off();
    },
  });

  const handleSaveFinalUserInfo = () => {
    postSaveFinalUserInfos.mutate({
      hospitalID,
    });
  };

  return {
    handleSaveFinalUserInfo,
  };
};
