import NoData from 'afterdoc-design-system/components/Molecules/NoData/NoData';

export const NoHTMLData = () => {
  return (
    <>
      <NoData
        title='표시할 내용이 없습니다.'
        titleClassName='text-Header12'
        subTitle='[원내매뉴얼]에서 표시할 내용을 설정할 수 있습니다.'
        subTitleClassName='text-Body12'
        className='h-[224px] flex-center'
        iconProps={{
          name: 'warning',
          size: 48,
          color: 'white600',
        }}
      />
    </>
  );
};
