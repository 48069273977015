import LabelText from '@afterdoc-design-system/components/Atoms/LabelText/LabelText';
import TextFieldSelectBoxDropdown from '@afterdoc-design-system/components/Molecules/TextSelect/TextFieldSelectBoxDropdown';
import dayjs from 'dayjs';
import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import type { MarketingAutomationAPIFormValues } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/components/RegisterMarketingAutomationDialogContent';
import {
  selectedMessageIndexState,
  selectedMessageState,
} from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/states/message-form';
import AutomationDaySelector from 'web/templates/Automation/containers/shared/components/AutomationDaySelector';
import { generateTimeIntervalArray } from 'web/templates/Automation/containers/shared/functions/generate-time-interval-array';

export default function SendDateTimeForm() {
  const { watch, setValue } = useFormContext<MarketingAutomationAPIFormValues>();

  const selectedMessageIndex = useAtomValue(selectedMessageIndexState);
  const setSelectedMessage = useSetAtom(selectedMessageState);

  const [hour, setHour] = useState<number | undefined>();
  const [minute, setMinute] = useState<number | undefined>();

  const messageLists = watch('messages');
  const sendingDateTime = watch(`messages.${selectedMessageIndex}.sendingDateTime`);
  const disableBeforeDate = useMemo(() => {
    const now = dayjs();
    const today8PM = now.hour(20).minute(0).second(0);

    // 현재 시각이 오후 8시를 넘었다면 내일부터, 아니면 오늘부터
    return now.isAfter(today8PM)
      ? now.add(1, 'day').hour(8).minute(0).second(0).toDate()
      : now.hour(8).minute(0).second(0).toDate();
  }, []);

  return (
    <div className='mt-20'>
      <LabelText width='fit-content' isRequired>
        발송시점
      </LabelText>
      <div className='mt-10 flex items-center gap-8'>
        <AutomationDaySelector
          onSelect={(date) => {
            setValue(
              `messages.${selectedMessageIndex}.sendingDateTime`,
              dayjs(date)
                .set('hour', hour ?? 8)
                .set('minute', minute ?? 0)
                .toISOString(),
            );

            const newMessageLists = messageLists.map((message, index) => {
              return {
                ...message,
                sendingDateTime: dayjs(date)
                  .add(index, 'day')
                  .set('hour', hour ?? 8)
                  .set('minute', minute ?? 0)
                  .toISOString(),
              };
            });

            setValue('messages', newMessageLists);
            setSelectedMessage({
              index: 0,
              ...newMessageLists[0],
            });

            setValue(
              'sendingSchedule.startDay',
              dayjs(date).hour(8).minute(0).second(0).millisecond(0).toISOString(),
            );
          }}
          selectedDate={dayjs(sendingDateTime).toDate()}
          hasDayChangeButton={false}
          width={124}
          disableBeforeDate={disableBeforeDate}
        />
        <TimeOfDayDropdown setHour={setHour} setMinute={setMinute} />
        <span className='text-Body13 text-black500'>에 발송합니다.</span>
      </div>
    </div>
  );
}

interface CustomDropdownProps {
  options: string[];
  width: number;
  setHour: (hour: number) => void;
  setMinute: (minute: number) => void;
}

const CustomDropdown = ({ options, width, setHour, setMinute }: CustomDropdownProps) => {
  const { setValue, getValues } = useFormContext<MarketingAutomationAPIFormValues>();
  const selectedMessage = useAtomValue(selectedMessageState);
  const selectedMessageIndex = useAtomValue(selectedMessageIndexState);

  const messageLists = getValues('messages');
  const sendingDateTime = getValues(`messages.${selectedMessageIndex}.sendingDateTime`);

  const customScrollHandler = useCallback((index: number) => index - 2, []);

  const onSelect = useCallback(
    (index: number) => {
      if (messageLists && selectedMessage) {
        const hour = Number(options[index].split(':')[0]);
        const minute = Number(options[index].split(':')[1]);

        setValue(
          `messages.${selectedMessageIndex}.sendingDateTime`,
          dayjs(sendingDateTime).set('hour', hour).set('minute', minute).toISOString(),
        );

        setHour(hour);
        setMinute(minute);
      }
    },
    [
      messageLists,
      options,
      selectedMessage,
      selectedMessageIndex,
      setValue,
      sendingDateTime,
      setHour,
      setMinute,
    ],
  );

  const getIndex = useCallback(() => {
    if (!selectedMessage) return 0;
    const value = messageLists[selectedMessageIndex]?.sendingDateTime;
    const HHmm = dayjs(value).format('HH:mm');
    return options.indexOf(HHmm) !== -1 ? options.indexOf(HHmm) : 0;
  }, [messageLists, options, selectedMessage, selectedMessageIndex]);

  if (!messageLists.length) return null;

  return (
    <TextFieldSelectBoxDropdown
      options={options}
      onSelect={onSelect}
      selectedIndex={getIndex()}
      focusedIndex={getIndex()}
      width={width}
      customFocusScrollHandler={customScrollHandler}
      customSelectedScrollHandler={customScrollHandler}
    />
  );
};

const TimeOfDayDropdown = ({
  setHour,
  setMinute,
}: { setHour: (hour: number) => void; setMinute: (minute: number) => void }) => {
  const options = useRef(generateTimeIntervalArray({ startMinute: 0 }));
  return (
    <CustomDropdown options={options.current} width={100} setHour={setHour} setMinute={setMinute} />
  );
};
