import ContentManagerContainer from '@templates/Content/containers/ContentManager/ContentManager.container';
import { contentEditState } from '@templates/Content/containers/ContentManager/states/content-edit-state';
import ContentPanelContainer from '@templates/Content/containers/ContentPanel/ContentPanel.container';
import { focusedContentSubLayerState } from '@templates/Content/containers/ContentPanel/states/focused-content-sub-layer-state';
import { useResetAtom } from 'jotai/utils';
import { useEffect } from 'react';

export default function ContentContainer() {
  const resetFocusedContentSubLayer = useResetAtom(focusedContentSubLayerState);
  const resetContentEdit = useResetAtom(contentEditState);

  useEffect(() => {
    return () => {
      resetFocusedContentSubLayer();
      resetContentEdit();
    };
  }, []);

  return (
    <main className='grid h-full grid-cols-[300px,1fr]'>
      <ContentPanelContainer />
      <ContentManagerContainer />
    </main>
  );
}
