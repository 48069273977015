import { SHARED_UTILS } from '@shared-utils/utils';
import { createUserPersistedAtom } from 'utils/jotai/create-user-persisted-atom';
import type { Hospital, User } from 'web/apis/swaggers/swagger-docs';

// Local Storage에 저장되는 token을 위한 Atom
export const tokenAtom = SHARED_UTILS.jotai.createPersistedAtom<string>('token', '');
// Local Storage에 저장되는 유저 타입을 위한 Atom
export const userTypeAtom = SHARED_UTILS.jotai.createPersistedAtom<number>('userType', 0);
// Local Storage에 저장되는 hospitalID를 위한 Atom
export const selectedHospitalIDAtom = SHARED_UTILS.jotai.createPersistedAtom<string>(
  'selectedHospitalID',
  '',
);

export const userIDAtom = SHARED_UTILS.jotai.createPersistedAtom<string>('userID', '');
export const userInfoAtom = SHARED_UTILS.jotai.createPersistedAtom<User>('userInfo', {});

export const hospitalInfosAtom = SHARED_UTILS.jotai.createPersistedAtom<Hospital[]>(
  'hospitalInfos',
  [],
);

export const selectedNotificationAlarmAtom = (userId: string) =>
  createUserPersistedAtom(userId, 'userInfo', {
    usingSound: false,
    soundID: '',
    path: '',
    volume: 0,
  });
