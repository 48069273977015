import { replaceTemplateVariables } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/components/PhoneView/functions/replace-template-variables';

interface DisplayTextProps {
  text?: string;
}

export default function DisplayText({ text }: DisplayTextProps) {
  return (
    <span className='whitespace-pre-wrap break-all text-Body12 text-black700'>
      {replaceTemplateVariables(text) || '내용을 입력해 주세요.'}
    </span>
  );
}
