const isUsingLocalServer = import.meta.env.VITE_USE_LOCAL_SERVER === 'true';

export const API_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://api.afterdoc.net'
    : isUsingLocalServer
      ? 'http://localhost:8080'
      : 'https://dev.api.afterdoc.net';

export const NEXT_PUBLIC_API_URL = 'https://4979-210-223-83-166.ngrok-free.app';
