import { useSetAtom } from 'jotai';
import { useEffect } from 'react';
import type { LanguageNameAndAlpha2 } from 'web/apis/swaggers/swagger-docs';
import { useLanguageList } from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingSending/components/ChattingSendingBottom/hooks/use-language-list';
import { translateLanguageState } from 'web/templates/CustomerChat/states/translate-language';

export const useLanguageCode = (languageID?: LanguageNameAndAlpha2) => {
  const { data: languageList } = useLanguageList();

  const setTranslateLanguage = useSetAtom(translateLanguageState);

  const languageCodeISOAlpha2 = languageList.find(
    (item) => item?.languageCodeISOAlpha2 === languageID?.languageCodeISOAlpha2,
  )?.languageCodeISOAlpha2;

  useEffect(() => {
    if (languageCodeISOAlpha2) {
      setTranslateLanguage(languageCodeISOAlpha2);
    }
  }, [languageCodeISOAlpha2, setTranslateLanguage]);

  return {
    languageCodeISOAlpha2,
  };
};
