import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isUpdatingState } from '@templates/ServiceNoticeDialog/states/is-updating-state';
import type { ServiceNoticeTabs } from '@templates/ServiceNoticeDialog/types/service-notice-tabs';
import { useAtom } from 'jotai';
import { SHARED_UTILS } from 'utils/utils';

type UpdateReadStateParam = Parameters<typeof apiClient.v3.apiNoticeServicenoticeElUpdateRead>[0];

type UpdateReadAllStateParam = Parameters<
  typeof apiClient.v3.apiNoticeServicenoticeElUpdateReadAll
>[0];

interface UpdateCallbackAction {
  onSettledCallback?: (isSuccess: boolean) => void;
  onFailureCallback?: () => void;
}

interface UpdateAllCallbackAction {
  onSuccessCallback?: () => void;
}

const updateServiceNoticeReadStatus = async (params: UpdateReadStateParam) => {
  const { data } = await apiClient.v3.apiNoticeServicenoticeElUpdateRead(params);
  return SHARED_UTILS.api.checkApiResponse(data);
};

const updateServiceNoticeReadAllStatus = async (params: UpdateReadAllStateParam) => {
  const { data } = await apiClient.v3.apiNoticeServicenoticeElUpdateReadAll(params);
  return SHARED_UTILS.api.checkApiResponse(data);
};

export const useServiceListReadStatus = () => {
  const queryClient = useQueryClient();
  const [isUpdating, setIsUpdating] = useAtom(isUpdatingState);

  const updateMutation = useMutation({
    mutationFn: ({ _id }: UpdateReadStateParam) => updateServiceNoticeReadStatus({ _id }),
    onMutate: () => setIsUpdating(true),
  });

  const updateMutationAction = (id: string, callback: UpdateCallbackAction) => {
    updateMutation.mutate(
      { _id: id },
      {
        onSettled: (data) => {
          setIsUpdating(false);
          const isSuccess = data !== undefined;
          if (isSuccess) {
            queryClient.invalidateQueries({
              queryKey: [QUERY_KEY.apiNoticeServicenoticeElCount],
            });
            callback.onSettledCallback?.(true);
          }
        },
      },
    );
  };

  const updateAllMutation = useMutation({
    mutationFn: (param: UpdateReadAllStateParam) => updateServiceNoticeReadAllStatus(param),
    onMutate: () => setIsUpdating(true),
    onSettled: () => setIsUpdating(false),
  });

  const updateAllMutationAction = (
    userID: string,
    activeTab: ServiceNoticeTabs['key'],
    callback: UpdateAllCallbackAction,
  ) => {
    const category = activeTab === 'All' ? undefined : activeTab;

    updateAllMutation.mutate(
      { userID },
      {
        onSuccess: () => {
          callback.onSuccessCallback?.();
        },
      },
    );
  };

  return {
    isUpdating,
    updateMutationAction,
    updateAllMutationAction,
  };
};
