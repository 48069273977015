import AccountListTableBody from '@templates/HospitalSetting/containers/AllAccountSetting/containers/AllAccountList/components/AccountListTable/components/AccountListTableBody';
import AccountListTableHead from '@templates/HospitalSetting/containers/AllAccountSetting/containers/AllAccountList/components/AccountListTable/components/AccountListTableHead';

export default function AccountListTable() {
  return (
    <>
      <div className='mx-80 mt-20 px-20'>
        <table className='w-full table-fixed'>
          <AccountListTableHead />
          <AccountListTableBody />
        </table>
      </div>
    </>
  );
}
