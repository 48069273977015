import type { NoticeEditError } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Notice/types/notice-edit-error-type';
import { atom } from 'jotai/index';
import { atomWithReset } from 'jotai/utils';

export const noticeEditErrorState = atomWithReset<NoticeEditError>({
  title: null,
  content: null,
  pushTarget: null,
});

const createErrorAtom = (key: keyof NoticeEditError) =>
  atom(
    (get) => get(noticeEditErrorState)[key],
    (get, set, value: string | null) => {
      const currentState = get(noticeEditErrorState);
      set(noticeEditErrorState, {
        ...currentState,
        [key]: value || null,
      });
    },
  );

export const selectedNoticeEditTitleErrorAtom = createErrorAtom('title');
export const selectedNoticeEditContentErrorAtom = createErrorAtom('content');
export const selectedNoticeEditTargetErrorAtom = createErrorAtom('pushTarget');
