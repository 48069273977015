import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';
import type { ErrorMessage } from 'react-use-downloader/dist/types';

// 파일 진행 상태와 완료 플래그를 포함하는 객체의 타입 정의
type FileProgress = {
  fileName: string;
  progress: number;
  error?: ErrorMessage | null;
  triggerCancel?: boolean;
  triggerReload?: boolean;
  filePath?: string | null;
  isDeleted?: boolean; // 논리 삭제 여부 추가
};
type FileDownloadHistoryForIconView = {
  isComplete: boolean;
};
type FileDownloadHistoryForIconViewMap = Map<string, FileDownloadHistoryForIconView>;
type FileProgressMap = Map<string, FileProgress>;

// Map 자료구조를 초기값으로 가지는 atom 생성
export const chatFileProgressAtom = atomWithReset<FileProgressMap>(new Map());
export const chatFileDownloadHistoryAtom = atomWithReset<FileDownloadHistoryForIconViewMap>(
  new Map(),
);

export const setFileProgressAtom = atom(
  null, // 읽기 전용이므로 null
  (
    get,
    set,
    {
      id,
      fileName,
      filePath = null,
      progress = 0,
      error = null,
      triggerCancel = false,
      triggerReload = false,
    }: {
      id: string;
      fileName: string;
      filePath?: string | null;
      progress?: number;
      error?: ErrorMessage | null;
      triggerCancel?: boolean;
      triggerReload?: boolean;
    },
  ) => {
    const currentMap = new Map(get(chatFileProgressAtom)); // 기존 Map 복사
    const currentHistoryForIconViewMap = new Map(get(chatFileDownloadHistoryAtom)); // 기존 Map 복사

    const fileProgress = currentMap.get(id) || {
      fileName,
      progress: 0,
      error: null,
      triggerCancel: false,
      triggerReload: false,
      filePath: null,
    }; // 파일 진행 상태 가져오기
    const fileDownloadHistoryForIconView = currentMap.get(id) || {
      isComplete: false,
    }; // 파일 진행 상태 가져오기

    // 진행 상태 업데이트
    const updatedProgress: FileProgress = {
      ...fileProgress,
      progress,
      error,
      triggerCancel,
      triggerReload,
      filePath: filePath === null ? fileProgress.filePath : filePath,
    };
    const updatedFileDownloadHistoryForIconView: FileDownloadHistoryForIconView = {
      ...fileDownloadHistoryForIconView,
      isComplete: progress >= 100, // 진행 상태가 100%이면 isComplete 플래그를 true로 설정
    };

    currentMap.set(id, updatedProgress); // Map에 업데이트된 진행 상태와 완료 플래그 설정
    currentHistoryForIconViewMap.set(id, updatedFileDownloadHistoryForIconView); // Map에 업데이트된 진행 상태와 완료 플래그 설정

    set(chatFileProgressAtom, currentMap); // atom 업데이트
    set(chatFileDownloadHistoryAtom, currentHistoryForIconViewMap); // atom 업데이트
  },
);

// deleteFileProgressAtom은 map에서 fileName 키 오브젝트를 삭제하는 atom, history랑 상관 없음
export const deleteFileProgressAtom = atom(null, (get, set, id: string) => {
  const currentMap = new Map(get(chatFileProgressAtom)); // 기존 Map 복사
  currentMap.delete(id); // fileName에 해당하는 진행 상태 삭제
  set(chatFileProgressAtom, currentMap); // atom 업데이트
});
