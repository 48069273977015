import {
  eventPushEndDateAtom,
  eventPushStartDateAtom,
} from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/components/EventList/components/EventListBody/components/EventPushAlarmDialog/EventPushSelector/states/event-push-request-create-param-state';
import DayPicker from 'afterdoc-design-system/components/Atoms/Calendar/DayPicker';
import TextInput from 'afterdoc-design-system/components/Atoms/Input/TextInput';
import dayjs from 'dayjs';
import { useSetAtom } from 'jotai';
import { type ChangeEvent, useEffect, useRef, useState } from 'react';

interface SignupDatePickerProps {
  isDisabled: boolean;
  type: 'start' | 'end';
}

const formatDateWithHyphens = (dateStr: string) => {
  const cleaned = dateStr.replace(/[^0-9]/g, '');

  const year = cleaned.slice(0, 4);
  const month = cleaned.slice(4, 6);
  const day = cleaned.slice(6, 8);

  if (cleaned.length <= 4) return year;
  if (cleaned.length <= 6) return `${year}-${month}`;

  return `${year}-${month}-${day}`;
};

const isValidDate = (year: number, month: number, day: number) => {
  if (year < 1900 || year > 2500) return false;
  if (month < 1 || month > 12) return false;
  const daysInMonth = new Date(year, month, 0).getDate();
  return !(day < 1 || day > daysInMonth);
};

export default function SignupDatePicker({ isDisabled, type }: SignupDatePickerProps) {
  const [inputValue, setInputValue] = useState('');
  const [isToggle, setIsToggle] = useState(false);
  const [isValid, setIsValid] = useState(true);

  const [selectedDate, setSelectedDate] = useState<Date>();

  const inputRef = useRef<HTMLInputElement>(null);

  const setTargetDate = useSetAtom(
    type === 'start' ? eventPushStartDateAtom : eventPushEndDateAtom,
  );

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!isToggle) {
      setIsToggle(true);
    }
    const input = e.target.value;
    if (/[^0-9-]/.test(input)) return;

    const formattedInput = formatDateWithHyphens(input);
    setInputValue(formattedInput);

    if (formattedInput.length === 10) {
      const [year, month, day] = formattedInput.split('-').map(Number);
      if (isValidDate(year, month, day)) {
        setIsValid(true);
        setSelectedDate(new Date(year, month - 1, day));
        return;
      }
    }
    setIsValid(false);
    setTargetDate(formattedInput);
  };

  const handleDayPickerSelect = (date?: Date) => {
    if (!date) return;
    setIsValid(true);
    setSelectedDate(date);
    setInputValue(dayjs(date).format('YYYY-MM-DD'));
    setIsToggle(false);
  };

  useEffect(() => {
    if (selectedDate) {
      setTargetDate(dayjs(selectedDate).format('YYYY-MM-DD'));
    }
  }, [selectedDate]);

  useEffect(() => {
    if (!isToggle) {
      setTimeout(() => {
        inputRef.current?.blur();
      }, 0);
    }
  }, [isToggle]);

  return (
    <div className='relative'>
      <TextInput
        ref={inputRef}
        value={inputValue}
        onChange={handleInputChange}
        onClick={() => setIsToggle(!isToggle)}
        maxLength={10}
        placeholder='YYYY-MM-DD'
        disabled={isDisabled}
      />
      {isToggle && (
        <DayPicker
          hasError={!isValid}
          className='absolute z-[10000]'
          onChangeDate={handleDayPickerSelect}
          selectedDate={selectedDate}
          handleToggle={() => setIsToggle(false)}
          yearRange={[1900, 2500]}
          onEscKeyDown={() => setIsToggle(false)}
          styles={{
            table: {
              padding: 10,
            },
          }}
        />
      )}
    </div>
  );
}
