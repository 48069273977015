export const OVERLAY_PAGE_ID = {
  // ################ 고객관리 ################
  'customer-detail-info': 'customer-detail-info',

  // ################ 자동화 ################
  // 상담자동화 등록
  'register-counsel-automation-dialog': 'register-counsel-automation-dialog',
  // 마케팅자동화 등록
  'register-marketing-automation-dialog': 'register-marketing-automation-dialog',
  // 공통 알고리즘 수정
  'edit-common-algorithm-dialog': 'edit-common-algorithm-dialog',
  // 상담자동화 줌인아웃 뷰어
  'counsel-automation-zoom-in-out-viewer': 'counsel-automation-zoom-in-out-viewer',
  // 마케팅자동화 줌인아웃 뷰어
  'marketing-automation-zoom-in-out-viewer': 'marketing-automation-zoom-in-out-viewer',
};
