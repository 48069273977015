import IntegrationConsultDatePicker from '@templates/HospitalSetting/containers/ChannelIntegration/components/IntegrationFormWrapper/KakaoConsultTalkForm/components/ConsultTalkIntegrationWrapper/components/IntegrationConsultDatePicker/IntegrationConsultDatePicker';
import ChannelIdInput from '@templates/HospitalSetting/containers/ChannelIntegration/components/IntegrationFormWrapper/common/ChannelIdInput';
import { hasLinkageOrRequestHistoryState } from '@templates/HospitalSetting/containers/ChannelIntegration/states/has-linkage-or-request-history-state';
import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import Title from 'afterdoc-design-system/components/Molecules/Title/Title';
import { useAtomValue } from 'jotai/index';

export default function ConsultTalkIntegrationWrapper() {
  const hasLinkageOrRequestHistory = useAtomValue(hasLinkageOrRequestHistoryState);

  return (
    <>
      <Title title='연동정보' wrapperClassName={'px-0 pt-0 mt-8'} />
      <div className='flex flex-col gap-12 py-8'>
        <ChannelIdInput />
        <IntegrationConsultDatePicker />
      </div>
      {hasLinkageOrRequestHistory === 'NotLinked' && (
        <Divider type={'line'} height={1} className='my-10' />
      )}
    </>
  );
}
