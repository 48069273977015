type HeaderType = {
  title: string;
  width: number;
};

export const EVENT_PUSH_LIST_HEADERS: HeaderType[] = [
  { title: '발송일시', width: 140 },
  { title: '발송대상', width: 180 },
  { title: '조건', width: 100 },
  { title: '상세조건', width: 150 },
  { title: '가입기간', width: 160 },
];
