import * as React from "react";
import type { SVGProps } from "react";
const SvgCsv = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <rect width={32} height={32} fill="#000" rx={4} />
    <path
      fill="#fff"
      d="m21.934 20.036-2.724-8.88h1.872l1.164 4.32c.276.996.48 1.872.756 2.88h.06c.288-1.008.48-1.884.744-2.88l1.152-4.32h1.812l-2.724 8.88zM15.537 20.204c-1.2 0-2.376-.456-3.24-1.284l1.02-1.224c.624.588 1.476.972 2.256.972.924 0 1.404-.396 1.404-1.008 0-.66-.54-.876-1.332-1.212l-1.188-.504c-.888-.36-1.8-1.092-1.8-2.376 0-1.452 1.284-2.568 3.072-2.568 1.032 0 2.064.408 2.784 1.14l-.888 1.116c-.576-.468-1.164-.732-1.896-.732-.78 0-1.272.348-1.272.924 0 .636.636.876 1.392 1.176l1.164.48c1.068.444 1.776 1.14 1.776 2.412 0 1.452-1.212 2.688-3.252 2.688M9.056 20.204C6.812 20.204 5 18.596 5 15.632 5 12.704 6.872 11 9.128 11c1.128 0 2.028.528 2.604 1.128l-.936 1.14c-.456-.444-.96-.744-1.644-.744-1.332 0-2.34 1.152-2.34 3.048 0 1.944.9 3.096 2.316 3.096.756 0 1.356-.36 1.824-.876l.936 1.116c-.732.852-1.68 1.296-2.832 1.296"
    />
  </svg>
);
export default SvgCsv;
