import CustomerManagementTableContainer from './containers/shared/containers/SearchFilter/CustomerManagementTable.container';
import SearchFilterContainer from './containers/shared/containers/SearchFilter/SearchFilter.container';

export default function BoardPanelContainer() {
  return (
    <div>
      {/* 고객 관리 검색 필터 */}
      <SearchFilterContainer />
      {/* 고객 관리 테이블 */}
      <CustomerManagementTableContainer />
    </div>
  );
}
