import Divider from '@afterdoc-design-system/components/Atoms/Divider/Divider';
import Scrollbar from '@afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import { Suspense } from 'react';
import LayoutBox from 'web/shared/components/LayoutBox/LayoutBox';
import BaseSettingNextButton from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselBaseSetting/components/BaseSettingNextButton';
import AlgorithmApplyTargetSetting from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselBaseSetting/components/Setting/AlgorithmApplyTargetSetting';
import CounselAlgorithmNameSetting from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselBaseSetting/components/Setting/CounselAlgorithmNameSetting';
import NationalitySetting from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselBaseSetting/components/Setting/NationalitySetting';
import TreatmentTagSetting from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselBaseSetting/components/Setting/TreatmentTagSetting';
import NationalitySettingSkeleton from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselBaseSetting/components/Skeleton/NationalitySettingSkeleton';
import TreatmentTagSettingSkeleton from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselBaseSetting/components/Skeleton/TreatmentTagSettingSkeleton';
import { useAlgorithmData } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/hooks/use-algorithm-data';
import NextButton from 'web/templates/Automation/containers/shared/components/Buttons/NextButton';

export default function CounselBaseSettingContainer() {
  useAlgorithmData();

  return (
    <div className='h-[calc(100vh-140px)] w-full'>
      <Scrollbar>
        <div className='mx-auto mt-20 w-[700px] rounded-r16 bg-white50 px-40 py-20 shadow-modal'>
          {/* 자동화명 설정 */}
          <LayoutBox noStyle>
            <CounselAlgorithmNameSetting />
          </LayoutBox>

          <Divider type='line' className='my-20' />

          {/* 상담 - 자동화 적용 대상 설정 */}
          <LayoutBox noStyle>
            <AlgorithmApplyTargetSetting />
          </LayoutBox>

          {/* 치료태그 설정 */}
          <Suspense fallback={<TreatmentTagSettingSkeleton />}>
            <TreatmentTagSetting />
          </Suspense>

          {/* 국적 설정 */}
          <Suspense fallback={<NationalitySettingSkeleton />}>
            <NationalitySetting />
          </Suspense>

          {/* 다음 버튼 */}
          <Suspense fallback={<NextButton className='mx-auto mt-20 w-[200px]' />}>
            <BaseSettingNextButton />
          </Suspense>
        </div>
      </Scrollbar>
    </div>
  );
}
