import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import type { ApiHospitalsAccountsElFindParams } from '@apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from '@shared/hooks/use-selected-hospital-info';
import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { fullDimmedLoadingService } from 'afterdoc-design-system/components/Atoms/Loading/FullDimmedLoading/FullDimmedLoading.service';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { ApiError } from 'utils/api/check-api-response';
import { SHARED_UTILS } from 'utils/utils';

export type UpdateHospitalAccountParams = Parameters<
  typeof apiClient.v3.apiHospitalsAccountsElUpdate
>[0];

export type RemoveHospitalAccountParams = Parameters<
  typeof apiClient.v3.apiHospitalsAccountsElUpdate
>[0];

const fetchAccounts = async (params: ApiHospitalsAccountsElFindParams) => {
  const response = await apiClient.v3.apiHospitalsAccountsElFind(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const updateAccount = async (params: UpdateHospitalAccountParams) => {
  const response = await apiClient.v3.apiHospitalsAccountsElUpdate(params);
  return SHARED_UTILS.api.checkApiResponse(response.data).isUpdatedSuccess;
};

const removeAccount = async (params: RemoveHospitalAccountParams) => {
  const response = await apiClient.v3.apiHospitalsAccountsElRemove(params);
  return SHARED_UTILS.api.checkApiResponse(response.data).isDeletedSucess;
};

export const useHospitalAccount = () => {
  const queryClient = useQueryClient();
  const { hospitalID } = useSelectedHospitalInfo();

  const fetchAccountsQuery = () =>
    useSuspenseQuery({
      queryKey: [QUERY_KEY.apiHospitalsAccountsElFind, { hospitalID }] as const,
      queryFn: ({ queryKey }) => fetchAccounts(queryKey[1]),
    });

  const createMutation = <TParams>(
    mutationFn: (params: TParams) => Promise<boolean | undefined>,
    successMessage: string,
    errorMessage: string,
  ) => {
    return useMutation({
      mutationFn,
      onMutate: () => fullDimmedLoadingService.on(),
      onSettled: () => fullDimmedLoadingService.off(),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEY.apiHospitalsAccountsElFind] });
        toastService.successMsg({ text: successMessage });
      },
      onError: (error) => {
        if (SHARED_UTILS.api.isApiErrorWithCode(error, 3020)) {
          toastService.errorMsg({ text: error.message });
        } else {
          toastService.errorMsg({ text: errorMessage });
        }
      },
    });
  };

  const updateAccountMutation = createMutation<UpdateHospitalAccountParams>(
    (params: UpdateHospitalAccountParams) => updateAccount(params),
    '수정되었습니다.',
    '수정을 실패했습니다. 다시 시도해 주세요.',
  );

  const removeAccountMutation = createMutation<RemoveHospitalAccountParams>(
    (params: RemoveHospitalAccountParams) => removeAccount(params),
    '삭제되었습니다.',
    '삭제를 실패했습니다.',
  );

  return {
    fetchAccountsQuery,
    updateAccountMutation,
    removeAccountMutation,
  };
};
