import Toggle from '@afterdoc-design-system/components/Atoms/Toggle/Toggle';
import { useEffect, useState } from 'react';
import type { SortedFoldersWithTags } from 'web/apis/swaggers/swagger-docs';

interface FolderRightToggleProps {
  folder: SortedFoldersWithTags;
  isActive: boolean;
  onClickUpdateFolderActivation: (isActive: boolean, folder: SortedFoldersWithTags) => void;
}

export default function FolderRightToggle({
  folder,
  isActive,
  onClickUpdateFolderActivation,
}: FolderRightToggleProps) {
  const [isActivated, setIsActivated] = useState(isActive);

  useEffect(() => {
    setIsActivated(isActive);
  }, [isActive]);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Toggle
        toggleSize='small'
        checked={isActivated}
        onChange={(e) => {
          const newIsActive = e.target.checked;
          onClickUpdateFolderActivation(newIsActive, folder);
        }}
      />
    </div>
  );
}
