import { FileService } from '@shared/utils/fileUpload/services/FileServices';
import { FileUploader } from '@shared/utils/fileUpload/services/FileUploader';
import type { FileUploadCallback } from '@shared/utils/fileUpload/types/types';

type FileUploadParams = {
  files: File[];
  userID: string;
  flags?: object[];
  callback?: FileUploadCallback;
};

const uploadFiles = async ({ files, userID, flags, callback }: FileUploadParams) => {
  try {
    const imageFiles = files.filter(FileService.isImageFile);
    const nonImageFiles = files.filter((file) => !FileService.isImageFile(file));

    const sizes = await Promise.all(imageFiles.map(FileService.getImageFileWidthHeight));

    const imageUploadPromise =
      imageFiles.length > 0
        ? FileUploader.uploadFiles({
            files: imageFiles,
            sizes,
            type: 300,
            userID,
            flags,
          })
        : null;

    const nonImageUploadPromises = nonImageFiles.map((file) =>
      FileUploader.uploadFiles({
        files: [file],
        sizes,
        type: 500,
        userID,
        flags,
      }),
    );

    const uploadResults = await Promise.all(
      [imageUploadPromise, ...nonImageUploadPromises].filter((promise) => promise !== null),
    );

    const flattenedResults = uploadResults.flat();

    callback?.({
      code: 0,
      data: flattenedResults,
      message: 'done',
    });
  } catch (error) {
    callback?.({
      code: -1,
      data: [],
      message: `Error uploading files ${error}`,
    });
  }
};

export const FileUploadController = {
  uploadFiles,
};
