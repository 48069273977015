import Divider from '@afterdoc-design-system/components/Atoms/Divider/Divider';
import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';
import type { ConsultationCompletionData } from 'web/apis/swaggers/swagger-docs';
import CardItemLayout from 'web/templates/Automation/containers/Main/shared/components/CardItemLayout';

interface ConsultationCompletionRateCardItemProps {
  consultationCompletionRate?: ConsultationCompletionData;
}

export default function ConsultationCompletionRateCardItem({
  consultationCompletionRate,
}: ConsultationCompletionRateCardItemProps) {
  if (!consultationCompletionRate) {
    return <CardItemLayout />;
  }

  const { completionRate, incompleteConsultations, newConsultations, changeFromLastMonth } =
    consultationCompletionRate;

  return (
    <CardItemLayout>
      <div>
        <div className='text-Header12 text-black500'>상담 미완료 건 수 (추후 수정)</div>
        <div className='flex items-center gap-10'>
          <div className='text-Header16 text-black700'>
            {completionRate ? `${completionRate}%` : '-%'}
          </div>
          <div className='flex items-center'>
            <span className='text-Body12 text-black200'>전달대비</span>
            <span
              className={`ml-6 text-Header12 ${changeFromLastMonth && changeFromLastMonth > 0 ? 'text-red500' : 'text-blue500'}`}>
              {changeFromLastMonth ? `${Math.abs(changeFromLastMonth)}%` : '-%'}
            </span>
            {changeFromLastMonth && changeFromLastMonth > 0 ? (
              <Icon name='arrow-up-right-thick' color='red500' size={16} />
            ) : (
              <Icon name='arrow-down-left-thick' color='blue500' size={16} />
            )}
          </div>
        </div>
      </div>
      <div className='mt-33 grid grid-cols-[1fr,1px,1fr] items-center'>
        <div className='flex flex-col items-center gap-2'>
          <div className='text-Body11 text-black200'>신규상담</div>
          <div className='text-Header14 text-black700'>
            {newConsultations ? newConsultations.toLocaleString() : '-'}건
          </div>
        </div>
        <Divider type='line' direction='vertical' height={82} />
        <div className='flex flex-col items-center gap-2'>
          <div className='text-Body11 text-black200'>미완료 상담</div>
          <div className='text-Header14 text-black700'>
            {incompleteConsultations ? incompleteConsultations.toLocaleString() : '-'}건
          </div>
        </div>
      </div>
    </CardItemLayout>
  );
}
