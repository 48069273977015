import type { SingleHospitalPushSettingHandlerResponse } from '@apis/swaggers/swagger-docs';
import dayjs from 'dayjs';

const getFormattedTime = (type: 'start' | 'end'): string => {
  const defaultHour = type === 'start' ? 9 : 20;
  return dayjs().hour(defaultHour).minute(0).second(0).millisecond(0).format();
};

export const convertNotificationSettingData = (
  data: SingleHospitalPushSettingHandlerResponse['data'],
) => {
  return {
    _id: data._id,
    onInChargeChat: data.onInChargeChat ?? true,
    hasTimeSettingForInChargeChat: data.hasTimeSettingForInChargeChat ?? false,
    inChargeChatStartedAt: data.inChargeChatStartedAt ?? getFormattedTime('start'),
    inChargeChatEndedAt: data.inChargeChatEndedAt ?? getFormattedTime('end'),
    onChatsAll: data.onChatsAll ?? true,
    onChatsDependsTime: data.onChatsDependsTime ?? false,
    chatStartedAt: data.chatStartedAt ?? getFormattedTime('start'),
    chatEndedAt: data.chatEndedAt ?? getFormattedTime('end'),
    onReservationNew: data.onReservationNew ?? true,
    onReservationChange: data.onReservationChange ?? true,
    onReservationCancel: data.onReservationCancel ?? true,
    onManagerChatsAll: data.onManagerChatsAll ?? true,
    onManagerChatsDependsTime: data.onManagerChatsDependsTime ?? false,
    managerChatStartedAt: data.managerChatStartedAt ?? getFormattedTime('start'),
    managerChatEndedAt: data.managerChatEndedAt ?? getFormattedTime('end'),
  };
};
