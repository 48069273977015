import AdminPhoneInput from '@templates/HospitalSetting/containers/ChannelIntegration/components/IntegrationFormWrapper/KakaoAlimTalkForm/components/AlimTalkIntegrationWrapper/components/AdminPhoneInput';
import AuthNumInput from '@templates/HospitalSetting/containers/ChannelIntegration/components/IntegrationFormWrapper/KakaoAlimTalkForm/components/AlimTalkIntegrationWrapper/components/AuthNumInput';
import CategorySelector from '@templates/HospitalSetting/containers/ChannelIntegration/components/IntegrationFormWrapper/KakaoAlimTalkForm/components/AlimTalkIntegrationWrapper/components/CatogorySelector/CategorySelector';
import ChannelIdInput from '@templates/HospitalSetting/containers/ChannelIntegration/components/IntegrationFormWrapper/common/ChannelIdInput';
import { hasLinkageOrRequestHistoryState } from '@templates/HospitalSetting/containers/ChannelIntegration/states/has-linkage-or-request-history-state';
import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import Title from 'afterdoc-design-system/components/Molecules/Title/Title';
import { useAtomValue } from 'jotai/index';
import { useCallback, useState } from 'react';

export default function AlimTalkIntegrationWrapper() {
  const hasLinkageOrRequestHistory = useAtomValue(hasLinkageOrRequestHistoryState);

  const [isAuthRequestDone, setIsAuthRequestDone] = useState(false);

  const handleChangeDoneStatus = useCallback((isDone: boolean) => {
    setIsAuthRequestDone(isDone);
  }, []);

  return (
    <>
      <Title title='연동정보' wrapperClassName={'px-0 pt-0 mt-8'} />
      <div className='flex flex-col gap-12 py-8'>
        <ChannelIdInput isAuthRequestDone={isAuthRequestDone} />
        <AdminPhoneInput
          isAuthRequestDone={isAuthRequestDone}
          handleChangeDoneStatus={handleChangeDoneStatus}
        />
        {isAuthRequestDone && hasLinkageOrRequestHistory === 'NotLinked' && <AuthNumInput />}
        <CategorySelector />
      </div>
      {hasLinkageOrRequestHistory === 'NotLinked' && (
        <Divider type={'line'} height={1} className='my-10' />
      )}
    </>
  );
}
