import { hospitalInfoState } from '@templates/HospitalSetting/containers/HospitalProfile/states/hospital-info-state';
import TextArea from 'afterdoc-design-system/components/Atoms/Input/TextArea/TextArea';
import LabelText from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import { useAtom } from 'jotai/index';

export default function ConsultationScheduleNote() {
  const [hospitalInfo, setHospitalInfo] = useAtom(hospitalInfoState);

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setHospitalInfo((prev) => ({
      ...prev,
      careScheduleDescription: event.target.value,
    }));
  };

  return (
    <div className='flex-1'>
      <LabelText>참고사항</LabelText>
      <div className='mt-10'>
        <TextArea
          onSave={() => {}}
          placeholder='예) 점심시간 안내: 오전 11시 ~ 오후 12시(최대 500자)'
          height={284}
          maxLength={500}
          isShowBottom={false}
          onChange={handleInputChange}
          value={hospitalInfo.careScheduleDescription ?? ''}
        />
      </div>
    </div>
  );
}
