import TextInput from 'afterdoc-design-system/components/Atoms/Input/TextInput';
import type React from 'react';
import { useFormContext } from 'react-hook-form';

export default function EditAccountPassword() {
  const {
    register,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const inputValue = watch('password') ?? '';

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearErrors('password');

    let { value } = e.target;
    value = value.replace(/[^a-zA-Z0-9]/g, '');
    setValue('password', value);
  };

  return (
    <div className='flex flex-col gap-4'>
      <TextInput
        placeholder='영문, 숫자 포함 3~25자'
        width={272}
        maxLength={25}
        label={{
          children: '비밀번호',
          width: 60,
          position: 'horizontal',
        }}
        type={'password'}
        value={inputValue}
        {...register('password', { onChange: handleChange })}
      />
      {!!errors.password && (
        <span className='ml-70 text-Body10 text-red500'>영문, 숫자 3~25자로 설정해 주세요.</span>
      )}
    </div>
  );
}
