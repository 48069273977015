import { v4 as uuidv4 } from 'uuid';
import type { ChatFileAndContentIDs } from 'web/apis/swaggers/swagger-docs';
import ChattingMessage from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/components/ChattingMessage/ChattingMessage';
import { useDateDivider } from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/hooks/use-date-divider';
import { USER_TYPE_CODE } from './components/ChattingMessage/constants/message';
import DateDivider from './components/DateDivider/DateDivider';
type ChattingContentType = ChatFileAndContentIDs & { isUnread: boolean; status: string };

type ChattingContentProps = {
  messages: Array<ChattingContentType>;
};

export default function ChattingContent({ messages }: ChattingContentProps) {
  const { shouldShowDate } = useDateDivider();

  const processMessageData = (
    chatMessage: ChattingContentType,
    messages: ChattingContentType[],
    index: number,
  ) => {
    const previousMessageTime = messages[index + 1]?.sentAt;
    const currentMessageTime = chatMessage.sentAt;
    const isLastSendMessage = messages.length === index + 1;

    const dividerLineDate = shouldShowDate({
      isLastSendMessage,
      previousMessageTime,
      currentMessageTime,
    });

    const isLeft = chatMessage.userType === USER_TYPE_CODE.PATIENT;

    return { dividerLineDate, isLeft };
  };

  return (
    <>
      {messages.map((chatMessage, index) => {
        const { dividerLineDate, isLeft } = processMessageData(chatMessage, messages, index);

        return (
          <div key={chatMessage._id ?? uuidv4()} className='flex flex-col gap-20'>
            {dividerLineDate && <DateDivider dividerLineDate={dividerLineDate} />}
            <ChattingMessage {...chatMessage} isLeft={isLeft} index={index} />
          </div>
        );
      })}
    </>
  );
}
