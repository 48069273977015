import type { HService } from 'web/apis/swaggers/swagger-docs';
import type { TempChatRoomHServiceLastMessageUser } from '../hooks/use-chatting-list';

interface SaaSHService extends HService {
  hasKakaoLinkage: boolean;
}

type parseIconSetParams = {
  chatRoom: TempChatRoomHServiceLastMessageUser;
};

//hserviceID가 필수값이지만 개발계에서 임의로 일괄 삭제 처리된 경우가 있어 ? 살인마로 방어 로직 불가피하게 생성
export const parseIconSet = ({ chatRoom }: parseIconSetParams) => {
  const { hserviceID, parentHServiceID } = chatRoom;

  const hservice = hserviceID as SaaSHService;
  const linkType = Number(hserviceID?.linkType);

  const isAfterdocService = linkType <= 500;
  const isKakaoService = linkType === 600;

  const isGrayIcon = (service: typeof hserviceID | typeof parentHServiceID) =>
    service.status !== 'run' || (service.closeReason?.length ?? 0) > 0;

  const isColorIcon = (service: typeof hserviceID | typeof parentHServiceID) =>
    service.status === 'run' && (service.closeReason?.length ?? 0) === 0;

  let isKakaoIcon = false;
  let isGrayAfterDocIcon = false;
  let isColorAfterDocIcon = false;
  let isPendingService = false;

  if (isAfterdocService) {
    isKakaoIcon = hservice?.hasKakaoLinkage ?? false;
    isGrayAfterDocIcon = isGrayIcon(hservice);
    isColorAfterDocIcon = isColorIcon(hservice);
    isPendingService = hserviceID.status === 'pending';
  } else if (isKakaoService) {
    isKakaoIcon = true;
    if (hservice._id === parentHServiceID?._id) {
      isGrayAfterDocIcon = false;
      isColorAfterDocIcon = false;
    } else {
      isGrayAfterDocIcon = isGrayIcon(parentHServiceID);
      isColorAfterDocIcon = isColorIcon(parentHServiceID);
    }
  }

  return {
    isKakaoIcon,
    isGrayAfterDocIcon,
    isColorAfterDocIcon,
    isPendingService,
  };
};
