import { popupCreateOrUpdateParamState } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/state/popup-create-or-update-param-state';
import { selectedPopupEditNameErrorAtom } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/state/popup-edit-error-state';
import TextInput from 'afterdoc-design-system/components/Atoms/Input/TextInput';
import LabelText from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import { useAtom, useSetAtom } from 'jotai/index';
import { type ChangeEvent, memo } from 'react';

interface PopupTitleInputProps {
  name: string;
  isDisabled: boolean;
}
function PopupNameInput({ name, isDisabled }: PopupTitleInputProps) {
  const setPopupCreateOrUpdateParams = useSetAtom(popupCreateOrUpdateParamState);

  const [popupNameError, setPopupNameError] = useAtom(selectedPopupEditNameErrorAtom);

  const handleNameInput = (e: ChangeEvent<HTMLInputElement>) => {
    setPopupNameError(null);
    setPopupCreateOrUpdateParams((prev) => ({ ...prev, name: e.target.value }));
  };

  return (
    <div className='flex min-h-40 items-start gap-24 py-4'>
      <LabelText width={76} isRequired className='flex h-32 items-center'>
        팝업명
      </LabelText>
      <TextInput
        className='h-full flex-grow'
        maxLength={100}
        value={name}
        disabled={isDisabled}
        onChange={handleNameInput}
        placeholder='관리를 위해, 병원 내부에서만 보여지는 이름입니다.'
        errorText={popupNameError}
        hasError={!!popupNameError}
      />
    </div>
  );
}

export default memo(PopupNameInput);
