import IconButton from '@afterdoc-design-system/components/Atoms/Button/IconButton';
import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import dayjs from 'dayjs';
import type { MemoWithFileID } from 'web/apis/swaggers/swagger-docs';

interface ServiceManagerMemoCardProps {
  managerNote: MemoWithFileID;
  onClick: () => void;
  selectedMemoId?: string;
  onTogglePin: (memoInfo: MemoWithFileID) => void;
}

export default function ServiceManagerMemoCard({
  managerNote,
  onClick,
  selectedMemoId,
  onTogglePin,
}: ServiceManagerMemoCardProps) {
  const { content, _id: managerNoteId, updatedAt, writerName, modifierName } = managerNote;

  const bottomText =
    managerNote && dayjs(updatedAt).isValid()
      ? `${dayjs(updatedAt).format('YYYY-MM-DD HH:mm')}/${modifierName ?? writerName}`
      : undefined;

  const handlePinClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onTogglePin(managerNote);
  };

  return (
    <div
      className={customTwMerge(
        'w-full cursor-pointer rounded-r10 border bg-white50 px-20 py-10',
        selectedMemoId === managerNoteId ? 'border-blue500 bg-blue50' : 'border-white600',
      )}
      onClick={onClick}>
      <div
        className={customTwMerge(
          'grid h-[46px] items-start gap-4',
          managerNote.fileIDs?.length ? 'grid-cols-[16px,1fr]' : 'w-full',
        )}>
        {!!managerNote.fileIDs?.length && <Icon name='image-filter' size={16} color='black200' />}
        <div className='line-clamp-2 text-Body12 text-black700'>{content}</div>
      </div>
      <div className='flex w-full items-center justify-between'>
        <div className='text-Body10 text-black200'>{bottomText}</div>
        <div className='flex items-center gap-6'>
          <div className='text-Body10 text-black200'>상단고정</div>
          <IconButton
            onClick={handlePinClick}
            icon={managerNote.isPinned ? 'pinned' : 'pin'}
            size={20}
          />
        </div>
      </div>
    </div>
  );
}
