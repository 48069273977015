import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import type {
  ApiPushRequestElFindParams,
  RequestPushNotification,
} from '@apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from '@shared/hooks/use-selected-hospital-info';
import { useSuspenseQuery } from '@tanstack/react-query';
import { EVENT_PUSH_LIST_HEADERS } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/components/EventList/components/EventListBody/components/EventPushAlarmDialog/EventPushList/constants/event-push-header-items';
import { eventPushRequestListQueryKeyState } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/components/EventList/components/EventListBody/components/EventPushAlarmDialog/EventPushSelector/states/event-push-request-list-query-key-state';
import NoData from 'afterdoc-design-system/components/Molecules/NoData/NoData';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai/index';
import { SHARED_UTILS } from 'utils/utils';

interface ListItemProps {
  data: RequestPushNotification[];
}

const fetchEventPushRequestList = async (params: ApiPushRequestElFindParams) => {
  const { data } = await apiClient.v3.apiPushRequestElFind(params);
  return SHARED_UTILS.api.checkApiResponse(data);
};

export default function EventPushListBody({ hospitalEventID }: { hospitalEventID: string }) {
  const queryKey = useAtomValue(eventPushRequestListQueryKeyState);
  const { hospitalID } = useSelectedHospitalInfo();
  const { data } = useSuspenseQuery({
    queryKey: [
      QUERY_KEY.apiPushRequestElFind,
      { ...queryKey, hospitalID, hospitalEventID },
    ] as const,
    queryFn: ({ queryKey }) => fetchEventPushRequestList(queryKey[1]),
  });

  if (data.length === 0) {
    return <ListNoData />;
  }

  return <ListItem data={data} />;
}

const ListNoData = () => {
  return (
    <div className='h-[400px] flex-center'>
      <NoData
        iconProps={{
          name: 'warning',
          size: 48,
          color: 'white400',
        }}
        title='발송내역이 없습니다.'
        subTitle='푸시알림을 발송하면 여기서 확인할 수 있습니다.'
      />
    </div>
  );
};

const columnWidths = EVENT_PUSH_LIST_HEADERS.map(({ width }) => `${width}px`);
const basicSpanClassName = 'min-h-40 px-12 py-10 text-Body12 break-all';

const ListItem = ({ data }: ListItemProps) => {
  return (
    <div className='min-h-[410px] w-full'>
      {data.map((data) => {
        const { _id, createdAt, isSendToHospital, filterTitle, filterContent, filterJoinedAt } =
          data;

        const formattedDate = dayjs(createdAt).format('YYYY-MM-DD HH:mm');
        const formattedTarget = `${isSendToHospital ? '모든 고객' : '특정 고객'}에게 푸시알림 보내기`;

        return (
          <div className='flex-center border-line-table border-b' key={_id}>
            <span className={basicSpanClassName} style={{ width: columnWidths[0] }}>
              {formattedDate}
            </span>
            <span className={basicSpanClassName} style={{ width: columnWidths[1] }}>
              {formattedTarget}
            </span>
            <span className={basicSpanClassName} style={{ width: columnWidths[2] }}>
              {filterTitle || '-'}
            </span>
            <span className={basicSpanClassName} style={{ width: columnWidths[3] }}>
              {filterContent || '-'}
            </span>
            <span className={basicSpanClassName} style={{ width: columnWidths[4] }}>
              {filterJoinedAt || '-'}
            </span>
          </div>
        );
      })}
    </div>
  );
};
