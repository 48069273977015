import { v4 as uuidv4 } from 'uuid';
import { MESSAGE_TYPE_CODE } from '../../../../ChattingContainer/components/ChattingContent/components/ChattingMessage/constants/message';

const createMockingAWSObject = (name: string, size: number) => {
  return {
    Bucket: '',
    Etag: '',
    Key: '',
    Location: '',
    fileName: name,
    fileSize: size,
    key: '',
  };
};

export const createMockingFileIDs = (nonImageFiles: File[]) => {
  return nonImageFiles.map((file) => {
    const { name, size } = file;

    return {
      aws: [createMockingAWSObject(name, size)],
      createdAt: '',
      expiredAt: '',
      paths: [],
      type: MESSAGE_TYPE_CODE.FILE_MOCKING,
      updatedAt: '',
      userID: '',
      _v: 0,
      _id: uuidv4(),
    };
  });
};
