import AccountListTable from '@templates/HospitalSetting/containers/AllAccountSetting/containers/AllAccountList/components/AccountListTable/AccountListTable';
import AccountListTitle from '@templates/HospitalSetting/containers/AllAccountSetting/containers/AllAccountList/components/AccountListTitle';
import React from 'react';

export default function AccountListContainer() {
  return (
    <>
      <AccountListTitle />
      <AccountListTable />
    </>
  );
}
