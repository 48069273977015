import AddAccountContainer from '@templates/HospitalSetting/containers/AllAccountSetting/containers/AddAccount/AddAccount.container';
import AccountListContainer from '@templates/HospitalSetting/containers/AllAccountSetting/containers/AllAccountList/AccountList.container';

export default function AllAccountSettingContainer() {
  return (
    <div className='min-h-screen bg-white50'>
      <AddAccountContainer />
      <AccountListContainer />
    </div>
  );
}
