import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { useMemo } from 'react';
import type { ParseEventOrHomecareContent } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/components/Forms/EventOrHomecareContentForm/EventOrHomecareContentForm';
import { useGetCalculatedResult } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/components/Forms/EventOrHomecareContentForm/hooks/use-get-calculated-result';

interface DisplayEventProps {
  messageEventOrHomecare?: string;
}

export default function DisplayEventOrHomecare({ messageEventOrHomecare }: DisplayEventProps) {
  const parsedEventOrHomecareContent: ParseEventOrHomecareContent = useMemo(() => {
    if (!messageEventOrHomecare) return undefined;
    try {
      return JSON.parse(messageEventOrHomecare);
    } catch {
      return undefined;
    }
  }, [messageEventOrHomecare]);

  if (!parsedEventOrHomecareContent) {
    return <NoEventOrHomecareContent />;
  }

  return <EventOrHomecareContent parsedEventOrHomecareContent={parsedEventOrHomecareContent} />;
}

interface EventOrHomecareContentProps {
  parsedEventOrHomecareContent: ParseEventOrHomecareContent;
}

const EventOrHomecareContent = ({ parsedEventOrHomecareContent }: EventOrHomecareContentProps) => {
  const {
    title,
    thumbnailUrl,
    saleRate,
    saleType,
    price = 0,
    salePrice,
  } = parsedEventOrHomecareContent;

  const { formattedPriceDisplay, calculatedPrice, calculatedRate } = useGetCalculatedResult({
    saleRate,
    saleType,
    price,
    salePrice,
  });

  return (
    <div className='w-[234px] rounded-[12px] bg-white50 shadow-modal'>
      <div className='rounded-tl-[8px] rounded-tr-[8px] bg-blue400 p-8 text-Header12 text-white50'>
        이벤트 상담
      </div>
      <div className='flex items-center gap-8 p-8'>
        {thumbnailUrl ? (
          <img
            src={thumbnailUrl}
            alt='이벤트 썸네일'
            className={customTwMerge('h-[64px] w-[64px] rounded-[8px] object-cover')}
          />
        ) : (
          <div className='h-64 w-64 rounded-r10 bg-disabled' />
        )}
        <div className='flex flex-col'>
          {title ? (
            <div className='line-clamp-2 text-Header12 text-black500'>{title}</div>
          ) : (
            <div className='line-clamp-2 text-Header12 text-black500'>이벤트/홈케어상품 제목</div>
          )}
          <div className='mt-8 text-Body12 text-black200 line-through'>{formattedPriceDisplay}</div>
          <div className='mt-2 flex'>
            <div className='text-Header12 text-blue500'>{calculatedPrice}원</div>
            <div className='text-Header12 text-blue500'>&nbsp;({calculatedRate})</div>
          </div>
        </div>
      </div>
      <div className='flex-w-full-center py-8 text-Header14 text-blue400'>바로가기</div>
    </div>
  );
};

const NoEventOrHomecareContent = () => (
  <div className='w-[234px] rounded-[12px] bg-white50 shadow-modal'>
    <div className='rounded-tl-[8px] rounded-tr-[8px] bg-blue400 p-8 text-Header12 text-white50'>
      이벤트 상담
    </div>
    <div className='flex items-center gap-8 p-8'>
      <div className='h-[64px] w-[64px] rounded-[8px] bg-disabled' />
      <div className='flex flex-col'>
        <span className='text-Body14 text-black700'>이벤트/홈케어상품 제목</span>
        <span className='mt-8 text-Body13 text-black200 line-through'>0원</span>
        <span className='mt-2 text-Body13 text-blue500'>0원 (0%)</span>
      </div>
    </div>
    <div className='flex-w-full-center py-8 text-Header14 text-blue400'>바로가기</div>
  </div>
);
