import TextInput from 'afterdoc-design-system/components/Atoms/Input/TextInput';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

export default function AccountPassword() {
  const {
    register,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const inputValue = watch('password') ?? '';

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearErrors('password');

    let { value } = e.target;
    value = value.replace(/[^a-zA-Z0-9]/g, '');
    setValue('password', value);
  };

  return (
    <div className='w-[164px]'>
      <TextInput
        id='password'
        maxLength={25}
        placeholder={'영문, 숫자 포함 3자-25자'}
        label={{
          children: '비밀번호',
          isRequired: true,
          width: '100%',
          position: 'vertical',
        }}
        type={'password'}
        value={inputValue}
        hasError={!!errors.password}
        errorText={'영문, 숫자 3~25자로 설정해 주세요.'}
        {...register('password', { onChange: handleChange })}
      />
    </div>
  );
}
