import * as React from "react";
import type { SVGProps } from "react";
const SvgCheckboxMarked = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 40 40"
    {...props}
  >
    <path d="M16.667 28.333 8.333 20l2.35-2.367 5.984 5.984 12.65-12.65 2.35 2.366m0-8.333H8.333A3.32 3.32 0 0 0 5 8.333v23.334A3.333 3.333 0 0 0 8.333 35h23.334A3.333 3.333 0 0 0 35 31.667V8.333A3.333 3.333 0 0 0 31.667 5" />
    <path
      fill="#fff"
      d="m8.333 20 8.334 8.333 15-15-2.35-2.366-12.65 12.65-5.984-5.984z"
    />
  </svg>
);
export default SvgCheckboxMarked;
