import { useState } from 'react';

export function useDropdown() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  const openDropdown = () => {
    setIsDropdownOpen(true);
  };

  return {
    isDropdownOpen,
    handleDropdown,
    closeDropdown,
    openDropdown,
  };
}
