import { useMemo } from 'react';

interface EventSellingPriceProps {
  price: number;
  salePrice: number;
  saleRate: number;
  saleType: string;
}

export default function SellingPrice({
  price,
  salePrice,
  saleRate,
  saleType,
}: EventSellingPriceProps) {
  const formattedPrice = useMemo(() => {
    const isPriceValid = saleType === 'price' && salePrice !== 0;
    const isRateValid = saleType === 'rate' && saleRate !== 0;

    return isPriceValid || isRateValid ? `${price.toLocaleString()}원` : '';
  }, [saleType, salePrice, price]);

  const calculatePrice = useMemo(() => {
    if (saleType === 'price') {
      return `${(price - salePrice).toLocaleString()}원`;
    }
    if (saleType === 'rate') {
      const discount = (price * saleRate) / 100;
      return `${(price - discount).toLocaleString()}원`;
    }
    return '0';
  }, [saleType, salePrice, saleRate, price]);

  const combinedFormattedText = useMemo(() => {
    const rateText = (() => {
      if (saleType === 'rate' && saleRate) {
        return saleRate > 0 ? `(${saleRate}%)` : '';
      }
      if (saleType === 'price' && salePrice > 0 && price > 0) {
        const rate = Math.round((salePrice / price) * 100);
        return `(${rate}%)`;
      }
      return '';
    })();

    if (!formattedPrice && !rateText) {
      return '-';
    }

    return `${formattedPrice} ${rateText}`.trim();
  }, [formattedPrice, saleType, salePrice, saleRate, price]);

  return (
    <div className='flex h-full w-full flex-col justify-center'>
      <p className='overflow-hidden text-ellipsis whitespace-nowrap text-Header12 text-black700 '>
        {calculatePrice}
      </p>
      <p
        className={`overflow-hidden text-ellipsis whitespace-nowrap text-Body11 text-white700 ${combinedFormattedText === '-' ? '' : 'line-through'}`}>
        {combinedFormattedText}
      </p>
    </div>
  );
}
