import FilledTag from '@afterdoc-design-system/components/Atoms/Tag/FilledTag';
import { Color } from '@tailwind-base/styles/color';
import type React from 'react';
import type { TreatmentTag } from 'web/apis/swaggers/swagger-docs';

interface TagListProps {
  tags: TreatmentTag[];
}

const TagList: React.FC<TagListProps> = ({ tags }) => {
  return (
    <div className='flex flex-wrap gap-6'>
      {tags.map((tag) => (
        <FilledTag key={tag.tagId} bgColor={tag.color || Color.white400} tagSize='big'>
          {tag.name}
        </FilledTag>
      ))}
    </div>
  );
};

export default TagList;
