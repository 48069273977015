import { HOSPITAL_SETTING_PAGE } from '@templates/HospitalSetting/constants/page';
import { HOSPITAL_SETTING_PANEL_ITEMS } from '@templates/HospitalSetting/constants/tab-items';
import type { PageState } from '@templates/HospitalSetting/containers/WrapperPage.container';
import { hospitalSettingPageState } from '@templates/HospitalSetting/states/hospital-setting-page-state';
import { hospitalSettingsModifiedState } from '@templates/HospitalSetting/states/hospital-settings-modified-state';
import PanelList from 'afterdoc-design-system/components/Atoms/PanelList/PanelList';
import { modalService } from 'afterdoc-design-system/components/Molecules/Modal/Modal.service';
import Title from 'afterdoc-design-system/components/Molecules/Title/Title';
import { useAtom, useAtomValue } from 'jotai';
import { useResetAtom } from 'jotai/utils';

const getPanelState = (layerIndex: number, itemIndex: number): PageState | undefined => {
  const stateMapping: { [key: string]: PageState } = {
    '0,0': HOSPITAL_SETTING_PAGE.HOSPITAL_PROFILE,
    '0,1': HOSPITAL_SETTING_PAGE.CUSTOMER_MESSAGE_SEND,
    '1,0': HOSPITAL_SETTING_PAGE.SERVICE_SETTING,
    '1,1': HOSPITAL_SETTING_PAGE.CHANNEL_INTEGRATION,
    '2,0': HOSPITAL_SETTING_PAGE.ACCOUNT_SETTING,
    '2,1': HOSPITAL_SETTING_PAGE.PERMISSION_SETTING,
  };
  return stateMapping[`${layerIndex},${itemIndex}`];
};

export default function HospitalSettingPanel() {
  const [currentPage, setCurrentPage] = useAtom(hospitalSettingPageState);
  const resetHospitalSettingsModified = useResetAtom(hospitalSettingsModifiedState);
  const hospitalSettingsModified = useAtomValue(hospitalSettingsModifiedState);

  const showModal = () =>
    new Promise<boolean>((resolve) => {
      modalService.defaultWarning({
        onConfirm: () => {
          resetHospitalSettingsModified();
          modalService.pop();
          resolve(true);
        },
        onCancel: () => {
          modalService.pop();
          resolve(false);
        },
      });
    });

  const shouldProceedWithClick = async (layerIndex: number, itemIndex?: number) => {
    const newPage = getPanelState(layerIndex, itemIndex ?? 0);
    if (newPage && hospitalSettingsModified && currentPage !== newPage) {
      return await showModal();
    }
    resetHospitalSettingsModified();
    return true;
  };

  const handleLayerClick = (layerIndex: number) => {
    const items = HOSPITAL_SETTING_PANEL_ITEMS[layerIndex].items;
    if (items.length === 0) {
      const page = getPanelState(layerIndex, 0);
      if (page) setCurrentPage(page);
    }
  };

  const handleItemClick = (layerIndex: number, itemIndex: number) => {
    const page = getPanelState(layerIndex, itemIndex);
    if (page) setCurrentPage(page);
  };

  return (
    <div className='z-10 border-r border-r-white400 bg-white50'>
      <Title title='병원설정' />
      <div className='py-10'>
        <PanelList
          layers={HOSPITAL_SETTING_PANEL_ITEMS}
          shouldProceedWithClick={shouldProceedWithClick}
          onLayerClick={handleLayerClick}
          onItemClick={handleItemClick}
        />
      </div>
    </div>
  );
}
