import { Suspense } from 'react';
import FullLoading from 'web/shared/components/FullLoading/FullLoading';
import type { ParseEventOrHomecareContent } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/components/Forms/EventOrHomecareContentForm/EventOrHomecareContentForm';
import EventOrHomecareContentList from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/components/Forms/EventOrHomecareContentForm/components/EventOrHomecareContentSelectDialog/components/EventOrHomecareContentList/EventHomecareContentList';
import EventHomecareContentPanel from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/components/Forms/EventOrHomecareContentForm/components/EventOrHomecareContentSelectDialog/components/EventOrHomecareContentPanel/EventOrHomecareContentPanel';

export interface EventOrHomecareContentSelectDialogProps {
  handleClickWorkoutCard: (props: ParseEventOrHomecareContent) => void;
}

export default function EventOrHomecareContentSelectDialog({
  handleClickWorkoutCard,
}: EventOrHomecareContentSelectDialogProps) {
  return (
    <Suspense fallback={<FullLoading />}>
      <div className='flex'>
        <EventHomecareContentPanel />
        <EventOrHomecareContentList handleClickWorkoutCard={handleClickWorkoutCard} />
      </div>
    </Suspense>
  );
}
