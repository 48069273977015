import Pagination from '@afterdoc-design-system/components/Molecules/Pagination/Pagination';

export default function ReservationPagination({
  totalPages,
  currentPage,
  handlePageChange,
}: {
  totalPages: number;
  currentPage: number;
  handlePageChange: (page: number) => void;
}) {
  return (
    <Pagination totalPages={totalPages} defaultPage={currentPage} onPageChange={handlePageChange} />
  );
}
