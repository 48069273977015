interface EventViewCountProps {
  viewCount: number;
}

export default function ViewCount({ viewCount }: EventViewCountProps) {
  return (
    <div className='flex-center overflow-hidden text-ellipsis whitespace-nowrap '>
      <p className='truncate text-Body12 text-black500'>{viewCount.toLocaleString()}</p>
    </div>
  );
}
