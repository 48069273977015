import BaseLoading from '@afterdoc-design-system/components/Atoms/Loading/BaseLoading';
import { SHARED_UTILS } from '@shared-utils/utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { Suspense, useEffect, useMemo, useState } from 'react';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { ApiV2ReservationElCountParams } from 'web/apis/swaggers/swagger-docs';
import { usePatientDetailInfo } from 'web/shared/hooks/use-get-patient-detail-info';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import ReservationHistoryTableBody from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/AfterDoc/ReservationHistory/components/ReservationHistoryTable/components/ReservationHistoryTableBody';
import ReservationHistoryTableHeader from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/AfterDoc/ReservationHistory/components/ReservationHistoryTable/components/ReservationHistoryTableHeader';
import { RESERVATION_HISTORY_TABLE_LIMIT } from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/AfterDoc/ReservationHistory/components/ReservationHistoryTable/constants/reservation-history-table-limit';
import { reservationSelectedDateState } from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/AfterDoc/ReservationHistory/states/date';
import ReservationPagination from '../ReservationPagination';
import { RESERVATION_HISTORY_TABLE_HEADERS } from './constants/reservation-history-table-headers';

const fetchReservationHistoryCount = async (params: ApiV2ReservationElCountParams) => {
  const response = await apiClient.v2.apiV2ReservationElCount(params);
  return SHARED_UTILS.api.checkApiResponse<number>(response.data);
};

export default function ReservationHistoryTable() {
  const queryClient = useQueryClient();

  const { hospitalID } = useSelectedHospitalInfo();
  const { patientId } = usePatientDetailInfo();
  const reservationSelectedDate = useAtomValue(reservationSelectedDateState);

  const [currentPage, setCurrentPage] = useState(1);
  const [showPagination, setShowPagination] = useState(false);

  const params = useMemo(
    () => ({
      hospitalID,
      hserviceID: patientId,
      startedAt: reservationSelectedDate?.startedAt,
      endedAt: reservationSelectedDate?.endedAt,
      isRemoved: false,
    }),
    [hospitalID, patientId, reservationSelectedDate],
  );

  const { data: reservationCount } = useQuery({
    queryKey: [QUERY_KEY.apiV2ReservationElCount, params] as const,
    queryFn: ({ queryKey }) => fetchReservationHistoryCount(queryKey[1]),
    staleTime: 0,
    gcTime: 0,
  });

  const totalPages = useMemo(() => {
    return reservationCount ? Math.ceil(reservationCount / RESERVATION_HISTORY_TABLE_LIMIT) : 0;
  }, [reservationCount]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEY.apiV2ReservationElCount, params],
    });
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [reservationSelectedDate]);

  useEffect(() => {
    if (reservationCount) {
      const totalPages = Math.ceil(reservationCount / RESERVATION_HISTORY_TABLE_LIMIT);
      setShowPagination(totalPages > 1);

      if (currentPage > totalPages) {
        setCurrentPage(Math.max(1, totalPages));
      }
    } else {
      setShowPagination(false);
    }
  }, [reservationCount, currentPage]);

  return (
    <>
      <div className='flex min-h-[300px] w-full flex-col'>
        <table className='w-full table-fixed bg-white text-Body12'>
          <colgroup>
            {/* 예약일시 */}
            <col className='w-[200px]' />
            {/* 부서 */}
            <col className='w-[180px]' />
            {/* 담당자 */}
            <col className='w-[100px]' />
            {/* 프로그램 */}
            <col className='w-[200px]' />
            {/* 예약메모 */}
            <col className='min-w-[180px]' />
            {/* 최종수정자 */}
            <col className='w-[110px]' />
            {/* 변경내역 */}
            <col className='w-[80px]' />
          </colgroup>
          <ReservationHistoryTableHeader />
          <tbody>
            <Suspense
              fallback={
                <tr>
                  <td colSpan={RESERVATION_HISTORY_TABLE_HEADERS.length}>
                    <div className='flex h-[300px] w-full items-center justify-center'>
                      <BaseLoading />
                    </div>
                  </td>
                </tr>
              }>
              <ReservationHistoryTableBody
                reservationSelectedDate={reservationSelectedDate}
                currentPage={currentPage}
              />
            </Suspense>
          </tbody>
        </table>
      </div>
      {showPagination && (
        <ReservationPagination
          totalPages={totalPages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
      )}
    </>
  );
}
