import * as React from "react";
import type { SVGProps } from "react";
const SvgArrowDownLeftThick = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      d="M13.5 35.5V18h3v12.379l16.44-16.44 2.12 2.122L18.623 32.5H31v3z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgArrowDownLeftThick;
