import IconButton from '@afterdoc-design-system/components/Atoms/Button/IconButton';
import Dropdown from '@afterdoc-design-system/components/Atoms/Dropdown/Dropdown';
import TextInput from '@afterdoc-design-system/components/Atoms/Input/TextInput';
import dayjs from 'dayjs';
import { useSetAtom } from 'jotai';
import { useEffect, useRef, useState } from 'react';
import { reservationSelectedDateState } from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/AfterDoc/ReservationHistory/states/date';

const generateYears = (currentYear: number) => {
  const startYear = 1900;
  const endYear = 2500;
  const years = [];

  for (let year = startYear; year <= endYear; year++) {
    years.push(`${year}년`);
  }

  const currentYearIndex = years.findIndex((year) => year === `${currentYear}년`);

  return { years, currentYearIndex };
};

export default function YearSelector() {
  const dropdownOptions = useRef<string[]>(generateYears(dayjs().year()).years);

  const setReservationSelectedDate = useSetAtom(reservationSelectedDateState);
  const [isToggle, setIsToggle] = useState(false);
  const [selectedDropdownIndex, setSelectedDropdownIndex] = useState<number>(
    generateYears(dayjs().year()).currentYearIndex,
  );

  const customFocusScrollHandler = (focusedIndex: number) => {
    return focusedIndex - 2;
  };

  const customSelectedScrollHandler = (selectedIndex: number) => {
    return selectedIndex - 2;
  };

  useEffect(() => {
    const startDate = dayjs()
      .year(selectedDropdownIndex + 1900)
      .startOf('year')
      .startOf('day');

    const endDate = dayjs()
      .year(selectedDropdownIndex + 1900)
      .endOf('year')
      .endOf('day');

    setReservationSelectedDate({
      startedAt: startDate.utc().toDate(),
      endedAt: endDate.utc().toDate(),
    });
  }, [selectedDropdownIndex, setReservationSelectedDate]);

  return (
    <div className='flex items-center gap-10'>
      <IconButton
        icon='chevron-left'
        size={16}
        color='black500'
        className='h-24 w-24 rounded-r6 border border-white400 p-3'
        onClick={() => {
          setSelectedDropdownIndex(selectedDropdownIndex - 1);
        }}
      />
      <div className='relative'>
        <TextInput
          inputNotAllowed={true}
          onClick={() => {
            setIsToggle(!isToggle);
          }}
          contentEditable={false}
          value={dropdownOptions.current[selectedDropdownIndex].replace('년', '')}
        />
        {isToggle && (
          <Dropdown
            customFocusScrollHandler={customFocusScrollHandler}
            customSelectedScrollHandler={customSelectedScrollHandler}
            options={dropdownOptions.current}
            focusedIndex={selectedDropdownIndex}
            onSelect={(index) => {
              setSelectedDropdownIndex(index);
              setIsToggle(!isToggle);
            }}
            selectedIndex={selectedDropdownIndex}
            handleToggle={() => setIsToggle(!isToggle)}
          />
        )}
      </div>
      <IconButton
        icon='chevron-right'
        size={16}
        color='black500'
        className='h-24 w-24 rounded-r6 border border-white400 p-3'
        onClick={() => {
          setSelectedDropdownIndex(selectedDropdownIndex + 1);
        }}
      />
    </div>
  );
}
