import { REPLACEMENT_TEXT } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/components/PhoneView/constants/replacement-text';

export const replaceTemplateVariables = (text?: string) => {
  const regex = /{{(고객명|병원명|치료태그명)}}/g;

  if (!text) return undefined;

  return text.replace(regex, (match, key: keyof typeof REPLACEMENT_TEXT) => {
    return REPLACEMENT_TEXT[key] || match;
  });
};
