import BaseLoading from 'afterdoc-design-system/components/Atoms/Loading/BaseLoading';
import React from 'react';

export default function AddressFetchLoading() {
  return (
    <div className='h-full flex-center flex-grow'>
      <BaseLoading />
    </div>
  );
}
