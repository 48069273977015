import FilePicker, { type FilePickerHandle } from '@shared/components/FilePicker/FilePicker';
import { FileUploadController } from '@shared/utils/fileUpload/FileUploadController';
import type { FileUploadResponse } from '@shared/utils/fileUpload/types/types';
import { hospitalInfoState } from '@templates/HospitalSetting/containers/HospitalProfile/states/hospital-info-state';
import BigImage from 'afterdoc-design-system/components/Atoms/BigImage/BigImage';
import { useAtom } from 'jotai';
import { useRef } from 'react';
import { useUserInfo } from 'web/shared/hooks/use-user-info';

export default function HospitalProfileImage() {
  const { userId } = useUserInfo();
  const [hospitalInfo, setHospitalInfo] = useAtom(hospitalInfoState);
  const filePickerRef = useRef<FilePickerHandle>(null);

  const handleClick = () => {
    filePickerRef.current?.clickSingle();
  };

  const handleFileChange = (files: File[]) => {
    const callback = (result: FileUploadResponse) => {
      if (result.code !== 0 || !result.data) {
        return;
      }
      const file = result.data[0];
      setHospitalInfo((prev) => ({
        ...prev,
        representImage: file,
      }));
    };

    FileUploadController.uploadFiles({
      files,
      userID: userId,
      callback,
    });
  };

  const imageURL = hospitalInfo.representImage?.paths?.[0];

  return (
    <>
      <FilePicker ref={filePickerRef} onFileChange={handleFileChange} />
      <BigImage
        text='이미지 변경'
        width={206}
        height={206}
        onClickImage={handleClick}
        onClickNoImage={handleClick}>
        {!!imageURL && (
          <img alt='Karina' className='h-full w-full rounded-xl object-cover' src={imageURL} />
        )}
      </BigImage>
    </>
  );
}
