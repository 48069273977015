import NoData from 'afterdoc-design-system/components/Molecules/NoData/NoData';

interface CustomerReservationEmptyProps {
  isFetchingError: boolean;
}
export const CustomerReservationEmpty = ({ isFetchingError }: CustomerReservationEmptyProps) => {
  return (
    <>
      <NoData
        title={
          isFetchingError ? '일시적인 오류로 예약을 불러올 수 없습니다.' : '예약 정보가 없습니다.'
        }
        iconProps={{
          name: 'warning',
          size: 48,
          color: 'white600',
        }}
        className='flex h-full w-full flex-center'
      />
    </>
  );
};
