import { SHARED_UTILS } from '@shared-utils/utils';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { useEffect, useMemo } from 'react';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { ApiChatElFindIdParams } from 'web/apis/swaggers/swagger-docs';
import { chatRoomIDSelector } from 'web/templates/CustomerChat/states/selected-chat-room';

const INITIAL_NUM = 20;
const INTERVAL_NUM = 20;

const getChat = async (query: ApiChatElFindIdParams) => {
  const { data } = await apiClient.v3.apiChatElFindId(query);
  return SHARED_UTILS.api.checkApiResponse(data);
};

export const useInfiniteMessageIDChatData = ({
  selectedMessageID,
}: { selectedMessageID: string | null }) => {
  const chatRoomID = useAtomValue(chatRoomIDSelector);
  const queryClient = useQueryClient();

  const query = useMemo(
    () => ({
      chatRoomID,
      _id: selectedMessageID,
      key: 'sentAt',
      direction: 'next',
    }),
    [chatRoomID, selectedMessageID],
  );

  const {
    data,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    fetchPreviousPage,
    hasPreviousPage,
    isFetchingPreviousPage,
    isLoading,
  } = useInfiniteQuery({
    queryKey: [QUERY_KEY.apiChatElFindId, query],
    queryFn: ({ pageParam }) => {
      const { direction, skip, limit } = pageParam;
      return getChat({ ...query, direction, skip, limit } as ApiChatElFindIdParams);
    },
    getNextPageParam: (lastPage, _, lastPageParam) => {
      if (!selectedMessageID) return undefined;
      const { direction, skip, limit } = lastPageParam;

      const isLast = lastPage.length === 0;
      if (isLast) return undefined;
      const newSkip = direction === 'next' ? skip + limit : 1;

      return { direction: 'next', skip: newSkip, limit: INTERVAL_NUM };
    },
    getPreviousPageParam: (firstPage, _, firstPageParam) => {
      if (!selectedMessageID) return undefined;
      if (!firstPage?.length) return undefined;

      const { skip, limit } = firstPageParam;
      const newSkip = skip + limit;

      if (newSkip === skip) return undefined;

      return { direction: 'prev', skip: newSkip, limit: INTERVAL_NUM };
    },
    initialPageParam: { direction: 'prev', limit: INITIAL_NUM, skip: 0 },
    enabled: selectedMessageID !== null,
  });

  // 페이지별 direction에 따라 정렬
  const newMessages = [];

  for (const index in data?.pageParams) {
    const numberIndex = Number(index);
    const param = data?.pageParams[numberIndex] as { direction: string };
    const isPrevDirection = param?.direction === 'prev';
    const newPushArray = isPrevDirection
      ? (data?.pages[numberIndex] ?? [])
      : (data?.pages[numberIndex].slice().reverse() ?? []);
    newMessages.unshift(...newPushArray);
  }

  useEffect(() => {
    if (selectedMessageID) {
      queryClient.resetQueries({
        queryKey: [QUERY_KEY.apiChatElFindId, query],
        exact: true, // Ensures that only this query is reset
      });
      refetch(); // Fetch new data after reset
    }
  }, [selectedMessageID, queryClient, refetch]);

  return {
    data: newMessages,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    fetchPreviousPage,
    hasPreviousPage,
    isFetchingPreviousPage,
    isLoading,
  };
};
