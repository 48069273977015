import * as React from "react";
import type { SVGProps } from "react";
const SvgPin = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <rect width={20} height={20} fill="#fff" rx={10} />
    <path
      fill="#888"
      d="M12.223 10V5.555h.555v-1.11H7.223v1.11h.555V10L6.667 11.11v1.111h2.889v3.333h.889v-3.333h2.889v-1.111zm-4 1.111.666-.667V5.555h2.222v4.89l.667.666z"
    />
  </svg>
);
export default SvgPin;
