import { toastService } from '@afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { useAtom } from 'jotai';
import { useCallback } from 'react';
import { MAX_LENGTH } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/constants/replaceable-texts';
import { useTextAreaWithMaxLength } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/hooks/use-text-area-with-max-length';
import { actualTextLengthState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/states/actual-text-length';

interface UseTextAreaControlProps {
  text: string;
}

export function useTextAreaControl({ text }: UseTextAreaControlProps) {
  const [actualTextLength, setActualTextLength] = useAtom(actualTextLengthState);

  const { handleTextAreaChange, calculateActualLength } = useTextAreaWithMaxLength({
    setActualTextLength,
  });

  const handlePaste = useCallback(
    (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
      const pastedText = e.clipboardData.getData('text');
      const currentText = e.currentTarget.value;

      // 붙여넣기 될 위치 계산
      const start = e.currentTarget.selectionStart ?? 0;
      const end = e.currentTarget.selectionEnd ?? 0;

      // 붙여넣기 후의 전체 텍스트 계산
      const newText = currentText.slice(0, start) + pastedText + currentText.slice(end);

      // 실제 길이 계산
      const newLength = calculateActualLength(newText);

      // 최대 길이 초과 시 붙여넣기 취소
      if (newLength > MAX_LENGTH) {
        toastService.errorMsg({
          text: '최대 길이를 초과하였습니다.',
        });
        e.preventDefault();
      }
    },
    [calculateActualLength],
  );

  const handleChange = useCallback(
    (
      e: React.ChangeEvent<HTMLTextAreaElement>,
      onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void,
    ) => {
      handleTextAreaChange(e);

      if (actualTextLength >= MAX_LENGTH && e.target.value.length > (text?.length ?? 0)) {
        return;
      }

      onChange(e);
    },
    [actualTextLength, text, handleTextAreaChange],
  );

  return {
    handlePaste,
    handleChange,
  };
}
