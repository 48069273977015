import { useNavigationBlocker } from '@shared/hooks/use-navigation-blocker';
import { prompts } from '@templates/HospitalSetting/containers/CustomerMessageSend/constants/prompts';
import RegistrationPrompt from '@templates/HospitalSetting/containers/CustomerMessageSend/containers/RegistrationPrompt';
import { useHospitalInfo } from '@templates/HospitalSetting/hooks/use-hospital-info';
import { hospitalSettingsModifiedState } from '@templates/HospitalSetting/states/hospital-settings-modified-state';
import { funcSaveButtonState } from '@templates/HospitalSetting/states/save-button-disabled-state';
import { useAtom } from 'jotai';
import { useSetAtom } from 'jotai/index';
import { isEqual } from 'lodash-es';
import { useEffect } from 'react';

export default function CustomerMessageSendContainer() {
  const setSaveFunction = useSetAtom(funcSaveButtonState);

  const { hospitalInfo, initialHospitalInfo, handleSave } = useHospitalInfo();

  const [hospitalSettingsModified, setHospitalSettingsModified] = useAtom(
    hospitalSettingsModifiedState,
  );

  useEffect(() => {
    if (initialHospitalInfo && hospitalInfo) {
      const isEquals = isEqual(initialHospitalInfo, hospitalInfo);
      setHospitalSettingsModified(!isEquals);
    }
  }, [initialHospitalInfo, hospitalInfo]);

  useEffect(() => {
    setSaveFunction(() => handleSave('customerMessage'));
  }, [handleSave, setSaveFunction]);

  useNavigationBlocker({
    shouldBlock: hospitalSettingsModified,
  });

  return (
    <div className='bg-white50 pt-20'>
      {prompts.map((prompt) => (
        <RegistrationPrompt key={prompt.type} {...prompt} />
      ))}
    </div>
  );
}
