import ContainedButton from '@afterdoc-design-system/components/Atoms/Button/ContainedButton';
import OutlinedButton from '@afterdoc-design-system/components/Atoms/Button/OutlinedButton';
import { toastService } from '@afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { dialogService } from '@afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import { SHARED_UTILS } from '@shared-utils/utils';
import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type {
  ApiMemosElFindParams,
  ApiMemosElRemoveData,
  File,
  MemoWithFileID,
} from 'web/apis/swaggers/swagger-docs';
import FullLoading from 'web/shared/components/FullLoading/FullLoading';
import { useProcessImagesForUpload } from 'web/shared/hooks/files/images/use-process-images-for-upload';
import type { ImageType } from 'web/shared/hooks/files/images/use-upload-multiple-images';
import { usePatientDetailInfo } from 'web/shared/hooks/use-get-patient-detail-info';
import ManagementDirectionImageGallery from './components/ManagementDirectionImageGallery';
import ManagementDirectionListsWithTitle from './components/ManagementDirectionListsWithTitle';
import ManagementDirectionMemoContentEditor from './components/ManagementDirectionMemoContentEditor';

const fetchManagementDirection = async (params: ApiMemosElFindParams) => {
  const response = await apiClient.v3.apiMemosElFind(params);
  return SHARED_UTILS.api.checkApiResponse(response.data) as MemoWithFileID[];
};

const createManagementDirection = async (
  params: Parameters<typeof apiClient.v3.apiMemosElCreate>['0'],
) => {
  const response = await apiClient.v3.apiMemosElCreate(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const updateManagementDirection = async (
  params: Parameters<typeof apiClient.v3.apiMemosElUpdate>['0'],
) => {
  const response = await apiClient.v3.apiMemosElUpdate(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const removeManagementDirection = async (
  params: Parameters<typeof apiClient.v3.apiMemosElRemove>['0'],
) => {
  const response = await apiClient.v3.apiMemosElRemove(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export type CreateMemosAPIFormValues = Parameters<typeof apiClient.v3.apiMemosElCreate>[0];
export type UpdateMemosAPIFormValues = Parameters<typeof apiClient.v3.apiMemosElUpdate>[0];

export const MANAGEMENT_DIRECTION_DIALOG_ID = 'management-direction-dialog';

export default function ManagementDirectionDialogContainer() {
  const queryClient = useQueryClient();
  const { patientId } = usePatientDetailInfo();

  const [contentType, setContentType] = useState<'CREATE' | 'EDIT'>();
  const [selectedManagementDirectionId, setSelectedManagementDirectionId] = useState<string>();

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteQuery({
    queryKey: [
      QUERY_KEY.apiMemosElFind,
      { hserviceID: patientId, type: 2000, limit: 5, key: '_id', way: -1 },
    ] as const,
    queryFn: async ({ queryKey, pageParam = 0 }) => {
      return fetchManagementDirection({ ...queryKey[1], skip: pageParam });
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) => {
      const totalLoadedMemos = allPages.flat().length;
      if (lastPage.length === 5) {
        return totalLoadedMemos;
      }
      return undefined;
    },
  });

  const selectedManagementDirection = useMemo(
    () => data?.pages.flat().find((memo) => memo._id === selectedManagementDirectionId),
    [data, selectedManagementDirectionId],
  );

  const defaultValues = useMemo(() => {
    return {
      content: selectedManagementDirection?.content,
      fileIDs: selectedManagementDirection?.fileIDs,
      type: 2000,
    };
  }, [selectedManagementDirection]);

  const methods = useForm<CreateMemosAPIFormValues | UpdateMemosAPIFormValues>({
    mode: 'onChange',
    defaultValues,
  });

  const fileIDs = methods.watch('fileIDs') as string[];
  const parsedImageFiles = fileIDs ? (JSON.parse(fileIDs[0]) as File[]) : [];

  const { processImagesForUpload } = useProcessImagesForUpload();

  const deleteMutation = useMutation({
    mutationFn: (params: ApiMemosElRemoveData) => removeManagementDirection(params),
    onSuccess: () => {
      toastService.successMsg({
        text: '관리방향을 삭제했습니다.',
      });
      queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEY.apiMemosElFind,
          {
            hserviceID: patientId,
            type: 2000,
            limit: 5,
            key: '_id',
            way: -1,
          },
        ],
      });
      resetState();
      setSelectedManagementDirectionId(undefined);
    },
  });

  const createManagementDirectionMutation = useMutation({
    mutationFn: (params: Parameters<typeof apiClient.v3.apiMemosElCreate>['0']) =>
      createManagementDirection(params),
    onSuccess: (data) => {
      toastService.successMsg({
        text: '관리방향을 저장했습니다.',
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.apiPatientsElDetail, { patientId }],
      });
      queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEY.apiMemosElFind,
          {
            hserviceID: patientId,
            type: 2000,
            limit: 5,
            key: '_id',
            way: -1,
          },
        ],
      });
      setContentType('EDIT');
      setSelectedManagementDirectionId(data._id);
    },
  });

  const updateManagementDirectionMutation = useMutation({
    mutationFn: (params: Parameters<typeof apiClient.v3.apiMemosElUpdate>['0']) =>
      updateManagementDirection(params),
    onSuccess: () => {
      toastService.successMsg({
        text: '관리방향을 수정했습니다.',
      });
      queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEY.apiMemosElFind,
          {
            hserviceID: patientId,
            type: 2000,
            limit: 5,
            key: '_id',
            way: -1,
          },
        ],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.apiPatientsElDetail, { patientId }],
      });
    },
  });

  const resetState = () => {
    methods.reset({
      content: '',
      fileIDs: undefined,
      type: 2000,
    });
    setSelectedManagementDirectionId(undefined);
    setContentType(undefined);
  };

  const handleSaveManagementDirection = async () => {
    const fileIDs = methods.getValues('fileIDs');
    const managementDirectionContent = methods.getValues('content');

    if (!managementDirectionContent?.length) {
      toastService.errorMsg({
        text: '내용을 입력해주세요.',
      });
      return;
    }

    let processedFileID: string | undefined = undefined;

    if (fileIDs && JSON.parse(fileIDs[0]).length > 0) {
      const newImageFiles: ImageType[] = JSON.parse(fileIDs[0]);
      processedFileID = await processImagesForUpload({
        newImages: newImageFiles,
        originalImages: parsedImageFiles[0]?.paths?.map((path) => ({
          url: path,
          fileName: parsedImageFiles[0].aws?.[0].fileName ?? parsedImageFiles[0]._id,
        })),
      });
    }

    if (contentType === 'CREATE') {
      return createManagementDirectionMutation.mutate({
        hserviceID: patientId,
        type: 2000,
        content: managementDirectionContent,
        fileIDs: processedFileID ? [processedFileID] : undefined,
      });
    }

    if (contentType === 'EDIT' && selectedManagementDirectionId) {
      return updateManagementDirectionMutation.mutate({
        _id: selectedManagementDirectionId,
        content: managementDirectionContent,
        fileIDs: processedFileID ? [processedFileID] : undefined,
      });
    }
  };

  const handleAddManagementDirectionButton = () => {
    resetState();
    setContentType('CREATE');
  };

  useEffect(() => {
    if (contentType === 'EDIT') {
      methods.setValue('content', selectedManagementDirection?.content);

      if (selectedManagementDirection?.fileIDs) {
        methods.setValue('fileIDs', [JSON.stringify(selectedManagementDirection?.fileIDs)]);
      }
    }
  }, [selectedManagementDirectionId, contentType]);

  useEffect(() => {
    if (selectedManagementDirectionId) {
      setContentType('EDIT');
    }
  }, [selectedManagementDirectionId]);

  useEffect(() => {
    if (data && data.pages[0].length > 0 && !selectedManagementDirectionId && !contentType) {
      setSelectedManagementDirectionId(data.pages[0][0]._id);
      setContentType('EDIT');
    }
  }, [data]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.code === 'Escape') {
        dialogService.popById(MANAGEMENT_DIRECTION_DIALOG_ID);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  if (!data) {
    return (
      <div className='mt-10 flex h-[444px] w-full gap-20 bg-white50 px-20'>
        <FullLoading />
      </div>
    );
  }

  return (
    <FormProvider {...methods}>
      <div className='mt-10 grid h-full grid-cols-[320px,1fr] gap-20 px-20 pb-20'>
        <ManagementDirectionListsWithTitle
          managementDirections={data.pages.flat()}
          selectedManagementDirectionId={selectedManagementDirectionId}
          handleAddManagementDirectionButton={handleAddManagementDirectionButton}
          handleClickMemoCard={setSelectedManagementDirectionId}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
        />
        <div className='flex h-full w-[620px] flex-col gap-10'>
          <div className='relative h-full w-full'>
            <ManagementDirectionMemoContentEditor
              selectedManagementDirectionId={selectedManagementDirectionId}
              disabled={!selectedManagementDirectionId && !contentType}
            />
            <ManagementDirectionImageGallery
              disabled={!selectedManagementDirectionId && !contentType}
            />
          </div>
          {(selectedManagementDirectionId || !!contentType) && (
            <BottomTextAndHandlerButtons
              onRemoveManagementDirection={() =>
                deleteMutation.mutate({ _id: selectedManagementDirectionId })
              }
              onHandleSaveManagementDirection={handleSaveManagementDirection}
              updatedAt={selectedManagementDirection?.updatedAt}
              modifierName={selectedManagementDirection?.modifierName}
              writerName={selectedManagementDirection?.writerName}
            />
          )}
        </div>
      </div>
    </FormProvider>
  );
}

interface BottomTextAndHandlerButtonsProps {
  onRemoveManagementDirection: () => void;
  onHandleSaveManagementDirection: () => void;
  updatedAt?: string;
  modifierName?: string;
  writerName?: string;
}

const BottomTextAndHandlerButtons = ({
  onHandleSaveManagementDirection,
  onRemoveManagementDirection,
  updatedAt,
  modifierName,
  writerName,
}: BottomTextAndHandlerButtonsProps) => {
  return (
    <div className='flex items-center'>
      <div className='whitespace-nowrap text-Body10 text-black200'>
        {updatedAt
          ? `${dayjs(updatedAt).format('YYYY-MM-DD HH:mm')}/${modifierName ?? writerName}`
          : undefined}
      </div>
      <div className='mt-4 flex w-full items-center justify-end gap-10 pt-10'>
        <OutlinedButton
          btnColor='secondary'
          buttonSize='big'
          className='w-[100px]'
          onClick={onRemoveManagementDirection}>
          삭제
        </OutlinedButton>
        <ContainedButton
          buttonSize='big'
          className='w-[100px]'
          onClick={onHandleSaveManagementDirection}>
          저장
        </ContainedButton>
      </div>
    </div>
  );
};
