import { type RefObject, useEffect, useState } from 'react';

export const useCheckIsOverflow = (elem?: RefObject<HTMLElement> | null) => {
  const [isOverflow, setIsOverflow] = useState<boolean>(false);

  useEffect(() => {
    if (!elem?.current) {
      return;
    }
    const overflowObs = new ResizeObserver(() => {
      if (!elem?.current) {
        return;
      }

      setIsOverflow(elem.current.scrollWidth > elem.current.clientWidth);
    });

    overflowObs.observe(elem.current);
    return () => overflowObs.disconnect();
  }, []);

  return { isOverflow };
};
