import type { ALReservationDetail } from '@apis/Almighty/types';
import { useSelectedHospitalInfo } from '@shared/hooks/use-selected-hospital-info';
import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import LabelText from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import dayjs from 'dayjs';
import { useCallback, useMemo } from 'react';

export const CustomerAlReservationCell = ({
  reservation,
}: { reservation: ALReservationDetail }) => {
  const { integrationSetting } = useSelectedHospitalInfo();

  const isCanceled = useMemo(
    () => integrationSetting?.customObject?.defaultCancelID?.includes(reservation.label.id),
    [integrationSetting, reservation.label.id],
  );

  const formattedDate = useMemo(() => {
    const { reservationDate, reservationStartTime } = reservation;
    const date = dayjs(`${reservationDate}T${reservationStartTime}`);
    return date.isValid() ? date.format('YYYY년 MM월 DD일(ddd) HH:mm') : '-';
  }, [reservation]);

  const handleClick = useCallback(() => {
    const date = dayjs(`${reservation.reservationDate}T${reservation.reservationStartTime}`);
    const selectedDate = date.isValid() ? date.toISOString() : undefined;
    window?.electron?.ipcRenderer.send('Application.showReservationWindow', {
      value: { selectedDate },
    });
  }, [reservation]);

  return (
    <div
      onClick={() => handleClick()}
      className='flex cursor-pointer flex-col gap-10 rounded-r16 border border-white500 px-16 pt-16 pb-10'>
      <div className='flex h-20 items-center justify-between gap-10'>
        <div className='flex flex-shrink-0 items-center gap-10'>
          <Icon
            name={isCanceled ? 'calendar-remove-outline' : 'calendar-check-outline'}
            size={20}
            color={isCanceled ? 'red500' : 'blue500'}
          />
          <LabelText textClassName='text-Header14 text-black700'>{formattedDate}</LabelText>
        </div>
        <div className='flex h-full flex-grow items-center justify-end'>
          <span className='line-clamp-1 text-Body10Bold text-black500'>
            {reservation.consultantName || '-'}
          </span>
        </div>
      </div>
      <div className='flex h-18 w-full items-center justify-between gap-10'>
        <div className='flex flex-grow items-end overflow-hidden'>
          <span className='w-full overflow-hidden text-ellipsis whitespace-nowrap text-Body12'>
            {reservation.reservationMemo || '-'}
          </span>
        </div>
        <div className='flex flex-shrink-0 items-center gap-10'>
          <span className='max-w-[60px] truncate text-Body10Bold'>
            {reservation.departmentName || '-'}
          </span>
          <Divider type={'line'} direction={'vertical'} height={18} />
          <span className='max-w-[60px] truncate text-Body10Bold'>
            {reservation.chargeDoctorName || '-'}
          </span>
        </div>
      </div>
    </div>
  );
};
