import Radio from '@afterdoc-design-system/components/Atoms/Radio/Radio';
import type { ALIntegrationUserData } from './hooks/type';

interface ALIntegrationEditRepresentedItemProps {
  index: number;
  user: ALIntegrationUserData;
  updateRepresentedUser: (index: number) => void;
}

export const ALIntegrationEditRepresentedItem = ({
  index,
  user,
  updateRepresentedUser,
}: ALIntegrationEditRepresentedItemProps) => {
  const update = () => {
    updateRepresentedUser(index);
  };
  return (
    <div
      style={{ width: '279px' }}
      className='my-10 flex flex-col gap-10 rounded-r10 border border-white600 bg-white50 p-16'>
      <div className='item-center flex gap-10'>
        {/* <Checkbox checked={data.isSelected} onChange={handleCheckBoxChange} /> */}
        <Radio checked={user.isRepresented === true} onClick={update} />
        <div>
          <span className='text-Header14'>{user.name}</span>{' '}
          <span className='text-Body11'>{user.chartNumber}</span>
        </div>
      </div>
      <div className='flex gap-10'>
        <span className='rounded-r6 bg-white100 px-8 text-Body12'>
          {user.gender}/{user.birthdate}
        </span>
        <span className='rounded-r6 bg-white100 px-8 text-Body12'>{user.phoneNumber}</span>
      </div>
    </div>
  );
};
