import type {
  VideoContentHospitalThumbnails,
  VideoContentMaterialFilePaths,
} from '@apis/swaggers/swagger-docs';
import type { Section } from '@templates/Content/containers/ContentManager/containers/VideoContent/componants/VideoContentDetail/constants/content-sections';
import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';

interface VideoContentSectionProps {
  section: Section;
  content: VideoContentHospitalThumbnails;
  children?: React.ReactNode;
}

const getSectionContent = <T extends VideoContentHospitalThumbnails, K extends keyof T>(
  obj: T,
  key: K,
): T[K] | undefined => {
  return obj[key];
};

const isMaterialContent = (
  key: keyof VideoContentHospitalThumbnails,
  data: VideoContentHospitalThumbnails[keyof VideoContentHospitalThumbnails],
): data is VideoContentMaterialFilePaths[] => {
  return key === 'material' && Array.isArray(data);
};

const formatMaterialContent = (materials: VideoContentMaterialFilePaths[]): string => {
  return materials
    .map((material, index) => {
      if (material.name) {
        return index < materials.length - 1 ? `${material.name}, ` : material.name;
      }
      return '';
    })
    .filter(Boolean)
    .join('');
};

export default function VideoContentSection({
  section,
  content,
  children,
}: VideoContentSectionProps) {
  const { title, key } = section;

  const data = getSectionContent(content, key);

  const getContentString = () => {
    if (typeof data === 'string') {
      return data || '-';
    }

    if (isMaterialContent(key, data)) {
      return formatMaterialContent(data) || '-';
    }

    return '-';
  };

  const contentString = getContentString();

  return (
    <div className='flex w-full flex-col'>
      <span className='text-Body13 text-black500'>{title}</span>
      {contentString && <span className='mt-6 text-Body12 text-black500'>{contentString}</span>}
      {children}
      <Divider type='line' className='mt-10' />
    </div>
  );
}
