import { selectedChattingListTabState } from '@templates/CustomerChat/states/selected-chatting-list-tab';
import { useResetAtom } from 'jotai/utils';
import {
  chatFileDownloadHistoryAtom,
  chatFileProgressAtom,
} from '../components/ChattingRoom/components/ChattingContainer/components/ChattingContent/states/chatFileProgress';
import { selectedChatRoomState } from '../states/selected-chat-room';

export const useResetCustomerChatAtomState = () => {
  const resetSelectedChatRoom = useResetAtom(selectedChatRoomState);
  const resetSelectedChattingListTab = useResetAtom(selectedChattingListTabState);
  const resetChatFileProgress = useResetAtom(chatFileProgressAtom);
  const resetChatFileDownloadHistory = useResetAtom(chatFileDownloadHistoryAtom);

  const resetChatListAll = () => {
    resetSelectedChatRoom();
    resetSelectedChattingListTab();
  };

  //resetSelectedChatRoom을 함부로 사용하면 채팅창 누르는 순간 채팅방이 초기화되어 채팅방이 깜빡이는 현상이 발생함
  const resetChattingProgressStateAll = () => {
    resetChatFileProgress();
    resetChatFileDownloadHistory();
  };

  return {
    resetChatListAll,
    resetChattingProgressStateAll,
    resetSelectedChatRoom,
    resetSelectedChattingListTab,
  };
};
