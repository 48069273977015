export const makingTooltipNames = (participants: { realName: string }[]) => {
  const participantCount = participants.length;

  if (participantCount === 0) {
    return '';
  }

  if (participantCount === 1) {
    return participants[0].realName;
  }

  return participants.map((participant) => participant.realName).join(', ');
};
