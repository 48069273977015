import IconButton from '@afterdoc-design-system/components/Atoms/Button/IconButton';
import Divider from '@afterdoc-design-system/components/Atoms/Divider/Divider';
import DashedTag from '@afterdoc-design-system/components/Atoms/Tag/DashedTag';
import FilledTag from '@afterdoc-design-system/components/Atoms/Tag/FilledTag';
import { useClickOutside } from '@shared-hooks/use-click-outside';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import HoverTooltip from 'afterdoc-design-system/components/Atoms/Tooltip/HoverTooltip';
import dayjs from 'dayjs';
import { useMemo, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import type {
  AlgorithmWithMessagesInput,
  SingleAlgorithmHandlerResponse,
} from 'web/apis/swaggers/swagger-docs';

type AlgorithmInfoBoxProps = SingleAlgorithmHandlerResponse['data'] & {
  type: Exclude<AlgorithmWithMessagesInput['type'], 'COMMON'>;
};

export default function AlgorithmInfoBox({ type, ...props }: AlgorithmInfoBoxProps) {
  const contentRef = useRef<HTMLDivElement>(null);

  const {
    name,
    tagsHaveFolder,
    tagsHaveNoFolder,
    appliedNationalities,
    sendingSchedule,
    isForNoTreatmentTags,
    commonAlgorithm,
    isForNoNationality,
  } = props;

  const [isShowDetail, setIsShowDetail] = useState(false);
  const nationalities = useMemo(() => {
    if (type === 'MARKETING' && isForNoNationality) {
      return '국적 미입력';
    }
    return appliedNationalities.map((nationalities) => nationalities.koreanCountryName).join(', ');
  }, [appliedNationalities, type, isForNoNationality]);

  useClickOutside({
    id: uuidv4(),
    ref: contentRef,
    contentRef: contentRef,
    onClose: () => setIsShowDetail(false),
  });

  return (
    <div
      className='absolute top-20 left-40 z-50 w-[350px] rounded-r16 bg-white50 px-20 py-16 shadow-modal'
      ref={contentRef}>
      <div className={customTwMerge('flex w-full items-center justify-between gap-3')}>
        <div
          className={customTwMerge('text-Header16 text-black700', isShowDetail ? '' : 'truncate')}>
          {name}
        </div>
        <IconButton
          onClick={() => setIsShowDetail((prev) => !prev)}
          icon={isShowDetail ? 'chevron-up' : 'chevron-down'}
          size={16}
          color='black200'
        />
      </div>
      {isShowDetail && (
        <>
          {nationalities && (
            <>
              <div className='mt-4 text-Body13 text-black300'>{nationalities}</div>
              <Divider type='line' className='my-16' />
            </>
          )}
          <div className='mt-10 flex flex-col gap-12'>
            {type === 'MARKETING' && isForNoTreatmentTags && <DashedTag>치료태그 미입력</DashedTag>}
            {tagsHaveFolder.map((target) => {
              return (
                <div key={target._id} className='flex flex-col gap-8'>
                  <div className='text-Header12 text-black500'>{target.name}</div>
                  <div className='flex flex-wrap gap-8'>
                    {target.tags.map((tag) => {
                      return (
                        <HoverTooltip
                          key={tag._id}
                          text={tag.categoryName}
                          show={tag.categoryName.length > 10}
                          position='bottomRight'>
                          <FilledTag
                            bgColor={tag.color}
                            maxTextLength={10}
                            className='rounded-8 bg-blue50 px-12 py-4 text-Body13 text-black500'>
                            {tag.categoryName}
                          </FilledTag>
                        </HoverTooltip>
                      );
                    })}
                  </div>
                </div>
              );
            })}
            {tagsHaveNoFolder.length > 0 && (
              <>
                <div className='mt-8 text-Header12'>폴더없음</div>
                <div className='flex flex-wrap gap-8'>
                  {tagsHaveNoFolder.map((tag) => {
                    return (
                      <HoverTooltip
                        key={tag._id}
                        text={tag.categoryName}
                        show={tag.categoryName.length > 10}
                        position='bottomRight'>
                        <FilledTag
                          bgColor={tag.color}
                          maxTextLength={10}
                          className='rounded-8 bg-blue50 px-12 py-4 text-Body13 text-black500'>
                          {tag.categoryName}
                        </FilledTag>
                      </HoverTooltip>
                    );
                  })}
                </div>
              </>
            )}
          </div>
          {type === 'CONSULTATION' && (
            <>
              <Divider type='line' className='my-16' />
              <div className='flex items-center gap-20 text-black500'>
                <div className='w-80 text-Body10Bold'>공통 재내원 안내</div>
                {commonAlgorithm?.enabled ? (
                  <div className='flex text-Body12'>
                    <div>대상 고객에게</div>&nbsp;
                    <div className='font-bold'>
                      {commonAlgorithm.sendingDaysAfterStart ?? 0}일 이후
                    </div>
                    &nbsp;
                    <div>메시지 발송</div>
                  </div>
                ) : (
                  <div className='ml-20 text-Body12 text-black500'>미설정</div>
                )}
              </div>
            </>
          )}
          {type === 'MARKETING' && (
            <>
              <Divider type='line' className='my-16' />
              <div className='flex items-center gap-20 text-black500'>
                <div className='w-80 text-Body10Bold'>시작일/회차</div>
                {sendingSchedule ? (
                  <div className='flex text-Body12'>
                    <div className='font-bold'>
                      {dayjs(sendingSchedule.startDay).format('YYYY-MM-DD')}/
                      {sendingSchedule.sendingCount}회
                    </div>
                    &nbsp;
                    <div>메시지 발송</div>
                  </div>
                ) : (
                  <div className='ml-20 text-Body12 text-black500'>미설정</div>
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}
