import DetailedAddress from '@templates/HospitalSetting/containers/HospitalProfile/components/HospitalAddress/components/DetailedAddress';
import PrimaryAddress from '@templates/HospitalSetting/containers/HospitalProfile/components/HospitalAddress/components/PrimaryAddress';
import { hospitalInfoState } from '@templates/HospitalSetting/containers/HospitalProfile/states/hospital-info-state';
import { createErrorMessageAtom } from '@templates/HospitalSetting/states/hospital-info-input-error-messages-state';
import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import Title from 'afterdoc-design-system/components/Molecules/Title/Title';
import { useAtomValue } from 'jotai';
import { useAtom } from 'jotai/index';
import { selectAtom } from 'jotai/utils';
import { useEffect } from 'react';

const TITLE = '병원주소';
const SUB_TITLE = '고객이 내원할 때 정확한 위치를 확인할 수 있도록 주소를 설정해 주세요.';

const hospitalAddressInfoAtom = selectAtom(hospitalInfoState, (hospital) => hospital.addressInfo);

const addressErrorAtom = createErrorMessageAtom('address');

export default function HospitalAddress() {
  const [addressError, setAddressError] = useAtom(addressErrorAtom);

  const hospitalAddressInfo = useAtomValue(hospitalAddressInfoAtom);

  useEffect(() => {
    if (addressError !== null) {
      setAddressError(null);
    }
  }, [hospitalAddressInfo]);

  return (
    <>
      <div className='px-80 pt-20 pb-40'>
        <Title title={TITLE} subTitle={SUB_TITLE} />
        <div className='mt-10 flex flex-col gap-10 px-20 pb-10'>
          <PrimaryAddress />
          <DetailedAddress />
        </div>
        {addressError && (
          <span className='mt-10 px-20 text-Body10 text-red500'>{addressError}</span>
        )}
      </div>
      <Divider type='plane' height={10} />
    </>
  );
}
