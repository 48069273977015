import { useUserInfo } from '@shared/hooks/use-user-info';
import { validateNoticeParams } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Notice/functions/validate-notice-params';
import { useHospitalNotice } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Notice/hooks/use-hospital-notice';
import { noticeCreateOrUpdateParamState } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Notice/states/notice-create-or-update-param-state';
import { noticeEditErrorState } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Notice/states/notice-edit-error-state';
import { contentEditState } from '@templates/Content/containers/ContentManager/states/content-edit-state';
import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';
import DownloadFooter from 'afterdoc-design-system/components/Molecules/DownloadFooter/DownloadFooter';
import { modalService } from 'afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { useSetAtom } from 'jotai';
import { useAtom, useAtomValue } from 'jotai/index';

interface NoticeManagePageFooterProps {
  isModified: boolean;
}

const showDeleteConfirmation = (onConfirm: () => void) => {
  modalService.defaultWarning({
    title: '공지사항을 삭제할까요?',
    contents: '삭제한 공지사항은 복구할 수 없습니다.',
    onConfirm,
  });
};

export default function NoticeManagePageFooter({ isModified }: NoticeManagePageFooterProps) {
  const [contentEdit, setContentEdit] = useAtom(contentEditState);

  const { createNoticeAction, updateNoticeAction, removeNoticeAction } = useHospitalNotice();

  const { authorizationTypeID } = useUserInfo();

  const setNoticeEditError = useSetAtom(noticeEditErrorState);

  const noticeCreateOrUpdateParam = useAtomValue(noticeCreateOrUpdateParamState);

  const handleEditEnd = () => {
    setContentEdit({ isEditing: false });
  };

  const handleConfirm = () => {
    const errors = validateNoticeParams(noticeCreateOrUpdateParam);
    if (Object.keys(errors).length > 0) {
      setNoticeEditError((prev) => ({ ...prev, ...errors }));
      return false;
    }
    if (contentEdit.id) {
      updateNoticeAction(noticeCreateOrUpdateParam, handleEditEnd);
    } else {
      createNoticeAction(noticeCreateOrUpdateParam, handleEditEnd);
    }
  };

  const handleCancel = () => {
    if (isModified) {
      modalService.defaultWarning({
        onConfirm: () => {
          setContentEdit({ isEditing: false });
          modalService.pop();
        },
      });
      return;
    }
    setContentEdit({ isEditing: false });
  };

  const handleRemove = () => {
    if (!contentEdit.id) return;
    showDeleteConfirmation(() => {
      removeNoticeAction(contentEdit.id || '', handleEditEnd);
    });
  };

  const customLeftButton = contentEdit.id && authorizationTypeID?.canDeleteContent && (
    <ContainedButton btnColor='red' onClick={handleRemove}>
      삭제
    </ContainedButton>
  );

  const customRightButton = (
    <div className='flex gap-10'>
      <ContainedButton btnColor='transparent' onClick={handleCancel}>
        취소
      </ContainedButton>
      <ContainedButton
        btnColor='blue'
        onClick={handleConfirm}
        disabled={!authorizationTypeID?.canUpsertContent}>
        저장
      </ContainedButton>
    </div>
  );

  return (
    <DownloadFooter customLeftButton={customLeftButton} customRightButton={customRightButton} />
  );
}
