import TextInput from 'afterdoc-design-system/components/Atoms/Input/TextInput';
import React from 'react';
import { useFormContext } from 'react-hook-form';

export default function EditAccountName() {
  const {
    register,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  return (
    <div className='flex flex-col gap-4'>
      <TextInput
        placeholder='최대 15자'
        maxLength={15}
        width={272}
        label={{
          isRequired: true,
          children: '이름',
          width: 60,
          position: 'horizontal',
        }}
        {...register('realName', { onChange: () => clearErrors('realName') })}
      />
      {!!errors.name && (
        <span className='ml-70 text-Body10 text-red500'>이름을 입력해 주세요.</span>
      )}
    </div>
  );
}
