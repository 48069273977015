import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isBookmarkMapState } from '@templates/CustomerChat/states/is-bookmark-map';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { useSetAtom } from 'jotai/index';
import { useState } from 'react';
import { SHARED_UTILS } from 'utils/utils';

type UpdateBookmarkParam = Parameters<typeof apiClient.v3.apiChatElUpdateBookmark>[0];

interface UpdateActionCallback {
  onSuccessCallback?: () => void;
  onFailureCallback?: (error: Error) => void;
}

const postUpdateBookmark = async (query: UpdateBookmarkParam) => {
  const { data } = await apiClient.v3.apiChatElUpdateBookmark(query);
  return SHARED_UTILS.api.checkApiResponse(data);
};
export const useUpdateBookMark = () => {
  const queryClient = useQueryClient();

  const [isUpdating, setIsUpdating] = useState(false);

  const setBookmarkState = useSetAtom(isBookmarkMapState);

  const updateBookMarkMutation = useMutation({
    mutationFn: (params: UpdateBookmarkParam) => postUpdateBookmark(params),
    onMutate: () => setIsUpdating(true),
    onSettled: () => setIsUpdating(false),
  });

  const updateBookMarkAction = (
    messageID: string,
    isBookmark: boolean,
    callback?: UpdateActionCallback,
  ) => {
    const bookmarkName = isBookmark ? '등록' : '해제';
    updateBookMarkMutation.mutate(
      { _id: messageID, isBookmark },
      {
        onSuccess: () => {
          setBookmarkState((prev) => ({ ...prev, [messageID]: isBookmark }));
          queryClient.invalidateQueries({ queryKey: [QUERY_KEY.apiChatElFind] });

          toastService.successMsg({ text: `북마크를 ${bookmarkName} 했습니다.` });

          callback?.onSuccessCallback?.();
        },
        onError: (error) => {
          toastService.errorMsg({ text: `북마크를 ${bookmarkName}를 실패 했습니다.` });

          callback?.onFailureCallback?.(error);
        },
      },
    );
  };

  return {
    isUpdating,
    updateBookMarkAction,
  };
};
