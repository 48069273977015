import { CHATTING_LIST_TABS } from '@templates/CustomerChat/constants/tab';
import { selectedChattingListTabState } from '@templates/CustomerChat/states/selected-chatting-list-tab';
import { overlayPageService } from 'afterdoc-design-system/components/Molecules/OverlayPage/OverlayPage.service';
import { useSetAtom } from 'jotai';
import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getChatRoomOne } from './api/get-chatroom-one';
import type { HandleSelectedChatRoomParmas } from './components/ChattingListBody/ChattingListBody';

type HandleSelectChatRoom = (params: HandleSelectedChatRoomParmas) => Promise<void>;

interface useChatRoomRoutingProps {
  handleSelectChatRoom: HandleSelectChatRoom;
}

export const useChatRoomRouting = ({ handleSelectChatRoom }: useChatRoomRoutingProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setSelectedTab = useSetAtom(selectedChattingListTabState);

  const parsedParams = useMemo(
    () => ({
      chatRoomID: searchParams.get('chatRoomId'),
      patientId: searchParams.get('patientId'),
      isReload: searchParams.has('reload'),
    }),
    [searchParams],
  );

  useEffect(() => {
    const handleRouting = async () => {
      const { chatRoomID, isReload } = parsedParams;

      if (!chatRoomID || !isReload) return;

      setSelectedTab(CHATTING_LIST_TABS.ALL);

      try {
        const chaRoom = await getChatRoomOne(chatRoomID);
        const chatRoomParentHServiceID = chaRoom.parentHServiceID._id;
        const patientIDInChattingListUserID = chaRoom.userID?._id ?? '';
        const chatRoomHserviceID = chaRoom.hserviceID._id;

        setSelectedTab(CHATTING_LIST_TABS.ALL);

        await handleSelectChatRoom({
          chatRoomID,
          chatRoomParentHServiceID,
          patientIDInChattingListUserID,
          chatRoomHserviceID,
        });

        overlayPageService.popAll();

        if (isReload) {
          setSearchParams({}, { replace: true });
        }
      } catch (error) {
        console.error('Error during chat room routing:', error);
      }
    };

    handleRouting();
  }, [parsedParams]);

  useEffect(() => {
    return () => {
      setSearchParams({}, { replace: true });
    };
  }, []);
};
