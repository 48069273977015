import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import { dialogService } from 'afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import { useEffect, useRef } from 'react';
import ReactPlayer, { type ReactPlayerProps } from 'react-player';
import { API_URL } from 'web/config/url';
import './VideoPlayer.scss';

interface VideoContentPlayerProps {
  vchID: string;
  wrapperClassName?: string;
  playerProps?: ReactPlayerProps;
}

const VIDEO_CONTENT_HOSPITAL_PLAY = `${API_URL}/v3/api/videocontenthospital/el/play`;

const defaultProps: ReactPlayerProps = {
  width: '100%',
  height: '100%',
  playing: true,
  muted: false,
  volume: 0.5,
  controls: true,
  pip: false,
  config: {
    file: {
      forceHLS: true,
    },
  },
};

export default function VideoPlayer({
  vchID,
  wrapperClassName,
  playerProps,
}: VideoContentPlayerProps) {
  const playerRef = useRef<ReactPlayer>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const url = `${VIDEO_CONTENT_HOSPITAL_PLAY}?vchID=${vchID}`;

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.code === 'Escape') {
        dialogService.pop();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (wrapperRef.current) {
      const videoElement = document.getElementsByTagName('video');
      if (videoElement.length > 0) {
        videoElement[0].focus();
      }
    }
  }, [wrapperRef.current]);

  return (
    <div className={customTwMerge('h-[540px] w-[960px]', wrapperClassName)}>
      <Divider type='line' />
      <div className='video_wrapper' ref={wrapperRef}>
        <ReactPlayer
          className='react-player'
          ref={playerRef}
          url={url}
          {...defaultProps}
          {...playerProps}
        />
      </div>
    </div>
  );
}
