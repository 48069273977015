import * as React from "react";
import type { SVGProps } from "react";
const SvgCheckboxBlankOutline = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path fill="#fff" d="M10 10h28v28H10z" />
    <path d="M38 6H10c-2.22 0-4 1.78-4 4v28a4 4 0 0 0 4 4h28a4 4 0 0 0 4-4V10a4 4 0 0 0-4-4m0 4v28H10V10z" />
  </svg>
);
export default SvgCheckboxBlankOutline;
