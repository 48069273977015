import NotificationSettingItem from '@templates/UserInfoSetting/containers/NotificationSetting/components/NotificationSettingItem';
import NotificationTimeRangeControl from '@templates/UserInfoSetting/containers/NotificationSetting/components/NotificationTimeRangeControl/NotificationTimeRangeControl';
import type {
  BooleanSettingKey,
  StringSettingKey,
} from '@templates/UserInfoSetting/containers/NotificationSetting/types/res-notification-setting-type';
import Title from 'afterdoc-design-system/components/Molecules/Title/Title';

interface NotificationSetting {
  fieldKey: BooleanSettingKey;
  label: string;
  timeRangeKey: BooleanSettingKey;
  startTimeKey: StringSettingKey;
  endTimeKey: StringSettingKey;
}

const notificationSettings: NotificationSetting[] = [
  {
    fieldKey: 'onInChargeChat',
    label: '내가 담당자인 채팅방의 신규 메시지 알림을 받습니다.',
    timeRangeKey: 'hasTimeSettingForInChargeChat',
    startTimeKey: 'inChargeChatStartedAt',
    endTimeKey: 'inChargeChatEndedAt',
  },
  {
    fieldKey: 'onChatsAll',
    label: '내가 참여자인 채팅방의 신규 메시지 알림을 받습니다.',
    timeRangeKey: 'onChatsDependsTime',
    startTimeKey: 'chatStartedAt',
    endTimeKey: 'chatEndedAt',
  },
];

export default function CustomerSupportNotification() {
  return (
    <div>
      <Title title={'고객상담'} subTitle={'고객이 채팅을 보냈을 때 알림을 받을 수 있습니다.'} />
      <div className='mx-20 mt-10 flex flex-col gap-10'>
        {notificationSettings.map(({ fieldKey, label, timeRangeKey, startTimeKey, endTimeKey }) => (
          <NotificationSettingItem key={fieldKey} fieldKey={fieldKey} label={label}>
            <NotificationTimeRangeControl
              key={timeRangeKey}
              fieldKey={timeRangeKey}
              startTimeKey={startTimeKey}
              endTimeKey={endTimeKey}
            />
          </NotificationSettingItem>
        ))}
      </div>
    </div>
  );
}
