import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import type {
  ApiVideocontenthospitalElFindParams,
  VideoContentHospitalCategoryThumbnailPath,
} from '@apis/swaggers/swagger-docs';
import { useQuery } from '@tanstack/react-query';
import ContentNoDataMessage from '@templates/Content/containers/ContentManager/containers/VideoContent/componants/VideoContentList/components/ContentNoDataMessage';
import VideoContentList from '@templates/Content/containers/ContentManager/containers/VideoContent/componants/VideoContentList/components/VideoContentList';
import VideoContentSearchInput from '@templates/Content/containers/ContentManager/containers/VideoContent/componants/VideoContentList/components/VideoContentSearchInput';
import VideoContentTitle from '@templates/Content/containers/ContentManager/containers/VideoContent/componants/VideoContentList/components/VideoContentTitle';
import { selectedVideoContentState } from '@templates/Content/containers/ContentManager/containers/VideoContent/states/selected-video-content-state';
import { videoContentNextPageEnabledState } from '@templates/Content/containers/ContentManager/containers/VideoContent/states/video-content-next-page-enabled-state';
import { videoContentSkipState } from '@templates/Content/containers/ContentManager/containers/VideoContent/states/video-content-skip-state';
import { focusedContentSubLayerState } from '@templates/Content/containers/ContentPanel/states/focused-content-sub-layer-state';
import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import { useAtom } from 'jotai';
import { useAtomValue, useSetAtom } from 'jotai/index';
import { useResetAtom } from 'jotai/utils';
import { useEffect, useState } from 'react';
import { SHARED_UTILS } from 'utils/utils';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';

const fetchVideoContents = async (pageParam: {
  skip: number;
  categoryID: string | undefined;
  keyword: string | undefined;
  hospitalID: string;
}) => {
  const { skip, categoryID, keyword, hospitalID } = pageParam;
  const params: ApiVideocontenthospitalElFindParams = {
    hospitalID,
    limit: 20,
    categoryID,
    keyword: keyword || undefined,
    skip,
  };
  const response = await apiClient.v3.apiVideocontenthospitalElFind(params);
  return SHARED_UTILS.api.checkApiResponse<VideoContentHospitalCategoryThumbnailPath[]>(
    response.data,
  );
};

export default function VideoContentListContainer() {
  const { hospitalID } = useSelectedHospitalInfo();
  const [searchKeyword, setSearchKeyword] = useState('');
  const [contents, setContents] = useState<VideoContentHospitalCategoryThumbnailPath[]>([]);
  const [hasLoaded, setHasLoaded] = useState(false);

  const [videoContentSkip, setVideoContentSkip] = useAtom(videoContentSkipState);

  const focusedContentSubLayer = useAtomValue(focusedContentSubLayerState);

  const setNextPageEnabled = useSetAtom(videoContentNextPageEnabledState);

  const resetSelectedVideoContent = useResetAtom(selectedVideoContentState);

  const { data, isLoading, isError } = useQuery({
    queryKey: [
      QUERY_KEY.apiVideocontenthospitalElFind,
      searchKeyword,
      videoContentSkip,
      focusedContentSubLayer?.key,
    ],
    queryFn: () =>
      fetchVideoContents({
        skip: videoContentSkip,
        categoryID: focusedContentSubLayer?.data?._id,
        keyword: searchKeyword,
        hospitalID,
      }),
  });

  useEffect(() => {
    setContents([]);
    setVideoContentSkip(0);
    setNextPageEnabled(true);
    setHasLoaded(false);

    resetSelectedVideoContent();
  }, [searchKeyword, focusedContentSubLayer?.key]);

  useEffect(() => {
    if (searchKeyword !== '') {
      setSearchKeyword('');
    }
  }, [focusedContentSubLayer?.key]);

  useEffect(() => {
    if (data) {
      setNextPageEnabled(data.length === 20);
      if (data.length > 0) {
        setContents((prev) => [...prev, ...data]);
      }
      setHasLoaded(true);
    } else if (isError) {
      setHasLoaded(true);
    }
  }, [data, isError]);

  return (
    <div className='flex min-h-screen w-[750px] flex-col bg-white50'>
      <VideoContentTitle />
      <Divider type='line' />
      <VideoContentSearchInput searchKeyword={searchKeyword} setSearchKeyword={setSearchKeyword} />

      {contents.length === 0 && !isLoading && hasLoaded && (
        <ContentNoDataMessage searchKeyword={searchKeyword} />
      )}

      {contents.length > 0 && (
        <VideoContentList contents={contents} searchKeyword={searchKeyword} />
      )}
    </div>
  );
}
