import PanelList from '@afterdoc-design-system/components/Atoms/PanelList/PanelList';
import Scrollbar from '@afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import NoData from '@afterdoc-design-system/components/Molecules/NoData/NoData';
import { apiClient } from '@apis/instances/api-client';
import type {
  ApiServiceSettingsElFindOneParams,
  ApiVideocontentcategoryElFindParams,
  VideoContentCategoryParentInfos,
} from '@apis/swaggers/swagger-docs';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { useSetAtom } from 'jotai';
import { useCallback, useEffect, useMemo } from 'react';
import { SHARED_UTILS } from 'utils/utils';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import type {
  ContentLayer,
  ContentSubLayer,
} from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/components/Forms/WorkoutVideoContentForm/components/WorkoutVideoContentSelectDialog/components/WorkoutVideoContentPanel/constants/panel-types';
import { focusedContentSubLayerState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/components/Forms/WorkoutVideoContentForm/components/WorkoutVideoContentSelectDialog/components/WorkoutVideoContentPanel/states/focused-content-sub-layer-state';

const fetchServiceSetting = async (params: ApiServiceSettingsElFindOneParams) => {
  const response = await apiClient.v3.apiServiceSettingsElFindOne(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const fetchVideoContentCategories = async (params: ApiVideocontentcategoryElFindParams) => {
  const response = await apiClient.v3.apiVideocontentcategoryElFind(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

// 카테고리에서 ContentSubLayer 아이템을 생성
const createContentItem = (category: VideoContentCategoryParentInfos): ContentSubLayer => ({
  text: category.categoryName || '',
  categoryID: category._id || '',
});

// 부모 카테고리명에 따라 ContentLayer를 빌드
const buildContentPanelItems = (categories: VideoContentCategoryParentInfos[]): ContentLayer[] => {
  const layersMap = new Map<string, ContentLayer>();

  for (const category of categories) {
    const parentCategories = category.parentCategoryIDs ?? [];
    for (const parent of parentCategories) {
      const parentName = parent.categoryName || ''; // 부모 카테고리명 확인

      // layersMap에 없으면 새로운 ContentLayer 생성
      if (!layersMap.has(parentName)) {
        layersMap.set(parentName, { title: parentName, items: [], state: 'default' });
      }

      // 해당 부모 카테고리에 아이템 추가
      layersMap.get(parentName)?.items.push(createContentItem(category));
    }
  }

  return Array.from(layersMap.values());
};

export default function WorkoutVideoContentPanel() {
  const { hospitalID } = useSelectedHospitalInfo();
  const setFocusedContentSubLayer = useSetAtom(focusedContentSubLayerState);

  const { data: serviceSettings } = useSuspenseQuery({
    queryKey: [
      QUERY_KEY.apiServiceSettingsElFindOne,
      {
        hospitalID,
      },
    ] as const,
    queryFn: ({ queryKey }) => fetchServiceSetting(queryKey[1]),
  });

  const uniqueParentCategoryIDs = useMemo(() => {
    return [
      ...new Set(
        serviceSettings?.usingAutoSendVideo
          ?.map((video) => video._id)
          .filter((id): id is string => id !== undefined) ?? [],
      ),
    ];
  }, [serviceSettings]);

  const { data: categories } = useQuery({
    queryKey: [QUERY_KEY.apiVideocontentcategoryElFind, uniqueParentCategoryIDs],
    queryFn: () =>
      serviceSettings
        ? fetchVideoContentCategories({
            isTop: false,
            isParent: true,
            'parentCategoryIDs[]': uniqueParentCategoryIDs,
          })
        : null,
    enabled: !!serviceSettings && uniqueParentCategoryIDs.length > 0,
  });

  const contentPanelLayer = useMemo(() => {
    const panels = buildContentPanelItems(categories ?? []);

    // 첫 번째 Layer와 첫 번째 SubLayer에 'focus' 상태 부여
    if (panels.length > 0 && panels[0].items.length > 0) {
      panels[0].state = 'focus';
      panels[0].items[0].state = 'focus';
    }

    return panels;
  }, [categories]);

  const handleClick = useCallback(
    (layerIndex: number, itemIndex: number) => {
      setFocusedContentSubLayer(contentPanelLayer[layerIndex].items[itemIndex]);
    },
    [contentPanelLayer, setFocusedContentSubLayer],
  );

  useEffect(() => {
    setFocusedContentSubLayer(contentPanelLayer[0]?.items[0]);
  }, [contentPanelLayer, setFocusedContentSubLayer]);

  if (!serviceSettings || !serviceSettings.usingAutoSendVideo?.length || !categories?.length) {
    return (
      <div className='flex-full-center'>
        <NoData
          iconProps={{
            name: 'warning',
            size: 48,
            color: 'white600',
          }}
          title='등록된 콘텐츠가 없습니다.'
        />
      </div>
    );
  }

  return (
    <div className='h-[calc(100vh-220px)] w-full max-w-[180px] truncate border-r border-r-white400 bg-white50'>
      <Scrollbar>
        <PanelList
          layers={contentPanelLayer}
          onItemClick={handleClick}
          onLayerClick={(index) => {
            handleClick(index, 0);
          }}
          subLayerClassName='px-0 pl-32 [&>#sub-layer-text]:truncate'
        />
      </Scrollbar>
    </div>
  );
}
