export type ALResponse<T> = {
  data: T;
  status: number;
};

export type SmartdoctorCustomer = {
  _id?: string;
  customerNumber: string;
  name: string;
  citizenNumber: string;
  sex: string;
  address1: string;
  address2: string;
  cellPhone: string;
  emailOrPassportNumber: string;
  zipCode: string;
  memo: string;
  isForeign: true;
  isAgreementAccepted: true;
  isSmsDenied: true;
  isAdvertiseSmsDenied: true;
  discountPolicyCode: string;
  nationalityCode: string;
  isSpecial: true;
  telephone: string;
  age: number;
  managerId: string;

  isSelected: boolean;
};
export type SmartdoctorCustomerResponse = {
  customer: SmartdoctorCustomer;
};
export type SmartdoctorCustomersResponse = {
  customers: [SmartdoctorCustomer];
};

export type ALIntegrationUserData = {
  _id: string;
  name: string;
  patientId: string;
  chartNumber: string;
  birthdate: string;
  gender: string;
  phoneNumber: string;
  ALCustomerNumber?: string;
  ALCustomerNumbers?: [string];
  isSelected: boolean;
  isRepresented: boolean;
  isDuplicated?: boolean;
};

export const defaultData = {
  _id: '',
  name: '-',
  patientId: '-',
  chartNumber: '-',
  birthdate: '-',
  gender: '-',
  phoneNumber: '-',
  ALCustomerNumber: undefined,
  ALCustomerNumbers: undefined,
  isRepresented: false,
  isSelected: false,
};

export const defaultArray: ALIntegrationUserData[] = [];

export type responseDataDuplicatedCustomerNumber = {
  patientId: string;
  birthdate: string;
  phone: string;
  _id: string;
  id: string;
  ALCustomerNumbers: number[];
  userTagIDs: string[];
  isRun: boolean;
  userID: string;
  name: string;
  birth: string;
  gender: string;
  linkType: string;
  ALCustomerNumber: string;
  lastLogin: string;
  chartNumber: string;
  createdAt: string;
};
