import ChatIsBookmarkListItem from '@templates/CustomerChat/components/SupportBot/components/ChatIsBookmarkListItem';
import NoSupportBotContents from '@templates/CustomerChat/components/SupportBot/components/NoSuportBotContents/NoSupportBotContents';
import { useInfiniteChatIsBookmarkData } from '@templates/CustomerChat/components/SupportBot/hooks/use-infinite-chat-is-bookmark-data';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import { useEffect } from 'react';

interface BookmarkListProps {
  onChangeLoadingState: (isVisible: boolean) => void;
}

export default function BookmarkList({ onChangeLoadingState }: BookmarkListProps) {
  const { data: chatRooms, isLoading, isFetchingNextPage } = useInfiniteChatIsBookmarkData();

  useEffect(() => {
    onChangeLoadingState(isLoading || isFetchingNextPage);
  }, [isLoading, isFetchingNextPage]);

  if (!isLoading && chatRooms.length === 0) return <NoSupportBotContents />;

  return (
    <>
      <Scrollbar disabledX>
        <div className='mx-16 flex flex-col gap-10'>
          {chatRooms.map((item) => (
            <ChatIsBookmarkListItem key={item._id} data={item} />
          ))}
        </div>
      </Scrollbar>
    </>
  );
}
