import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import TextEditor from 'web/shared/components/TextEditor/TextEditor';
import { useUserInfo } from 'web/shared/hooks/use-user-info';
import type { UpdateSingleManualAPIFormValues } from 'web/templates/HospitalManual/components/HospitalManualContent/HospitalManualContent';

interface ManualContentEditorProps {
  defaultManualContent?: string;
}

export default function ManualContentEditor({ defaultManualContent }: ManualContentEditorProps) {
  const { control, setValue } = useFormContext<UpdateSingleManualAPIFormValues>();
  const { authorizationTypeID } = useUserInfo();

  const canUserCreateManual = authorizationTypeID?.canCreateManual;

  useEffect(() => {
    setValue('manualContent', defaultManualContent ?? '');
  }, [defaultManualContent, setValue]);

  return (
    <div className='pt-30'>
      <Controller
        control={control}
        name='manualContent'
        render={({ field: { onChange, value } }) => (
          <TextEditor
            disabled={!canUserCreateManual}
            height={350}
            value={value}
            onChange={onChange}
          />
        )}
      />
    </div>
  );
}
