import type { ProgressBarProps } from '@afterdoc-design-system/components/Molecules/ProgressBar/ProgressBar';
import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useRef, useState } from 'react';
import type { File } from 'web/apis/swaggers/swagger-docs';
import { bytesToMB } from 'web/shared/utils/bytesToMB';
import {
  chatFileDownloadHistoryAtom,
  chatFileProgressAtom,
  deleteFileProgressAtom,
  setFileProgressAtom,
} from '../../../states/chatFileProgress';
import { useChatMessageContext } from '../ChatMessageContext';
import { MESSAGE_TYPE_CODE } from '../constants/message';
import { RenderingDownloadIcon } from './components/RenderingDownloadIcon';

// TODO: electron에서의 removeListeners 함수 작업 후 removeAllListeners를 removeListeners 다시 변경
export default function FileDownloadContainer({ id, fileID }: { id: string; fileID: File }) {
  if (!fileID.aws) return null;
  const { type } = useChatMessageContext();
  const isMockingMessage = type === MESSAGE_TYPE_CODE.FILE_MOCKING;

  const chatFileDownloadHistory = useAtomValue(chatFileDownloadHistoryAtom);
  const chatFileProgress = useAtomValue(chatFileProgressAtom);
  const updateFileProgress = useSetAtom(setFileProgressAtom);
  const deleteFileProgress = useSetAtom(deleteFileProgressAtom);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { fileName, Location } = fileID.aws[0];

  const fileSize = fileID.aws[0]?.fileSize;
  const fileSizeMB = bytesToMB(fileSize);
  const fileProgress = chatFileProgress.get(id);
  const isComplete = chatFileDownloadHistory?.get(id)?.isComplete ?? false;
  const triggerCancel = fileProgress?.triggerCancel ?? false;
  const triggerReload = fileProgress?.triggerReload ?? false;

  /*
   * download
   */
  const fileNameRef = useRef<string | null>(null);
  const fileBlobRef = useRef<Blob | null>(null);
  const fileUrlRef = useRef<string | null>(null);
  const [status, setStatus] = useState<ProgressBarProps['status']>();
  const [progress, setProgress] = useState(0);
  const [isCanceled, setIsCanceled] = useState(false);
  const [filePath, setFilePath] = useState<string>();

  const startDownload = async (
    source: Blob | string,
    fileName: string,
    filters: { name: string; extensions: string[] }[],
  ) => {
    addEventListeners();
    if (typeof source === 'string') {
      fileUrlRef.current = source;
      fileBlobRef.current = null;
    } else {
      fileBlobRef.current = source;
      fileUrlRef.current = null;
    }

    fileNameRef.current = fileName;

    window.electron?.ipcRenderer.send('Application.startFileDownload', {
      title: 'Download File',
      fileName,
      filters,
    });
  };

  const downloadFileAfterSaveDialog = async () => {
    if (!fileBlobRef.current && !fileUrlRef.current) return;
    if (isLoading === true) {
      return;
    }

    setIsLoading(true);

    setIsCanceled(false);
    setStatus('downloading');
    setProgress(0);

    let offset = 0;
    const CHUNK_SIZE = 1024 * 1024;
    const sendChunk = async () => {
      if (isCanceled) return;

      if (!fileBlobRef.current && fileUrlRef.current) {
        const response = await fetch(fileUrlRef.current);
        fileBlobRef.current = await response.blob();
      }

      const blob = fileBlobRef.current;
      if (!blob) return;
      const totalSize = blob.size;

      if (offset >= totalSize) {
        window.electron?.ipcRenderer.send('Application.downloadFileEnd');
        return;
      }

      const chunk = blob.slice(offset, offset + CHUNK_SIZE);
      const reader = new FileReader();

      reader.onload = () => {
        if (isCanceled) return;

        const chunkData = reader.result;
        if (chunkData) {
          const progress = Math.floor((offset / totalSize) * 100);
          window.electron?.ipcRenderer.send('Application.downloadFileChunk', {
            chunk: chunkData,
            progress,
          });
          setProgress(progress);
          offset += CHUNK_SIZE;
          sendChunk();
        }
      };

      reader.onerror = () => {
        console.error('Error reading file chunk');
      };

      reader.readAsArrayBuffer(chunk);
    };

    sendChunk();
  };

  const listeners = {
    writeStreamCreated: () => {
      downloadFileAfterSaveDialog();
    },
    fileSaveDialogResult: (_: {}, path?: string) => {
      setStatus(path ? 'complete' : 'error');
      setFilePath(path);
    },
    openFileLocatedFolderResult: (_: {}, { success }: { success: boolean }) => {
      if (!success) {
        console.error('Error opening folder');
      }
    },
    cancelDownloadCompleted: (_: {}, { success }: { success: boolean }) => {
      if (!success) {
        console.error('Error opening folder');
      }
    },
  };

  const cancelDownload = () => {
    setIsCanceled(true);
    setStatus('error');
    window.electron?.ipcRenderer.send('Application.cancelFileDownload');
  };
  const addEventListeners = () => {
    window.electron?.ipcRenderer.on('Application.writeStreamCreated', listeners.writeStreamCreated);
    window.electron?.ipcRenderer.on(
      'Application.fileSaveDialogResult',
      listeners.fileSaveDialogResult,
    );
    window.electron?.ipcRenderer.on(
      'Application.openFileLocatedFolderResult',
      listeners.openFileLocatedFolderResult,
    );
    window.electron?.ipcRenderer.on(
      'Application.cancelDownloadCompleted',
      listeners.cancelDownloadCompleted,
    );
  };
  const removeEventListeners = () => {
    window.electron?.ipcRenderer.removeAllListeners(
      'Application.writeStreamCreated',
      // listeners.writeStreamCreated,
    );
    window.electron?.ipcRenderer.removeAllListeners(
      'Application.fileSaveDialogResult',
      // listeners.fileSaveDialogResult,
    );
    window.electron?.ipcRenderer.removeAllListeners(
      'Application.openFileLocatedFolderResult',
      // listeners.openFileLocatedFolderResult,
    );
    window.electron?.ipcRenderer.removeAllListeners(
      'Application.cancelDownloadCompleted',
      // listeners.cancelDownloadCompleted,
    );
  };
  /* download end */

  useEffect(() => {
    if (progress && isLoading === true) {
      updateFileProgress({ id, fileName, progress, filePath });
    }
  }, [progress, fileName, filePath]);
  useEffect(() => {
    if (status === 'complete') {
      removeEventListeners();
      setIsLoading(false);
      updateFileProgress({ id, fileName, progress: 100, filePath });
    }
    if (status === 'error') {
      removeEventListeners();
      setIsLoading(false);
    }
  }, [status]);

  useEffect(() => {
    if (triggerCancel) {
      cancelDownload();
      deleteFileProgress(id);
    }
  }, [triggerCancel, deleteFileProgress]);

  //Only Reload download
  useEffect(() => {
    if (triggerReload) {
      startDownload(Location, fileName, [{ name: fileName, extensions: ['*'] }]);
      // download(Location, fileName);
    }
  }, [triggerReload]);

  return (
    <div className='line-clamp-2 flex-center justify-between'>
      <div className='flex-center gap-10'>
        <div
          className='box-border h-36 w-36 flex-center flex-shrink-0 cursor-pointer rounded-r10 border border-white400 bg-white100 p-6'
          onClick={() =>
            !isMockingMessage &&
            startDownload(Location, fileName, [{ name: fileName, extensions: ['*'] }])
          }>
          <RenderingDownloadIcon isComplete={isComplete} percentage={progress} />
        </div>
        <div className='text-Body13'>{fileName}</div>
      </div>
      <div className='text-Body12 text-black200'>{fileSizeMB ? `${fileSizeMB}MB` : null}</div>
    </div>
  );
}
