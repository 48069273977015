import type {
  FetchPreviousPageOptions,
  InfiniteData,
  InfiniteQueryObserverResult,
} from '@tanstack/react-query';
import { debounce } from 'lodash-es';
import { type MutableRefObject, useEffect, useRef } from 'react';
import type { ChatFileAndContentIDs } from 'web/apis/swaggers/swagger-docs';

type UseTopScrollObserverProps = {
  hasPreviousPage: boolean;
  fetchPreviousPage: (
    options?: FetchPreviousPageOptions,
  ) => Promise<InfiniteQueryObserverResult<InfiniteData<ChatFileAndContentIDs[], unknown>, Error>>;
  isLoading: boolean;
  chatContainerRef: MutableRefObject<HTMLDivElement | null>;
  loadPreviousMoreRef: MutableRefObject<HTMLDivElement | null>;
  setPreviousScrollHeight: React.Dispatch<React.SetStateAction<number>>;
};

//아래의 부분은 .withDebounce를 사용하기 위한 실험적 Function prototype 확장
declare global {
  interface Function {
    withDebounce(options: { wait: number }): Function;
    withSetTimeout(delay: number): void;
  }
}

const extendFunctionPrototypeForDebounce = () => {
  if (!Function.prototype.withDebounce) {
    Function.prototype.withDebounce = function ({ wait = 1000 }) {
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      return debounce((...args: any[]) => {
        return this.apply(this, args);
      }, wait);
    };
  }
  if (!Function.prototype.withSetTimeout) {
    Function.prototype.withSetTimeout = function (delay) {
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      return setTimeout((...args: any[]) => {
        this.apply(this, args);
      }, delay);
    };
  }
};

extendFunctionPrototypeForDebounce();

//참고로 스크롤의 값들은 뒤집어져서 위로 올릴수록 0부터 -로 절대값은 커지고 값은 작아지는 환경
export const useTopScrollObserver = ({
  fetchPreviousPage,
  isLoading,
  chatContainerRef,
  loadPreviousMoreRef,
  setPreviousScrollHeight,
}: UseTopScrollObserverProps) => {
  const observerRef = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!chatContainerRef.current || isLoading) return;

      setPreviousScrollHeight(chatContainerRef.current.scrollHeight);
      await fetchPreviousPage();
    };

    const debouncedFetch = debounce(fetchData, 300, {
      leading: true,
      trailing: true,
    });

    observerRef.current = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting && entry.target === loadPreviousMoreRef.current) {
            void debouncedFetch();
          }
        }
      },
      {
        root: chatContainerRef.current,
        rootMargin: '100px 0px',
        threshold: 0.1,
      },
    );

    if (loadPreviousMoreRef.current) {
      observerRef.current.observe(loadPreviousMoreRef.current);
    }

    return () => {
      debouncedFetch.cancel();

      if (observerRef.current && loadPreviousMoreRef.current) {
        observerRef.current.unobserve(loadPreviousMoreRef.current);
        observerRef.current.disconnect();
      }
    };
  }, [
    isLoading,
    fetchPreviousPage,
    chatContainerRef,
    loadPreviousMoreRef,
    setPreviousScrollHeight,
  ]);
};
