import { useNotificationSetting } from '@templates/UserInfoSetting/containers/NotificationSetting/hooks/use-notification-setting';
import type { BooleanSettingKey } from '@templates/UserInfoSetting/containers/NotificationSetting/types/res-notification-setting-type';
import Toggle from 'afterdoc-design-system/components/Atoms/Toggle/Toggle';

export type ItemType = {
  key: BooleanSettingKey;
  label: string;
};

interface NotificationSettingItemProps {
  fieldKey: BooleanSettingKey;
  label: string;
  children?: React.ReactNode;
}

export default function NotificationSettingItem({
  fieldKey,
  label,
  children,
}: NotificationSettingItemProps) {
  const { getValue, setValue } = useNotificationSetting();

  const fieldValue = getValue(fieldKey) ?? false;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(fieldKey, e.target.checked);
  };

  return (
    <div className='min-h-52 flex-shrink-0 flex-grow gap-10 rounded-r16 border border-white600 px-20 py-16'>
      <div className='flex items-center'>
        <span className='text-Body13 text-black500'>{label}</span>
        <Toggle
          toggleSize='big'
          checked={fieldValue}
          wrapperClassName='py-0 min-h-20 ml-auto'
          onChange={handleChange}
        />
      </div>
      {fieldValue && children}
    </div>
  );
}
