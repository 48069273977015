import axios from 'axios';

const kakaoIntegrationAxiosInstance = axios.create({
  timeout: 30000,
  // baseURL: 'http://192.168.0.100:8080',
  baseURL: 'https://ikakao.afterdoc.net',
  headers: {
    'Content-Type': 'application/json',
  },
});

kakaoIntegrationAxiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');

    if (token) {
      config.headers.Authorization = JSON.parse(token);
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export { kakaoIntegrationAxiosInstance };
