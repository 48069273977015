import { SHARED_UTILS } from '@shared-utils/utils';
import { useQuery } from '@tanstack/react-query';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type {
  ApiEventHospitalAftercareElFindSimpleParams,
  ApiEventHospitalEventElFindSimpleParams,
} from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';

const getEvent = async (query: ApiEventHospitalEventElFindSimpleParams) => {
  const { data } = await apiClient.v3.apiEventHospitalEventElFindSimple(query);
  return SHARED_UTILS.api.checkApiResponse(data);
};

const getHomecare = async (query: ApiEventHospitalAftercareElFindSimpleParams) => {
  const { data } = await apiClient.v3.apiEventHospitalAftercareElFindSimple(query);
  return SHARED_UTILS.api.checkApiResponse(data);
};

export const useEventList = ({
  keyword,
  categoryTitle,
}: { keyword?: string; categoryTitle?: string; categoryID?: string }) => {
  const { hospitalID } = useSelectedHospitalInfo();
  const query = {
    hospitalID,
    isActive: true,
    isRemoved: false,
    ...(keyword && { keyword }),
  };

  const isEvent = categoryTitle === '이벤트';

  const { data, error, isLoading } = useQuery({
    queryKey: [
      isEvent
        ? QUERY_KEY.apiEventHospitalEventElFindSimple
        : QUERY_KEY.apiEventHospitalAftercareElFindSimple,
      query,
    ] as const,
    queryFn: ({ queryKey }) => (isEvent ? getEvent(queryKey[1]) : getHomecare(queryKey[1])),
  });

  const newData =
    data?.map(({ _id, title, price, representImage, salePrice, saleRate, saleType }) => {
      const { paths } = representImage ?? {};
      return {
        id: _id ?? '',
        price,
        salePrice,
        saleRate,
        saleType,
        url: paths?.[0] ?? null,
        title,
      };
    }) ?? [];

  return {
    data: newData ?? [],
    error,
    isLoading,
  };
};
