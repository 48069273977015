import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';

export interface ContentEditErrorsState {
  title?: string;
  price?: string;
  date?: string;
  description?: string;
  thumbnailImage?: string;
  contentImage?: string;
  contentText?: string;
}

export const contentEditErrorsState = atomWithReset<ContentEditErrorsState>({});

export const selectedContentEditTitleErrorAtom = atom(
  (get) => get(contentEditErrorsState).title,
  (get, set, value: string | undefined) => {
    const currentState = get(contentEditErrorsState);
    set(contentEditErrorsState, {
      ...currentState,
      title: value || undefined,
    });
  },
);

export const selectedContentEditPriceErrorAtom = atom(
  (get) => get(contentEditErrorsState).price,
  (get, set, value: string | undefined) => {
    const currentState = get(contentEditErrorsState);
    set(contentEditErrorsState, {
      ...currentState,
      price: value || undefined,
    });
  },
);

export const selectedContentEditDateErrorAtom = atom(
  (get) => get(contentEditErrorsState).date,
  (get, set, value: string | undefined) => {
    const currentState = get(contentEditErrorsState);
    set(contentEditErrorsState, {
      ...currentState,
      date: value || undefined,
    });
  },
);

export const selectedThumbnailImageErrorAtom = atom(
  (get) => get(contentEditErrorsState).thumbnailImage,
  (get, set, value: string | undefined) => {
    const currentState = get(contentEditErrorsState);
    set(contentEditErrorsState, {
      ...currentState,
      thumbnailImage: value || undefined,
    });
  },
);

export const selectedContentImageErrorAtom = atom(
  (get) => get(contentEditErrorsState).contentImage,
  (get, set, value: string | undefined) => {
    const currentState = get(contentEditErrorsState);
    set(contentEditErrorsState, {
      ...currentState,
      contentImage: value || undefined,
    });
  },
);

export const selectedContentTextErrorAtom = atom(
  (get) => get(contentEditErrorsState).contentText,
  (get, set, value: string | undefined) => {
    const currentState = get(contentEditErrorsState);
    set(contentEditErrorsState, {
      ...currentState,
      contentText: value || undefined,
    });
  },
);
