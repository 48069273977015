import LoadingSpinner from '@afterdoc-design-system/components/Atoms/Loading/LoadingSpinner';
import Scrollbar from '@afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import { SHARED_UTILS } from '@shared-utils/utils';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type {
  ApiPatientsElTemporaryListOrSearchParams,
  ApiServiceSettingsElCountriesData,
  ApiServiceSettingsElToBeDisplayedPatientsFieldsData,
  ApiTreatmentTagsElData,
} from 'web/apis/swaggers/swagger-docs';
import FullLoading from 'web/shared/components/FullLoading/FullLoading';
import { useNavigationBlocker } from 'web/shared/hooks/use-navigation-blocker';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import CustomerManagementEditableTableFooter from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementEditableTableBody/components/CustomerManagementEditableTableFooter';
import { useTemporaryPatientsLock } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementSaveFooter/utils/ use-temporary-patients-lock';
import { TOTAL_PATIENT_ROW_COUNT } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/constants/total-patient-row-count';
import { customerTableDisplayModeState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/states/customer-management-display-mode';
import { editableHeaderFilterState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/states/editable-header-filter';
import type { CustomerManagementTemporaryAPIFormValues } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/types/table';
import { calculateEmptyRows } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/utils/calculate-empty-rows';
import { createInitialRows } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/utils/create-initial-rows';
import { CUSTOMER_SEARCH_FIELD_OPTIONS } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/containers/SearchFilter/constants/dropdown-items';
import { searchTextState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/containers/SearchFilter/states/search';
import { selectedSearchCriteriaIndexState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/containers/SearchFilter/states/selected-search-criteria';
import { useIntersectionObserver } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/hooks/use-intersection-observer';
import { useResetTable } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/hooks/use-reset-table';
import { useVirtualScroll } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/hooks/use-virtual-scroll';
import CustomerManagementEditableTableBody from '../components/CustomerManagementEditableTableBody/CustomerManagementEditableTableBody';

const nullIfEmpty = (value: string | undefined | null): string | null =>
  value && value.trim().length > 0 ? value.trim() : null;

const fetchPatientsTemporaryList = async ({
  pageParam = undefined,
  ...params
}: {
  pageParam?: string;
} & ApiPatientsElTemporaryListOrSearchParams) => {
  const response = await apiClient.v3.apiPatientsElTemporaryListOrSearch({
    cursor: pageParam,
    ...params,
  });
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

type TableContainerProps = {
  countriesList: ApiServiceSettingsElCountriesData['data'];
  treatmentTags: ApiTreatmentTagsElData['data']['treatmentTags'];
} & ApiServiceSettingsElToBeDisplayedPatientsFieldsData['data'];

export default function CustomerManagementEditableTableBodyContainer({
  countriesList,
  treatmentTags,
  ...usingColumns
}: TableContainerProps) {
  const { setValue, watch } = useFormContext<{
    rows: CustomerManagementTemporaryAPIFormValues;
  }>();
  const rows = watch('rows');

  const { hospitalID } = useSelectedHospitalInfo();
  const rowHeight = useRef(40);
  const loadMoreRef = useRef<HTMLDivElement | null>(null);
  const virtualScrollData = useVirtualScroll({
    rows,
    rowHeight: rowHeight.current,
    offset: 196,
  });

  const customerTableDisplayMode = useAtomValue(customerTableDisplayModeState);
  const editableHeaderFilter = useAtomValue(editableHeaderFilterState);
  const searchText = useAtomValue(searchTextState);
  const selectedSearchCriteriaIndex = useAtomValue(selectedSearchCriteriaIndexState);

  const params = {
    hospitalID,
    limit: TOTAL_PATIENT_ROW_COUNT,
  };

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isError, isLoading } =
    useInfiniteQuery({
      queryKey: [QUERY_KEY.apiPatientsElTemporaryListOrSearch, params],
      queryFn: ({ pageParam }: { pageParam?: string }) =>
        fetchPatientsTemporaryList({
          pageParam,
          ...params,
        }),
      getNextPageParam: (lastPage) => lastPage.nextCursor || undefined,
      initialPageParam: undefined,
      select: (data) => {
        const allPatients = data.pages.flatMap((page) =>
          page.patients?.map((patient) => ({
            order: patient.order,
            name: nullIfEmpty(patient.name),
            chartNumber: nullIfEmpty(patient.chartNumber),
            phoneNumber: nullIfEmpty(patient.phoneNumber),
            birthdate: nullIfEmpty(patient.birthdate),
            isFirstVisit: patient.isFirstVisit,
            gender: patient.gender,
            treatmentTagIds: patient.treatmentTags?.map((tag) => tag.id),
            nationalityId: patient.nationality?.id,
            defaultTreatmentTags: patient.treatmentTags,
            defaultNationality: patient.nationality,
          })),
        ) as CustomerManagementTemporaryAPIFormValues;

        const emptyRows = createInitialRows(
          calculateEmptyRows(allPatients.length),
          allPatients[allPatients.length - 1]?.order ?? 0,
          hospitalID,
        );

        const mergedPatients = [...allPatients, ...emptyRows];

        return {
          ...data,
          pages: [{ ...data.pages[0], patients: mergedPatients }],
        };
      },
    });

  const handleAddRows = (newRows: CustomerManagementTemporaryAPIFormValues) => {
    setValue('rows', [...rows, ...newRows]);
  };

  const addNew50Rows = () => {
    const new50Rows: CustomerManagementTemporaryAPIFormValues = Array.from(
      { length: TOTAL_PATIENT_ROW_COUNT },
      (_, index) => ({
        order: rows.length + index + 1,
        hospitalID,
        name: null,
        patientId: undefined,
        chartNumber: null,
        isFirstVisit: null,
        phoneNumber: null,
        birthdate: null,
        gender: null,
        treatmentTagIds: [],
        nationalityId: null,
      }),
    );
    handleAddRows(new50Rows);
  };

  const scrollContainerRef = useRef<
    (HTMLDivElement & { scrollTo: (index: number, behavior?: ScrollBehavior) => void }) | null
  >(null);

  const { resetTable } = useResetTable();
  const { getPatientsIsLocked, patchRequestLockMutation } = useTemporaryPatientsLock();

  const handleConfirm = async () => {
    const latestLockData = await getPatientsIsLocked({ hospitalID });
    if (!latestLockData) return;

    await patchRequestLockMutation.mutateAsync({
      hospitalID,
      isLock: false,
      version: latestLockData.version,
    });

    resetTable();
  };

  useNavigationBlocker({
    shouldBlock: customerTableDisplayMode === 'EDITOR',
    onConfirm: handleConfirm,
  });

  useIntersectionObserver({
    target: loadMoreRef.current,
    onIntersect: fetchNextPage,
    enabled: hasNextPage && !isError && !isLoading,
    root: scrollContainerRef.current,
    rootMargin: '0px 0px 100px 0px',
  });

  // 각 필터 함수를 메모이제이션
  const filterByFirstVisit = useCallback(
    (rows: CustomerManagementTemporaryAPIFormValues) => {
      if (editableHeaderFilter.isFirstVisit === undefined) {
        return rows;
      }

      if (editableHeaderFilter.isFirstVisit === 'all') {
        return rows.filter((row) => row.isFirstVisit === true || row.isFirstVisit === false);
      }

      return rows.filter((row) => row.isFirstVisit === editableHeaderFilter.isFirstVisit);
    },
    [editableHeaderFilter.isFirstVisit],
  );

  // const filterByCountryCode = useCallback(
  //   (rows: CustomerManagementTemporaryAPIFormValues) => {
  //     if (!editableHeaderFilter.countryCode) return rows;

  //     const countryIds = editableHeaderFilter.countryCode.split(',');
  //     return rows.filter((row) => row. && countryIds.includes(row.countryCode.id));
  //   },
  //   [editableHeaderFilter.countryCode],
  // );

  const filterByGender = useCallback(
    (rows: CustomerManagementTemporaryAPIFormValues) => {
      if (editableHeaderFilter.gender === undefined) {
        return rows;
      }

      if (editableHeaderFilter.gender === 'all') {
        // gender가 'MALE' 또는 'FEMALE'인 경우만 포함
        return rows.filter((row) => row.gender === 'MALE' || row.gender === 'FEMALE');
      }

      // 특정 성별만 선택된 경우
      return rows.filter((row) => row.gender === editableHeaderFilter.gender);
    },
    [editableHeaderFilter.gender],
  );

  const filterByTreatmentCategories = useCallback(
    (rows: CustomerManagementTemporaryAPIFormValues) => {
      if (!editableHeaderFilter.treatmentCategories) return rows;

      const categoryIds = editableHeaderFilter.treatmentCategories.split(',');

      return rows.filter((row) =>
        // row 전체를 유지하면서, treatmentTags 중에 매칭되는 것이 있는지만 확인
        row.treatmentTagIds?.some((tagId) => categoryIds.includes(tagId)),
      );
    },
    [editableHeaderFilter.treatmentCategories],
  );

  const filterByNationality = useCallback(
    (rows: CustomerManagementTemporaryAPIFormValues) => {
      if (!editableHeaderFilter.nationality) return rows;

      const nationalityIds = editableHeaderFilter.nationality.split(',');
      return rows.filter((row) => row.nationalityId && nationalityIds.includes(row.nationalityId));
    },
    [editableHeaderFilter.nationality],
  );

  // 검색 필터 함수
  const filterBySearch = useCallback(
    (rows: CustomerManagementTemporaryAPIFormValues) => {
      if (!searchText) return rows;

      const searchColumn = CUSTOMER_SEARCH_FIELD_OPTIONS[selectedSearchCriteriaIndex].key;
      const searchRegex = new RegExp(`.*${searchText}.*`, 'i');

      return rows.filter((row) => {
        const value = row[searchColumn as keyof typeof row];
        return value && searchRegex.test(String(value));
      });
    },
    [searchText, selectedSearchCriteriaIndex],
  );

  // 모든 필터 함수를 배열로 관리
  const filterFunctions = useMemo(
    () => [
      filterByFirstVisit,
      // filterByCountryCode,
      filterByGender,
      filterByTreatmentCategories,
      filterByNationality,
      filterBySearch,
    ],
    [
      filterByFirstVisit,
      // filterByCountryCode,
      filterByGender,
      filterByTreatmentCategories,
      filterByNationality,
      filterBySearch,
    ],
  );

  // 필터링된 rows를 메모이제이션
  const filteredRows = useMemo(() => {
    if (!data) return [];

    const allPatients = data.pages.flatMap((page) => page.patients);

    // 모든 필터를 순차적으로 적용
    const filtered = filterFunctions.reduce((rows, filterFn) => filterFn(rows), allPatients);

    // 검색 결과가 없을 때 토스트 메시지 표시
    if (
      filtered.length === 0 &&
      (searchText || Object.values(editableHeaderFilter).some((v) => v !== undefined))
    ) {
      return [];
    }

    // 필터링된 결과가 있을 때는 그대로 반환, 없을 때는 빈 행 추가
    if (
      filtered.length > 0 ||
      searchText ||
      Object.values(editableHeaderFilter).some((v) => v !== undefined)
    ) {
      return filtered;
    }

    return [
      ...allPatients,
      ...createInitialRows(
        calculateEmptyRows(allPatients.length),
        allPatients[allPatients.length - 1]?.order ?? 0,
        hospitalID,
      ),
    ];
  }, [data, filterFunctions]);

  // rows 업데이트
  useEffect(() => {
    setValue('rows', filteredRows);
  }, [filteredRows, setValue]);

  if (isLoading && !data) {
    return <FullLoading />;
  }

  return (
    <div data-component-id='customer-management-editable-table-body'>
      <Scrollbar
        className='customer-management-editable-scrollbar'
        onScroll={(scrollTop) => virtualScrollData.handleScroll(scrollTop)}
        style={{ height: `${window.innerHeight - 196}px` }}>
        <div style={{ height: `${virtualScrollData.totalHeight}px`, position: 'relative' }}>
          <table
            className='w-full table-fixed'
            style={{
              transform: `translateY(${virtualScrollData.offsetY}px)`,
            }}>
            <CustomerManagementEditableTableBody
              countriesList={countriesList}
              treatmentTagsList={treatmentTags}
              visibleRows={virtualScrollData.visibleRows}
              {...usingColumns}
            />
            {virtualScrollData.visibleRows.length > 0 && (
              <CustomerManagementEditableTableFooter
                handleAddRow={addNew50Rows}
                {...usingColumns}
              />
            )}
          </table>
          <div ref={loadMoreRef} className='absolute bottom-0 left-0 h-1 w-full' />
        </div>
      </Scrollbar>

      {isFetchingNextPage && (
        <div className='flex w-full justify-center'>
          <LoadingSpinner />
        </div>
      )}
    </div>
  );
}
