import OutlinedButton from '@afterdoc-design-system/components/Atoms/Button/OutlinedButton';
import HoverTooltip from '@afterdoc-design-system/components/Atoms/Tooltip/HoverTooltip';
import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';
import { dialogService } from '@afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import ParentDropdown from '@templates/CustomerChat/components/ParentDropDown/ParentDropdown';
import { useDropdown } from '@templates/CustomerChat/components/ParentDropDown/hooks/use-dropdown';
import { useAtomValue } from 'jotai';
import { useState } from 'react';
import ALIntegrationEdit from 'web/shared/components/ALIntegrationEdit/ALIntegrationEdit';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import { useChatTopPatient } from 'web/templates/CustomerChat/hooks/use-chat-top-patient';
import { chatRoomHserviceIDSelector } from 'web/templates/CustomerChat/states/selected-chat-room';
import { makePatientInfoStringDisplay } from '../functions/makePatientInfoStringDisplay';
import EnrollDialog from './components/EnrollDialog/EnrollDialog';

export default function PatientTitle() {
  const { integrationSetting } = useSelectedHospitalInfo();
  const chatRoomHserivceID = useAtomValue(chatRoomHserviceIDSelector);
  const [isTooltipVisible, setIsTooltipVisible] = useState(true);

  const {
    isFirstVisit,
    name,
    chartNumber,
    isKakaoIcon,
    isColorAfterDocIcon,
    isGrayAfterDocIcon,
    iconSet,
    hasSmartDoctorLinkage,
  } = useChatTopPatient();

  const { isDropdownOpen, handleDropdown } = useDropdown();

  const dispalyNameDisplay = makePatientInfoStringDisplay({
    type: 'name',
    payload: {
      name,
    },
  });
  const chartNumberDisplay = makePatientInfoStringDisplay({
    type: 'chartNumber',
    payload: {
      chartNumber,
    },
  });
  const isFirstVisitDisplay = makePatientInfoStringDisplay({
    type: 'isFirstVisit',
    payload: {
      isFirstVisit,
    },
  });

  const handleMouseEnter = () => {
    setIsTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    setIsTooltipVisible(false);
  };

  const isVisitStatus = isKakaoIcon && !isColorAfterDocIcon && !isGrayAfterDocIcon;

  //카톡 고객 등록 버튼과 전능 연동 버튼은 공존 할 수 없음
  const isShowRegistButton =
    isKakaoIcon === true && isGrayAfterDocIcon === false && isColorAfterDocIcon === false;

  const isShowALMightyLinkButton =
    !hasSmartDoctorLinkage &&
    integrationSetting &&
    !integrationSetting.isRemoved &&
    integrationSetting.integrationType === 'ALM' &&
    integrationSetting.clientToken;

  return (
    <div className='mt-5 flex justify-between'>
      <div className='flex flex-row-center'>
        {!isVisitStatus && isFirstVisit !== null ? (
          <div className='mr-10 h-20 w-28 rounded-[5px] bg-blueLight pt-1 text-center text-Header12 text-blue500'>
            {isFirstVisitDisplay}
          </div>
        ) : null}
        <div className='flex flex-center gap-4'>
          <HoverTooltip
            show={isTooltipVisible}
            text={name}
            position='bottomRight'
            wrapperProps={{
              className: 'cursor-default',
            }}>
            <div
              className='text-Header16 text-black700 hover:cursor-pointer'
              onClick={handleDropdown}>
              <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                {dispalyNameDisplay}
              </div>
              {isDropdownOpen && (
                <ParentDropdown dropdownDataParams={iconSet} handleToggle={handleDropdown} />
              )}
            </div>
          </HoverTooltip>
          <HoverTooltip
            show={true}
            text={chartNumber}
            position='bottomRight'
            wrapperProps={{
              className: 'cursor-default',
            }}>
            {!isVisitStatus && <div className='text-Body10'>{chartNumberDisplay}</div>}
          </HoverTooltip>
        </div>
        <div className='ml-6 flex flex-center gap-6'>
          {isColorAfterDocIcon && <Icon name='afterdoc' />}
          {isGrayAfterDocIcon && <Icon name='afterdoc-non' />}
          {isKakaoIcon && <Icon name='kakaotalk' />}
        </div>
      </div>
      <div>
        {isShowRegistButton ? (
          <OutlinedButton
            onClick={() => {
              dialogService.push(<EnrollDialog />, {
                titleProps: {
                  title: '고객등록',
                },
                width: 384,
                wrapperClassName: 'h-fit mt-0 mb-0 overflow-visible',
              });
            }}>
            고객 등록
          </OutlinedButton>
        ) : (
          isShowALMightyLinkButton && (
            <OutlinedButton
              onClick={() => {
                dialogService.push(
                  <ALIntegrationEdit
                    patientId={chatRoomHserivceID}
                    dialogId={'ALIntegrationEdit'}
                  />,
                  {
                    width: 'auto',
                    id: 'ALIntegrationEdit',
                  },
                );
              }}>
              계정 연동
            </OutlinedButton>
          )
        )}
      </div>
    </div>
  );
}
