import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { type MutableRefObject, type ReactNode, memo, useEffect, useMemo, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import type {
  ApiServiceSettingsElCountriesData,
  ApiTreatmentTagsElData,
} from 'web/apis/swaggers/swagger-docs';
import { GENDER_DISPLAY, VISIT_DISPLAY } from 'web/shared/constants/options';
import type { UnTaggedCustomerManagementTableRowArr } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/UnTaggedCustomerManagement/containers/UnTaggedCustomerManagementEditableTable/types/table';
import useUnTaggedCustomerKeyboardNavigation from 'web/templates/CustomerManagement/containers/BoardPanel/containers/UnTaggedCustomerManagement/containers/hooks/use-untagged-customer-keyboard-navigation';
import MultipleTagsSelectBoxTagDropdownForTable from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/components/MultipleTagsSelectBoxTagDropdownForTable/MultipleTagsSelectBoxTagDropdownForTable';
import SingleTagSelectBoxTagDropdownForTable from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/components/SingleTagSelectBoxTagDropdownForTable/SingleTagSelectBoxTagDropdownForTable';
import { COLUMN_WIDTHS } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/constants/width';
import type { useVirtualScroll } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/hooks/use-virtual-scroll';
import './UnTaggedCustomerManagementEditableTableBody.scss';

type UnTaggedCustomerManagementEditableTableBodyProps = {
  rows: UnTaggedCustomerManagementTableRowArr;
  countriesList: ApiServiceSettingsElCountriesData['data'];
  treatmentTagsList: ApiTreatmentTagsElData['data']['treatmentTags'];
  virtualScrollData: ReturnType<
    typeof useVirtualScroll<UnTaggedCustomerManagementTableRowArr[number]>
  >;
  inputRefs: MutableRefObject<(HTMLDivElement | HTMLInputElement | null)[][]>;
  setInputRef: (
    rowIndex: number,
    colIndex: number,
    element: HTMLInputElement | HTMLDivElement | null,
  ) => void;
};

type SelectedCell = {
  row: number;
  col: number;
};

type FocusedCell = {
  row: number | null;
  col: number | null;
};

const DisabledTableCell = ({
  value,
  placeholder,
  className,
}: { value?: string; placeholder?: string; className?: string }) => (
  <td className={customTwMerge('border-line-table border-y border-r bg-disabled', className)}>
    <input
      type='text'
      placeholder={placeholder}
      disabled={true}
      value={value ?? ''}
      className={customTwMerge(
        'w-full bg-disabled px-12 py-11 text-Body12 placeholder-white600 outline-none placeholder:text-white600',
      )}
    />
  </td>
);

const renderIsSelected = (rowIndex: number, colIndex: number, selectedCell: SelectedCell) => {
  return selectedCell.row === rowIndex && selectedCell.col === colIndex;
};

const renderIsFocused = (rowIndex: number, colIndex: number, focusedCell: FocusedCell) => {
  return focusedCell.row === rowIndex && focusedCell.col === colIndex;
};

const getTableCellProps = (
  rowIndex: number,
  colIndex: number,
  selectedCell: SelectedCell,
  onClick: () => void,
  className?: string,
) => ({
  className: `group border-line-table border-y border-r text-Body12 hover:bg-blueLight ${
    renderIsSelected(rowIndex, colIndex, selectedCell)
      ? '-outline-offset-1 bg-blue50 outline outline-1 outline-blue500'
      : ''
  } ${className}`,
  onClick,
});

const TableCell = ({
  rowIndex,
  colIndex,
  selectedCell,
  handleCellClick,
  children,
  className,
}: {
  rowIndex: number;
  colIndex: number;
  selectedCell: SelectedCell;
  handleCellClick: () => void;
  children: ReactNode;
  className?: string;
}) => (
  <td {...getTableCellProps(rowIndex, colIndex, selectedCell, handleCellClick, className)}>
    {children}
  </td>
);

function UnTaggedCustomerManagementEditableTableBody({
  rows,
  countriesList,
  treatmentTagsList,
  virtualScrollData,
  inputRefs,
  setInputRef,
}: UnTaggedCustomerManagementEditableTableBodyProps) {
  const { visibleRows } = virtualScrollData;
  const contentEditableRefs = useRef<Array<HTMLDivElement | null>>([]);

  const {
    selectedCell,
    focusedCell,
    handleCellClick,
    handleChangeFocusedCell,
    handleChangeSelectedCell,
    resetFocusedCell,
  } = useUnTaggedCustomerKeyboardNavigation(
    {
      maxRows: rows.length,
    },
    virtualScrollData,
  );

  const { setValue } = useFormContext();

  const columnsToRender = useMemo(
    () => [
      { visible: true },
      { visible: true },
      { visible: true },
      { visible: true },
      { visible: true },
      { visible: true },
      { visible: true },
      { visible: true },
    ],
    [],
  );

  const activeColumns = useMemo(
    () =>
      columnsToRender
        .map((col, index) => (col.visible ? index : null))
        .filter((index) => index !== null),
    [columnsToRender],
  );

  useEffect(() => {
    // 선택된 셀이 없을 경우
    if (selectedCell.row === null || selectedCell.col === null) return;

    // 선택된 셀과 포커스된 셀이 일치할 때 포커스 설정
    const inputElement = inputRefs.current[selectedCell.row]?.[selectedCell.col];

    if (inputElement) {
      if (focusedCell.row === selectedCell.row && focusedCell.col === selectedCell.col) {
        inputElement.classList.remove('hidden-caret');
        inputElement.focus();
      } else {
        inputElement.classList.add('hidden-caret');
        inputElement.blur();
      }
    }
  }, [selectedCell, focusedCell, inputRefs]);

  useEffect(() => {
    if (
      inputRefs.current.length === rows.length &&
      inputRefs.current.every((ref) => ref !== null)
    ) {
    }
  }, [rows.length]);

  useEffect(() => {
    if (!rows) return;

    // 폼 리셋 후 contentEditable 필드 초기화
    for (const ref of contentEditableRefs.current) {
      if (ref) {
        ref.textContent = '';
      }
    }
  }, [rows]);

  return (
    <>
      <colgroup>
        <col className='w-[320px]' />
        <col className='w-[140px]' />
        <col className='w-[120px]' />
        <col className='w-[130px]' />
        <col className='w-[130px]' />
        <col className='w-[90px]' />
        <col className='min-w-[178px]' />
        <col className='w-[160px]' />
      </colgroup>
      <tbody>
        {visibleRows.map((row, rowIndex) => {
          const {
            name,
            chartNumber,
            isFirstVisit,
            // countryCode,
            phoneNumber,
            birthdate,
            gender,
            nationality,
            treatmentTags,
            patientId,
          } = row;

          const order = rowIndex + 1;

          return (
            <tr key={patientId}>
              {activeColumns.map((colIndex, index) => {
                const rowOrder = order ?? 0;

                switch (colIndex) {
                  case 0:
                    return (
                      <DisabledTableCell
                        key={`name-${rowOrder - 1}`}
                        value={name}
                        placeholder='예) 홍길동'
                        className={`w-[${COLUMN_WIDTHS.name}]`}
                      />
                    );

                  case 1:
                    return (
                      <DisabledTableCell
                        key={`chartNumber-${rowOrder - 1}`}
                        value={chartNumber}
                        placeholder='예) 00000'
                        className={`w-[${COLUMN_WIDTHS.chartNumber}]`}
                      />
                    );

                  case 2:
                    return (
                      <DisabledTableCell
                        key={`isFirstVisit-${rowOrder - 1}`}
                        placeholder='선택'
                        value={
                          isFirstVisit !== undefined && isFirstVisit !== null
                            ? isFirstVisit
                              ? VISIT_DISPLAY.FIRST_VISIT
                              : VISIT_DISPLAY.REVISIT
                            : '선택'
                        }
                      />
                    );

                  // case 3:
                  //   return (
                  //     <DisabledTableCell
                  //       key={`countryCode-${rowOrder - 1}`}
                  //       placeholder='선택'
                  //       value={
                  //         countryCode !== undefined && countryCode !== null
                  //           ? `${countryCode.koreanCountryName}(+${countryCode.internationalDialingCodes})`
                  //           : '선택'
                  //       }
                  //     />
                  //   );

                  case 3:
                    return (
                      <DisabledTableCell
                        key={`phoneNumber-${rowOrder - 1}`}
                        value={phoneNumber}
                        placeholder='예) 01012345678'
                        className={`w-[${COLUMN_WIDTHS.phoneNumber}]`}
                      />
                    );

                  case 4:
                    return (
                      <DisabledTableCell
                        key={`birthdate-${rowOrder - 1}`}
                        value={birthdate}
                        placeholder='예) YYYY-MM-DD'
                        className={`w-[${COLUMN_WIDTHS.birthdate}]`}
                      />
                    );

                  case 5:
                    return (
                      <DisabledTableCell
                        key={`gender-${rowOrder - 1}`}
                        placeholder='선택'
                        value={gender !== undefined ? GENDER_DISPLAY[gender] : '선택'}
                      />
                    );

                  case 6:
                    return (
                      <TableCell
                        key={`treatmentTagIds-${rowOrder - 1}`}
                        handleCellClick={() => handleCellClick(rowOrder - 1, index)}
                        rowIndex={rowOrder - 1}
                        selectedCell={selectedCell}
                        colIndex={index}>
                        <MultipleTagsSelectBoxTagDropdownForTable
                          id={`untagged-treatmentTagIds-${rowOrder - 1}`}
                          key={`treatmentTagIds-${rowOrder - 1}`}
                          ref={(el) => setInputRef(rowOrder - 1, index, el)}
                          isFocused={renderIsFocused(rowOrder - 1, index, focusedCell)}
                          isSelected={renderIsSelected(rowOrder - 1, index, selectedCell)}
                          placeholder={'예) 도수치료, 리프팅'}
                          onRemoveTag={() => {
                            handleChangeFocusedCell(rowOrder - 1, index);
                            handleChangeSelectedCell(rowOrder - 1, index);
                          }}
                          tagOptions={
                            treatmentTagsList?.map((tag) => ({
                              name: tag.name,
                              id: tag.tagId,
                              color: tag.color,
                            })) ?? []
                          }
                          defaultSelectedTags={treatmentTags?.map((tag) => ({
                            name: tag.name,
                            id: tag.tagId,
                            color: tag.color,
                          }))}
                          onSelectedTagsChange={(tags) => {
                            setValue(
                              `rows.${rowOrder - 1}.treatmentTagIds`,
                              tags.map((tag) => tag.id),
                              {
                                shouldDirty: true,
                                shouldTouch: true,
                              },
                            );
                          }}
                          handleClickOutside={() => {
                            resetFocusedCell();
                          }}
                        />
                      </TableCell>
                    );

                  case 7:
                    return (
                      <TableCell
                        key={`nationalityId-${rowOrder - 1}`}
                        handleCellClick={() => handleCellClick(rowOrder - 1, index)}
                        rowIndex={rowOrder - 1}
                        selectedCell={selectedCell}
                        colIndex={index}
                        className='w-[140px]'>
                        <SingleTagSelectBoxTagDropdownForTable
                          id={`untagged-nationalityId-${rowOrder - 1}`}
                          key={`nationalityId-${rowOrder - 1}`}
                          width={140}
                          onRemoveTag={() => {
                            handleChangeFocusedCell(rowOrder - 1, index);
                            handleChangeSelectedCell(rowOrder - 1, index);
                          }}
                          ref={(el) => setInputRef(rowOrder - 1, index, el)}
                          placeholder='선택'
                          isFocused={renderIsFocused(rowOrder - 1, index, focusedCell)}
                          isSelected={renderIsSelected(rowOrder - 1, index, selectedCell)}
                          defaultSelectedTag={
                            nationality !== undefined && nationality !== null
                              ? { name: nationality.koreanCountryName }
                              : undefined
                          }
                          tagOptions={countriesList.map((item) => ({
                            name: item.koreanCountryName ?? '',
                            id: item.countryId,
                          }))}
                          onSelectedTagChange={(tag) => {
                            if (tag?.id) {
                              setValue(`rows.${rowOrder - 1}.nationalityId`, tag.id, {
                                shouldDirty: true,
                                shouldTouch: true,
                              });
                            } else {
                              setValue(`rows.${rowOrder - 1}.nationalityId`, null, {
                                shouldDirty: true,
                                shouldTouch: true,
                              });
                            }
                          }}
                          handleClickOutside={resetFocusedCell}
                        />
                      </TableCell>
                    );

                  default:
                    return null;
                }
              })}
            </tr>
          );
        })}
      </tbody>
    </>
  );
}

export default memo(UnTaggedCustomerManagementEditableTableBody);
