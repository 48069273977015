import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import type { HospitalNoticePopupFilterFiles } from '@apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from '@shared/hooks/use-selected-hospital-info';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { CreateUpdatePopupParamType } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/types/create-update-popup-param-type';
import { fullDimmedLoadingService } from 'afterdoc-design-system/components/Atoms/Loading/FullDimmedLoading/FullDimmedLoading.service';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { modalService } from 'afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { SHARED_UTILS } from 'utils/utils';

type DetailPopupParam = Parameters<typeof apiClient.v3.apiPopupHospitalElFindDetail>[0];

type CreatePopupParam = Parameters<typeof apiClient.v3.apiPopupHospitalElCreate>[0];

type UpdatePopupParam = Parameters<typeof apiClient.v3.apiPopupHospitalElUpdate>[0];

type RemovePopupParam = Parameters<typeof apiClient.v3.apiPopupHospitalElRemove>[0];

const fetchHospitalPopupDetail = async (params: DetailPopupParam) => {
  const { data } = await apiClient.v3.apiPopupHospitalElFindDetail(params);
  return SHARED_UTILS.api.checkApiResponse(data);
};

const createHospitalPopup = async (params: CreatePopupParam) => {
  const { data } = await apiClient.v3.apiPopupHospitalElCreate(params);
  return SHARED_UTILS.api.checkApiResponse(data);
};

const updateHospitalPopup = async (params: UpdatePopupParam) => {
  const { data } = await apiClient.v3.apiPopupHospitalElUpdate(params);
  return SHARED_UTILS.api.checkApiResponse(data);
};

const removeHospitalPopup = async (params: RemovePopupParam) => {
  const { data } = await apiClient.v3.apiPopupHospitalElRemove(params);
  return SHARED_UTILS.api.checkApiResponse(data);
};

export const useHospitalPopup = () => {
  const queryClient = useQueryClient();
  const { hospitalID } = useSelectedHospitalInfo();

  // 팝업 상세
  const detailPopupMutation = useMutation({
    mutationFn: (params: DetailPopupParam) => fetchHospitalPopupDetail(params),
    onMutate: () => fullDimmedLoadingService.on(),
    onSettled: () => fullDimmedLoadingService.off(),
    onError: (error: Error) => {
      console.error('Error fetching hospital popup details', error);
    },
  });

  const detailPopupAction = (
    id: string,
    onSuccessCallback?: (data: HospitalNoticePopupFilterFiles) => void,
  ) => {
    detailPopupMutation.mutate({ _id: id }, { onSuccess: (data) => onSuccessCallback?.(data) });
  };

  // 팝업 생성
  const createPopupMutation = useMutation({
    mutationFn: (params: CreateUpdatePopupParamType) => {
      const { _id, ...restParams } = params;
      return createHospitalPopup({ ...restParams, hospitalID });
    },
    onMutate: () => fullDimmedLoadingService.on(),
    onSettled: () => fullDimmedLoadingService.off(),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.apiPopupHospitalElCount] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.apiPopupHospitalElFindSimple] });
      toastService.successMsg({ text: '팝업을 등록했습니다.' });
    },
    onError: (error: Error) => {
      console.error('Error creating hospital popup', error);
      toastService.errorMsg({ text: '팝업 등록을 실패했습니다.' });
    },
  });

  const createPopupAction = (
    params: CreateUpdatePopupParamType,
    onSuccessCallback?: (id: string) => void,
    onFailureCallback?: () => void,
  ) => {
    createPopupMutation.mutate(params, {
      onSuccess: (data) => onSuccessCallback?.(data._id ?? ''),
      onError: () => onFailureCallback?.(),
    });
  };

  const updatePopupMutation = useMutation({
    mutationFn: (params: CreateUpdatePopupParamType) =>
      updateHospitalPopup({ ...params, hospitalID }),
    onMutate: () => fullDimmedLoadingService.on(),
    onSettled: () => fullDimmedLoadingService.off(),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.apiPopupHospitalElFindSimple] });
      toastService.successMsg({ text: '팝업을 수정했습니다.' });
    },
    onError: (error: Error) => {
      console.error('Error creating hospital popup', error);
      toastService.errorMsg({ text: '팝업 수정을 실패했습니다.' });
    },
  });

  const updatePopupAction = (
    params: CreateUpdatePopupParamType,
    onSuccessCallback?: () => void,
    onFailureCallback?: () => void,
  ) => {
    const { popupType, content, fileIDs } = params;

    const filteredParams = {
      ...params,
      content: popupType === 'text' ? content : undefined,
      fileIDs: popupType === 'image' ? fileIDs : undefined,
    };

    updatePopupMutation.mutate(filteredParams, {
      onSuccess: () => onSuccessCallback?.(),
      onError: () => onFailureCallback?.(),
    });
  };

  // 팝업 삭제
  const removePopupMutation = useMutation({
    mutationFn: (params: RemovePopupParam) => removeHospitalPopup(params),
    onMutate: () => fullDimmedLoadingService.on(),
    onSettled: () => fullDimmedLoadingService.off(),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.apiPopupHospitalElFindSimple] });
      modalService.pop();
      toastService.successMsg({ text: '팝업을 삭제했습니다.' });
    },
    onError: (error: Error) => {
      console.error('Error removing hospital popup', error);
      modalService.pop();
      toastService.errorMsg({ text: '팝업 삭제를 실패했습니다.' });
    },
  });

  const removePopupAction = (id: string, onSuccessCallback?: () => void) => {
    removePopupMutation.mutate({ _id: id }, { onSuccess: () => onSuccessCallback?.() });
  };

  return { detailPopupAction, createPopupAction, updatePopupAction, removePopupAction };
};
