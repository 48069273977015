import SearchInput from '@afterdoc-design-system/components/Atoms/Input/SearchInput';
import ButtonTab from '@afterdoc-design-system/components/Atoms/Tabs/components/ButtonTab';
import Tabs from '@afterdoc-design-system/components/Atoms/Tabs/components/Tabs';
import Title from 'afterdoc-design-system/components/Molecules/Title/Title';
import { useAtomValue } from 'jotai';
import { useEffect } from 'react';
import { chatRoomIDSelector } from '../../states/selected-chat-room';
import { useSearchInput } from '../ChattingList/hooks/use-search-input';
import ContentsList from './components/ContentsList/ContentsList';
import ManualList from './components/ManualList/ManualList';
import { useChangedChatRoomIDDefaultTab } from './hooks/use-changed-chatRoomID-default-tab';

export const TAB_NAMES = ['원내매뉴얼', '콘텐츠'] as const;

export default function Manual() {
  const chatRoomID = useAtomValue(chatRoomIDSelector);
  const { selectedTab, setSelectedTab } = useChangedChatRoomIDDefaultTab();
  const { inputValue, keyword, handleChange, handleKeyDown, handleDelete } = useSearchInput();

  const isInnerManual = selectedTab === '원내매뉴얼';
  const isContents = selectedTab === '콘텐츠';

  useEffect(() => {
    handleDelete();
  }, [selectedTab, chatRoomID]);

  return (
    <div className='mb-10 flex h-full flex-col'>
      <Title title={'상담 백과사전'} />
      <div>
        <Tabs value={selectedTab} onChange={setSelectedTab}>
          {TAB_NAMES.map((name) => (
            <ButtonTab className='border-b border-b-white400' value={name} label={name} key={name}>
              {name}
            </ButtonTab>
          ))}
        </Tabs>
        {isInnerManual && (
          <div className='mx-10 pt-10'>
            <SearchInput
              placeholder='태그명으로 검색'
              value={inputValue}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              onDelete={handleDelete}
            />
          </div>
        )}
        {isInnerManual && (
          <div className='h-[calc(100vh-130px)]'>
            <ManualList keyword={keyword} />
          </div>
        )}
        {isContents && (
          <div className='h-[calc(100vh-90px)]'>
            <ContentsList />
          </div>
        )}
      </div>
    </div>
  );
}
