import { AUTHORIZATION_TYPES } from '@templates/HospitalSetting/containers/AuthorizationSetting/constants/type-items';
import AuthorizationList from '@templates/HospitalSetting/containers/AuthorizationSetting/containers/AuthorizationTypeDetailSetting/components/AuthorizationItem/AuthorizationList';

export default function AuthorizationTypeDetailSettingContainer() {
  return (
    <>
      <div className='mt-30 flex-col-center'>
        {Object.keys(AUTHORIZATION_TYPES).map((key) => {
          return <AuthorizationList key={key} item={AUTHORIZATION_TYPES[key]} />;
        })}
      </div>
    </>
  );
}
