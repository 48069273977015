import { useUserInfo } from 'web/shared/hooks/use-user-info';
import { FileService } from 'web/shared/utils/fileUpload/services/FileServices';
import { FileUploader } from 'web/shared/utils/fileUpload/services/FileUploader';

export interface VideoContent {
  /**
   * 비디오 파일 ID
   * @example "60d0fe4f5311236168a109cb"
   */
  _id?: string;
  /**
   * 비디오 URL
   */
  url: string;
  /**
   * 비디오 파일 이름
   * @example "video.mp4"
   */
  fileName: string;
  /**
   * 비디오 파일 크기 (바이트 단위)
   * @example 1048576
   */
  fileSize: number;
}

const convertToFileObject = async (video: VideoContent): Promise<File> => {
  const response = await fetch(video.url);
  const blob = await response.blob();
  const file = new File([blob], video.fileName, {
    type: 'video/mp4',
    lastModified: Date.now(),
  });
  return file;
};

export const useProcessVideoForUpload = () => {
  const { userId } = useUserInfo();

  const processVideoForUpload = async (video: VideoContent) => {
    if (video) {
      const fileToUpload = await convertToFileObject(video);

      const sizes = await FileService.getVideoFileWidthHeight(fileToUpload);

      const result = await FileUploader.uploadFiles({
        files: [fileToUpload],
        sizes: [sizes],
        userID: userId,
        type: 300,
      });

      return result._id;
    }
  };

  return {
    processVideoForUpload,
  };
};
