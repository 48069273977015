import dayjs from 'dayjs';

interface EventDisplayPriceProps {
  isLimitedTime: boolean;
  startDate: string;
  endDate: string;
}

export default function DisplayPeriod({
  isLimitedTime,
  startDate,
  endDate,
}: EventDisplayPriceProps) {
  const formattedDate =
    isLimitedTime && startDate && endDate
      ? `${dayjs(startDate).format('YYYY-MM-DD')}\n~${dayjs(endDate).format('YYYY-MM-DD')}`
      : '-';

  return (
    <div className='flex h-full items-center'>
      <p
        className={`text-Body12 text-white700 ${
          formattedDate === '-' ? 'whitespace-nowrap' : 'whitespace-pre-line'
        }`}>
        {formattedDate}
      </p>
    </div>
  );
}
