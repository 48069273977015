import { toastService } from '@afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { useEffect, useState } from 'react';
import useFileDownloadWithProgress from 'web/shared/hooks/files/use-file-download-with-progress';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import { sanitizeFileName } from 'web/shared/utils/sanitize-file-name';

export const useSaveCustomerManagementExcelForm = () => {
  const { name } = useSelectedHospitalInfo();
  const { startDownload, ...rest } = useFileDownloadWithProgress();

  const [ExcelJS, setExcelJS] = useState<typeof import('exceljs') | null>(null);

  const excelTitle = `${sanitizeFileName(name)}_고객등록_양식.xlsx`;
  const handleDownloadCustomerManagementExcelForm = async () => {
    if (!ExcelJS) return toastService.errorMsg({ text: '엑셀 파일 다운로드에 실패했습니다.' });

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('고객등록 양식');

    // A컬럼 너비
    worksheet.getColumn(1).width = 15;
    // B컬럼 너비
    worksheet.getColumn(2).width = 15;
    // C컬럼 너비
    worksheet.getColumn(3).width = 15;

    // 첫 번째 행 높이
    worksheet.getRow(1).height = 40;

    worksheet.mergeCells('A1:B1');
    worksheet.mergeCells('A2:C2');

    // 들어가는 데이터
    worksheet.getCell('A1').value = '고객 등록 양식';
    worksheet.getCell('A2').value = '*양식을 임의로 수정하면 업로드에 실패할 수 있습니다.';
    worksheet.getCell('A3').value = '고객명*';
    worksheet.getCell('B3').value = '휴대폰번호*';
    worksheet.getCell('A4').value = '홍길동';
    worksheet.getCell('B4').value = '01012345678';

    // 폰트 설정
    worksheet.getCell('A1').font = { name: 'Arial', bold: true, size: 20 };
    worksheet.getCell('A2').font = {
      name: 'Arial',
      size: 10,
      color: { argb: 'FF0000' },
    };
    worksheet.getCell('A3').font = { name: 'Arial', size: 10, bold: true };
    worksheet.getCell('B3').font = { name: 'Arial', size: 10, bold: true };
    worksheet.getCell('A4').font = { name: 'Arial', size: 10 };
    worksheet.getCell('B4').font = { name: 'Arial', size: 10 };
    worksheet.getCell('B4').numFmt = '@';

    for (let i = 5; i <= 300; i++) {
      worksheet.getCell(`A${i}`).value = '';
      worksheet.getCell(`A${i}`).font = { name: 'Arial', size: 10 };
      worksheet.getCell(`B${i}`).value = '';
      worksheet.getCell(`B${i}`).font = { name: 'Arial', size: 10 };
      worksheet.getCell(`B${i}`).numFmt = '@';
    }

    // align 설정
    worksheet.getRow(1).alignment = { vertical: 'middle' };

    worksheet.getColumn(1).alignment = { horizontal: 'left' };
    worksheet.getColumn(2).alignment = { horizontal: 'left' };

    try {
      if (window.electron) {
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        if (window.electron) {
          startDownload(blob, excelTitle, [{ name: 'Excel Files', extensions: ['xlsx'] }]);
        }
      }
    } catch (error) {
      console.error('엑셀 파일 다운로드 에러 발생 :', error);
      toastService.errorMsg({ text: '엑셀 파일 다운로드에 실패했습니다.' });
    }
  };

  useEffect(() => {
    import('exceljs/dist/exceljs.min.js').then((module) => {
      setExcelJS(module as typeof import('exceljs'));
    });
  }, []);

  return {
    handleDownloadCustomerManagementExcelForm,
    excelTitle,
    ...rest,
  };
};
