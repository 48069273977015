import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import type { AlgorithmWithMessagesInput, MessageInput } from 'web/apis/swaggers/swagger-docs';
import RegisterMarketingAutomationDialogTabs from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/components/RegisterMarketingAutomationDialogTabs';
import MarketingBaseSettingContainer from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingBaseSetting/MarketingBaseSetting.container';
import MarketingEventStartDateSettingContainer from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingEventStartDateSetting/MarketingEventStartDateSetting.container';
import MarketingMessageSettingContainer from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/MarketingMessageSetting.container';
import { selectedMarketingAutomationTabState } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/states/selected-marketing-automation-tab-state';

export type MarketingAutomationAPIFormValues = Omit<AlgorithmWithMessagesInput, 'messages'> & {
  messages: (MessageInput & {
    id: string;
  })[];
};

export default function RegisterMarketingAutomationDialogContent() {
  const selectedAutomationTab = useAtomValue(selectedMarketingAutomationTabState);

  const [hasVisitedMessageTab, setHasVisitedMessageTab] = useState(false);

  useEffect(() => {
    if (selectedAutomationTab === 2) {
      setHasVisitedMessageTab(true);
    }
  }, [selectedAutomationTab]);

  return (
    <div className='w-full flex-col-center pt-40'>
      <RegisterMarketingAutomationDialogTabs />
      {/* 기본설정 */}
      {selectedAutomationTab === 0 && <MarketingBaseSettingContainer />}
      {/* 이벤트 시작일 설정 */}
      {selectedAutomationTab === 1 && (
        <MarketingEventStartDateSettingContainer hasVisitedMessageTab={hasVisitedMessageTab} />
      )}
      {/* 메시지 */}
      {selectedAutomationTab === 2 && <MarketingMessageSettingContainer />}
    </div>
  );
}
