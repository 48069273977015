import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import type { ApiUserElFindOneParams } from '@apis/swaggers/swagger-docs';
import { useNavigationBlocker } from '@shared/hooks/use-navigation-blocker';
import { useUserInfo } from '@shared/hooks/use-user-info';
import { useSuspenseQuery } from '@tanstack/react-query';
import AccountSettingsSection from '@templates/UserInfoSetting/containers/AccountSetting/components/AccountSettingsSection';
import BasicInfoSection from '@templates/UserInfoSetting/containers/AccountSetting/components/BasicInfoSection';
import type { UserUpdateFieldType } from '@templates/UserInfoSetting/containers/AccountSetting/types/user-update-field-type';
import { isNotificationSettingModifiedState } from '@templates/UserInfoSetting/containers/NotificationSetting/states/is-notification-setting-modified-state';
import { useAtom } from 'jotai/index';
import { SHARED_UTILS } from 'utils/utils';

export type UserElUpdateParam = Parameters<typeof apiClient.v2.apiUserElUpdate>[0] &
  UserUpdateFieldType;

const fetchUserInfo = async (params: ApiUserElFindOneParams) => {
  const { data } = await apiClient.v2.apiUserElFindOne(params);
  return SHARED_UTILS.api.checkApiResponse(data);
};

export default function AccountSettingContainer() {
  const { userId } = useUserInfo();

  const { authorizationTypeID } = useUserInfo();

  const isMasterOrAdmin =
    authorizationTypeID?.name === '마스터' || authorizationTypeID?.name === '관리자';

  const [isModified, setIsModified] = useAtom(isNotificationSettingModifiedState);

  const { data } = useSuspenseQuery({
    queryKey: [QUERY_KEY.v2apiUserElFindOne, { userID: userId }] as const,
    queryFn: ({ queryKey }) => fetchUserInfo(queryKey[1]),
  });

  useNavigationBlocker({
    shouldBlock: isModified,
  });

  return (
    <div className='flex flex-col py-20'>
      <BasicInfoSection
        userName={data.realName ?? ''}
        isMasterOrAdmin={isMasterOrAdmin}
        onUnsavedChange={(isChanged) => setIsModified(isChanged)}
      />
      <AccountSettingsSection
        id={data.id ?? ''}
        phone={data.phone ?? ''}
        isMasterOrAdmin={isMasterOrAdmin}
      />
    </div>
  );
}
