import * as React from "react";
import type { SVGProps } from "react";
const SvgMen = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <circle cx={10} cy={8} r={3.25} stroke="#0085FF" strokeWidth={1.5} />
    <path stroke="#0085FF" strokeWidth={1.5} d="M10 11v5M14 13l-4 3-4-3" />
  </svg>
);
export default SvgMen;
