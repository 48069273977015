import { SHARED_UTILS } from '@shared-utils/utils';
import { apiClient } from 'web/apis/instances/api-client';
import type { PostSendNoticeParams } from '../types';

export const postSendNotice = async ({
  chatRoomID,
  hserviceID,
  hnoticeID,
}: PostSendNoticeParams) => {
  const body = {
    chatRoomID,
    hserviceID,
    hnoticeID,
  };

  const { data } = await apiClient.v3.apiChatElSendHnotice(body);
  return SHARED_UTILS.api.checkApiResponse(data);
};
