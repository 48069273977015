import type { SingleHospitalPushSettingHandlerResponse } from '@apis/swaggers/swagger-docs';
import { notificationSettingState } from '@templates/UserInfoSetting/containers/NotificationSetting/states/notification-setting-state';
import { useAtomValue, useSetAtom } from 'jotai';
import { selectAtom } from 'jotai/utils';
import { useMemo } from 'react';

export const useNotificationSetting = () => {
  const setNotificationSetting = useSetAtom(notificationSettingState);

  const getValue = <Key extends keyof SingleHospitalPushSettingHandlerResponse['data']>(
    key: Key,
  ): SingleHospitalPushSettingHandlerResponse['data'][Key] | null => {
    return useAtomValue(
      useMemo(() => selectAtom(notificationSettingState, (state) => state?.[key] ?? null), [key]),
    );
  };

  const setValue = <Key extends keyof SingleHospitalPushSettingHandlerResponse['data']>(
    key: Key,
    newValue: SingleHospitalPushSettingHandlerResponse['data'][Key],
  ) => {
    setNotificationSetting(
      (prev) =>
        ({
          ...prev,
          [key]: newValue,
        }) as typeof prev,
    );
  };

  return { getValue, setValue };
};
