import { Suspense } from 'react';
import ErrorBoundary from 'web/shared/components/ErrorBoundary/ErrorBoundary';
import CustomerManagementContainer from 'web/templates/CustomerManagement/CustomerManagement.container';

export default function CustomerManagementPage() {
  return (
    <ErrorBoundary>
      <Suspense>
        <CustomerManagementContainer />
      </Suspense>
    </ErrorBoundary>
  );
}
