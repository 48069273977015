import BaseLoading from '@afterdoc-design-system/components/Atoms/Loading/BaseLoading';
import { useSelectedHospitalInfo } from '@shared/hooks/use-selected-hospital-info';
import { Suspense, useMemo } from 'react';
import ErrorBoundary from 'web/shared/components/ErrorBoundary/ErrorBoundary';
import TreatmentHistoryContainer from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/AfterDoc/TreatmentHistory/TreatmentHistory.container';
import ReservationHistoryContainer from './containers/AfterDoc/ReservationHistory/ReservationHistory.container';
import SmartDoctorReservationContainer from './containers/SmartDoctor/SmartDoctorReservation.container';

export default function ReservationManagementContainer() {
  const { integrationSetting } = useSelectedHospitalInfo();

  const hasSmartDoctorLinkage = useMemo(() => {
    if (integrationSetting) {
      const { isRemoved, integrationType, usingReservation } = integrationSetting;
      return !isRemoved && integrationType === 'ALM' && usingReservation;
    }
    return false;
  }, [integrationSetting]);

  return (
    <div className='flex h-full flex-col gap-16'>
      <div className='text-Header16 text-black700'>예약관리</div>

      {hasSmartDoctorLinkage ? (
        <ErrorBoundary>
          <Suspense>
            <SmartDoctorReservationContainer />
          </Suspense>
        </ErrorBoundary>
      ) : (
        <div className='flex flex-col gap-16'>
          {/* 치료내역 */}
          <Suspense
            fallback={
              <div className='flex h-full w-full flex-col gap-4'>
                <div className='mb-16 text-Header14 text-black500'>치료내역</div>
                <div className='h-[100px] flex-center'>
                  <BaseLoading />
                </div>
              </div>
            }>
            <TreatmentHistoryContainer />
          </Suspense>
          {/* 예약내역 */}
          <ReservationHistoryContainer />
        </div>
      )}
    </div>
  );
}
