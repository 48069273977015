import { SHARED_UTILS } from '@shared-utils/utils';
import { useMutation } from '@tanstack/react-query';
import { useSetAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { useCallback } from 'react';
import { apiClient } from 'web/apis/instances/api-client';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import { customerTableDisplayModeState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/states/customer-management-display-mode';
import type { CustomerManagementTemporaryAPIFormValues } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/types/table';
import {
  focusedCellState,
  selectedCellState,
} from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/states/table';

const postPatientsTemporaryInfo = async (
  params: Parameters<typeof apiClient.v3.apiPatientsElTemporary>[0],
) => {
  const response = await apiClient.v3.apiPatientsElTemporary(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export const useSaveTemporaryUser = () => {
  const { hospitalID } = useSelectedHospitalInfo();

  const resetSelectedCell = useResetAtom(selectedCellState);
  const resetFocusedCell = useResetAtom(focusedCellState);

  const setCustomerTableDisplayMode = useSetAtom(customerTableDisplayModeState);

  const handleChangeDisplayMode = () => {
    setCustomerTableDisplayMode('VIEWER');
  };

  const processRowForAPI = useCallback(
    (row: CustomerManagementTemporaryAPIFormValues[number], index: number) => {
      const {
        birthdate,
        chartNumber,
        gender,
        isFirstVisit,
        name,
        nationalityId,
        order,
        phoneNumber,
        treatmentTagIds,
      } = row;

      return {
        hospitalID,
        order: order ?? index + 1,
        birthdate,
        chartNumber,
        gender,
        isFirstVisit,
        name,
        phoneNumber,
        nationalityId,
        treatmentTagIds: treatmentTagIds ?? [],
      };
    },
    [hospitalID],
  );

  const temporarySaveMutation = useMutation({
    mutationFn: (params: Parameters<typeof postPatientsTemporaryInfo>[0]) =>
      postPatientsTemporaryInfo(params),
  });

  const handleTemporarySave = async (
    currentRows: CustomerManagementTemporaryAPIFormValues,
    onSuccessCallback?: () => void,
  ) => {
    if (!currentRows.length) return;

    if (currentRows.length > 0) {
      const rowsWithOrder = currentRows.map((row, index) => processRowForAPI(row, index));

      const getLastModifiedRowIndex = (rows: typeof rowsWithOrder) => {
        let lastModifiedIndex = -1;

        for (let i = 0; i < rows.length; i++) {
          const row = rows[i];
          if (!row) continue;

          const { hospitalID, order, ...otherFields } = row;

          // 데이터가 있는 row 확인
          const hasData = Object.values(otherFields).some(
            (v) => v !== null && v !== undefined && !(Array.isArray(v) && v.length === 0),
          );

          if (hasData) {
            lastModifiedIndex = i;
          }
        }

        return lastModifiedIndex;
      };

      const lastModifiedIndex = getLastModifiedRowIndex(rowsWithOrder);

      // 마지막 수정된 row까지만 포함
      const relevantRows =
        lastModifiedIndex >= 0 ? rowsWithOrder.slice(0, lastModifiedIndex + 1) : rowsWithOrder;

      const updatedValues =
        relevantRows.length > 0
          ? relevantRows
          : [
              {
                order: 1,
                hospitalID,
                name: null,
                patientId: undefined,
                chartNumber: null,
                isFirstVisit: null,
                phoneNumber: null,
                birthdate: null,
                gender: null,
                treatmentTagIds: [],
                nationalityId: null,
              },
            ];

      const firstOrder = updatedValues[0]?.order ?? 0;
      const maxOrder = Math.max(...updatedValues.map((row) => row?.order ?? 0));

      const filledValues = Array.from({ length: maxOrder - firstOrder + 1 }, (_, i) => {
        const existingRow = updatedValues.find((row) => row?.order === i + firstOrder);
        return existingRow || { hospitalID, order: i + firstOrder, name: '' };
      });

      const result = await temporarySaveMutation.mutateAsync(filledValues, {
        onSuccess: () => {
          // 셀 선택 초기화
          resetSelectedCell();
          resetFocusedCell();

          handleChangeDisplayMode();
          onSuccessCallback?.();
        },
        onError: (error) => {
          console.error('임시 고객정보 저장 에러 발생 :', error);
        },
      });
      return result;
    }
  };

  return {
    handleTemporarySave,
  };
};
