import { JOB_ITEMS } from '@templates/HospitalSetting/containers/AllAccountSetting/constants/job-items';
import Checkbox from 'afterdoc-design-system/components/Atoms/Checkbox/Checkbox';
import TextInput, {
  type TextInputLabelTextProps,
} from 'afterdoc-design-system/components/Atoms/Input/TextInput';
import TextFieldSelectBoxDropdown from 'afterdoc-design-system/components/Molecules/TextSelect/TextFieldSelectBoxDropdown';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

const baseLabelProps: TextInputLabelTextProps = {
  children: '직책/직급',
  isRequired: true,
  width: 140,
  position: 'vertical',
};

export default function AccountJob() {
  const [jobIndex, setJobIndex] = useState<number | undefined>(undefined);
  const [jobTypeText, setJobTypeText] = useState('');
  const [isDirectlySelected, setIsDirectlySelected] = useState(false);

  const {
    setValue,
    watch,
    register,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const jobType = watch('jobType');

  const handleChange = () => {
    setIsDirectlySelected(!isDirectlySelected);
  };

  useEffect(() => {
    register('jobType');
  }, [register, isDirectlySelected]);

  useEffect(() => {
    let value: string | undefined;
    if (isDirectlySelected) {
      value = jobTypeText;
    } else {
      value = jobIndex !== undefined ? JOB_ITEMS[jobIndex] : undefined;
    }
    clearErrors('jobType');
    setValue('jobType', value);
  }, [isDirectlySelected, jobTypeText, jobIndex]);

  useEffect(() => {
    if (jobType === undefined) {
      setJobIndex(undefined);
      setJobTypeText('');
      setIsDirectlySelected(false);
    }
  }, [jobType]);

  return (
    <div className='flex flex-col gap-4'>
      <div className='flex gap-10'>
        {isDirectlySelected ? (
          <TextInput
            id='jobType'
            placeholder='직접입력'
            maxLength={15}
            inputClassName='w-[140px]'
            value={jobTypeText}
            onChange={(e) => setJobTypeText(e.target.value)}
            label={baseLabelProps}
          />
        ) : (
          <TextFieldSelectBoxDropdown
            label={baseLabelProps}
            options={JOB_ITEMS}
            width={146}
            onSelect={(index) => setJobIndex(index)}
            defaultValue='선택'
            selectedIndex={jobIndex}
          />
        )}
        <div className='mt-auto flex h-[32px] items-center'>
          <Checkbox
            label='직접입력'
            gapSize={5}
            checked={isDirectlySelected}
            onChange={handleChange}
          />
        </div>
      </div>
      {!!errors.jobType && (
        <span className='text-Body10 text-red500'>직책/직급을 입력해 주세요.</span>
      )}
    </div>
  );
}
