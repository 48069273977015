import { hospitalInfoState } from '@templates/HospitalSetting/containers/HospitalProfile/states/hospital-info-state';
import TextInput from 'afterdoc-design-system/components/Atoms/Input/TextInput';
import { useAtom } from 'jotai/index';

export default function DetailedAddress() {
  const [hospitalInfo, setHospitalInfo] = useAtom(hospitalInfoState);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHospitalInfo((prev) => ({
      ...prev,
      addressInfo: {
        ...prev.addressInfo,
        detailAddress: event.target.value ?? '',
      },
    }));
  };

  return (
    <>
      <TextInput
        label={{
          children: '상세주소',
          width: 60,
          position: 'horizontal',
        }}
        placeholder='상세주소를 입력해 주세요.'
        maxLength={500}
        onChange={handleInputChange}
        value={hospitalInfo.addressInfo?.detailAddress ?? ''}
      />
    </>
  );
}
