import { Suspense } from 'react';
import FullLoading from 'web/shared/components/FullLoading/FullLoading';
import type { ParseWorkoutVideoContent } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/components/Forms/WorkoutVideoContentForm/WorkoutVideoContentForm';
import WorkoutVideoContentList from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/components/Forms/WorkoutVideoContentForm/components/WorkoutVideoContentSelectDialog/components/WorkoutVideoContentList/WorkoutVideoContentList';
import WorkoutVideoContentPanel from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/components/Forms/WorkoutVideoContentForm/components/WorkoutVideoContentSelectDialog/components/WorkoutVideoContentPanel/WorkoutVideoContentPanel';

export interface WorkoutVideoContentSelectDialogProps {
  handleClickWorkoutCard: (props: ParseWorkoutVideoContent) => void;
}

export default function WorkoutVideoContentSelectDialog({
  handleClickWorkoutCard,
}: WorkoutVideoContentSelectDialogProps) {
  return (
    <Suspense fallback={<FullLoading />}>
      <div className='flex'>
        <WorkoutVideoContentPanel />
        <WorkoutVideoContentList handleClickWorkoutCard={handleClickWorkoutCard} />
      </div>
    </Suspense>
  );
}
