import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import type { KakaoConsultationTalkLinkageHandlerBodyRequest } from '@apis/swaggers/swagger-docs';
import { ISO_DATE_TIME_TIME_FORMAT } from '@shared/constants/iso-date-time-format';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { hasLinkageOrRequestHistoryState } from '@templates/HospitalSetting/containers/ChannelIntegration/states/has-linkage-or-request-history-state';
import {
  type KakaoConsultFormErrorType,
  kakaoConsultFormErrorState,
} from '@templates/HospitalSetting/containers/ChannelIntegration/states/kakao-consult-form-error-state';
import { hospitalSettingsModifiedState } from '@templates/HospitalSetting/states/hospital-settings-modified-state';
import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';
import { fullDimmedLoadingService } from 'afterdoc-design-system/components/Atoms/Loading/FullDimmedLoading/FullDimmedLoading.service';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import dayjs from 'dayjs';
import { useAtomValue, useSetAtom } from 'jotai/index';
import { useResetAtom } from 'jotai/utils';
import { useCallback } from 'react';
import { type SubmitHandler, useFormContext } from 'react-hook-form';
import { SHARED_UTILS } from 'utils/utils';

const requestkakaoConsultationTalkLinkage = async (
  parmas: KakaoConsultationTalkLinkageHandlerBodyRequest,
) => {
  const { data } = await apiClient.v3.kakaoConsultationTalkLinkageHandler(parmas);
  return SHARED_UTILS.api.checkApiResponse(data);
};

const checkAllDaysUnavailable = (
  consultationInfo: KakaoConsultationTalkLinkageHandlerBodyRequest['consultationInformation'],
) => {
  const days = [
    consultationInfo.mondayAvailable,
    consultationInfo.tuesdayAvailable,
    consultationInfo.wednesdayAvailable,
    consultationInfo.thursdayAvailable,
    consultationInfo.fridayAvailable,
    consultationInfo.saturdayAvailable,
    consultationInfo.sundayAvailable,
  ];
  return days.every((available) => !available);
};

const checkConsultationTime = (
  consultationInfo: KakaoConsultationTalkLinkageHandlerBodyRequest['consultationInformation'],
) => {
  const startedTime = dayjs(consultationInfo.consultationStartTime, ISO_DATE_TIME_TIME_FORMAT);
  const endedTime = dayjs(consultationInfo.consultationEndTime, ISO_DATE_TIME_TIME_FORMAT);
  return (
    endedTime.isBefore(startedTime) ||
    (endedTime.hour() === startedTime.hour() && endedTime.minute() < startedTime.minute())
  );
};

export default function KakaoConsultationRequestButton() {
  const { handleSubmit } = useFormContext<KakaoConsultationTalkLinkageHandlerBodyRequest>();
  const queryClient = useQueryClient();

  const hasLinkageOrRequestHistory = useAtomValue(hasLinkageOrRequestHistoryState);

  const setKakaoConsultFormError = useSetAtom(kakaoConsultFormErrorState);

  const resetHospitalSettingsModified = useResetAtom(hospitalSettingsModifiedState);

  const mutation = useMutation({
    mutationFn: (params: KakaoConsultationTalkLinkageHandlerBodyRequest) =>
      requestkakaoConsultationTalkLinkage(params),
    onMutate: () => fullDimmedLoadingService.on(),
    onSettled: () => fullDimmedLoadingService.off(),
    onSuccess: () => handleMutationSuccess(),
    onError: (error) => handleMutationError(error),
  });

  const handleMutationSuccess = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QUERY_KEY.apiServiceSettingsElFindOne] });
    queryClient.invalidateQueries({ queryKey: [QUERY_KEY.kakaoLatestLinkageHistoryHandler] });
    resetHospitalSettingsModified();
    toastService.successMsg({ text: '신청되었습니다. 담당자 확인 후 연락드리겠습니다.' });
  }, []);

  const handleMutationError = useCallback((error: Error) => {
    console.error('Error during Kakao Consultation Talk linkage request', error);
    toastService.errorMsg({ text: '신청을 실패했습니다' });
  }, []);

  const validateFields = (data: KakaoConsultationTalkLinkageHandlerBodyRequest) => {
    const errors: KakaoConsultFormErrorType = {
      requesterName: !data.requesterName,
      toBeContactedPhone: !data.toBeContactedPhone,
      toBeStartedAt: !dayjs(data.toBeStartedAt, ISO_DATE_TIME_TIME_FORMAT).isValid(),
      searchableChannelId: !data.searchableChannelId,
      consultationInformationDay: checkAllDaysUnavailable(data.consultationInformation),
      consultationInformationTime: checkConsultationTime(data.consultationInformation),
    };

    const filteredErrors = Object.entries(errors).reduce((acc, [key, value]) => {
      if (value) acc[key as keyof KakaoConsultFormErrorType] = value;
      return acc;
    }, {} as KakaoConsultFormErrorType);

    if (Object.keys(filteredErrors).length > 0) {
      setKakaoConsultFormError((prev) => ({ ...prev, ...filteredErrors }));
      toastService.errorMsg({ text: '신청을 실패했습니다' });
      return false;
    }
    return true;
  };

  const onSubmit: SubmitHandler<KakaoConsultationTalkLinkageHandlerBodyRequest> = (data) => {
    if (!validateFields(data)) return;

    mutation.mutate(data);
  };

  return (
    <>
      {hasLinkageOrRequestHistory === 'NotLinked' && (
        <div className='mt-8 ml-auto'>
          <ContainedButton onClick={handleSubmit(onSubmit)}>신청</ContainedButton>
        </div>
      )}
    </>
  );
}
