import { apiClient } from '@apis/instances/api-client';
import type {
  ElPhoneCodeRequestHandlerBodyRequest,
  ElPhoneCodeVerifyingRequestHandlerBodyRequest,
} from '@apis/swaggers/swagger-docs';
import { useMutation } from '@tanstack/react-query';
import { SHARED_UTILS } from 'utils/utils';

const requestPhoneAuthCode = async (param: ElPhoneCodeRequestHandlerBodyRequest) => {
  const { data } = await apiClient.v3.elPhoneCodeRequestHandler(param);
  return SHARED_UTILS.api.checkApiResponse(data);
};

const checkVerifyPhoneCode = async (param: ElPhoneCodeVerifyingRequestHandlerBodyRequest) => {
  const { data } = await apiClient.v3.elPhoneCodeVerifyingRequestHandler(param);
  return SHARED_UTILS.api.checkApiResponse(data);
};

interface RequestAuthNumAction {
  param: ElPhoneCodeRequestHandlerBodyRequest;
  onSuccessCallback?: (phoneID: string, isSendingSuccess: boolean) => void;
  onFailureCallback?: (error: Error) => void;
}

interface VerifyAuthNumAction {
  param: ElPhoneCodeVerifyingRequestHandlerBodyRequest;
  onSuccessCallback?: () => void;
  onFailureCallback?: (error: Error) => void;
}

export const useUserPhoneVerify = () => {
  const requestPhoneAuthNumMutation = useMutation({
    mutationFn: (params: ElPhoneCodeRequestHandlerBodyRequest) => requestPhoneAuthCode(params),
  });

  const checkVerifyPhoneCodeMutation = useMutation({
    mutationFn: (params: ElPhoneCodeVerifyingRequestHandlerBodyRequest) =>
      checkVerifyPhoneCode(params),
  });

  const requestPhoneAuthNum = (action: RequestAuthNumAction) => {
    requestPhoneAuthNumMutation.mutate(action.param, {
      onSuccess: ({ phoneID, isSendingSuccess }) => {
        action.onSuccessCallback?.(phoneID, isSendingSuccess);
      },
      onError: (e) => action.onFailureCallback?.(e),
    });
  };

  const requestVerifyPhoneCode = (action: VerifyAuthNumAction) => {
    checkVerifyPhoneCodeMutation.mutate(action.param, {
      onSuccess: () => action.onSuccessCallback?.(),
      onError: (e) => action.onFailureCallback?.(e),
    });
  };

  return {
    requestPhoneAuthNum,
    requestVerifyPhoneCode,
  };
};
