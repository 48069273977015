const TITLE = '자동 인사말';
const SUB_TITLE = '고객이 앱을 가입했을 때 자동으로 발송할 첫번째 채팅문구를 입력해 주세요.';

export default function AutoGreetingMessageTitle() {
  return (
    <>
      <div className='pt-20 pb-8'>
        <div className='flex items-center '>
          <h1 className='text-Header16 text-black700'>{TITLE}</h1>
          <h1 className='text-Header16 text-red500'>*</h1>
        </div>
        <h2 className='whitespace-pre-wrap pt-4 text-Body11 text-black200'>{SUB_TITLE}</h2>
      </div>
    </>
  );
}
