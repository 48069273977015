interface EventInquiryCountProps {
  chatCount: number;
}

export default function InquiryCount({ chatCount }: EventInquiryCountProps) {
  return (
    <div className='flex-center overflow-hidden text-ellipsis whitespace-nowrap'>
      <p className='truncate text-Body12 text-black500'>{chatCount.toLocaleString()}</p>
    </div>
  );
}
