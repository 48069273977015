import type { Hospital } from '@apis/swaggers/swagger-docs';
import type { HospitalInfoErrorType } from '@templates/HospitalSetting/types/hospital-info-error-type';
import dayjs from 'dayjs';

export const validateHospitalProfileParams = (params: Hospital) => {
  const errors: HospitalInfoErrorType = {
    shortName: null,
    operatingHours: null,
    address: null,
    inquiryMethod: null,
    autoGreeting: null,
    basicMessage: null,
    resendMessage: null,
    reservationMessage: null,
  };

  const { shortName, careSchedule, addressInfo, usingHomepage, homepageURL, phone, initialChats } =
    params;

  if (!shortName) {
    errors.shortName = '병원명을 입력해 주세요.';
  }

  const activeSchedules = careSchedule?.filter((schedule) => schedule.isActive);
  if (!activeSchedules || !activeSchedules.length) {
    errors.operatingHours = '요일을 1개 이상 선택해 주세요. ';
  } else {
    for (const schedule of activeSchedules) {
      const { startedAt, endedAt } = schedule;

      if (!startedAt || !endedAt) {
        errors.operatingHours = '운영 시간을 입력해 주세요.';
        break;
      }
      const startedTime = dayjs(startedAt);
      const endedTime = dayjs(endedAt);

      if (
        endedTime.hour() < startedTime.hour() ||
        (endedTime.hour() === startedTime.hour() && endedTime.minute() < startedTime.minute())
      ) {
        errors.operatingHours = '종료시간이 시작시간보다 빠릅니다.';
        break;
      }
    }
  }

  if (!addressInfo || !addressInfo.roadAddr) {
    errors.address = '주소를 입력해 주세요.';
  }

  if (usingHomepage) {
    if (!homepageURL) {
      errors.inquiryMethod = '홈페이지 주소를 입력해 주세요.';
    }
  } else {
    if (!phone) {
      errors.inquiryMethod = '전화번호를 입력해 주세요.';
    }
  }

  if (!initialChats?.[0]?.content) {
    errors.autoGreeting = '자동 인사말을 입력해 주세요.';
  }

  return Object.keys(errors).reduce((acc, key) => {
    const typedKey = key as keyof HospitalInfoErrorType;
    if (errors[typedKey] !== null) {
      acc[typedKey] = errors[typedKey];
    }
    return acc;
  }, {} as HospitalInfoErrorType);
};
