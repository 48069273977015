import type { SingleHospitalPushSettingHandlerResponse } from '@apis/swaggers/swagger-docs';
import dayjs from 'dayjs';

type ResType = SingleHospitalPushSettingHandlerResponse['data'];

const isTimeEqual = (time1: string, time2: string): boolean => {
  const formatTime = (time: string) => dayjs(time).format('HH:mm');
  return formatTime(time1) === formatTime(time2);
};

export const areSettingsEqual = (obj1: ResType, obj2: ResType): boolean => {
  const keys = Object.keys(obj1) as Array<keyof ResType>;

  return keys.every((key) => {
    if (key === '_id') return true;

    const value1 = obj1[key];
    const value2 = obj2[key];

    if (typeof value1 === 'boolean' && typeof value2 === 'boolean') {
      return value1 === value2;
    }

    if (typeof value1 === 'string' && typeof value2 === 'string') {
      return isTimeEqual(value1, value2);
    }

    return false;
  });
};
