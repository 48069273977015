import { useUserInfo } from '@shared/hooks/use-user-info';
import { contentEditState } from '@templates/Content/containers/ContentManager/states/content-edit-state';
import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';
import DownloadFooter from 'afterdoc-design-system/components/Molecules/DownloadFooter/DownloadFooter';
import { useSetAtom } from 'jotai/index';
import React from 'react';

type FooterType = 'event' | 'homeCare' | 'notice' | 'popup';

interface EventHomeCareListCommonFooterProps {
  type: FooterType;
}

export default function ContentListCommonFooter({ type }: EventHomeCareListCommonFooterProps) {
  const { authorizationTypeID } = useUserInfo();
  const setContentEdit = useSetAtom(contentEditState);

  const handleConfirm = () => {
    setContentEdit({ isEditing: true, data: { editType: type } });
  };

  const customRightButton = <ContainedButton onClick={handleConfirm}>등록</ContainedButton>;

  return (
    <>
      {!!authorizationTypeID?.canUpsertContent && (
        <DownloadFooter customRightButton={customRightButton} />
      )}
    </>
  );
}
