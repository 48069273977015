import imageCompression from 'browser-image-compression';
import { getImageMimeType } from 'web/shared/utils/fileUpload/functions/get-image-mime-type';

interface ImageCompressionOptions {
  maxSizeMB?: number;
  maxWidthOrHeight?: number;
  useWebWorker?: boolean;
  initialQuality?: number;
}

interface CompressImageResult {
  compressedDataUrl: string;
  fileName: string;
}

const DEFAULT_OPTIONS: ImageCompressionOptions = {
  maxSizeMB: 0.5,
  maxWidthOrHeight: 800,
  useWebWorker: true,
  initialQuality: 0.8,
};

// 이미지 타입 체크 함수
async function isValidImage(blob: Blob): Promise<boolean> {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
    img.src = URL.createObjectURL(blob);
  });
}

export function useImageCompression(customOptions?: ImageCompressionOptions) {
  const compressSingleImage = async (
    blobOrFile: Blob | File,
    fileName?: string,
  ): Promise<CompressImageResult> => {
    try {
      // 이미지 유효성 체크
      const isValid = await isValidImage(blobOrFile);
      if (!isValid) {
        throw new Error('유효하지 않은 이미지 파일입니다.');
      }

      const originalFileSizeMB = blobOrFile.size / (1024 * 1024);
      const mimeType = fileName ? getImageMimeType(fileName) : 'image/jpeg';

      // Blob을 File로 변환
      const file =
        blobOrFile instanceof File
          ? blobOrFile
          : new File(
              [blobOrFile],
              fileName || `image_${Date.now()}.${blobOrFile.type.split('/')[1] || 'png'}`,
              { type: mimeType },
            );

      const compressionOptions = {
        ...DEFAULT_OPTIONS,
        ...customOptions,
        maxSizeMB: originalFileSizeMB > 1 ? 0.3 : 0.5,
        initialQuality: originalFileSizeMB > 1 ? 0.6 : 0.7,
      };

      const compressedBlob = await imageCompression(file, compressionOptions);
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          const img = new Image();
          img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext('2d');
            if (!ctx) return reject('Canvas context 생성 실패');

            ctx.drawImage(img, 0, 0);
            const webpBase64 = canvas.toDataURL('image/webp', 0.7);

            resolve({
              compressedDataUrl: webpBase64,
              fileName: fileName || `image_${Date.now()}.${blobOrFile.type.split('/')[1] || 'png'}`,
            });
          };
          img.onerror = (error) => {
            console.error('이미지 로드 실패:', error);
            reject(error);
          };
          img.src = reader.result as string;
        };
        reader.onerror = (error) => {
          console.error('파일 읽기 실패:', error);
          reject(error);
        };
        reader.readAsDataURL(compressedBlob);
      });
    } catch (error) {
      console.error('이미지 압축 처리 실패:', error);
      throw error;
    }
  };

  const compressImages = async (
    blobsOrFiles: Array<Blob | File>,
    fileNames?: string[],
  ): Promise<CompressImageResult[]> => {
    try {
      const compressionPromises = blobsOrFiles.map((blobOrFile, index) =>
        compressSingleImage(blobOrFile, fileNames?.[index]),
      );

      return await Promise.all(compressionPromises);
    } catch (error) {
      console.error('이미지 일괄 압축 처리 실패:', error);
      throw error;
    }
  };

  return {
    compressImage: compressSingleImage,
    compressImages,
  };
}
