import { hospitalInfoState } from '@templates/HospitalSetting/containers/HospitalProfile/states/hospital-info-state';
import LabelText from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import Radio from 'afterdoc-design-system/components/Atoms/Radio/Radio';
import { useAtom, useSetAtom } from 'jotai/index';
import { selectAtom } from 'jotai/utils';

const hospitalUsingHomepageAtom = selectAtom(
  hospitalInfoState,
  (hospital) => hospital.usingHomepage,
);

export default function ContactMethodSelection() {
  const [usingHomepage] = useAtom(hospitalUsingHomepageAtom);
  const setHospitalInfo = useSetAtom(hospitalInfoState);

  const handleContactTypeChange = (usingHomepage: boolean) => {
    setHospitalInfo((prev) => ({
      ...prev,
      usingHomepage: usingHomepage,
    }));
  };

  return (
    <>
      <div className='flex-1'>
        <LabelText isRequired={true}>예약 문의하기 시</LabelText>
        <div className='mt-10 flex flex-col gap-10 px-10'>
          <Radio
            label='전화를 통해 연결'
            id={'call'}
            checked={usingHomepage !== true}
            onChange={() => handleContactTypeChange(false)}
          />
          <Radio
            label='홈페이지를 통해 연결'
            id={'homepage'}
            checked={usingHomepage === true}
            onChange={() => handleContactTypeChange(true)}
          />
        </div>
      </div>
    </>
  );
}
