import { SHARED_UTILS } from '@shared-utils/utils';
import { useQuery } from '@tanstack/react-query';
import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useMemo, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { ApiAutomationsElAlgorithmsMessagesParams } from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import { safeStringify } from 'web/shared/utils/safe-stringify';
import type { COUNSEL_MESSAGE_SETTING_TAB_ITEMS } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/constants/tab';
import type { CounselAutomationAPIFormValues } from '../components/RegisterCounselAutomationDialogContent';
import { algorithmModeState } from '../containers/CounselMessageSetting/states/algorithm-mode';
import {
  selectedContentTabItemState,
  selectedMessageState,
} from '../containers/CounselMessageSetting/states/message-form';
import { originMessagesState } from '../containers/CounselMessageSetting/states/origin-message';
import { useSingleAlgorithmInfo } from './use-single-algorithm-info';

const fetchAutomationsElAlgorithmsMessages = async (
  params: ApiAutomationsElAlgorithmsMessagesParams,
) => {
  const response = await apiClient.v3.apiAutomationsElAlgorithmsMessages(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export const useAlgorithmData = () => {
  const { setValue, getValues } = useFormContext<CounselAutomationAPIFormValues>();
  const { hospitalID } = useSelectedHospitalInfo();

  const setSelectedContentTabItem = useSetAtom(selectedContentTabItemState);
  const setOriginMessages = useSetAtom(originMessagesState);
  const setSelectedMessage = useSetAtom(selectedMessageState);
  const algorithmMode = useAtomValue(algorithmModeState);

  const isFirstRender = useRef(true);

  const { singleAlgorithmInfo } = useSingleAlgorithmInfo();

  const { data: algorithmMessagesData } = useQuery({
    queryKey: [
      QUERY_KEY.apiAutomationsElAlgorithmsMessages,
      {
        algorithmId: algorithmMode.mode !== 'CREATE' ? algorithmMode.algorithmId : '',
        hospitalID,
      },
    ] as const,
    queryFn: ({ queryKey }) => {
      if (algorithmMode.mode !== 'CREATE') {
        return fetchAutomationsElAlgorithmsMessages(queryKey[1]);
      }
    },
    enabled: algorithmMode.mode !== 'CREATE',
    select: (data) =>
      data
        ?.filter((message) => !message.isCommonAlgorithmMessage)
        .map((message) => ({
          ...message,
          hasApprovedAlimtalkTemplate: message.hasApprovedAlimtalkTemplate ?? false,
        })),
  });

  const messagesData: CounselAutomationAPIFormValues['messages'] = useMemo(
    () =>
      algorithmMessagesData?.map(
        ({
          id,
          name,
          daysAfter,
          sendingType,
          content,
          consultationGuide,
          showConsultationGuide,
          timeOfDay,
          createdAt,
          updatedAt,
        }) => {
          const processedContent = {
            text: content?.text,
            image: content?.images
              ? safeStringify(
                  content.images.map((image) => ({
                    id: content.imageFileID,
                    url: image,
                  })),
                )
              : undefined,
            video: safeStringify(content?.video),
            workoutVideo: safeStringify(content?.workoutVideo),
            homecareContent: safeStringify(content?.homecareContent),
            event: safeStringify(content?.event),
            survey: {
              question: content?.survey?.question,
              answers: content?.survey?.answers,
            },
          };

          return {
            id,
            name,
            daysAfter,
            sendingType,
            content: processedContent,
            consultationGuide: showConsultationGuide ? consultationGuide : undefined,
            showConsultationGuide,
            timeOfDay,
            createdAt,
            updatedAt,
          };
        },
      ) || [],
    [algorithmMessagesData],
  );

  useEffect(() => {
    if (!isFirstRender.current) return;
    if (!singleAlgorithmInfo || !algorithmMessagesData || !messagesData) return;

    const setBaseData = () => {
      const { name, tagsHaveFolder, tagsHaveNoFolder, commonAlgorithm, appliedNationalities } =
        singleAlgorithmInfo;

      if (!getValues('name').length) {
        setValue('name', name);
      }

      if (!getValues('commonAlgorithm')) {
        setValue('commonAlgorithm', commonAlgorithm);
      }

      if (!getValues('targetTreatmentTags')?.length) {
        setValue('targetTreatmentTags', [
          ...tagsHaveFolder.flatMap(({ tags }) => tags.map((tag) => tag._id)),
          ...tagsHaveNoFolder.map((tag) => tag._id),
        ]);
      }

      if (!getValues('toBeAppliedNationalityIds')?.length) {
        setValue(
          'toBeAppliedNationalityIds',
          appliedNationalities.map((appliedNationality) => appliedNationality._id),
        );
      }
    };

    const setMessagesData = () => {
      if (!getValues('messages').length) {
        setValue('messages', messagesData);
      }
      setOriginMessages(algorithmMessagesData);
      setValue('messages', messagesData);
      setSelectedMessage({
        ...messagesData[0],
        id: messagesData[0].id,
        index: 0,
      });
    };

    if (algorithmMode.mode === 'EDIT' || algorithmMode.mode === 'COPY') {
      isFirstRender.current = false;

      const handleContentTabItem = () => {
        const newContentTabItem: Record<
          string,
          (typeof COUNSEL_MESSAGE_SETTING_TAB_ITEMS)[number]
        > = {};

        for (const message of messagesData) {
          if (message.content.image && message.content.image.length > 0) {
            newContentTabItem[message.id] = '이미지';
          } else if (message.content.video) {
            newContentTabItem[message.id] = '동영상';
          } else if (message.content.workoutVideo) {
            newContentTabItem[message.id] = '홈케어콘텐츠';
          } else if (message.content.homecareContent || message.content.event) {
            newContentTabItem[message.id] = '이벤트';
          } else {
            newContentTabItem[message.id] = '텍스트';
          }
        }

        setSelectedContentTabItem((prev) => ({
          ...prev,
          ...newContentTabItem,
        }));
      };
      handleContentTabItem();

      setBaseData();
      setMessagesData();
    }
  }, [singleAlgorithmInfo, algorithmMessagesData, algorithmMode.mode]);
};
