import { SHARED_UTILS } from '@shared-utils/utils';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { useCallback, useEffect, useMemo } from 'react';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { ApiChatElFindParams, ChatFileAndContentIDs } from 'web/apis/swaggers/swagger-docs';
import { selectedMessageIDState } from 'web/templates/CustomerChat/components/SupportBot/states/selected-message-id';
import { chatRoomIDSelector } from 'web/templates/CustomerChat/states/selected-chat-room';
import { SORT_WAY } from '../../../../../constants/message-sort-way';

const INTERVAL_NUM = 20;

const getChat = async (query: ApiChatElFindParams) => {
  const { data } = await apiClient.v3.apiChatElFind(query);
  return SHARED_UTILS.api.checkApiResponse(data);
};

export const useInfiniteChatData = () => {
  const queryClient = useQueryClient();
  const chatRoomID = useAtomValue(chatRoomIDSelector);
  const selectedMessageID = useAtomValue(selectedMessageIDState);

  const query = useMemo(
    () => ({
      chatRoomID,
      key: 'sentAt',
      way: SORT_WAY,
    }),
    [chatRoomID],
  );

  const queryResult = useInfiniteQuery({
    queryKey: [QUERY_KEY.apiChatElFind, query] as const,
    queryFn: ({ pageParam }) => {
      const { skip, limit } = pageParam;
      return getChat({ ...query, skip, limit } as ApiChatElFindParams);
    },
    getNextPageParam: (lastPage, pages) => {
      if ((lastPage?.length ?? 0) < INTERVAL_NUM || selectedMessageID) return undefined;

      const pagesNum = pages.reduce((acc, page) => acc + page.length, 0);
      return { skip: pagesNum, limit: INTERVAL_NUM };
    },
    initialPageParam: { limit: INTERVAL_NUM, skip: 0 },
    enabled: !!chatRoomID,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: 0,
    gcTime: 0,
  });

  const { data, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } = queryResult;

  // 새 메시지 추가를 위한 캐시 업데이트 함수
  const updateChatCache = useCallback(
    (newMessage: ChatFileAndContentIDs) => {
      queryClient.setQueryData<typeof data>([QUERY_KEY.apiChatElFind, query], (oldData) => {
        if (!oldData) return oldData;

        return {
          ...oldData,
          pages: [
            [newMessage, ...oldData.pages[0]], // 최신 메시지를 첫 페이지 앞에 추가
            ...oldData.pages.slice(1),
          ],
        };
      });
    },
    [query, queryClient],
  );

  // 채팅방 변경 시 prefetch
  useEffect(() => {
    if (!chatRoomID) return;

    // 다음 2페이지까지 미리 프리패치
    const prefetchNextPages = async () => {
      const pagesToPrefetch = [1, 2]; // 다음 2페이지를 미리 가져옴

      for (const pageOffset of pagesToPrefetch) {
        await queryClient.prefetchInfiniteQuery({
          queryKey: [QUERY_KEY.apiChatElFind, query],
          queryFn: ({ pageParam = { skip: 0, limit: INTERVAL_NUM } }) => {
            return getChat({
              ...query,
              skip: pageParam.skip + INTERVAL_NUM * pageOffset,
              limit: INTERVAL_NUM,
            } as ApiChatElFindParams);
          },
          initialPageParam: { skip: 0, limit: INTERVAL_NUM },
        });
      }
    };

    void prefetchNextPages();
  }, [chatRoomID, query, queryClient]);

  const messages = useMemo(() => data?.pages.flat() ?? [], [data?.pages]);

  return {
    data: messages,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    updateChatCache, // 웹소켓 등으로 새 메시지를 받았을 때 사용
  };
};

// 채팅 관련 유틸리티 함수들
export const chatQueryUtils = {
  invalidateChats: (queryClient: ReturnType<typeof useQueryClient>, chatRoomID: string) => {
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEY.apiChatElFind, { chatRoomID }],
    });
  },

  prefetchChats: async (queryClient: ReturnType<typeof useQueryClient>, chatRoomID: string) => {
    await queryClient.prefetchInfiniteQuery({
      queryKey: [QUERY_KEY.apiChatElFind, { chatRoomID, key: 'sentAt', way: SORT_WAY }],
      queryFn: () =>
        getChat({
          chatRoomID,
          key: 'sentAt',
          way: SORT_WAY,
          skip: 0,
          limit: INTERVAL_NUM,
        } as ApiChatElFindParams),
      initialPageParam: { limit: INTERVAL_NUM, skip: 0 },
    });
  },
};
