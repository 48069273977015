import type { TreatmentTag } from '@apis/swaggers/swagger-docs';
import { treatmentTagsState } from '@templates/HospitalSetting/containers/ServiceSetting/containers/TreatmentTag/states/hospital-treatment-tags';
import { tagInputErrorMessageState } from '@templates/HospitalSetting/containers/ServiceSetting/containers/TreatmentTag/states/tag-input-error-message-state';
import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';
import { useAtom } from 'jotai';
import { useSetAtom } from 'jotai/index';
import type React from 'react';

export default function TagCreateButton(treatmentTag: TreatmentTag) {
  const { name } = treatmentTag;

  const [treatmentTags, setTreatmentTags] = useAtom(treatmentTagsState);

  const setTagInputErrorMessage = useSetAtom(tagInputErrorMessageState);

  const handleClick = () => {
    const duplicated = treatmentTags.slice(1).some((tag) => tag.name === name);
    if (duplicated) {
      setTagInputErrorMessage('이미 사용중인 태그명입니다. 다른 이름으로 설정해 주세요.');
    } else {
      addTempTag();
    }
  };

  const addTempTag = () => {
    const tempTags = treatmentTags.filter((tag) => tag.tagId.startsWith('temp'));

    const nextTempId =
      tempTags.length > 0
        ? Math.max(...tempTags.map((tag) => Number.parseInt(tag.tagId.replace('temp', ''), 10))) + 1
        : 1;

    const newTempTag = {
      tagId: `temp${nextTempId}`,
      name: '',
      color: '#007AFF',
    };

    setTreatmentTags([newTempTag, ...treatmentTags]);
  };

  return (
    <ContainedButton disabled={!name} onClick={handleClick}>
      추가
    </ContainedButton>
  );
}
