import { overlayPageService } from '@afterdoc-design-system/components/Molecules/OverlayPage/OverlayPage.service';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { isServiceManagerNavigatorVisibleState } from 'web/shared/states/is-service-manager-navigator-visible';

interface OpenDialogOptions {
  replace?: boolean;
}

export const useOpenAutomationDialog = () => {
  const isServiceManagerNavigatorVisible = useAtomValue(isServiceManagerNavigatorVisibleState);

  const openAutomationDialog = useCallback(
    (
      Component: React.ReactNode,
      id: string,
      customClasses?: string,
      options?: OpenDialogOptions,
    ) => {
      const positionLeft = isServiceManagerNavigatorVisible ? 140 : 70;
      const widthClass = isServiceManagerNavigatorVisible
        ? 'w-[calc(100vw-140px)]'
        : 'w-[calc(100vw-70px)]';

      if (options?.replace) {
        overlayPageService.replace(Component, {
          id,
          position: {
            left: positionLeft,
            top: 0,
          },
          className: customTwMerge(
            'h-screen flex-col items-center justify-center bg-blueLight',
            widthClass,
            customClasses || '',
          ),
        });
      } else {
        overlayPageService.push(Component, {
          id,
          position: {
            left: positionLeft,
            top: 0,
          },
          className: customTwMerge(
            'h-screen flex-col items-center justify-center bg-blueLight',
            widthClass,
            customClasses || '',
          ),
        });
      }
    },
    [isServiceManagerNavigatorVisible],
  );

  return openAutomationDialog;
};
