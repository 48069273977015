import * as React from "react";
import type { SVGProps } from "react";
const SvgLine = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path fill="#000" d="M18 26h-8v-4h8zM28 26h-8v-4h8zM38 26h-8v-4h8z" />
  </svg>
);
export default SvgLine;
