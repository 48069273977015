import { modalService } from '@afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { overlayPageService } from '@afterdoc-design-system/components/Molecules/OverlayPage/OverlayPage.service';
import { useAtom, useAtomValue } from 'jotai';
import { useCallback } from 'react';
import type { UseFormReset } from 'react-hook-form';
import { OVERLAY_PAGE_ID } from 'web/shared/constants/overlay-page-id';
import type { CounselAutomationAPIFormValues } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/components/RegisterCounselAutomationDialogContent';
import { isEditDisabledByKakaoAlimTalkState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/hooks/states/is-edit-disabled-by-kakao-alim-talk';
import { selectedMessageIndexState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/message-form';
import { originMessagesState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/origin-message';
import { useCounselAutomationResetAll } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/hooks/use-counsel-automation-reset-all';

interface UseKakaoCheckWhetherEditOrNotProps {
  reset: UseFormReset<CounselAutomationAPIFormValues>;
}

export const useKakaoCheckWhetherEditOrNot = ({ reset }: UseKakaoCheckWhetherEditOrNotProps) => {
  const [isEditDisabled, setIsEditDisabled] = useAtom(isEditDisabledByKakaoAlimTalkState);

  const originMessages = useAtomValue(originMessagesState);
  const selectedMessageIndex = useAtomValue(selectedMessageIndexState);

  const hasApprovedTemplate = originMessages[selectedMessageIndex]?.hasApprovedAlimtalkTemplate;

  const { resetAll } = useCounselAutomationResetAll({
    reset,
  });

  const handleFormClick = useCallback(
    (e?: React.MouseEvent, successCallback?: () => void) => {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }

      if (!isEditDisabled) {
        return;
      }

      return modalService.defaultWarning({
        id: 'kakao-alimtalk-warning',
        title: '카카오 알림톡 승인된 메시지입니다.',
        contents:
          '내용을 수정하실 경우 알림톡 승인이 거부될 수 있습니다.\n승인이 완료되기 전까지 애프터닥 앱에 가입한 사람들에게만 수정된 메시지가 전달됩니다.\n내용을 계속 수정하시려면 “네” 버튼을 클릭해주세요.',
        onConfirm: () => {
          modalService.popById('kakao-alimtalk-warning');
          setIsEditDisabled(false);
          successCallback?.();
        },
        onCancel: () => {
          modalService.popById('kakao-alimtalk-warning');
          overlayPageService.popById(OVERLAY_PAGE_ID['register-counsel-automation-dialog']);
          resetAll();
        },
      });
    },
    [resetAll, setIsEditDisabled, isEditDisabled],
  );

  return {
    handleFormClick,
    showOverlay: hasApprovedTemplate && isEditDisabled,
  };
};
