import AuthorizationTypeCard from '@templates/HospitalSetting/containers/AuthorizationSetting/containers/AuthorizationTypeSelection/components/AuthorizationTypeCard';
import { hospitalAuthorizationTypesState } from '@templates/HospitalSetting/containers/AuthorizationSetting/jotai/hospital-authorization-types';
import { useAtomValue } from 'jotai';

export default function AuthorizationTypeSelectionContainer() {
  const authorizationTypes = useAtomValue(hospitalAuthorizationTypesState);

  return (
    <>
      <div className='mt-40 grid grid-cols-3 gap-10'>
        {authorizationTypes.map((authorizationType, index) => (
          <AuthorizationTypeCard
            key={authorizationType._id}
            index={index}
            authorizationType={authorizationType}
          />
        ))}
      </div>
    </>
  );
}
