import { merge } from 'lodash-es';
import { mockedApiClient } from '../../mocks/mock-api-instance';
import { Api } from '../swaggers/swagger-docs';

type RealApiClientType = InstanceType<typeof Api>;

type Combine<T, U> = T & U;

const combineClients = <T, U>(realClient: T, mockClient: U): Combine<T, U> => {
  return merge(realClient, mockClient);
};

const realApiClient = new Api() as RealApiClientType;

const composedApiClient = combineClients(realApiClient, mockedApiClient);

export const apiClient = composedApiClient;
