import { atom } from 'jotai';
import { atomWithReset, selectAtom } from 'jotai/utils';
import type { MessageInput } from 'web/apis/swaggers/swagger-docs';
import type { MARKETING_MESSAGE_SETTING_TAB_ITEMS } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/containers/MessageContentForm/constants/tab';

export const selectedMessageState = atomWithReset<
  | (MessageInput & {
      id: string;
      index: number;
    })
  | undefined
>(undefined);
export const selectedMessageIdState = selectAtom(selectedMessageState, (state) => state?.id ?? '');

export const selectedMessageIndexState = selectAtom(
  selectedMessageState,
  (state) => state?.index ?? -1,
);
export const selectedContentTabItemState = atomWithReset<
  Record<string, (typeof MARKETING_MESSAGE_SETTING_TAB_ITEMS)[number]>
>({});
export const sendTypeState = atom<MessageInput['sendingType']>('BASIC');
