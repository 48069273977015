import TextFieldSelectBoxDropdown from '@afterdoc-design-system/components/Molecules/TextSelect/TextFieldSelectBoxDropdown';
import { useAtomValue, useSetAtom } from 'jotai';
import {
  selectedSearchCriteriaIndexState,
  selectedSearchCriteriaState,
} from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/containers/SearchFilter/states/selected-search-criteria';
import { CUSTOMER_SEARCH_FIELD_OPTIONS } from '../constants/dropdown-items';

export default function TableSearchCriteriaDropdown() {
  const selectedSearchCriteriaIndex = useAtomValue(selectedSearchCriteriaIndexState);
  const setSelectedSearchCriteria = useSetAtom(selectedSearchCriteriaState);

  const handleDropdownSelect = () => {
    const searchInputElement = document.getElementById('customer-management-search-input');
    if (searchInputElement) {
      searchInputElement.focus();
    }
  };

  return (
    <TextFieldSelectBoxDropdown
      options={CUSTOMER_SEARCH_FIELD_OPTIONS.map((option) => option.value)}
      manualInput={false}
      onSelect={(index) => {
        setSelectedSearchCriteria(CUSTOMER_SEARCH_FIELD_OPTIONS[index].key);
        handleDropdownSelect();
      }}
      selectedIndex={selectedSearchCriteriaIndex}
      wrapperClassName='mr-20'
    />
  );
}
