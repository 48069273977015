import { useFormContext } from 'react-hook-form';
import type { MarketingAutomationAPIFormValues } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/components/RegisterMarketingAutomationDialogContent';
import AlgorithmNameSetting from 'web/templates/Automation/containers/shared/components/Settings/AlgorithmNameSetting';

export default function MarketingAlgorithmNameSetting() {
  const { setValue, register, watch } = useFormContext<MarketingAutomationAPIFormValues>();

  const name = watch('name');

  return (
    <AlgorithmNameSetting
      value={name}
      onChange={(value) => setValue('name', value)}
      inputProps={{
        ...register('name', { required: true }),
      }}
    />
  );
}
