import { atomWithReset } from 'jotai/utils';

export const selectedCellState = atomWithReset<{
  row: number;
  col: number;
}>({
  row: 0,
  col: 0,
});
export const focusedCellState = atomWithReset<{
  row: number | null;
  col: number | null;
}>({
  row: null,
  col: null,
});
