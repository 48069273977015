import { SHARED_UTILS } from '@shared-utils/utils';
import { useSuspenseQuery } from '@tanstack/react-query';
import { Suspense, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { ApiServiceSettingsElCountriesParams } from 'web/apis/swaggers/swagger-docs';
import FullLoading from 'web/shared/components/FullLoading/FullLoading';
import { usePatientDetailInfo } from 'web/shared/hooks/use-get-patient-detail-info';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import {
  COUNTRY_LIMIT,
  KOREA_COUNTRY_CODE_ISO_ALPHA_3,
} from 'web/templates/CustomerManagement/constants/country-limit';
import CustomerInfoFormContainer from './containers/CustomerInfoForm/CustomerInfoForm.container';
import ServiceManagerMemoContainer from './containers/ServiceManagerMemo/ServiceManagerMemo.container';
import SaveButton from './containers/components/SaveButton';

export type APIFormValues = Parameters<typeof apiClient.v3.apiPatientsElUpsertSingle>[0];

const fetchCountriesList = async (params: ApiServiceSettingsElCountriesParams) => {
  const response = await apiClient.v3.apiServiceSettingsElCountries(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export default function CareAndCustomerInfoInputFormContainer() {
  const { hospitalID } = useSelectedHospitalInfo();
  const patientInfo = usePatientDetailInfo();

  const { data: countriesList } = useSuspenseQuery({
    queryKey: [
      QUERY_KEY.apiServiceSettingsElCountries,
      { hospitalID, limit: COUNTRY_LIMIT },
    ] as const,
    queryFn: ({ queryKey }) => fetchCountriesList(queryKey[1]),
    select: (data) =>
      data.filter((country) => country.countryCodeISOAlpha3 !== KOREA_COUNTRY_CODE_ISO_ALPHA_3),
  });

  const memoizedDefaultValues: APIFormValues = useMemo(
    () => ({
      name: patientInfo.name,
      patientId: patientInfo.patientId,
      phoneNumber: patientInfo.phoneNumber,
      birthdate: patientInfo.birthdate,
      chartNumber: patientInfo.chartNumber,
      // countryCodeId: patientInfo.countryCode.id,
      gender: patientInfo.gender,
      isFirstVisit: patientInfo.isFirstVisit,
      treatmentTagIds: patientInfo.treatmentTags?.map((tag) => tag.tagId),
      nationalityId:
        patientInfo.nationality &&
        countriesList?.some((country) => country.countryId === patientInfo.nationality?.id)
          ? patientInfo.nationality.id
          : undefined,
      wantToDeleteAutomationAppliedTreatmentTag: false,
    }),
    [patientInfo, countriesList],
  );

  const methods = useForm<APIFormValues>({
    defaultValues: memoizedDefaultValues,
  });

  if (!patientInfo) return null;

  return (
    <FormProvider {...methods}>
      <div className='mt-16 mb-60 h-fit flex-col-center justify-between rounded-r16 bg-white100 p-20'>
        <div className='flex flex-col'>
          <Suspense fallback={<FullLoading />}>
            <CustomerInfoFormContainer countriesList={countriesList} {...patientInfo} />
          </Suspense>
          <Suspense>
            <SaveButton disabled={patientInfo.isClosed} />
          </Suspense>
        </div>
        {/* 이용 제외인 고객의 경우, 서비스매니저 메모를 보여주지 않음 */}
        <Suspense>{!patientInfo.isClosed && <ServiceManagerMemoContainer />}</Suspense>
      </div>
    </FormProvider>
  );
}
