import { apiClient } from '@apis/instances/api-client';
import type {
  KakaoAlimFriendTalkLinkageHandlerBodyRequest,
  KakaoAuthenticationNumberHandlerBodyRequest,
} from '@apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from '@shared/hooks/use-selected-hospital-info';
import { useMutation } from '@tanstack/react-query';
import { hasLinkageOrRequestHistoryState } from '@templates/HospitalSetting/containers/ChannelIntegration/states/has-linkage-or-request-history-state';
import { channelAdminPhoneErrorAtom } from '@templates/HospitalSetting/containers/ChannelIntegration/states/kakao-consult-form-error-state';
import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';
import OutlinedButton from 'afterdoc-design-system/components/Atoms/Button/OutlinedButton';
import TextInput from 'afterdoc-design-system/components/Atoms/Input/TextInput';
import LabelText from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import { fullDimmedLoadingService } from 'afterdoc-design-system/components/Atoms/Loading/FullDimmedLoading/FullDimmedLoading.service';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import HoverTooltip from 'afterdoc-design-system/components/Atoms/Tooltip/HoverTooltip';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import { useAtom, useAtomValue } from 'jotai/index';
import type React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { ApiError } from 'utils/api/check-api-response';
import { SHARED_UTILS } from 'utils/utils';

const requestKakaoAuthNumber = async (params: KakaoAuthenticationNumberHandlerBodyRequest) => {
  const { data } = await apiClient.v3.kakaoAuthenticationNumberHandler(params);
  const isValidRequest = SHARED_UTILS.api.checkApiResponse(data).isAccurateKakaoAuthRequest;

  if (isValidRequest) return true;

  throw new ApiError('Invalid Kakao authentication request');
};

interface AdminPhoneInputProps {
  isAuthRequestDone: boolean;
  handleChangeDoneStatus: (isDone: boolean) => void;
}

export default function AdminPhoneInput({
  isAuthRequestDone,
  handleChangeDoneStatus,
}: AdminPhoneInputProps) {
  const { register, setValue } = useFormContext<KakaoAlimFriendTalkLinkageHandlerBodyRequest>();
  const { hospitalID } = useSelectedHospitalInfo();

  const [channelAdminPhoneError, setChannelAdminPhoneError] = useAtom(channelAdminPhoneErrorAtom);

  const hasLinkageOrRequestHistory = useAtomValue(hasLinkageOrRequestHistoryState);

  const inputValue = useWatch({ name: 'channelAdminPhone' }) ?? '';
  const searchableChannelId = useWatch({ name: 'searchableChannelId' }) ?? '';

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChannelAdminPhoneError(false);
    setValue('channelAdminPhone', e.target.value.replace(/[^0-9]/g, ''));
  };

  const requestMutation = useMutation({
    mutationFn: () =>
      requestKakaoAuthNumber({
        hospitalID,
        searchableChannelId,
        channelAdminPhoneNumber: inputValue,
      }),
    onMutate: () => fullDimmedLoadingService.on(),
    onSettled: () => fullDimmedLoadingService.off(),
    onSuccess: () => {
      handleChangeDoneStatus(true);
      toastService.successMsg({ text: '인증번호가 발송되었습니다.' });
    },
    onError: (error: Error) => {
      console.error('kakao  authentication number error :', error);
      toastService.errorMsg({ text: '인증번호 요청에 실패했습니다.' });
    },
  });

  return (
    <div className='flex min-h-[32px] gap-4'>
      <div className='flex flex-grow gap-10'>
        <div className='flex h-32 w-[140px] items-center gap-6'>
          <LabelText isRequired width={'w-fit'} className='flex-center'>
            관리자 휴대폰번호
          </LabelText>
          <HoverTooltip
            position='topRight'
            text={
              '카카오 채널 관리자 센터>관리>관리자에 등록된 계정의 휴대폰번호를 입력해 주세요.'
            }>
            <Icon name={'info-circle-outline'} color={'black200'} size={16} />
          </HoverTooltip>
        </div>
        <TextInput
          maxLength={1000}
          className={'flex-grow'}
          disabled={hasLinkageOrRequestHistory !== 'NotLinked' || isAuthRequestDone}
          errorTextPosition={'vertical'}
          hasError={channelAdminPhoneError}
          value={inputValue}
          placeholder='01012345678'
          errorText='관리자 휴대폰번호를 입력해 주세요.'
          {...register('channelAdminPhone', { onChange: handleChange })}
        />
      </div>
      {hasLinkageOrRequestHistory === 'NotLinked' && (
        <>
          {isAuthRequestDone && (
            <OutlinedButton className='h-32' onClick={() => handleChangeDoneStatus(false)}>
              재입력
            </OutlinedButton>
          )}
          {!isAuthRequestDone && (
            <ContainedButton
              className='h-32'
              disabled={!inputValue || !searchableChannelId}
              onClick={() => requestMutation.mutate()}>
              인증요청
            </ContainedButton>
          )}
        </>
      )}
    </div>
  );
}
