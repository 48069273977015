export const COUNSEL_MESSAGE_SETTING_TAB_ITEMS = [
  '텍스트',
  '이미지',
  '동영상',
  '홈케어콘텐츠',
  '이벤트',
] as const;

export const COUNSEL_MESSAGE_CONTENT_RESET_MAPPING = {
  이미지: ['image'],
  동영상: ['video'],
  홈케어콘텐츠: ['workoutVideo'],
  이벤트: ['event', 'homecareContent'],
} as const;
