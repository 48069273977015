export const DEV_HOSPITAL_ID = '613d614a25276223a9b1eea8';
export const DEV_USER_ID = '613aa681cb516152eed8fdb3';
//mp_n7하다희가짜오리 //userType: 3000+ //를 할 경우, 613d614a25276223a9b1eea8로 변경해줘야 함.

export const integrationSetting = {
  isRemoved: true,
  _id: '651f64a06c7f3cb615cc2473',
  hospitalID: '613d614a25276223a9b1eea8',
  clientToken:
    'UEb45chqxCdTWbthzRZ0pyVs4sW9opInZmZpPcCdUol5lVPu95meggpDHbYnuC8Ok5cgAPryBoM5YbNsjEo2zDXOgAxc4aAH7C1uWhyaYByhB4RnpirVcSSzDB7cmjmG87VM6pyAiHEesrMgqReUt3WvtgojKUgY2RKz9',
  integrationType: 'ALM',
  customObject: {
    defaultLabelID: [0],
    defaultCancelID: [2, 12],
  },
  createdAt: '2023-10-06T01:36:32.243Z',
  updatedAt: '2024-08-23T02:07:41.212Z',
  __v: 0,
  usingDashboard: false,
  usingRegist: false,
  usingReservation: false,
};
