import type { HospitalInfoErrorType } from '@templates/HospitalSetting/types/hospital-info-error-type';
import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';

export const HospitalInfoInputErrorMessagesState = atomWithReset<HospitalInfoErrorType>({
  shortName: null,
  operatingHours: null,
  address: null,
  inquiryMethod: null,
  autoGreeting: null,
  basicMessage: null,
  reservationMessage: null,
  resendMessage: null,
});

export const createErrorMessageAtom = (key: keyof HospitalInfoErrorType) =>
  atom(
    (get) => get(HospitalInfoInputErrorMessagesState)[key],
    (get, set, update: string | null) => {
      const currentErrors = get(HospitalInfoInputErrorMessagesState);
      set(HospitalInfoInputErrorMessagesState, {
        ...currentErrors,
        [key]: update || null,
      });
    },
  );
