import AddAccountInputArea from '@templates/HospitalSetting/containers/AllAccountSetting/containers/AddAccount/components/AddAccountInputArea/AddAccountInputArea';
import AddAccountTitle from '@templates/HospitalSetting/containers/AllAccountSetting/containers/AddAccount/components/AddAccountTitle';
import React from 'react';

export default function AddAccountContainer() {
  return (
    <>
      <div className='mb-10 px-80 pt-40 pb-20'>
        <AddAccountTitle />
        <AddAccountInputArea />
      </div>
    </>
  );
}
