import type { HospitalsAccountsCountTuningFormValues } from '@templates/HospitalSetting/containers/AllAccountSetting/containers/AddAccount/components/AddAccountRequestDialog/components/AccountFormActionButtons';
import TextInput from 'afterdoc-design-system/components/Atoms/Input/TextInput';
import LabelText from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

export interface AccountRequestFormFieldProps {
  fieldName: keyof HospitalsAccountsCountTuningFormValues;
  label: string;
  placeholder: string;
  errorMessage: string;
}

export default function AccountRequestFormField({
  fieldName,
  label,
  placeholder,
  errorMessage,
}: AccountRequestFormFieldProps) {
  const {
    register,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const inputValue = watch(fieldName) ?? '';

  const maxLength = useMemo(() => {
    if (fieldName === 'requesterContact') return 15;
    if (fieldName === 'requestedAccountCount') return 5;
    return undefined;
  }, [fieldName]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      clearErrors(fieldName);

      let { value } = e.target;

      if (fieldName !== 'requesterName') {
        value = value.replace(/[^0-9]/g, '');
      }
      setValue(fieldName, value);
    },
    [fieldName],
  );

  return (
    <div className='flex w-full gap-10'>
      <LabelText
        isRequired={true}
        width={'140px'}
        textClassName={fieldName === 'requestedAccountCount' ? 'text-blue500' : ''}
        className='flex h-[32px] items-center'>
        {label}
      </LabelText>
      <div className='flex flex-grow flex-col gap-4'>
        <TextInput
          id={fieldName}
          placeholder={placeholder}
          value={inputValue}
          maxLength={maxLength}
          inputClassName='w-full'
          {...register(fieldName, { onChange: handleChange })}
        />
        {!!errors[fieldName] && <span className='text-Body10 text-red500'>{errorMessage}</span>}
      </div>
    </div>
  );
}
