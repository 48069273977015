import HoverTooltip from '@afterdoc-design-system/components/Atoms/Tooltip/HoverTooltip';
import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';
import {
  CategoryScale,
  type ChartData,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import type { CustomerCountData } from 'web/apis/swaggers/swagger-docs';
import { CHART_OPTIONS } from 'web/templates/Automation/containers/Main/DashBoardContent/components/constants/chart-options';
import CardItemLayout from 'web/templates/Automation/containers/Main/shared/components/CardItemLayout';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface ManagedCustomersCardItemProps {
  managedCustomers?: CustomerCountData;
}

export default function ManagedCustomersCardItem({
  managedCustomers,
}: ManagedCustomersCardItemProps) {
  if (!managedCustomers) {
    return <CardItemLayout />;
  }

  const { total, monthlyCounts } = managedCustomers;

  const chartData: ChartData<'line', number[], string> = {
    labels: monthlyCounts?.map((item) => `${item.month}월`) || [],
    datasets: [
      {
        label: '고객 수',
        data: monthlyCounts?.map((item) => item.count || 0) || [],
        borderColor: '#64B5F6',
        backgroundColor: 'rgba(100, 181, 246, 0.1)',
        fill: true,
        tension: 0,
        pointRadius: 0,
        datalabels: {
          display: false,
        },
      },
    ],
  };

  return (
    <CardItemLayout className='flex flex-col gap-24'>
      <TopInfo number={total} />
      <div className='mt-4 h-[90px]'>
        <Line data={chartData} options={CHART_OPTIONS} height={90} />
      </div>
    </CardItemLayout>
  );
}

interface TopInfoProps {
  number?: number;
}

function TopInfo({ number }: TopInfoProps) {
  return (
    <div className='flex items-start justify-between'>
      <div>
        <div className='text-Header12 text-black500'>누적 상담자동화 대상자 수</div>
        <div className='mt-8 flex items-center gap-10'>
          <div className='text-Header16 text-black700'>
            {number ? number.toLocaleString() : '-'}명
          </div>
        </div>
      </div>
      <HoverTooltip text={'현재까지 상담자동화가 적용된\n모든 고객 수를 의미합니다.'}>
        <div className='p-6'>
          <Icon name='info-circle-outline' color='black200' size={20} />
        </div>
      </HoverTooltip>
    </div>
  );
}
