export const generateTexMessageAfterReservation = (type: string, dateString: string) => {
  switch (type) {
    case 'create':
      return `${dateString}로 예약 도와드렸습니다.`;
    case 'update':
      return `${dateString}으로 변경 도와드렸습니다.`;
    case 'remove':
      return `${dateString} 예약 취소 도와드렸습니다.`;
    default:
      return '';
  }
};
