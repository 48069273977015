export const MESSAGE_TEXT_MAX_LENGTH = 500;
export const urlRegex = /(https?:\/\/[^\s]+|www\.[^\s]+)/g;
export const MESSAGE_TYPE_CODE = {
  SYSTEM: 40000,
  TEXT_MOCKING: 50100,
  IMAGE_MOCKING: 50300,
  FILE_MOCKING: 50500,
};
export const USER_TYPE_CODE = {
  PATIENT: 1000,
};
