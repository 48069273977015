import type { AuthorizationSection, AuthorizationTypeForV3 } from '@apis/swaggers/swagger-docs';
import type { AuthorizationItemType } from '@templates/HospitalSetting/containers/AuthorizationSetting/constants/type-items';
import AuthorizationItem from '@templates/HospitalSetting/containers/AuthorizationSetting/containers/AuthorizationTypeDetailSetting/components/AuthorizationItem/components/AuthorizationItem';
import AuthorizationListTitle from '@templates/HospitalSetting/containers/AuthorizationSetting/containers/AuthorizationTypeDetailSetting/components/AuthorizationItem/components/AuthorizationListTitle';
import { selectedAuthorizationTypeState } from '@templates/HospitalSetting/containers/AuthorizationSetting/jotai/hospital-authorization-types';
import { useAtomValue } from 'jotai/index';
import { useEffect, useState } from 'react';

interface AuthorizationListProps {
  item: AuthorizationItemType;
}

const getAuthorizationSectionByKey = (data: AuthorizationTypeForV3, key: string) => {
  for (const property in data) {
    if (Object.prototype.hasOwnProperty.call(data, property)) {
      const value = data[key as keyof AuthorizationTypeForV3];
      if (value && typeof value === 'object' && property === key) {
        return value as AuthorizationSection;
      }
    }
  }
};

export default function AuthorizationList({ item }: AuthorizationListProps) {
  const { key, icon } = item;

  const selectedAuthorizationType = useAtomValue(selectedAuthorizationTypeState);
  const [authorizationSection, setAuthorizationSection] = useState<AuthorizationSection>();

  useEffect(() => {
    if (selectedAuthorizationType) {
      const data = getAuthorizationSectionByKey(selectedAuthorizationType, key);
      setAuthorizationSection(data);
    }
  }, [selectedAuthorizationType]);

  return (
    <>
      <div className='flex w-full gap-60 border-white600 border-b py-10'>
        <AuthorizationListTitle key={key} icon={icon} name={authorizationSection?.name} />
        <div className='grid flex-grow grid-cols-3 gap-10'>
          {authorizationSection?.types?.map((item) => (
            <AuthorizationItem key={item.name} group={key} item={item} />
          ))}
        </div>
      </div>
    </>
  );
}
