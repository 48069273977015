import { hospitalAddressQueryKeyState } from '@templates/HospitalSetting/containers/HospitalProfile/components/HospitalAddress/components/AddressSearchDialog/jotai/address-query-key';
import OutlinedButton from 'afterdoc-design-system/components/Atoms/Button/OutlinedButton';
import TextInput from 'afterdoc-design-system/components/Atoms/Input/TextInput';
import { useSetAtom } from 'jotai/index';
import React, { type KeyboardEvent, useEffect, useRef, useState } from 'react';

export default function SearchKeyword() {
  const [keyword, setKeyword] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const setHospitalAddressQueryKey = useSetAtom(hospitalAddressQueryKeyState);

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleSearch = () => {
    setHospitalAddressQueryKey((prevQueryKey) => [
      prevQueryKey[0],
      {
        ...prevQueryKey[1],
        keyword,
        currentPage: '1',
      },
    ]);
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <>
      <div className='flex items-center justify-center gap-10 px-20 py-10'>
        <TextInput
          placeholder='주소를 입력해 주세요. 예) 테헤란로 420길'
          className='flex-grow'
          value={keyword}
          ref={inputRef}
          onChange={(e) => setKeyword(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <OutlinedButton onClick={handleSearch}>검색</OutlinedButton>
      </div>
    </>
  );
}
