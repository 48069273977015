const MAX_LENGTH = 15;

const splitFileName = (fileName: string) => {
  if (fileName === '') return { name: '', extension: '' };
  const split = fileName.split('.');
  const extension = split.pop();
  const name = split.join('.');
  return { name, extension };
};

export const truncateFileName = (fileName: string | undefined) => {
  if (!fileName) return '';

  const { name, extension } = splitFileName(fileName);

  // 한글 분리를 위해 Intl.Segmenter 사용
  if (name.length > MAX_LENGTH) {
    const segmenter = new Intl.Segmenter('ko', { granularity: 'grapheme' });
    const segments = Array.from(segmenter.segment(name)).slice(0, MAX_LENGTH);
    const truncatedName = segments.map((segment) => segment.segment).join('');
    return `${truncatedName}...${extension}`;
  }

  return `${name}.${extension}`;
};
