import Scrollbar from '@afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import Pagination from '@afterdoc-design-system/components/Molecules/Pagination/Pagination';
import TextFieldSelectBoxDropdown from '@afterdoc-design-system/components/Molecules/TextSelect/TextFieldSelectBoxDropdown';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { useSetAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { PATIENT_OPTIONS } from 'web/shared/constants/options';
import CustomerManagementReadOnlyTableHeader from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementReadOnlyTable/components/CustomerManagementReadOnlyTableHeader';
import type { ReadOnlyHeaderFilterState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementReadOnlyTable/states/read-only-header-filter';
import { DestroyBlockerProvider } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/context/component-destory-blocker.context';
import {
  currentPageState,
  selectedPatientOptionState,
  totalSearchCountState,
} from '../../../shared/containers/SearchFilter/states/search';
import CustomerManagementReadOnlyTableBody from './components/CustomerManagementReadOnlyTableHeader/CustomerManagementReadOnlyTableBody';

export default function CustomerManagementReadOnlyTableContainer() {
  const [totalCount, setTotalCount] = useState(0);
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [isNoDataOrNoSearchResult, setIsNoDataOrNoSearchResult] = useState(false);

  const resetCurrentPage = useResetAtom(currentPageState);
  const resetTotalCount = useResetAtom(totalSearchCountState);
  const resetSelectedPatientOption = useResetAtom(selectedPatientOptionState);

  const setSelectedPatientOption = useSetAtom(selectedPatientOptionState);

  const methods = useForm<ReadOnlyHeaderFilterState>();

  const handlePageChange = useCallback((page: number) => {
    setSelectedPage(page);
  }, []);

  const handleTotalCount = (count: number) => {
    setTotalCount(count);
  };

  const handleNoDataOrNoSearchResult = (isNoData: boolean) => {
    setIsNoDataOrNoSearchResult(isNoData);
  };

  useEffect(() => {
    setSelectedPage(1);
  }, [totalCount]);

  useEffect(() => {
    return () => {
      resetCurrentPage();
      resetTotalCount();
      resetSelectedPatientOption();
    };
  }, []);

  return (
    <DestroyBlockerProvider>
      <FormProvider {...methods}>
        <div className='h-full max-h-[calc(100vh-187px)] bg-white50'>
          <div className='flex items-center justify-between px-40 py-15'>
            <div className='text-Header16 text-black500'>{`총 ${totalCount.toLocaleString()}명`}</div>
            <TextFieldSelectBoxDropdown
              width={100}
              options={PATIENT_OPTIONS}
              manualInput={false}
              onSelect={(index) => {
                setSelectedPatientOption(PATIENT_OPTIONS[index]);
              }}
              selectedIndex={0}
            />
          </div>
          <div className='relative h-full w-full overflow-x-auto'>
            <Scrollbar>
              <table
                className={customTwMerge(
                  'w-full table-fixed border-separate border-spacing-0 bg-white text-Body12',
                  isNoDataOrNoSearchResult ? 'h-full' : 'h-fit',
                )}>
                <colgroup>
                  <col className='w-[320px]' />
                  <col className='w-[140px]' />
                  {/* <col className='w-[98px]' /> */}
                  <col className='w-[120px]' />
                  <col className='w-[130px]' />
                  <col className='w-[130px]' />
                  <col className='w-[90px]' />
                  <col className='min-w-[178px]' />
                  <col className='w-[160px]' />
                </colgroup>

                <CustomerManagementReadOnlyTableHeader />

                <tbody>
                  <CustomerManagementReadOnlyTableBody
                    handleTotalCount={handleTotalCount}
                    handleNoDataOrNoSearchResult={handleNoDataOrNoSearchResult}
                    currentPage={selectedPage}
                  />
                </tbody>
              </table>
            </Scrollbar>
          </div>

          {totalCount > 0 && Math.floor(totalCount / 20) >= 0 && (
            <Pagination
              onPageChange={handlePageChange}
              className='h-fit'
              totalPages={Math.ceil(totalCount / 20)}
              selectedPage={selectedPage}
            />
          )}
        </div>
      </FormProvider>
    </DestroyBlockerProvider>
  );
}
