export const MIN_LAYOUT_WIDTH = 550;

export const Z_INDEXS = {
  ChattingRoom: 2,
  ChattingDateLine: 3,
  ChattingTooltip: 4,
  ChattingList: 2,
  ChattingListWrapper: 3,
  ChattingListChildDropdown: 20,
  ManualToggleButton: 4,
  MoveToBottomButton: 4,
};
