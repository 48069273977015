import type { KakaoConsultationTalkLinkageHandlerBodyRequest } from '@apis/swaggers/swagger-docs';
import { hasLinkageOrRequestHistoryState } from '@templates/HospitalSetting/containers/ChannelIntegration/states/has-linkage-or-request-history-state';
import { requesterNameErrorAtom } from '@templates/HospitalSetting/containers/ChannelIntegration/states/kakao-consult-form-error-state';
import TextInput from 'afterdoc-design-system/components/Atoms/Input/TextInput';
import { useAtom } from 'jotai';
import { useAtomValue } from 'jotai/index';
import { useFormContext } from 'react-hook-form';

export default function NameInput() {
  const { register } = useFormContext<KakaoConsultationTalkLinkageHandlerBodyRequest>();

  const hasLinkageOrRequestHistory = useAtomValue(hasLinkageOrRequestHistoryState);

  const [requesterNameError, setRequesterNameError] = useAtom(requesterNameErrorAtom);

  return (
    <TextInput
      label={{
        isRequired: true,
        children: '신청자',
        width: 140,
        position: 'horizontal',
      }}
      maxLength={30}
      width={290}
      hasError={requesterNameError}
      disabled={hasLinkageOrRequestHistory !== 'NotLinked'}
      errorTextPosition={'vertical'}
      placeholder='신청하는 분의 성함'
      errorText='연락받으실 분의 성함을 입력해 주세요.'
      {...register('requesterName', { onChange: () => setRequesterNameError(false) })}
    />
  );
}
