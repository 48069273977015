export const calculatePrice = (
  price: number,
  salePrice: number,
  saleRate: number,
  saleType: string,
): string => {
  const calculateDiscountedPrice = (): number => {
    if (saleType === 'price') {
      return Math.max(0, price - salePrice);
    }
    if (saleType === 'rate') {
      return price - (price * saleRate) / 100;
    }
    return 0;
  };

  const calculateDiscountRate = (): string => {
    if (saleType === 'rate') {
      return saleRate ? `${saleRate}%` : '';
    }
    if (salePrice > 0) {
      const rate = Math.round((salePrice / price) * 100);
      return rate > 0 && Number.isFinite(rate) ? `${rate}%` : '';
    }
    return '';
  };

  const discountedPrice = calculateDiscountedPrice().toLocaleString();
  const discountRate = calculateDiscountRate();

  return `${discountedPrice}원${discountRate ? ` (${discountRate})` : ''}`;
};
