import { fullDimmedLoadingService } from '@afterdoc-design-system/components/Atoms/Loading/FullDimmedLoading/FullDimmedLoading.service';
import { toastService } from '@afterdoc-design-system/components/Atoms/Toast/Toast.service';
import DownloadFooter from '@afterdoc-design-system/components/Molecules/DownloadFooter/DownloadFooter';
import { SHARED_UTILS } from '@shared-utils/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { useFormContext, useWatch } from 'react-hook-form';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import { unTaggedHeaderFilterState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/UnTaggedCustomerManagement/containers/UnTaggedCustomerManagementEditableTable/states/editable-header-filter';
import { searchTextState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/containers/SearchFilter/states/search';
import { selectedSearchCriteriaState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/containers/SearchFilter/states/selected-search-criteria';
import {
  focusedCellState,
  selectedCellState,
} from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/states/table';
import type { UnTaggedCustomerManagementAPIFormValues } from '../../types/table';

const postUnTaggedPatientsInfo = async (params: UnTaggedCustomerManagementAPIFormValues) => {
  const response = await apiClient.v3.apiPatientsElUntaggedTarget(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export default function UnTaggedCustomerManagementDownloadFooter() {
  const { hospitalID } = useSelectedHospitalInfo();

  const queryClient = useQueryClient();
  const {
    control,
    reset,
    formState: { isDirty },
  } = useFormContext<{ rows: UnTaggedCustomerManagementAPIFormValues }>();

  const rows = useWatch({
    name: 'rows',
    control: control,
  });

  const resetSelectedCell = useResetAtom(selectedCellState);
  const resetFocusedCell = useResetAtom(focusedCellState);

  const unTaggedHeaderFilter = useAtomValue(unTaggedHeaderFilterState);
  const searchText = useAtomValue(searchTextState);
  const selectedSearchCriteria = useAtomValue(selectedSearchCriteriaState);

  const postUnTaggedPatientsInfoMutation = useMutation({
    mutationFn: postUnTaggedPatientsInfo,
    onMutate: () => {
      fullDimmedLoadingService.on();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEY.apiPatientsElListOrSearchForUntagged,
          {
            hospitalID,
            limit: 50,
            chartNumber: selectedSearchCriteria === 'chartNumber' ? searchText : undefined,
            patientName: selectedSearchCriteria === 'name' ? searchText : undefined,
            phone: selectedSearchCriteria === 'phoneNumber' ? searchText : undefined,
            birth: selectedSearchCriteria === 'birthdate' ? searchText : undefined,
            ...unTaggedHeaderFilter,
            isFirstVisit:
              unTaggedHeaderFilter.isFirstVisit === 'all'
                ? undefined
                : unTaggedHeaderFilter.isFirstVisit,
          },
        ],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.apiPatientsElUntaggedCount, { hospitalID }],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.apiPatientsElListOrSearchForUntagged],
      });

      for (const row of rows) {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.apiPatientsElDetail, { patientId: row.patientId }],
        });
      }

      resetSelectedCell();
      resetFocusedCell();

      reset(
        { rows: [] },
        {
          keepDefaultValues: false,
          keepDirty: false,
          keepErrors: false,
          keepDirtyValues: false,
          keepTouched: false,
          keepSubmitCount: false,
        },
      );

      fullDimmedLoadingService.off();
      toastService.successMsg({ text: '고객 정보를 업데이트 했습니다.' });
    },
    onError: (error) => {
      console.error('error', error);
      toastService.errorMsg({ text: '고객 정보 업데이트를 실패했습니다.' });
      fullDimmedLoadingService.off();
    },
  });

  const handleSave = () => {
    const updatedValues = rows
      .map((item) => ({
        patientId: item.patientId,
        treatmentTagIds: item.treatmentTagIds || [],
        nationalityId: item.nationalityId,
      }))
      .filter((item) => item.treatmentTagIds.length > 0 || item.nationalityId !== undefined);

    postUnTaggedPatientsInfoMutation.mutate(updatedValues);
  };

  return (
    <DownloadFooter
      onSave={handleSave}
      saveButton='저장하기'
      saveButtonProps={{
        disabled: !isDirty,
      }}
      className='sticky right-0 bottom-0'
    />
  );
}
