import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';

export interface CategoryData {
  broadCategory: string;
  mediumCategory: string;
  smallCategory: string;
}

interface HistoryCategoryState {
  history: CategoryData;
  current: CategoryData;
}

export const historyCategoryDataState = atomWithReset<HistoryCategoryState>({
  history: {
    broadCategory: '',
    mediumCategory: '',
    smallCategory: '',
  },
  current: {
    broadCategory: '',
    mediumCategory: '',
    smallCategory: '',
  },
});
