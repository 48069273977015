import EventPushListBody from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/components/EventList/components/EventListBody/components/EventPushAlarmDialog/EventPushList/components/EventPushListBody';
import EventPushListHeader from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/components/EventList/components/EventListBody/components/EventPushAlarmDialog/EventPushList/components/EventPushListHeader';
import EventPushPageNavigation from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/components/EventList/components/EventListBody/components/EventPushAlarmDialog/EventPushList/components/EventPushPageNavigation';
import { eventListQueryKeyState } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/states/event-list-query-key-state';
import BaseLoading from 'afterdoc-design-system/components/Atoms/Loading/BaseLoading';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import { useAtomValue } from 'jotai/index';
import { Suspense, useEffect, useRef } from 'react';

export default function EventPushListContainer({ hospitalEventID }: { hospitalEventID: string }) {
  const queryKey = useAtomValue(eventListQueryKeyState);

  const scrollbarRef = useRef<{
    scrollTo: (index: number, behavior?: ScrollBehavior) => void;
  }>(null);

  useEffect(() => {
    if (scrollbarRef.current) {
      scrollbarRef.current.scrollTo(0, 'auto');
    }
  }, [queryKey]);

  return (
    <div className='w-[730px] flex-col-center'>
      <EventPushListHeader />
      <div className='h-[410px] w-full'>
        <Scrollbar disabledX ref={scrollbarRef}>
          <Suspense fallback={<Loading />}>
            <EventPushListBody hospitalEventID={hospitalEventID} />
          </Suspense>
        </Scrollbar>
      </div>
      <EventPushPageNavigation hospitalEventID={hospitalEventID} />
    </div>
  );
}

const Loading = () => {
  return (
    <div className='min-h-[410px] w-full flex-center'>
      <BaseLoading />
    </div>
  );
};
