export const CHANGED_RESERVATIONS_TABLE_HEADERS = [
  '변경일시',
  '부서',
  '담당자',
  '예약일시',
  '프로그램',
  '예약상태',
  '예약메모',
  '수정자',
];
