import LoadingSpinner from '@afterdoc-design-system/components/Atoms/Loading/LoadingSpinner';
import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';
import { contentPanelLayerState } from '@templates/Content/containers/ContentPanel/states/content-panel-layer-state';
import { AnimatePresence, motion } from 'framer-motion';
import { useAtomValue } from 'jotai/index';
import { Suspense } from 'react';
import HomcareVideoSelect from './components/HomecareVideoSelect/HomecareVideoSelect';
interface ContentPanelFolderItemProps {
  id: string;
  categoryTitle: string;
  folderIndex: number;
  isOpen: boolean;
  itemIndex: number;
  handleClick: ({ title }: { title: string }) => void;
}

export default function ChatContentPanelFolderItem({
  id,
  categoryTitle,
  folderIndex,
  isOpen,
  itemIndex,
  handleClick,
}: ContentPanelFolderItemProps) {
  const contentPanelLayer = useAtomValue(contentPanelLayerState);

  const folder = contentPanelLayer[folderIndex];
  const item = folder.items[itemIndex];
  if (item.text === '팝업') return null;

  return (
    <AnimatePresence initial={false}>
      {folder.isOpen && (
        <motion.ul
          initial={folder.isOpen ? { height: 0, opacity: 0 } : { height: 'auto', opacity: 1 }}
          animate={{ height: 'auto', opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}
          transition={{ duration: 0.4 }}
          className='overflow-hidden'>
          <li className='px-8 py-4'>
            <div
              onClick={() => handleClick({ title: id })}
              className={`line-clamp-1 flex w-full cursor-pointer items-center justify-start gap-4 rounded-r6 py-11 pr-20 pl-56 text-Body12 ${
                isOpen
                  ? 'bg-blue50 font-bold text-black500'
                  : 'bg-white50 font-regular text-black500 hover:bg-blueLight'
              }`}>
              <Icon name={isOpen ? 'chevron-down' : 'chevron-right'} size={16} color='black200' />
              {item.text}
            </div>
          </li>
          {isOpen && (
            <Suspense fallback={<LoadingSpinner />}>
              <HomcareVideoSelect categoryID={id} categoryTitle={categoryTitle} />
            </Suspense>
          )}
        </motion.ul>
      )}
    </AnimatePresence>
  );
}
