import type { CreateUpdateEventParamType } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/types/create-update-event-param-type';
import dayjs from 'dayjs';

export const DEFAULT_EVENT_PARAMS: CreateUpdateEventParamType = {
  _id: '',
  hospitalID: '',
  title: '',
  description: '',
  price: 0,
  salePrice: 0,
  saleRate: 0,
  saleType: 'price',
  isLimitedTime: false,
  startDate: dayjs().format('YYYY-MM-DD'),
  endDate: dayjs().add(7, 'day').format('YYYY-MM-DD'),
  isActive: true,
  isPinned: false,
  representImage: '',
  type: 'image',
  content: '',
};
