import * as React from "react";
import type { SVGProps } from "react";
const SvgQuestionCircleOutline = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="#000"
      d="M8 13.333A5.34 5.34 0 0 1 2.667 8 5.34 5.34 0 0 1 8 2.667 5.34 5.34 0 0 1 13.333 8 5.34 5.34 0 0 1 8 13.333m0-12a6.667 6.667 0 1 0 0 13.334A6.667 6.667 0 0 0 8 1.333"
    />
    <path
      fill="#000"
      d="M7.167 10.917h1.25v1.25h-1.25zM8 3.833c2.23.092 3.2 2.342 1.875 4.03-.346.416-.904.691-1.18 1.041-.278.346-.278.763-.278 1.18h-1.25c0-.697 0-1.284.279-1.7.275-.417.833-.663 1.179-.938 1.008-.934.758-2.254-.625-2.363a1.25 1.25 0 0 0-1.25 1.25H5.5a2.5 2.5 0 0 1 2.5-2.5"
    />
  </svg>
);
export default SvgQuestionCircleOutline;
