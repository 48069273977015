import ReservationHistoryTitleAndSubTextWithDatePicker from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/AfterDoc/ReservationHistory/components/ReservationHistoryTitleAndSubTextWithDatePicker';
import ReservationHistoryTable from './components/ReservationHistoryTable/ReservationHistoryTable';

export default function ReservationHistoryContainer() {
  return (
    <>
      <ReservationHistoryTitleAndSubTextWithDatePicker />
      <ReservationHistoryTable />
    </>
  );
}
