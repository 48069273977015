import type React from 'react';
import { useRef } from 'react';
import { forwardRef, useEffect, useState } from 'react';
import { ColorService, Hue, type IColor, Saturation, useColor } from 'react-color-palette';
import 'react-color-palette/css';
import './CustomColorPicker.scss';
import { treatmentTagsState } from '@templates/HospitalSetting/containers/ServiceSetting/containers/TreatmentTag/states/hospital-treatment-tags';
import { useSetAtom } from 'jotai/index';

interface CustomColorPickerProps {
  defaultColor: string;
  id: string;
  onHandleClose: () => void;
}

const CustomColorPicker = forwardRef<HTMLDivElement, CustomColorPickerProps>(
  ({ defaultColor, id, onHandleClose }, ref) => {
    const [color, setColor] = useColor(defaultColor.toLowerCase());
    const [selectedColor, setSelectedColor] = useState(defaultColor.toLowerCase());
    const setTreatmentTags = useSetAtom(treatmentTagsState);

    const inputRef = useRef<HTMLInputElement>(null);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      let inputValue = e.target.value;

      if (!inputValue.startsWith('#')) {
        inputValue = `#${inputValue}`;
      }

      inputValue = inputValue[0] + inputValue.slice(1).replace(/[#]/g, '');
      inputValue = inputValue.replace(/[^#0-9a-fA-F]/g, '');
      inputValue = inputValue.slice(0, 7);

      if (ColorService.toHex(inputValue) === inputValue) {
        const newColor = ColorService.convert('hex', inputValue);
        setColor(newColor);
        handleChangeComplete(newColor);
      } else {
        setSelectedColor(inputValue);
      }
    };

    const handleChangeComplete = (color: IColor) => {
      setTreatmentTags((prev) =>
        prev.map((tag) => (tag.tagId === id ? { ...tag, color: color.hex } : tag)),
      );
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && selectedColor.length === 7) {
        onHandleClose();
      }
    };

    useEffect(() => {
      setSelectedColor(color.hex);
    }, [color]);

    useEffect(() => {
      inputRef.current?.focus();
    }, []);

    return (
      <div
        ref={ref}
        className='w-[182px] flex-col-center gap-10 rounded-r16 bg-white50 p-16 shadow-black100'
        style={{
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
        }}>
        <Saturation
          height={150}
          color={color}
          onChangeComplete={handleChangeComplete}
          onChange={(newColor) => {
            setColor(ColorService.convert('hex', newColor.hex));
          }}
        />
        <div className='w-full px-4'>
          <Hue
            color={color}
            onChangeComplete={handleChangeComplete}
            onChange={(newColor) => {
              setColor(ColorService.convert('hex', newColor.hex));
            }}
          />
        </div>
        <div className='h-18 w-full flex-center gap-4'>
          <span className='px-6 text-Body12 text-black500'>HEX</span>
          <input
            ref={inputRef}
            type='text'
            value={selectedColor}
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            className='w-full rounded-r-[4px] border border-white500 px-6 py-2 text-Body12 text-black500 outline-none focus:border-blue500 focus:outline-none'
          />
        </div>
      </div>
    );
  },
);

export default CustomColorPicker;
