import { hospitalAddressQueryKeyState } from '@templates/HospitalSetting/containers/HospitalProfile/components/HospitalAddress/components/AddressSearchDialog/jotai/address-query-key';
import Pagination from 'afterdoc-design-system/components/Molecules/Pagination/Pagination';
import { useAtom } from 'jotai/index';
import React from 'react';

interface AddressPaginationProps {
  totalCount: number;
}

export default function AddressPagination({ totalCount }: AddressPaginationProps) {
  const [hospitalAddressQueryKey, setHospitalAddressQueryKey] = useAtom(
    hospitalAddressQueryKeyState,
  );

  const handlePageChange = (page: number) => {
    setHospitalAddressQueryKey((prevQueryKey) => [
      prevQueryKey[0],
      {
        ...prevQueryKey[1],
        currentPage: String(page),
      },
    ]);
  };

  return (
    <Pagination
      onPageChange={handlePageChange}
      className='h-auto'
      totalPages={Math.ceil(totalCount / 20)}
      defaultPage={Number(hospitalAddressQueryKey[1].currentPage)}
    />
  );
}
