import React from 'react';

interface EventImageProps {
  imagePath: string;
}
export default function EventHomeCareImage({ imagePath }: EventImageProps) {
  return (
    <div className='flex-center'>
      <img className='h-40 w-40 rounded-r10' src={imagePath} alt='eventThumbnail' />
    </div>
  );
}
