import Checkbox from '@afterdoc-design-system/components/Atoms/Checkbox/Checkbox';
import type { ALIntegrationUserData } from './hooks/type';

interface ALIntegrationEditSearchedCustomerItemProps {
  value: ALIntegrationUserData[];
  index: number;
  setter: (index: number, user: ALIntegrationUserData) => void;
}

export default function ALIntegrationEditSearchedCustomerItem({
  value,
  index,
  setter,
}: ALIntegrationEditSearchedCustomerItemProps) {
  const data = value[index];
  const handleCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedValue = { ...value[index] };
    updatedValue.isSelected = e.target.checked;
    setter(index, updatedValue);
  };
  return (
    <div
      style={{ width: '279px' }}
      className='my-10 flex flex-col gap-10 rounded-r10 border border-white600 bg-white50 p-16'>
      <div className='item-center flex gap-10'>
        <Checkbox checked={data.isSelected} onChange={handleCheckBoxChange} />
        <div>
          <span className='text-Header14'>{data.name}</span>{' '}
          <span className='text-Body11'>{data.chartNumber}</span>
        </div>
      </div>
      <div className='flex gap-10'>
        <span className='rounded-r6 bg-white100 px-8 text-Body12'>
          {data.gender}/{data.birthdate}
        </span>
        <span className='rounded-r6 bg-white100 px-8 text-Body12'>{data.phoneNumber}</span>
      </div>
    </div>
  );
}
