import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';
import { dialogService } from '@afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import { getDetailEvent } from 'web/templates/CustomerChat/components/Manual/components/ContentsList/components/ContentsPanelFolderItem/components/ChatContentPanelFolderItem/components/HomecareVideoSelect/apis/get-detail-event';
import { getDetailHAftercare } from 'web/templates/CustomerChat/components/Manual/components/ContentsList/components/ContentsPanelFolderItem/components/ChatContentPanelFolderItem/components/HomecareVideoSelect/apis/get-detail-haftercare';
import EventPopupView from 'web/templates/CustomerChat/components/Manual/components/ContentsList/components/ContentsPanelFolderItem/components/ChatContentPanelFolderItem/components/HomecareVideoSelect/components/EventPopupView';
import { useChatMessageContext } from '../../ChatMessageContext';
import { makeChattingInfoStringDisplay } from '../../functions/makeChattingInfoStringDisplay';
import { MessageDialogContent } from './MessageDialogContent';

type Props = {
  url: string;
  description: string;
  originalPrice: number;
  eventPrice: number;
  percentage: number;
  isEvent: boolean;
};

export default function EventListCard({
  url,
  description,
  originalPrice,
  eventPrice,
  percentage,
  isEvent,
}: Props) {
  const { heventID, haftercareID } = useChatMessageContext();
  const displayOriginalPrice = makeChattingInfoStringDisplay({
    type: 'price',
    payload: { num: originalPrice },
  });
  const displaySalePrice = makeChattingInfoStringDisplay({
    type: 'price',
    payload: { num: eventPrice },
  });
  return (
    <div
      onClick={async () => {
        const dynamicFunction = isEvent ? getDetailEvent : getDetailHAftercare;
        const id = isEvent ? heventID?._id : haftercareID?._id;
        const {
          title,
          content,
          fileIDs,
          description,
          isLimitedTime,
          endDate,
          type,
          saleType,
          salePrice,
          saleRate,
          price,
        } = await dynamicFunction(id ?? '');
        const detailInfoImageSrc = fileIDs?.[0]?.paths?.[0];
        dialogService.push(
          <MessageDialogContent>
            <EventPopupView
              title={title ?? ''}
              description={description ?? ''}
              content={content ?? ''}
              isLimitedTime={isLimitedTime ?? false}
              endDate={endDate ?? ''}
              thumbnailSrc={url ?? ''}
              type={type ?? ''}
              detailInfoImageSrc={detailInfoImageSrc ?? ''}
              saleType={saleType ?? ''}
              salePrice={salePrice ?? 0}
              saleRate={saleRate ?? 0}
              price={price ?? 0}
            />
          </MessageDialogContent>,
          {
            hasCloseButton: true,
            titleProps: {
              title: '상세내용',
            },
          },
        );
      }}
      className='flex h-100 w-full cursor-pointer items-center gap-10 rounded-r16 border border-white600 bg-white50 p-10 text-Body13'>
      <img className='size-60 flex-shrink-0 rounded-r16' src={url} alt='Event Card' />
      <div className='flex flex-grow flex-col'>
        {/* TODO - 해당 static 부분 없애기 */}
        <div className='line-clamp-2 text-Body13 text-black700'>{description}</div>
        <div className='text-Body12 text-black200 line-through'>{displayOriginalPrice}</div>
        <div className='flex flex-row items-center gap-8'>
          <div className='text-Header14 text-black700 '>{displaySalePrice}</div>
          <div className='text-Header14 text-red500'>{percentage}%</div>
        </div>
      </div>
      <Icon size={16} color={'black600'} name='chevron-right' className='flex-shrink-0' />
    </div>
  );
}
