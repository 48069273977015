import ContactInfoInput from '@templates/HospitalSetting/containers/HospitalProfile/components/ReservationInquiryMethod/components/ContactInfoInput';
import ContactMethodSelection from '@templates/HospitalSetting/containers/HospitalProfile/components/ReservationInquiryMethod/components/ContactMethodSelection';
import { hospitalInfoState } from '@templates/HospitalSetting/containers/HospitalProfile/states/hospital-info-state';
import { createErrorMessageAtom } from '@templates/HospitalSetting/states/hospital-info-input-error-messages-state';
import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import { useAtom, useAtomValue } from 'jotai/index';
import { selectAtom } from 'jotai/utils';
import { useEffect, useMemo } from 'react';

const TITLE = '예약문의방식';
const SUB_TITLE = '신규고객이 예약을 희망할 때 연결할 방식을 설정해 주세요.';

const inquiryMethodErrorAtom = createErrorMessageAtom('inquiryMethod');

const hospitalInquiryMethodAtom = selectAtom(hospitalInfoState, (hospital) => ({
  usingHomepage: hospital.usingHomepage,
  phone: hospital.phone,
  homepageURL: hospital.homepageURL,
}));

export default function ReservationInquiryMethod() {
  const hospitalInquiryMethod = useAtomValue(hospitalInquiryMethodAtom);

  const [inquiryMethodError, setInquiryMethodError] = useAtom(inquiryMethodErrorAtom);

  const memoizedInquiryMethod = useMemo(
    () => ({
      usingHomepage: hospitalInquiryMethod.usingHomepage,
      phone: hospitalInquiryMethod.phone,
      homepageURL: hospitalInquiryMethod.homepageURL,
    }),
    [
      hospitalInquiryMethod.usingHomepage,
      hospitalInquiryMethod.phone,
      hospitalInquiryMethod.homepageURL,
    ],
  );

  useEffect(() => {
    if (inquiryMethodError !== null) {
      setInquiryMethodError(null);
    }
  }, [memoizedInquiryMethod]);

  return (
    <>
      <div className='flex flex-col gap-10 px-100 pt-20 pb-40'>
        <div className='pt-20 pb-8'>
          <div className='flex items-center '>
            <h1 className='text-Header16 text-black700'>{TITLE}</h1>
            <h1 className='text-Header16 text-red500'>*</h1>
          </div>
          <h2 className='whitespace-pre-wrap pt-4 text-Body11 text-black200'>{SUB_TITLE}</h2>
        </div>
        <div className='flex w-full flex-col gap-10'>
          <div className='flex h-[100px] flex-grow justify-between gap-40'>
            <ContactInfoInput />
            <ContactMethodSelection />
          </div>
          {inquiryMethodError && (
            <span className='text-Body10 text-red500'>{inquiryMethodError}</span>
          )}
        </div>
      </div>
      <Divider type='plane' height={10} />
    </>
  );
}
