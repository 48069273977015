import LabelText from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import Toggle from 'afterdoc-design-system/components/Atoms/Toggle/Toggle';
import { memo } from 'react';

interface ActivationStatusSwitchProps {
  isActive: boolean;
  onHandleValue: (value: boolean) => void;
}

const ActivationStatusSwitch = memo(({ isActive, onHandleValue }: ActivationStatusSwitchProps) => {
  return (
    <div className='flex h-32 items-center gap-28'>
      <LabelText width={72} className='flex h-32 items-center'>
        활성화
      </LabelText>
      <Toggle
        toggleSize='big'
        checked={isActive}
        onChange={(e) => onHandleValue(e.target.checked)}
      />
    </div>
  );
});

export default ActivationStatusSwitch;
