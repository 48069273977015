import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import type {
  AuthorizationSection,
  AuthorizationType,
  AuthorizationTypeForV3,
} from '@apis/swaggers/swagger-docs';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AUTHORIZATION_TYPES } from '@templates/HospitalSetting/containers/AuthorizationSetting/constants/type-items';
import {
  selectedAuthorizationTypeState,
  selectedInitialAuthorizationTypeState,
} from '@templates/HospitalSetting/containers/AuthorizationSetting/jotai/hospital-authorization-types';
import { hospitalSettingsModifiedState } from '@templates/HospitalSetting/states/hospital-settings-modified-state';
import OutlinedButton from 'afterdoc-design-system/components/Atoms/Button/OutlinedButton';
import { fullDimmedLoadingService } from 'afterdoc-design-system/components/Atoms/Loading/FullDimmedLoading/FullDimmedLoading.service';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { useAtomValue } from 'jotai';
import { SHARED_UTILS } from 'utils/utils';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';

const updateHospitalAuthorizationTypes = async (params: AuthorizationType) => {
  const response = await apiClient.v3.apiHospitalsAuthorizationTypeElUpdate(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const sectionKeys = Object.values(AUTHORIZATION_TYPES).map((item) => item.key);

const getUpdatedFields = (
  originalData: AuthorizationTypeForV3,
  updatedData: AuthorizationTypeForV3,
  updateBody: AuthorizationType,
) => {
  let updatedFields: AuthorizationType = { ...updateBody };

  const compareSections = (
    originalSection: AuthorizationSection,
    updatedSection: AuthorizationSection,
  ) => {
    for (const [index, updatedType] of updatedSection?.types?.entries() || []) {
      const originalType = originalSection?.types?.[index];
      if (originalType?.value !== updatedType.value) {
        updatedFields = {
          ...updatedFields,
          [updatedType.fieldNameForUpdate as string]: updatedType.value,
        };
      }
    }
  };

  for (const key of sectionKeys) {
    const originalSection = originalData[key];
    const updatedSection = updatedData[key];

    if (originalSection && updatedSection) {
      compareSections(originalSection, updatedSection);
    }
  }

  return updatedFields;
};

export default function AuthorizationSettingSaveButton() {
  const { hospitalID } = useSelectedHospitalInfo();

  const queryClient = useQueryClient();
  const selectedAuthorizationType = useAtomValue(selectedAuthorizationTypeState);
  const initAuthorizationType = useAtomValue(selectedInitialAuthorizationTypeState);
  const hospitalSettingsModified = useAtomValue(hospitalSettingsModifiedState);

  const mutation = useMutation({
    mutationFn: (params: AuthorizationType) => updateHospitalAuthorizationTypes(params),
    onMutate: () => fullDimmedLoadingService.on(),
    onSettled: () => fullDimmedLoadingService.off(),
    onSuccess: () => handleMutationSuccess(),
    onError: (error) => handleMutationError(error),
  });

  const handleMutationSuccess = () => {
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEY.apiHospitalsAuthorizationTypeElFind],
    });
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEY.v2apiUserElFindOne],
    });
    toastService.successMsg({
      text: `${selectedAuthorizationType.name} 권한의 설정값을 변경했습니다.`,
    });
  };

  const handleMutationError = (error: Error) => {
    console.error('Error updating hospital authorization types:', error);
    toastService.errorMsg({ text: '저장에 실패했습니다. 다시 시도해 주세요.' });
  };

  const handleSaveButtonClick = () => {
    let body: AuthorizationType = {
      _id: selectedAuthorizationType?._id ?? '',
      hospitalID,
    };
    body = getUpdatedFields(initAuthorizationType, selectedAuthorizationType, body);
    mutation.mutate(body);
  };

  return (
    <div className='mt-30 flex justify-end'>
      <OutlinedButton
        disabled={!hospitalSettingsModified}
        onClick={handleSaveButtonClick}
        className='w-[110px]'>
        저장
      </OutlinedButton>
    </div>
  );
}
