import * as React from "react";
import type { SVGProps } from "react";
const SvgGroup = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 38 38"
    {...props}
  >
    <rect width={38} height={38} fill="#DFDFE0" rx={19} />
    <path
      fill="#929292"
      d="M19 17.593a4.574 4.574 0 1 1 0-9.149 4.574 4.574 0 0 1 0 9.149m0 3.43c3.643 0 6.913.653 9.148 3.503v2.215H9.852v-2.215c2.236-2.851 5.505-3.503 9.148-3.503"
    />
  </svg>
);
export default SvgGroup;
