export const COLUMN_WIDTHS = {
  order: '58px',
  name: '128px',
  chartNumber: '130px',
  isFirstVisit: '100px',
  countryCode: '113px',
  phoneNumber: '130px',
  birthdate: '112px',
  gender: '80px',
  treatmentTag: '150px',
  nationality: '140px',
};
