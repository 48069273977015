import { Color } from '@tailwind-base/styles/color';
import type { MessageInput } from 'web/apis/swaggers/swagger-docs';

export const MESSAGE_OPTIONS: {
  sendingType: MessageInput['sendingType'];
  label: string;
  description: string;
  bgClassName: string;
  circleBgClassName: string;
  circleBorderClassName: string;
  bgColor: (typeof Color)[keyof typeof Color];
}[] = [
  {
    sendingType: 'BASIC',
    label: '기본',
    description:
      '증상 체크 및 안부메시지등을 전송할 때 활용합니다.\n자동화가 적용된 고객이 응답을 한 이후, 기본 타입의 메시지는 발송되지 않습니다.',
    bgClassName: 'bg-white50',
    circleBgClassName: 'bg-white50',
    circleBorderClassName: 'border-white600',
    bgColor: Color.white50,
  },
  {
    sendingType: 'REVISIT_INDUCEMENT',
    label: '필수',
    description:
      '필수 안내, 치료별 재내원 안내 등 반드시 전송되어야 할 메시지를 전송할 때 활용합니다.',
    bgClassName: 'bg-blue50',
    circleBgClassName: 'bg-blue500',
    circleBorderClassName: 'border-blue500',
    bgColor: Color.blue50,
  },
  {
    sendingType: 'SURVEY',
    label: '설문',
    description:
      '진료 만족도, 통증체크 등 간단한 설문 타입의 메시지를 발송하여 응답을 수집할 수 있습니다.',
    bgClassName: 'bg-red50',
    circleBgClassName: 'bg-red500',
    circleBorderClassName: 'border-red500',
    bgColor: Color.red50,
  },
];
