import { SHARED_UTILS } from '@shared-utils/utils';
import { useQuery } from '@tanstack/react-query';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { ApiChatroomElFindOneParams } from 'web/apis/swaggers/swagger-docs';

export const getChatRoomInfo = async (params: ApiChatroomElFindOneParams) => {
  const response = await apiClient.v3.apiChatroomElFindOne(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export const useChatRoomInfoOne = ({ chatRoomID }: { chatRoomID: string }) => {
  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEY.apiChatroomElFindOne, { chatRoomID }] as const,
    queryFn: ({ queryKey }) => getChatRoomInfo(queryKey[1]),
    enabled: !!chatRoomID,
  });

  const {
    usingTranslation: isUsingTranslation,
    counselors,
    users,
    ikakaoInfo,
    languageID,
    isCounselInProgress,
    roomType,
  } = data || {};

  return {
    isUsingTranslation,
    ikakaoInfo,
    languageID,
    participants: users ?? [],
    counselors: counselors ?? [],
    isCounselInProgress: isCounselInProgress,
    isLoading,
    roomType,
  };
};
