import * as React from "react";
import type { SVGProps } from "react";
const SvgChatBubble = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M40 4H8a4 4 0 0 0-4 4v36l8-8h28a4 4 0 0 0 4-4V8a4 4 0 0 0-4-4M14 14a2 2 0 0 1 2-2h17a2 2 0 1 1 0 4H16a2 2 0 0 1-2-2m2 5a2 2 0 1 0 0 4h12a2 2 0 1 0 0-4z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgChatBubble;
