import VideoContentDetailContainer from '@templates/Content/containers/ContentManager/containers/VideoContent/componants/VideoContentDetail/VideoContentDetailContainer';
import VideoContentListContainer from '@templates/Content/containers/ContentManager/containers/VideoContent/componants/VideoContentList/VideoContentListContainer';
import { selectedVideoContentState } from '@templates/Content/containers/ContentManager/containers/VideoContent/states/selected-video-content-state';
import { useResetAtom } from 'jotai/utils';
import { useEffect } from 'react';

export default function VideoContentContainer() {
  const resetSelectedVideoContent = useResetAtom(selectedVideoContentState);

  useEffect(() => {
    resetSelectedVideoContent();
  }, []);

  return (
    <>
      <div className='mx-auto h-full'>
        <div className='flex-center'>
          <VideoContentListContainer />
          <VideoContentDetailContainer />
        </div>
      </div>
    </>
  );
}
