import TextArea from '@afterdoc-design-system/components/Atoms/Input/TextArea/TextArea';
import BaseLoading from 'afterdoc-design-system/components/Atoms/Loading/BaseLoading';

interface SmartDoctorCustomerMemoContainerProps {
  isLoading: boolean;
  customerMemo: string | undefined;
}

export default function SmartDoctorCustomerMemoContainer({
  isLoading,
  customerMemo,
}: SmartDoctorCustomerMemoContainerProps) {
  return (
    <>
      <div className='flex w-full flex-col gap-4'>
        <div className='mb-16 text-Header14 text-black500'>스마트닥터 고객메모</div>
        <div className='relative'>
          <TextArea
            inputWrapperClassName='bg-white200'
            inputClassName='disabled:bg-white200'
            height={100}
            disabled={true}
            isShowBottom={false}
            value={isLoading ? '' : customerMemo || '작성된 내용이 없습니다.'}
          />
          {isLoading && (
            <div className='absolute inset-0 flex-center bg-white50 opacity-40'>
              <BaseLoading />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
