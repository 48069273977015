import Divider from '@afterdoc-design-system/components/Atoms/Divider/Divider';
import BaseLoading from '@afterdoc-design-system/components/Atoms/Loading/BaseLoading';
import Title from '@afterdoc-design-system/components/Molecules/Title/Title';
import { Suspense } from 'react';
import ErrorBoundary from 'web/shared/components/ErrorBoundary/ErrorBoundary';
import CustomerManagementPanel from './components/CustomerManagementPanel';

export default function CustomerManagementPanelListContainer() {
  return (
    <div>
      <Title title='고객관리' subTitle='고객 정보를 업데이트하거나 새로 등록할 수 있습니다.' />
      <ErrorBoundary>
        <Suspense
          fallback={
            <div className='h-[100px] w-[360px] flex-center'>
              <BaseLoading />
            </div>
          }>
          <CustomerManagementPanel />
        </Suspense>
      </ErrorBoundary>
      <Divider type='line' direction='horizontal' height={1} margin={10} />
    </div>
  );
}
