import { fullDimmedLoadingService } from '@afterdoc-design-system/components/Atoms/Loading/FullDimmedLoading/FullDimmedLoading.service';
import { toastService } from '@afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { modalService } from '@afterdoc-design-system/components/Molecules/Modal/Modal.service';
import type Excel from 'exceljs';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import { useAtomValue } from 'jotai';
import Papa from 'papaparse';
import { useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import type { CustomerManagementTemporaryAPIFormValues } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/types/table';
import { calculateEmptyRows } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/utils/calculate-empty-rows';
import { createInitialRows } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/utils/create-initial-rows';
import {
  focusedCellState,
  selectedCellState,
} from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/states/table';

const MAX_FILE_SIZE_MB = 30;

export const useUploadExcelCustomerManagement = () => {
  const { hospitalID } = useSelectedHospitalInfo();
  const { getValues, setValue } = useFormContext<{
    rows: CustomerManagementTemporaryAPIFormValues;
  }>();

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const abortController = useRef<AbortController | null>(null);

  const resetState = () => {
    fullDimmedLoadingService.off();
    toastService.close('uploading');
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const validateTemplate = (
    headerValues: { A1: string; A2: string; A3: string; B3: string },
    _fileType: 'xlsx' | 'csv',
  ) => {
    return (
      headerValues.A1 === '고객 등록 양식' &&
      headerValues.A2 === '*양식을 임의로 수정하면 업로드에 실패할 수 있습니다.' &&
      headerValues.A3 === '고객명*' &&
      headerValues.B3 === '휴대폰번호*'
    );
  };

  const selectedCell = useAtomValue(selectedCellState);
  const focusedCell = useAtomValue(focusedCellState);

  const getStartingRowIndex = () => {
    // 선택된 셀이나 포커스된 셀의 행 인덱스를 반환
    if (selectedCell.row !== null) return selectedCell.row;
    if (focusedCell.row !== null) return focusedCell.row;
    return 0; // 둘 다 없으면 첫 번째 행부터 시작
  };

  const readXlsxFile = async (file: File): Promise<void> => {
    let workbook: typeof ExcelJS.Workbook | null = null;
    try {
      workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(file);
    } catch (error) {
      console.error('엑셀 파일 로드 에러 발생 :', error);
      toastService.errorMsg({ text: '엑셀 파일 로드에 실패했습니다.' });
      resetState();
      return;
    }

    const worksheet = workbook.getWorksheet(1);

    const headerValues = {
      A1: worksheet.getCell('A1').value as string,
      A2: worksheet.getCell('A2').value as string,
      A3: worksheet.getCell('A3').value as string,
      B3: worksheet.getCell('B3').value as string,
    };

    if (!validateTemplate(headerValues, 'xlsx')) {
      modalService.defaultWarning({
        title: '양식 오류',
        contents: '업로드한 파일의 양식이 올바르지 않습니다. 양식을 다시 확인해 주세요.',
        buttonType: 'CONFIRM',
        buttonText: '확인',
      });
      resetState();
      return;
    }

    const jsonData: Partial<CustomerManagementTemporaryAPIFormValues[number]>[] = [];

    worksheet.eachRow((row: Excel.Row, rowNumber: number) => {
      const rowData: Partial<CustomerManagementTemporaryAPIFormValues[number]> = {};

      let isTargetRow = false;

      row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
        // Rich Text 객체 처리를 위한 함수
        const getCellValue = (cell: Excel.Cell) => {
          const value = cell.value;

          // Rich Text 객체 처리
          if (value && typeof value === 'object' && 'richText' in value) {
            return (value.richText as { text: string }[]).map((part) => part.text).join('');
          }

          // 일반 텍스트 처리
          if (value && typeof value === 'object' && 'text' in value) {
            return value.text;
          }

          // 빈 셀이나 null 값 처리
          if (value === null || value === undefined) {
            return null;
          }

          return value.toString().trim();
        };

        if (rowNumber === 4 && colNumber === 1 && cell.value === '홍길동') {
          isTargetRow = true;
        } else if (rowNumber === 4 && colNumber === 2 && cell.value === '01012345678') {
          isTargetRow = true;
        }

        if (colNumber === 1 && cell.value !== '홍길동') {
          const cellValue = getCellValue(cell);
          rowData.name = cellValue;
        } else if (colNumber === 2 && cell.value !== '01012345678') {
          const cellValue = getCellValue(cell);
          rowData.phoneNumber = cellValue ? cellValue.toString().replace(/-/g, '') : null;
        }
      });

      // 빈 행 필터링
      const hasValidData = rowData.name || rowData.phoneNumber;
      if (!isTargetRow && hasValidData) {
        // 데이터 유효성 검사
        if (rowData.name === '[object Object]') {
          console.warn('Invalid name format detected:', rowData);
          return;
        }
        jsonData.push(rowData);
      }
    });

    // 데이터 필터링 로직 강화
    const dataWithoutEmpty = jsonData?.slice(3)?.filter((item) => {
      return (
        item !== undefined &&
        item !== null &&
        Object.values(item).some((value) => {
          // [object Object] 문자열 체크 추가
          if (typeof value === 'string' && value === '[object Object]') {
            return false;
          }
          return value !== undefined && value !== null && value !== '';
        })
      );
    });

    const startingRowIndex = getStartingRowIndex();
    const existingRows = getValues('rows') || [];

    const filteredExistingRows = existingRows.filter((row) => {
      const { hospitalID, order, ...otherFields } = row;

      return Object.values(otherFields).some(
        (v) => v !== null && v !== undefined && !(Array.isArray(v) && v.length === 0),
      );
    });

    const updatedData: CustomerManagementTemporaryAPIFormValues = dataWithoutEmpty?.map(
      ({ name, phoneNumber, birthdate, chartNumber, isFirstVisit, gender }, index) => ({
        // 기존 order 값을 유지하면서 새로운 데이터 추가
        order:
          filteredExistingRows[startingRowIndex + index]?.order || startingRowIndex + index + 1,
        hospitalID,
        patientId: undefined,
        name,
        phoneNumber,
        birthdate,
        chartNumber,
        gender: gender as 'MALE' | 'FEMALE',
        isFirstVisit,
        nationalityId: null,
        treatmentTagIds: [],
      }),
    );

    // 전체 데��터의 길이 계산 (기존 데이터 + 새로운 데이터)
    const totalLength = Math.max(
      startingRowIndex + updatedData.length,
      filteredExistingRows.length,
    );

    // 50의 배수로 필요한 전체 행 수 계산
    const requiredTotalRows = Math.ceil(totalLength / 50) * 50;

    // 새로운 데이터의 order 값 조정
    const adjustedUpdatedData = updatedData.map((item, index) => ({
      ...item,
      order: startingRowIndex + index + 1,
    }));

    // 기존 데이터를 order 기준으로 정렬하고 빈 공간 채우기
    const sortedExistingRows = [...filteredExistingRows].sort(
      (a, b) => (a.order || 0) - (b.order || 0),
    );

    // 최종 데이터 배열 생성
    const finalData: CustomerManagementTemporaryAPIFormValues = [];

    // 1부터 requiredTotalRows까지 순회하면서 데이터 채우기
    for (let i = 1; i <= requiredTotalRows; i++) {
      // 현재 순서에 해당하는 데이터 찾기
      if (i >= startingRowIndex + 1 && i < startingRowIndex + updatedData.length + 1) {
        // 새로운 데이터 영역
        finalData.push(adjustedUpdatedData[i - startingRowIndex - 1]);
      } else {
        // 기존 데이터나 빈 행
        const existingRow = sortedExistingRows.find((row) => row.order === i);
        if (existingRow) {
          finalData.push(existingRow);
        } else {
          finalData.push({
            order: i,
            hospitalID,
            patientId: null,
            name: null,
            chartNumber: null,
            isFirstVisit: null,
            phoneNumber: null,
            birthdate: null,
            gender: null,
            treatmentTagIds: [],
            nationalityId: null,
          });
        }
      }
    }

    const mergedData = finalData;

    if (mergedData.length > 0) {
      setValue('rows', mergedData as CustomerManagementTemporaryAPIFormValues);

      resetState();
      return;
    }
  };

  const readCsvFile = (file: File) => {
    Papa.parse(file, {
      header: true,
      complete: (results: { data: { [key: string]: string }[] }) => {
        const jsonData = results.data;

        if (jsonData.length === 0) {
          modalService.defaultWarning({
            title: '파일 데이터 없음',
            contents: '등록하려는 파일에 등록할 데이터가 없습니다.',
            buttonType: 'CONFIRM',
            buttonText: '확인',
          });
          resetState();
          return;
        }

        const headerValues = {
          A1: jsonData[0]['고객 등록 양식'] || '',
          A2: jsonData[0] ? jsonData[0][''] : '',
          A3: jsonData[1] ? jsonData[1]['고객 등록 양식'] : '',
          B3: jsonData[1] ? jsonData[1][''] : '',
        };

        if (!validateTemplate(headerValues, 'csv')) {
          modalService.defaultWarning({
            title: '파일 등록 불가',
            contents: '등록하려는 파일 상태를 다시 확인해 주세요.',
            buttonType: 'CONFIRM',
            buttonText: '확인',
          });
          resetState();
          return;
        }

        const dataWithoutEmpty = jsonData?.slice(3)?.filter((item) => {
          return (
            item !== undefined &&
            item !== null &&
            Object.values(item).some((value) => {
              // [object Object] 문자열 체크 추가
              if (typeof value === 'string' && value === '[object Object]') {
                return false;
              }
              return value !== undefined && value !== null && value !== '';
            })
          );
        });

        const startingRowIndex = getStartingRowIndex();
        const existingRows = getValues('rows') || [];

        const filteredExistingRows = existingRows.filter((row) => {
          const { hospitalID, order, ...otherFields } = row;

          // otherFields의 값들 중 하나라도 유효한 데이터가 있는지 확인
          return Object.values(otherFields).some(
            (v) =>
              v !== null &&
              v !== undefined &&
              !(Array.isArray(v) && v.length === 0) &&
              !(typeof v === 'string' && v.trim() === ''),
          );
        });

        // 새로운 데이터 포맷팅 - order 값을 기존 순서 유지
        const updatedData: CustomerManagementTemporaryAPIFormValues = dataWithoutEmpty?.map(
          ({ name, phoneNumber }, index) => ({
            hospitalID,
            // 기존 order 값을 유지하면서 새로운 데이터 추가
            order:
              filteredExistingRows[startingRowIndex + index]?.order || startingRowIndex + index + 1,
            patientId: undefined,
            name,
            phoneNumber,
            birthdate: null,
            chartNumber: null,
            gender: null,
            isFirstVisit: null,
            nationalityId: null,
            treatmentTagIds: [],
          }),
        );

        // 전체 데이터의 길이 계산 (기존 데이터 + 새로운 데이터)
        const totalLength = Math.max(
          startingRowIndex + updatedData.length,
          filteredExistingRows.length,
        );

        // 50의 배수로 맞추기 위해 필요한 빈 행 수 계산
        const emptyRowsCount = calculateEmptyRows(totalLength);

        // 빈 행 생성 - 마지막 order 값 이후부터 순차적으로 증가
        const lastOrder = Math.max(
          ...[
            ...filteredExistingRows.map((row) => row.order || 0),
            ...updatedData.map((row) => row.order || 0),
          ],
        );

        const emptyRows = createInitialRows(emptyRowsCount, lastOrder, hospitalID);

        // 기존 데이터, 새로운 데이터, 빈 행을 합침
        const mergedData: CustomerManagementTemporaryAPIFormValues = [
          // 선택된 셀 이전의 데이터 유지 (order 값 포함)
          ...filteredExistingRows.slice(0, startingRowIndex).map((row) => ({
            hospitalID,
            order: row.order || 0,
            patientId: row.patientId,
            name: row.name,
            phoneNumber: row.phoneNumber,
            birthdate: row.birthdate,
            chartNumber: row.chartNumber,
            gender: row.gender,
            isFirstVisit: row.isFirstVisit,
            nationalityId: row.nationalityId,
            treatmentTagIds: row.treatmentTagIds,
          })),
          // 새로운 데이터 추가
          ...updatedData,
          // 나머지 빈 행 추가
          ...emptyRows,
        ];

        if (mergedData.length > 0) {
          setValue('rows', mergedData as CustomerManagementTemporaryAPIFormValues);

          fullDimmedLoadingService.off();
          toastService.close('uploading');
          return;
        }
      },
      error: (error) => {
        console.error('CSV Parsing Error:', error);
        toastService.errorMsg({ text: 'CSV 파일 로드에 실패했습니다.' });
        resetState();
      },
    });
  };

  const onUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (abortController.current) {
      abortController.current = null;
    }

    abortController.current = new AbortController();

    toastService.loadingMsg({
      id: 'uploading',
      text: '파일 업로드 중입니다. 잠시만 기다려주세요.',
      rightButton: {
        text: '취소하기',
        onClick: () => {
          if (abortController.current) {
            abortController.current.abort();
          }
          resetState();
        },
      },
    });
    fullDimmedLoadingService.on();

    const file = event.target.files?.[0];
    if (file) {
      const fileSizeMB = file.size / (1024 * 1024);

      if (fileSizeMB > MAX_FILE_SIZE_MB) {
        modalService.defaultWarning({
          title: '파일 용량 초과',
          contents: '30MB 이하의 파일만 등록할 수 있습니다.',
          buttonType: 'CONFIRM',
          buttonText: '확인',
        });
        resetState();
        return;
      }

      const fileExtension = file.name.split('.').pop()?.toLowerCase();
      if (fileExtension === 'xlsx') {
        readXlsxFile(file);
      } else if (fileExtension === 'csv') {
        readCsvFile(file);
      } else {
        toastService.errorMsg({ text: '지원하지 않는 파일 형식입니다.' });
        resetState();
      }
    }
  };

  return {
    onUpload,
    fileInputRef,
  };
};
