import ContainedButton from '@afterdoc-design-system/components/Atoms/Button/ContainedButton';
import OutlinedButton from '@afterdoc-design-system/components/Atoms/Button/OutlinedButton';
import { toastService } from '@afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { Suspense, memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import FinalSaveButton from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementSaveFooter/components/EditorModeButtons/components/FinalSaveButton';
import TemporarySaveButton from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementSaveFooter/components/EditorModeButtons/components/TemporarySaveButton';
import { useLockRefreshTimer } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementSaveFooter/components/EditorModeButtons/hooks/use-lock-refresh-timer';
import { customerTableDisplayModeState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/states/customer-management-display-mode';
import type { CustomerManagementTemporaryAPIFormValues } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/types/table';
import { calculateEmptyRows } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/utils/calculate-empty-rows';
import { createInitialRows } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/utils/create-initial-rows';
import { useResetTable } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/hooks/use-reset-table';

function EditorModeButtons() {
  const queryClient = useQueryClient();
  const { setValue } = useFormContext<{
    rows: CustomerManagementTemporaryAPIFormValues;
  }>();
  const { openLeaveWithoutSavingModal } = useResetTable();
  const { hospitalID } = useSelectedHospitalInfo();

  const customerTableDisplayMode = useAtomValue(customerTableDisplayModeState);
  const isEditorMode = customerTableDisplayMode === 'EDITOR';

  useLockRefreshTimer(hospitalID, isEditorMode);

  return (
    <div className='flex gap-10'>
      <OutlinedButton
        onClick={() => {
          openLeaveWithoutSavingModal({
            cb: async () => {
              const queryData = (await queryClient.getQueriesData({
                queryKey: [QUERY_KEY.apiPatientsElTemporaryListOrSearch],
              })[0][1]) as {
                pages: {
                  patients: CustomerManagementTemporaryAPIFormValues;
                }[];
              };

              const prevData = queryData?.pages[0]?.patients ?? [];

              setValue(
                'rows',
                [
                  ...prevData,
                  ...createInitialRows(
                    calculateEmptyRows(prevData.length),
                    prevData[prevData.length - 1]?.order ?? 0,
                    hospitalID,
                  ),
                ],
                {
                  shouldDirty: false,
                  shouldTouch: false,
                },
              );
              toastService.successMsg({
                text: '고객정보 수정이 취소되었습니다.',
              });
            },
          });
        }}
        btnColor='red'>
        취소
      </OutlinedButton>
      <Suspense fallback={<OutlinedButton btnColor='blue'>임시 저장</OutlinedButton>}>
        <TemporarySaveButton />
      </Suspense>
      <Suspense fallback={<ContainedButton btnColor='blue'>저장</ContainedButton>}>
        <FinalSaveButton />
      </Suspense>
    </div>
  );
}

export default memo(EditorModeButtons);
