import { SHARED_UTILS } from '@shared-utils/utils';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useMemo, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { ApiAutomationsElAlgorithmsMessagesParams } from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import { safeStringify } from 'web/shared/utils/safe-stringify';
import { isBaseDataSettingCompletedState } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/components/AutomatedMessageLists/states/is-base-data-setting-completed';
import type { MARKETING_MESSAGE_SETTING_TAB_ITEMS } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/containers/MessageContentForm/constants/tab';
import type { MarketingAutomationAPIFormValues } from '../components/RegisterMarketingAutomationDialogContent';
import {
  isEventSendingCountChangedState,
  isEventStartDateChangedState,
} from '../containers/MarketingEventStartDateSetting/states/is-event-start-date-changed';
import { algorithmModeState } from '../containers/MarketingMessageSetting/states/algorithm-mode';
import {
  selectedContentTabItemState,
  selectedMessageState,
} from '../containers/MarketingMessageSetting/states/message-form';
import { originMessagesState } from '../containers/MarketingMessageSetting/states/origin-message';
import { useSingleAlgorithmInfo } from './use-single-algorithm-info';

const fetchAutomationsElAlgorithmsMessages = async (
  params: ApiAutomationsElAlgorithmsMessagesParams,
) => {
  const response = await apiClient.v3.apiAutomationsElAlgorithmsMessages(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export const useAlgorithmData = () => {
  const isFirstRender = useRef(true);

  const { setValue, getValues } = useFormContext<MarketingAutomationAPIFormValues>();
  const { hospitalID } = useSelectedHospitalInfo();

  const algorithmMode = useAtomValue(algorithmModeState);
  const [isEventStartDateChanged, setIsEventStartDateChanged] = useAtom(
    isEventStartDateChangedState,
  );
  const [isEventSendingCountChanged, setIsEventSendingCountChanged] = useAtom(
    isEventSendingCountChangedState,
  );
  const setSelectedContentTabItem = useSetAtom(selectedContentTabItemState);
  const setOriginMessages = useSetAtom(originMessagesState);
  const setSelectedMessage = useSetAtom(selectedMessageState);
  const setIsBaseDataSettingCompleted = useSetAtom(isBaseDataSettingCompletedState);

  const { singleAlgorithmInfo } = useSingleAlgorithmInfo();

  const { data: algorithmMessagesData } = useQuery({
    queryKey: [
      QUERY_KEY.apiAutomationsElAlgorithmsMessages,
      {
        algorithmId: algorithmMode.mode !== 'CREATE' ? algorithmMode.algorithmId : '',
        hospitalID,
      },
    ] as const,
    queryFn: ({ queryKey }) => {
      if (algorithmMode.mode !== 'CREATE') {
        return fetchAutomationsElAlgorithmsMessages(queryKey[1]);
      }
    },
    enabled: algorithmMode.mode !== 'CREATE',
    select: (data) =>
      data?.map((message) => ({
        ...message,
        canBeSentToFriendTalk: message.canBeSentToFriendTalk ?? false,
      })),
  });

  const messagesData: MarketingAutomationAPIFormValues['messages'] = useMemo(
    () =>
      algorithmMessagesData?.map(
        ({ id, name, content, sendingDateTime, createdAt, updatedAt }) => ({
          id,
          name,
          sendingType: 'BASIC' as const,
          sendingDateTime,
          content: {
            text: content?.text,
            image: content?.images
              ? safeStringify(
                  content.images.map((image) => ({
                    id: content.imageFileID,
                    url: image,
                  })),
                )
              : undefined,
          },
          createdAt,
          updatedAt,
        }),
      ) || [],
    [algorithmMessagesData],
  );

  useEffect(() => {
    if (!isFirstRender.current) return;
    if (!singleAlgorithmInfo || !algorithmMessagesData || !messagesData) return;

    const setBaseData = () => {
      const {
        name,
        tagsHaveFolder,
        tagsHaveNoFolder,
        sendingSchedule,
        appliedNationalities,
        isForNoTreatmentTags,
        isForNoNationality,
      } = singleAlgorithmInfo;

      if (!getValues('name').length) {
        setValue('name', name);
      }

      if (!getValues('isForNoTreatmentTags') && isForNoTreatmentTags) {
        setValue('isForNoTreatmentTags', true);
      }

      if (!isEventStartDateChanged) {
        setValue('sendingSchedule.startDay', sendingSchedule?.startDay ?? dayjs().toISOString());
      }

      if (!isEventSendingCountChanged) {
        setValue('sendingSchedule.sendingCount', sendingSchedule?.sendingCount ?? 1);
      }

      if (!getValues('isForNoNationality') && isForNoNationality) {
        setValue('isForNoNationality', true);
      }

      if (!getValues('targetTreatmentTags')?.length) {
        setValue('targetTreatmentTags', [
          ...tagsHaveFolder.flatMap(({ tags }) => tags.map((tag) => tag._id)),
          ...tagsHaveNoFolder.map((tag) => tag._id),
        ]);
      }

      if (!getValues('toBeAppliedNationalityIds')?.length) {
        setValue(
          'toBeAppliedNationalityIds',
          appliedNationalities.map((appliedNationality) => appliedNationality._id),
        );
      }
    };

    const setMessagesData = () => {
      if (isEventSendingCountChanged || isEventStartDateChanged) {
        return;
      }

      setOriginMessages(algorithmMessagesData);
      setValue('messages', messagesData);
      setSelectedMessage({
        ...messagesData[0],
        id: messagesData[0].id,
        index: 0,
      });
    };

    if (algorithmMode.mode === 'EDIT' || algorithmMode.mode === 'COPY') {
      isFirstRender.current = false;

      const handleContentTabItem = () => {
        const newContentTabItem: Record<
          string,
          (typeof MARKETING_MESSAGE_SETTING_TAB_ITEMS)[number]
        > = {};

        for (const message of messagesData) {
          if (message.content.image && message.content.image.length > 0) {
            newContentTabItem[message.id] = '이미지';
          } else {
            newContentTabItem[message.id] = '텍스트';
          }
        }

        setSelectedContentTabItem((prev) => ({
          ...prev,
          ...newContentTabItem,
        }));
      };
      handleContentTabItem();

      setBaseData();
      setMessagesData();

      setIsBaseDataSettingCompleted(true);
    }
  }, [
    singleAlgorithmInfo,
    algorithmMessagesData,
    algorithmMode.mode,
    isEventSendingCountChanged,
    isEventStartDateChanged,
  ]);
};
