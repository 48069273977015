import ContainedButton from '@afterdoc-design-system/components/Atoms/Button/ContainedButton';
import OutlinedButton from '@afterdoc-design-system/components/Atoms/Button/OutlinedButton';
import { toastService } from '@afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { modalService } from '@afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type SubmitHandler, useFormContext } from 'react-hook-form';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import { usePatientDetailInfo } from 'web/shared/hooks/use-get-patient-detail-info';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import type { APIFormValues } from '../../CareAndCustomerInfoInputForm.container';

interface SaveButtonProps {
  disabled?: boolean;
}

type PostReInvitationSMSParams = Parameters<
  typeof apiClient.v3.apiPatientsRegistrationsElReinvitationWithSms
>[0];

const postPatientsInfo = async (params: APIFormValues) => {
  const response = await apiClient.v3.apiPatientsElUpsertSingle(params);
  return response.data;
};

const postReInvitationSMS = async (params: PostReInvitationSMSParams) => {
  const response = await apiClient.v3.apiPatientsRegistrationsElReinvitationWithSms(params);
  return response;
};

export default function SaveButton({ disabled }: SaveButtonProps) {
  const queryClient = useQueryClient();

  const {
    handleSubmit,
    getValues,
    formState: { defaultValues },
  } = useFormContext<APIFormValues>();

  const { hospitalID } = useSelectedHospitalInfo();
  const { patientId, name, automationAppliedTreatmentTag } = usePatientDetailInfo();

  const updateCustomerSuccess = async () => {
    if (
      !!automationAppliedTreatmentTag?.automationApplied &&
      defaultValues?.wantToDeleteAutomationAppliedTreatmentTag !==
        getValues('wantToDeleteAutomationAppliedTreatmentTag') &&
      getValues('wantToDeleteAutomationAppliedTreatmentTag')
    ) {
      toastService.successMsg({
        text: '상담자동화 태그가 삭제되어 진행 중인 상담자동화를 종료하였습니다.',
      });
    } else {
      toastService.successMsg({ text: '고객 정보를 업데이트 했습니다.' });
    }

    queryClient.invalidateQueries({
      queryKey: [QUERY_KEY.apiPatientsElDetail, { patientId }],
    });
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEY.apiPatientsElListOrSearch],
    });
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEY.apiChatroomElFind],
    });
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEY.apiPatientsElListOrSearchForUntagged],
    });
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEY.apiPatientsElUntaggedCount, { hospitalID }],
    });
  };

  const mutationPostReInvitationSMS = useMutation({
    mutationFn: (params: PostReInvitationSMSParams) => postReInvitationSMS(params),
    onSuccess: (data) => {
      // 성공한 경우
      if (data.data.code === 0) {
        return toastService.successMsg({
          text: '애프터닥 앱 가입 문자를 발송했습니다.',
        });
      }

      // 실패한 경우
      return modalService.defaultWarning({
        id: 're-invitation-sms-fail',
        title: '문자 발송 실패',
        contents: '문자 발송을 실패했습니다. 다시 시도하시겠습니까?',
        buttonText: ['확인', '취소'],
        onConfirm: () => {
          mutationPostReInvitationSMS.mutate({
            hospitalID,
            hserviceID: patientId,
          });
          modalService.popById('re-invitation-sms-fail');
        },
      });
    },
  });

  const mutationPostPatientsInfo = useMutation({
    mutationFn: (data: APIFormValues) => postPatientsInfo(data),
    onSuccess: (response) => {
      // 성공한 경우
      if (response.code === 0) {
        // 휴대폰번호 변경 시
        if (response.data.isPhoneChanged) {
          return modalService.defaultSuccess({
            title: '휴대폰번호 변경',
            buttonType: 'CUSTOM',
            bottomButtons: (
              <div className='flex gap-8'>
                <OutlinedButton
                  className='w-[136px]'
                  btnColor='blue'
                  onClick={() => {
                    updateCustomerSuccess();
                    modalService.pop();
                  }}>
                  취소
                </OutlinedButton>
                <ContainedButton
                  className='w-[136px]'
                  onClick={() => {
                    updateCustomerSuccess();
                    mutationPostReInvitationSMS.mutate({
                      hospitalID,
                      hserviceID: patientId,
                    });
                    modalService.pop();
                  }}
                  btnColor='blue'>
                  확인
                </ContainedButton>
              </div>
            ),
            contents: (
              <div className='whitespace-pre-wrap'>
                {`휴대폰번호가 변경되었습니다.\n${name}님께 다운로드 문자를 전송할까요?`}
              </div>
            ),
          });
        }

        if (response.data.isUpdatedSuccess) {
          return updateCustomerSuccess();
        }
      }

      // 실패한 경우 - 사용 중인 휴대폰 번호
      if (response.code === 40001) {
        return modalService.defaultSuccess({
          title: '사용 중인 휴대폰번호',
          buttonType: 'CONFIRM',
          buttonText: '확인',
          contents: (
            <div className='whitespace-pre-wrap'>
              {'다른 고객의 휴대폰번호와 동일합니다.\n휴대폰번호를 다시 확인해주세요.'}
            </div>
          ),
        });
      }

      // 나머지 실패
      return toastService.errorMsg({ text: '고객 정보 업데이트를 실패했습니다.' });
    },
    onError: (error) => {
      console.error('Error saving memo:', error);
    },
  });

  const onSubmit: SubmitHandler<APIFormValues> = (data) => {
    const convertedData = {
      ...data,
      phoneNumber: data.phoneNumber.replace(/-/g, ''),
      birthdate: data.birthdate ? data.birthdate.replace(/-/g, '') : undefined,
      treatmentTagIds: data.treatmentTagIds?.filter(
        (tagId) => tagId !== automationAppliedTreatmentTag?.tagId,
      ),
    };

    mutationPostPatientsInfo.mutate(convertedData);
  };

  return (
    <div className='mt-20 flex w-full justify-end'>
      <ContainedButton
        disabled={disabled}
        buttonSize='small'
        type='button'
        onClick={handleSubmit(onSubmit)}>
        저장
      </ContainedButton>
    </div>
  );
}
