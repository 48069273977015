export const INTERVAL_NUM = 20;

export const DEFAULT_PARAMS = {
  way: -1,
  isActive: true,
  isDoctor: false,
};

export const DEFAULT_CHATTING_LIST_PARAMS = {
  ...DEFAULT_PARAMS,
  limit: INTERVAL_NUM,
  key: 'lastMessageUpdatedAt',
};
