import * as React from "react";
import type { SVGProps } from "react";
const SvgMore = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path fill="#000" d="M9 5h2v2H9zM9 9h2v2H9zM9 13h2v2H9z" />
  </svg>
);
export default SvgMore;
