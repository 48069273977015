import AddressSearchDialog from '@templates/HospitalSetting/containers/HospitalProfile/components/HospitalAddress/components/AddressSearchDialog/AddressSearchDialog';
import { hospitalAddressQueryKeyState } from '@templates/HospitalSetting/containers/HospitalProfile/components/HospitalAddress/components/AddressSearchDialog/jotai/address-query-key';
import { hospitalInfoState } from '@templates/HospitalSetting/containers/HospitalProfile/states/hospital-info-state';
import OutlinedButton from 'afterdoc-design-system/components/Atoms/Button/OutlinedButton';
import TextInput from 'afterdoc-design-system/components/Atoms/Input/TextInput';
import { dialogService } from 'afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import { useAtom } from 'jotai/index';
import { selectAtom, useResetAtom } from 'jotai/utils';
import React from 'react';

const hospitalAddressInfoAtom = selectAtom(hospitalInfoState, (hospital) => hospital.addressInfo);

export default function PrimaryAddress() {
  const [addressInfo] = useAtom(hospitalAddressInfoAtom);
  const resetHospitalAddressQueryKey = useResetAtom(hospitalAddressQueryKeyState);

  const handleClick = () => {
    dialogService.push(<AddressSearchDialog />, {
      id: 'address-search-dialog',
      titleProps: {
        title: '주소 검색',
      },
      onClose: () => {
        resetHospitalAddressQueryKey();
      },
    });
  };

  return (
    <>
      <div className='flex items-center gap-10'>
        <TextInput
          placeholder='주소를 입력해 주세요.'
          disabled={true}
          onChange={() => {}}
          label={{
            isRequired: true,
            children: '주소',
            width: 60,
            position: 'horizontal',
          }}
          className='flex-grow'
          value={addressInfo?.roadAddr ?? ''}
        />
        <OutlinedButton btnColor='blue' onClick={handleClick}>
          주소검색
        </OutlinedButton>
      </div>
    </>
  );
}
