import type { VideoContentHospitalThumbnails } from '@apis/swaggers/swagger-docs';

export type Section = {
  title: string;
  key: keyof VideoContentHospitalThumbnails;
  fileKey?: keyof VideoContentHospitalThumbnails;
};

export const CONTENT_SECTIONS: Section[] = [
  { title: '관련질환', key: 'relatedDiseases' },
  { title: '종류', key: 'exerciseType' },
  { title: '구성', key: 'exerciseType', fileKey: 'exerciseSet' },
  { title: '정보', key: 'description' },
  { title: '준비물', key: 'material', fileKey: 'material' },
  { title: '주의사항', key: 'precaution' },
  { title: '검색키워드', key: 'searchKeyword' },
  {
    title: '적용/자극부위',
    key: 'stimulateMuscles',
    fileKey: 'stimulateMusclesImageID',
  },
];
