import { fullDimmedLoadingService } from '@afterdoc-design-system/components/Atoms/Loading/FullDimmedLoading/FullDimmedLoading.service';
import { toastService } from '@afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { modalService } from '@afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { overlayPageService } from '@afterdoc-design-system/components/Molecules/OverlayPage/OverlayPage.service';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type {
  AlgorithmWithMessagesInput,
  UpdateAlgorithmMainDataHandlerBodyRequest,
} from 'web/apis/swaggers/swagger-docs';
import { OVERLAY_PAGE_ID } from 'web/shared/constants/overlay-page-id';
import { useProcessImagesForUpload } from 'web/shared/hooks/files/images/use-process-images-for-upload';
import { useProcessVideoForUpload } from 'web/shared/hooks/files/video/use-process-video-for-upload';
import { useOpenAutomationDialog } from 'web/shared/hooks/overlayPage/use-open-automation-dialog';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import type { CounselAutomationAPIFormValues } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/components/RegisterCounselAutomationDialogContent';
import type { ParseEventOrHomecareContent } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/components/Forms/EventOrHomecareContentForm/EventOrHomecareContentForm';
import type { ParseWorkoutVideoContent } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/components/Forms/WorkoutVideoContentForm/WorkoutVideoContentForm';
import { algorithmModeState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/algorithm-mode';
import { selectedMessageIndexState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/message-form';
import { messageWarningStatesState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/message-warning-states';
import { originMessagesState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/origin-message';
import { useCounselAutomationResetAll } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/hooks/use-counsel-automation-reset-all';
import { useHandleCounselAutomationMessages } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/hooks/use-handle-counsel-automation-messages';
import { useDeleteAlgorithm } from 'web/templates/Automation/containers/shared/hooks/use-delete-algorithm';
import AutomationZoomInOutViewerContainer from '../../../Viewer/AutomationZoomInOutViewer/AutomationZoomInOutViewer.container';

const registerCounselAutomation = async (params: AlgorithmWithMessagesInput) => {
  const response = await apiClient.v3.apiAutomationsElAlgorithmsSingle(params);
  return response.data;
};

export const useSubmitRegisterCounselAutomation = () => {
  const queryClient = useQueryClient();
  const { getValues, reset, watch } = useFormContext<CounselAutomationAPIFormValues>();

  const openAutomationDialog = useOpenAutomationDialog();

  const { hospitalID } = useSelectedHospitalInfo();
  const { handleAutomationMessages, handleUpdateAlgorithmMainData } =
    useHandleCounselAutomationMessages();
  const { handleDeleteAlgorithm } = useDeleteAlgorithm({
    type: 'CONSULTATION',
  });

  const [disabled, setDisabled] = useState(true);

  const messageWarningStates = useAtomValue(messageWarningStatesState);
  const originMessages = useAtomValue(originMessagesState);
  const algorithmMode = useAtomValue(algorithmModeState);
  const selectedMessageIndex = useAtomValue(selectedMessageIndexState);

  // 기본 데이터
  const name = watch('name');
  const commonAlgorithm = watch('commonAlgorithm');
  const messageLists = watch('messages');

  // 메시지 데이터
  const hasApprovedAlimtalkTemplate =
    originMessages[selectedMessageIndex]?.hasApprovedAlimtalkTemplate ?? false;

  const registerText = useMemo(() => {
    if (algorithmMode.mode === 'CREATE') {
      return '상담자동화가 등록되었습니다.';
    }

    if (algorithmMode.mode === 'COPY') {
      return '상담자동화가 복사되었습니다.';
    }

    return '상담자동화가 수정되었습니다.';
  }, [algorithmMode.mode]);

  const { processImagesForUpload } = useProcessImagesForUpload();

  const { processVideoForUpload } = useProcessVideoForUpload();

  const { resetAll } = useCounselAutomationResetAll({
    reset,
  });

  const handleOnSuccess = (text: string) => {
    if (algorithmMode.mode === 'CREATE') return;

    toastService.successMsg({
      text,
    });
    resetAll();

    queryClient.invalidateQueries({
      queryKey: [QUERY_KEY.apiAutomationsElMainList, { hospitalID }],
    });
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEY.apiAutomationsElOngoing, { hospitalID }],
    });
    queryClient.invalidateQueries({
      queryKey: [
        QUERY_KEY.apiAutomationsElAlgorithmsMessages,
        { algorithmId: algorithmMode.algorithmId, hospitalID },
      ],
    });
    queryClient.invalidateQueries({
      queryKey: [
        QUERY_KEY.singleAlgorithmHandler,
        { algorithmId: algorithmMode.algorithmId, hospitalID },
      ],
    });

    modalService.popAll();
    overlayPageService.popById(OVERLAY_PAGE_ID['register-counsel-automation-dialog']);
  };

  const registerMessagesMutation = useMutation({
    mutationFn: registerCounselAutomation,
    onSuccess: ({ code, message, data }) => {
      if (code === 0 && !!data?.createdAlgorithmId) {
        toastService.successMsg({
          text: registerText,
        });

        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.apiAutomationsElMainList, { hospitalID }],
        });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.apiAutomationsElOngoing, { hospitalID }],
        });

        resetAll();
        modalService.popAll();
        overlayPageService.popById(OVERLAY_PAGE_ID['register-counsel-automation-dialog']);

        if (algorithmMode.mode !== 'CREATE') {
          openAutomationDialog(
            <AutomationZoomInOutViewerContainer
              algorithmId={data.createdAlgorithmId}
              type='CONSULTATION'
            />,
            OVERLAY_PAGE_ID['counsel-automation-zoom-in-out-viewer'],
            undefined,
            { replace: true },
          );
        }
      } else {
        toastService.errorMsg({
          text: message ?? '상담자동화 등록을 실패했습니다. 다시 시도해 주세요.',
        });
      }
    },
    onError: (error) => {
      console.error('error', error);
      toastService.errorMsg({
        text: '상담자동화 등록을 실패했습니다. 다시 시도해 주세요.',
      });
    },
    onSettled: () => {
      setDisabled(false);
      fullDimmedLoadingService.off();
    },
  });

  const handleCounselAutomationSubmit = async () => {
    const originValues = getValues();
    const newValues = { ...originValues };

    setDisabled(true);

    try {
      for (let i = 0; i < newValues.messages.length; i++) {
        const message = newValues.messages[i];
        const originMessage = originMessages[i];

        if (message.content.image) {
          newValues.messages[i].content.image = await processImagesForUpload({
            newImages: JSON.parse(message.content.image),
            originalImages:
              originMessage?.content?.images?.map((image) => ({
                url: image,
              })) ?? [],
          });
        } else if (message.content.video) {
          newValues.messages[i].content.video = await processVideoForUpload(
            JSON.parse(message.content.video),
          );
        } else if (message.content.workoutVideo) {
          const parseWorkoutVideoContent: ParseWorkoutVideoContent = JSON.parse(
            message.content.workoutVideo,
          );
          newValues.messages[i].content.workoutVideo = parseWorkoutVideoContent._id;
        } else if (message.content.homecareContent) {
          const parsedHomecareContent: ParseEventOrHomecareContent = JSON.parse(
            message.content.homecareContent,
          );
          newValues.messages[i].content.homecareContent = parsedHomecareContent._id;
        } else if (message.content.event) {
          const parsedEventContent: ParseEventOrHomecareContent = JSON.parse(message.content.event);
          newValues.messages[i].content.event = parsedEventContent._id;
        }

        if (message.content.text === '') {
          newValues.messages[i].content.text = undefined;
        }
      }
    } catch (error) {
      console.error('File upload failed:', error);
      toastService.errorMsg({
        text: '파일 업로드에 실패했습니다. 다시 시도해 주세요.',
      });
      setDisabled(false);
      return;
    }

    if (algorithmMode.mode === 'CREATE' || algorithmMode.mode === 'COPY') {
      fullDimmedLoadingService.on();
      registerMessagesMutation.mutate({
        ...newValues,
        commonAlgorithm: commonAlgorithm
          ? {
              enabled: commonAlgorithm?.enabled,
              sendingDaysAfterStart: commonAlgorithm?.sendingDaysAfterStart ?? 60,
            }
          : {
              enabled: false,
            },
      });
      return;
    }

    if (algorithmMode.mode === 'EDIT') {
      const updatedAlgorithmValues: UpdateAlgorithmMainDataHandlerBodyRequest = {
        algorithmId: algorithmMode.algorithmId,
        hospitalID,
        name,
        commonAlgorithm: commonAlgorithm
          ? {
              enabled: commonAlgorithm?.enabled,
              sendingDaysAfterStart: commonAlgorithm?.sendingDaysAfterStart ?? 60,
            }
          : {
              enabled: false,
              sendingDaysAfterStart: 60,
            },
      };

      await handleUpdateAlgorithmMainData({
        updatedAlgorithmValues,
      });

      await handleAutomationMessages({
        onSuccess: () => {
          handleOnSuccess('상담자동화가 수정되었습니다.');
          setDisabled(false);
        },
        newMessagesValues: newValues.messages,
        setDisabled,
      });
      return;
    }

    if (algorithmMode.mode === 'TARGET_EDITTED') {
      try {
        await handleDeleteAlgorithm(algorithmMode.algorithmId);

        // 0.5초 딜레이 후 새로운 메시지 등록 및 쿼리 무효화
        await new Promise((resolve) => setTimeout(resolve, 500));

        registerMessagesMutation.mutateAsync(newValues);
      } catch (error) {
        console.error('Error in TARGET_EDITTED mode:', error);
        toastService.errorMsg({
          text: '작업 처리 중 오류가 발생했습니다. 다시 시도해 주세요.',
        });
      }
      return;
    }
  };

  const handleRegisterWithCondition = () => {
    if (disabled) return;

    if (algorithmMode.mode === 'CREATE' || algorithmMode.mode === 'COPY') {
      return handleCounselAutomationSubmit();
    }

    if (hasApprovedAlimtalkTemplate) {
      return modalService.defaultWarning({
        title: '메시지를 수정할까요?',
        contents:
          '자동화를 수정한 시점부터 해당하는 고객에게 메시지를 전송합니다. 카카오 알림톡은 검수 담당자의 승인 이후 적용됩니다. 승인까지는 3~5일이 소요됩니다.',
        onConfirm: async () => {
          modalService.pop();
          fullDimmedLoadingService.on();
          await handleCounselAutomationSubmit();
          fullDimmedLoadingService.off();
        },
        onCancel: modalService.pop,
      });
    }

    return modalService.defaultWarning({
      title: '메시지를 수정할까요?',
      contents: '자동화를 수정한 시점부터 해당하는 고객에게 메시지를 전송합니다.',
      onConfirm: async () => {
        modalService.pop();
        fullDimmedLoadingService.on();
        await handleCounselAutomationSubmit();
        fullDimmedLoadingService.off();
      },
      onCancel: modalService.pop,
    });
  };

  useEffect(() => {
    if (!messageLists.length) return;

    const isDisabled = Object.values(messageWarningStates).some((value) => value);

    setDisabled(isDisabled);
  }, [messageLists, messageWarningStates]);

  return {
    disabled: disabled || registerMessagesMutation.isPending,
    handleRegister: handleRegisterWithCondition,
  };
};
