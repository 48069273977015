export const LIMIT_SIZE = 30 * 1024 * 1024;

export const VIDEO_FILE_EXTENSIONS = ['mp4'];

export const IMAGE_EXTENSIONS = [
  'jpeg',
  'jpg',
  'jfif',
  'exif',
  'gif',
  'bmp',
  'png',
  'ppm',
  'pgm',
  'pbm',
  'pnm',
  'tif',
  'tiff',
  'webp',
].map((ext) => ext.toLowerCase());
