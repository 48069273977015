import { NavBar, Statusbar } from 'afterdoc-design-system/assets/images';
import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import Title from 'afterdoc-design-system/components/Molecules/Title/Title';
import DOMPurify from 'dompurify';

interface NoticePreviewProps {
  title: string;
  content: string;
  createdAt: string;
}

export default function NoticePreview({ title, content, createdAt }: NoticePreviewProps) {
  const sanitizedContent = DOMPurify.sanitize(content ?? '');

  return (
    <div className='flex w-[400px] flex-col bg-blue50'>
      <Title
        title='미리보기'
        subTitle='미리보기는 참고용이며, 고객의 디바이스에 따라 다소 차이가 있을 수 있습니다.'
      />
      <div className='mt-40 max-h-[800px] flex-grow px-16 '>
        <div className='h-full flex-col-center overflow-hidden rounded-[24px] border-[5px] border-blueLight bg-white50 shadow-modal'>
          <Statusbar className='w-full bg-white50' />
          <div className='mt-12 flex w-full items-center gap-10 px-20'>
            <Icon name='chevron-left' size={40} color='black800' />
            <p className='font-bold text-[21px] text-black800'>공지사항</p>
          </div>
          <div className='mt-8 w-full flex-grow'>
            <Scrollbar disabledX={true}>
              <div className='flex flex-col'>
                <div className='mt-64 w-full px-12'>
                  <p className='break-words font-bold text-[21px] text-black800'>
                    {title || '공지사항 제목을 입력해 주세요'}
                  </p>
                  <p className='mt-10 break-words text-[12px] text-black200'>{createdAt}</p>
                </div>
                <div className='mt-16 px-6'>
                  <Divider type='line' />
                </div>

                {!sanitizedContent ? (
                  <div className='mt-16 px-16'>
                    <span className=' text-Body14 text-black800'>내용을 입력해 주세요.</span>
                  </div>
                ) : (
                  <div className='editor-viewer'>
                    <div
                      className='content break-words px-16 py-10 text-black700'
                      // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
                      dangerouslySetInnerHTML={{ __html: sanitizedContent }}
                    />
                  </div>
                )}
              </div>
            </Scrollbar>
          </div>
          <NavBar className='w-full' />
        </div>
      </div>
    </div>
  );
}
