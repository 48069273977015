import { useQueryClient } from '@tanstack/react-query';
import { postChatroomUpdateRead } from '@templates/CustomerChat/components/ChattingList/components/ChattingListBody/ChattingListBody';
import { useAtomValue } from 'jotai';
import { useEffect, useMemo, useState } from 'react';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { ChatFileAndContentIDs } from 'web/apis/swaggers/swagger-docs';
import { useUserInfo } from 'web/shared/hooks/use-user-info';
import { ROOM_TYPE_MAP } from 'web/templates/CustomerChat/components/ChattingList/constants/room-type';
import { useInfiniteChatData } from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/hooks/use-infinite-chat-data';
import { useInfiniteMessageIDChatData } from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/hooks/use-infinite-messageID-chat-data';
import { roomTypeSelector } from 'web/templates/CustomerChat/states/selected-chat-room';
import type {
  ChattingResponse,
  ImageChattingResponse,
  TextChattingResponse,
} from 'web/templates/CustomerChat/types';
import { useChatRoomInfoOne } from '../../../hooks/use-chatroom-info-one';
import { REQUEST_ROOM_TYPE_MAP } from '../../ChattingSending/constants/types-codes';
import { postChatroomUsersAdd } from '../apis/post-chatroom-users-add';
import {
  FILE_TYPE_GROUP,
  IMAGE_TYPE_GROUP,
  TEXT_TYPE_GROUP,
} from '../components/ChattingContent/components/ChattingMessage/Message/Message';
import {
  MESSAGE_TYPE_CODE,
  USER_TYPE_CODE,
} from '../components/ChattingContent/components/ChattingMessage/constants/message';
import { useHandleChatSocket } from '../components/ChattingContent/hooks/use-handle-chat-socket';
import { chattingRequestState } from '../states/chatting-request';

export type DataResponse =
  | ChattingResponse
  | TextChattingResponse
  | ImageChattingResponse
  | ChatFileAndContentIDs;

const replaceTextMessage = (
  prev: Array<DataResponse>,
  res: ChattingResponse | TextChattingResponse,
) => {
  // 기존 메시지 중 현재 메시지와 ID가 일치하는 메시지를 찾음
  const existingMessageIndex = prev.findIndex(
    (item) => item?.content === res?.content && item.type === MESSAGE_TYPE_CODE.TEXT_MOCKING,
  );

  if (existingMessageIndex !== -1) {
    prev[existingMessageIndex] = res;
    return [...prev];
  }

  // 일치하는 메시지가 없으면 현재 메시지를 추가
  if (res.isTranslated) {
    return [...prev];
  }
  return [res, ...prev];
};
const replaceAfterdocImageMessage = (
  prev: Array<DataResponse>,
  res: ChattingResponse | TextChattingResponse,
) => {
  const existingMessageIndex = prev.findIndex(
    (item) => item.type === MESSAGE_TYPE_CODE.IMAGE_MOCKING,
  );

  if (existingMessageIndex !== -1) {
    prev[existingMessageIndex] = res;
    return [...prev];
  }

  return [res, ...prev];
};
const replaceKakaoImageMessage = (
  prev: Array<DataResponse>,
  res: ChattingResponse | TextChattingResponse,
) => {
  // 기존 메시지 중 현재 메시지와 ID가 일치하는 메시지를 찾음
  // const existingMessageIndex = prev.findIndex((item) => item?._id === res._id);
  const existingMessageIndex = prev.findIndex(
    // (item) => item._id === res._id && item.type === MESSAGE_TYPE_CODE.IMAGE_MOCKING,
    (item) => item.type === MESSAGE_TYPE_CODE.IMAGE_MOCKING,
  );

  if (existingMessageIndex !== -1) {
    prev[existingMessageIndex] = res;
    return [...prev];
  }

  return [res, ...prev];
};
const replaceAfterdocFileeMessage = (
  prev: Array<DataResponse>,
  res: ChattingResponse | TextChattingResponse,
) => {
  // 기존 메시지 중 현재 메시지와 ID가 일치하는 메시지를 찾음
  const existingMessageIndex = prev.findIndex(
    (item) => item.type === MESSAGE_TYPE_CODE.FILE_MOCKING,
  );

  if (existingMessageIndex !== -1) {
    prev[existingMessageIndex] = res;
    return [...prev];
  }

  return [res, ...prev];
};
const replaceKakaoFileeMessage = (
  prev: Array<DataResponse>,
  res: ChattingResponse | TextChattingResponse,
) => {
  // 기존 메시지 중 현재 메시지와 ID가 일치하는 메시지를 찾음
  const existingMessageIndex = prev.findIndex(
    (item) => item._id === res._id && item.type === MESSAGE_TYPE_CODE.FILE_MOCKING,
  );

  if (existingMessageIndex !== -1) {
    prev[existingMessageIndex] = res;
    return [...prev];
  }

  return [res, ...prev];
};

export function useConditionalChatData(
  selectedMessageID: string | null,
  chatRoomID: string | null,
) {
  // if (!chatRoomID) return null;
  const queryClient = useQueryClient();
  const { authorizationTypeID, userId } = useUserInfo();
  const { participants } = useChatRoomInfoOne({ chatRoomID: chatRoomID ?? '' });
  const [resMessages, setResMessages] = useState<Array<DataResponse>>([]);

  const roomType = useAtomValue(roomTypeSelector);

  const { userId: userID } = useUserInfo();

  const { res } = useHandleChatSocket();
  const req = useAtomValue(chattingRequestState);
  const [data, setData] = useState<Array<DataResponse>>([]);
  const {
    data: infiniteData,
    fetchNextPage: fetchPreviousPage,
    hasNextPage: hasPreviousPage,
    isFetchingNextPage: isFetchingPreviousPage,
    isLoading,
    refetch,
  } = useInfiniteChatData();

  useEffect(() => {
    if (!infiniteData) return;
    setData(infiniteData);
  }, [infiniteData]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    refetch();
    setResMessages([]);
  }, [chatRoomID]);

  const infiniteMessageIDChatData = useInfiniteMessageIDChatData({ selectedMessageID });

  useEffect(() => {
    if (res) {
      console.count('count check res');
      console.info('check res:', res);

      chatRoomID && postChatroomUpdateRead(chatRoomID);

      const { userType } = res;
      const isLeft = userType === USER_TYPE_CODE.PATIENT; // 1000: 환자
      const isLoginUser = res.userID === userID;

      if (isLeft) {
        if (res.isTranslated) {
          const existingMessageIndex = resMessages.findIndex((item) => item?._id === res?._id);

          if (existingMessageIndex !== -1) {
            setResMessages((prev) => {
              prev[existingMessageIndex] = res;
              return [...prev];
            });
          } else {
            const existingMessageIndexInData = data.findIndex((item) => item?._id === res?._id);
            if (existingMessageIndexInData !== -1) {
              setData((prev) => {
                prev[existingMessageIndexInData] = res;
                return [...prev];
              });
              queryClient.invalidateQueries({
                queryKey: [QUERY_KEY.apiChatElFind],
              });
            }
          }

          //번역으로 와서 메시지에 업데이트를 쳐줘야하는데 무한 스크롤 때문에 resMessages와 data에 일치하는 친구가 없을 수 있음.
          //이들은 어차피 데이터를 서버에서 불러올때 번역 처리가 되어있을 것이기에 그냥 무시.
          if (res?.isFromTranslateServer) {
            return;
          }
        }

        //이전에 일반 채팅을 한 것을 번역으로 돌리는 게 아니라
        //일반적으로 번역이 켜져있는 상태에서 보낼 경우는 그냥 그대로 붙이면 됨.
        setResMessages((prev) => [res, ...prev]);

        return;
      }
      const { type } = res;

      switch (roomType) {
        case ROOM_TYPE_MAP.kakao: {
          if (isLoginUser) {
            if (TEXT_TYPE_GROUP.includes(type)) {
              setResMessages((prev) => {
                return replaceTextMessage(prev, res);
              });
            }
            if (IMAGE_TYPE_GROUP.includes(type)) {
              setResMessages((prev) => {
                return replaceKakaoImageMessage(prev, res);
              });
            }
            if (FILE_TYPE_GROUP.includes(type)) {
              setResMessages((prev) => {
                return replaceKakaoFileeMessage(prev, res);
              });
            }
          } else {
            setResMessages((prev) => {
              return [res, ...prev];
            });
          }

          break;
        }
        //patient가 애프터닥이라고 보면 됨.
        case ROOM_TYPE_MAP.afterdoc: {
          if (isLoginUser) {
            if (TEXT_TYPE_GROUP.includes(type)) {
              setResMessages((prev) => {
                return replaceTextMessage(prev, res);
              });
            }
            if (IMAGE_TYPE_GROUP.includes(type)) {
              setResMessages((prev) => {
                return replaceAfterdocImageMessage(prev, res);
              });
            }
            if (FILE_TYPE_GROUP.includes(type)) {
              setResMessages((prev) => {
                return replaceAfterdocFileeMessage(prev, res);
              });
            }
          } else {
            setResMessages((prev) => {
              return [res, ...prev];
            });
          }

          break;
        }
        default:
          console.error('Unsupported room type');
          break;
      }
    }
    //채팅리스트 카운트 업데이트
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEY.apiChatroomElCount],
    });
    //채팅리스트 목록 업데이트
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEY.apiChatroomElFind],
    });
    //채팅 메시지 내용 갱신(
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEY.apiChatroomElFindOne],
    });
  }, [res]);

  //res에 모킹 메시지를 커스텀 하는 걸 건드리는 로직
  useEffect(() => {
    if (req) {
      console.count('count check req');
      console.info('check req:', req);

      //참여자에 빠져있는데 권한이 있어서 채팅을 시작하려는 자.
      chatRoomID && postChatroomUpdateRead(chatRoomID);

      if (
        authorizationTypeID?.canControlPatientChatting &&
        !participants.some((participant) => participant._id === userId)
      ) {
        const addUser = async () => {
          await postChatroomUsersAdd({
            chatRoomID: chatRoomID ?? '', //해당 커스텀 훅은 chatRoomID가 null일수 없음 하지만 return 값을 일일히 적용해줘야 하는 문제 때문에 ''로 default 적용
            onlyAddIDs: [userId],
          });

          queryClient.invalidateQueries({
            queryKey: [QUERY_KEY.apiChatroomElFindOne],
          });
        };

        addUser();
      }

      switch (req.type) {
        case REQUEST_ROOM_TYPE_MAP.AFTERDOC.TEXT_TYPE:
          {
            const payload = req.payload as TextChattingResponse;
            setResMessages((prev) => {
              return [{ ...payload, type: MESSAGE_TYPE_CODE.TEXT_MOCKING }, ...prev];
            });
          }
          break;
        case REQUEST_ROOM_TYPE_MAP.KAKAO.TEXT_TYPE: {
          const payload = req.payload as ChattingResponse;
          setResMessages((prev) => {
            return [{ ...payload, type: MESSAGE_TYPE_CODE.TEXT_MOCKING }, ...prev];
          });
          break;
        }
        case REQUEST_ROOM_TYPE_MAP.KAKAO.IMAGES_FILES_TYPE: {
          const payloads = req.payload as ImageChattingResponse[];

          setResMessages((prev) => {
            return [...payloads, ...prev];
          });
          break;
        }
        case REQUEST_ROOM_TYPE_MAP.KAKAO.IMAGE_TYPE:
        case REQUEST_ROOM_TYPE_MAP.AFTERDOC.FILE_TYPE: {
          const payload = req.payload as ImageChattingResponse;
          setResMessages((prev) => {
            return [payload, ...prev];
          });
          break;
        }
        default:
          break;
      }
    }
  }, [req]);

  const refreshAll = () => {
    refetch();
    setResMessages([]);
  };

  // console.info('check resMessages:', resMessages);

  //어떠한 경우에라도 chat data에 있는 메시지는 무결해야함.
  //어떠한 경우로 empty값이 들어가도 안됨.
  const newResMessages = useMemo(() => {
    return resMessages.filter((message) => {
      return message && !data.some((item) => message._id === item._id);
    });
  }, [resMessages, data]);

  if (!selectedMessageID) {
    return {
      data: [...new Set(newResMessages), ...data],
      refetch,
      refreshAll,
      setResMessages,
      fetchPreviousPage,
      hasPreviousPage,
      isFetchingPreviousPage,
      isLoading,
      hasNextPage: null,
      isFetchingNextPage: false,
      fetchNextPage: () => {},
    };
  }

  return {
    setResMessages,
    refreshAll,
    ...infiniteMessageIDChatData,
  };
}
