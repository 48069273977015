import Scrollbar from '@afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import ServiceManagerMemoImages from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/CareAndCustomerInfoInputForm/containers/ServiceManagerMemo/components/ServiceManagerMemoImages';

interface ServiceManagerMemoContentProps {
  content: string;
  images?: string[];
}

export default function ServiceManagerMemoContent({
  content,
  images,
}: ServiceManagerMemoContentProps) {
  return (
    <div className='flex h-full w-full flex-col gap-10'>
      <div className='h-full rounded-r10 bg-disabled py-16'>
        <div className='h-[600px] w-full'>
          <Scrollbar>
            <div className='px-16 text-Body12 text-black700'>{content}</div>
          </Scrollbar>
        </div>
        {images && <ServiceManagerMemoImages images={images} />}
      </div>
    </div>
  );
}
