import { toastService } from '@afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { modalService } from '@afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { useClickOutside } from '@shared-hooks/use-click-outside';
import { SHARED_UTILS } from '@shared-utils/utils';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { useQueryClient } from '@tanstack/react-query';
import {
  type DropdownDataParams,
  useParentDropdownOptions,
} from '@templates/CustomerChat/components/ParentDropDown/hooks/use-parent-dropdown-options';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import { useAtomValue } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { useId, useMemo, useRef } from 'react';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { UserNameAndType } from 'web/apis/swaggers/swagger-docs';
import { useHandleCustomerInfo } from 'web/shared/hooks/overlayPage/use-handle-customer-detail-info';
import { useUserInfo } from 'web/shared/hooks/use-user-info';
import { Z_INDEXS } from 'web/templates/CustomerChat/constants/layout';
import { usePatient } from 'web/templates/CustomerChat/hooks/use-patient';
import { type PatientIconInfoType, useChatTopPatient } from '../../hooks/use-chat-top-patient';
import {
  chatRoomIDSelector,
  chatRoomParentHServiceIDSelector,
  selectedChatRoomState,
} from '../../states/selected-chat-room';
import { useInfiniteChatData } from '../ChattingRoom/components/ChattingContainer/components/ChattingContent/hooks/use-infinite-chat-data';
import { useChatRoomInfoOne } from '../ChattingRoom/hooks/use-chatroom-info-one';
import { handleOpenElectronReservation } from '../CustomerReservation/functions/handle-open-electron-reservation';
import BottomButtons from './components/BottomButtons/BottomButtons';
import ChildDropdown from './components/ChildDropdown/ChildDropdown';
import { createTopPosition } from './components/ChildDropdown/functions/create-top-position';
import { useRenderingPosition } from './components/ChildDropdown/hooks/use-rendering-position';
import {
  OPTION_CHAT_EXIT,
  OPTION_CHAT_NOT_IN_PROGRESS,
  OPTION_DETAIL,
  OPTION_RESERVATION_ENROLL,
  OPTION_USER_ADD,
  WIDTH,
} from './constants/dropdown';
import { postChatroomUpdateCounselState } from './functions/postChatroomUpdateCounselState';
import { postChatroomUserRemove } from './functions/postChatroomUserRemove';
import { useNextDropdown } from './hooks/use-next-dropdown';

export interface DropdownProps {
  selectedName?: string;
  selectedIconSet?: PatientIconInfoType;
  triggerPosition?: { top: number; left: number };
  inChattingListchatRoomID?: string;
  inChattingListpatientID?: string;
  inChattingCounselors?: UserNameAndType[];
  handleToggle: () => void;
  redTextIndices?: number[];
  isReservationDisabled?: boolean;
  dropdownDataParams: DropdownDataParams;
}

const calculateDropdownHeight = (options: string[]) => 30 * options.length + 8;

//global과 local 데이터들이 섞여있어서, local 데이터가 우선순위를 가짐
export default function ParentDropdown({
  selectedName,
  selectedIconSet,
  triggerPosition = { top: 0, left: 0 },
  inChattingListchatRoomID,
  inChattingListpatientID,
  inChattingCounselors,
  handleToggle,
  isReservationDisabled = false,
  dropdownDataParams,
}: DropdownProps) {
  const queryClient = useQueryClient();

  const { top, left } = triggerPosition;

  const ChatRoomID = useAtomValue(chatRoomIDSelector);
  const chatRoomParentHServiceID = useAtomValue(chatRoomParentHServiceIDSelector);

  const chatRoomID = inChattingListchatRoomID ?? ChatRoomID;
  const thatHospitalPatientId = inChattingListpatientID ?? chatRoomParentHServiceID;
  if (!chatRoomID) return null; //해당 내역이 없을 경우 렌더링하지 않음, 실제로 논리적으로 불가능한 경우

  const { userId: userID, realName, authorizationTypeID } = useUserInfo();
  const { counselors: globalCounselors } = useChatRoomInfoOne({ chatRoomID });
  const counselors = inChattingCounselors ?? globalCounselors;
  const { isKakaoIcon, isColorAfterDocIcon, isGrayAfterDocIcon } = useChatTopPatient();
  const selectedPatientIconInfo = useMemo(() => {
    return {
      isColorAfterDocIcon,
      isKakaoIcon,
      isGrayAfterDocIcon,
    };
  }, [isKakaoIcon, isColorAfterDocIcon, isGrayAfterDocIcon]);

  const iconSet = selectedIconSet ?? selectedPatientIconInfo;

  const resetSelectedChatRoom = useResetAtom(selectedChatRoomState);

  const { refetch } = useInfiniteChatData();

  const { name: patientName } = usePatient();

  const { openCustomerInfo } = useHandleCustomerInfo(thatHospitalPatientId);

  const { data: options, redTextIndices } = useParentDropdownOptions({
    id: dropdownDataParams.id,
    isColorAfterDocIcon: dropdownDataParams.isColorAfterDocIcon,
    isKakaoIcon: dropdownDataParams.isKakaoIcon,
    isGrayAfterDocIcon: dropdownDataParams.isGrayAfterDocIcon,
  });

  const id = useId();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const dropdownHeight = calculateDropdownHeight(options);
  const { position } = useRenderingPosition({
    renderingRef: dropdownRef,
    renderingHeight: dropdownHeight,
  });

  const {
    nextDropdownPosition,
    isNextDropdownOpen,
    updateNextDropdownPosition,
    toggleNextDropdown,
  } = useNextDropdown();

  const handleMouseHover = (el: EventTarget & HTMLLIElement, option: string) => {
    const isChildDropdown = option === OPTION_USER_ADD;

    const parentRect = dropdownRef.current?.getBoundingClientRect();
    const rect = el.getBoundingClientRect();

    const top = parentRect ? rect.top - parentRect.top : rect.top;

    updateNextDropdownPosition({
      top,
      left: left === 0 ? WIDTH : left > 170 ? left - WIDTH : left + WIDTH,
    });
    toggleNextDropdown(isChildDropdown);
  };

  const handleClick = () => {
    const isDisabled =
      isReservationDisabled || (!iconSet?.isColorAfterDocIcon && !iconSet?.isGrayAfterDocIcon);
    handleOpenElectronReservation({ isDisabled, chatRoomParentHServiceID });
  };

  const handleClickOption = async (userID: string, option: string) => {
    switch (option) {
      case OPTION_RESERVATION_ENROLL:
        handleClick();
        break;
      case OPTION_DETAIL:
        openCustomerInfo();
        break;
      case OPTION_CHAT_EXIT:
        modalService.defaultSuccess({
          title: OPTION_CHAT_EXIT,
          contents: (
            <div className='flex flex-col'>
              <div>채팅방을 나간 후에는 채팅 내역을 확인할 수 없습니다.</div>
              <div>
                <b>{selectedName ?? patientName}</b>님 채팅방을 나가시겠습니까?
              </div>
            </div>
          ),
          buttonType: 'CUSTOM',
          bottomButtons: BottomButtons({
            handleConfirmClick: async () => {
              await postChatroomUserRemove({ chatRoomID, userIDs: [userID] });

              // 최준수 : 채팅방 읽음처리 시 네비게이션바 고객상담 뱃지 체크를 위한 쿼리 초기화
              queryClient.invalidateQueries({
                queryKey: [QUERY_KEY.apiChatroomElUnreadcount],
              });
              queryClient.invalidateQueries({
                queryKey: [QUERY_KEY.apiChatroomElCount],
              });
              queryClient.invalidateQueries({
                queryKey: [QUERY_KEY.apiChatroomElFind],
              });
              queryClient.invalidateQueries({
                queryKey: [QUERY_KEY.apiChatroomElFindOne],
              });

              modalService.pop();
              if (ChatRoomID === chatRoomID) {
                resetSelectedChatRoom();
              }
            },
          }),
        });
        break;
      case OPTION_CHAT_NOT_IN_PROGRESS: {
        const counselorIDs = counselors.map((counselor) => counselor._id);
        const isInCounselor = counselorIDs.includes(userID);
        if (authorizationTypeID?.canControlPatientChatting || isInCounselor) {
          try {
            await postChatroomUpdateCounselState({ chatRoomID });

            // 최준수 : 채팅방 읽음처리 시 네비게이션바 고객상담 뱃지 체크를 위한 쿼리 초기화
            queryClient.invalidateQueries({
              queryKey: [QUERY_KEY.apiChatroomElUnreadcount],
            });
            //채팅리스트 카운트 업데이트
            queryClient.invalidateQueries({
              queryKey: [QUERY_KEY.apiChatroomElCount],
            });
            //채팅리스트 목록 업데이트
            queryClient.invalidateQueries({
              queryKey: [QUERY_KEY.apiChatroomElFind],
            });

            //invalidateQueries를 하면 제대로 최신 데이터 처리가 안됨.
            if (ChatRoomID === chatRoomID) {
              refetch();
            }

            toastService.successMsg({ text: `${realName}님이 상담을 종료했습니다.` });
          } catch (error) {
            toastService.errorMsg({ text: '상담 종료를 실패했습니다. 다시 시도해주세요.' });
            console.error(error);
          }
          if (ChatRoomID === chatRoomID) {
            resetSelectedChatRoom();
          }
        } else {
          toastService.errorMsg({ text: '상담 종료 권한이 없습니다.' });
        }
        break;
      }
      default:
        break;
    }
    handleToggle();
  };

  useClickOutside({
    id,
    ref: dropdownRef,
    contentRef,
    onClose: handleToggle,
  });

  return (
    <div
      className='relative'
      style={{ zIndex: Z_INDEXS.ChattingTooltip }}
      onClick={(e) => {
        e.stopPropagation();
      }}>
      <div ref={dropdownRef}>
        <div
          ref={contentRef}
          className={customTwMerge(
            'absolute w-full select-none rounded-r6 bg-white50 py-4 shadow-modal ring-1 ring-black ring-opacity-5',
            position === 'top' ? 'bottom-full mb-2' : 'top-full mt-2',
          )}
          data-ignore-click-outside={true}
          style={{
            height: dropdownHeight,
            top: createTopPosition({ top, dropdownHeight, position }),
            left,
            width: SHARED_UTILS.css.getCssSizeValue(WIDTH),
          }}>
          <ul aria-orientation='vertical' aria-labelledby='options-menu'>
            {options.map((option, index) => (
              <li
                key={option}
                tabIndex={index}
                onClick={() => handleClickOption(userID, option)}
                onMouseEnter={(event) => {
                  const liElement = event.currentTarget;
                  handleMouseHover?.(liElement, option);
                }}
                className={customTwMerge(
                  `block h-30 cursor-pointer px-10 py-7 focus:bg-blue50 focus:outline-none ${
                    redTextIndices.includes(index) ? 'text-red500' : 'text-black500'
                  } flex items-center justify-between hover:bg-blue50 hover:text-Header12 ${isNextDropdownOpen && option === OPTION_USER_ADD ? 'text-Header12' : 'font-regular text-Body12 '}`,
                )}
                item-index={index}>
                <span>{option}</span>
                {option === OPTION_USER_ADD && (
                  <Icon name='chevron-right' color='black200' size={16} />
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
      {isNextDropdownOpen && (
        <ChildDropdown
          triggerPosition={nextDropdownPosition}
          handleToggle={handleToggle}
          isOnMouseLeave={false}
          eachChattingRoomID={chatRoomID}
        />
      )}
    </div>
  );
}
