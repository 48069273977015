import * as React from "react";
import type { SVGProps } from "react";
const SvgMeatball = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path d="M15 9v2h-2V9zM11 9v2H9V9zM7 9v2H5V9z" />
  </svg>
);
export default SvgMeatball;
