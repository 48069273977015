import { AUTHORIZATION_ITEMS } from '@templates/HospitalSetting/containers/AllAccountSetting/constants/authorization-items';
import TextFieldSelectBoxDropdown from 'afterdoc-design-system/components/Molecules/TextSelect/TextFieldSelectBoxDropdown';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

export default function AccountAuthorization() {
  const [authorizationIndex, setAuthorizationIndex] = useState<number | undefined>(undefined);

  const {
    setValue,
    watch,
    register,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const authorizationType = watch('authorizationType');

  const handleSelect = (index: number) => {
    setAuthorizationIndex(index);
    clearErrors('authorizationType');
    setValue('authorizationType', AUTHORIZATION_ITEMS[index]);
  };

  useEffect(() => {
    register('authorizationType');
  }, [register]);

  useEffect(() => {
    if (authorizationType === undefined) {
      setAuthorizationIndex(authorizationType);
    }
  }, [authorizationType]);

  return (
    <div className='flex w-[164px] flex-col gap-4'>
      <TextFieldSelectBoxDropdown
        label={{
          children: '권한',
          isRequired: true,
        }}
        width={'100%'}
        options={AUTHORIZATION_ITEMS}
        onSelect={handleSelect}
        defaultValue='선택'
        selectedIndex={authorizationIndex}
      />
      {!!errors.authorizationType && (
        <span className='text-Body10 text-red500'>권한을 선택해 주세요.</span>
      )}
    </div>
  );
}
