import * as React from "react";
import type { SVGProps } from "react";
const SvgNavBar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 360 48"
    {...props}
  >
    <path fill="#fff" d="M0 0h360v48H0z" />
    <rect width={14} height={14} x={273} y={17} fill="#797979" rx={2} />
    <path
      fill="#797979"
      fillRule="evenodd"
      d="M83.484 17.146a1 1 0 0 1 1.516.857v11.994a1 1 0 0 1-1.516.857l-9.962-5.997a1 1 0 0 1 0-1.714zM188 24a8 8 0 1 1-16 0 8 8 0 0 1 16 0m-1 0a7 7 0 1 1-14 0 7 7 0 0 1 14 0m-7 6a6 6 0 1 0 0-12 6 6 0 0 0 0 12"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgNavBar;
