import ContentImagePicker from '@templates/Content/containers/ContentManager/containers/EventHomeCare/shared/components/Manage/DetailContentSelector/components/ContentImagePicker';
import ContentTextEditor from '@templates/Content/containers/ContentManager/containers/EventHomeCare/shared/components/Manage/DetailContentSelector/components/ContentTextEditor';
import { contentEditState } from '@templates/Content/containers/ContentManager/states/content-edit-state';
import LabelText from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import Radio from 'afterdoc-design-system/components/Atoms/Radio/Radio';
import {
  RadioGroup,
  type ValueType,
} from 'afterdoc-design-system/components/Atoms/Radio/RadioGroup';
import { useAtomValue } from 'jotai/index';
import { memo, useState } from 'react';

interface DetailContentSelectorProps {
  type: string;
  content?: string;
  imagePath?: string;
  onHandleContentTypeValue: (value: string) => void;
  onHandleContentValue: (value: string) => void;
}

const DetailContentSelector = memo(
  ({
    type,
    content,
    imagePath,
    onHandleContentTypeValue,
    onHandleContentValue,
  }: DetailContentSelectorProps) => {
    const { id } = useAtomValue(contentEditState);

    const [imageUrl, setImageUrl] = useState(imagePath);

    const handleChange = (value: ValueType) => {
      onHandleContentTypeValue(value as string);
    };

    return (
      <div className={`mt-10 flex w-full gap-28 ${id ? 'pb-18' : 'pb-40'}`}>
        <LabelText
          width={72}
          isRequired={true}
          className='flex h-32 items-center whitespace-nowrap'>
          상세정보
        </LabelText>
        <div className='flex flex-grow flex-col gap-10'>
          <div className='flex gap-20'>
            <RadioGroup name='contentType' value={type} onChange={handleChange}>
              <Radio id='image' label='이미지' value={'image'} />
              <Radio id='text' label='텍스트' value={'text'} />
            </RadioGroup>
          </div>
          {type === 'image' && (
            <ContentImagePicker imageUrl={imageUrl} onHandleChange={(url) => setImageUrl(url)} />
          )}
          {type === 'text' && (
            <ContentTextEditor content={content} onHandleContentValue={onHandleContentValue} />
          )}
        </div>
      </div>
    );
  },
);

export default DetailContentSelector;
