import ContainedButton from '@afterdoc-design-system/components/Atoms/Button/ContainedButton';
import OutlinedButton from '@afterdoc-design-system/components/Atoms/Button/OutlinedButton';
import { toastService } from '@afterdoc-design-system/components/Atoms/Toast/Toast.service';
import HoverTooltip from '@afterdoc-design-system/components/Atoms/Tooltip/HoverTooltip';
import Icon, {
  type IconColorType,
  type IconType,
  type SpecificIconSizes,
} from '@afterdoc-design-system/components/Foundations/Icon/Icon';
import DownloadFooter from '@afterdoc-design-system/components/Molecules/DownloadFooter/DownloadFooter';
import { overlayPageService } from '@afterdoc-design-system/components/Molecules/OverlayPage/OverlayPage.service';
import { SHARED_UTILS } from '@shared-utils/utils';
import { Color } from '@tailwind-base/styles/color';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSetAtom } from 'jotai';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  type ReactZoomPanPinchRef,
  TransformComponent,
  TransformWrapper,
} from 'react-zoom-pan-pinch';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type {
  AlgorithmWithMessagesInput,
  ApiAutomationsElAlgorithmsMessagesParams,
  SingleAlgorithmHandlerParams,
} from 'web/apis/swaggers/swagger-docs';
import FullLoading from 'web/shared/components/FullLoading/FullLoading';
import { OVERLAY_PAGE_ID } from 'web/shared/constants/overlay-page-id';
import { useOpenAutomationDialog } from 'web/shared/hooks/overlayPage/use-open-automation-dialog';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import { useUserInfo } from 'web/shared/hooks/use-user-info';
import RegisterCounselAutomationDialogContainer from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/RegisterCounselAutomationDialog.container';
import { COUNSEL_TAB_ITEMS } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/constants/counsel-tab-items';
import { algorithmModeState as counselAlgorithmModeState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/algorithm-mode';
import { selectedCounselAutomationTabState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/states/selected-counsel-automation-tab-state';
import RegisterMarketingAutomationDialogContainer from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/RegisterMarketingAutomationDialog.container';
import { MARKETING_TAB_ITEMS } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/constants/marketing-tab-items';
import { algorithmModeState as marketingAlgorithmModeState } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/states/algorithm-mode';
import { selectedMarketingAutomationTabState } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/states/selected-marketing-automation-tab-state';
import { useGetIsKakaoAlimAndFriendTalkLinkage } from 'web/templates/Automation/containers/Dialog/shared/hooks/use-get-is-kakao-alim-and-friend-talk-linkage';
import AlgorithmInfoBox from 'web/templates/Automation/containers/Viewer/AutomationZoomInOutViewer/components/components/AlgorithmInfoBox';
import { MessageCardItem } from 'web/templates/Automation/containers/Viewer/AutomationZoomInOutViewer/components/components/MessageCardItem/MessageCardItem';
import ZoomInOutController from 'web/templates/Automation/containers/Viewer/AutomationZoomInOutViewer/components/components/ZoomInOutController';
import { useDeleteAlgorithm } from 'web/templates/Automation/containers/shared/hooks/use-delete-algorithm';

const fetchAutomationsElAlgorithmsMessages = async (
  params: ApiAutomationsElAlgorithmsMessagesParams,
) => {
  const response = await apiClient.v3.apiAutomationsElAlgorithmsMessages(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const fetchSingleAlgorithmInfo = async (params: SingleAlgorithmHandlerParams) => {
  const response = await apiClient.v3.singleAlgorithmHandler(params);
  return response.data.data;
};

interface AutomationZoomInOutViewerContentProps {
  algorithmId: string;
  type: Exclude<AlgorithmWithMessagesInput['type'], 'COMMON'>;
}

export default function AutomationZoomInOutViewerContent({
  algorithmId,
  type,
}: AutomationZoomInOutViewerContentProps) {
  const queryClient = useQueryClient();
  const { hospitalID } = useSelectedHospitalInfo();

  const openAutomationDialog = useOpenAutomationDialog();
  const setCounselMessageMode = useSetAtom(counselAlgorithmModeState);
  const setMarketingMessageMode = useSetAtom(marketingAlgorithmModeState);
  const setSelectedCounselAutomationTab = useSetAtom(selectedCounselAutomationTabState);
  const setSelectedMarketingAutomationTab = useSetAtom(selectedMarketingAutomationTabState);

  const transformRef = useRef<ReactZoomPanPinchRef>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [scale, setScale] = useState(100);

  const { authorizationTypeID } = useUserInfo();
  const canUpsertAutomation = !!authorizationTypeID?.canUpsertAutomation;
  const canDeleteAutomation = !!authorizationTypeID?.canDeleteAutomation;

  const { data: messageListsData, isError: isMessageError } = useQuery({
    queryKey: [QUERY_KEY.apiAutomationsElAlgorithmsMessages, { algorithmId, hospitalID }] as const,
    queryFn: ({ queryKey }) => fetchAutomationsElAlgorithmsMessages(queryKey[1]),
    retry: 1,
  });

  const { data: algorithmData, isError: isAlgorithmError } = useQuery({
    queryKey: [QUERY_KEY.singleAlgorithmHandler, { algorithmId, hospitalID }] as const,
    queryFn: ({ queryKey }) => fetchSingleAlgorithmInfo(queryKey[1]),
    retry: 1,
  });

  const isMessageEditButtonDisabled = useMemo(() => {
    return (
      // 타입이 마케팅이고
      type === 'MARKETING' &&
      // 적용된 태그가 없고
      !algorithmData?.appliedTreatmentTagIds?.length &&
      // 치료태그 미입력
      !algorithmData?.isForNoTreatmentTags
    );
  }, [type, algorithmData]);

  const { handleDeleteAlgorithmWithModal } = useDeleteAlgorithm({
    type,
    onSuccessCb:
      type === 'CONSULTATION'
        ? () => overlayPageService.popById(OVERLAY_PAGE_ID['counsel-automation-zoom-in-out-viewer'])
        : () =>
            overlayPageService.popById(OVERLAY_PAGE_ID['marketing-automation-zoom-in-out-viewer']),
  });

  const onClickEditButton = () => {
    if (type === 'CONSULTATION') {
      openAutomationDialog(
        <RegisterCounselAutomationDialogContainer />,
        OVERLAY_PAGE_ID['register-counsel-automation-dialog'],
      );

      setCounselMessageMode({
        algorithmId,
        mode: 'EDIT',
      });
      setSelectedCounselAutomationTab(COUNSEL_TAB_ITEMS[2].value);
      return;
    }

    if (type === 'MARKETING') {
      openAutomationDialog(
        <RegisterMarketingAutomationDialogContainer />,
        OVERLAY_PAGE_ID['register-marketing-automation-dialog'],
      );

      setMarketingMessageMode({
        algorithmId,
        mode: 'EDIT',
      });

      setSelectedMarketingAutomationTab(MARKETING_TAB_ITEMS[2].value);
    }
  };

  const clearSelection = () => {
    if (!window.getSelection) return;
    const selection = window.getSelection();
    if (!selection) return;
    if (selection.empty) {
      selection.empty();
    } else if (selection.removeAllRanges) {
      selection.removeAllRanges();
    }
  };

  const handleBackgroundClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      clearSelection();
    }
  };

  const handleChangeScale = (newScale: number) => {
    setScale(newScale);
  };

  const handleOnZoom = (ref: ReactZoomPanPinchRef) => {
    const currentScale = ref.state.scale;
    let calculatedScale = Math.round(currentScale * 100);
    if (calculatedScale < 5) calculatedScale = 5;
    setScale(calculatedScale);
  };

  useEffect(() => {
    if (isMessageError || isAlgorithmError) {
      toastService.errorMsg({
        text: '해당 자동화가 존재하지 않습니다.',
      });

      overlayPageService.popById(
        OVERLAY_PAGE_ID[
          type === 'MARKETING'
            ? 'marketing-automation-zoom-in-out-viewer'
            : 'counsel-automation-zoom-in-out-viewer'
        ],
      );

      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.apiAutomationsElMainList, { hospitalID }],
      });
    }
  }, [isMessageError, isAlgorithmError, type, hospitalID]);

  if (!algorithmData || !messageListsData) {
    return <FullLoading />;
  }

  return (
    <div className='relative h-[calc(100vh-50px)] w-full'>
      <MessageTypeIndicators type={type} />
      <AlgorithmInfoBox type={type} {...algorithmData} />
      <TransformWrapper
        initialScale={1}
        minScale={0.05}
        maxScale={1}
        ref={transformRef}
        limitToBounds={true}
        onZoom={handleOnZoom}
        panning={{ disabled: scale === 0 }}
        centerZoomedOut={true}
        alignmentAnimation={{ sizeX: 0, sizeY: 0 }}>
        {() => (
          <>
            <div className='mb-4 flex justify-center' />
            <TransformComponent
              wrapperStyle={{
                width: '100%',
                height: '100%',
              }}>
              <div
                ref={containerRef}
                onClick={handleBackgroundClick}
                className='flex min-h-[calc(100vh-400px)] w-full items-start py-[200px] pr-[300px] pl-[100px]'>
                {messageListsData.map((item, index) => {
                  return (
                    <MessageCardItem
                      key={item.id}
                      algorithmName={algorithmData.name}
                      type={type}
                      isLast={index === messageListsData.length - 1}
                      {...item}
                    />
                  );
                })}
              </div>
            </TransformComponent>
          </>
        )}
      </TransformWrapper>
      <ZoomInOutController
        scale={scale}
        onChangeScale={handleChangeScale}
        transformRef={transformRef}
        containerRef={containerRef}
      />
      <DownloadFooter
        customRightButton={
          <div
            onClick={(e) => e.stopPropagation()}
            className={customTwMerge(
              'flex w-full',
              canDeleteAutomation && canUpsertAutomation ? 'justify-between' : 'justify-end',
            )}>
            {!canDeleteAutomation && !canUpsertAutomation && <div className='h-22 w-full' />}

            {canDeleteAutomation && (
              <OutlinedButton
                btnColor='secondary'
                onClick={() => {
                  if (!algorithmId) return;
                  handleDeleteAlgorithmWithModal(algorithmId);
                }}>
                삭제
              </OutlinedButton>
            )}
            {canUpsertAutomation && (
              <HoverTooltip
                position='topLeft'
                show={isMessageEditButtonDisabled}
                text={
                  '적용된 태그가 삭제되어 사용할 수 없는 자동화입니다.\n새로운 마케팅 자동화를 등록해 주세요.'
                }>
                <ContainedButton disabled={isMessageEditButtonDisabled} onClick={onClickEditButton}>
                  메시지 수정
                </ContainedButton>
              </HoverTooltip>
            )}
          </div>
        }
        className='sticky right-0 bottom-0'
      />
    </div>
  );
}

interface MessageTypeIndicatorsProps {
  type: Exclude<AlgorithmWithMessagesInput['type'], 'COMMON'>;
}

const MessageTypeIndicators = ({ type }: MessageTypeIndicatorsProps) => {
  const { hasKakaoAlimAndFriendTalkLinkage } = useGetIsKakaoAlimAndFriendTalkLinkage();

  return (
    <div className='absolute top-20 right-40 flex gap-10'>
      {type === 'CONSULTATION' && (
        <>
          {hasKakaoAlimAndFriendTalkLinkage && (
            <MessageIndicator
              iconProps={{
                name: 'kakaotalk',
                color: 'black200',
                size: 17,
              }}
              label='알림톡 승인'
            />
          )}
          <MessageIndicator bgColor={Color.white900} label='기본' />
          <MessageIndicator bgColor={Color.blue500} label='필수' />
          <MessageIndicator bgColor={Color.red500} label='설문' />
          <MessageIndicator bgColor={Color.purple500} label='공통 재내원 안내' />
        </>
      )}
      {type === 'MARKETING' && hasKakaoAlimAndFriendTalkLinkage && (
        <MessageIndicator
          iconProps={{
            name: 'kakaotalk',
            color: 'black200',
            size: 17,
          }}
          label='친구톡 전송'
        />
      )}
    </div>
  );
};

interface MessageIndicatorProps<T extends IconType = IconType> {
  label: string;
  iconProps?: {
    name: IconType;
    color: IconColorType;
    size: SpecificIconSizes<T>;
  };
  bgColor?: (typeof Color)[keyof typeof Color];
}

const MessageIndicator = ({ iconProps, label, bgColor }: MessageIndicatorProps) => {
  return (
    <div className='flex items-center gap-6'>
      {iconProps ? (
        <Icon {...iconProps} />
      ) : (
        <div
          className={'h-6 w-6 rounded-full border border-white50'}
          style={{ backgroundColor: bgColor }}
        />
      )}
      <div className='text-Body13 text-black200'>{label}</div>
    </div>
  );
};
