import * as React from "react";
import type { SVGProps } from "react";
const SvgChatBubbleOutline = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path d="M40 4H8C5.8 4 4 5.8 4 8v36l8-8h28c2.2 0 4-1.8 4-4V8c0-2.2-1.8-4-4-4m0 28H10.4L8 34.4V8h32z" />
    <rect width={21} height={4} x={14} y={12} rx={2} />
    <rect width={16} height={4} x={14} y={19} rx={2} />
  </svg>
);
export default SvgChatBubbleOutline;
