import * as React from "react";
import type { SVGProps } from "react";
const SvgExchange = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path stroke="#D1D1D1" d="M6 8h8M6 12h8" />
  </svg>
);
export default SvgExchange;
