import Rectangle from '@afterdoc-design-system/assets/icons/Rectangle';
import IconButton from '@afterdoc-design-system/components/Atoms/Button/IconButton';
import OutlinedButton from '@afterdoc-design-system/components/Atoms/Button/OutlinedButton';
import Scrollbar from '@afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import { toastService } from '@afterdoc-design-system/components/Atoms/Toast/Toast.service';
import HoverTooltip from '@afterdoc-design-system/components/Atoms/Tooltip/HoverTooltip';
import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';
import { modalService } from '@afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import dayjs from 'dayjs';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { memo, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import type { MessageInput } from 'web/apis/swaggers/swagger-docs';
import type { CounselAutomationAPIFormValues } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/components/RegisterCounselAutomationDialogContent';
import { MESSAGE_OPTIONS } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/constants/counsel-message-options';
import {
  selectedContentTabItemState,
  selectedMessageIndexState,
  selectedMessageState,
} from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/message-form';
import { showMessageTypeDialogState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/message-type-dialog';
import { messageWarningStatesState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/message-warning-states';
import { useAlgorithmData } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/hooks/use-algorithm-data';

export default function AutomatedMessageLists() {
  useAlgorithmData();

  const { setValue, watch, control } = useFormContext<CounselAutomationAPIFormValues>();

  const [warningStates, setWarningStates] = useAtom(messageWarningStatesState);
  const setSelectedMessage = useSetAtom(selectedMessageState);
  const setShowMessageTypeDialog = useSetAtom(showMessageTypeDialogState);
  const selectedMessageIndex = useAtomValue(selectedMessageIndexState);
  const selectedContentTabItem = useAtomValue(selectedContentTabItemState);

  const daysAfter = watch(`messages.${selectedMessageIndex}.daysAfter`);
  const timeOfDay = watch(`messages.${selectedMessageIndex}.timeOfDay`);
  const message = watch(`messages.${selectedMessageIndex}`);
  const messageLists = watch('messages');
  const messageTitle = watch(`messages.${selectedMessageIndex}.name`);
  const messageText = watch(`messages.${selectedMessageIndex}.content.text`);
  const messageImage = watch(`messages.${selectedMessageIndex}.content.image`);
  const messageVideo = watch(`messages.${selectedMessageIndex}.content.video`);
  const messageWorkoutVideo = watch(`messages.${selectedMessageIndex}.content.workoutVideo`);
  const messageEvents = watch(`messages.${selectedMessageIndex}.content.event`);
  const messageHomecareContent = watch(`messages.${selectedMessageIndex}.content.homecareContent`);
  const messageSurvey = watch(`messages.${selectedMessageIndex}.content.survey`);
  const messageSendingType = watch(`messages.${selectedMessageIndex}.sendingType`);
  const messageSurveyQuestion = watch(`messages.${selectedMessageIndex}.content.survey.question`);

  const messageAnswers = useWatch({
    name: `messages.${selectedMessageIndex}.content.survey.answers`,
    control: control,
  });

  const handleOpenMessageTypeDialogWithCreateType = () => {
    setShowMessageTypeDialog({
      type: 'create',
      isShow: true,
    });
  };

  useEffect(() => {
    if (messageLists.length === 0 || !message) return;

    const sortedMessages = [...messageLists].sort((a, b) => {
      const daysAfterA = a.daysAfter ?? 0;
      const daysAfterB = b.daysAfter ?? 0;
      const timeOfDayA = a.timeOfDay ? dayjs(a.timeOfDay, 'HH:mm').valueOf() : dayjs().valueOf();
      const timeOfDayB = b.timeOfDay ? dayjs(b.timeOfDay, 'HH:mm').valueOf() : dayjs().valueOf();

      if (daysAfterA !== daysAfterB) return daysAfterA - daysAfterB;
      return timeOfDayA - timeOfDayB;
    });

    if (
      JSON.stringify(sortedMessages.map((m) => m.id)) !==
      JSON.stringify(messageLists.map((m) => m.id))
    ) {
      setValue('messages', sortedMessages);

      const newIndex = sortedMessages.findIndex((m) => m.id === message.id);

      if (newIndex !== -1) {
        setSelectedMessage({
          ...sortedMessages[newIndex],
          index: newIndex,
        });
      }
    }
  }, [daysAfter, timeOfDay]);

  useEffect(() => {
    const newWarningStates = messageLists.reduce(
      (acc, currentMessage, index) => {
        const currentTabIndex = selectedContentTabItem?.[currentMessage.id];
        const currentTitle = currentMessage.name?.trim();
        const currentContent = currentMessage.content;
        const currentSendingType = currentMessage.sendingType;

        if (currentSendingType === 'SURVEY') {
          const hasValidAnswers = currentContent.survey?.answers?.some(
            (answer) => answer && answer.trim() !== '',
          );

          acc[index] =
            !currentTitle || !currentContent.survey?.question?.trim() || !hasValidAnswers;
          return acc;
        }

        const hasRequiredContent = (() => {
          const hasText = currentContent.text?.trim();

          switch (currentTabIndex) {
            case '텍스트':
              return !!hasText;
            case '이미지':
              return !!hasText && !!currentContent.image?.length;
            case '동영상':
              return !!hasText && !!currentContent.video;
            case '홈케어콘텐츠':
              return !!hasText && !!currentContent.workoutVideo;
            case '이벤트':
              return !!hasText && !!(currentContent.event || currentContent.homecareContent);
            default:
              return !!hasText;
          }
        })();

        acc[index] = !currentTitle || !hasRequiredContent;
        return acc;
      },
      {} as { [index: number]: boolean },
    );

    setWarningStates(newWarningStates);
  }, [
    messageLists,
    messageTitle,
    messageText,
    messageImage,
    messageVideo,
    messageWorkoutVideo,
    messageEvents,
    messageHomecareContent,
    messageSurvey,
    messageSendingType,
    selectedContentTabItem,
    messageSurveyQuestion,
    messageAnswers,
  ]);

  return (
    <div className='flex h-screen max-h-[722px] min-w-[200px] max-w-[370px] flex-grow flex-col justify-between rounded-r16 bg-white50 py-20 shadow-modal'>
      <div>
        <div className='flex flex-col gap-4 px-20 pt-20'>
          <span className='text-Header16 text-black700'>메시지 목록</span>
          <p className='text-Body11 text-black200'>
            치료태그를 입력한 일자를 기준으로 발송할 메시지를 설정합니다.
          </p>
        </div>
        <div className='mt-10'>
          <div className='h-[500px]'>
            <Scrollbar>
              {messageLists?.map((item) => (
                <MessageItem
                  key={item.id}
                  {...item}
                  index={messageLists.findIndex((m) => m.id === item.id)}
                  hasWarning={warningStates[messageLists.findIndex((m) => m.id === item.id)]}
                />
              ))}
            </Scrollbar>
          </div>
          <div className='p-15'>
            <OutlinedButton
              btnColor='secondary'
              className='w-full'
              onClick={handleOpenMessageTypeDialogWithCreateType}>
              <div className='flex items-center justify-center gap-4'>
                <Icon name='add-circle' color='white800' size={20} />
                <div className='text-Body13 text-white800'>메시지 추가</div>
              </div>
            </OutlinedButton>
          </div>
        </div>
      </div>

      <div className='flex justify-center gap-20 px-20 py-10'>
        {MESSAGE_OPTIONS.map((option) => (
          <div className='flex items-center gap-6' key={option.sendingType}>
            <div
              className={customTwMerge(
                'h-6 w-6 rounded-full border',
                option.circleBgClassName,
                option.circleBorderClassName,
              )}
            />
            <div className='text-Body13 text-black200'>{option.label}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

interface MessageItemProps extends MessageInput {
  id: string;
  hasWarning: boolean;
  index: number;
}

const MessageItem = ({ ...props }: MessageItemProps) => {
  const { index, hasWarning, daysAfter, name, sendingType } = props;

  const { getValues, setValue } = useFormContext<CounselAutomationAPIFormValues>();

  const selectedMessageIndex = useAtomValue(selectedMessageIndexState);
  const setSelectedMessage = useSetAtom(selectedMessageState);

  const handleClickMessage = async () => {
    if (selectedMessageIndex === index) {
      return;
    }

    await new Promise((resolve) => setTimeout(resolve, 0));

    const currentMessages = getValues('messages');
    const targetMessage = currentMessages[index];

    setSelectedMessage({
      ...targetMessage,
      id: targetMessage.id,
      index,
    });
  };

  const handleDeleteMessage = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    modalService.defaultWarning({
      id: 'delete-message',
      title: '메시지를 삭제하시겠어요?',
      contents: '선택한 메시지를 삭제합니다.',
      onConfirm: () => {
        const messages = getValues('messages');

        // 삭제 후 선택될 메시지 인덱스 결정
        const determineNextSelectedIndex = () => {
          // Case 1: 선택된 메시지를 삭제하는 경우
          if (index === selectedMessageIndex) {
            // Case 1.1: 이전 메시지가 있는 경우
            if (index > 0) {
              return index - 1;
            }
            // Case 1.2: 다음 메시지가 있는 경우
            if (index < messages.length - 1) {
              return index;
            }
            // Case 1.3: 마지막 메시지를 삭제하는 경우
            return -1;
          }

          // Case 2: 선택되지 않은 메시지를 삭제하는 경우
          // Case 2.1: 삭제할 메시지가 현재 선택된 메시지보다 앞에 있는 경우
          if (index < selectedMessageIndex) {
            return selectedMessageIndex - 1;
          }
          // Case 2.2: 삭제할 메시지가 현재 선택된 메시지보다 뒤에 있는 경우
          return selectedMessageIndex;
        };

        const nextSelectedIndex = determineNextSelectedIndex();

        // 메시지 삭제
        const updatedMessages = messages.filter((_, i) => i !== index);
        setValue('messages', updatedMessages);

        // selectedMessage 업데이트
        if (nextSelectedIndex === -1) {
          setSelectedMessage(undefined);
        } else {
          setSelectedMessage({
            ...updatedMessages[nextSelectedIndex],
            index: nextSelectedIndex,
            id: updatedMessages[nextSelectedIndex].id,
          });
        }

        modalService.popById('delete-message');
        toastService.successMsg({
          text: '메시지가 삭제되었습니다.',
        });
      },
    });
  };

  return (
    <div className='relative'>
      <div
        onClick={handleClickMessage}
        className={customTwMerge(
          'group relative flex cursor-pointer items-center border-b border-b-white600 px-20 py-15',
          MESSAGE_OPTIONS.find((option) => option.sendingType === sendingType)?.bgClassName,
        )}>
        {selectedMessageIndex === index && (
          <span className='absolute left-0 h-full w-[5px] bg-blue500' />
        )}
        <span className='mr-4 w-[50px] whitespace-nowrap font-bold text-Header12 text-black700'>
          {daysAfter}일차
        </span>
        <span className='truncate text-Body12 text-black700'>{name}</span>
        <div className='ml-auto flex items-center gap-10'>
          <IconButton
            className='opacity-0 transition-opacity group-hover:opacity-100'
            icon='trash-can-outline'
            color='black200'
            size={16}
            onClick={handleDeleteMessage}
          />
          {hasWarning && <WarningIcon />}
        </div>
      </div>
      <div className='-bottom-[6.3px] absolute left-[27px] z-10'>
        <Rectangle
          width={14}
          height={14}
          fill={MESSAGE_OPTIONS.find((option) => option.sendingType === sendingType)?.bgColor}
        />
      </div>
    </div>
  );
};

const WarningIcon = memo(() => (
  <HoverTooltip
    text='필수정보가 기입되지 않았습니다.'
    position='bottomLeft'
    offset={12}
    portalZindex={1000}>
    <Icon name='info-circle-outline' color='red500' size={16} />
  </HoverTooltip>
));
