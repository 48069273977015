import Divider from '@afterdoc-design-system/components/Atoms/Divider/Divider';
import LabelText from '@afterdoc-design-system/components/Atoms/LabelText/LabelText';
import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import type { ReservationDetail } from 'web/apis/swaggers/swagger-docs';

export const CustomerReservationCell = ({ reservation }: { reservation: ReservationDetail }) => {
  const formattedDate = useMemo(() => {
    const date = dayjs(reservation.startedAt);
    if (date.isValid()) {
      return date.format('YYYY년 MM월 DD일(ddd) HH:mm');
    }
    return '-';
  }, [reservation.startedAt]);

  const reservationDurationAndNote = `${reservation.duration}분 ${reservation.note ? ',' : ''} ${reservation.note ?? ''}`;

  const handleClick = () => {
    window?.electron?.ipcRenderer.send('Application.showReservationWindow', { value: reservation });
  };

  return (
    <div
      onClick={() => handleClick()}
      className='flex cursor-pointer flex-col gap-10 rounded-r16 border border-white500 px-16 pt-16 pb-10'>
      <div className='flex h-20 items-center justify-between gap-10'>
        <div className='flex flex-shrink-0 items-center gap-10'>
          <Icon
            name={reservation.isCanceled ? 'calendar-remove-outline' : 'calendar-check-outline'}
            size={20}
            color={reservation.isCanceled ? 'red500' : 'blue500'}
          />
          <LabelText textClassName='text-Header14 text-black700'>{formattedDate}</LabelText>
        </div>
        <div className='flex h-full flex-grow items-center justify-end'>
          <span
            className='line-clamp-1 text-Body10Bold text-black500'
            title={reservation.programTitle}>
            {reservation.programTitle}
          </span>
        </div>
      </div>
      <div className='flex h-18 w-full items-center justify-between gap-10'>
        <div className='flex flex-grow items-end overflow-hidden'>
          <span className='w-full overflow-hidden text-ellipsis whitespace-nowrap text-Body12'>
            {reservationDurationAndNote}
          </span>
        </div>
        <div className='flex flex-shrink-0 items-center gap-10'>
          <span className='max-w-[60px] truncate text-Body10Bold'>{reservation.groupTitle}</span>
          <Divider type={'line'} direction={'vertical'} height={18} />
          <span className='max-w-[60px] truncate text-Body10Bold'>{reservation.columnTitle}</span>
        </div>
      </div>
    </div>
  );
};
