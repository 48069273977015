import HoverTooltip from '@afterdoc-design-system/components/Atoms/Tooltip/HoverTooltip';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import type { ReplaceableText } from 'web/templates/Automation/containers/shared/functions/calculate-text';

interface ReplaceableTextListsProps {
  handleInsertReplaceText: (text: string, length: number) => void;
  replaceableTexts: ReplaceableText[];
  className?: string;
}

export default function ReplaceableTextLists({
  handleInsertReplaceText,
  replaceableTexts,
  className,
}: ReplaceableTextListsProps) {
  return (
    <div className={customTwMerge('mt-20 flex items-center gap-10', className)}>
      <div className='text-Header12 text-black500'>치환 문자</div>
      {replaceableTexts.map(({ text, description, length }) => (
        <HoverTooltip text={description} position='bottomRight' key={text} portalZindex={1000}>
          <button
            className='rounded-r6 bg-white200 px-6 py-3 font-regular text-Body12 text-black500'
            type='button'
            onClick={() => {
              handleInsertReplaceText(text, length);
            }}>
            {text}
          </button>
        </HoverTooltip>
      ))}
    </div>
  );
}
