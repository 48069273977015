import type {
  ALDepartmentDTO,
  ALReservationDetail,
  ALUserIdAndNameDTO,
} from '@apis/Almighty/types';

export const populateALReservationInfo = (
  alReservations: ALReservationDetail[],
  departments: ALDepartmentDTO[],
  consultants: ALUserIdAndNameDTO[],
) => {
  const departmentMap = new Map(
    departments.map((department) => [
      department.code,
      {
        name: department.name,
        users: new Map(department.userDTOs.map((user) => [user.id, user.name])),
      },
    ]),
  );

  const consultantMap = new Map(consultants.map((consultant) => [consultant.id, consultant.name]));

  // 매번 department, consultants 순회보다는 처음에 map 으로 전체 순회한번하고 이후로 다시 순회할일 없게 사용
  for (const reservation of alReservations) {
    const { departmentCode, chargeDoctorId, consultantId } = reservation;

    // 부서 데이터 검색
    const department = departmentMap.get(departmentCode);

    if (department) {
      reservation.departmentName = department.name;

      // 담당 의사 이름 설정
      const doctorName = department.users.get(chargeDoctorId);
      if (doctorName) {
        reservation.chargeDoctorName = doctorName;
      }
    }

    // 상담사 이름 설정
    const consultantName = consultantMap.get(consultantId);
    if (consultantName) {
      reservation.consultantName = consultantName;
    }
  }

  return alReservations.sort((l, r) => {
    const dateComparison = r.reservationDate.localeCompare(l.reservationDate);
    if (dateComparison !== 0) {
      return dateComparison;
    }
    return r.reservationStartTime.localeCompare(l.reservationStartTime);
  });
};
