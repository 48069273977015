import { SHARED_UTILS } from '@shared-utils/utils';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';
import {
  type ReactNode,
  type RefObject,
  type TextareaHTMLAttributes,
  forwardRef,
  useEffect,
  useState,
} from 'react';
import './ChatTextArea.scss';

const TEXTAREA_STYLE = {
  baseStyle: 'outline-none bg-white50 pt-10',
  disabledStyle:
    'disabled:bg-white200 disabled:cursor-not-allowed disabled:placeholder:text-black200 disabled:placeholder:text-Body12',
  focusStyle: 'focus:border-blue500',
};

export interface TextAreaProps
  extends Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'className' | 'width'> {
  hasError?: boolean;
  width?: number | string;
  height?: number | string;
  maxHeight?: number;
  errorText?: ReactNode;
  inputWrapperClassName?: string;
  inputClassName?: string;
  wrapperRef?: RefObject<HTMLDivElement>;
  isShowBottom?: boolean;
  isSaveButtonDisabled?: boolean;
  value?: string;
  onSave?: () => void;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  maxLength?: number;
  onKeyDown?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  handleInput?: (event: React.FormEvent<HTMLTextAreaElement>) => void;
  additionalRightBottomButton?: ReactNode;
  showRightBottomButton?: boolean;
  leftBottomText?: ReactNode;
  leftBottomTextClassName?: string;
  rightBottomButtonText?: ReactNode;
  className?: string;
  noBorder?: boolean;
  textAreaRef: RefObject<HTMLTextAreaElement>;
}

const ChatTextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      hasError = false,
      width,
      height,
      maxHeight,
      disabled = false,
      placeholder,
      errorText,
      inputWrapperClassName,
      inputClassName,
      wrapperRef,
      isShowBottom = true,
      isSaveButtonDisabled = true,
      value,
      onChange,
      onSave,
      additionalRightBottomButton,
      leftBottomText = '- / -',
      leftBottomTextClassName,
      showRightBottomButton = true,
      rightBottomButtonText = '저장',
      maxLength = 40000,
      onKeyDown,
      handleInput,
      className,
      noBorder = false,
      textAreaRef,
      ...props
    },
    _inputRef,
  ) => {
    const { baseStyle, disabledStyle } = TEXTAREA_STYLE;
    const [textLength, setTextLength] = useState<number>(value?.length ?? 0);
    const [isOverflowing, setIsOverflowing] = useState<boolean>(false);

    useEffect(() => {
      const adjustHeight = () => {
        if (textAreaRef.current) {
          if (maxHeight) {
            textAreaRef.current.style.height = 'auto';
            if (textAreaRef.current.scrollHeight > Number(maxHeight)) {
              textAreaRef.current.style.height = SHARED_UTILS.css.getCssSizeValue(maxHeight);
            } else {
              textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
            }
          }

          const isOverflow = textAreaRef.current.scrollHeight > textAreaRef.current.clientHeight;
          setIsOverflowing(isOverflow);
        }
      };

      // 이벤트루프의 큐를 통해 스크롤 너비를 고려한 마진 적용
      setTimeout(adjustHeight, 0);

      window.addEventListener('resize', adjustHeight);
      return () => window.removeEventListener('resize', adjustHeight);
    }, [value]);

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const newText = event.target.value;
      if (newText.length <= maxLength) {
        setTextLength(newText.length);
        onChange?.(event);
      }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (textLength >= maxLength && event.key !== 'Backspace' && event.key !== 'Delete') {
        event.preventDefault();
      }
      onKeyDown?.(event);
    };

    return (
      <div
        className={customTwMerge(
          'flex h-full w-full flex-col rounded-r10',
          !noBorder && 'border border-white600',
          className,
        )}
        style={{
          width: SHARED_UTILS.css.getCssSizeValue(width),
          height: SHARED_UTILS.css.getCssSizeValue(height),
        }}>
        <div
          ref={wrapperRef}
          className={customTwMerge(
            'textarea-wrapper relative h-full w-full flex-1 select-none',
            baseStyle,
            isOverflowing ? 'pr-5 pl-16' : 'px-16',
            disabled && 'bg-white200',
            isShowBottom ? 'rounded-t-r10' : 'rounded-r10',
            inputWrapperClassName,
          )}>
          <textarea
            ref={textAreaRef}
            placeholder={placeholder}
            disabled={disabled}
            className={customTwMerge(
              'h-full w-full resize-none pt-6 text-Body12 text-black700 placeholder-black200 outline-none placeholder:text-Body12 placeholder:text-black200',
              isOverflowing && 'overflowing',
              disabledStyle,
              inputClassName,
            )}
            onChange={handleChange}
            value={value}
            onKeyDown={handleKeyDown}
            onInput={handleInput}
            {...props}
          />
          {isOverflowing && (
            <div className='textarea-scrollbar'>
              <div className='textarea-scrollbar-thumb' />
            </div>
          )}
        </div>
        {isShowBottom && (
          <div
            className={customTwMerge(
              'flex items-center justify-between rounded-b-r10 bg-white50 px-16 py-8',
              disabled && 'bg-white200',
            )}>
            <div className={customTwMerge(leftBottomTextClassName, 'text-Body10 text-black200')}>
              {leftBottomText}
            </div>
            <div className={customTwMerge(additionalRightBottomButton && 'flex flex-row gap-10')}>
              {additionalRightBottomButton && additionalRightBottomButton}
              {showRightBottomButton && (
                <div>
                  {typeof rightBottomButtonText === 'string' ? (
                    <ContainedButton
                      buttonSize='small'
                      onClick={onSave}
                      disabled={isSaveButtonDisabled}>
                      {rightBottomButtonText}
                    </ContainedButton>
                  ) : (
                    rightBottomButtonText
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  },
);

export default ChatTextArea;
