import Checkbox from '@afterdoc-design-system/components/Atoms/Checkbox/Checkbox';
import FilledTag from '@afterdoc-design-system/components/Atoms/Tag/FilledTag';
import { forwardRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { filterWrapperClassName } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementEditableTableHeader/constants/class';
import type { EditableHeaderFilterState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/states/editable-header-filter';
import type { ReadOnlyHeaderFilterState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementReadOnlyTable/states/read-only-header-filter';
import type { UnTaggedHeaderFilterState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/UnTaggedCustomerManagement/containers/UnTaggedCustomerManagementEditableTable/states/editable-header-filter';

type CheckState = {
  초진: boolean;
  재진: boolean;
};

const IsFirstVisitFilter = forwardRef<HTMLDivElement>((_, ref) => {
  const { setValue, watch } = useFormContext<
    EditableHeaderFilterState | ReadOnlyHeaderFilterState | UnTaggedHeaderFilterState
  >();
  const isFirstVisit = watch('isFirstVisit');

  const [checkState, setCheckState] = useState<CheckState>({
    초진: isFirstVisit === true || isFirstVisit === 'all',
    재진: isFirstVisit === false || isFirstVisit === 'all',
  });

  const handleCheckboxChange = (type: '초진' | '재진') => {
    const newState = { ...checkState, [type]: !checkState[type] };
    setCheckState(newState);

    if (newState.초진 && !newState.재진) {
      setValue('isFirstVisit', true);
    } else if (!newState.초진 && newState.재진) {
      setValue('isFirstVisit', false);
    } else if (newState.초진 && newState.재진) {
      setValue('isFirstVisit', 'all');
    } else {
      setValue('isFirstVisit', undefined);
    }
  };

  return (
    <div className={`${filterWrapperClassName} w-[188px]`} ref={ref}>
      <div className='p-16'>
        <div className='mb-10 text-Caption9 text-black200'>
          *체크한 값에 해당하는 고객만 표시됩니다.
        </div>
        <Checkbox
          label={
            <FilledTag className='cursor-pointer' tagSize='small'>
              초진
            </FilledTag>
          }
          checked={checkState.초진}
          onChange={() => handleCheckboxChange('초진')}
        />
        <Checkbox
          label={
            <FilledTag className='cursor-pointer' tagSize='small'>
              재진
            </FilledTag>
          }
          checked={checkState.재진}
          onChange={() => handleCheckboxChange('재진')}
        />
      </div>
    </div>
  );
});

export default IsFirstVisitFilter;
