import type { KakaoAlimFriendTalkLinkageHandlerBodyRequest } from '@apis/swaggers/swagger-docs';
import { authenticationNumberErrorAtom } from '@templates/HospitalSetting/containers/ChannelIntegration/states/kakao-consult-form-error-state';
import TextInput from 'afterdoc-design-system/components/Atoms/Input/TextInput';
import LabelText from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import HoverTooltip from 'afterdoc-design-system/components/Atoms/Tooltip/HoverTooltip';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import { useAtom } from 'jotai/index';
import type React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

export default function AuthNumInput() {
  const { register, setValue } = useFormContext<KakaoAlimFriendTalkLinkageHandlerBodyRequest>();

  const [authenticationNumberError, setAuthenticationNumberError] = useAtom(
    authenticationNumberErrorAtom,
  );

  const [hasError, setHasError] = useState(false);

  const isFirstRender = useRef(true);

  const inputValue = useWatch({ name: 'authenticationNumber' }) ?? '';

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAuthenticationNumberError(false);
    setValue('authenticationNumber', e.target.value.replace(/[^0-9]/g, ''));
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      setAuthenticationNumberError(false);
    } else {
      setHasError(authenticationNumberError);
    }
  }, [authenticationNumberError]);

  useEffect(() => {
    setValue('authenticationNumber', '');
  }, []);

  return (
    <>
      <div className='flex gap-10'>
        <div className='flex h-32 w-[140px] items-center gap-6'>
          <LabelText isRequired width={'w-fit'} className='flex-center'>
            인증번호
          </LabelText>
          <HoverTooltip
            position='topRight'
            text={'관리자 휴대폰번호로 전송된 인증번호를 입력해 주세요.'}>
            <Icon name={'info-circle-outline'} color={'black200'} size={16} />
          </HoverTooltip>
        </div>
        <TextInput
          className={'flex-grow'}
          maxLength={1000}
          errorTextPosition={'vertical'}
          errorText={'인증번호를 입력해 주세요.'}
          value={inputValue}
          hasError={hasError}
          placeholder='인증번호 입력'
          {...register('authenticationNumber', { onChange: handleChange })}
        />
      </div>
    </>
  );
}
