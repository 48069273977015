import FinalPriceText from '@templates/Content/containers/ContentManager/containers/EventHomeCare/shared/components/Manage/PriceSettingInput/components/FinalPriceText';
import PriceInput from '@templates/Content/containers/ContentManager/containers/EventHomeCare/shared/components/Manage/PriceSettingInput/components/PriceInput';
import SalePriceInput from '@templates/Content/containers/ContentManager/containers/EventHomeCare/shared/components/Manage/PriceSettingInput/components/SalePriceInput';
import LabelText from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import { memo } from 'react';

interface PriceSettingInputProps {
  price?: number;
  salePrice: number;
  saleRate: number;
  saleType: string;
  onHandlePriceValue: (value?: number) => void;
  onHandleSalePriceValue: (value: number) => void;
  onHandleSaleRateValue: (value: number) => void;
  onHandleSaleTypeValue: (value: string) => void;
}

const PriceSettingInput = memo(
  ({
    price,
    salePrice,
    saleRate,
    saleType,
    onHandlePriceValue,
    onHandleSalePriceValue,
    onHandleSaleRateValue,
    onHandleSaleTypeValue,
  }: PriceSettingInputProps) => {
    return (
      <div className='flex items-center gap-28'>
        <LabelText width={72} isRequired={true} className='flex h-32 items-center'>
          가격
        </LabelText>
        <div className='flex flex-grow items-center gap-10'>
          <PriceInput price={price} onHandlePriceValue={onHandlePriceValue} />
          <SalePriceInput
            price={price ?? 0}
            salePrice={salePrice}
            saleRate={saleRate}
            saleType={saleType}
            onHandleSalePriceValue={onHandleSalePriceValue}
            onHandleSaleRateValue={onHandleSaleRateValue}
            onHandleSaleTypeValue={onHandleSaleTypeValue}
          />
          <FinalPriceText
            price={price ?? 0}
            salePrice={salePrice}
            saleRate={saleRate}
            saleType={saleType}
          />
        </div>
      </div>
    );
  },
);

export default PriceSettingInput;
