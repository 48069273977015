import * as React from "react";
import type { SVGProps } from "react";
const SvgPinned = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <rect width={20} height={20} fill="#E6F2FF" rx={10} />
    <path
      fill="#007AFF"
      d="M12.222 10V5.555h.555v-1.11H7.222v1.11h.555V10L6.666 11.11v1.111h2.889v3.333h.889v-3.333h2.889v-1.111z"
    />
  </svg>
);
export default SvgPinned;
