import type {
  AuthorizationSection,
  AuthorizationTypeDetail,
  AuthorizationTypeForV3,
} from '@apis/swaggers/swagger-docs';
import {
  hospitalAuthorizationTypesState,
  selectedAuthorizationTypeState,
} from '@templates/HospitalSetting/containers/AuthorizationSetting/jotai/hospital-authorization-types';
import Checkbox from 'afterdoc-design-system/components/Atoms/Checkbox/Checkbox';
import HoverTooltip from 'afterdoc-design-system/components/Atoms/Tooltip/HoverTooltip';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import { produce } from 'immer';
import { useAtomValue, useSetAtom } from 'jotai/index';
import { useEffect, useState } from 'react';

interface AuthorizationItemProps {
  group: string;
  item: AuthorizationTypeDetail;
}

export default function AuthorizationItem({ group, item }: AuthorizationItemProps) {
  const selectedAuthorizationType = useAtomValue(selectedAuthorizationTypeState);
  const setHospitalAuthorizationTypes = useSetAtom(hospitalAuthorizationTypesState);
  const [checkboxDisabled, setCheckboxDisabled] = useState(false);

  useEffect(() => {
    if (selectedAuthorizationType) {
      setCheckboxDisabled(selectedAuthorizationType.name === '마스터');
    }
  }, [selectedAuthorizationType]);

  const handleChange = () => {
    setHospitalAuthorizationTypes((prev) =>
      produce(prev, (draft) => {
        const authType = draft.find((authType) => authType._id === selectedAuthorizationType._id);
        if (!authType) return;
        const section = authType[group as keyof AuthorizationTypeForV3] as AuthorizationSection;
        if (!section) return;
        const detail = section.types?.find(
          (detail) => detail.fieldNameForUpdate === item.fieldNameForUpdate,
        );
        if (detail) {
          detail.value = !item.value;
        }
      }),
    );
  };

  return (
    <div>
      <div className='flex items-center gap-4'>
        <Checkbox
          disabled={checkboxDisabled}
          checked={item.value}
          label={item.name}
          onChange={handleChange}
        />
        <HoverTooltip position='topRight' text={item.toolTip}>
          <Icon name={'info-circle-outline'} color={'black200'} size={16} />
        </HoverTooltip>
      </div>
    </div>
  );
}
