import TextInput from '@afterdoc-design-system/components/Atoms/Input/TextInput';
import { useAtomValue } from 'jotai';
import { useFormContext } from 'react-hook-form';
import type { MarketingAutomationAPIFormValues } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/components/RegisterMarketingAutomationDialogContent';
import { selectedMessageIndexState } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/states/message-form';

export default function MessageNameForm() {
  const { setValue, watch } = useFormContext<MarketingAutomationAPIFormValues>();

  const selectedMessageIndex = useAtomValue(selectedMessageIndexState);

  return (
    <TextInput
      onChange={(e) => {
        const limitedValue = e.target.value.slice(0, 50);
        setValue(`messages.${selectedMessageIndex}.name`, limitedValue);
      }}
      value={watch(`messages.${selectedMessageIndex}.name`) || ''}
      maxLength={50}
      label={{
        isRequired: true,
        children: '메시지명',
      }}
    />
  );
}
