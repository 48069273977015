import { Suspense } from 'react';
import CustomerInfoCustomerMemo from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/CustomerInfoInputBoard/components/CustomerInfoCustomerMemo';
import ManagementDirection from './ManagementDirection/ManagementDirection';
import ApplyingAutomation from './components/ApplyingAutomation';

export default function CustomerInfoInputBoardContainer() {
  return (
    <div className='grid h-full min-h-[300px] w-full grid-cols-3 items-center gap-20 rounded-r16 bg-white100 px-24 py-32'>
      <ApplyingAutomation />
      <CustomerInfoCustomerMemo />
      <Suspense>
        <ManagementDirection />
      </Suspense>
    </div>
  );
}
