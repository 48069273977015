import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import type { MouseEventHandler } from 'react';

interface CardItemLayoutProps {
  onClick?: MouseEventHandler<HTMLDivElement>;
  children?: React.ReactNode;
  className?: string;
}

export default function CardItemLayout({ children, onClick, className }: CardItemLayoutProps) {
  return (
    <div
      className={customTwMerge(
        'h-full w-full rounded-r10 border border-white400 bg-white50 px-20 py-16',
        className,
        onClick ? 'cursor-pointer' : '',
      )}
      onClick={onClick}>
      {children}
    </div>
  );
}
