import { useEffect, useState } from 'react';

export interface WindowSize {
  width: number;
  height: number;
}

/**
 * 현재 창의 크기를 반환하는 훅입니다.
 *
 * @returns {WindowSize} 현재 창의 너비와 높이를 포함하는 객체입니다.
 *
 * @example
 * const { width, height } = useWindowSize();
 * console.info(`Width: ${width}, Height: ${height}`);
 */
export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        if (
          windowSize.width === 0 ||
          windowSize.width !== window.innerWidth ||
          windowSize.height !== window.innerHeight
        ) {
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
          });
        }
      };

      window.addEventListener('resize', handleResize);

      handleResize();

      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  return windowSize;
};
