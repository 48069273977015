import { makeChattingInfoStringDisplay } from './functions/makeChattingInfoStringDisplay';

type MessageTimestampProps = {
  sentAt: string | undefined;
};

export default function MessageTimestamp({ sentAt }: MessageTimestampProps) {
  const displayCreated = makeChattingInfoStringDisplay({
    type: 'chattingSentAt',
    payload: { timestamp: sentAt },
  });
  return <span className='text-Caption9 text-black200'>{displayCreated}</span>;
}
