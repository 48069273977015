import PopupListContainer from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/components/PopupList/PopupListContainer';
import PopupManageDialogContainer from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/components/PopupManageDialog/PopupManageDialogContainer';
import { contentEditState } from '@templates/Content/containers/ContentManager/states/content-edit-state';
import { useAtomValue } from 'jotai/index';

export default function PopupContainer() {
  const contentEdit = useAtomValue(contentEditState);

  return (
    <>
      <PopupListContainer />
      {contentEdit.isEditing && <PopupManageDialogContainer />}
    </>
  );
}
