import { useInfiniteQuery } from '@tanstack/react-query';
import { atom, useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type {
  ChatRoomHServiceLastMessageUsers,
  HService,
  UserNameAndType,
} from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import { useUserInfo } from 'web/shared/hooks/use-user-info';
import { CHATTING_LIST_TABS } from 'web/templates/CustomerChat/constants/tab';
import { selectedChattingListTabState } from 'web/templates/CustomerChat/states/selected-chatting-list-tab';
import { getChatRoom } from '../api/get-chatroom';
import {
  DEFAULT_CHATTING_LIST_PARAMS,
  INTERVAL_NUM,
} from '../constants/chatting-list-default-params';

export interface InfoItem {
  userID: string;
  count: number;
  title: string;
}

interface TempHService extends HService {
  hasKakaoLinkage: boolean;
}

export interface TempChatRoomHServiceLastMessageUser extends ChatRoomHServiceLastMessageUsers {
  users: UserNameAndType[];
  userID: UserNameAndType;
  c_UnreadCount: number;
  info: InfoItem[];
  counselorID: string;
  parentHServiceID: TempHService;
  hasKakaoLinkage: boolean;
  parentTitle: string;
  parentChatRoomID: {
    title: string;
    hasKakaoLinkage: boolean;
  };
}

export const chattingListQueryParamsState = atom<{
  keyword?: string;
  sortOption?: string;
}>({});

export const useChattingList = () => {
  const selectedChattingListTab = useAtomValue(selectedChattingListTabState);
  const { hospitalID } = useSelectedHospitalInfo();
  const { userId: userID } = useUserInfo();
  const { keyword, sortOption } = useAtomValue(chattingListQueryParamsState);

  const isCounselInProgress =
    selectedChattingListTab === CHATTING_LIST_TABS.IN_PROGRESS ? true : undefined;

  const chattingListQuery = useMemo(
    () => ({
      ...DEFAULT_CHATTING_LIST_PARAMS,
      hospitalID,
      participant: userID,
      ...(keyword && { keyword }),
      isCounselInProgress,
      isSortByUnread: sortOption === '읽지 않은 순',
    }),
    [hospitalID, userID, keyword, sortOption, isCounselInProgress],
  );

  const { data, fetchNextPage, isFetchingNextPage, hasNextPage, isLoading } = useInfiniteQuery<
    TempChatRoomHServiceLastMessageUser[]
  >({
    queryKey: [QUERY_KEY.apiChatroomElFind, chattingListQuery] as const,
    queryFn: ({ queryKey, pageParam }) => {
      const { skip, limit } = pageParam as { skip: number; limit: number };
      return getChatRoom({
        ...(queryKey[1] as object),
        skip,
        limit,
      });
    },
    getNextPageParam: (lastPage, pages) => {
      if ((lastPage?.length ?? 0) < INTERVAL_NUM) return undefined;
      return { skip: pages.length * INTERVAL_NUM, limit: INTERVAL_NUM };
    },
    initialPageParam: { skip: 0, limit: INTERVAL_NUM },
    staleTime: 0,
    gcTime: 0,
  });

  const chatRooms = useMemo(() => data?.pages.flat() ?? [], [data?.pages]);

  return {
    chatRooms,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    isLoading,
  };
};
