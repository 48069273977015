import { focusedContentSubLayerState } from '@templates/Content/containers/ContentPanel/states/focused-content-sub-layer-state';
import Title from 'afterdoc-design-system/components/Molecules/Title/Title';
import { useAtomValue } from 'jotai/index';

export default function VideoContentTitle() {
  const focusedContentSubLayer = useAtomValue(focusedContentSubLayerState);

  const title = focusedContentSubLayer?.data?.categoryName ?? '';

  return (
    <div className='px-60'>
      <Title wrapperClassName={'pb-20'} title={title} />
    </div>
  );
}
