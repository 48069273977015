import { displayedPatientsFieldsState } from '@templates/HospitalSetting/containers/ServiceSetting/containers/CustomerInfo/jotai/displayed-patients-fields';
import Toggle from 'afterdoc-design-system/components/Atoms/Toggle/Toggle';
import { useSetAtom } from 'jotai/index';

interface CustomerInfoToggleProps {
  id: string;
  title: string;
  checked: boolean;
}

export default function CustomerInfoToggle({ id, title, checked }: CustomerInfoToggleProps) {
  const setPatientsFields = useSetAtom(displayedPatientsFieldsState);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPatientsFields((prev) => ({
      ...prev,
      [id]: e.target.checked,
    }));
  };

  return (
    <div className='flex-center rounded-r10 border border-white600 bg-white50 px-16 py-5'>
      <span className='flex-grow text-Body13 text-black500'>{title}</span>
      <Toggle toggleSize='big' checked={checked} onChange={handleChange} />
    </div>
  );
}
