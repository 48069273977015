import * as React from "react";
import type { SVGProps } from "react";
const SvgBold = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#7F7F7F"
      d="M11.25 12.916H8.334v-2.5h2.916a1.25 1.25 0 1 1 0 2.5m-2.916-7.5h2.5a1.25 1.25 0 0 1 0 2.5h-2.5M13 8.991c.809-.566 1.375-1.491 1.375-2.325 0-1.883-1.458-3.333-3.333-3.333H5.834V15H11.7c1.75 0 3.092-1.417 3.092-3.159 0-1.266-.717-2.35-1.792-2.85"
    />
  </svg>
);
export default SvgBold;
