import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import type { ApiTreatmentTagsElParams } from '@apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from '@shared/hooks/use-selected-hospital-info';
import { useQuery } from '@tanstack/react-query';
import TextFieldSelectBoxDropdown from 'afterdoc-design-system/components/Molecules/TextSelect/TextFieldSelectBoxDropdown';
import MultipleTextFieldSelectBoxTagDropdown from 'afterdoc-design-system/components/Molecules/TextSelect/TextFieldSelectBoxTagDropdown/MultipleTextFieldSelectBoxTagDropdown';
import type { TagProperty } from 'afterdoc-design-system/components/Molecules/TextSelect/types/tag-dropdown';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SHARED_UTILS } from 'utils/utils';

type NoticePushDropdownType = 'treatmentTags' | 'isFirstVisit';

const visitTypeTags: TagProperty[] = [
  { name: '초진', id: 'firstVisit', color: '#EBEBEB' },
  { name: '재진', id: 'followUpVisit', color: '#EBEBEB' },
];

const fetchTreatmentTags = async (params: ApiTreatmentTagsElParams) => {
  const { data } = await apiClient.v3.apiTreatmentTagsEl(params);
  return SHARED_UTILS.api.checkApiResponse(data);
};

interface PopupNoticeTargetDropdownProps {
  isDisabled?: boolean;
  errorMessage: string | null;
  defaultSelectedTagsIDs?: string[];
  defaultSelectedVisitTypes?: boolean[];
  onHandleSelectType: () => void;
  onHandleSelectTags: (tags: string[]) => void;
  onHandleSelectVisitType: (tags: boolean[] | undefined) => void;
}

const determineCategoryType = (
  defaultSelectedVisitTypes: boolean[] | undefined,
): NoticePushDropdownType => {
  if (defaultSelectedVisitTypes && defaultSelectedVisitTypes?.length > 0) {
    return 'isFirstVisit';
  }
  return 'treatmentTags';
};

function PopupNoticeTargetDropdown({
  isDisabled,
  errorMessage,
  defaultSelectedTagsIDs,
  defaultSelectedVisitTypes,
  onHandleSelectType,
  onHandleSelectTags,
  onHandleSelectVisitType,
}: PopupNoticeTargetDropdownProps) {
  const { hospitalID } = useSelectedHospitalInfo();

  const containerRef = useRef<HTMLDivElement>(null);

  const [categoryType, setCategoryType] = useState<NoticePushDropdownType>(
    determineCategoryType(defaultSelectedVisitTypes),
  );

  const { data } = useQuery({
    queryKey: [QUERY_KEY.apiTreatmentTagsEl, { hospitalID }] as const,
    queryFn: ({ queryKey }) => fetchTreatmentTags(queryKey[1]),
  });

  const handleSelect = (index: number) => {
    const type = index === 0 ? 'treatmentTags' : 'isFirstVisit';
    if (categoryType !== type) {
      onHandleSelectType();
      setCategoryType(type);
    }
  };

  const handleSelectedTreatmentTag = useCallback(
    (tags: TagProperty[]) => {
      const selectedTags = tags.map((tag) => tag.id);
      onHandleSelectTags(selectedTags);
    },
    [onHandleSelectTags],
  );

  const handleSelectedVisitType = useCallback(
    (tags: TagProperty[]) => {
      const type = tags.reduce(
        (acc, tag) => {
          if (tag.id === 'firstVisit') acc[0] = true;
          if (tag.id === 'followUpVisit') acc[1] = true;
          return acc;
        },
        [false, false],
      );

      onHandleSelectVisitType(type.some(Boolean) ? type : undefined);
    },
    [onHandleSelectVisitType],
  );

  const treatmentTags = useMemo(() => {
    if (!data) return [];

    return (
      data.treatmentTags?.map((tag) => ({
        name: tag.name,
        id: tag.tagId,
        color: tag.color,
      })) ?? []
    );
  }, [data]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.focus();
    }
  }, []);

  return (
    <div className='mt-10 flex gap-10' ref={containerRef}>
      <TextFieldSelectBoxDropdown
        selectedIndex={0}
        options={['치료태그', '초/재진']}
        disabled={isDisabled}
        onSelect={handleSelect}
        wrapperClassName={'ml-35 w-95'}
      />
      <div>
        {categoryType === 'treatmentTags' && (
          <MultipleTextFieldSelectBoxTagDropdown
            placeholder={'예) 도수치료, 리프팅'}
            tagOptions={treatmentTags}
            disabled={isDisabled}
            onSelectedTagsChange={handleSelectedTreatmentTag}
            defaultSelectedTags={treatmentTags.filter((tag) => {
              return defaultSelectedTagsIDs?.some((id) => id === tag.id);
            })}
            className='w-[285px]'
            fieldWrapperClassName='max-h-[180px]'
          />
        )}
        {categoryType === 'isFirstVisit' && (
          <MultipleTextFieldSelectBoxTagDropdown
            placeholder={'선택'}
            disabled={isDisabled}
            tagOptions={visitTypeTags}
            onSelectedTagsChange={handleSelectedVisitType}
            defaultSelectedTags={visitTypeTags.filter((visitType) => {
              const [isFirstVisit, isFollowUpVisit] = defaultSelectedVisitTypes || [];

              if (isFirstVisit && isFollowUpVisit) {
                return visitType.id === 'firstVisit' || visitType.id === 'followUpVisit';
              }

              if (isFirstVisit) {
                return visitType.id === 'firstVisit';
              }

              if (isFollowUpVisit) {
                return visitType.id === 'followUpVisit';
              }

              return false;
            })}
            className='w-[285px]'
            fieldWrapperClassName='max-h-[180px]'
          />
        )}
        {errorMessage && <p className='mt-4 text-Body10 text-red500'>{errorMessage}</p>}
      </div>
    </div>
  );
}

export default memo(PopupNoticeTargetDropdown);
