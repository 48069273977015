import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import type {
  ApiVideocontenthospitalElDetailParams,
  VideoContentCategory,
  VideoContentHospitalCategoryThumbnailPath,
} from '@apis/swaggers/swagger-docs';
import { useQuery } from '@tanstack/react-query';
import { selectedVideoContentState } from '@templates/Content/containers/ContentManager/containers/VideoContent/states/selected-video-content-state';
import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { SHARED_UTILS } from 'utils/utils';

const getCategoryName = (
  categories: VideoContentCategory[] | undefined,
  isTop: boolean,
  isParent: boolean,
): string => {
  return (
    categories?.find((category) => category.isTop === isTop && category.isParent === isParent)
      ?.categoryName ?? ''
  );
};

const fetchVideoContentDetail = async (params: ApiVideocontenthospitalElDetailParams) => {
  const response = await apiClient.v3.apiVideocontenthospitalElDetail(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export default function VideoContentListItem(content: VideoContentHospitalCategoryThumbnailPath) {
  const [selectedVideoContent, setSelectedVideoContent] = useAtom(selectedVideoContentState);

  const [trigger, setTrigger] = useState(0);

  const { _id: vchID = '', title, subtitle, thumbnailFileID, categories } = content;

  const middleCategory = getCategoryName(categories, false, true);
  const minorCategory = getCategoryName(categories, false, false);

  const { data, refetch } = useQuery({
    queryKey: [QUERY_KEY.apiVideocontenthospitalElDetail, { vchID }] as const,
    queryFn: ({ queryKey }) => fetchVideoContentDetail(queryKey[1]),
    enabled: false,
  });

  const handleClick = () => {
    refetch();
    setTrigger((prev) => prev + 1);
  };

  useEffect(() => {
    if (data && trigger > 0) {
      setSelectedVideoContent(data);
    }
  }, [data, trigger]);

  return (
    <div className='w-full cursor-pointer px-60' onClick={handleClick}>
      <div
        className={`${selectedVideoContent._id === vchID ? 'border-blue500 bg-blue50' : 'border-white600 bg-white50'} flex h-[116px] w-full gap-20 rounded-r16 border p-10`}>
        <img
          alt={title}
          className='h-full w-[172px] rounded-r10 border border-white600 object-cover'
          src={thumbnailFileID?.paths?.[0] ?? ''}
        />
        <div className='my-auto w-full flex-grow '>
          <div className='flex items-center gap-8'>
            <span className='text-Body10 text-black500'>{middleCategory}</span>
            <Divider type='plane' width={1} height={11} />
            <span className='text-Body10 text-black500'>{minorCategory}</span>
          </div>
          <div className='mt-[20px] line-clamp-2 overflow-hidden break-all text-Body11 text-black200'>
            {subtitle}
          </div>
          <div className='mt-6 line-clamp-2 overflow-hidden break-all text-Body13 text-black700'>
            {title}
          </div>
        </div>
      </div>
    </div>
  );
}
