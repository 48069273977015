import TextInput from 'afterdoc-design-system/components/Atoms/Input/TextInput';
import { useEffect, useState } from 'react';

const calculateDiscount = (
  price: number,
  saleType: string,
  salePrice: number,
  saleRate: number,
) => {
  const discountedPrice = saleType === 'price' ? price - salePrice : price * (1 - saleRate / 100);

  return Math.max(0, Math.floor(discountedPrice));
};

interface FinalPriceTextProps {
  price: number;
  salePrice: number;
  saleRate: number;
  saleType: string;
}
export default function FinalPriceText({
  price,
  salePrice,
  saleRate,
  saleType,
}: FinalPriceTextProps) {
  const [calculatedPrice, setCalculatedPrice] = useState('');

  useEffect(() => {
    const discountedPrice = calculateDiscount(price, saleType, salePrice, saleRate);
    setCalculatedPrice(discountedPrice.toLocaleString());
  }, [price, salePrice, saleRate, saleType]);

  return (
    <TextInput
      disabled
      className='h-full'
      placeholder='0원'
      inputClassName='w-[120px]'
      value={`${calculatedPrice}원`}
      label={{
        children: '최종가격',
        position: 'horizontal',
        width: 45,
        textClassName: 'text-Body12 text-black200 font-regular',
      }}
    />
  );
}
