import * as React from "react";
import type { SVGProps } from "react";
const SvgPdf = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <rect width={32} height={32} fill="#000" rx={4} />
    <path
      fill="#fff"
      d="M21.57 20.88V12h5.472v1.476h-3.696v2.328h3.156v1.476h-3.156v3.6zM13.004 20.88V12h2.436c2.712 0 4.38 1.404 4.38 4.404 0 2.988-1.668 4.476-4.284 4.476zm1.776-1.428h.54c1.668 0 2.688-.888 2.688-3.048s-1.02-2.988-2.688-2.988h-.54zM5 20.88V12h2.916c1.956 0 3.468.684 3.468 2.796 0 2.028-1.512 2.928-3.42 2.928H6.776v3.156zm1.776-4.56h1.068c1.224 0 1.812-.516 1.812-1.524 0-1.044-.648-1.38-1.872-1.38H6.776z"
    />
  </svg>
);
export default SvgPdf;
